import { AccordionIcon, Flex, Text } from "@chakra-ui/react";

interface EventAlertEditorHeadingProps {
  title: string;
  stepNumber: number;
}

export const EventAlertEditorHeading = ({
  title,
  stepNumber,
}: EventAlertEditorHeadingProps) => {
  return (
    <Flex
      align="center"
      gap={2}
      my={2}
      data-testid={`event-alert-editor-heading-${stepNumber}`}
    >
      <Flex
        border="1px solid"
        borderColor="gray.200"
        w={8}
        h={8}
        justify="center"
        align="center"
        borderRadius="full"
      >
        <Text color="primary">{stepNumber}</Text>
      </Flex>
      <Text>{title}</Text>
      <AccordionIcon />
    </Flex>
  );
};
