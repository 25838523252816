import React, { useState } from "react";
import { IInsightSection } from "core/types/TemplateConfig";
import { Drilldown } from "core/components/Drilldown";
import { Button, Flex, Spinner, UseDisclosureReturn } from "@chakra-ui/react";
import { ReferrersTable } from "./ReferrersTable";
import { IReferrer } from "./ReferrersContainer";

interface IReferrersListProps
  extends Pick<UseDisclosureReturn, "isOpen" | "onClose"> {
  insight: IInsightSection;
  insightSlug: "referrers" | "signup-referrers";
  initialReferrers: IReferrer[];
}

export const ReferrersList: React.FC<IReferrersListProps> = ({
  isOpen,
  onClose,
  initialReferrers,
  insight,
  insightSlug,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [referrers, setReferrers] = useState<IReferrer[]>(
    initialReferrers.slice(0, 10),
  );

  const loadNextReferrers = () => {
    setIsLoading(true);
    setReferrers([
      ...referrers,
      ...initialReferrers.slice(referrers.length, referrers.length + 10),
    ]);
    setIsLoading(false);
  };

  const hasMoreReferrers = referrers.length < initialReferrers.length;

  return (
    <Drilldown isOpen={isOpen} onClose={onClose} p={0}>
      {(Drilldown) => (
        <>
          <Drilldown.Header className="p-6">
            <Drilldown.Title title={insight.title} isLoading={isLoading} />
          </Drilldown.Header>
          <Drilldown.Body pt={2}>
            <ReferrersTable referrers={referrers} insightSlug={insightSlug} />
            {hasMoreReferrers && (
              <Flex justify="center">
                <Button isDisabled={isLoading} onClick={loadNextReferrers}>
                  {isLoading ? <Spinner /> : "Load more"}
                </Button>
              </Flex>
            )}
          </Drilldown.Body>
        </>
      )}
    </Drilldown>
  );
};
