export const mockNewGroupsChangesData = [
  {
    count: 12,
    insightChange: 83.33,
    date: "2021-01-26T00:00:00.000Z",
    humanizedDate: "Tue, 26 Jan",
  },
  {
    count: 93,
    insightChange: 33.33,
    date: "2021-01-26T00:00:00.000Z",
    humanizedDate: "26 Jan",
  },
  {
    count: 435,
    insightChange: 30.2,
    date: "2021-01-26T00:00:00.000Z",
    humanizedDate: "Jan",
  },
];

export const dngMockData = {
  newGroups: [
    { humanizedDate: "Tue, 26 Jan", newGroups: 6 },
    { humanizedDate: "Wed, 27 Jan", newGroups: 16 },
    { humanizedDate: "Thu, 28 Jan", newGroups: 14 },
    { humanizedDate: "Fri, 29 Jan", newGroups: 10 },
    { humanizedDate: "Sat, 30 Jan", newGroups: 6 },
    { humanizedDate: "Sun, 31 Jan", newGroups: 2 },
    { humanizedDate: "Mon, 01 Feb", newGroups: 12 },
  ],
};

export const wngMockData = {
  newGroups: [
    {
      humanizedDate: "Dec 14 - Dec 20",
      newGroups: 0,
    },
    {
      humanizedDate: "Dec 21 - Dec 27",
      newGroups: 4,
    },
    {
      humanizedDate: "Dec 28 - Jan 03",
      newGroups: 7,
    },
    {
      humanizedDate: "Jan 04 - Jan 10",
      newGroups: 11,
    },
    {
      humanizedDate: "Jan 11 - Jan 17",
      newGroups: 8,
    },
    {
      humanizedDate: "Jan 18 - Jan 24",
      newGroups: 22,
    },
    {
      humanizedDate: "Jan 25 - Jan 31",
      newGroups: 33,
    },
  ],
};

export const mngMockData = {
  newGroups: [
    {
      humanizedDate: "November",
      newGroups: 0,
    },
    {
      humanizedDate: "December",
      newGroups: 87,
    },
    {
      humanizedDate: "January",
      newGroups: 234,
    },
  ],
};
