import { ComputedTraitEditorContainer } from "modules/People/ComputedTraitEditorContainer";
import { cx } from "helpers/cx";
import { ITableTraitConfig } from "core/types/TableTraitConfig";
import { IGroup } from "core/types/Group";
import { IContact } from "core/types/Contact";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import {
  Command,
  CommandList,
  CommandOption,
} from "core/design-system/components/Command";
import { TraitIcon } from "core/components/Traits/TraitIcon";
import { HeaderContext } from "@tanstack/react-table";
import { BoltIcon as SolidBoltIcon } from "@heroicons/react/24/solid";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  BarsArrowDownIcon,
  BarsArrowUpIcon,
  BoltIcon as BoltOutlineIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import {
  Divider,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { ComputedTraitPopover } from "./ComputedTraitPopover";

export const ColumnHeader: React.FC<{
  info: HeaderContext<IContact | IGroup, unknown>;
  traitConfig: ITableTraitConfig;
  deleteTraitConfig: ({ id }: { id: string }) => void;
  moveColumnLeft: (columnId: string) => void;
  moveColumnRight: (columnId: string) => void;
  isFirst: boolean;
  isLast: boolean;
  sortBy: string;
  sortOrder: string;
  setSortBy: (sortBy: string) => void;
  setSortOrder: (sortOrder: string) => void;
}> = ({
  info,
  traitConfig,
  deleteTraitConfig,
  moveColumnLeft,
  moveColumnRight,
  isFirst,
  isLast,
  sortBy,
  sortOrder,
  setSortBy,
  setSortOrder,
}) => {
  const traitName = traitConfig.trait;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const currentApp = useCurrentApp();

  return (
    <>
      <Popover className="relative">
        <PopoverButton
          as="span"
          className={cx(
            "block w-full min-w-full py-1.5 pl-3 pr-5 hover:bg-gray-50 hover:ring-2 hover:ring-gray-50",
          )}
        >
          <div className="flex w-full flex-row items-center justify-between">
            <div className="flex w-full items-center gap-1">
              <TraitIcon
                traitName={traitName}
                computationType={
                  traitConfig.computedTrait?.config.computationType
                }
              />
              <Tooltip label={info.header.id} placement="top" hasArrow>
                <p className="w-full truncate selection:bg-transparent">
                  {traitConfig.trait.split(`_`).join(` `)}
                </p>
              </Tooltip>
            </div>
            <div className="flex items-center">
              {traitConfig.computedTrait && (
                <ComputedTraitPopover computedTrait={traitConfig.computedTrait}>
                  {traitConfig.computedTrait.isLive ? (
                    <SolidBoltIcon className="h-3 w-3 text-purple-500" />
                  ) : (
                    <BoltOutlineIcon className="h-3 w-3 text-purple-500" />
                  )}
                </ComputedTraitPopover>
              )}
            </div>
          </div>
        </PopoverButton>
        <PopoverPanel>
          <Command>
            <CommandList>
              <Tooltip
                shouldWrapChildren
                label={
                  !currentApp.sortingEnabled
                    ? "Sorting is not currently available for your workspace as you have more than 100k contacts"
                    : !traitConfig.computedTrait
                      ? "Sorting is only available for computed traits"
                      : ""
                }
                placement="right"
                hasArrow
              >
                <CommandOption
                  value="sort-asc"
                  onClick={() => {
                    setSortBy(`"${traitName}"`);
                    setSortOrder("ASC");
                  }}
                  disabled={
                    !traitConfig.computedTrait || !currentApp.sortingEnabled
                  }
                >
                  <div className="flex w-full items-center justify-between">
                    <div className="flex w-full items-center gap-1">
                      <BarsArrowUpIcon className="h-4 w-4" />
                      <p>Sort ascending</p>
                    </div>
                    {`"${traitName}"` === sortBy && sortOrder === "ASC" && (
                      <div className="h-2 w-2 rounded-full bg-purple-500" />
                    )}
                  </div>
                </CommandOption>
              </Tooltip>
              <Tooltip
                shouldWrapChildren
                label={
                  !currentApp.sortingEnabled
                    ? "Sorting is not currently available for your workspace as you have more than 100k contacts"
                    : !traitConfig.computedTrait
                      ? "Sorting is only available for computed traits"
                      : ""
                }
                placement="right"
                hasArrow
              >
                <CommandOption
                  value="sort-desc"
                  onClick={() => {
                    setSortBy(`"${traitName}"`);
                    setSortOrder("DESC");
                  }}
                  disabled={
                    !traitConfig.computedTrait || !currentApp.sortingEnabled
                  }
                >
                  <div className="flex w-full items-center justify-between">
                    <div className="flex w-full items-center gap-1">
                      <BarsArrowDownIcon className="h-4 w-4" />
                      <p>Sort descending</p>
                    </div>
                    {`"${traitName}"` === sortBy && sortOrder === "DESC" && (
                      <div className="h-2 w-2 rounded-full bg-purple-500" />
                    )}
                  </div>
                </CommandOption>
              </Tooltip>
              <CommandOption
                value="move-left"
                onClick={() => moveColumnLeft(traitConfig.trait)}
                disabled={isFirst}
              >
                <div className="flex items-center gap-1">
                  <ArrowLongLeftIcon className="h-4 w-4" />
                  <p>Move left</p>
                </div>
              </CommandOption>
              <CommandOption
                value="move-right"
                onClick={() => moveColumnRight(traitConfig.trait)}
                disabled={isLast}
              >
                <div className="flex items-center gap-1">
                  <ArrowLongRightIcon className="h-4 w-4" />
                  <p>Move right</p>
                </div>
              </CommandOption>
              <Divider my={1} />
              <CommandOption
                value="hide-column"
                onClick={() =>
                  deleteTraitConfig({ id: String(traitConfig.id) })
                }
              >
                <div className="flex items-center gap-1">
                  <EyeSlashIcon className="h-4 w-4" />
                  <p>Hide column</p>
                </div>
              </CommandOption>
              {traitConfig.computedTrait && (
                <Tooltip
                  shouldWrapChildren
                  label={
                    !traitConfig.computedTrait.isEditable &&
                    "This computed trait is read only"
                  }
                >
                  <CommandOption
                    value="edit-computed-trait"
                    disabled={!traitConfig.computedTrait.isEditable}
                    onClick={onOpen}
                  >
                    <div className="flex items-center gap-1">
                      <BoltOutlineIcon className="h-4 w-4" />
                      <p>Edit computed trait</p>
                    </div>
                  </CommandOption>
                </Tooltip>
              )}
            </CommandList>
          </Command>
        </PopoverPanel>
      </Popover>
      {isOpen && (
        <Drawer size="md" isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <ComputedTraitEditorContainer
              traitId={traitConfig?.computedTrait?.id}
              onClose={onClose}
            />
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};
