import { useDispatch, useSelector } from "react-redux";
import { IToast } from "core/types/Toast";
import { selector } from "core/models/toasts/selectors";
import { removeOne } from "core/models/toasts/actions";
import Notification from "core/components/Notification";
import { useToast } from "@chakra-ui/react";

export default function Toasts() {
  const dispatch = useDispatch();
  const toast = useToast();
  const toasts = useSelector(selector);
  toasts.forEach((t: IToast) => {
    if (!toast.isActive(t.id)) {
      toast({
        id: t.id,
        title: t.title,
        description: t.description,
        status: t.status,
        duration: 2000,
        isClosable: true,
        position: "top",
        render: () => (
          <Notification
            title={t.title}
            description={t.description}
            status={t.status}
          />
        ),
        onCloseComplete: () => dispatch(removeOne(t.id)),
      });
    }
  });
  return null;
}
