import React from "react";
import { Size } from "core/types/ViewInsight";
import { Tooltip } from "@chakra-ui/react";
import { ISerie } from "./ExplorationGraph";
import { Dot } from "../../Milestones/CompletionRateContainer";

export const ExplorationLegend: React.FC<{
  series: ISerie[];
  size: Size;
  onHover: (index: string | undefined) => void;
}> = ({ series, size, onHover }) => {
  const LEGEND_SIZE = { [Size.Small]: 1, [Size.Medium]: 3, [Size.Large]: 6 };

  return (
    <div className="flex w-full items-center justify-center">
      <div className="flex items-center gap-4">
        {series?.slice(0, LEGEND_SIZE[size]).map((serie, index: number) => (
          <Tooltip key={index} label={serie.name} placement="bottom" hasArrow>
            <div
              className="flex items-center"
              onMouseEnter={() => onHover(serie.uuid)}
              onMouseLeave={() => onHover(undefined)}
            >
              <Dot color={serie.color} />
              <p className="max-w-[100px] cursor-pointer truncate text-xs text-gray-700">
                {serie.name}
              </p>
            </div>
          </Tooltip>
        ))}
        {series?.length > LEGEND_SIZE[size] && (
          <Tooltip
            label={
              <div className="p-1">
                {series
                  ?.slice(LEGEND_SIZE[size])
                  .map((serie, index: number) => (
                    <div className="flex items-center">
                      <Dot color={serie.color} />
                      <p className="max-w-[100px] truncate text-xs text-gray-400">
                        {serie.name}
                      </p>
                    </div>
                  ))}
              </div>
            }
            placement="bottom"
            hasArrow
          >
            <div className="flex items-center">
              <Dot color="gray.400" />
              <p className="truncate text-xs text-gray-700">
                {series.length - LEGEND_SIZE[size]} more
              </p>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
