import { useParams } from "react-router-dom";
import React from "react";
import moment from "moment";
import { IInsightSection } from "core/types/TemplateConfig";
import { IReport } from "core/types/Report";
import { useGroupDrilldown } from "core/hooks/useGroupDrilldown";
import { useAppObjects } from "core/hooks/useAppObjects";
import {
  GroupListSingleGroup,
  IGroup,
} from "core/components/Group/GroupListSingleGroup";
import Error from "core/components/Error";
import { Drilldown } from "core/components/Drilldown";
import { Button, Flex, Spinner, UseDisclosureReturn } from "@chakra-ui/react";

interface IHottestGroupsListProps
  extends Pick<UseDisclosureReturn, "isOpen" | "onClose"> {
  insight: IInsightSection;
  report: IReport;
}

export const HottestGroupsList: React.FC<IHottestGroupsListProps> = ({
  isOpen,
  onClose,
  insight,
  report,
}) => {
  const { appId } = useParams<{ appId: string }>();
  const { activeAppObject } = useAppObjects();
  const {
    groups: hottestGroups,
    isLoading,
    error,
    hasMoreGroups,
    loadMore,
  } = useGroupDrilldown({
    report,
    insightType: insight.typeId,
    skip: !isOpen,
  });

  if (error) {
    return <Error />;
  }

  return (
    <Drilldown isOpen={isOpen} onClose={onClose}>
      {(Drilldown) => (
        <>
          <Drilldown.Header>
            <Drilldown.Title
              isLoading={isLoading}
              title={insight.title}
              description={`Fastest ${activeAppObject?.pluralName.toLowerCase()} to reach all milestones`}
            />
          </Drilldown.Header>
          <Drilldown.Body>
            {hottestGroups.map((group: IGroup) => (
              <GroupListSingleGroup
                key={group.groupId}
                id={group.groupId}
                traits={group.traits}
                groupType={group.groupType}
                appId={Number(appId)}
                description={`Joined ${moment.utc(group?.milestone1Timestamp).fromNow()}`}
              />
            ))}
            {hasMoreGroups && (
              <Flex justify="center">
                <Button isDisabled={isLoading} onClick={loadMore}>
                  {isLoading ? <Spinner /> : "Load more"}
                </Button>
              </Flex>
            )}
          </Drilldown.Body>
        </>
      )}
    </Drilldown>
  );
};
