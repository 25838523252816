// Divides a progression into a series of definite targets.
export type Level = number;

// Identifier for which progression track to follow.
export enum Track {
  ADOPTION = "adoption",
  USAGE = "usage",
}

/* -------------------------- Goals & Progressions -------------------------- */

export interface GoalDefinition {
  name?: string;
  description?: string;
  badgeVisible?: boolean;
  badgeIcon?: string;
}

export type Goal = GoalDefinition & {
  name: string;
  track: Track;
  level: Level;
  target: number;
};

export interface ProgressionDefinition {
  [Track.ADOPTION]: GoalDefinition[];
  [Track.USAGE]: GoalDefinition[];
}

export interface ProgressionMap {
  [Track.ADOPTION]: Goal[];
  [Track.USAGE]: Goal[];
}

/* ---------------------------- State Management ---------------------------- */

export type InsightResponse = {
  [Track.ADOPTION]: {
    percentage: number;
    count: number;
    timestamps: string[];
  };
  [Track.USAGE]: {
    percentage: number;
    count: number;
    timestamps: string[];
  };
};

export interface TrackState {
  level: Level;
  count: number;
  currentGoal: Goal;
  nextGoal: Goal;
  percentComplete: number;
  isFinished: boolean;
  timestamps: string[];
}

export type ProgressionState = {
  [key in Track]: TrackState;
};
