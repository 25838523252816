import { useParams } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { ITableTraitConfig } from "core/types/TableTraitConfig";
import {
  useCreateTableTraitConfigMutation,
  useDeleteTableTraitConfigMutation,
  useLazyGetTableTraitConfigsQuery,
  useUpdateTableTraitConfigOrderMutation,
} from "core/models/tableTraitConfig";
import { AppObjectSlugs, AppObjectType } from "core/models/appObjects";

export const useTableTraitConfig = (
  objectType?: AppObjectType,
  trait?: string,
): any => {
  const { appId } = useParams();
  const [getTableTraitConfigs, { data }] = useLazyGetTableTraitConfigsQuery();

  useEffect(() => {
    if (!data) getTableTraitConfigs({ appId: Number(appId) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId, data]);

  const [createTableTraitConfig] = useCreateTableTraitConfigMutation();
  const [deleteTableTraitConfig] = useDeleteTableTraitConfigMutation();
  const [updateTableTraitConfigOrder] =
    useUpdateTableTraitConfigOrderMutation();

  function createTraitConfig({
    trait,
    objectType,
  }: {
    trait: string;
    objectType: string;
  }) {
    if (!appId) return;
    createTableTraitConfig({ appId, trait, objectType })
      .unwrap()
      .then(() => {
        getTableTraitConfigs({ appId });
        window.analytics.track(
          "added_trait_column",
          {
            appId,
            trait: trait,
          },
          { context: { groupId: appId } },
        );
      });
  }

  function deleteTraitConfig({ id }: { id: string }) {
    if (!appId) return;
    deleteTableTraitConfig({ id, appId })
      .unwrap()
      .then(() => {
        getTableTraitConfigs({ appId });
        window.analytics.track(
          "removed_trait_column",
          {
            appId,
          },
          { context: { groupId: appId } },
        );
      });
  }

  function updateTraitConfigOrder({
    order,
  }: {
    order: { id: string; order: number }[];
  }) {
    if (!appId) return;
    updateTableTraitConfigOrder({ order, appId })
      .unwrap()
      .then(() => {
        getTableTraitConfigs({ appId });
        window.analytics.track(
          "update_trait_column_order",
          {
            appId,
          },
          { context: { groupId: appId } },
        );
      });
  }

  const userTableTraitConfigs = useMemo(
    () =>
      data?.tableTraitConfigs.filter(
        (ttc: ITableTraitConfig) => ttc.appObject.slug === AppObjectSlugs.User,
      ) || [],
    [data],
  );

  const groupTableTraitConfigs = useMemo(
    () =>
      data?.tableTraitConfigs.filter(
        (ttc: ITableTraitConfig) => ttc.appObject.slug === AppObjectSlugs.Group,
      ) || [],
    [data],
  );

  const companyTableTraitConfigs = useMemo(
    () =>
      data?.tableTraitConfigs.filter(
        (ttc: ITableTraitConfig) =>
          ttc.appObject.slug === AppObjectSlugs.Company,
      ) || [],
    [data],
  );

  const traitConfigsByObjectType = {
    [AppObjectType.User]: userTableTraitConfigs,
    [AppObjectType.Company]: companyTableTraitConfigs,
    [AppObjectType.Group]: groupTableTraitConfigs,
  };

  return {
    tableTraitConfig: data?.tableTraitConfigs?.find(
      (ttc) => ttc.trait === trait,
    ),
    userTableTraitConfigs,
    groupTableTraitConfigs,
    companyTableTraitConfigs,
    createTraitConfig,
    deleteTraitConfig,
    updateTraitConfigOrder,
    tableTraitConfigs: objectType && traitConfigsByObjectType[objectType],
  } as any;
};
