import humps from "humps";
import { IFilterGroup } from "core/types/Report";

export const transformParams = (params: any[] | undefined) => {
  return (
    (params && params.map((element: any) => humps.decamelizeKeys(element))) ||
    params
  );
};

export const transformFilterGroups = (filterGroups: IFilterGroup[]) => {
  return filterGroups.map((filterGroup: any) => ({
    filters: transformParams(filterGroup.filters),
    join_operator: filterGroup.joinOperator,
  }));
};
