import { useLabels } from "core/hooks/useLabels";
import { IReport } from "core/types/Report";
import { ReportLabelsBadge } from "modules/Reports/List/ReportLabelsBadge";
import { SelectLabels } from "core/modules/reports/Navbar/SelectLabels";
import { useState } from "react";

export const ReportLabels: React.FC<{
  report: IReport;
}> = ({ report }) => {
  const { labels } = useLabels({
    appId: report.appId,
  });
  const [selectedLabels, setSelectedLabels] = useState(report.labels);

  return (
    <div className="flex items-center">
      <ReportLabelsBadge labels={selectedLabels} />
      <SelectLabels
        appId={report.appId}
        reportId={report.id}
        labels={labels || []}
        selectedLabels={selectedLabels}
        setSelectedLabels={setSelectedLabels}
      />
    </div>
  );
};
