import {
  generateHexColorForTraitValueWithIndex,
  getTraitValue,
} from "core/design-system/charts/Breakdown/utils";

const MAX_NUMBER_OF_TRAIT_VALUES = 25;

export const ConversionLineChartLegend: React.FC<{ traitValues: string[] }> = ({
  traitValues,
}) => {
  return (
    <div className="flex flex-col items-center gap-3">
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {traitValues
          .slice(0, MAX_NUMBER_OF_TRAIT_VALUES)
          .map((breakdownTrait, index) => {
            return (
              <span key={`item-${breakdownTrait}`}>
                <svg width="160" height="20" viewBox="0 0 180 100">
                  <circle
                    cx="-10"
                    cy="50"
                    r="30"
                    fill={generateHexColorForTraitValueWithIndex(
                      breakdownTrait,
                      traitValues.length - index - 1,
                    )}
                  />
                  <text x="40" y="70" fill="black" fontSize="60">
                    {getTraitValue(breakdownTrait)}
                  </text>
                </svg>
              </span>
            );
          })}
      </div>
      {traitValues.length > MAX_NUMBER_OF_TRAIT_VALUES && (
        <span className="text-xs text-gray-500">
          Only the first 25 values are displayed.
        </span>
      )}
    </div>
  );
};
