import { useLocation } from "react-router-dom";
import { useMemo } from "react";

/**
 * Parses a given query param value from the current location.
 *
 * Example: \
 *   const name = useQueryParam('name'); // analytics.june.so/property?name=device_type
 *
 */
export const useQueryParam = (queryparam: string): string | null => {
  const { search } = useLocation();
  return useMemo(
    () => new URLSearchParams(search).get(queryparam),
    [search, queryparam],
  );
};
