import humps from "humps";

export const transformResponseExceptPropertiesAndContext = (
  obj: Record<string, any>,
): Record<string, any> => {
  if (Array.isArray(obj)) {
    return obj.map((item) => transformResponseExceptPropertiesAndContext(item));
  } else if (obj && typeof obj === "object") {
    return Object.entries(obj).reduce(
      (acc: Record<string, any>, [key, value]) => {
        if (["properties", "context"].includes(key)) {
          acc[key] = value;
        } else {
          acc[humps.camelize(key)] =
            transformResponseExceptPropertiesAndContext(value);
        }
        return acc;
      },
      {},
    );
  }
  return obj;
};
