import { Issue, IssueLevel } from "./issue";
import { getIssues } from "./utils";
import { IEventLog } from "core/types/Debugger";

export type ValidationResult = {
  isValid: boolean;
  maxLevel: IssueLevel | null;
  issues: Issue[];
};

function getMaxLevel(issues: Issue[]): IssueLevel | null {
  if (issues.length === 0) {
    return null;
  }

  const hasError = issues.some((issue) => issue.level === "error");
  if (hasError) {
    return "error";
  }

  const hasWarning = issues.some((issue) => issue.level === "warning");
  if (hasWarning) {
    return "warning";
  }

  return "info";
}

export function isValid(issues: Issue[]) {
  return issues.length === 0 || getMaxLevel(issues) === "info";
}

export const validateEventLog = (log: IEventLog): ValidationResult => {
  const { type, ...payload } = log;

  const issues = getIssues(type, payload);

  return {
    isValid: isValid(issues),
    maxLevel: getMaxLevel(issues),
    issues,
  };
};
