import reduce from "lodash/reduce";
import { pluralize } from "helpers/pluralize";
import {
  Goal,
  InsightResponse,
  Level,
  ProgressionDefinition,
  ProgressionMap,
  ProgressionState,
  Track,
  TrackState,
} from "./types";

/* -------------------------------- Constants ------------------------------- */

export const BASE_LEVEL = 0;
export const DEFAULT_ICON = "/badges/trophy.png";
export const GET_STATE_KEY = (reportId: number) =>
  `june__achievements-${reportId}`;

/* ------------------------- Progression Definitions ------------------------ */

// Please keep in sync with backend: api/app/services/insight/feature_release/feature_adoption_milestones.rb
export const PROGRESSION = createProgressionMap({
  [Track.ADOPTION]: [
    {
      name: "First {targetName}",
      badgeIcon: "/badges/seedling.png",
    },
    {
      name: "First 5 {targetName}",
      badgeIcon: "/badges/herb.png",
    },
    {
      name: "First 10 {targetName}",
      badgeIcon: "/badges/potted-plant.png",
    },
    {
      name: "First 25 {targetName}",
      badgeIcon: "/badges/tree.png",
    },
    {
      name: "First 50 {targetName}",
      badgeIcon: "/badges/mountain.png",
    },
    {
      name: "First 100 {targetName}",
      badgeIcon: "/badges/dragon-face.png",
    },
  ],
  [Track.USAGE]: [
    {
      name: "Used 1 time",
      badgeIcon: "/badges/one-fourth-moon.png",
    },
    {
      name: "Used 5 times",
      badgeIcon: "/badges/half-moon.png",
    },
    {
      name: "Used 10 times",
      badgeIcon: "/badges/three-fourths-moon.png",
    },
    {
      name: "Used 25 times",
      badgeIcon: "/badges/full-moon.png",
    },
    {
      name: "Used 50 times",
      badgeIcon: "/badges/new-moon-face.png",
    },
    {
      name: "Used 100 times",
      badgeIcon: "/badges/full-moon-face.png",
    },
  ],
});

/* ---------------------------- Core Logic --------------------------- */

/**
 * Returns a ProgressionMap from a definition.
 * This inlines all the level information.
 */
function createProgressionMap(
  progression: ProgressionDefinition,
): ProgressionMap {
  let finalMap = {} as ProgressionMap;
  for (const [track, goals] of Object.entries(progression) as [Track, any][]) {
    finalMap[track] = goals.map((goal: Goal, level: Level) => ({
      ...goal,
      target: getTargetForLevel(track, level),
      track,
      level,
    }));
  }
  return finalMap;
}

/**
 * Generates the infinite progression curve.
 * Every step will be assigned to a badge, if it exists, in the progression map.
 */
export function getTargetForLevel(track: Track, level: number): number {
  if (level <= BASE_LEVEL) {
    return 1;
  } else if (level === BASE_LEVEL + 1) {
    return 5;
  } else {
    const prev = getTargetForLevel(track, level - 1);
    const change = level % 3 ? 2 : 2.5;
    return prev * change;
  }
}

/**
 * Given a metric's current value, returns the level the user is at.
 */
export function getLevelForMetric(track: Track, metric: number): number {
  let i = 0;
  while (i <= 30) {
    if (metric < getTargetForLevel(track, i)) return i;
    i++;
  }
  return 1;
}

/**
 * Gets the percent completion towards the next goal.
 * Removes the previous progress: e.g. 5 users, the next goal's progress (10) starts at 0% , not at 50%.
 */
export function getPercentComplete(
  previousTarget: number,
  count: number,
  nextTarget: number,
): number {
  // Don't subtract from level 1.
  let adjustedPrevious = previousTarget !== 1 ? previousTarget : 0;
  return Math.round(
    ((count - adjustedPrevious) / (nextTarget - adjustedPrevious)) * 100,
  );
}

/**
 * Builds the achievements state.
 * We do this only once and aggressively memoize at the component to make sure there's no impact to performance.
 */
export function buildAchievementsState(
  metrics: InsightResponse,
): ProgressionState {
  return reduce(
    Object.keys(metrics) as Track[],
    (newState: ProgressionState, track: Track, index: number) => {
      const { count, timestamps } = metrics[track];
      const level = getLevelForMetric(track, count);
      const nextGoal = getGoalForLevel(track, level);
      const currentGoal =
        level === BASE_LEVEL
          ? getBaseGoal(track)
          : getGoalForLevel(track, level - 1);
      const percentComplete = getPercentComplete(
        currentGoal.target,
        count,
        nextGoal.target,
      );
      const isFinished = isTrackFinished(track, level);
      return {
        ...newState,
        [track]: {
          level,
          count,
          nextGoal,
          currentGoal,
          percentComplete,
          isFinished,
          timestamps,
        } as TrackState,
      };
    },
    {} as ProgressionState,
  );
}

/* --------------------------- State diffing logic -------------------------- */

export function getGoalsBetweenLevels(
  track: Track,
  previousLevel: number,
  currentLevel: number,
): Goal[] {
  return PROGRESSION[track].slice(previousLevel, currentLevel);
}

/**
 * Retrieves any achievements earned between states.
 */
export function getGoalChanges(
  previousState: ProgressionState | undefined,
  state: ProgressionState,
): Goal[] {
  if (!state) return [];

  return reduce(
    Object.values(Track),
    (allChanges: Goal[], track: Track) => {
      const { level: previousLevel } = previousState
        ? previousState[track]
        : { level: 0 };
      const { level: currentLevel } = state[track];
      if (currentLevel > previousLevel) {
        return [
          ...allChanges,
          ...getGoalsBetweenLevels(track, previousLevel, currentLevel),
        ];
      } else {
        return allChanges;
      }
    },
    [],
  );
}

export function getBaseGoal(track: Track): Goal {
  return {
    name: "Just started",
    badgeIcon: getBaseIcons(track),
    level: -1,
    track: track,
    target: 0,
  };
}

export function getGoalForLevel(track: Track, level: number): Goal {
  const goal = PROGRESSION[track][level];
  const target = getTargetForLevel(track, level);
  if (!goal) {
    return {
      name: `${target.toLocaleString("en-US")} {targetName}`,
      badgeIcon: DEFAULT_ICON,
      level: level,
      track: track,
      target,
    };
  } else {
    return goal;
  }
}

export function fetchState(reportId: number): ProgressionState | undefined {
  const state = localStorage.getItem(GET_STATE_KEY(reportId));
  return state ? JSON.parse(state) : undefined;
}

export function saveState(reportId: number, state: ProgressionState) {
  localStorage.setItem(GET_STATE_KEY(reportId), JSON.stringify(state));
}

export function diffAndSave(
  reportId: number,
  state: ProgressionState,
): { changes: Goal[]; isFirstLoad: boolean } {
  // Get the previous state in local storage and save the new one.
  const previousState = fetchState(reportId);
  saveState(reportId, state);
  // Return any changes in an array.
  return {
    changes: getGoalChanges(previousState, state),
    isFirstLoad: previousState === undefined,
  };
}

export function isTrackFinished(track: Track, currentLevel: number) {
  return currentLevel >= PROGRESSION[track].length;
}

export function getCompletionState(state: ProgressionState): boolean {
  return state[Track.ADOPTION].isFinished && state[Track.USAGE].isFinished;
}

/* -------------------------- Insight Manipulation -------------------------- */

export function getEmptyInsight(): InsightResponse {
  return {
    [Track.ADOPTION]: {
      count: 0,
      percentage: 0,
      timestamps: [],
    },
    [Track.USAGE]: {
      count: 0,
      percentage: 0,
      timestamps: [],
    },
  };
}

export function buildMockInsight(
  adoption: number,
  usage: number,
): InsightResponse {
  return {
    adoption: { percentage: 22.8, count: adoption, timestamps: [] },
    usage: { count: usage, percentage: 0, timestamps: [] },
  };
}

/* ---------------------------- Visual Utilities ---------------------------- */

export function getTrackColorScheme(track?: Track): string {
  switch (track) {
    case Track.ADOPTION:
      return "#E6F8EC";
    case Track.USAGE:
      return "purple.50";
    default:
      return "gray.50";
  }
}

export function getTargetNameForTrack(
  track: Track,
  target: number,
  unit?: string,
): string {
  return track === Track.ADOPTION ? unit || "" : "times";
}

export function getGoalName(goal: Goal, unit?: string): string {
  return goal.name.replace(
    "{targetName}",
    getTargetNameForTrack(goal.track, goal.target, unit),
  );
}

export function getMetricText(
  track: Track,
  trackData: InsightResponse,
  unit?: string,
): string {
  switch (track) {
    case Track.ADOPTION:
      return `${trackData[Track.ADOPTION].count.toLocaleString("en-US")} ${getTargetNameForTrack(
        Track.ADOPTION,
        trackData[Track.ADOPTION].count,
        unit,
      )}`;
    case Track.USAGE:
      return `${trackData[Track.USAGE].count.toLocaleString("en-US")} ${pluralize(
        trackData[Track.USAGE].count,
        `time`,
        `times`,
      )}`;
    default:
      return "...";
  }
}

export function getHelpText(track: Track, unit?: string) {
  switch (track) {
    case Track.ADOPTION:
      return `How many ${unit} have adopted your feature?`;
    case Track.USAGE:
      return "How many times has your feature been used?";
    default:
      return "...";
  }
}

export function getBaseIcons(track: Track) {
  switch (track) {
    case Track.ADOPTION:
      return "/badges/empty-planter.png";
    case Track.USAGE:
      return "/badges/new-moon.png";
    default:
      return "/badges/trophy.png";
  }
}

export function getTimestamps(state: ProgressionState, track: Track): string[] {
  return state[track].timestamps;
}
