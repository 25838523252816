import { useEffect, useState } from "react";

const DEFAULT_PAGE = 1;

const getInitialPage = () => {
  const qs = new URLSearchParams(window.location.search);
  if (Boolean(qs.get("page"))) return Number(qs.get("page"));

  return (
    Number(localStorage.getItem(`${window.location.pathname}-visited-page`)) ||
    DEFAULT_PAGE
  );
};

export const usePagination = ({
  withURL = true,
  searchQuery = "",
  persist = true,
}: {
  withURL?: boolean;
  searchQuery?: string;
  persist?: boolean;
}) => {
  const [currentPage, setCurrentPage] = useState(getInitialPage());
  const previousPage = () => setCurrentPage(currentPage - 1);
  const nextPage = () => setCurrentPage(currentPage + 1);

  function persistPage() {
    if (!persist) return;
    // Update URL
    if (currentPage >= 1 && withURL) {
      const qs = new URLSearchParams(window.location.search);
      qs.set("page", String(currentPage));
      window.history.replaceState(null, "", `?${qs.toString()}`);
    }

    // Store page in local storage
    localStorage.setItem(
      `${window.location.pathname}-visited-page`,
      String(currentPage),
    );
  }

  useEffect(() => {
    persistPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (!!searchQuery && currentPage !== 1) {
      setCurrentPage(1);
      persistPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return {
    currentPage,
    previousPage,
    nextPage,
    setPage: setCurrentPage,
  };
};

export default usePagination;
