import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  StyleProps,
} from "@chakra-ui/react";

interface IPopoverProps extends PopoverProps {
  popoverTrigger: React.ReactNode;
  popoverBody: React.ReactNode;
  backgroundColor?: StyleProps["backgroundColor"];
}

export const CustomPopover: React.FC<IPopoverProps> = ({
  popoverTrigger,
  popoverBody,
  backgroundColor,
  ...popoverProps
}) => {
  const background = backgroundColor || "gray.900";

  return (
    <Popover trigger="hover" placement="right" {...popoverProps}>
      <PopoverTrigger>{popoverTrigger}</PopoverTrigger>
      <PopoverContent
        color="white"
        bg={background}
        borderColor={background}
        borderRadius="lg"
      >
        <PopoverArrow bg={background} />
        <PopoverBody>{popoverBody}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default CustomPopover;
