import React from "react";
import { IInsightSection } from "core/types/TemplateConfig";
import { IReport } from "core/types/Report";
import { useContactDrilldown } from "core/hooks/useContactDrilldown";
import { GroupListSingleGroup } from "core/components/Group/GroupListSingleGroup";
import Error from "core/components/Error";
import { Drilldown } from "core/components/Drilldown";
import { UserListSingleContact } from "core/components/Contact/UserListSingleContact";
import { Button, Flex, Spinner, UseDisclosureReturn } from "@chakra-ui/react";
import { IEngageUser } from "./EngagedUsersContainer";

interface IEngagedUsersListProps
  extends Pick<UseDisclosureReturn, "isOpen" | "onClose"> {
  report: IReport;
  unit: string;
  csvExportLoading: boolean;
  requestCsvExport: () => void;
  insight: IInsightSection;
}

export const EngagedUsersList: React.FC<IEngagedUsersListProps> = ({
  report,
  unit,
  isOpen,
  onClose,
  csvExportLoading,
  requestCsvExport,
  insight,
}) => {
  const { contacts, isLoading, error, hasMoreContacts, loadMore } =
    useContactDrilldown({
      report,
      insightType: insight.typeId,
      skip: !isOpen,
    });
  const engagedUsers = contacts as IEngageUser[];

  if (error) {
    return <Error />;
  }

  return (
    <Drilldown isOpen={isOpen} onClose={onClose}>
      {(Drilldown) => (
        <>
          <Drilldown.Header>
            <Drilldown.Title title={insight.title} isLoading={isLoading} />
            <Drilldown.DownloadButton
              isDisabled={engagedUsers.length <= 0}
              isLoading={csvExportLoading}
              onClick={requestCsvExport}
            />
          </Drilldown.Header>
          <Drilldown.Body>
            {engagedUsers.map((contact) => (
              <>
                {unit === "users" ? (
                  <UserListSingleContact
                    key={contact.userId}
                    id={contact.userId || ""}
                    traits={contact.traits}
                    description={`Used ${contact.numberOfTimesUsed.toString()} times over ${
                      contact.streakInWeeks
                    } weeks`}
                  />
                ) : (
                  <GroupListSingleGroup
                    key={contact.userId}
                    id={contact.groupId || ""}
                    traits={contact.traits}
                    groupType={contact.groupType}
                    description={`Used ${contact.numberOfTimesUsed.toString()} times over ${
                      contact.streakInWeeks
                    } weeks`}
                  />
                )}
              </>
            ))}
            {hasMoreContacts && (
              <Flex justify="center">
                <Button isDisabled={isLoading} onClick={loadMore}>
                  {isLoading ? <Spinner /> : "Load more"}
                </Button>
              </Flex>
            )}
          </Drilldown.Body>
        </>
      )}
    </Drilldown>
  );
};
