export interface ISortedByOption {
  label: string;
  value: string;
  column: string;
  order: "+" | "-";
}

export const RECENT: ISortedByOption = {
  column: "updatedAt",
  order: "-",
  value: "updatedAt",
  label: "Recent",
};

export const POPULAR: ISortedByOption = {
  column: "openCount",
  order: "-",
  value: "openCount",
  label: "Popular",
};

export const NAME_A_Z: ISortedByOption = {
  column: "name",
  order: "+",
  value: "nameAsc",
  label: "Name (A-Z)",
};

export const NAME_Z_A: ISortedByOption = {
  column: "name",
  order: "-",
  value: "nameDesc",
  label: "Name (Z-A)",
};

export const SORTED_BY_OPTIONS_ARRAY: ISortedByOption[] = [
  RECENT,
  POPULAR,
  NAME_A_Z,
  NAME_Z_A,
];

export const SORTED_BY_OPTIONS = [
  {
    label: "",
    options: SORTED_BY_OPTIONS_ARRAY,
  },
];
