import { SnakeCasedProperties } from "type-fest/source/snake-cased-properties";
import humps from "humps";
import { baseQuery } from "core/initializers/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";
import { ITableTraitConfigResponse } from "core/types/TableTraitConfig";

export const tableTraitConfigApi = createApi({
  baseQuery,
  reducerPath: "tableTraitConfigApi",
  tagTypes: ["TableTraitConfig"],
  endpoints: (builder) => ({
    getTableTraitConfigs: builder.query<
      ITableTraitConfigResponse,
      { appId: string | number }
    >({
      query: ({ appId }) => ({
        url: `/table_trait_config`,
        params: humps.decamelizeKeys({ appId }),
        providesTags: ["TableTraitConfig"],
      }),
      transformResponse: (
        response: SnakeCasedProperties<ITableTraitConfigResponse>,
      ) => humps.camelizeKeys(response) as ITableTraitConfigResponse,
    }),
    createTableTraitConfig: builder.mutation<
      ITableTraitConfigResponse,
      { appId: string | number; trait: string; objectType: string }
    >({
      query: ({ appId, trait, objectType }) => ({
        url: `/table_trait_config`,
        method: "POST",
        params: humps.decamelizeKeys({ appId, trait, objectType }),
        invalidatesTags: ["TableTraitConfig"],
      }),
    }),
    deleteTableTraitConfig: builder.mutation<
      ITableTraitConfigResponse,
      { appId: string | number; id: string }
    >({
      query: ({ appId, id }) => ({
        url: `/table_trait_config/${id}`,
        method: "DELETE",
        params: humps.decamelizeKeys({ appId, id }),
        invalidatesTags: ["TableTraitConfig"],
      }),
    }),
    updateTableTraitConfigOrder: builder.mutation<
      ITableTraitConfigResponse,
      { appId: string | number; order: { id: string; order: number }[] }
    >({
      query: ({ appId, order }) => ({
        url: `/table_trait_config/update_order`,
        method: "PUT",
        body: { app_id: appId, order: order },
        invalidatesTags: ["TableTraitConfig"],
      }),
    }),
    updateTableTraitConfig: builder.mutation<
      ITableTraitConfigResponse,
      { appId: string | number; id: string; size: number }
    >({
      query: ({ appId, id, size }) => ({
        url: `/table_trait_config/${id}`,
        method: "PUT",
        body: { app_id: appId, id: id, size: size },
        invalidatesTags: ["TableTraitConfig"],
      }),
    }),
  }),
});

export const {
  useLazyGetTableTraitConfigsQuery,
  useCreateTableTraitConfigMutation,
  useDeleteTableTraitConfigMutation,
  useUpdateTableTraitConfigOrderMutation,
  useUpdateTableTraitConfigMutation,
} = tableTraitConfigApi;
