import React, { useState } from "react";
import { ColorPicker } from "modules/Labels/ColorPicker";
import { ILabel } from "core/types/Label";
import { useLabels } from "core/hooks/useLabels";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import {
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

interface ILabelActionCellProps {
  props: ILabel;
}

export const LabelActionCell: React.FC<ILabelActionCellProps> = ({
  props: label,
}) => {
  const currentApp = useCurrentApp();
  const [color, setColor] = useState<string>(label.color);
  const [labelName, setLabelName] = useState<string>(label.name);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { deleteLabel, updateLabel } = useLabels({ appId: currentApp.id });

  const onUpdate = () => {
    updateLabel({
      appId: currentApp.id,
      id: label.id,
      name: labelName,
      color,
    });
    onClose();
  };

  const onDeleteLabel = () => {
    deleteLabel({
      appId: currentApp.id,
      id: label.id,
    });
  };

  return (
    <Flex justify="flex-end">
      <Button
        data-testid="label-action-cell-edit-button"
        onClick={onOpen}
        borderRadius="md"
        size="xs"
        variant="ghost"
        colorScheme="purple"
      >
        Edit
      </Button>
      <Button
        data-testid="label-action-cell-delete-button"
        onClick={onDeleteLabel}
        borderRadius="md"
        size="xs"
        variant="ghost"
        colorScheme="purple"
      >
        Delete
      </Button>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="md">{label.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody data-testid="label-action-cell-edit-modal">
            <Flex>
              <ColorPicker color={color} setColor={setColor} />
              <Input
                data-testid="label-action-cell-edit-input"
                ml={2}
                size="sm"
                placeholder="Label name"
                bg="white"
                borderRadius="lg"
                value={labelName}
                onChange={(e) => setLabelName(e.target.value)}
              />
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button onClick={onUpdate} colorScheme="purple">
              Update
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};
