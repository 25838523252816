export const trackEvent = ({
  eventName,
  appId,
  properties = {},
}: {
  eventName: string;
  appId: number | string;
  properties?: { [key: string]: any };
}) => {
  window.analytics.track(
    eventName,
    {
      ...properties,
      appId,
    },
    { context: { groupId: appId } },
  );
};
