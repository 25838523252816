const baseStyle = {
  list: {
    p: 1,
    minW: "180px",
  },
  item: {
    fontSize: "sm",
    borderRadius: "md",
  },
};

const Menu = {
  baseStyle,
};

export default Menu;
