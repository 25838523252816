import { useParams } from "react-router-dom";
import React from "react";
import { IInsightSection } from "core/types/TemplateConfig";
import { IReportInsight } from "core/types/Report";
import useInsightDataFetching from "core/hooks/useInsightDataFetching";
import { useInsightCsvExport } from "core/hooks/useInsightCsvExport";
import { useAppObjects } from "core/hooks/useAppObjects";
import { IntervalTypeNames } from "core/constants/timerange";
import { IListConfig } from "core/components/List/List.types";
import List from "core/components/List";
import { InsightReactions } from "core/components/InsightReactions";
import InsightCard from "core/components/InsightCard/Index";
import { HandThumbUpIcon } from "@heroicons/react/24/outline";
import { Button, useDisclosure } from "@chakra-ui/react";
import { UserCell } from "./ChurningUsersTable/UserCell";
import { UsedOverCell } from "./ChurningUsersTable/UsedOverCell";
import { TotalEventsCell } from "./ChurningUsersTable/TotalEventsCell";
import { LastSeenCell } from "./ChurningUsersTable/LastSeenCell";
import { GroupCell } from "./ChurningUsersTable/GroupCell";
import { ActivityCell } from "./ChurningUsersTable/ActivityCell";
import { ChurningUsersList } from "./ChurningUsersList";
import { isUserReportLevel } from "../../utils";

export interface IRetentionInsightUser {
  userId?: string;
  groupId?: string;
  groupType?: number;
  cohortDate: string;
  totalEvents: number;
  lastEventDate: string;
  numberOfActiveIntervals: number;
  threeMonthWeeklyActivity: [string, number][];
  intervalType: IntervalTypeNames;
  traits: { [key: string]: string };
}

export interface IChurningUsersData {
  contacts?: IRetentionInsightUser[];
}

const mockData = {
  contacts: [
    {
      userId: "Josephine Edamon",
      cohortDate: "2022-05-01",
      totalEvents: "10",
      lastEventDate: "2022-05-01",
      numberOfActiveIntervals: 16,
      numberOfActiveIntervalsLastPeriod: 2,
      threeMonthWeeklyActivity: [
        ["2022-03-01", 10],
        ["2022-04-01", 6],
        ["2022-05-01", 7],
      ],
      intervalType: "week",
    },
    {
      userId: "Roy Carpent",
      cohortDate: "2022-05-01",
      totalEvents: "10",
      lastEventDate: "2022-05-01",
      numberOfActiveIntervals: 10,
      numberOfActiveIntervalsLastPeriod: 2,
      threeMonthWeeklyActivity: [
        ["2022-03-01", 10],
        ["2022-04-01", 6],
        ["2022-05-01", 7],
      ],
      intervalType: "week",
    },
    {
      userId: "Anita James",
      cohortDate: "2022-05-01",
      totalEvents: "10",
      lastEventDate: "2022-05-01",
      numberOfActiveIntervals: 12,
      numberOfActiveIntervalsLastPeriod: 2,
      threeMonthWeeklyActivity: [
        ["2022-03-01", 10],
        ["2022-04-01", 6],
        ["2022-05-01", 7],
      ],
      intervalType: "week",
    },
    {
      userId: "Alice Terpantine",
      cohortDate: "2022-05-01",
      totalEvents: "10",
      lastEventDate: "2022-05-01",
      numberOfActiveIntervals: 10,
      numberOfActiveIntervalsLastPeriod: 2,
      threeMonthWeeklyActivity: [
        ["2022-03-01", 10],
        ["2022-04-01", 6],
        ["2022-05-01", 7],
      ],
      intervalType: "week",
    },
    {
      userId: "Gus Edams",
      cohortDate: "2022-05-01",
      totalEvents: "10",
      lastEventDate: "2022-05-01",
      numberOfActiveIntervals: 10,
      numberOfActiveIntervalsLastPeriod: 2,
      threeMonthWeeklyActivity: [
        ["2022-03-01", 10],
        ["2022-04-01", 6],
        ["2022-05-01", 7],
      ],
      intervalType: "week",
    },
  ],
};

export const listConfig = ({
  previewMode,
  sharingMode,
  appObjectSingularName,
  isUserReport,
}: {
  previewMode: boolean;
  sharingMode: boolean;
  appObjectSingularName: string;
  isUserReport: boolean;
}) =>
  ({
    resourceName: "users",
    columns: [
      {
        title: appObjectSingularName,
        name: appObjectSingularName.toLowerCase(),
        Component: isUserReport ? UserCell : GroupCell,
        width: 400,
        align: "left",
        previewMode: previewMode,
        sharingMode: sharingMode,
      },
      {
        title: "Activity",
        name: "activity",
        Component: ActivityCell,
        width: 200,
        align: "left",
        additionalProps: { userType: "churning" },
        previewMode: previewMode,
        sharingMode: sharingMode,
      },
      {
        title: "Total events",
        name: "totalEvents",
        Component: TotalEventsCell,
        width: 200,
        align: "left",
        previewMode: previewMode,
        sharingMode: sharingMode,
      },
      {
        title: "Used over",
        name: "numberOfActiveIntervals",
        Component: UsedOverCell,
        width: 200,
        align: "left",
        previewMode: previewMode,
        sharingMode: sharingMode,
      },
      {
        title: "Last seen",
        name: "lastSeen",
        Component: LastSeenCell,
        width: 200,
        align: "left",
        previewMode: previewMode,
        sharingMode: sharingMode,
      },
    ],
  }) as IListConfig;

export const ChurningUsersContainer: React.FC<IReportInsight> = ({
  previewMode,
  sharingMode,
  screenshotMode,
  sharingSecretToken,
  report,
  config,
  reportLevel,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isUserReport = isUserReportLevel(reportLevel);
  const { activeAppObject } = useAppObjects();
  const { reportId } = useParams<{ reportId: string }>() as {
    reportId: string;
  };
  const unit = isUserReport ? "users" : "groups";
  const insight = config.insights.find(
    ({ slug }: { slug: string }) => slug === `churning-${unit}`,
  ) as IInsightSection;
  const isSetupValid = config.validateSetup(report.config);
  const { response, isLoading, isFetching, error, onRefreshInsight } =
    useInsightDataFetching({
      insight,
      report,
      insightParams: { truncate: true },
      sharingSecretToken,
      previewMode,
      skip: !isSetupValid,
    });

  const { requestCsvExport, csvExportLoading } = useInsightCsvExport({
    report,
    insightId: insight.typeId,
    insightParams: {},
    count: 1, // hard coded to 1 because we don't know count beforehand
  });

  const data = previewMode ? mockData : response?.data;

  const onViewButtonClick = () => {
    if (!sharingMode && !previewMode) {
      onOpen();
    }
  };

  const noData = data?.contacts?.length === 0;

  return (
    <>
      <InsightCard>
        {(Card) => (
          <Card.Container insight={insight} id={insight.slug}>
            <Card.Header
              hasCaching={response?.hasCaching}
              screenshotMode={screenshotMode}
              showReportTitle={sharingMode}
              insight={insight}
              showActions={!sharingMode && !previewMode}
              sharingMode={sharingMode}
              config={config}
              report={report}
              refresh={onRefreshInsight}
              cachedAt={response?.cachedAt}
            />
            <Card.Body
              isLoading={!data || isLoading || isFetching}
              isPreviewMode={previewMode}
              isSetupValid={isSetupValid}
              error={error}
            >
              {noData && !isLoading ? (
                <Card.EmptyState
                  IconComponent={HandThumbUpIcon}
                  title={`Great news!`}
                  description={`There are no ${activeAppObject?.pluralName.toLocaleLowerCase()} at risk of churn`}
                />
              ) : (
                <List
                  id="loaded"
                  config={listConfig({
                    previewMode,
                    sharingMode,
                    appObjectSingularName: activeAppObject?.singularName || "",
                    isUserReport,
                  })}
                  rows={previewMode ? mockData.contacts : data?.contacts}
                  border={0}
                  borderRadius={0}
                />
              )}
            </Card.Body>
            {!sharingMode && !previewMode && !noData && !isLoading && (
              <Card.Footer topBorder={false}>
                <div className="flex w-full flex-col">
                  <div className="flex w-full px-4 pt-4">
                    <div className="flex w-full items-center justify-between gap-2 rounded-lg bg-gray-50 p-4">
                      <div className="w-2/3 text-sm">
                        These {activeAppObject?.pluralName.toLowerCase()} were
                        retained users but have been inactive for the last 2
                        weeks. Look at their profiles and reach out to
                        understand what's going on.
                      </div>
                      <Button
                        className="w-3/20 rounded-lg"
                        onClick={onViewButtonClick}
                      >
                        <div className="p-4 text-sm font-semibold">
                          View {activeAppObject?.pluralName}
                        </div>
                      </Button>
                    </div>
                  </div>
                  <InsightReactions
                    reactions={response?.reactions}
                    insightId={insight.typeId}
                    reportId={Number(reportId)}
                  />
                </div>
              </Card.Footer>
            )}
          </Card.Container>
        )}
      </InsightCard>
      {!sharingMode && !previewMode && insight && (
        <ChurningUsersList
          report={report}
          insight={insight}
          isUserReport={isUserReport}
          isOpen={isOpen}
          onClose={onClose}
          csvExportLoading={csvExportLoading}
          requestCsvExport={requestCsvExport}
        />
      )}
    </>
  );
};
