import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { useCurrentApp } from "core/hooks/useCurrentApp";

export const Issues: React.FC = () => {
  const { hasImpersonation, receivingGroupContext } = useCurrentApp();
  return (
    <div className="flex flex-col">
      {!hasImpersonation && receivingGroupContext && (
        <p className="mt-3 text-sm text-gray-500">No issues found</p>
      )}
      {hasImpersonation && (
        <div className="flex flex-row space-x-3 border-b py-3">
          <div>
            <ExclamationCircleIcon className="h-4 text-gray-500" />
          </div>
          <div>
            <p className="text-sm text-gray-600">
              Some users belong to multiple companies. Are you impersonating
              your users?
            </p>
            <a
              className="text-sm text-purple-500"
              target="_blank"
              href="https://help.june.so/en/articles/7188058-how-to-fix-users-belonging-to-multiple-companies"
              rel="noreferrer"
            >
              Learn more
            </a>
          </div>
        </div>
      )}
      {!receivingGroupContext && (
        <div className="flex flex-row space-x-3 border-b py-3">
          <div>
            <ExclamationCircleIcon className="h-4 text-gray-500" />
          </div>
          <div>
            <p className="text-sm text-gray-600">
              Looks like you're not tying events performed by a user to a
              specific company. Make sure to implement the group context.
            </p>
            <a
              className="text-sm text-purple-500"
              target="_blank"
              href="https://www.june.so/docs/quickstart/track-event"
              rel="noreferrer"
            >
              Learn more
            </a>
          </div>
        </div>
      )}
    </div>
  );
};
