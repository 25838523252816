import { Flex } from "@chakra-ui/react";
import ReportActionsMenu from "./ReportActionsMenu";
import { IReport } from "core/types/Report";

interface IReportNameActionsCellProps {
  props: IReport;
  mutate: () => void;
}

const ReportActionsCell: React.FC<IReportNameActionsCellProps> = ({
  props: report,
  mutate,
}) => {
  return (
    <Flex>
      <ReportActionsMenu report={report} mutate={mutate} />
    </Flex>
  );
};

export default ReportActionsCell;
