import React, { useEffect, useState } from "react";
import { useInsightCsvExport } from "core/hooks/useInsightCsvExport";
import { useContactDrilldown } from "core/hooks/useContactDrilldown";
import {
  generateColorForTraitValueWithIndex,
  getTraitValue,
} from "core/design-system/charts/Breakdown/utils";
import { ToggleButton } from "core/components/Toggle";
import Error from "core/components/Error";
import { Drilldown } from "core/components/Drilldown";
import { UserListSingleContact } from "core/components/Contact/UserListSingleContact";
import { Flex } from "@chakra-ui/react";
import { FunnelBreakdownTraitSelect } from "./FunnelBreakdownTraitSelect";
import { IReport } from "core/types/Report";

interface IFunnelUsersProps {
  report: IReport;
  convertedContactCount: number;
  droppedContactCount: number;
  eventIndex: number;
  humanizedEventName: string;
  isOpen: boolean;
  onClose: () => void;
  showConverted: boolean;
  traitValues?: string[];
}

export const FunnelUsers: React.FC<IFunnelUsersProps> = ({
  report,
  convertedContactCount,
  droppedContactCount,
  eventIndex,
  humanizedEventName,
  isOpen,
  onClose,
  showConverted = true,
  traitValues,
}) => {
  const [converted, setConverted] = useState(showConverted);
  const _traitValues = traitValues || [];
  const [selectedTraitValues, setSelectedTraitValues] = useState<string[]>([]);
  const contactCount = converted ? convertedContactCount : droppedContactCount;
  const contactAction = converted ? "performed" : "dropped off before";

  const {
    contacts,
    isLoading,
    error,
    hasMoreContacts,
    loadMore,
    searchQuery,
    setSearchQuery,
    onSearch,
    setPage,
  } = useContactDrilldown({
    report,
    insightType: 1,
    skip: !isOpen,
    insightParams: {
      eventIndex,
      converted,
      traitValues: selectedTraitValues,
    },
  });

  const { requestCsvExport, csvExportLoading } = useInsightCsvExport({
    report,
    insightId: 1,
    insightParams: {
      eventIndex,
      converted,
      traitValues: selectedTraitValues,
    },
    count: contactCount,
  });

  const handleToggle = (_converted: boolean) => {
    setConverted(_converted);
    setSearchQuery("");
    setPage(0);
  };

  const onDrilldownClose = () => {
    onClose();
    setConverted(true);
    setSearchQuery("");
    setPage(0);
  };

  useEffect(() => {
    setConverted(showConverted);
  }, [showConverted]);

  if (error) {
    return <Error />;
  }

  return (
    <Drilldown isOpen={isOpen} onClose={onDrilldownClose}>
      {(Drilldown) => (
        <>
          <Drilldown.Header>
            <Drilldown.Title
              isLoading={isLoading}
              title={`${contactCount} users ${contactAction} ${humanizedEventName}`}
            />
            <Drilldown.DownloadButton
              isDisabled={contactCount <= 0}
              isLoading={csvExportLoading}
              onClick={requestCsvExport}
            />
            <Drilldown.Toggle>
              <ToggleButton
                isDisabled={false}
                isSelected={converted}
                onToggle={() => handleToggle(true)}
              >
                Converted
              </ToggleButton>
              <ToggleButton
                isDisabled={false}
                isSelected={!converted}
                onToggle={() => {
                  handleToggle(false);
                }}
              >
                Dropped
              </ToggleButton>
            </Drilldown.Toggle>
            <Flex w="full" gap={2} align="center" justify="space-between">
              <Drilldown.Search
                placeholder="Search your users by email address..."
                searchQuery={searchQuery}
                onSearch={onSearch}
                disabled={contactCount <= 0}
              />
              {_traitValues.length > 0 && (
                <FunnelBreakdownTraitSelect
                  labels={_traitValues}
                  selectedLabels={selectedTraitValues}
                  setSelectedLabels={setSelectedTraitValues}
                />
              )}
            </Flex>
          </Drilldown.Header>
          <Drilldown.Body pt={4}>
            <Drilldown.List
              items={contacts}
              hasMore={hasMoreContacts}
              itemRenderer={({ item }) => (
                <UserListSingleContact
                  key={item.userId}
                  id={item.userId}
                  traits={item.traits}
                  isAnonymous={item.isAnonymous}
                  traitValue={getTraitValue(item.value)}
                  traitColor={generateColorForTraitValueWithIndex(
                    item.value,
                    _traitValues.length - _traitValues.indexOf(item.value) - 1,
                  )}
                />
              )}
              loadNext={loadMore}
              isLoading={isLoading}
              emptyStateText={`No users ${converted ? "converted" : "dropped"}`}
            />
          </Drilldown.Body>
        </>
      )}
    </Drilldown>
  );
};
