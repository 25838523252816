import React, { useState } from "react";
import { IReport } from "core/types/Report";
import { useUpdateReportNameMutation } from "core/models/reports";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

interface NameEditorModalProps {
  isOpen: boolean;
  toggle: () => void;
  report: IReport;
}

const NameEditorModal: React.FC<NameEditorModalProps> = ({
  isOpen,
  toggle,
  report,
}) => {
  const { id: appId } = useCurrentApp();
  const [name, setName] = useState(report.name);
  const [updateReportName, { error }] = useUpdateReportNameMutation();

  const handleChange = (event: any) => {
    setName(event.target.value);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    await updateReportName({
      appId,
      reportId: report.id,
      name,
      audience: report.audience,
    })
      .unwrap()
      .then(() => {
        toggle();
      })
      .catch(() => {
        console.log("api errors:", error);
      });
  };

  return (
    <Modal size="lg" isOpen={isOpen} onClose={toggle}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit report name</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            borderRadius="md"
            size={"sm"}
            width="100%"
            placeholder="Template name"
            type="text"
            name="templateName"
            defaultValue={report.name}
            onChange={handleChange}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleSubmit} colorScheme="purple">
            Rename
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NameEditorModal;
