import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

export const NoResultsFound: React.FC = () => {
  return (
    <>
      <div className="w-100 h-112 bg-red-500"></div>
      <div className="px-6 py-14 text-center text-sm sm:px-14">
        <ExclamationCircleIcon
          className="mx-auto h-6 w-6 text-gray-400"
          aria-hidden="true"
        />
        <p className="mt-4 font-semibold text-gray-900">No results found</p>
        <p className="mt-2 text-gray-500">
          We couldn’t find anything with that term. Please try again.
        </p>
      </div>
    </>
  );
};
