import { useDispatch, useSelector } from "react-redux";
import { IAudienceFilters } from "core/types/Audience";
import { IAlert, IUpdateAlert } from "core/types/Alert";
import {
  editedAlertSelector,
  resetEventAlert,
  setEventAlert,
} from "core/models/editEventAlert";
import { useUpdateEventAlertMutation } from "core/models/alerts";
import { useToast } from "core/hooks/useToast";
import { JoinOperatorValue } from "core/constants/report-setup";
import {
  FREQUENCIES,
  IAlertFrequencyEnum,
  IAlertStateEnum,
} from "core/constants/alert";
import { ThresholdType } from "modules/Events/List/EventAlertEditor";

export interface IOnSaveProps {
  newSetup: { [key: string]: any };
  newState: IAlertStateEnum;
  enrichmentEnabled: boolean;
  frequency: IAlertFrequencyEnum;
  slackChannelId: string | undefined;
  property: string | undefined;
  propertyComparisonOperator: string | undefined;
  propertyValue: string | undefined;
}

export const useEditEventAlert = (alert: IAlert) => {
  const editedAlert = useSelector(editedAlertSelector(alert.id)) || alert;
  const dispatch = useDispatch();
  const toast = useToast();
  const [updateEventAlert] = useUpdateEventAlertMutation();

  if (!editedAlert) {
    dispatch(setEventAlert(alert));
  }

  const {
    setup: newSetup,
    frequency: frequencyValue,
    enrichmentEnabled,
    slackChannelId,
    property,
    propertyValue,
    propertyComparisonOperator,
    joinOperator,
    threshold,
    thresholdType,
  } = editedAlert;
  const messageBody =
    newSetup && newSetup.messageBody ? newSetup.messageBody : "";

  const filterGroups =
    newSetup && newSetup.filterGroups && newSetup.filterGroups.length > 0
      ? newSetup.filterGroups
      : [];
  const audienceFilters = {
    filterGroups: filterGroups,
    joinOperator: joinOperator || JoinOperatorValue.AND,
  };

  const isSetupUpdated =
    JSON.stringify(alert.setup) !== JSON.stringify(newSetup) ||
    editedAlert.slackChannelId !== alert.slackChannelId ||
    editedAlert.frequency !== alert.frequency ||
    editedAlert.property !== alert.property ||
    editedAlert.propertyValue !== alert.propertyValue ||
    editedAlert.propertyComparisonOperator !==
      alert.propertyComparisonOperator ||
    editedAlert.joinOperator !== alert.joinOperator ||
    editedAlert.enrichmentEnabled !== alert.enrichmentEnabled ||
    editedAlert.threshold !== alert.threshold ||
    editedAlert.thresholdType !== alert.thresholdType;

  const frequency = FREQUENCIES.find((f) => f.value === frequencyValue);

  const save = ({ newState }: { newState: IAlertStateEnum }) => {
    const changes: IUpdateAlert = {
      appId: String(editedAlert.appId),
      state: newState,
      setup: editedAlert.setup,
      enrichmentEnabled: editedAlert.enrichmentEnabled,
      frequency: editedAlert.frequency,
      slackChannelId: editedAlert.slackChannelId,
      property: editedAlert.property || "",
      propertyValue: editedAlert.propertyValue,
      propertyComparisonOperator:
        editedAlert.propertyComparisonOperator || "is",
      joinOperator: editedAlert.joinOperator,
      threshold: editedAlert.threshold,
      thresholdType: editedAlert.thresholdType,
    };

    if (alert) {
      updateEventAlert({ id: alert.id, ...changes })
        .unwrap()
        .then(() => {
          toast({
            title: "Saved successfully",
            description: "Your slack notification was updated successfully.",
          });
        })
        .catch((e) => {
          const title = "Failed to update alert";
          const description = e?.data?.error;

          toast({ title, description, status: "error" });
        });
    }
  };

  const setFrequency = (frequency: IAlertFrequencyEnum) => {
    dispatch(setEventAlert({ ...editedAlert, frequency }));
  };

  const setThreshold = (threshold: number) => {
    dispatch(setEventAlert({ ...editedAlert, threshold }));
  };

  const setThresholdType = (thresholdType: ThresholdType) => {
    dispatch(setEventAlert({ ...editedAlert, thresholdType }));
  };

  const onChangeChannel = (slackChannelId: string) => {
    dispatch(setEventAlert({ ...editedAlert, slackChannelId }));
  };

  const onChangeMessageBody = (e: any) => {
    dispatch(
      setEventAlert({
        ...editedAlert,
        setup: { ...newSetup, messageBody: e.target.value },
      }),
    );
  };

  const onSetAudienceFilters = (audienceFilters: IAudienceFilters) => {
    dispatch(
      setEventAlert({
        ...editedAlert,
        setup: {
          ...newSetup,
          filterGroups: audienceFilters.filterGroups,
        },
        joinOperator: audienceFilters.joinOperator,
      }),
    );
  };

  const validateAudience = (audienceFilters: IAudienceFilters) => {
    return audienceFilters.filterGroups.some((filterGroup) => {
      return filterGroup.filters.some((filter) => {
        // If the event is the same as the alert, we don't want to allow the user to create a filter
        // that will always be true

        return filter.type === 1 && filter.body.eventId === alert.alertableId;
      });
    });
  };

  const hasInvalidAudience = validateAudience(audienceFilters);

  const onChangeEnrichment = (e: any) => {
    dispatch(
      setEventAlert({ ...editedAlert, enrichmentEnabled: e.target.checked }),
    );
  };

  const setMessageBody = (messageBody: string) => {
    dispatch(
      setEventAlert({ ...editedAlert, setup: { ...newSetup, messageBody } }),
    );
  };

  const setProperty = (property: string | undefined) => {
    dispatch(
      setEventAlert({ ...editedAlert, property, propertyValue: undefined }),
    );
  };

  const setPropertyValue = (propertyValue: string | undefined) => {
    dispatch(setEventAlert({ ...editedAlert, propertyValue }));
  };

  const setPropertyComparisonOperator = (
    propertyComparisonOperator: string | undefined,
  ) => {
    dispatch(setEventAlert({ ...editedAlert, propertyComparisonOperator }));
  };

  const resetChanges = () => {
    dispatch(resetEventAlert(alert));
  };

  const eventId = editedAlert.alertableId;

  return {
    newSetup,
    frequencyValue,
    enrichmentEnabled,
    slackChannelId,
    isSetupUpdated,
    messageBody,
    audienceFilters,
    frequency,
    threshold,
    thresholdType,
    onChangeChannel,
    onChangeMessageBody,
    onSetAudienceFilters,
    onChangeEnrichment,
    save,
    propertyComparisonOperator,
    joinOperator,
    setFrequency,
    setMessageBody,
    resetChanges,
    eventId,
    property,
    propertyValue,
    setProperty,
    setPropertyValue,
    setPropertyComparisonOperator,
    hasInvalidAudience,
    setThresholdType,
    setThreshold,
  };
};
