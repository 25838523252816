import { useNavigate } from "react-router";
import HelpCircle from "core/design-system/components/Icon/Interface/HelpCircle";
import Icon from "core/design-system/components/Icon";
import { Button, Flex, Spacer, Text, Tooltip } from "@chakra-ui/react";

export interface ITitleProps {
  title: string;
  appId: string | number;
  value?: string;
  reportId?: string | number;
  reportName?: string;
  level?: string;
  icon?: React.ReactNode;
  tooltipText?: string | React.ReactNode;
  Cta?: React.ReactNode;
  titleSize?: string;
  valueSize?: string;
}

export const Title: React.FC<ITitleProps> = ({
  title,
  appId,
  reportId,
  reportName,
  level = "user",
  value,
  icon = null,
  tooltipText,
  Cta = null,
  titleSize = "sm",
  valueSize = "3xl",
}) => {
  const navigate = useNavigate();

  return (
    <>
      <Flex data-testid="highlight-card-title" direction="column">
        <Text fontSize={titleSize} fontWeight="medium" overflow={"visible"}>
          {title}
          {tooltipText && (
            <Tooltip label={tooltipText} hasArrow placement="top">
              <HelpCircle h={4} w={4} color="purple.500" ml={1} />
            </Tooltip>
          )}
        </Text>
        <Flex align="center" gridGap={3}>
          {icon && <Flex>{icon}</Flex>}
          {value && (
            <Flex>
              <Text fontSize={valueSize} fontWeight="medium">
                {value}{" "}
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
      <Spacer />
      {(Cta && !(reportId && reportName) && <>{Cta}</>) ||
        (appId && reportId && reportName && (
          <Flex>
            <Tooltip label={reportName}>
              <Button
                data-testid="highlight-card-cta"
                variant="ghost"
                colorScheme="purple"
                rightIcon={<Icon iconType="icon" name="arrow-right-2" />}
                size="xs"
                onClick={() => {
                  // eslint-disable-next-line no-undef
                  window.analytics.track(
                    "report_opened_from_home",
                    {
                      app_id: appId,
                      report_id: reportId,
                    },
                    { context: { groupId: appId } },
                  );
                  navigate(`/a/${appId}/report/${reportId}?level=${level}`);
                }}
              >
                View
              </Button>
            </Tooltip>
          </Flex>
        ))}
    </>
  );
};
