import React from "react";
import { IReportInsight } from "core/types/Report";
import LineChart from "core/modules/reports/report-types/PowerUsers/LineChart";
import {
  INTERVAL_TYPES,
  POWER_USER_OPTIONS,
  POWER_USER_THREE_DAYS,
} from "core/constants/timerange";
import { LineContainer } from "core/components/LineContainer";
import { PowerUsersWeekOnWeekMockData } from "./mockData";

export const PowerUsersWeekOnWeekContainer: React.FC<IReportInsight> = ({
  report,
  config,
  ...props
}) => {
  const insight = config.insights.find(
    ({ slug }: { slug: string }) => slug === "power-users-week-on-week",
  );
  const initialTimeRange = localStorage.getItem(
    `${insight?.slug}_LastTimeRange`,
  )
    ? JSON.parse(localStorage.getItem(`${insight?.slug}_LastTimeRange`) || "")
    : POWER_USER_THREE_DAYS;

  if (!insight) return null;

  return (
    <LineContainer
      LineChart={LineChart}
      insight={insight}
      initialTimeRange={initialTimeRange}
      timeRangeOptions={POWER_USER_OPTIONS}
      intervalType={INTERVAL_TYPES.DAY}
      keyAccessor={"powerUsers"}
      mockData={PowerUsersWeekOnWeekMockData}
      config={config}
      unit={"users"}
      actions={null}
      report={report}
      {...props}
    />
  );
};
