import React from "react";
import { Avatar } from "@chakra-ui/react";

interface IData {
  id: string;
  displayName: string;
  avatar?: string;
  description?: string;
}

export const List: React.FC<{
  data: IData[];
}> = ({ data }) => {
  return (
    <div className="ml-6 flex w-full flex-col gap-3">
      {data?.map(
        ({ id, avatar, displayName, description }: IData, index: number) => {
          const name = displayName || id;
          return (
            <div className="flex w-full gap-2" key={index}>
              <div className="flex">
                <Avatar name={displayName} size="xs" src={avatar} />
              </div>
              <div className="flex flex-1 flex-col items-start overflow-hidden">
                <div className="w-[80%] truncate text-ellipsis whitespace-nowrap text-xs">
                  {name}
                </div>
                <div className="text-xs text-gray-600">{description}</div>
              </div>
            </div>
          );
        },
      )}
    </div>
  );
};
