import { SnakeCasedProperties } from "type-fest";
import humps from "humps";
import { IReport } from "core/types/Report";
import { IGroup } from "core/types/Group";
import { IContact } from "core/types/Contact";
import { baseQuery } from "core/initializers/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";

export interface ISearchResponse {
  companies: IGroup[];
  groups: IGroup[];
  contacts: IContact[];
  reports: IReport[];
}

export const searchApi = createApi({
  baseQuery,
  reducerPath: "searchApi",
  tagTypes: ["Search"],
  endpoints: (builder) => ({
    fetchSearchResult: builder.query({
      query: ({ query, appId }: { query: string; appId: number }) => ({
        url: "/search",
        params: humps.decamelizeKeys({ query, appId }),
      }),
      transformResponse: (
        response: SnakeCasedProperties<ISearchResponse>,
      ): SnakeCasedProperties<ISearchResponse> =>
        humps.camelizeKeys(response) as ISearchResponse,
    }),
  }),
});

export const { useFetchSearchResultQuery, useLazyFetchSearchResultQuery } =
  searchApi;
