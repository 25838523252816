import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  ImplementedStatus,
  useLazyGetImplementedStatusQuery,
} from "core/models/events";
import { NEW_ONBOARDING } from "core/constants/features";
import useFlag from "./useFlag";

const ONE_SECOND = 1000;
const MAX_RETRIES = 500;

export const useImplemented = ({
  event,
  skip,
}: {
  event: string;
  skip?: boolean;
}) => {
  const { appId } = useParams();
  const hasNewOnboardingEnabled: boolean | undefined = useFlag(NEW_ONBOARDING);
  const [getImplementedStatus, { data, isLoading }] =
    useLazyGetImplementedStatusQuery();
  const [refreshCount, setRefreshCount] = useState(0);

  useEffect(() => {
    if (
      !hasNewOnboardingEnabled ||
      skip ||
      (data?.isImplemented !== undefined && data?.isImplemented)
    )
      return;

    const pollingInterval = setInterval(() => {
      if (refreshCount < MAX_RETRIES) {
        setRefreshCount((prevCount) => prevCount + 1);
        getImplementedStatus({ appId: Number(appId), event });
      } else {
        clearInterval(pollingInterval);
      }
    }, ONE_SECOND);
    return () => clearInterval(pollingInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCount]);

  if (!hasNewOnboardingEnabled || skip)
    return {
      isImplemented: true,
      implementedStatus: {} as ImplementedStatus,
    };

  return {
    isImplemented:
      data?.isImplemented === undefined ? true : data?.isImplemented,
    implementedStatus: data?.implementedStatus.find(
      (s) => s.eventName === event,
    ),
    isLoading,
  };
};
