import React from "react";
import { IReportInsight } from "core/types/Report";
import { isUserReportLevel } from "core/modules/reports/utils";
import LineChart from "core/modules/reports/report-types/FeatureRelease/Users/UsersLineChart";
import { monthlyUsageMockData } from "core/modules/reports/report-types/FeatureRelease/mockData";
import { INTERVAL_TYPES, MAU_OPTIONS } from "core/constants/timerange";
import { LineContainer } from "core/components/LineContainer";

export const MonthlyUsersContainer: React.FC<IReportInsight> = ({
  config,
  report,
  reportLevel,
  ...props
}) => {
  const isUserReport = isUserReportLevel(reportLevel);
  const insightSlug = isUserReport ? "monthly-users" : "monthly-companies";
  const insight = config.insights.find(
    ({ slug }: { slug: string }) => slug === insightSlug,
  );

  if (!insight) return null;

  return (
    <LineContainer
      LineChart={LineChart}
      report={report}
      insight={insight}
      initialTimeRange={"THREE_MONTHS"}
      timeRangeOptions={MAU_OPTIONS}
      intervalType={INTERVAL_TYPES.WEEK}
      keyAccessor={"usageInTimeRange"}
      intervalPeriod={"THIRTY_DAYS"}
      mockData={monthlyUsageMockData}
      config={config}
      {...props}
    />
  );
};
