import React from "react";
import moment from "moment";
import { BoltIcon } from "@heroicons/react/24/outline";

interface ITraitProps {
  name: string;
  lastComputedAt?: string;
  description?: string;
}

export const ComputedTraitTooltip: React.FC<ITraitProps> = ({
  name,
  lastComputedAt,
  description,
}) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center">
        <BoltIcon className="mr-1 h-4" />
        <p className="text-sm">{name}</p>
      </div>
      {description && <p className="text-xs">{description}</p>}
      {lastComputedAt && (
        <div>
          <p className="text-xs text-gray-500">
            Last computed {moment.utc(lastComputedAt).fromNow()}
          </p>
        </div>
      )}
    </div>
  );
};
