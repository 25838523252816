let _window;
if (typeof window !== "undefined") {
  _window = window;
}
const queryParams = new URLSearchParams(_window?.location?.search);
export const animationQueryParam = queryParams.get(`animation`);
export const isAnimationParamPresent = !!queryParams.get(`animation`);
export const isAnimationDisabled = animationQueryParam === `false`;
export const isScreenshotMode = !!queryParams.get(`screenshot_mode`);
export function addLoadedIdToElement() {
  const elements = document.getElementsByClassName(
    `recharts-responsive-container`,
  );
  if (elements.length > 0 && elements[0]) {
    elements[0].id = "loaded";
  }
}
