import { useEffect, useState } from "react";
import axios from "axios";
import { useMountedState } from "./useMountedState";

const CLEARBIT_LOGO_URL = "https://logo.clearbit.com";

export const useClearbitLogo = ({
  domain,
  size,
}: {
  domain?: string;
  size?: number;
}) => {
  const isMounted = useMountedState();
  const [logoURL, setLogoURL] = useState("");

  useEffect(() => {
    if (domain) {
      axios
        .get(`${CLEARBIT_LOGO_URL}/${domain}${size ? `?size=${size}` : ""}`)
        .then((response) => {
          if (isMounted() && response.status === 200 && response.config?.url) {
            setLogoURL(response.config.url);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [domain, isMounted, size]);

  return logoURL;
};

export const useLazyClearbitLogo = () => {
  const isMounted = useMountedState();
  const [logoURL, setLogoURL] = useState("");
  const [domain, setDomain] = useState("");
  const [size, setSize] = useState<number | undefined>();

  const getClearbitLogo = ({
    domain: newDomain,
    size: newSize,
  }: {
    domain: string;
    size?: number;
  }) => {
    setDomain(newDomain);
    setSize(newSize);
  };

  useEffect(() => {
    if (domain) {
      axios
        .get(`${CLEARBIT_LOGO_URL}/${domain}${size ? `?size=${size}` : ""}`)
        .then((response) => {
          if (isMounted() && response.status === 200 && response.config?.url) {
            setLogoURL(response.config.url);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [domain, isMounted, size]);

  return { getClearbitLogo, data: logoURL };
};
