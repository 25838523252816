import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { Title } from "modules/ComputedTrait/Title";
import { Timerange } from "modules/ComputedTrait/Timerange";
import { Output } from "modules/ComputedTrait/Output";
import { Level } from "modules/ComputedTrait/Level";
import { Events } from "modules/ComputedTrait/Events";
import { Actions } from "modules/ComputedTrait/Actions";
import { ITraitWithConfig } from "core/types/Trait";
import useFlag from "core/hooks/useFlag";
import useEditSmartTrait from "core/hooks/useEditSmartTrait";
import { Breadcrumbs } from "core/design-system/components/Breadcrumbs";
import { SMART_TRAITS_MANUAL_COMPUTE } from "core/constants/features";
import { SmartTraitPreview } from "core/components/SmartTrait/SmartTraitPreview";
import { Button, ComponentDefaultProps, Divider } from "@chakra-ui/react";

export const ComputedTraitEditor: React.FC<
  {
    trait?: ITraitWithConfig;
    onSave: (trait: ITraitWithConfig) => void;
    onCompute?: () => void;
    onSetLive?: (isLive: boolean) => void;
    onDelete?: () => void;
    compact?: boolean;
  } & ComponentDefaultProps
> = ({ trait, onSave, onCompute, onDelete, onSetLive, compact, children }) => {
  const { appId } = useParams();
  const navigate = useNavigate();

  const {
    editableTrait,
    isDirty,
    onChangeTitle,
    onChangeDescription,
    onChangeLevel,
    onAddFilter,
    onRemoveFilter,
    onChangeFilter,
    onChangeComputationType,
    onChangeTimerange,
  } = useEditSmartTrait(trait);
  const canUseComputeButton = useFlag(SMART_TRAITS_MANUAL_COMPUTE);

  if (!editableTrait?.isEditable) {
    navigate(`/a/${appId}/settings/computed-traits`);
  }

  if (compact) {
    return (
      <div className="flex w-full flex-col p-5">
        <div className="flex w-full flex-col gap-y-2">
          <div className="flex items-center justify-between gap-2">
            <Title
              trait={editableTrait}
              onChangeTitle={onChangeTitle}
              onChangeDescription={onChangeDescription}
              compact={compact}
            />
            <div className="flex items-center gap-2">
              <Actions
                trait={editableTrait}
                isDirty={isDirty}
                onDelete={onDelete}
                onSetLive={onSetLive}
                onSave={onSave}
                onCompute={onCompute}
              />
              {children}
            </div>
          </div>
          <Divider my={1} />
          <div className="flex w-full gap-5">
            <div className="flex w-full flex-col">
              <div className="bg-white">
                <div className="flex flex-col gap-2">
                  <div className="mb-4 flex flex-col gap-1">
                    <p className="font-medium">Level</p>
                    <p className="text-sm text-gray-500">
                      Whether your trait will be added to users or companies.
                    </p>
                  </div>
                  <Level trait={editableTrait} onChangeLevel={onChangeLevel} />
                </div>
                <Divider my={5} />
                <div className="flex flex-col gap-2">
                  <div className="mb-4 flex flex-col gap-1">
                    <p className="font-medium">Output</p>
                    <p className="text-sm text-gray-500">
                      The type of output for your trait value
                    </p>
                  </div>
                  <Output
                    trait={editableTrait}
                    onChangeComputationType={onChangeComputationType}
                  />
                </div>
                <Divider my={5} />
                <div className="flex flex-col gap-2">
                  <div className="mb-4 flex flex-col gap-1">
                    <p className="font-medium">Events</p>
                    <p className="text-sm text-gray-500">
                      The event(s) that will be used to compute the output
                    </p>
                  </div>
                  <Events
                    trait={editableTrait}
                    onAddFilter={onAddFilter}
                    onChangeFilter={onChangeFilter}
                    onRemoveFilter={onRemoveFilter}
                  />
                </div>
                <Divider my={5} />
                <div className="flex flex-col gap-2">
                  <div className="mb-4 flex flex-col gap-1">
                    <p className="font-medium">Time range</p>
                    <p className="text-sm text-gray-500">
                      The time range for the computation
                    </p>
                  </div>
                  <Timerange
                    trait={editableTrait}
                    onChangeTimerange={onChangeTimerange}
                    isPaywalled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 flex items-center gap-2 text-xs">
          {trait && (
            <div className="flex flex-col text-gray-500">
              <p>
                {trait.lastComputedAt
                  ? `Last computed at ${moment
                      .utc(trait.lastComputedAt)
                      .format("DD MMM, YYYY [at] hh:mm a")} UTC`
                  : "Trait has not been computed yet"}
              </p>
            </div>
          )}
          {onCompute && canUseComputeButton && (
            <div className="flex">
              <Button
                disabled={isDirty}
                variant="ghost"
                colorScheme="purple"
                onClick={onCompute}
              >
                <div className="text-xs">Force compute</div>
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-col gap-y-5">
        <Breadcrumbs
          pages={[
            {
              name: "Computed traits",
              path: `/a/${appId}/settings/computed-traits`,
              current: false,
            },
            {
              name: editableTrait?.name || "New trait",
              path: `/a/${appId}/settings/computed-traits`,
              current: true,
            },
          ]}
        />
        <div className="flex items-center justify-between">
          <Title
            trait={editableTrait}
            onChangeTitle={onChangeTitle}
            onChangeDescription={onChangeDescription}
          />
          <Actions
            trait={editableTrait}
            isDirty={isDirty}
            onDelete={onDelete}
            onSetLive={onSetLive}
            onSave={onSave}
            onCompute={onCompute}
          />
        </div>
        <div className="flex w-full gap-5">
          <div className="flex w-[60%] flex-col">
            <div className="rounded-lg border border-gray-100 bg-white p-6 shadow-sm">
              <div className="flex flex-col gap-2">
                <div className="mb-4 flex flex-col gap-1">
                  <p className="font-medium">Level</p>
                  <p className="text-sm text-gray-500">
                    Whether your trait will be added to users or companies.
                  </p>
                </div>
                <Level trait={editableTrait} onChangeLevel={onChangeLevel} />
              </div>
              <Divider my={5} />
              <div className="flex flex-col gap-2">
                <div className="mb-4 flex flex-col gap-1">
                  <p className="font-medium">Output</p>
                  <p className="text-sm text-gray-500">
                    The type of output for your trait value
                  </p>
                </div>
                <Output
                  trait={editableTrait}
                  onChangeComputationType={onChangeComputationType}
                />
              </div>
              <Divider my={5} />
              <div className="flex flex-col gap-2">
                <div className="mb-4 flex flex-col gap-1">
                  <p className="font-medium">Events</p>
                  <p className="text-sm text-gray-500">
                    The event(s) that will be used to compute the output
                  </p>
                </div>
                <Events
                  trait={editableTrait}
                  onAddFilter={onAddFilter}
                  onChangeFilter={onChangeFilter}
                  onRemoveFilter={onRemoveFilter}
                />
              </div>
              <Divider my={5} />
              <div className="flex flex-col gap-2">
                <div className="mb-4 flex flex-col gap-1">
                  <p className="font-medium">Time range</p>
                  <p className="text-sm text-gray-500">
                    The time range for the computation
                  </p>
                </div>
                <Timerange
                  trait={editableTrait}
                  onChangeTimerange={onChangeTimerange}
                  isPaywalled
                />
              </div>
            </div>
          </div>
          <div>
            <SmartTraitPreview
              key={JSON.stringify(editableTrait)}
              level={editableTrait.level}
              name={editableTrait.name}
              trait={editableTrait}
            />
          </div>
        </div>
      </div>
      <div className="mt-5 flex items-center gap-2 text-xs">
        {trait && (
          <div className="flex flex-col text-gray-500">
            <p>
              {trait.lastComputedAt
                ? `Last computed at ${moment
                    .utc(trait.lastComputedAt)
                    .format("DD MMM, YYYY [at] hh:mm a")} UTC`
                : "Trait has not been computed yet"}
            </p>
          </div>
        )}
        {onCompute && canUseComputeButton && (
          <div className="flex">
            <Button
              disabled={isDirty}
              variant="ghost"
              colorScheme="purple"
              onClick={onCompute}
            >
              <div className="text-xs">Force compute</div>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
