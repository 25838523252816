import { useParams } from "react-router-dom";
import { useEffect } from "react";
import ContactEventLog from "modules/Contacts/ContactEventLog";
import { UnidentifiedUserDescription } from "modules/Contacts/Contact/UnidentifiedUserDescription";
import { Milestones } from "modules/Contacts/Contact/Milestones";
import Header from "modules/Contacts/Contact/Header";
import { EnrichmentCard } from "modules/Contacts/Contact/EnrichmentCard";
import { ContactPages } from "modules/Contacts/Contact/ContactPages";
import { ContactMostTriggeredEvents } from "modules/Contacts/Contact/ContactMostTriggeredEvents";
import { ContactAISummary } from "modules/Contacts/Contact/ContactAISummary";
import { ContactActivity } from "modules/Contacts/Contact/ContactActivity";
import { Companies } from "modules/Contacts/Contact/Companies";
import { ITrait } from "core/types/Trait";
import { useRecentlyViewedPeople } from "core/hooks/useRecentlyViewedPeople";
import useFlag from "core/hooks/useFlag";
import { useContact } from "core/hooks/useContact";
import { useAppObjects } from "core/hooks/useAppObjects";
import { getVisibleName } from "core/helpers/contacts/getVisibleName";
import { getContactEmail } from "core/helpers/contactEmail";
import {
  StyledTab,
  StyledTabList,
  StyledTabPanel,
  StyledTabs,
} from "core/design-system/components/Tabs";
import { Breadcrumbs } from "core/design-system/components/Breadcrumbs";
import { TRAIT_LEVEL } from "core/constants/traits";
import { AI_SUMMARY } from "core/constants/features";
import WarningBanner from "core/components/WarningBanner";
import TraitsContainer from "core/components/Traits/Container";
import { PageContainer } from "core/components/PageContainer";
import { Box, Flex, Skeleton, Stack, TabPanels } from "@chakra-ui/react";

function Contact() {
  const { appId, contactId: encodedContactId } = useParams<{
    appId: string;
    contactId: string;
  }>() as {
    appId: string;
    contactId: string;
  };
  const id = decodeURIComponent(encodedContactId);

  const {
    contact,
    fetchContact,
    isLoadingContact,
    contactError,
    contactGroups,
    contactCompanies,
    isLoadingContactGroups,
    isLoadingContactCompanies,
    fetchContactGroups,
    fetchContactCompanies,
  } = useContact({ appId, id });

  const { save } = useRecentlyViewedPeople();

  useEffect(() => {
    fetchContact();
    fetchContactGroups();
    fetchContactCompanies();
  }, [fetchContact, fetchContactGroups, fetchContactCompanies]);

  const hasGroups = Boolean(contactGroups && contactGroups.groups.length > 0);
  const hasCompanies = Boolean(
    contactCompanies && contactCompanies?.companies?.length > 0,
  );
  const { userAppObject, groupAppObject, companyAppObject } = useAppObjects();
  const hasAISummary = useFlag(AI_SUMMARY);
  contact && userAppObject && save(appId, contact, userAppObject.objectType);

  const pages = [
    {
      name: userAppObject?.pluralName || "Users",
      path: `/a/${appId}/people`,
      current: false,
    },
    {
      name: getVisibleName({ contactError, contact }) ?? "Unknown",
      path: `/a/${appId}/contact/${encodedContactId}`,
      current: true,
    },
  ];

  return (
    <PageContainer>
      <div className="mb-6">
        <Breadcrumbs pages={pages} />
      </div>
      <Flex direction="column">
        <Box w="94%">
          {contactError && (
            <WarningBanner
              title={`This user was not identified yet`}
              description={<UnidentifiedUserDescription contactId={id} />}
            />
          )}
        </Box>
        <Flex w="100%" direction="row">
          <Stack minW="340px" w="30%" pb={10} gap={4}>
            {isLoadingContact && !contactError && (
              <Skeleton borderRadius="lg" height="300px" w="100%" />
            )}
            {!isLoadingContact && contact && contact.id && (
              <Header
                contactId={id}
                contact={contact}
                contactError={contactError}
              />
            )}
            {id && hasAISummary && <ContactAISummary contactId={id} />}
            {isLoadingContact && !contactError ? (
              <Skeleton borderRadius="lg" height="300px" width="100%" />
            ) : (
              <TraitsContainer
                id={id}
                traitLevel={TRAIT_LEVEL.USER}
                traitKeyValues={contact?.traits as ITrait[]}
                showError={Boolean(contactError)}
              />
            )}
            {isLoadingContact && !contactError ? (
              <Skeleton borderRadius="lg" height="300px" width="100%" />
            ) : (
              <>
                {contact && <EnrichmentCard email={getContactEmail(contact)} />}
              </>
            )}
            {companyAppObject?.isEnabled && (
              <>
                {isLoadingContactCompanies ? (
                  <Skeleton borderRadius="lg" height="200px" width="100%" />
                ) : (
                  <>
                    {hasCompanies && (
                      <Companies
                        groups={contactCompanies.companies}
                        name={companyAppObject?.pluralName || "Companies"}
                        slug={companyAppObject?.slug}
                      />
                    )}
                  </>
                )}
              </>
            )}
            {isLoadingContactGroups ? (
              <Skeleton borderRadius="lg" height="200px" width="100%" />
            ) : (
              <>
                {hasGroups && groupAppObject?.isEnabled && (
                  <Companies
                    groups={contactGroups.groups}
                    name={groupAppObject?.pluralName || "Groups"}
                    slug={groupAppObject?.slug}
                  />
                )}
              </>
            )}
            {contact && <Milestones contactId={contact.id} />}
          </Stack>
          <Box pb={10} w="60%" mb={10} ml={10}>
            <StyledTabs defaultIndex={0}>
              <StyledTabList>
                <StyledTab>Overview</StyledTab>
                <StyledTab>Feed</StyledTab>
              </StyledTabList>
              <TabPanels>
                <StyledTabPanel>
                  <ContactActivity />
                  <ContactMostTriggeredEvents />
                  <ContactPages mt={6} />
                </StyledTabPanel>
                <StyledTabPanel>
                  {contact && (
                    <ContactEventLog
                      appId={Number(appId)}
                      contactId={contact.id}
                    />
                  )}
                </StyledTabPanel>
              </TabPanels>
            </StyledTabs>
          </Box>
        </Flex>
      </Flex>
    </PageContainer>
  );
}

export default Contact;
