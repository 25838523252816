import { useCallback } from "react";
import {
  IFavourite,
  useCreateFavouriteMutation,
  useDeleteFavouriteMutation,
  useGetFavouritesQuery,
} from "core/models/favourites";
import { useToast } from "core/hooks/useToast";
import { FAVOURITES } from "core/constants/features";
import useFlag from "./useFlag";

export const useFavourites = (appId: number) => {
  const { data: favourites, isLoading } = useGetFavouritesQuery({ appId });
  const [createFavourite] = useCreateFavouriteMutation();
  const [deleteFavourite, { isLoading: isDeleting }] =
    useDeleteFavouriteMutation();
  const hasFavouritesEnabled = useFlag(FAVOURITES);
  const toast = useToast();

  const onCreateFavourite = useCallback(
    (favourite: Pick<IFavourite, "objectId" | "objectType">) => {
      createFavourite({ ...favourite, appId })
        .unwrap()
        .then(() => {
          toast({
            title: "Added to favourites",
            status: "success",
          });
        })
        .catch((err) => {
          toast({
            title: "Failed to add to favourites",
            description: err?.data?.error,
            status: "error",
          });
        });
    },
    [createFavourite, appId, toast],
  );

  const onDeleteFavourite = useCallback(
    (favourite: IFavourite) => {
      deleteFavourite({
        id: favourite.id,
        appId,
        objectId: favourite.objectId,
        objectType: favourite.objectType,
      })
        .unwrap()
        .then(() => {
          toast({
            title: "Removed from favourites",
            status: "success",
          });
        })
        .catch((err) => {
          toast({
            title: "Failed to remove from favourites",
            description: err?.data?.error,
            status: "error",
          });
        });
    },
    [deleteFavourite, appId, toast],
  );

  return {
    favourites,
    isLoading,
    isDeleting,
    onCreateFavourite,
    onDeleteFavourite,
    hasFavouritesEnabled,
  };
};
