import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { IQuery } from "core/types/Query";
import colors from "core/design-system/constants/theme/colors";
import { Box, Button } from "@chakra-ui/react";

const CodeEditor = React.lazy(() => import("@uiw/react-textarea-code-editor"));

export const SQLEditor: React.FC<{
  query: IQuery;
  executeQuery: any;
  updateQuery: any;
}> = ({ query, executeQuery, updateQuery }) => {
  const [sqlQuery, setSqlQuery] = useState("");
  const { appId } = useParams();

  function onUpdateSql() {
    if (query.id)
      updateQuery({ appId: Number(appId), queryId: query.id, query: sqlQuery });
  }

  useEffect(() => {
    if (query?.query) {
      setSqlQuery(query.query);
    }
  }, [query?.query]);

  return (
    <Box
      position="relative"
      w="full"
      borderBottom="1px solid"
      borderColor="gray.300"
    >
      <CodeEditor
        value={sqlQuery}
        language="sql"
        placeholder="Write some SQL..."
        onChange={(e) => setSqlQuery(e.target.value)}
        data-color-mode="light"
        padding={15}
        style={{
          borderRadius: "0px",
          width: "100%",
          fontSize: 12,
          backgroundColor: colors.gray[50],
          fontFamily:
            "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
        }}
      />
      <Button
        position="absolute"
        right="10px"
        bottom="10px"
        colorScheme="purple"
        variant="ghost"
        onClick={() => {
          onUpdateSql();
          executeQuery({ appId: Number(appId), query: sqlQuery, page: 1 });
        }}
      >
        Run
      </Button>
    </Box>
  );
};
