import { Link } from "react-router-dom";
import { ITrait } from "core/types/Trait";
import { useClearbitLogo } from "core/hooks/useClearbitLogo";
import { isValidUrl } from "core/helpers/isValidUrl";
import { avatarFromTraits } from "core/helpers/contactAvatar";
import { getCompanyName } from "core/helpers/companyName";
import CompanyAvatar from "core/components/Avatars/CompanyAvatar";
import { Tooltip } from "@chakra-ui/react";

export interface ICompanyNameCellProps {
  props: {
    appId?: string | number;
    id: string;
    name?: string;
    traits: ITrait;
  };
}

function CompanyNameCell({
  props: { appId, id, name, traits },
}: ICompanyNameCellProps) {
  const companyName = getCompanyName({ name, traits });
  const avatar = avatarFromTraits(traits);
  const logoURL = useClearbitLogo({ domain: id });
  const url = isValidUrl(avatar) ? avatar : logoURL;

  return (
    <Link to={`/a/${appId}/company/${id}/`}>
      <div className="flex w-full items-center gap-2">
        <div className="min-w-[30px]">
          <CompanyAvatar size="sm" group={{ id }} logoURL={url} />
        </div>
        <Tooltip label={companyName}>
          <p className="truncate text-sm">{companyName}</p>
        </Tooltip>
      </div>
    </Link>
  );
}

export default CompanyNameCell;
