export const mockData = {
  status: 200,
  data: {
    startDate: "2022-03-24",
    endDate: "2022-04-30",
    overallConversionPercentage: 60,
    eventBars: [
      {
        name: "reports_opened",
        index: 0,
        convertedCount: 38,
        droppedCount: 0,
        conversionPercentage: 100,
      },
      {
        name: "templates_opened",
        index: 1,
        convertedCount: 23,
        droppedCount: 15,
        conversionPercentage: 60,
      },
    ],
  },
};
