export const strugglingMockData = {
  contacts: [
    {
      userId: "d5edc3c7-7fb3-443f-93ae-sad2344cd412",
      traits: {
        name: "Johnass-Scallio",
        connected_segment: "false",
      },
      milestonesCompleted: "3",
      firstEventTimestamp: "2022-06-11 10:36:57.000000",
    },
    {
      userId: "d5edc3c7-7fb3-443f-93ae-11a5e5c33f28",
      traits: {
        name: "Beatty-Carter",
        connected_segment: "false",
      },
      milestonesCompleted: "3",
      firstEventTimestamp: "2022-06-21 10:36:57.000000",
    },
    {
      userId: "2da9e5e2-b201-4a5c-b803-95a4503f09d8",
      traits: {
        name: "Shanahan and Sons",
        connected_segment: "true",
      },
      milestonesCompleted: "3",
      firstEventTimestamp: "2022-06-25 10:36:57.000000",
    },
    {
      userId: "92e6d314-bc76-42bd-b6ee-4a1cae73b0bb",
      traits: {
        connected_segment: "true",
        name: "Corwin Group",
      },
      milestonesCompleted: "3",
      firstEventTimestamp: "2022-06-25 10:36:57.000000",
    },
    {
      userId: "31de1f37-798d-4792-8bb9-cd96374fb3f7",
      traits: {
        name: "Hills-Stracke",
        connected_segment: "true",
      },
      milestonesCompleted: "3",
      firstEventTimestamp: "2022-06-29 10:36:57.000000",
    },
  ],
};
