import React, { useState } from "react";

export const useTooltipCoordinate = (offsetY = -120) => {
  const [coordinates, setCoordinates] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  function onMouseMove(e: React.MouseEvent) {
    // @ts-ignore
    var frame = e?.target?.getBoundingClientRect();
    var x =
      // @ts-ignore
      e.target?.outerHTML?.includes(`recharts-surface`) ||
      // @ts-ignore
      e.target?.outerHTML?.includes(`recharts-area`)
        ? e.clientX - frame.left - 80
        : coordinates.x;
    setCoordinates({ x, y: offsetY });
  }

  return {
    coordinates,
    onMouseMove,
  };
};
