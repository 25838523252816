const rawWidgetScript = `
// Variables used by Scriptable.
// These must be at the very top of the file. Do not edit.
// icon-color: deep-gray; icon-glyph: magic;
const REPORT_URL = null;

// Utility functions to process data.
const processVal = val => (
  typeof val !== 'object'
  ? val
  : Array.isArray(val)
    ? val.map(renameKeys)
    : renameKeys(val)
);

const renameKeys = obj => {
  const removedEmptyValuesObj = Object.keys(obj).reduce((newObj, key) => {
    if (obj[key]) {
      newObj[key] = obj[key]
    }

    return newObj;
  }, {})
  return Object.fromEntries(
    Object.entries(removedEmptyValuesObj)
      .map(([key, val]) => [
        key.replace(/_(.)/g, g =>  g[1].toUpperCase()),
        processVal(val)
      ])
  )
};


function normalizeInsights(insights) {
  if (!insights) return null;

  return insights.map((insight) => {
    let normalized = {
      // ID must be an index in report_insights.rb
      id: 0,
      value: 0,
      reportId: 0,
    };

    if (
      Array.isArray(insight?.data) &&
      insight.data.length > 0 &&
      insight.data[insight.data.length - 1]?.activeUsers !== undefined
    ) {
      normalized = {
        value: insight.data[insight.data.length - 1].activeUsers,
      };
    } else if (
      Array.isArray(insight?.data) &&
      insight.data.length > 0 &&
      insight.data[insight.data.length - 1]?.activeGroups !== undefined
    ) {
      normalized = {
        value: insight.data[insight.data.length - 1].activeGroups,
      };
    }

    let title = 0
    switch (insight.insightId) {
      case 0:
        title = "DAU"
        break;
      case 1:
        title = "WAU"
        break;
      case 2:
        title = "MAU"
        break;
      case 9:
        title = "DAC"
        break;
      case 10:
        title = "WAC"
        break;
      case 11:
        title = "MAC"
        break;
      default:
        title = "DAU"
     };

    return {
      ...normalized,
      reportName: insight.reportName,
      insightChange: insight.insightChange,
      reportId: insight.reportId,
      title: title,
      id: insight.insightId,
    };
  });
}

async function loadIcon() {
  let icon =
    "https://analytics.june.so/logo192.png";
  return new Request(icon).loadImage();
}

async function loadData() {
  let req = new Request(REPORT_URL);
  req.method = "POST";

  return req.loadJSON();
}

function formatNumber(num) {
  return num <= 999 ? num : (0.1 * Math.floor(num / 100)).toFixed(1).replace('.0','') + 'K'
}

/**
* Initializes the widget.
* Configuration for app icons, etc goes here.
*/
async function createWidget() {
let widget = null;

let primaryColor = new Color("#6868F7");

try {
  widget = new ListWidget();
  widget.backgroundColor = Color.dynamic(Color.white(), new Color("#121212"));
  let resp = await loadData();
  const reportNameResp = resp.report_name ?? "Report";
  data = renameKeys(resp["data"]);
  data = normalizeInsights([data])[0];
  let appIcon = await loadIcon();
  let titleStack = widget.addStack();
  let titleElement = titleStack.addText(data.title);
  titleElement.textColor = Color.dynamic(primaryColor, Color.white());
  titleElement.textOpacity = 0.7;
  titleElement.font = Font.regularSystemFont(16);
  titleStack.centerAlignContent();

  titleStack.addSpacer();
  let appIconElement = titleStack.addImage(appIcon);
  appIconElement.imageSize = new Size(20, 20);
  appIconElement.cornerRadius = 4;

  let nameElement = widget.addText(formatNumber(data.value.toString()));
  nameElement.textColor = Color.dynamic(Color.black(), Color.white());
  nameElement.font = Font.lightSystemFont(42);

  widget.addSpacer();
  let reportStack = widget.addStack();
  reportStack.centerAlignContent();
  reportStack.addSpacer(3);

  let fauxBorder = reportStack.addStack();
  fauxBorder.layoutVertically();
  fauxBorder.backgroundColor = primaryColor;
  fauxBorder.borderWidth = 2;
  fauxBorder.borderColor = Color.dynamic(Color.white(), new Color("#121212"));
  fauxBorder.cornerRadius = 5;
  fauxBorder.addText(" ")

  if (reportNameResp.length > 20 && config.widgetFamily == "small") {
    fauxBorder.addText(" ")
  }

  reportStack.addSpacer(6);

  let reportName = reportStack.addText(reportNameResp);
  reportName.font = Font.regularSystemFont(12);
  reportName.textColor = Color.lightGray();

} catch (e) {
  widget = new ListWidget();
  widget.backgroundColor = Color.dynamic(Color.white(), new Color("#121212"));
  let titleElement = widget.addText("⚠️ Oops! June widget failed to load.");
  titleElement.textColor = Color.red();
  titleElement.font = Font.boldSystemFont(16);
  widget.addSpacer(4);
  widget.addText("Try generating the widget again at widgets.june.so.");
}

return widget;
}

const widget = await createWidget();

if (config.runsInWidget) {
Script.setWidget(widget);
} else {
widget.presentMedium();
}

Script.complete();
`;

export default rawWidgetScript;
