import { Flex, Text } from "@chakra-ui/react";

export const GroupContextTooltip: React.FC = () => {
  return (
    <Flex direction="column" p={2}>
      <Text color="white" fontSize="sm">
        Events with groupId associate user actions to their groups
      </Text>
    </Flex>
  );
};
