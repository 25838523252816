import { IViewInsight } from "core/models/viewInsights";
import { PERSISTED_TIMERANGE_REPORT_TYPES } from "core/constants/report-configurations";
import { useNavigate } from "react-router-dom";

function getGroupType(): string | null {
  if (window.location.href.includes("/company/")) return "company";
  if (window.location.href.includes("/group/")) return "group";

  return null;
}

export function useNavigateToReport({
  viewInsight,
}: {
  viewInsight: IViewInsight;
}) {
  const navigate = useNavigate();
  const level = viewInsight?.appObject?.slug || "user";
  const reportId = viewInsight?.reportId;
  const appId = viewInsight?.appId;
  const timerangeType = viewInsight?.timerangeType;
  const timerangeValue = viewInsight?.timerangeValue;
  const timerangeStartTimestamp = viewInsight?.timerangeStartTimestamp;
  const timerangeEndTimestamp = viewInsight?.timerangeEndTimestamp;
  const shouldIncludeTimerangeParams =
    PERSISTED_TIMERANGE_REPORT_TYPES.includes(viewInsight.reportType);

  function navigateToReport({
    groupId,
    skipNavigate = false,
  }: {
    groupId?: string | number;
    level?: string;
    skipNavigate?: boolean;
  }) {
    const groupType = getGroupType();
    const timerangeParams = shouldIncludeTimerangeParams
      ? `&timerangeType=${timerangeType}&timerangeValue=${timerangeValue}&timerangeStartTimestamp=${timerangeStartTimestamp}&timerangeEndTimestamp=${timerangeEndTimestamp}`
      : "";
    const path = skipNavigate
      ? ""
      : groupId && groupType
        ? `/a/${appId}/report/${reportId}?level=${level}&groupId=${groupId}&groupType=${groupType}${timerangeParams}`
        : `/a/${appId}/report/${reportId}?level=${level}${timerangeParams}`;
    navigate(path);
  }

  return { navigateToReport, shouldIncludeTimerangeParams };
}
