import { useEffect, useState } from "react";
import axios from "core/initializers/axios";

export default function useSdkToken(appId: number) {
  const [{ token }, setTokenState] = useState({
    isLoading: false,
    token: "YOUR_WRITE_KEY",
    error: null,
  });

  useEffect(() => {
    async function getToken() {
      try {
        setTokenState({
          isLoading: true,
          token: "YOUR_WRITE_KEY",
          error: null,
        });
        const req = await axios.get(`/sdk_keys/0`, {
          params: {
            app_id: appId,
            name: "Write key",
          },
        });
        setTokenState({ isLoading: false, token: req.data.key, error: null });
      } catch (e) {
        // TODO
      }
    }

    getToken();
  }, [appId]);

  return { token };
}
