import React from "react";
import EventFrequencyBars from "core/modules/reports/setup/Menu/EventFrequencyBars";
import { Flex } from "@chakra-ui/react";
import { IEventRow } from "./EventsList";

function EventFrequencyCell({ props: event }: { props: IEventRow }) {
  return (
    <Flex align="center">
      <Flex align="flex-start" direction="column">
        <EventFrequencyBars event={event} tooltipPlacement="top" />
      </Flex>
    </Flex>
  );
}

export default EventFrequencyCell;
