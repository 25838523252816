import { useOnboardingChecklist } from "core/hooks/useOnboardingChecklist";
import { Button } from "@chakra-ui/react";
import { IOnboardingChecklist } from "core/models/onboardingChecklist";
import { CodeBracketIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { SetupItem } from "modules/Developers/Overview/SetupItem";

function checkProgress(onboardingChecklist: IOnboardingChecklist) {
  const { isIdentifyingUsers, isSendingEvents } = onboardingChecklist;
  if (isIdentifyingUsers && isSendingEvents) return 100;
  if (isIdentifyingUsers || isSendingEvents) return 50;
  return 0;
}

export const CompleteSetup: React.FC = () => {
  const { appId } = useParams();
  const [checklist, setChecklist] = useState<IOnboardingChecklist | null>(null);

  const shouldPoll =
    !!appId && !(checklist?.isIdentifyingUsers && checklist?.isSendingEvents);

  const { onboardingChecklist, updateOnboardingChecklist, isLoading } =
    useOnboardingChecklist({
      pollingEnabled: shouldPoll,
    });

  const progress = onboardingChecklist ? checkProgress(onboardingChecklist) : 0;
  const complete = progress === 100;

  useEffect(() => {
    if (!appId) return;

    setChecklist(onboardingChecklist ?? null);

    if (
      onboardingChecklist?.isIdentifyingUsers &&
      onboardingChecklist?.isSendingEvents
    ) {
      updateOnboardingChecklist({
        appId: Number(appId),
        step: "is_identifying_users",
        value: "true",
      });

      updateOnboardingChecklist({
        appId: Number(appId),
        step: "is_sending_events",
        value: "true",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingChecklist]);

  return (
    <div className="flex flex-col space-y-3">
      {!complete && (
        <p className="text-sm">
          In order to be correctly set up, we ask that you have the following
        </p>
      )}
      {complete && (
        <p className="text-md font-medium text-green-600">You're all set!</p>
      )}
      <div>
        <SetupItem
          isLoading={isLoading}
          isComplete={onboardingChecklist?.isIdentifyingUsers}
          title="Identify users"
          itemKey={"isIdentifyingUsers"}
        />
        <SetupItem
          isLoading={isLoading}
          isComplete={onboardingChecklist?.isSendingEvents}
          title="Track your first event"
          itemKey={"isSendingEvents"}
        />
        <SetupItem
          isLoading={isLoading}
          isComplete={onboardingChecklist?.isIdentifyingGroups}
          title="Identify workspaces (groups)"
          itemKey={"isIdentifyingGroups"}
          tooltip={`June automatically creates companies based on users. If you'd like to identify different groups, e.g. workspaces, you can use the group call. Read the docs for more info.`}
          isOptional
        />
      </div>
      <div className="flex flex-row justify-between">
        <Button
          mt={3}
          as={Link}
          to={`/a/${appId}/settings/developers`}
          leftIcon={<CodeBracketIcon className="h-4" />}
        >
          Developer tools
        </Button>
      </div>
      {!complete && (
        <p className="text-xs text-gray-500">
          This step requires help from a developer
        </p>
      )}
    </div>
  );
};
