import { useParams } from "react-router-dom";
import React from "react";
import { IInsightSection } from "core/types/TemplateConfig";

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Link,
  Text,
} from "@chakra-ui/react";

export const DescriptionAccordion: React.FC<{ insight: IInsightSection }> = ({
  insight,
}) => {
  const { appId } = useParams<{ appId: string }>();

  if (!insight.description) {
    return null;
  }

  return (
    <Accordion w="100%" borderColor="gray.100" allowToggle>
      <AccordionItem border="none" shadow="none">
        <AccordionButton
          boxShadow="none"
          _hover={{ bg: "white", borderBottomRadius: "lg" }}
          px={7}
          py={4}
          onClick={() => {
            window.analytics.track(
              "insight_formula_clicked",
              {
                appId,
                insight_slug: insight.slug,
              },
              { context: { groupId: appId } },
            );
          }}
        >
          <Flex w="100%" justifyContent="space-between">
            <Text fontWeight="medium" fontSize="sm">
              {insight.description.title}
            </Text>
            <AccordionIcon />
          </Flex>
        </AccordionButton>
        <AccordionPanel px={7} paddingTop={0}>
          <Text fontSize="sm">
            {insight.description.content}
            {insight.description.link && (
              <Link
                color="purple.500"
                ml={1}
                href={insight.description.link}
                isExternal
              >
                Learn more
              </Link>
            )}
          </Text>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
