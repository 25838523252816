import React from "react";
import { IRetentionInsightUser } from "../ChurningUsersContainer";

export const LastSeenCell: React.FC<{
  props: IRetentionInsightUser;
  previewMode: boolean;
}> = ({ props, previewMode }) => {
  return (
    <div className="text-sm">
      {new Date(props.lastEventDate).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      })}
    </div>
  );
};
