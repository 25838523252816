import { Flex, Tooltip } from "@chakra-ui/react";

interface IEventFrequencyBarsProps {
  frequency: "HIGH" | "MEDIUM" | "LOW";
  tooltipPlacement?: "top" | "right" | "bottom" | "left";
}

export const FrequencyBars: React.FC<IEventFrequencyBarsProps> = ({
  frequency,
  tooltipPlacement = "right",
}) => {
  let tooltipLabel = "";
  const isHigh = frequency === "HIGH";
  const isMedium = isHigh || frequency === "MEDIUM";
  const isLow = isMedium || frequency === "LOW";

  switch (true) {
    case isHigh:
      tooltipLabel = "Triggered very frequently";
      break;
    case isMedium:
      tooltipLabel = "Triggered frequently";
      break;
    case isLow:
      tooltipLabel = "Not triggered frequently";
      break;
    default:
      tooltipLabel = "Only triggered once";
  }

  return (
    <Tooltip placement={tooltipPlacement} label={tooltipLabel}>
      <Flex transform="rotate(-180deg)" gridGap={0.5}>
        <Flex
          borderRadius={1}
          w={1}
          h={3}
          bg={isHigh ? "gray.700" : "gray.300"}
        ></Flex>
        <Flex
          borderRadius={1}
          w={1}
          h={2}
          bg={isMedium ? "gray.700" : "gray.300"}
        ></Flex>
        <Flex
          borderRadius={1}
          w={1}
          h={1}
          bg={isLow ? "gray.700" : "gray.300"}
        ></Flex>
      </Flex>
    </Tooltip>
  );
};
