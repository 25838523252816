import { Skeleton } from "@chakra-ui/react";
import { IAppObject } from "core/models/appObjects";
import { ITableTraitConfig } from "core/types/TableTraitConfig";

export const LoadingState: React.FC<{
  appObject: IAppObject;
  tableTraitConfigs: ITableTraitConfig[];
}> = ({ appObject, tableTraitConfigs }) => {
  return (
    <>
      {Array.from({ length: 20 }).map((row, rowIndex) => {
        return (
          <tr className="divide-x divide-gray-100" key={rowIndex}>
            {[...Array.from({ length: 2 }), ...tableTraitConfigs].map(
              (cell, cellIndex) => {
                return (
                  <td className="p-2" key={cellIndex}>
                    <p className="truncate">
                      <Skeleton h="25px" w="full" borderRadius="xl" />
                    </p>
                  </td>
                );
              },
            )}
          </tr>
        );
      })}
    </>
  );
};
