import { useState } from "react";
import { ITrait as TraitKeyValue } from "core/types/Trait";
import { Plan } from "core/types/App";
import { TRAIT_LEVEL } from "core/constants/traits";
import { useToast } from "./useToast";
import { usePinnedTraits } from "./usePinnedTraits";

const FAKE_TRAIT_VALUE = "nicetry";
const MAX_UNPINNED_TRAITS = 15;

export const useProfilePinnedTraits = ({
  appId,
  traitLevel,
  traitKeyValues,
  plan,
}: {
  appId: number;
  traitLevel: TRAIT_LEVEL;
  traitKeyValues: TraitKeyValue[];
  plan: Plan;
}) => {
  const toast = useToast();
  const [showAll, setShowAll] = useState(false);
  const { pinTrait, traitByKey, traits } = usePinnedTraits(
    Number(appId),
    traitLevel,
  );

  const fakeComputedTraitValues = traits
    .filter((t) => t.isComputed && !t.isLive)
    .map((t) => ({
      name: t.name,
      value: FAKE_TRAIT_VALUE,
    })) as TraitKeyValue[];

  const normalTraitKeyValues = traitKeyValues?.filter(
    (t) => !traitByKey[t.name]?.isComputed || traitByKey[t.name]?.isLive,
  );

  // we are adding fake values for computed traits to show them in the UI if there's a paywall
  const paywalledKeyValues = normalTraitKeyValues?.concat(
    fakeComputedTraitValues,
  );

  const allTraitKeyValues =
    plan !== Plan.Pro ? paywalledKeyValues : traitKeyValues ?? [];

  const pinnedTraitKeyValues = allTraitKeyValues.filter(
    (t) => traitByKey[t.name]?.isPinned,
  );
  const unpinnedTraitKeyValues = allTraitKeyValues.filter(
    (t) => !traitByKey[t.name]?.isPinned,
  );

  // if there's no pinned traits, we display all unpinned traits
  const shouldShowUnpinned = showAll || !pinnedTraitKeyValues.length;

  const traitCount = traitKeyValues?.length ?? 0;

  // we are showing all unpinned traits if there are no pinned traits
  // if there are no pinned traits, we are showing maximum 15 traits
  const displayedCount = shouldShowUnpinned
    ? showAll
      ? unpinnedTraitKeyValues.length
      : Math.min(MAX_UNPINNED_TRAITS, unpinnedTraitKeyValues.length)
    : pinnedTraitKeyValues.length;

  const hiddenCount = traitCount - displayedCount;

  const displayedUnpinnedTraits = showAll
    ? unpinnedTraitKeyValues
    : unpinnedTraitKeyValues.slice(0, MAX_UNPINNED_TRAITS);

  return {
    pinnedKeyValues: pinnedTraitKeyValues,
    shouldShowUnpinned,
    hiddenTraitsCount: hiddenCount,
    unpinnedKeyValues: displayedUnpinnedTraits,
    showAll,
    setShowAll,
    pinTrait: (name: string, isPinned: boolean) =>
      pinTrait(name, isPinned).catch(() =>
        toast({
          title: "Could not pin the trait",
          status: "error",
        }),
      ),
    traitByKey,
  };
};
