import { useGetAppObjectsQuery } from "core/models/appObjects";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { ObjectRow } from "./ObjectRow";

export const ObjectsList: React.FC = () => {
  const currentApp = useCurrentApp();
  const { data: appObjects, isLoading } = useGetAppObjectsQuery({
    appId: currentApp.id,
  });

  return (
    <div className="flex flex-col">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Unique identifier
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Enabled
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {isLoading && (
                <tr>
                  <td
                    colSpan={4}
                    className="py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-0"
                  >
                    Loading...
                  </td>
                </tr>
              )}
              {appObjects &&
                appObjects.map((appObject) => (
                  <ObjectRow key={appObject.id} appObject={appObject} />
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
