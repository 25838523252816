// @ts-nocheck
import {
  Bar as RechartsBar,
  BarChart as RechartsBarChart,
  Rectangle as RechartsRectangle,
  ResponsiveContainer as RechartsResponsiveContainer,
  Tooltip as RechartsTooltip,
} from "recharts";
import React from "react";
import colors from "core/design-system/constants/theme/colors";
import {
  addLoadedIdToElement,
  isAnimationDisabled,
} from "core/design-system/constants/charts";
import { Box, ComponentDefaultProps, Text } from "@chakra-ui/react";

interface IBarChart extends ComponentDefaultProps {
  data: any;
  showYAxis?: boolean;
}

function BarChart({ children, data, showYAxis, ...props }: IBarChart) {
  return (
    <RechartsResponsiveContainer width="100%" height="100%">
      <RechartsBarChart
        width="100%"
        height="100%"
        data={data}
        margin={{ top: 30, left: showYAxis ? 0 : -40, right: 40, bottom: 10 }}
        {...props}
      >
        {children}
      </RechartsBarChart>
    </RechartsResponsiveContainer>
  );
}

class BarTooltip extends RechartsTooltip {
  static defaultProps = {
    ...RechartsTooltip.defaultProps,
    // Customized props
    cursor: { fill: colors.gray[50] },
    content: <CustomTooltip />,
  };
}

function CustomTooltip({ label, payload, active, ...props }) {
  if (active && payload && payload.length) {
    return (
      <Box bg="gray.700" px={3} py={2} borderRadius={5}>
        <Text fontSize="xs" color="gray.400">
          {label}
        </Text>
        <Text fontSize="sm" color="white">
          {payload[0].payload.contactsPercentage}%
        </Text>
      </Box>
    );
  }
  return null;
}

class BarItem extends RechartsBar {
  static defaultProps = {
    ...RechartsBar.defaultProps,
    // Customized props
    shape: <BarRectangle radius={[5, 5, 0, 0]} />,
    barSize: 40,
    fill: "#C6C8FF",
    isAnimationActive: !isAnimationDisabled,
    onAnimationEnd: () => addLoadedIdToElement(),
  };
}

function BarRectangle({ ...props }) {
  return <RechartsRectangle radius={[5, 5, 0, 0]} {...props} />;
}

export { BarChart, BarTooltip, BarItem, BarRectangle };
