import { IAlert } from "core/types/Alert";
import { AudienceFilters } from "core/modules/audience/Filters/AudienceFilters";
import { useEditEventAlert } from "core/hooks/useEditEventAlert";
import { IAlertFrequencyEnum } from "core/constants/alert";
import { Flex, Switch, Text } from "@chakra-ui/react";
import { PropertyFilters } from "./PropertyFilters";

interface IAdvancedSettingsProps {
  alert: IAlert;
}

export const AdvancedSettings = ({ alert }: IAdvancedSettingsProps) => {
  const {
    eventId,
    frequency,
    enrichmentEnabled,
    audienceFilters,
    onChangeEnrichment,
    onSetAudienceFilters,
    property,
    propertyValue,
    setProperty,
    setPropertyValue,
    propertyComparisonOperator,
    setPropertyComparisonOperator,
  } = useEditEventAlert(alert);

  return (
    <>
      <Flex direction="column">
        <Text mb={0} fontWeight="semibold">
          Audience
        </Text>
        <Text mb={2} fontSize="sm" color="gray.600">
          You can specify which users should trigger a Slack notification
        </Text>
        <AudienceFilters
          audienceFilters={audienceFilters}
          setAudienceFilters={onSetAudienceFilters}
        />
      </Flex>
      {frequency && frequency.value === IAlertFrequencyEnum.Instant && (
        <Flex direction="column" py={3}>
          <Text mb={0} fontWeight="semibold">
            Properties
          </Text>
          <Text mb={2} fontSize="sm" color="gray.600">
            The event property you want to receive an alert for{" "}
          </Text>
          <PropertyFilters
            appId={alert.appId}
            eventId={eventId}
            property={property}
            propertyValue={propertyValue}
            propertyComparisonOperator={propertyComparisonOperator}
            setProperty={setProperty}
            setPropertyValue={setPropertyValue}
            setPropertyComparisonOperator={setPropertyComparisonOperator}
          />
        </Flex>
      )}
      {frequency && frequency.value === IAlertFrequencyEnum.Instant && (
        <Flex direction="column" py={3}>
          <Text mb={0} fontWeight="semibold">
            Enrichment
          </Text>
          <Text mb={2} fontSize="sm" color="gray.600">
            Use the June data enrichment to receive a notification with more
            context about your users.{" "}
          </Text>
          <Switch
            colorScheme={"purple"}
            defaultChecked={enrichmentEnabled}
            onChange={onChangeEnrichment}
          />
        </Flex>
      )}
    </>
  );
};
