import { useNavigate, useParams } from "react-router-dom";
import { ITemplateConfigSetup } from "core/types/TemplateConfig";
import { IReportConfig } from "core/types/Report";
import { useReportSetup } from "core/hooks/useReportSetup";
import Icon from "core/design-system/components/Icon";
import {
  MULTI_EVENT_TYPE,
  SINGLE_EVENT_TYPE,
} from "core/constants/report-setup";
import { DEMO_APP_ID } from "core/constants/appIds";
import MotionButton from "core/components/Motion/Button";
import { Button, ComponentDefaultProps, Tooltip } from "@chakra-ui/react";

function areRequiredSectionsCompleted({
  setup,
  reportConfig,
}: {
  setup: ITemplateConfigSetup;
  reportConfig: IReportConfig;
}) {
  const requiredSections = setup.setupSections.filter(
    (section) => section.required && !section.isDynamic,
  );
  const requiredSectionsCompleted = requiredSections.filter((section) => {
    const config = reportConfig[section.configKey];

    if (!config) return false;
    return section.validate && section.validate(config);
  });

  return requiredSections.length === requiredSectionsCompleted.length;
}

function areOptionalEventSectionsCompleted({
  setup,
  reportConfig,
}: {
  setup: ITemplateConfigSetup;
  reportConfig: IReportConfig;
}) {
  const optionalSections = setup.setupSections.filter(
    (section) =>
      !section.required &&
      (section.type === MULTI_EVENT_TYPE || section.type === SINGLE_EVENT_TYPE),
  );
  const optionalSectionsCompleted = optionalSections.filter((section) => {
    const config = reportConfig[section.configKey];

    if (!config) return true;
    return section.validate && section.validate(config);
  });
  return optionalSections.length === optionalSectionsCompleted.length;
}

function isReportSetup({
  setup,
  reportConfig,
}: {
  setup: ITemplateConfigSetup;
  reportConfig: IReportConfig;
}): boolean {
  if (!setup?.setupSections) return false;

  return (
    areRequiredSectionsCompleted({ setup, reportConfig }) &&
    areOptionalEventSectionsCompleted({ setup, reportConfig })
  );
}

interface IGenerateReportButton extends ComponentDefaultProps {
  saveButton?: boolean;
  onCloseDrawer?: () => void;
  setup: ITemplateConfigSetup;
}

const GenerateReportButton: React.FC<IGenerateReportButton> = ({
  saveButton,
  onCloseDrawer,
  setup,
  ...props
}) => {
  const { appId, reportId } = useParams();
  const { currentReport, isUpdating, hasBeenPersisted, updateReportSetup } =
    useReportSetup();
  const _isReportSetup = isReportSetup({
    setup,
    reportConfig: currentReport.config,
  });
  const navigate = useNavigate();

  function onGoToReport() {
    updateReportSetup({ setup });
    onCloseDrawer && onCloseDrawer();
    if (window.location.href.includes(`/setup`)) {
      navigate(`/a/${appId}/report/${reportId}?level=user`);
    }
  }

  if (isUpdating)
    return (
      <Button
        rightIcon={<Icon iconType="icon" name="arrow-right-2" />}
        colorScheme="gray"
        isDisabled
        isLoading
        loadingText="Saving setup..."
        {...props}
      />
    );

  return (
    <>
      {(_isReportSetup && !hasBeenPersisted && (
        <MotionButton
          data-testid="generate-report-button"
          rightIcon={<Icon iconType="icon" name="arrow-right-2" />}
          colorScheme="purple"
          transition={{ duration: 0.5 }}
          animate={{ x: [5, -5, 4, -4, 3, -3, 0], duration: 1 }}
          zIndex={"sticky"}
          onClick={onGoToReport}
          isDisabled={Number(appId) === DEMO_APP_ID}
          {...props}
        >
          {saveButton ? "Save & close" : `Go to report`}
        </MotionButton>
      )) || (
        <Tooltip
          shouldWrapChildren
          mt={-2}
          hasArrow
          label="You need to complete the required steps before creating the report"
        >
          <Button
            data-testid="generate-report-button"
            rightIcon={<Icon iconType="icon" name="arrow-right-2" />}
            colorScheme="purple"
            isDisabled
            {...props}
          >
            {saveButton ? "Save & close" : `Go to report`}
          </Button>
        </Tooltip>
      )}
    </>
  );
};

export default GenerateReportButton;
