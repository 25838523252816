import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { useCallback } from "react";
import { useToast } from "core/hooks/useToast";
import { useCompany } from "core/hooks/useCompany";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

interface IDeleteCompanyModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const DeleteCompanyModal = ({
  isOpen,
  onClose,
}: IDeleteCompanyModalProps) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { appId, groupId: encodedGroupId } = useParams<{
    appId: string;
    groupId: string;
  }>() as {
    appId: string;
    groupId: string;
  };
  const groupId = decodeURIComponent(encodedGroupId);

  const { deleteCompany } = useCompany({
    appId: Number(appId),
    id: groupId,
  });

  const onDelete = useCallback(() => {
    if (!appId || !groupId) return;
    deleteCompany({ appId: Number(appId), id: groupId });
  }, [deleteCompany, appId, groupId]);

  return (
    <Modal
      size="lg"
      closeOnEsc
      closeOnOverlayClick
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete this company?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            If the user sends more identify calls with an email trait, the
            company will show up again.
          </Text>
          <Text pt={2}>
            It might take at least an hour before this company disappears from
            your companies list.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme="red"
            onClick={() => {
              onDelete();
              toast({
                title: "Deleted successfully",
                description:
                  "It might take at least an hour before this company disappears from your companies list.",
              });
              navigate(`/a/${appId}/people`);
            }}
          >
            Delete permanently
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
