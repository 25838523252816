import humps from "humps";
import { GraphType, Interval, Measure } from "core/types/ViewInsight";
import { IReport } from "core/types/Report";
import { IQuery } from "core/types/Query";
import { baseQuery } from "core/initializers/rtk";
import { ABSOLUTE, RELATIVE } from "core/constants/timerange";
import { createApi } from "@reduxjs/toolkit/query/react";
import { IAppObject } from "./appObjects";

import type { SnakeCasedPropertiesDeep } from "type-fest";
export interface IViewInsight {
  id: number;
  appId: number;
  reportId: number;
  viewId: number;
  reportType: number;
  insightId?: number;
  queryId?: number;
  order: number;
  isAutoGenerated: boolean;
  insight: {
    name: string;
    reportType: number;
    reportName?: string;
    level?: "user" | "group";
    defaultParams?: object;
  };
  query?: IQuery;
  timeRange?: number;
  startDate: string;
  endDate: string;
  timerangeType: typeof RELATIVE | typeof ABSOLUTE;
  timerangeValue: number;
  timerangeStartTimestamp?: string;
  timerangeEndTimestamp?: string;
  appObject?: IAppObject;
  report: IReport;
  graphType: GraphType;
  color: string;
  gridWidth: number;
  gridHeight: number;
  persisted: boolean;
  measure: Measure;
  interval?: Interval;
  title?: string;
}

export const viewInsightsApi = createApi({
  baseQuery,
  reducerPath: "viewInsightsApi",
  tagTypes: ["ViewInsights", "Views", "Preview"],
  endpoints: (builder) => ({
    getViewInsights: builder.query<
      IViewInsight[],
      { viewId: number; appId: number }
    >({
      query: ({ appId, viewId }) => ({
        url: `/view_insights`,
        params: humps.decamelizeKeys({ appId, viewId }),
      }),
      providesTags: ["ViewInsights"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IViewInsight[]>,
      ): IViewInsight[] => humps.camelizeKeys(response) as IViewInsight[],
    }),
    getViewInsightPreview: builder.query<
      IViewInsight,
      {
        appId: number;
        viewId: number;
        measure: Measure;
        graphType: GraphType;
        interval?: Interval;
        objectType?: string;
      }
    >({
      query: ({ appId, viewId, measure, graphType, interval, objectType }) => ({
        url: `/view_insights/preview`,
        params: humps.decamelizeKeys({
          appId,
          viewId,
          measure,
          graphType,
          interval,
          objectType,
        }),
      }),
      providesTags: ["Preview"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IViewInsight>,
      ): IViewInsight => humps.camelizeKeys(response) as IViewInsight,
    }),
    deleteViewInsight: builder.mutation<{}, { appId: number; id: number }>({
      query: ({ appId, id }) => ({
        url: `/view_insights/${id}`,
        method: "DELETE",
        params: humps.decamelizeKeys({ appId, id }),
        credentials: "include",
        mode: "cors",
      }),
      invalidatesTags: ["ViewInsights", "Views"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IViewInsight[]>,
      ): IViewInsight[] => humps.camelizeKeys(response) as IViewInsight[],
    }),
    addViewInsight: builder.mutation<
      {},
      {
        appId: number;
        viewId: number;
        reportId?: number;
        insightId?: number;
        queryId?: number;
        objectType?: string;
        title?: string;
        graphType?: string;
        color?: string;
        interval?: string;
        measure?: string;
        gridWidth?: number;
        gridHeight?: number;
      }
    >({
      query: ({
        appId,
        viewId,
        reportId,
        insightId,
        queryId,
        objectType,
        title,
        graphType,
        color,
        interval,
        measure,
        gridWidth,
        gridHeight,
      }) => ({
        url: `/view_insights`,
        method: "POST",
        params: humps.decamelizeKeys({
          appId,
          reportId,
          viewId,
          insightId,
          queryId,
          objectType,
          title,
          graphType,
          color,
          interval,
          measure,
          gridWidth,
          gridHeight,
        }),
        credentials: "include",
        mode: "cors",
      }),
      invalidatesTags: ["ViewInsights", "Views"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IViewInsight[]>,
      ): IViewInsight[] => humps.camelizeKeys(response) as IViewInsight[],
    }),
    updateViewInsight: builder.mutation<
      void,
      {
        appId: number;
        id: number;
        order?: number;
        timerangeType?: typeof RELATIVE | typeof ABSOLUTE;
        timerangeValue?: number;
        timerangeStartTimestamp?: string;
        timerangeEndTimestamp?: string;
        title?: string;
        graphType?: string;
        objectType?: string;
        color?: string;
        interval?: string;
        measure?: string;
        gridWidth?: number;
        gridHeight?: number;
        state?: number;
      }
    >({
      query: ({
        appId,
        id,
        order,
        timerangeType,
        timerangeValue,
        timerangeStartTimestamp,
        timerangeEndTimestamp,
        objectType,
        title,
        graphType,
        color,
        interval,
        measure,
        gridWidth,
        gridHeight,
        state,
      }) => ({
        url: `/view_insights/${id}`,
        method: "PUT",
        params: humps.decamelizeKeys({
          appId,
          id,
          order,
          timerangeType,
          timerangeValue,
          timerangeStartTimestamp,
          timerangeEndTimestamp,
          title,
          graphType,
          color,
          interval,
          measure,
          gridWidth,
          gridHeight,
          state,
          objectType,
        }),
        credentials: "include",
        mode: "cors",
      }),
      invalidatesTags: ["ViewInsights", "Views", "Preview"],
    }),
  }),
});

export const {
  useLazyGetViewInsightsQuery,
  useDeleteViewInsightMutation,
  useUpdateViewInsightMutation,
  useAddViewInsightMutation,
  useGetViewInsightPreviewQuery,
  useLazyGetViewInsightPreviewQuery,
} = viewInsightsApi;
