import React, { useEffect } from "react";
import { useLazyClearbitLogo } from "core/hooks/useClearbitLogo";
import CompanyAvatar from "core/components/Avatars/CompanyAvatar";
import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import { GroupType } from "./GroupListSingleGroup";

interface IGroupProps {
  group?: {
    name?: string;
    id: string;
    appId: number | string;
  };
  size?: string;
  unidentified?: boolean;
  groupId?: string;
  description?: string | React.ReactNode;
  truncateName?: boolean;
  previewMode?: boolean;
  groupType?: number;
}

export const Group: React.FC<IGroupProps> = ({
  group,
  size = "sm",
  unidentified = false,
  groupId = "",
  description = "",
  truncateName = false,
  previewMode = false,
  groupType,
}) => {
  const { getClearbitLogo, data } = useLazyClearbitLogo();

  useEffect(() => {
    if (groupType === GroupType.Company) {
      getClearbitLogo({ domain: groupId, size: 100 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupType]);

  if (unidentified || previewMode) {
    return (
      <Box>
        <Flex justify="start" align="center">
          <Flex align="center">
            <CompanyAvatar
              group={group}
              size={size}
              unidentified={unidentified}
            />
            <Flex direction="column" ml={3} mt={1}>
              <Text fontSize="sm" color="gray.800">
                {groupId}
              </Text>
              {description && (
                <Text color="gray.600" fontSize="xs">
                  {description}
                </Text>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Box>
    );
  }

  return (
    <Box>
      <Flex justify="center" align="center" position="relative">
        <Flex align="center">
          <CompanyAvatar
            group={group}
            size={size}
            unidentified={unidentified}
            logoURL={data}
          />
          <Flex>
            <Tooltip
              placement="bottom"
              label={group?.name || group?.id}
              isDisabled={!truncateName}
            >
              <Flex direction="column" ml={3}>
                <Flex align="center" gridGap={1}>
                  <Text
                    color="gray.800"
                    fontSize="sm"
                    noOfLines={1}
                    maxW={truncateName ? 200 : undefined}
                  >
                    {group?.name || group?.id}
                  </Text>
                </Flex>
                {description && (
                  <Text color="gray.600" fontSize="xs">
                    {description}
                  </Text>
                )}
              </Flex>
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Group;
