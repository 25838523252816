import moment from "moment";
import { ISlippingAwayUsersData } from "./SlippingAway.types";

export const mockData: ISlippingAwayUsersData = [
  {
    id: "32967",
    traits: {
      email: "elisha.lynch.jr@example.org",
      firstName: "Elisha",
      lastName: "Lynch Jr.",
    },
    eventCount: 14,
    firstSeenAt: moment().subtract(14, "days").toISOString(),
    lastSeenAt: moment().subtract(7, "days").toISOString(),
    daysSinceLastEvent: 7,
  },
  {
    id: "32968",
    traits: {
      email: "lacresha_west_sr@example.com",
      firstName: "Lacresha",
      lastName: "West Sr.",
    },
    eventCount: 11,
    firstSeenAt: moment().subtract(11, "days").toISOString(),
    lastSeenAt: moment().subtract(7, "hours").toISOString(),
    daysSinceLastEvent: 7,
  },
  {
    id: "32969",
    traits: {
      email: "odell_hoeger@example.net",
      firstName: "Odell Hoeger",
    },
    eventCount: 12,
    firstSeenAt: moment().subtract(10, "days").toISOString(),
    lastSeenAt: moment().subtract(7, "days").toISOString(),
    daysSinceLastEvent: 7,
  },
  {
    id: "32970",
    traits: {
      email: "milagro_greenholt_md@example.org",
      firstName: "Milagro Greenholt MD",
    },
    eventCount: 19,
    firstSeenAt: moment().subtract(33, "days").toISOString(),
    lastSeenAt: moment().subtract(8, "days").toISOString(),
    daysSinceLastEvent: 8,
  },
  {
    id: "32971",
    traits: {
      email: "nakita_tremblay@example.com",
      firstName: "Nakita Tremblay",
      lastName: "null",
    },
    eventCount: 51,
    firstSeenAt: moment().subtract(52, "days").toISOString(),
    lastSeenAt: moment().subtract(10, "days").toISOString(),
    daysSinceLastEvent: 10,
  },
  {
    id: "32972",
    traits: {
      email: "ratke_jess@example.org",
      firstName: "Jess Ratke",
    },
    eventCount: 13,
    firstSeenAt: moment().subtract(17, "days").toISOString(),
    lastSeenAt: moment().subtract(7, "days").toISOString(),
    daysSinceLastEvent: 7,
  },
  {
    id: "32973",
    traits: {
      email: "dooley.rosetta.iii@example.org",
      firstName: "Rosetta Dooley III",
    },
    eventCount: 29,
    firstSeenAt: moment().subtract(20, "days").toISOString(),
    lastSeenAt: moment().subtract(7, "days").toISOString(),
    daysSinceLastEvent: 7,
  },
  {
    id: "32974",
    traits: {
      email: "otelia.herzog.lld@example.net",
      firstName: "Otelia Herzog LLD",
    },
    eventCount: 21,
    firstSeenAt: moment().subtract(16, "days").toISOString(),
    lastSeenAt: moment().subtract(10, "days").toISOString(),
    daysSinceLastEvent: 10,
  },
  {
    id: "33418",
    traits: {
      email: "bernhard.sen.wesley@example.net",
      firstName: "Sen. Wesley Bernhard",
    },
    eventCount: 29,
    firstSeenAt: moment().subtract(13, "days").toISOString(),
    lastSeenAt: moment().subtract(7, "days").toISOString(),
    daysSinceLastEvent: 7,
  },
  {
    id: "33419",
    traits: {
      email: "prohaska.dwight@example.com",
      firstName: "Dwight Prohaska",
    },
    eventCount: 9,
    firstSeenAt: moment().subtract(11, "days").toISOString(),
    lastSeenAt: moment().subtract(7, "days").toISOString(),
    daysSinceLastEvent: 7,
  },
  {
    id: "33420",
    traits: {
      email: "howard_skiles@example.com",
      firstName: "Howard Skiles",
    },
    eventCount: 43,
    firstSeenAt: moment().subtract(21, "days").toISOString(),
    lastSeenAt: moment().subtract(11, "days").toISOString(),
    daysSinceLastEvent: 11,
  },
  {
    id: "33421",
    traits: {
      email: "hayes.fransisca@example.net",
      firstName: "Fransisca Hayes",
    },
    eventCount: 14,
    firstSeenAt: moment().subtract(22, "days").toISOString(),
    lastSeenAt: moment().subtract(11, "days").toISOString(),
    daysSinceLastEvent: 11,
  },
  {
    id: "83779",
    traits: {},
    eventCount: 12,
    firstSeenAt: moment().subtract(13, "days").toISOString(),
    lastSeenAt: moment().subtract(7, "days").toISOString(),
    daysSinceLastEvent: 7,
  },
  {
    id: "83793",
    traits: {},
    eventCount: 31,
    firstSeenAt: moment().subtract(15, "days").toISOString(),
    lastSeenAt: moment().subtract(9, "days").toISOString(),
    daysSinceLastEvent: 9,
  },
];
