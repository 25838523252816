interface IMenuItemTextProps {
  label: string;
  subtitle?: string;
}

export const MenuItemText: React.FC<IMenuItemTextProps> = ({
  label,
  subtitle,
}) => {
  return (
    <div className="flex flex-col">
      <span className="max-w-[224px] truncate text-sm">{label}</span>
      {subtitle && (
        <div className="truncate text-xs text-gray-500">{subtitle}</div>
      )}
    </div>
  );
};
