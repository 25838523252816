import { useCallback } from "react";
import { useRequestDataExportMutation } from "core/models/apps";
import { useToast } from "core/hooks/useToast";

export const useDataExport = ({ appId }: { appId: number }) => {
  const toast = useToast();
  const [requestDataExport] = useRequestDataExportMutation();

  const startDataExport = useCallback(() => {
    requestDataExport({ appId: appId })
      .unwrap()
      .then(() => {
        const title = "Export started";
        const description = `You will shortly receive an email with all your data`;
        toast({
          title,
          description,
          status: "success",
        });
      })
      .catch((err) => {
        const title = "Export failed";
        const description = err?.data?.error;
        console.log(err);
        toast({
          title,
          description,
          status: "error",
        });
      });
  }, [appId, requestDataExport, toast]);

  return {
    startDataExport,
  };
};
