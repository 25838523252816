import { useNavigate } from "react-router-dom";
import React from "react";
import { ComponentDefaultProps } from "@chakra-ui/react";
import List from "../List";
import { ITraitWithConfig } from "../../types/Trait";
import { config } from "../../../routes/Settings/sections/ComputedTraits";

interface ITraitsList extends ComponentDefaultProps {
  traits: ITraitWithConfig[] | undefined;
  isLoading: boolean;
}

export const TraitsList: React.FC<ITraitsList> = ({
  traits,
  isLoading,
  ...props
}) => {
  const navigate = useNavigate();

  return (
    <List
      onListRowClick={(row) => {
        if (row.isEditable)
          navigate(`/a/${row.appId}/settings/computed-traits/${row.id}`);
      }}
      data-testid="labels-list"
      rows={traits || []}
      config={config}
      mt={4}
      isLoading={isLoading}
      w="full"
      {...props}
    />
  );
};
