import { cx } from "helpers/cx";
import { ComboboxOptions } from "@headlessui/react";
import { ComponentDefaultProps } from "@chakra-ui/react";

interface ICommandListProps extends ComponentDefaultProps {
  className?: string;
  children?: React.ReactNode;
  "data-testid"?: string;
}

export const CommandList: React.FC<ICommandListProps> = ({
  children,
  className,
  "data-testid": dataTestId,
  ...props
}) => {
  return (
    <ComboboxOptions
      static
      className={cx(className ?? "flex flex-col gap-1")}
      data-testid={dataTestId}
      {...props}
    >
      {children}
    </ComboboxOptions>
  );
};
