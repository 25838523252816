import React, { ReactNode } from "react";
import { LayoutGroup, motion } from "framer-motion";
import { Box, BoxProps, Flex, HStack, Text, TextProps } from "@chakra-ui/react";

const MotionBox = motion(Box);

export interface AnimatedToggleProps {
  containerProps?: BoxProps;
  labelContainerProps?: BoxProps;
  shouldAnimate?: boolean;
  labelProps?: TextProps;
  items: {
    label?: ReactNode;
    value: string;
    isDisabled?: boolean;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    icon?: ReactNode;
  }[];
  value: string;
  onChange: (value: string, e: React.SyntheticEvent) => void;
}

const AnimatedToggle: React.FC<AnimatedToggleProps> = ({
  containerProps,
  items,
  value,
  onChange,
  labelContainerProps,
  labelProps,
  shouldAnimate = true,
}) => {
  const valuesKey = items.map((item) => item.value).join("-");

  return (
    <Box
      display="grid"
      placeItems="center"
      bg="gray.100"
      p={[0.5]}
      rounded="lg"
      minH={[8]}
      {...containerProps}
    >
      <Flex justifyContent="space-evenly" h="100%" w="100%">
        <LayoutGroup id={valuesKey}>
          {items.map((item, i) => {
            const isSelected = item.value === value;
            return (
              <MotionBox
                key={item.value}
                cursor="pointer"
                position="relative"
                px={[item.icon ? 2 : 4]}
                color={item.isDisabled ? "gray.500" : "gray.800"}
                fontSize="sm"
                fontWeight="medium"
                onClick={(e: React.SyntheticEvent) => onChange(item.value, e)}
                animate={{ opacity: isSelected ? 1 : 0.5 }}
                h="100%"
                display="flex"
                alignItems="center"
                {...(item.isDisabled && {
                  cursor: "none",
                  pointerEvents: "none",
                })}
                {...labelContainerProps}
              >
                <HStack
                  zIndex="docked"
                  spacing={[item.icon ? 0 : 2]}
                  align="center"
                  justify="center"
                >
                  {item.startIcon}
                  {typeof item.label === "string" ? (
                    <Text
                      fontSize="xs"
                      textTransform="capitalize"
                      {...labelProps}
                    >
                      {item.label}
                    </Text>
                  ) : (
                    item.label
                  )}
                  {item.endIcon}
                  {item.icon}
                </HStack>
                {isSelected && (
                  <MotionBox
                    layoutId={shouldAnimate ? "highlight" : undefined}
                    key={
                      shouldAnimate ? item.value : `${item.value}-no-animation`
                    }
                    position="absolute"
                    top={0}
                    left={0}
                    width="100%"
                    height="100%"
                    background="white"
                    zIndex="base"
                    borderRadius="lg"
                    shadow="sm"
                  />
                )}
              </MotionBox>
            );
          })}
        </LayoutGroup>
      </Flex>
    </Box>
  );
};

export default AnimatedToggle;
