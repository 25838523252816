import React, { useEffect, useRef, useState } from "react";
import { IReport } from "core/types/Report";
import { isUserReportLevel } from "core/modules/reports/utils";
import { useInsightCsvExport } from "core/hooks/useInsightCsvExport";
import { useContactDrilldown } from "core/hooks/useContactDrilldown";
import { AdoptionState, AdoptionStateNames } from "core/constants/adoption";
import { ToggleButton } from "core/components/Toggle";
import { GroupListSingleGroup } from "core/components/Group/GroupListSingleGroup";
import Error from "core/components/Error";
import { Drilldown } from "core/components/Drilldown";
import { UserListSingleContact } from "core/components/Contact/UserListSingleContact";

export type AdoptionUserType = AdoptionState | "audience" | "dropped";

interface IAdoptionUserListProps {
  report: IReport;
  unit: string;
  adoptedCount: number;
  droppedCount?: number;
  adoptionState: AdoptionState;
  date: string;
  humanizedDate: string;
  isOverall?: boolean;
  isOpen: boolean;
  onClose: () => void;
  adoptionUserType: AdoptionUserType;
  showToggle?: boolean;
}

const getDrilldownTitle = ({
  adoptionState,
  humanizedDate,
  isOverall,
  adoptedCount,
  droppedCount,
  isDropped,
  unit,
}: Pick<
  IAdoptionUserListProps,
  | "adoptionState"
  | "humanizedDate"
  | "isOverall"
  | "adoptedCount"
  | "droppedCount"
  | "adoptionUserType"
  | "unit"
> & { isDropped: boolean }) => {
  if (isDropped) {
    return `Dropped by ${droppedCount} ${unit} ${isOverall ? "since" : "on"} ${humanizedDate}`;
  }

  return `${AdoptionStateNames[adoptionState]} by ${adoptedCount} ${unit} ${
    isOverall ? "since" : "on"
  } ${humanizedDate}`;
};

export const AdoptionUserList: React.FC<IAdoptionUserListProps> = ({
  report,
  unit,
  adoptedCount,
  droppedCount,
  adoptionState,
  adoptionUserType,
  humanizedDate,
  date,
  isOverall = false,
  isOpen,
  onClose,
  showToggle = true,
}) => {
  const isUserReport = isUserReportLevel();
  const insightId = isUserReport ? 5 : 36;

  const [userCount, setUserCount] = useState<number>(
    (adoptionUserType === "dropped" ? droppedCount : adoptedCount) || 0,
  );
  const [isDropped, setIsDropped] = useState<boolean>(
    adoptionUserType === "dropped",
  );
  const previousDroppedValue = useRef<boolean | undefined>();

  const {
    contacts,
    isLoading,
    error,
    hasMoreContacts,
    loadMore,
    searchQuery,
    onSearch,
  } = useContactDrilldown({
    report,
    insightType: insightId,
    insightParams: {
      adoptionState,
      isOverall,
      isDropped,
      date,
    },
    skip: !isOpen,
  });

  const { requestCsvExport, csvExportLoading } = useInsightCsvExport({
    report,
    insightId: insightId,
    insightParams: {
      adoptionState,
      isOverall,
      isDropped,
      date,
    },
    count: userCount,
  });

  useEffect(() => {
    setIsDropped(adoptionUserType === "dropped");
  }, [adoptionUserType]);

  useEffect(() => {
    previousDroppedValue.current = isDropped;
    setUserCount(isDropped ? droppedCount || 0 : adoptedCount);
  }, [adoptedCount, droppedCount, isDropped]);

  const title = getDrilldownTitle({
    adoptionState,
    humanizedDate,
    isOverall,
    adoptedCount,
    adoptionUserType,
    isDropped,
    droppedCount,
    unit,
  });

  if (error) {
    return <Error />;
  }

  return (
    <Drilldown isOpen={isOpen} onClose={onClose}>
      {(Drilldown) => (
        <>
          <Drilldown.Header>
            <Drilldown.Title title={title} isLoading={isLoading} />
            <Drilldown.DownloadButton
              isDisabled={userCount <= 0}
              isLoading={csvExportLoading}
              onClick={requestCsvExport}
              mb={showToggle ? 4 : 0}
            />
            {showToggle && (
              <Drilldown.Toggle>
                <ToggleButton
                  isDisabled={false}
                  isSelected={!isDropped}
                  onToggle={() => {
                    setIsDropped(false);
                    setUserCount(adoptedCount);
                  }}
                >
                  Adopted
                </ToggleButton>
                <ToggleButton
                  isDisabled={droppedCount === 0}
                  isSelected={isDropped}
                  onToggle={() => {
                    setIsDropped(true);
                    setUserCount(droppedCount || 0);
                  }}
                >
                  Dropped
                </ToggleButton>
              </Drilldown.Toggle>
            )}
            {unit === "users" && (
              <div className="mt-4 flex w-full">
                <Drilldown.Search
                  placeholder="Search your users by email address..."
                  searchQuery={searchQuery}
                  onSearch={onSearch}
                />
              </div>
            )}
          </Drilldown.Header>
          <Drilldown.Body>
            <Drilldown.List
              items={contacts || []}
              itemRenderer={({ item }) =>
                unit === "users" ? (
                  <UserListSingleContact
                    key={item.userId}
                    id={item.userId}
                    traits={item.traits}
                  />
                ) : (
                  <GroupListSingleGroup
                    key={item.groupId}
                    id={item.groupId}
                    traits={item.traits}
                    groupType={item.groupType}
                  />
                )
              }
              isLoading={isLoading}
              hasMore={hasMoreContacts}
              loadNext={loadMore}
              emptyStateText={`No ${unit}`}
            />
          </Drilldown.Body>
        </>
      )}
    </Drilldown>
  );
};
