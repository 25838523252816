export enum IAlertStateEnum {
  Enabled,
  Disabled,
}

export enum IAlertFrequencyEnum {
  Daily,
  Weekly,
  Monthly,
  Instant,
}
export interface IFrequencyOption {
  value: IAlertFrequencyEnum;
  label: string;
  description: string;
}

export const FREQUENCIES: IFrequencyOption[] = [
  {
    value: IAlertFrequencyEnum.Daily,
    label: "Daily",
    description: "Once a day, first thing in the morning.",
  },
  {
    value: IAlertFrequencyEnum.Weekly,
    label: "Weekly",
    description: "Every Monday morning",
  },
  {
    value: IAlertFrequencyEnum.Monthly,
    label: "Monthly",
    description: "The first day of every month",
  },
  {
    value: IAlertFrequencyEnum.Instant,
    label: "Instant",
    description: "As soon as the event occurs",
  },
];
