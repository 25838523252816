import React from "react";
import { AlertStatus } from "@chakra-ui/react";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";

interface INotificationProps {
  title: React.ReactNode;
  description?: React.ReactNode;
  status?: AlertStatus;
}

const Notification: React.FC<INotificationProps> = ({
  title,
  description,
  status = "success",
}) => {
  return (
    <div className="pointer-events-none fixed inset-0 flex items-end px-2 py-3 sm:items-start sm:p-6">
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition data-[closed]:data-[enter]:translate-y-2 data-[enter]:transform data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:data-[enter]:sm:translate-x-2 data-[closed]:data-[enter]:sm:translate-y-0">
          <div className="px-2 py-3">
            <div className="flex">
              <div className="ml-1 mt-0.5 flex-shrink-0">
                {status === "success" && (
                  <CheckCircleIcon className="h-5 w-5 text-green-500" />
                )}
                {status === "warning" && (
                  <ExclamationTriangleIcon className="h-5 w-5 text-yellow-500" />
                )}
                {status === "error" && (
                  <XCircleIcon className="h-5 w-5 text-red-500" />
                )}
              </div>
              <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="text-sm font-medium text-gray-900">{title}</p>
                <p className="text-sm text-gray-600">{description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
