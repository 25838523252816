import React from "react";
import { createIcon } from "@chakra-ui/icons";

const ArrowLeftBackSquare = createIcon({
  viewBox: "0 0 24 24",
  path: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5014 15.0013L12.5002 12L15.5014 8.99878"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.49856 8.99878V15.0013"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.99622 16.0016V7.9983C2.99622 5.23572 5.23572 2.99622 7.9983 2.99622H16.0016C18.7642 2.99622 21.0037 5.23572 21.0037 7.9983V16.0016C21.0037 18.7642 18.7642 21.0037 16.0016 21.0037H7.9983C5.23572 21.0037 2.99622 18.7642 2.99622 16.0016Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
});

export default ArrowLeftBackSquare;
