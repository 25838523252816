import humps from "humps";
import { baseQuery } from "core/initializers/rtk";
import { IDataMapping } from "core/hooks/useDataMappings";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppObjectType } from "./appObjects";

export interface IPropertys {
  name: string;
  label: string;
  type: string;
}

export interface ITrait {
  trait: string;
  audienceType: string;
}

export interface IProperty {
  name: string;
  label: string;
  type: string;

  // Attio properties
  isRequired?: boolean;
  isUnique?: boolean;
  isWritable?: boolean;

  // HubSpot properties
  modificationMetadata?: { readOnlyValue: boolean };
  hasUniqueValue?: boolean;
}

export interface ICreateDataMappingParams {
  appId: number;
  source: string;
  destination: string;
  integration: Integration;
  objectType: AppObjectType;
}

export enum Integration {
  Hubspot = "hubspot",
  Attio = "attio",
  Salesforce = "salesforce",
}

export const integrationNames = {
  [Integration.Hubspot]: "HubSpot",
  [Integration.Attio]: "Attio",
  [Integration.Salesforce]: "Salesforce",
};

export const dataMappingsApi = createApi({
  baseQuery,
  reducerPath: "dataMappingsApi",
  tagTypes: ["DataMappings", "AttioSettings"],
  endpoints: (builder) => ({
    getProperties: builder.query<
      any,
      {
        appId: number;
        integration: Integration;
        objectType: AppObjectType;
      }
    >({
      query: ({ appId, integration, objectType }) => ({
        url: `/data_mappings/properties`,
        params: humps.decamelizeKeys({
          appId,
          integration,
          objectType,
        }),
      }),
      providesTags: ["DataMappings"],
      transformResponse: (response: IProperty[]) =>
        humps.camelizeKeys(response),
    }),
    getDataMappings: builder.query<
      IDataMapping[],
      { appId: number; integration: Integration; objectType: AppObjectType }
    >({
      query: ({ appId, integration, objectType }) => ({
        url: `/data_mappings?app_id=${appId}`,
        params: humps.decamelizeKeys({ appId, integration, objectType }),
      }),
      providesTags: ["DataMappings"],
      transformResponse: (response: IDataMapping[]) =>
        humps.camelizeKeys(response) as IDataMapping[],
    }),
    createDataMapping: builder.mutation<undefined, ICreateDataMappingParams>({
      query: ({ appId, source, destination, integration, objectType }) => ({
        url: `/data_mappings?app_id=${appId}`,
        method: "POST",
        params: humps.decamelizeKeys({
          appId,
          source,
          destination,
          integration,
          objectType,
        }),
      }),
      invalidatesTags: ["DataMappings"],
    }),
    updateDataMapping: builder.mutation<
      undefined,
      {
        id: number;
        appId: number;
        source: string;
        destination: string;
        integration: Integration;
      }
    >({
      query: ({ id, appId, source, destination, integration }) => ({
        url: `/data_mappings/${id}?app_id=${appId}`,
        method: "PUT",
        params: humps.decamelizeKeys({
          id,
          appId,
          source,
          destination,
          integration,
        }),
      }),
      invalidatesTags: ["DataMappings"],
    }),
    deleteDataMapping: builder.mutation<
      undefined,
      { id: number; appId: number; integration: Integration }
    >({
      query: ({ id, appId, integration }) => ({
        url: `/data_mappings/${id}?app_id=${appId}`,
        method: "DELETE",
        params: humps.decamelizeKeys({
          id,
          appId,
          integration,
        }),
      }),
      invalidatesTags: ["DataMappings"],
    }),
  }),
});

export const {
  useLazyGetPropertiesQuery,
  useGetPropertiesQuery,
  useGetDataMappingsQuery,
  useCreateDataMappingMutation,
  useUpdateDataMappingMutation,
  useDeleteDataMappingMutation,
} = dataMappingsApi;
