import { useViews, ViewLocation } from "core/hooks/useViews";
import { Spinner } from "@chakra-ui/react";
import { ItemContainer } from "./ItemContainer";

export const CollapsedViews: React.FC = () => {
  const { views, isLoadingViews } = useViews({
    location: ViewLocation.Home,
    pinned: true,
  });

  const pathArray = window.location.pathname.split(`/`);
  const viewId = pathArray.includes("home")
    ? pathArray[pathArray.indexOf("home") + 1]
    : "";

  return (
    <>
      {isLoadingViews ? (
        <div className="flex flex-col gap-1">
          <Spinner size="sm" />
        </div>
      ) : (
        <div className="flex flex-col gap-1">
          {views?.map((view) => (
            <ItemContainer
              key={view.id}
              isCurrent={viewId === String(view.id)}
              to={`/a/${view.appId}/home/${view.id}`}
              className="group relative flex justify-between"
            >
              <div className="flex items-center justify-center">
                {view.emoji ? view.emoji : "🏠"}
              </div>
            </ItemContainer>
          ))}
        </div>
      )}
    </>
  );
};
