import { ITrait } from "core/types/Trait";
import { getDomainFromEmails } from "core/helpers/domainFromEmails";
import { getContactEmail } from "core/helpers/contactEmail";

interface IUseGroupDomain {
  group: { contacts: { userId: string; traits: ITrait }[]; traits: ITrait };
}

export const useGroupDomain = ({ group }: IUseGroupDomain) => {
  if (group?.traits?.domain) {
    return group.traits.domain;
  }

  const emails = group.contacts?.reduce((emails, contact) => {
    const email = getContactEmail({
      id: contact.userId,
      traits: contact.traits,
    });
    if (email && email !== "Unknown") {
      emails.push(email);
    }
    return emails;
  }, [] as string[]);
  const domain = getDomainFromEmails(emails);

  return domain;
};
