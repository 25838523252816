import React from "react";
import { IReport } from "core/types/Report";
import { useInsightCsvExport } from "core/hooks/useInsightCsvExport";
import { useContactDrilldown } from "core/hooks/useContactDrilldown";
import { ToggleButton } from "core/components/Toggle";
import Error from "core/components/Error";
import { Drilldown } from "core/components/Drilldown";
import { UserListSingleContact } from "core/components/Contact/UserListSingleContact";

interface IOverviewUsers {
  report: IReport;
  isOpen: boolean;
  onClose: () => void;
  milestone: string | undefined;
  milestoneName: string | undefined;
  milestoneConvertedCount: number;
  milestoneDroppedCount: number;
  startDate?: string;
  showCompleted: boolean;
  setShowCompleted: (completed: boolean) => void;
}

export const OverviewUsers: React.FC<IOverviewUsers> = ({
  report,
  isOpen,
  onClose,
  milestone,
  milestoneName,
  milestoneConvertedCount,
  milestoneDroppedCount,
  startDate,
  showCompleted,
  setShowCompleted,
}) => {
  const count = showCompleted ? milestoneConvertedCount : milestoneDroppedCount;

  const { requestCsvExport, csvExportLoading } = useInsightCsvExport({
    report,
    insightId: 4,
    insightParams: {
      milestone,
      completed: showCompleted,
    },
    count: count,
  });

  const {
    contacts,
    isLoading,
    error,
    hasMoreContacts,
    loadMore,
    searchQuery,
    onSearch,
    setSearchQuery,
    setPage,
  } = useContactDrilldown({
    report,
    insightType: 4,
    skip: !isOpen,
    insightParams: {
      milestone,
      completed: showCompleted,
      startDate,
    },
  });

  const handleToggle = (_completed: boolean) => {
    setShowCompleted(_completed);
    setSearchQuery("");
    setPage(0);
  };

  const onDrilldownClose = () => {
    onClose();
    setShowCompleted(true);
    setSearchQuery("");
  };

  if (error) {
    return <Error />;
  }

  return (
    <Drilldown isOpen={isOpen} onClose={onDrilldownClose}>
      {(Drilldown) => (
        <>
          <Drilldown.Header>
            <Drilldown.Title
              isLoading={isLoading}
              title={`${count} users ${
                showCompleted ? "completed" : "have not completed"
              } the milestone ${milestoneName}`}
            />
            <Drilldown.DownloadButton
              isDisabled={count <= 0}
              isLoading={csvExportLoading}
              onClick={requestCsvExport}
            />
            <Drilldown.Toggle>
              <ToggleButton
                isSelected={showCompleted}
                onToggle={() => handleToggle(true)}
              >
                Completed
              </ToggleButton>
              <ToggleButton
                isSelected={!showCompleted}
                onToggle={() => handleToggle(false)}
              >
                Uncompleted
              </ToggleButton>
            </Drilldown.Toggle>
            <Drilldown.Search
              placeholder="Search your users by email address..."
              searchQuery={searchQuery}
              onSearch={onSearch}
              disabled={count <= 0}
            />
          </Drilldown.Header>
          <Drilldown.Body>
            <Drilldown.List
              items={contacts}
              hasMore={hasMoreContacts}
              itemRenderer={({ item }) => {
                return (
                  <UserListSingleContact
                    key={item.userId}
                    id={item.userId}
                    traits={item.traits}
                    isNewUser={item.newUser}
                  />
                );
              }}
              loadNext={loadMore}
              isLoading={isLoading}
              emptyStateText={`No users`}
            />
          </Drilldown.Body>
        </>
      )}
    </Drilldown>
  );
};
