import { capitalize } from "lodash";
import { useToast } from "core/hooks/useToast";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

interface IUninstallModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUninstall: () => void;
  integration?: "hubspot" | "attio";
}

export const UninstallModal = ({
  isOpen,
  onClose,
  onUninstall,
  integration = "hubspot",
}: IUninstallModalProps) => {
  const toast = useToast();

  const integrationName = capitalize(integration);

  return (
    <Modal
      size="lg"
      closeOnEsc
      closeOnOverlayClick
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent borderRadius="lg">
        <ModalHeader fontSize="md">
          Uninstall {integrationName} Integration
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize="sm">
            By doing so, you'll remove the {integrationName} integration and
            stop syncing June data into {integrationName}.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme="red"
            onClick={() => {
              onUninstall();
              toast({
                title: `${integrationName} integration successfully uninstalled`,
              });
            }}
          >
            Uninstall integration
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
