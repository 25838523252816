import React from "react";
import { GroupListSingleGroup } from "core/components/Group/GroupListSingleGroup";
import { IRetentionInsightUser } from "../ChurningUsersContainer";

export const GroupCell: React.FC<{
  props: IRetentionInsightUser;
  previewMode: boolean;
}> = ({ props, previewMode }) => {
  return (
    <GroupListSingleGroup
      p={0}
      key={props.groupId}
      id={props.groupId || ""}
      traits={props.traits}
      previewMode={previewMode}
      truncateName={true}
      hideHoverBorder={true}
      hideExternalLinkIcon={true}
    />
  );
};
