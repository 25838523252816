import { StyleProps } from "@chakra-ui/react";
import avatars from "./avatars";

interface IAvatarIcon extends StyleProps {
  name: string;
}

function AvatarIcon({ name, ...props }: IAvatarIcon) {
  const iconSvg = avatars[name as keyof typeof avatars];

  const IconComponent = iconSvg;
  return <IconComponent {...props} />;
}

export default AvatarIcon;
