import { Box, Flex, Text } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import { QRCode } from "react-qrcode-logo";

export default function QrCode({
  nextUrl = null,
  isLoading = false,
  ...props
}) {
  return (
    <Flex
      position="relative"
      w="100%"
      direction="column"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <QRCode
        value={
          nextUrl
            ? nextUrl
            : "To get started, select an insight widget you'd like to view on your phone."
        }
        logoImage="/logo192.png"
        eyeRadius={5}
        logoWidth={50}
        qrStyle="dots"
        size={200}
        quietZone={0}
        style={{ borderRadius: 5 }}
      />
      {(nextUrl == null || isLoading) && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            background: "rgba(255, 255, 255, 0.9)",
            width: "100%",
            height: "100%",
            top: 0,
          }}
        >
          <Box
            boxShadow="sm"
            backgroundColor="white"
            px={4}
            py={2}
            borderRadius={"full"}
          >
            <Text fontSize="sm" textColor="primary">
              {isLoading ? (
                <Spinner size={"xs"} />
              ) : (
                "Select an insight to continue."
              )}
            </Text>
          </Box>
        </div>
      )}
    </Flex>
  );
}
