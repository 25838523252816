export function getLevelParam(): "user" | "company" | "group" {
  const queryParams = new URLSearchParams(window.location.search);
  return (queryParams.get("level") || "user") as unknown as
    | "user"
    | "company"
    | "group";
}

export function isUserReportLevel(reportLevel?: string) {
  const paramReportLevel = getLevelParam();
  return reportLevel
    ? !["company", "group"].includes(reportLevel)
    : !["company", "group"].includes(paramReportLevel);
}
