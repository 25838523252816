import { ITemplateConfig } from "core/types/TemplateConfig";
import { IEvent } from "core/types/Event";
import GraphContainer from "core/modules/reports/report-types/FeatureAudit/GraphContainer";
import FeatureUsageTable from "core/modules/reports/report-types/FeatureAudit/FeatureUsageTable";
import { ExplorationContainer } from "core/modules/reports/report-types/Explorer/ExplorationContainer";
import { DEFAULT_GROUPED_OPTIONS } from "core/constants/timerange";
import { Template } from "core/constants/templates";
import {
  AUDIENCE_TYPE,
  MULTI_EVENT_TYPE,
  OR,
  PAGE,
  TRACK,
} from "core/constants/report-setup";
import { SimpleStackedLineChart } from "core/components/ViewInsight/Graphs/SimpleStackedLineChart";
import { SimpleScatterPlot } from "core/components/ViewInsight/Graphs/SimpleScatterPlot";
import { FeatureTable } from "core/components/ViewInsight/Graphs/FeatureTable";
import defaultTemplateConfig from "./defaultTemplateConfig";

const explorer: ITemplateConfig = {
  ...defaultTemplateConfig,
  reportType: 18,
  route: "audit",
  aboutTemplate:
    "Find what are the core features in your product and how popular and how frequently they're used.",
  subtitle: "Analyze your current product and see which features are used",
  howItWorksText: "Learn how it works",
  howItWorksLink:
    "https://help.june.so/en/articles/4676097-how-your-feature-audit-is-calculated",
  howToSetupText: "Learn how to set up feature audit",
  howToSetupLink:
    "https://help.june.so/en/articles/4764714-how-to-set-up-the-feature-audit-template",
  title: "Explorer",
  template: Template.Explorer,
  sections: [ExplorationContainer, FeatureUsageTable, GraphContainer],
  insights: [
    {
      typeId: 0,
      slug: "popularity-and-frequency",
      title: "Popularity and frequency",
      section: GraphContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about popularity and frequency",
        content:
          "Popularity is the ratio of the number of uniquely identified users who triggered chosen event(s) at least once in the time range picked, over the number of uniquely identified users in the same time range. Frequency is the ratio of the total number of times that the chosen event is triggered over the total number of triggered events in the same period.",
        link: "https://help.june.so/en/articles/4676097-how-your-feature-audit-is-calculated",
      },
      downloadable: false,
      view: {
        level: "user",
        Component: SimpleScatterPlot,
        transform: (data) => data,
        hasTimerangePicker: true,
        dateRangeOptions: DEFAULT_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 1,
      slug: "feature-usage",
      title: "Total usage and users",
      section: FeatureUsageTable,
      slackAlertAvailable: true,
      view: {
        level: "user",
        Component: FeatureTable,
        transform: (data) => data,
        hasTimerangePicker: true,
        dateRangeOptions: DEFAULT_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 2,
      slug: "exploration",
      title: "Exploration",
      section: ExplorationContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about the explorer",
        content:
          "In the explorer report, you will find the total count of the events you choose and their properties. However, it won't tell you how many users or companies are involved",
      },
      hasYAxis: true,
      view: {
        level: "user",
        Component: SimpleStackedLineChart,
        transform: (data) => {
          const dataArray = data && Object.values(data);
          if (!dataArray) return [];
          return dataArray.map(
            (d: { data: { date: string; count: number }[] }) => {
              return {
                ...d,
                data: d?.data?.map((d: { date: string; count: number }) => {
                  return {
                    date: d.date,
                    count: Number(d.count),
                  };
                }),
              };
            },
          );
        },
        hasTimerangePicker: true,
        dateRangeOptions: DEFAULT_GROUPED_OPTIONS,
      },
    },
  ],
  setup: {
    requiredSections: [0],
    eventOperator: OR,
    setupSections: [
      {
        id: "default_feature",
        configKey: "feature",
        title: "Features",
        description: "Select the events you want to measure for this feature",
        type: MULTI_EVENT_TYPE,
        supportedEventTypes: [TRACK, PAGE],
        required: true,
        ordering: true,
        isRenameable: true,
        isDynamic: true,
        isRemovable: true,
        limit: 100,
        validate: (events) =>
          events &&
          events.length > 0 &&
          events.length ===
            events.map((event) => event.id).filter((x) => x).length,
      },
      {
        id: "audience",
        configKey: "audience",
        title: "Audience",
        description: "Select an audience for your report",
        type: AUDIENCE_TYPE,
        supportedUserTypes: ["user"],
      },
    ],
  },
  hasDateRangeSection: true,
  dateRangeOptions: DEFAULT_GROUPED_OPTIONS,
  validateSetup: (config) =>
    Object.keys(config).some(
      (key) =>
        key.includes("feature") &&
        Array.isArray(config[key]) &&
        config[key].length > 0 &&
        config[key].some((e: IEvent) => e.name),
    ),
};

export default explorer;
