import {
  Flex,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useDataDeletionRequests } from "core/hooks/useDataDeletionRequests";
import { IDataDeletionRequest } from "core/types/DataDeletionRequest";
import moment from "moment";
import { CloseIcon, MinusIcon, CheckIcon } from "@chakra-ui/icons";

enum State {
  Requested = "requested",
  Started = "started",
  Cancelled = "cancelled",
  Finished = "finished",
  Failed = "failed",
}

interface IAppDeletionModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const STATE_ICONS = {
  [State.Requested]: CheckIcon,
  [State.Started]: CheckIcon,
  [State.Finished]: CheckIcon,
  [State.Cancelled]: MinusIcon,
  [State.Failed]: CloseIcon,
};

const STATE_COLOUR = {
  [State.Requested]: "gray.400",
  [State.Started]: "yellow.400",
  [State.Finished]: "green.500",
  [State.Cancelled]: "gray.400",
  [State.Failed]: "red.500",
};

export const HistoryModal: React.FC<IAppDeletionModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { dataDeletionRequests, onCancelDataDeletionRequest } =
    useDataDeletionRequests();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Deletion history</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack gap={2}>
            <Text color="gray.600">
              A history of the data you requested to delete. Data deletions can
              take up to 30 days and are irreversible after completion.
            </Text>
          </Stack>
          <Flex my={5} w="full" gridGap={2} direction="column">
            {dataDeletionRequests.map(
              (dataDeletionRequest: IDataDeletionRequest, i: number) => {
                const Icon = STATE_ICONS[dataDeletionRequest.state];
                return (
                  <Flex
                    px={6}
                    py={4}
                    w="full"
                    key={i}
                    border="1px solid"
                    borderColor="gray.200"
                    borderRadius="lg"
                    justifyContent="space-between"
                  >
                    <Flex align="flex-start" gridGap={3}>
                      <Flex>
                        <Icon
                          h={6}
                          w={6}
                          borderRadius="full"
                          p={2}
                          bg={`${STATE_COLOUR[dataDeletionRequest.state]}`}
                          color="white"
                        />
                      </Flex>
                      <Flex direction="column">
                        <Flex>
                          <Text>Data deletion {dataDeletionRequest.state}</Text>
                        </Flex>
                        <Flex>
                          <Text fontSize="sm" color="gray.600">
                            All data until{" "}
                            {moment(dataDeletionRequest.endDate).format(
                              `D MMM YYYY`,
                            )}
                          </Text>
                        </Flex>
                        {dataDeletionRequest.state === State.Requested && (
                          <Flex mt={4}>
                            <Text fontSize="sm" color="gray.700">
                              You'll get an email confirmation once it's
                              processed. Depending on the size of your data,
                              deletion can take up to 24 hours.
                            </Text>
                          </Flex>
                        )}
                      </Flex>
                    </Flex>
                    {dataDeletionRequest.state === State.Requested && (
                      <Flex>
                        <Button
                          onClick={() =>
                            onCancelDataDeletionRequest(dataDeletionRequest.id)
                          }
                          variant="ghost"
                          colorScheme="purple"
                        >
                          Cancel
                        </Button>
                      </Flex>
                    )}
                  </Flex>
                );
              },
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
