import { IToast } from "core/types/Toast";
import { createSlice } from "@reduxjs/toolkit";

const initialState: { data: IToast[] } = {
  data: [],
};

export const toastsSlice = createSlice({
  name: "toasts",
  initialState,
  reducers: {
    add: (state, { payload }: { payload: IToast }) => {
      state.data.push(payload);
    },
    removeOne: (state, { payload }) => {
      state.data = state.data.filter((t) => t.id !== payload) || [];
    },
  },
});

export default toastsSlice.reducer;
