import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { Alert, CloseButton, Link, useDisclosure } from "@chakra-ui/react";

const LOCAL_STORAGE_ENVIRONMENTS_BANNER = "environments_banner";

export const EnvironmentsBanner = () => {
  const defaultIsOpen =
    localStorage.getItem(LOCAL_STORAGE_ENVIRONMENTS_BANNER) !== "true";
  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen });
  const onCloseBanner = () => {
    localStorage.setItem(LOCAL_STORAGE_ENVIRONMENTS_BANNER, "true");
    onClose();
  };

  if (!isVisible) return null;

  return (
    <Alert
      py={2}
      px={4}
      bg="purple.50"
      justifyContent="space-between"
      borderRadius="lg"
    >
      <div className="flex items-center gap-2">
        <QuestionMarkCircleIcon className="h-5 w-5 text-gray-600" />
        <div className="text-sm">
          To test your setup in a staging or development environment you can
          create a test workspace.{" "}
          <Link
            isExternal
            href="https://help.june.so/en/articles/7102819-testing-data"
          >
            Show me how
          </Link>
        </div>
      </div>
      <CloseButton color="gray.600" onClick={onCloseBanner} />
    </Alert>
  );
};
