import React from "react";

interface IconProps {
  className?: string;
}

export const FirstEventIcon: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.83283 2.31829C6.83283 1.97547 7.11073 1.69756 7.45355 1.69756H12.0886C12.9118 1.69756 13.7012 2.02455 14.2832 2.60659C14.8653 3.18864 15.1923 3.97806 15.1923 4.80119V8.93969C15.1923 9.28251 14.9143 9.56042 14.5715 9.56042C14.2287 9.56042 13.9508 9.28251 13.9508 8.93969V4.80119C13.9508 4.30731 13.7546 3.83366 13.4054 3.48443C13.0562 3.13521 12.5825 2.93901 12.0886 2.93901H7.45355C7.11073 2.93901 6.83283 2.6611 6.83283 2.31829ZM1.32872 5.83607C1.67154 5.83607 1.94944 6.11398 1.94944 6.4568V13.0782C1.94944 13.5721 2.14564 14.0457 2.49486 14.395C2.84409 14.7442 3.31774 14.9404 3.81162 14.9404H6.05815C6.40097 14.9404 6.67888 15.2183 6.67888 15.5611C6.67888 15.9039 6.40097 16.1818 6.05815 16.1818H3.81162C2.98849 16.1818 2.19907 15.8548 1.61702 15.2728C1.03498 14.6907 0.707993 13.9013 0.707993 13.0782V6.4568C0.707993 6.11398 0.985901 5.83607 1.32872 5.83607Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.2605 8.46159L12.6765 11.1696L10.5318 11.5985L10.5288 11.5991C10.3612 11.6335 10.2046 11.7088 10.0731 11.8183C9.94165 11.9278 9.83928 12.0681 9.77513 12.2268L8.89022 14.4414L7.2605 8.46159ZM8.66143 15.014L8.66184 15.013ZM6.82301 7.04197C7.02099 7.01949 7.22124 7.05465 7.39972 7.14321L7.40141 7.14405L13.7735 10.3301C13.9656 10.427 14.1227 10.5813 14.223 10.7716C14.3232 10.9619 14.3617 11.1788 14.3331 11.392C14.3044 11.6052 14.21 11.8041 14.063 11.9612C13.9161 12.1183 13.7238 12.2256 13.513 12.2683L13.5114 12.2686L10.8854 12.7938L9.81508 15.4726V15.4726C9.73478 15.6742 9.59289 15.8461 9.40991 15.9628C9.22673 16.0797 9.01144 16.1359 8.79455 16.1235C8.57765 16.1112 8.37013 16.0309 8.20141 15.894C8.05238 15.7731 7.94029 15.6134 7.87702 15.4333L7.86996 15.4192L5.94047 8.3394C5.88865 8.14701 5.89347 7.94375 5.95435 7.75404C6.01523 7.56432 6.12958 7.39621 6.28367 7.2699C6.43776 7.14359 6.62504 7.06444 6.82301 7.04197Z"
        fill="currentColor"
      />
      <path
        d="M4.97347 0.181824V5.96022H3.75177V1.34145H3.71791L2.39464 2.17097V1.08752L3.82513 0.181824H4.97347Z"
        fill="currentColor"
      />
    </svg>
  );
};
