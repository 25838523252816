import { useState } from "react";
import { AlertsTable } from "modules/Settings/Slack/AlertsTable";
import { useGetEnabledAlertsQuery } from "core/models/alerts";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { Spinner } from "@chakra-ui/react";

export const EnabledAlerts: React.FC = () => {
  const currentApp = useCurrentApp();
  const [page, setPage] = useState<number>(1);

  const { data: enabledAlerts, isLoading: enabledAlertsLoading } =
    useGetEnabledAlertsQuery({
      appId: currentApp.id,
      page,
    });

  return (
    <div className="flex flex-col gap-4">
      {!enabledAlertsLoading && enabledAlerts ? (
        <AlertsTable
          pagy={enabledAlerts.pagy}
          alerts={enabledAlerts.alerts}
          setPage={setPage}
        />
      ) : (
        <Spinner />
      )}
    </div>
  );
};
