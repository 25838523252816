import {
  useCreateLabelMutation,
  useDeleteLabelMutation,
  useUpdateLabelMutation,
  useGetLabelsQuery,
} from "core/models/labels";

interface IUseLabels {
  appId: string | number;
}

export const useLabels = ({ appId }: IUseLabels) => {
  const {
    data: labels,
    isLoading,
    isUninitialized,
  } = useGetLabelsQuery({ appId });
  const [createLabel] = useCreateLabelMutation();
  const [deleteLabel] = useDeleteLabelMutation();
  const [updateLabel] = useUpdateLabelMutation();

  return {
    labels,
    isLoading,
    isUninitialized,
    createLabel,
    deleteLabel,
    updateLabel,
  };
};
