import { ReferenceLine } from "recharts";
// @ts-nocheck
import { useState } from "react";
import { simplifyXAxisTicks } from "helpers/xAxis";
import colors from "core/design-system/constants/theme/colors";
import TickText from "core/design-system/charts/TickText";
import {
  CommonDot,
  CommonGrid,
  CommonTooltip,
  CommonXAxis,
  CommonYAxis,
} from "core/design-system/charts/Common";
import { Area, AreaChart } from "core/design-system/charts/AreaChart";
import { Box, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { FeatureUsageList } from "./FeatureUsageList";

interface ILineChart {
  unit: string;
  data: any;
  dataKey: string;
  previewMode: boolean;
  sharingMode: boolean;
  hasHour: boolean;
  intervalType: number;
  reportId?: number;
  groupId?: string;
  showGrid?: boolean;
  simplifyXAxis?: boolean;
}

function LineChart({
  unit,
  data,
  report,
  dataKey = "usageCount",
  previewMode,
  sharingMode,
  intervalType,
  hasHour,
  showYAxis,
  showGrid,
  simplifyXAxis,
  h = 300,
}: ILineChart) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [humanizedDate, setHumanizedDate] = useState("");
  const [date, setDate] = useState("");
  const [hour, setHour] = useState();
  const [usageCount, setUsageCount] = useState(0);
  const [uniqueUserCount, setUniqueUserCount] = useState(0);

  let marker;

  const formattedData = data.reduce((formatted, row, currentIndex) => {
    const lastRow = formatted[formatted.length - 1];
    const nextRow = data[currentIndex + 1];

    if (!marker && row.incomplete) {
      marker = lastRow.x;
    }

    formatted.push({
      x: row.chartDate ? row.chartDate : row.humanizedDate,
      y: row[dataKey],
      usageCount: !row.incomplete ? row[dataKey] : undefined,
      currentUsageCount:
        nextRow?.incomplete || row.incomplete ? row[dataKey] : undefined,
      uniqueUserCount: row.uniqueUserCount,
      humanizedDate: row.humanizedDate,
      date: row.date,
      hour: row.hour,
    });
    return formatted;
  }, []);

  const { firstTick, lastTick } = simplifyXAxisTicks(formattedData, "x");

  function CustomTooltip({ label, payload, active }) {
    if (active && payload && payload.length) {
      return (
        <Box w="200px" bg="gray.900" px={3} py={2} borderRadius={5}>
          <Text fontSize="xs" color="gray.400">
            {payload[0].payload.humanizedDate}
          </Text>
          <Text fontSize="sm" color="white">
            {payload[0].payload.y} times
          </Text>
          {!previewMode && (
            <Text mt={2} fontSize="xs" color="gray.400">
              Click on the chart to see the list of {unit}
            </Text>
          )}
        </Box>
      );
    }
    return null;
  }

  const onDataPointClick = (_data, index) => {
    if (!previewMode && !sharingMode) {
      onOpen();
      setHumanizedDate(index.payload.x);
      setDate(index.payload.date);
      setUsageCount(
        index.payload.usageCount || index.payload.currentUsageCount,
      );
      setUniqueUserCount(index.payload.uniqueUserCount);
      if (hasHour) {
        setHour(index.payload.hour);
      }
    }
  };

  return (
    <Flex justify="center" h={h}>
      <AreaChart
        margin={{ top: 30, left: showYAxis ? 0 : -30, right: 50, bottom: 20 }}
        height="100%"
        data={formattedData}
      >
        <defs>
          <linearGradient id="purpleGradient" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor={colors.purple[400]}
              stopOpacity={0.8}
            />
            <stop
              offset="95%"
              stopColor={colors.purple[100]}
              stopOpacity={0.4}
            />
          </linearGradient>
        </defs>
        <defs>
          <linearGradient id="greyGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={colors.gray[400]} stopOpacity={0.8} />
            <stop offset="95%" stopColor={colors.gray[100]} stopOpacity={0.4} />
          </linearGradient>
        </defs>
        {showGrid && <CommonGrid />}
        <CommonXAxis
          ticks={simplifyXAxis ? [firstTick, lastTick] : ""}
          dataKey="x"
        />
        <CommonYAxis
          tick={({ x, ...props }) =>
            showYAxis && (
              <TickText x={x - 5} {...props}>
                {props.payload.value}
              </TickText>
            )
          }
        />
        <ReferenceLine
          x={marker}
          stroke={colors.gray[600]}
          strokeDasharray="3 3"
        />
        <CommonTooltip content={<CustomTooltip />} />
        <Area
          stackId="1"
          name="Previous usage"
          dataKey="usageCount"
          showLegend={true}
          activeDot={
            <CommonDot
              onClick={onDataPointClick}
              r={7}
              cursor={!previewMode && "pointer"}
            />
          }
          type="monotone"
          fillOpacity={1}
          fill="url(#purpleGradient)"
        />
        <Area
          stackId="0"
          name="Current usage"
          dataKey="currentUsageCount"
          showLegend={false}
          fill="url(#greyGradient)"
          stroke={colors.gray[500]}
          type="monotone"
          activeDot={
            <CommonDot
              onClick={onDataPointClick}
              r={7}
              cursor={!previewMode && "pointer"}
            />
          }
        />
      </AreaChart>
      {!sharingMode && !previewMode && (
        <FeatureUsageList
          key={date}
          isOpen={isOpen}
          onClose={onClose}
          humanizedDate={humanizedDate}
          date={date}
          hour={hour}
          usageCount={usageCount}
          uniqueUserCount={uniqueUserCount}
          intervalType={intervalType}
          unit={unit}
          report={report}
        />
      )}
    </Flex>
  );
}

export default LineChart;
