import { IReportInsight } from "core/types/Report";
import { ReferrersContainer } from "./ReferrersContainer";

export const SignupReferrers: React.FC<IReportInsight> = ({
  previewMode,
  sharingMode,
  screenshotMode,
  sharingSecretToken,
  report,
  config,
}) => {
  return (
    <ReferrersContainer
      previewMode={previewMode}
      sharingMode={sharingMode}
      screenshotMode={screenshotMode}
      sharingSecretToken={sharingSecretToken}
      report={report}
      config={config}
      insightSlug="signup-referrers"
    />
  );
};
