import Tooltip from "./Tooltip";
import Table from "./Table";
import Switch from "./Switch";
import Spinner from "./Spinner";
import Progress from "./Progress";
import Link from "./Link";
import Input from "./Input";
import Drawer from "./Drawer";
import Button from "./Button";
import Badge from "./Badge";
import Alert from "./Alert";
import Menu from "./Menu";
import { Accordion } from "./Accordion";
import { Select } from "core/design-system/components/Select";

const Components = {
  Button,
  Tooltip,
  Spinner,
  Switch,
  Badge,
  Progress,
  Input,
  Link,
  Table,
  Drawer,
  Alert,
  Menu,
  Accordion,
  Select,
};

export default Components;
