import moment from "moment";
import { cx } from "helpers/cx";
import { IUser } from "core/types/User";
import { Avatar, Tooltip } from "@chakra-ui/react";

export const EditedByUser: React.FC<{
  user: IUser;
  timestamp: string;
  action: string;
  showAvatar?: boolean;
}> = ({ user, timestamp, action, showAvatar = true }) => {
  return (
    <div className="flex flex-row items-center gap-1">
      {showAvatar && (
        <Avatar
          size="2xs"
          bg={"purple.400"}
          name={user.email}
          src={user.imageUrl}
        />
      )}
      <p className="text-xs text-gray-500">
        {action} by
        <p
          className={cx(
            "mx-1 inline-block text-black",
            !showAvatar && "text-gray-500",
          )}
        >
          {user.firstName || user.email}
        </p>
        <Tooltip
          hasArrow
          label={moment(timestamp).format(`Do [of] MMMM, YYYY [at] HH:mm:ss`)}
        >
          <p className="inline-block">{moment.utc(timestamp).fromNow()}</p>
        </Tooltip>
      </p>
    </div>
  );
};
