import React, { useState } from "react";
import { IntegrationMetadata } from "modules/Settings/Integrations/Integration";
import { Settings } from "modules/Settings/Integrations/Attio/Settings";
import { Action } from "modules/Settings/Integrations/Attio/Action";
import { usePaywall } from "core/hooks/usePaywall";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useAttioSettings } from "core/hooks/useAttioSettings";
import TeamIcon from "core/design-system/components/Icon/Interface/TeamIcon";
import KeepControlIcon from "core/design-system/components/Icon/Interface/KeepControlIcon";
import InstantIcon from "core/design-system/components/Icon/Interface/InstantIcon";
import CelebrateIcon from "core/design-system/components/Icon/Interface/CelebrateIcon";
import AttioIcon from "core/design-system/components/Icon/Interface/AttioIcon";
import { ATTIO } from "core/constants/features";
import { ArrowsRightLeftIcon } from "@heroicons/react/24/outline";
import {
  Box,
  Center,
  Flex,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import * as Integration from "../../../modules/Settings/Integrations/Integration";

const CONTENT: IntegrationMetadata = {
  title: "Attio",
  description: "Send your June data to Attio",
  logoComponent: <AttioIcon w="full" h="full" />,
  isBeta: false,
  docsUrl: "https://help.june.so/en/articles/8376351-attio-integration",
};

export const PaywallUpgradeHeader: React.FC = () => {
  return (
    <Text fontWeight="bold" fontSize="2xl">
      Get access to the Attio
    </Text>
  );
};

export const PaywallUpgradeSubHeader: React.FC = () => {
  return (
    <Text color="gray.700" fontSize="md" fontWeight="normal" px={10}>
      Upgrade to start using the{" "}
      <Text as="span" fontWeight="bold">
        Attio integration
      </Text>{" "}
      and with June
    </Text>
  );
};

const Attio: React.FC = () => {
  const { id, features } = useCurrentApp();

  const [shouldShowPaywallModal, setShouldShowPaywallModal] =
    useState<boolean>(false);
  const hasAttioFeature = features?.includes(ATTIO);

  const { setting, isLoadingSetting, onDeleteSettings } = useAttioSettings();
  const {
    isOpen: isConfirmationOpen,
    onClose: onCloseConfirmation,
    onOpen: onOpenConfirmation,
  } = useDisclosure();
  const { shouldBePaywalled } = usePaywall();

  return (
    <Integration.Container isExpanded>
      <Integration.Header
        metadata={CONTENT}
        ActionComponent={() => (
          <Action
            {...{
              hasAttioInstalled: setting?.installed || false,
              hasAttioFeature,
              onOpenConfirmation,
              isConfirmationOpen,
              onCloseConfirmation,
              onDeleteSettings,
              id,
              setShouldShowPaywallModal,
              shouldShowPaywallModal,
              shouldBePaywalled,
            }}
          />
        )}
      />
      <Integration.Body>
        <Integration.Section>
          {isLoadingSetting || !setting ? (
            <div className="flex w-full items-center justify-center py-12">
              <Spinner size="md" />
            </div>
          ) : (
            <>
              {setting.installed ? (
                <Settings />
              ) : (
                <Integration.Collapsable
                  title={"Connect your Attio account"}
                  headerIcon={<ArrowsRightLeftIcon width={20} height={20} />}
                >
                  <Flex gridGap={4}>
                    <Flex direction="column" gridGap={4} py={6}>
                      <Flex direction="row" gridGap={4}>
                        <Flex>
                          <InstantIcon color="primary" h={7} w={7} />
                        </Flex>
                        <Flex direction="column">
                          <Text fontSize="sm" fontWeight="bold">
                            Turn your June data into pipeline
                          </Text>
                          <Text fontSize="sm" fontWeight="sm" color="gray.600">
                            Connect your product usage data to your CRM to get a
                            complete view of your relationships.
                          </Text>
                        </Flex>
                      </Flex>
                      <Flex direction="row" gridGap={4}>
                        <Flex>
                          <CelebrateIcon h={7} w={7} />
                        </Flex>
                        <Flex direction="column">
                          <Text fontSize="sm" fontWeight="bold">
                            Automatically sync users and companies
                          </Text>
                          <Text fontSize="sm" fontWeight="sm" color="gray.600">
                            Automatically create and update Attio users and
                            workspaces when they get created and updated in
                            June.
                          </Text>
                        </Flex>
                      </Flex>
                      <Flex direction="row" gridGap={4}>
                        <Flex>
                          <KeepControlIcon h={7} w={7} />
                        </Flex>
                        <Flex direction="column">
                          <Text fontSize="sm" fontWeight="bold">
                            Control what data is sent to Attio from June
                          </Text>
                          <Text fontSize="sm" fontWeight="sm" color="gray.600">
                            Map the June usage data you want to send to your
                            team in Attio so its always up to date.
                          </Text>
                        </Flex>
                      </Flex>
                      <Flex direction="row" gridGap={4}>
                        <Flex>
                          <TeamIcon color="purple.500" h={7} w={7} />
                        </Flex>
                        <Flex direction="column">
                          <Text fontSize="sm" fontWeight="bold">
                            Rally your team behind your most important metrics
                          </Text>
                          <Text fontSize="sm" fontWeight="sm" color="gray.600">
                            Get everyone up to speed with what's happening
                            inside your company, even your Sales and Customer
                            Success teams.
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex w="100%">
                      <Center w="100%">
                        <Box
                          borderRadius="lg"
                          h="350px"
                          w="100%"
                          bgImage={`url('/empty-at.png')`}
                          bgRepeat="no-repeat"
                          bgPosition="center"
                          bgSize="contain"
                        />
                      </Center>
                    </Flex>
                  </Flex>
                </Integration.Collapsable>
              )}
            </>
          )}
        </Integration.Section>
      </Integration.Body>
    </Integration.Container>
  );
};

export default Attio;
