import { useEffect, useState } from "react";
import { useMauThreshold } from "core/hooks/useMauThreshold";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { baseUrl } from "core/constants/paywall";
import {
  IPaywallModalChildren,
  PaywallModal,
} from "core/components/Paywall/Modal";
import { MauLimit } from "core/components/Paywall/MauLimit";
import { HeaderTextComponent } from "./HeaderText";

export const Paywall: React.FC = () => {
  const currentApp = useCurrentApp();

  const {
    isApproachingLimit: isApproachingMauLimit,
    isAboveLimit: isAboveMauLimit,
    isTrialEnded,
  } = useMauThreshold();

  const [isMauPaywallModalShowing, setMauPaywallModalShowing] = useState(false);

  useEffect(() => {
    if (
      !currentApp?.isPaying &&
      !currentApp?.isTrialing &&
      !currentApp?.isPaymentOverdue &&
      (isApproachingMauLimit || isAboveMauLimit)
    ) {
      setMauPaywallModalShowing(true);
    }
    if (isTrialEnded) {
      setMauPaywallModalShowing(true);
    }
  }, [currentApp, isApproachingMauLimit, isAboveMauLimit, isTrialEnded]);

  return (
    <PaywallModal
      HeaderTextComponent={HeaderTextComponent}
      CallToActionComponent={MauLimit}
      redirectUrl={`${baseUrl}/a/${currentApp.id}/getting-started`}
      isCloseable={
        (currentApp.isTrialing && !currentApp.noCardTrialEnded) ||
        isApproachingMauLimit
      }
    >
      {(paywall: IPaywallModalChildren) => (
        <paywall.Modal
          onClose={() => setMauPaywallModalShowing(false)}
          isOpen={isMauPaywallModalShowing}
        />
      )}
    </PaywallModal>
  );
};
