import { useNavigate } from "react-router-dom";
import moment from "moment";
import { pluralize } from "helpers/pluralize";
import { cx } from "helpers/cx";
import { IHomeFeature } from "core/types/HomeFeature";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { thousandsToK } from "core/helpers/thousands";
import { Ping } from "core/design-system/components/Ping";
import { GroupType } from "core/components/Group/GroupListSingleGroup";
import { Progress, Spinner, Tooltip } from "@chakra-ui/react";

const getTooltipText = ({
  isImplemented,
  isBeingUsed,
  adoptionValue,
  lastUsedTimestamp,
  reportName,
}: {
  isImplemented: boolean;
  isBeingUsed: boolean;
  adoptionValue: number;
  lastUsedTimestamp: string;
  reportName: string;
}) => {
  if (!isImplemented) return "This feature is not yet implemented.";
  if (!adoptionValue) return "This feature has not been used yet.";
  if (isBeingUsed) return "This feature is currently being used.";
  return `Last time ${reportName} was used was ${moment.utc(lastUsedTimestamp).fromNow()}.`;
};

export const Feature: React.FC<{
  feature: IHomeFeature;
  showAdoption?: boolean;
  showUserCount?: boolean;
  showUsageCount?: boolean;
  showRetention?: boolean;
  showReleaseDate?: boolean;
  showDividers?: boolean;
  groupId?: string;
  groupType?: GroupType;
}> = ({
  feature,
  showAdoption = true,
  showUserCount = true,
  showUsageCount = true,
  showRetention = true,
  showReleaseDate = true,
  showDividers = true,
  groupId,
  groupType,
}) => {
  const { id: appId } = useCurrentApp();
  const navigate = useNavigate();
  const {
    isImplemented,
    reportName,
    reportId,
    retention,
    adoption,
    featureReleasedTimestamp,
    usage,
    lastUsedTimestamp,
  } = feature;
  const featureReleasedValue =
    featureReleasedTimestamp &&
    !featureReleasedTimestamp.includes(`1970-01-01`) &&
    moment(featureReleasedTimestamp).fromNow();
  const isBeingUsed = moment().diff(moment(lastUsedTimestamp), "hours") <= 1;

  return (
    <tr
      data-testid="feature-row"
      className="cursor-pointer rounded-md hover:bg-gray-50"
      onClick={() => {
        groupId
          ? navigate(
              `/a/${appId}/report/${reportId}?level=user&groupId=${groupId}&groupType=${groupType === GroupType.Group ? "group" : "company"}`,
            )
          : navigate(`/a/${appId}/report/${reportId}`);
      }}
    >
      <td
        className={cx("max-w-[200px] px-2 py-1.5 pr-3", showDividers && "py-2")}
      >
        <div className="flex items-center gap-2">
          <Tooltip
            label={getTooltipText({
              isImplemented,
              reportName,
              isBeingUsed,
              lastUsedTimestamp,
              adoptionValue: adoption.value,
            })}
            hasArrow
            placement="top"
            shouldWrapChildren
          >
            {isImplemented ? (
              isBeingUsed ? (
                <Ping mr={2} />
              ) : (
                <Ping mr={2} animate={false} color={"gray.300"} />
              )
            ) : (
              <Spinner
                speed="2s"
                size="xs"
                color="purple.500"
                emptyColor="gray.300"
                mr={2}
              />
            )}
          </Tooltip>
          <p className="truncate text-sm">{reportName}</p>
        </div>
      </td>
      {showAdoption && (
        <td className={cx("py-1.5 pr-10", showDividers && "py-3")}>
          <div className="flex w-full flex-row items-center">
            <Progress
              maxW="full"
              colorScheme={"purple"}
              value={adoption.value}
              size="sm"
              borderRadius="lg"
              w="60%"
              mr={2}
            />
            <p className="counter text-sm">{adoption.value}%</p>
          </div>
        </td>
      )}
      {showUserCount && (
        <td className={cx("py-1.5 pr-10", showDividers && "py-3")}>
          <div className="flex flex-row items-center justify-between">
            <p className="counter truncate text-sm">
              {adoption.count.toLocaleString()} users
            </p>
          </div>
        </td>
      )}
      {showUsageCount && (
        <td className={cx("max-w-full py-1.5 pr-10", showDividers && "py-3")}>
          <div className="flex flex-row items-center">
            <p className="truncate text-sm">
              {usage
                ? `${thousandsToK(usage).toLocaleString()} ${pluralize(usage, "time", "times")}`
                : "0 times"}
            </p>
          </div>
        </td>
      )}
      {showRetention && (
        <td className={cx("py-1.5 pr-10", showDividers && "py-3")}>
          <div className="flex flex-row items-center">
            <Progress
              colorScheme={"purple"}
              value={retention}
              size="sm"
              borderRadius="lg"
              w="60%"
              mr={2}
            />
            <p className="counter text-sm">{retention}%</p>
          </div>
        </td>
      )}
      {showReleaseDate && (
        <td className={cx("py-1.5 pr-10", showDividers && "py-3")}>
          <div className="flex flex-row items-center">
            {featureReleasedValue ? (
              <p className="truncate text-sm">{featureReleasedValue}</p>
            ) : (
              <p className="cursor-pointer truncate text-sm font-medium text-purple-500">
                Finish setup
              </p>
            )}
          </div>
        </td>
      )}
    </tr>
  );
};
