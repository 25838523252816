import React, { ReactNode } from "react";
import { Box, Flex, FlexProps } from "@chakra-ui/react";

interface BodyProps extends FlexProps {
  children: ReactNode;
  ml?: string;
}

export const Body: React.FC<BodyProps> = ({
  children,
  ml = "297px",
  ...props
}) => {
  return (
    <Flex ml={ml} align="center" justify="flex-start" {...props}>
      <Flex direction="column" px={16}>
        <Box pb={8} pt={4}>
          {children}
        </Box>
      </Flex>
    </Flex>
  );
};
