import { useState } from "react";

export function useSidebarCollapse() {
  const [isViewsCollapsed, setIsViewsCollapsed] = useState(
    localStorage.getItem("isViewsCollapsed") === "true",
  );
  const [isAudiencesCollapsed, setIsAudiencesCollapsed] = useState(
    localStorage.getItem("isAudiencesCollapsed") === "true",
  );
  const [isFavouritesCollapsed, setIsFavouritesCollapsed] = useState(
    localStorage.getItem("isFavouritesCollapsed") === "true",
  );

  function toggleViewsCollapse() {
    const newValue = !isViewsCollapsed;
    setIsViewsCollapsed(newValue);
    localStorage.setItem("isViewsCollapsed", newValue.toString());
  }

  function toggleAudiencesCollapse() {
    const newValue = !isAudiencesCollapsed;
    setIsAudiencesCollapsed(newValue);
    localStorage.setItem("isAudiencesCollapsed", newValue.toString());
  }

  function toggleFavouritesCollapse() {
    const newValue = !isFavouritesCollapsed;
    setIsFavouritesCollapsed(newValue);
    localStorage.setItem("isFavouritesCollapsed", newValue.toString());
  }

  return {
    isViewsCollapsed,
    setIsViewsCollapsed,
    isAudiencesCollapsed,
    setIsAudiencesCollapsed,
    isFavouritesCollapsed,
    setIsFavouritesCollapsed,
    toggleViewsCollapse,
    toggleAudiencesCollapse,
    toggleFavouritesCollapse,
  };
}
