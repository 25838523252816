import React from "react";
import { createIcon } from "@chakra-ui/icons";

const CelebrateIcon = createIcon({
  path: (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9987 19.8466L18.6733 21.252C19.1933 21.5253 19.8027 21.084 19.7027 20.504L19.192 17.5266L21.356 15.42C21.7773 15.0093 21.5453 14.2933 20.9627 14.2093L17.9733 13.7746L16.636 11.064C16.376 10.536 15.624 10.536 15.3627 11.064L14.0267 13.7746L11.0373 14.2093C10.4547 14.2933 10.2227 15.0093 10.644 15.42L12.808 17.5266L12.2973 20.504C12.1973 21.084 12.8067 21.5253 13.3267 21.252L16.0013 19.8466H15.9987V19.8466Z"
        stroke="#6868F7"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.6666 16.0007H27.2"
        stroke="#6868F7"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.79992 16.0007H3.33325"
        stroke="#6868F7"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.1466 6.85352L23.7466 8.25352"
        stroke="#6868F7"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.87988 25.1868L8.29322 23.7734"
        stroke="#6868F7"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.85327 6.88086L8.25327 8.29419"
        stroke="#6868F7"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.1865 25.148L23.7732 23.748"
        stroke="#6868F7"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9999 27.2539V28.6672"
        stroke="#6868F7"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9999 3.33398V4.74732"
        stroke="#6868F7"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
});

export default CelebrateIcon;
