import React from "react";

interface IconProps {
  className?: string;
}

export const AnyEventIcon: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.75178 2.29118C6.75178 1.95219 7.02659 1.67738 7.36558 1.67738H11.9489C12.7629 1.67738 13.5435 2.00072 14.119 2.57627C14.6946 3.15182 15.0179 3.93243 15.0179 4.74637V8.83869C15.0179 9.17768 14.7431 9.45249 14.4041 9.45249C14.0651 9.45249 13.7903 9.17768 13.7903 8.83869V4.74637C13.7903 4.25801 13.5963 3.78964 13.251 3.44431C12.9057 3.09898 12.4373 2.90498 11.9489 2.90498H7.36558C7.02659 2.90498 6.75178 2.63017 6.75178 2.29118ZM1.3091 6.75178C1.64809 6.75178 1.9229 7.02659 1.9229 7.36558V12.931C1.9229 13.4194 2.1169 13.8877 2.46223 14.2331C2.80756 14.5784 3.27593 14.7724 3.7643 14.7724H5.98576C6.32475 14.7724 6.59955 15.0472 6.59955 15.3862C6.59955 15.7252 6.32475 16 5.98576 16H3.7643C2.95035 16 2.16974 15.6767 1.59419 15.1011C1.01864 14.5256 0.695304 13.745 0.695304 12.931V7.36558C0.695304 7.02659 0.970111 6.75178 1.3091 6.75178Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.17469 8.36592L12.5303 11.0437L10.4095 11.4679L10.4065 11.4685C10.2408 11.5024 10.086 11.5769 9.95594 11.6852C9.82592 11.7935 9.72468 11.9322 9.66125 12.0891L8.78621 14.279L7.17469 8.36592ZM8.55998 14.8452L8.56039 14.8442ZM6.74208 6.96214C6.93785 6.93992 7.13586 6.97469 7.31235 7.06226L7.31403 7.06309L13.615 10.2136C13.8049 10.3094 13.9603 10.462 14.0594 10.6502C14.1586 10.8384 14.1967 11.0528 14.1683 11.2636C14.14 11.4744 14.0466 11.6712 13.9013 11.8265C13.756 11.9818 13.5658 12.0879 13.3574 12.1302L13.3558 12.1305L10.7592 12.6498L9.70076 15.2987V15.2987C9.62136 15.498 9.48104 15.668 9.30011 15.7834C9.11898 15.899 8.90609 15.9546 8.69161 15.9423C8.47713 15.9301 8.27193 15.8507 8.1051 15.7154C7.95772 15.5958 7.84689 15.438 7.78432 15.2598L7.77734 15.2459L5.86939 8.2451C5.81814 8.05485 5.82291 7.85386 5.88311 7.66627C5.94331 7.47867 6.05639 7.31243 6.20876 7.18753C6.36113 7.06263 6.54631 6.98437 6.74208 6.96214Z"
        fill="currentColor"
      />
      <path
        d="M2.24994 5.89247L2.37985 3.91296L0.696288 5.02292L0 3.81578L1.81347 2.94623L0 2.07668L0.696288 0.869548L2.37985 1.9795L2.24994 0H3.64772L3.51262 1.9795L5.19618 0.869548L5.89247 2.07668L4.0842 2.94623L5.89247 3.81578L5.19618 5.02292L3.51262 3.91296L3.64772 5.89247H2.24994Z"
        fill="currentColor"
      />
    </svg>
  );
};
