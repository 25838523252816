import { useEffect, useRef } from "react";
import { Input } from "@chakra-ui/react";

export const ValueInput: React.FC<{
  traitValue: string;
  onValueChange: (val: string) => void;
}> = ({ traitValue, onValueChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  return (
    <Input
      focusBorderColor="purple.500"
      size="sm"
      type="text"
      ref={inputRef}
      placeholder="Enter a value"
      value={traitValue}
      onChange={(e) => onValueChange(e.currentTarget.value)}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          onValueChange(e.currentTarget.value);
        }
      }}
      borderRadius={5}
      data-testid="audience-trait-value-input"
    />
  );
};
