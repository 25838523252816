import { DataSource } from "core/models/onboardingChecklist";
import { IApiKey } from "core/models/apiKeys";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useApiKeys } from "core/hooks/useApiKeys";
import { ComponentDefaultProps, Flex, Spinner } from "@chakra-ui/react";
import CreateApiKey from "./CreateApiKey";
import ApiKeysEmptyState from "./ApiKeysEmptyState";
import ApiKeyActionsMenu from "./ApiKeys/ActionsMenu";
import ApiKey from "./ApiKey";

interface IApiKeys extends ComponentDefaultProps {
  source: DataSource;
  disableActions?: boolean;
}

const ApiKeys: React.FC<IApiKeys> = ({ source, disableActions = false }) => {
  const { id: appId } = useCurrentApp();
  const { apiKeys, isFetching } = useApiKeys({ appId, source });

  if (isFetching && !apiKeys) return <Spinner />;

  return (
    <Flex direction="column" w="full">
      <Flex direction="column" gridGap={1} w="full">
        {apiKeys?.length === 0 && <ApiKeysEmptyState />}
        {apiKeys?.map((key: IApiKey) => (
          <Flex key={key.id} gap={2} align="center" justify={"space-between"}>
            <ApiKey apiKey={key} />
            {!disableActions && (
              <Flex>
                <ApiKeyActionsMenu source={source} appId={appId} apiKey={key} />
              </Flex>
            )}
          </Flex>
        ))}
      </Flex>
      <Flex mt={3} justify="flex-end">
        <CreateApiKey source={source} />
      </Flex>
    </Flex>
  );
};
export default ApiKeys;
