import { SnakeCasedProperties } from "type-fest/source/snake-cased-properties";
import humps from "humps";
import { baseQuery } from "core/initializers/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";
import { IEnrichedEmail } from "../types/EnrichedEmail";

export const enrichedEmailApi = createApi({
  baseQuery,
  reducerPath: "enrichedEmailApi",
  tagTypes: ["EnrichedEmail"],
  endpoints: (builder) => ({
    getEnrichedEmail: builder.query<
      IEnrichedEmail,
      { appId: string | number; email: string }
    >({
      query: ({ appId, email }) => ({
        url: `/enriched_emails/${appId}`,
        params: humps.decamelizeKeys({ appId, email }),
      }),
      providesTags: ["EnrichedEmail"],
      transformResponse: (response: SnakeCasedProperties<IEnrichedEmail>) =>
        humps.camelizeKeys(response) as IEnrichedEmail,
    }),
    createEnrichedEmail: builder.mutation<
      IEnrichedEmail,
      { appId: string | number; email: string }
    >({
      query: ({ appId, email }) => ({
        url: `/enriched_emails/${appId}`,
        method: "PUT",
        params: humps.decamelizeKeys({ appId, email }),
      }),
      invalidatesTags: ["EnrichedEmail"],
      transformResponse: (response: SnakeCasedProperties<IEnrichedEmail>) =>
        humps.camelizeKeys(response) as IEnrichedEmail,
    }),
  }),
});

export const { useGetEnrichedEmailQuery, useCreateEnrichedEmailMutation } =
  enrichedEmailApi;
