export enum TemplateCategory {
  ProductUsage = "Product usage",
  Activate = "Activate users",
  Retain = "Retain users",
}

export enum Template {
  Explorer = "Explorer",
  FeatureReport = "Feature report",
  Funnel = "Funnel",
  Activation = "Activation",
  ActiveUsers = "Active users",
  NewSignups = "New signups",
  SlippingAway = "Churn risk",
  Engagement = "Most engaged",
  PowerUsers = "Power users",
  Retention = "Retention",
}

export const TEMPLATE_CATEGORIES = {
  [TemplateCategory.ProductUsage]: [
    Template.Explorer,
    Template.FeatureReport,
    Template.Funnel,
  ],
  [TemplateCategory.Activate]: [
    Template.Activation,
    Template.ActiveUsers,
    Template.NewSignups,
  ],
  [TemplateCategory.Retain]: [Template.PowerUsers, Template.Retention],
};

export const TEMPLATES = {
  [Template.Explorer]: {
    title: "Explorer",
    description:
      "Analyze your current product and see which features are used the most.",
  },
  [Template.Funnel]: {
    title: "Funnel",
    description:
      "Measure drop-offs in your product: sign up, onboarding, purchasing or invite flows.",
  },
  [Template.FeatureReport]: {
    title: "Feature report",
    description:
      "See the discovery, adoption, retention, and usage of your feature.",
  },
  [Template.Activation]: {
    title: "Activation",
    description:
      "See the discovery, adoption, retention, and usage of your feature.",
  },
  [Template.ActiveUsers]: {
    title: "Active users",
    description:
      "Measure drop-offs in your product: sign up, onboarding, purchasing or invite flows.",
  },
  [Template.NewSignups]: {
    title: "New signups",
    description:
      "Explore your data and compare the core pages and events for your product.",
  },
  [Template.SlippingAway]: {
    title: "Churn risk",
    description:
      "Explore your data and compare the core pages and events for your product.",
  },
  [Template.Engagement]: {
    title: "Most engaged",
    description:
      "See the discovery, adoption, retention, and usage of your feature.",
  },
  [Template.PowerUsers]: {
    title: "Power users",
    description:
      "Analyze your current product and see which features are used the most.",
  },
  [Template.Retention]: {
    title: "Retention",
    description:
      "Measure drop-offs in your product: sign up, onboarding, purchasing or invite flows.",
  },
};
