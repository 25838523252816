import { Avatar } from "@chakra-ui/react";
import { useGroup } from "core/hooks/useGroup";
import { IFavourite } from "core/models/favourites";
import { useEffect } from "react";
import { LoadingItem } from "./LoadingItem";

export const GroupItem: React.FC<{ favourite: IFavourite }> = ({
  favourite,
}) => {
  const { fetchGroup, groupLoading, groupError, group } = useGroup({
    id: favourite.objectId,
  });

  useEffect(() => {
    fetchGroup(favourite.objectId, favourite.appId);
  }, [favourite, fetchGroup]);

  if (groupError || !group) return <></>;

  if (groupLoading) return <LoadingItem />;

  return (
    <div className="flex flex-row items-center gap-2">
      <Avatar size="2xs" name={group?.name || favourite.objectId} />
      <p className="text-[13px]">{group?.name || favourite.objectId}</p>
    </div>
  );
};
