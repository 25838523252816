import React, { useState } from "react";
import { IAlert } from "core/types/Alert";
import { useGetSlackChannelsQuery } from "core/models/slackApps";
import { useEventAlerts } from "core/hooks/useEventAlerts";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import {
  Box,
  Button,
  Flex,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import EventAlertEditor from "./EventAlertEditor";

interface IEventAlertProps {
  eventId: number;
  onClose: () => void;
}

export const EventAlerts: React.FC<IEventAlertProps> = ({
  eventId,
  onClose,
}) => {
  const [showDeleteIcon, setShowDeleteIcon] = useState<number | null>(null);
  const currentApp = useCurrentApp();
  const appId = currentApp.id;
  const [alertIndex, setAlertIndex] = useState<number>(0);
  const { data } = useGetSlackChannelsQuery({ appId: currentApp.id });
  const slackChannelIdNameMapping: { [key: string]: string } | undefined =
    data?.channels.reduce(
      (acc, channel) => ({ ...acc, [channel.id]: channel.name }),
      {},
    );
  const { eventAlerts, isLoading, createEventAlert, deleteEventAlert } =
    useEventAlerts({
      appId,
      eventId,
    });

  const addAlert = () => {
    createEventAlert({
      appId,
      eventId,
    }).then(() => setAlertIndex(eventAlerts?.length || 0));
  };

  const deleteAlert = (id: number) => {
    deleteEventAlert({
      appId,
      id,
    }).then(() => setAlertIndex(0));
  };

  if (isLoading || !eventAlerts) {
    return (
      <Flex justify="center" py={8}>
        <Spinner />
      </Flex>
    );
  }

  return (
    <Box data-testid="event-alerts-box">
      <Tabs colorScheme="purple" index={alertIndex} onChange={setAlertIndex}>
        <Flex align="center">
          <TabList>
            {eventAlerts.map((alert: IAlert, index: number) => {
              const slackChannelName =
                slackChannelIdNameMapping && alert.slackChannelId
                  ? slackChannelIdNameMapping[alert.slackChannelId]
                    ? `#${slackChannelIdNameMapping[alert.slackChannelId]}`
                    : "Channel not found"
                  : "New alert";

              return (
                <Flex
                  align="center"
                  onMouseEnter={() => setShowDeleteIcon(alert.id)}
                  onMouseLeave={() => setShowDeleteIcon(null)}
                >
                  <Tab minW={100} data-testid={`event-alert-tab-${index}`}>
                    <Text fontSize="sm">{slackChannelName}</Text>
                    {eventAlerts.length !== 1 &&
                      showDeleteIcon === alert.id && (
                        <DeleteIcon
                          ml={2}
                          cursor="pointer"
                          onClick={() => deleteAlert(alert.id)}
                          fontSize="xs"
                          color={"purple.500"}
                          data-testid={`event-alert-delete-${index}`}
                        />
                      )}
                  </Tab>
                </Flex>
              );
            })}
          </TabList>
          <Button
            size="sm"
            variant="ghost"
            onClick={addAlert}
            data-testid="event-alert-add"
          >
            <AddIcon fontSize="sm" color="purple.500" />
          </Button>
        </Flex>
        <TabPanels>
          {eventAlerts.map((alert: IAlert) => (
            <TabPanel>
              <EventAlertEditor
                key={`${alert.state}-${JSON.stringify(alert.setup)}`}
                eventId={eventId}
                alert={alert}
                onClose={onClose}
              />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default EventAlerts;
