import { IReport } from "core/types/Report";
import { IGroup } from "core/types/Group";
import { IContact } from "core/types/Contact";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useAppObjects } from "core/hooks/useAppObjects";
import { Combobox } from "@headlessui/react";
import { Spinner } from "@chakra-ui/react";
import { UserResultOptions } from "./UserResultOptions";
import { UserPreview } from "./UserPreview";
import { ReportResultOptions } from "./ReportResultOptions";
import { QuickNavigationOptions } from "./QuickNavigationOptions";
import { GroupResultOptions } from "./GroupResultOptions";
import { GroupPreview } from "./GroupPreview";
import { CompanyResultOptions } from "./CompanyResultOptions";
import { CompanyPreview } from "./CompanyPreview";
import { IQuickNavigationItem, IResult } from "./Body";

interface IResultOptions {
  activeOption: IResult | null;
  companies?: IGroup[];
  groups?: IGroup[];
  contacts?: IContact[];
  reports?: IReport[];
  query: string;
  isFetching: boolean;
  navigationItems: IQuickNavigationItem[];
}

export const ResultOptions: React.FC<IResultOptions> = ({
  activeOption,
  companies,
  groups,
  contacts,
  reports,
  query,
  isFetching,
  navigationItems,
}) => {
  const { id: appId } = useCurrentApp();

  const companiesLength = companies?.length;
  const groupsLength = groups?.length;
  const contactsLength = contacts?.length;
  const reportsLength = reports?.length;

  const { companyAppObject, groupAppObject } = useAppObjects();

  const filteredCompanies =
    companies?.map((company: IGroup) => ({
      ...company,
      type: "company",
      path: `/a/${appId}/company/${company.id}`,
    })) || [];
  const filteredGroups =
    groups?.map((group: IGroup) => ({
      ...group,
      type: "group",
      path: `/a/${appId}/group/${group.id}`,
    })) || [];
  const filteredUsers =
    contacts?.map((contact: IContact) => ({
      ...contact,
      type: "user",
      path: `/a/${appId}/contact/${contact.id}`,
    })) || [];
  const filteredReports =
    reports?.map((report: IReport) => ({
      ...report,
      type: "report",
      path: `/a/${appId}/report/${report.id}`,
    })) || [];

  if (
    !isFetching &&
    query !== "" &&
    filteredCompanies.length === 0 &&
    filteredGroups.length === 0 &&
    filteredUsers.length === 0 &&
    filteredReports.length === 0 &&
    navigationItems.length === 0
  ) {
    return <></>;
  }

  return (
    <>
      <Combobox.Options
        as="div"
        static
        hold
        className={`flex divide-x divide-gray-100`}
        data-testid="cmdk-result-options"
      >
        <div className="h-112 w-1/2 flex-auto scroll-py-4 overflow-y-auto px-6 py-4">
          <div className="-mx-2 text-sm text-gray-700">
            {navigationItems?.length !== 0 && (
              <>
                <h2 className="mb-4 mt-2 text-xs font-semibold capitalize text-gray-500">
                  Quick navigation
                </h2>
                <QuickNavigationOptions navigationItems={navigationItems} />
              </>
            )}
            {isFetching ? (
              <div className="flex h-112 w-full items-center justify-center px-6 py-4">
                <Spinner data-testid="cmdk-spinner" />
              </div>
            ) : (
              <>
                {companyAppObject && Number(companiesLength) > 0 && (
                  <>
                    <h2 className="mb-4 mt-2 text-xs font-semibold capitalize text-gray-500">
                      Companies
                    </h2>
                    <CompanyResultOptions searchResults={filteredCompanies} />
                  </>
                )}

                {groupAppObject && Number(groupsLength) > 0 && (
                  <>
                    <h2 className="mb-4 mt-2 text-xs font-semibold capitalize text-gray-500">
                      {groupAppObject.pluralName}
                    </h2>
                    <GroupResultOptions searchResults={filteredGroups} />
                  </>
                )}

                {Number(contactsLength) > 0 && (
                  <>
                    <h2 className="mb-4 mt-2 text-xs font-semibold capitalize text-gray-500">
                      Users
                    </h2>
                    <UserResultOptions searchResults={filteredUsers} />
                  </>
                )}

                {Number(reportsLength) > 0 && (
                  <>
                    <h2 className="mb-4 mt-2 text-xs font-semibold capitalize text-gray-500">
                      Reports
                    </h2>
                    <ReportResultOptions searchResults={filteredReports} />
                  </>
                )}
              </>
            )}
          </div>
        </div>

        {companyAppObject &&
          activeOption &&
          activeOption.type === "company" && (
            <CompanyPreview
              key={(activeOption as unknown as IGroup).id}
              group={activeOption as unknown as IGroup}
            />
          )}

        {activeOption && activeOption.type === "group" && (
          <GroupPreview
            key={(activeOption as unknown as IGroup).id}
            group={activeOption as unknown as IGroup}
          />
        )}

        {activeOption && activeOption.type === "user" && (
          <UserPreview
            key={(activeOption as unknown as IContact).id}
            user={activeOption as unknown as IContact}
          />
        )}
      </Combobox.Options>
    </>
  );
};
