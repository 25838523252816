import React from "react";
import { useNavigation } from "core/hooks/useNavigation";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { Bars3Icon } from "@heroicons/react/20/solid";

export const CollapsedHeader: React.FC = () => {
  const currentApp = useCurrentApp();
  const { toggleSidebar } = useNavigation();

  if (!currentApp) {
    return null;
  }

  return (
    <div className="mt-3 flex h-[32px] w-full">
      <div
        className="flex w-full cursor-pointer items-center justify-center"
        onClick={toggleSidebar}
      >
        <div className="px-3 py-1 text-gray-300">
          <Bars3Icon className="h-5 w-5 text-gray-600" />
        </div>
      </div>
    </div>
  );
};
