export interface IColorPalette {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
  scheme: string;
}

interface IColors {
  transparent: string;
  current: string;
  primary: string;
  secondary: string;
  darkBlue: string;
  black: string;
  white: string;
  turquoise: string;
  error: string;

  templateColors: any;
  orange: IColorPalette;
  templatePink: IColorPalette;
  templateDarkBlue: IColorPalette;
  purple: IColorPalette;
  templateGreen: IColorPalette;
  templateBlue: IColorPalette;
  templateRed: IColorPalette;
  whiteAlpha: IColorPalette;
  blackAlpha: IColorPalette;
  gray: IColorPalette;
  purpleGray: any;
  red: IColorPalette;
  yellow: IColorPalette;
  green: IColorPalette;
  teal: IColorPalette;
  blue: IColorPalette;
  cyan: IColorPalette;
  pink: IColorPalette;
  success: IColorPalette;
  warning: Pick<IColorPalette, 100 | 500 | 600>;

  templateDarkGreen: IColorPalette;
  templateLightGreen: IColorPalette;
  templateLightPurple: IColorPalette;
}

export const colors: IColors = {
  transparent: "transparent",
  current: "currentColor",
  primary: "#6868F7",
  secondary: "#639",
  darkBlue: "#302F73",
  black: "#2B2B2B",
  white: "#fff",
  turquoise: "#48A9A6",
  error: "#F33F27",

  templateColors: {
    orange: {
      primary: "#EEA14C",
      secondary: "#FDF3D9",
    },
    blue: {
      primary: "#6868F7",
      secondary: "#DEE0FF",
    },
    purple: {
      primary: "#6868F7",
      secondary: "#F8F3FE",
    },
    green: {
      primary: "#62CBA6",
      secondary: "#DDF6EF",
    },
    red: {
      primary: "#D63A35",
      secondary: "#F1EEE5",
    },
    pink: {
      primary: "#E25993",
      secondary: "#DEE1FC",
    },
    darkBlue: {
      primary: "#2C31F8",
      secondary: "#FDE2DE",
    },
  },

  // GENERATED COLORS: https://hihayk.github.io/scale/#0/9/50/84/-51/0/15/14/EEA14C/238/161/76/white
  orange: {
    50: "#FEF0E0",
    100: "#FDE7CF",
    200: "#FCDFBE",
    300: "#FBD6AD",
    400: "#FACD9C",
    500: "#F8C48B",
    600: "#F6BC7B",
    700: "#F3B36B",
    800: "#F1AA5C",
    900: "#EEA14C",
    scheme: "orange",
  },

  // GENERATED COLORS: https://hihayk.github.io/scale/#0/9/50/84/-51/0/15/14/EEA14C/238/161/76/white
  templatePink: {
    50: "#FCF8FF",
    100: "#FFEFFD",
    200: "#FEE6EF",
    300: "#FDE2DE",
    400: "#F1D0D4",
    500: "#E4C2CF",
    600: "#D6B4CC",
    700: "#C9A7C9",
    800: "#BB99BB",
    900: "#A88CAD",
    scheme: "templatePink",
  },

  purple: {
    50: "#F0F0FE",
    100: "#E1E1FD",
    200: "#C3C3FC",
    300: "#A4A4FA",
    400: "#8686F9",
    500: "#6868F7",
    600: "#5353C6",
    700: "#3E3E94",
    800: "#2A2A63",
    900: "#151531",
    scheme: "purple",
  },

  // GENERATED COLORS: https://hihayk.github.io/scale/#0/9/50/84/-51/0/15/14/6868F7/104/104/247/white using purple.500
  templateLightPurple: {
    50: "#E5E5FF",
    100: "#D6D6FF",
    200: "#C8C8FF",
    300: "#B9B9FF",
    400: "#ABABFE",
    500: "#9D9DFD",
    600: "#9090FC",
    700: "#8282FA",
    800: "#7575F9",
    900: "#6868F7",
    scheme: "templateLightPurple",
  },

  // GENERATED COLORS: https://hihayk.github.io/scale/#8/3/54/99/0/0/15/100/DEE0FF/222/224/255/white
  templateBlue: {
    50: "#E8EAFF",
    100: "#DEE0FF",
    200: "#CDCFEE",
    300: "#BCBEDD",
    400: "#ACAECC",
    500: "#9C9EBB",
    600: "#8C8EA9",
    700: "#7C7E98",
    800: "#6D6F87",
    900: "#5F6075",
    scheme: "templateBlue",
  },

  templateRed: {
    50: "#F1EEE5",
    100: "#F6C1BF",
    200: "#F1A5A3",
    300: "#EB8A86",
    400: "#E46E6B",
    500: "#DE5450",
    600: "#D63A35",
    700: "#B82C28",
    800: "#98201C",
    900: "#D63A35",
    scheme: "templateRed",
  },

  templateDarkGreen: {
    50: "#D6E8E5",
    100: "#B8D6D1",
    200: "#99C4BD",
    300: "#7BB2A9",
    400: "#5EA094",
    500: "#408D80",
    600: "#39887A",
    700: "#237A6B",
    800: "#066756",
    900: "#01604F",
    scheme: "templateDarkGreen",
  },

  templateGreen: {
    50: "rgba(0, 101, 64, 0.04)",
    100: "rgba(0, 101, 64, 0.06)",
    200: "rgba(0, 101, 64, 0.08)",
    300: "rgba(0, 101, 64, 0.92)",
    400: "rgba(0, 101, 64, 0.64)",
    500: "rgba(0, 101, 64, 0.80)",
    600: "rgba(0, 101, 64, 0.92)",
    700: "rgba(0, 101, 64, 0.64)",
    800: "rgba(0, 101, 64, 0.80)",
    900: "rgba(0, 101, 64, 0.92)",
    scheme: "templateGreen",
  },

  templateLightGreen: {
    50: "#DAF9F2",
    100: "#C4F6E9",
    200: "#AFF2E1",
    300: "#9AEED9",
    400: "#86E9D0",
    500: "#72E5C8",
    600: "#5EE0BF",
    700: "#4ADAB6",
    800: "#36D5AD",
    900: "#23CFA4",
    scheme: "templateLightGreen",
  },

  // https://hihayk.github.io/scale/#0/9/50/84/-51/0/15/14/2C31F8/44/49/248/white
  templateDarkBlue: {
    50: "#DBDCFF",
    100: "#C6C8FF",
    200: "#B2B4FF",
    300: "#9EA0FF",
    400: "#8A8DFF",
    500: "#777AFF",
    600: "#6367FE",
    700: "#5155FC",
    800: "#3E43FA",
    900: "#2C31F8",
    scheme: "templateDarkBlue",
  },

  whiteAlpha: {
    50: "rgba(255, 255, 255, 0.04)",
    100: "rgba(255, 255, 255, 0.06)",
    200: "rgba(255, 255, 255, 0.08)",
    300: "rgba(255, 255, 255, 0.16)",
    400: "rgba(255, 255, 255, 0.24)",
    500: "rgba(255, 255, 255, 0.36)",
    600: "rgba(255, 255, 255, 0.48)",
    700: "rgba(255, 255, 255, 0.64)",
    800: "rgba(255, 255, 255, 0.80)",
    900: "rgba(255, 255, 255, 0.92)",
    scheme: "whiteAlpha",
  },

  blackAlpha: {
    50: "rgba(0, 0, 0, 0.04)",
    100: "rgba(0, 0, 0, 0.06)",
    200: "rgba(0, 0, 0, 0.08)",
    300: "rgba(0, 0, 0, 0.16)",
    400: "rgba(0, 0, 0, 0.24)",
    500: "rgba(0, 0, 0, 0.36)",
    600: "rgba(0, 0, 0, 0.48)",
    700: "rgba(0, 0, 0, 0.64)",
    800: "rgba(0, 0, 0, 0.80)",
    900: "rgba(0, 0, 0, 0.92)",
    scheme: "blackAlpha",
  },

  gray: {
    50: "#F8F9FA",
    100: "#F1F3F5",
    200: "#E9ECEF",
    300: "#DEE2E6",
    400: "#CED4DA",
    500: "#ADB5BD",
    600: "#868E96",
    700: "#495057",
    800: "#343A40",
    900: "#0D131B",
    scheme: "gray",
  },

  purpleGray: {
    100: "#F7F7FD",
    scheme: "purpleGray",
  },

  red: {
    50: "#fff5f5",
    100: "#fed7d7",
    200: "#feb2b2",
    300: "#fc8181",
    400: "#f56565",
    500: "#e53e3e",
    600: "#c53030",
    700: "#9b2c2c",
    800: "#822727",
    900: "#63171b",
    scheme: "red",
  },

  yellow: {
    50: "#fffff0",
    100: "#fefcbf",
    200: "#faf089",
    300: "#f6e05e",
    400: "#ecc94b",
    500: "#d69e2e",
    600: "#b7791f",
    700: "#975a16",
    800: "#744210",
    900: "#5F370E",
    scheme: "yellow",
  },

  green: {
    50: "#f0fff4",
    100: "#c6f6d5",
    200: "#9ae6b4",
    300: "#68d391",
    400: "#48bb78",
    500: "#38a169",
    600: "#26A559",
    700: "#276749",
    800: "#22543d",
    900: "#1C4532",
    scheme: "green",
  },

  teal: {
    50: "#E6FFFA",
    100: "#B2F5EA",
    200: "#81E6D9",
    300: "#4FD1C5",
    400: "#38B2AC",
    500: "#319795",
    600: "#2C7A7B",
    700: "#285E61",
    800: "#234E52",
    900: "#1D4044",
    scheme: "teal",
  },

  blue: {
    50: "#ebf8ff",
    100: "#ceedff",
    200: "#90cdf4",
    300: "#63b3ed",
    400: "#4299e1",
    500: "#3182ce",
    600: "#2a69ac",
    700: "#1e4e8c",
    800: "#153e75",
    900: "#1a365d",
    scheme: "blue",
  },

  cyan: {
    50: "#EDFDFD",
    100: "#C4F1F9",
    200: "#9DECF9",
    300: "#76E4F7",
    400: "#0BC5EA",
    500: "#00B5D8",
    600: "#00A3C4",
    700: "#0987A0",
    800: "#086F83",
    900: "#065666",
    scheme: "cyan",
  },

  pink: {
    50: "#fff5f7",
    100: "#fed7e2",
    200: "#fbb6ce",
    300: "#f687b3",
    400: "#ed64a6",
    500: "#d53f8c",
    600: "#b83280",
    700: "#97266d",
    800: "#702459",
    900: "#521B41",
    scheme: "pink",
  },

  success: {
    50: "#E9F6EE",
    100: "",
    200: "",
    300: "",
    400: "#5BB381",
    500: "#26A559",
    600: "",
    700: "",
    800: "",
    900: "",
    scheme: "success",
  },
  warning: {
    100: "#FFE9C8",
    500: "#FFA340",
    600: "#DF8F23",
  },
};

export default colors;
