import { Route, Routes } from "react-router-dom";
import routes from "./routes";
import Toasts from "./Components/Toasts";
import Error from "./Components/Error";

function UnauthenticatedApp() {
  return (
    <>
      <Toasts />
      <Error />
      <Routes>
        {[...routes.indifferentRoutes, ...routes.unauthenticatedRoutes].map(
          ({ path, component: Component }, index) => {
            return <Route path={path} element={<Component />} key={index} />;
          },
        )}
      </Routes>
    </>
  );
}

export default UnauthenticatedApp;
