import { Link, useParams } from "react-router-dom";
import { Relationships } from "modules/Settings/Objects/Object/Relationships";
import { GroupContextSetting } from "modules/Settings/GroupContextSetting";
import DefaultHeader from "modules/Pages/DefaultHeader";
import { cx } from "helpers/cx";
import {
  AppObjectType,
  useGetAppObjectQuery,
  useUpdateAppObjectMutation,
} from "core/models/appObjects";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { Breadcrumbs } from "core/design-system/components/Breadcrumbs";
import AppObjectTraits from "modules/Settings/Objects/Object/AppObjectTraits";
import { Button } from "@chakra-ui/react";

export const ViewObject: React.FC = () => {
  const currentApp = useCurrentApp();
  const { objectId } = useParams();
  const { data: appObject, isLoading } = useGetAppObjectQuery({
    appId: currentApp.id,
    id: Number(objectId),
  });
  const [updateAppObject] = useUpdateAppObjectMutation();

  const section = {
    name: appObject?.pluralName,
    title: appObject?.pluralName,
    description: `Manage object attributes and other relevant settings.`,
  };

  const pages = [
    { name: "Objects", path: "../objects", current: false },
    { name: appObject?.pluralName ?? "Object", path: "#", current: true },
  ];

  const handleSingularNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    updateAppObject({
      appId: currentApp.id,
      id: Number(objectId),
      singularName: event.target.value,
    });
  };

  const handlePluralNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    updateAppObject({
      appId: currentApp.id,
      id: Number(objectId),
      pluralName: event.target.value,
    });
  };

  const canEditName =
    appObject &&
    ![AppObjectType.User, AppObjectType.Company].includes(
      appObject?.objectType,
    );

  const showGroupContextSetting = appObject?.objectType === AppObjectType.Group;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!appObject) {
    return <div>Object not found</div>;
  }

  return (
    <div className="flex w-[985px] flex-col gap-4 pb-16">
      <Breadcrumbs pages={pages} />
      <DefaultHeader w="inherit" section={section} />
      <div className="flex flex-col gap-4">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            General
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Set words to describe a single instance and multiple objects of this
            type, e.g Workspace, Deal, User
          </p>
        </div>
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div>
            <label
              htmlFor="singular-noun"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Singular noun{" "}
            </label>
            <div className="mt-2">
              <input
                className={cx(
                  "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                  !canEditName &&
                    "disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200",
                )}
                type="text"
                defaultValue={appObject.singularName}
                disabled={!canEditName}
                name="singular-noun"
                id="singular-noun"
                placeholder="Workspace"
                onChange={handleSingularNameChange}
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="plural-noun"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Plural noun{" "}
            </label>
            <div className="mt-2">
              <input
                className={cx(
                  "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                  !canEditName &&
                    "disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200",
                )}
                type="text"
                disabled={!canEditName}
                defaultValue={appObject.pluralName}
                name="plural-noun"
                id="plural-noun"
                placeholder="Workspaces"
                onChange={handlePluralNameChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 flex flex-col gap-4">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Relationships
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            How does this object relate to other objects in your workspace
          </p>
        </div>
        <Relationships appObject={appObject} />
      </div>
      {showGroupContextSetting && (
        <div className="mt-4 flex flex-col gap-4">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Settings
            </h2>
          </div>
          <div>
            <GroupContextSetting />
          </div>
        </div>
      )}
      <div className="mt-6 flex flex-col gap-4">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Traits
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Manage your computed traits and non-computed traits
          </p>
        </div>
        <AppObjectTraits appObject={appObject} limit={5} />
      </div>
      <div className="text-center">
        <Link to={`./traits`} className="text-sm text-gray-600 underline">
          <Button>View all traits</Button>
        </Link>
      </div>
    </div>
  );
};
