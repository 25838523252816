import { AppObjectType } from "core/models/appObjects";
import { CrmEntityState, ICrmEntity } from "core/models/crmEntities";
import { useGetCrmEntitiesQuery } from "core/models/crmEntities";
import { ICrmSync } from "core/models/crmSyncs";
import { Integration } from "core/models/dataMappings";
import { IPagy } from "core/types/Pagy";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

interface IUseCrmEntities {
  integration: Integration;
  objectType: AppObjectType;
  state: CrmEntityState;
  page: number;
  crmSync?: ICrmSync;
}

export function useCrmEntities({
  integration,
  objectType,
  state,
  page,
  crmSync,
}: IUseCrmEntities): {
  pagy: IPagy | undefined;
  crmEntities: ICrmEntity[];
  isLoadingCrmEntities: boolean;
} {
  const { appId } = useParams();
  const [crmEntities, setCrmEntites] = useState<ICrmEntity[]>([]);
  const { data, isLoading: isLoadingCrmEntities } = useGetCrmEntitiesQuery({
    appId: Number(appId),
    integration,
    objectType,
    state,
    page,
    crmSyncId: crmSync?.id,
  });

  const prevState = useRef(state);

  useEffect(() => {
    const stateChanged = state !== prevState.current;
    if (stateChanged) setCrmEntites([]);
    prevState.current = state;
  }, [state]);

  useEffect(() => {
    setCrmEntites((prevEntities) => {
      return [...prevEntities, ...(data?.crmEntities || [])];
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, page]);

  return {
    pagy: data?.pagy,
    crmEntities,
    isLoadingCrmEntities,
  };
}
