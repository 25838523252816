import { Button, ButtonProps, Spinner } from "@chakra-ui/react";

interface ILoadMoreButtonProps extends ButtonProps {
  isLoading: boolean;
  onClick: () => void;
}

export const LoadMoreButton: React.FC<ILoadMoreButtonProps> = ({
  isLoading,
  onClick,
  ...buttonProps
}) => {
  return (
    <Button isDisabled={isLoading} onClick={onClick} {...buttonProps}>
      {isLoading ? <Spinner /> : "Load more"}
    </Button>
  );
};
