import { CSVLink } from "react-csv";

import { Button } from "@chakra-ui/react";

interface ICSVExport {
  data: any[];
  headers: string[];
  filename: string;
  disabled: boolean;
}

export const CSVExport = ({
  data,
  headers,
  filename,
  disabled,
  ...props
}: ICSVExport) => {
  return (
    <CSVLink
      filename={filename}
      data={data}
      headers={headers}
      target="_blank"
      onClick={() => {
        if (disabled) {
          return false;
        }
      }}
    >
      <Button size="sm" colorScheme="gray" disabled={disabled} {...props}>
        Export as CSV
      </Button>
    </CSVLink>
  );
};
