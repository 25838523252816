import {
  Editable as ChakraEditable,
  EditableInput,
  EditablePreview,
  EditableProps as ChakraEditableProps,
  Input,
  InputProps,
} from "@chakra-ui/react";

interface IEditableProps extends ChakraEditableProps {
  inputProps?: InputProps;
}

export const Editable: React.FC<IEditableProps> = ({
  inputProps,
  ...props
}) => {
  return (
    <ChakraEditable
      overflow="visible"
      border="1px"
      borderLeftColor="white"
      borderRightColor="white"
      borderTopColor="white"
      borderBottomColor="gray.500"
      borderBottomWidth="1px"
      borderBottomStyle="dashed"
      _hover={{
        borderBottomColor: "purple.500",
        transition: "border-bottom-color 0.2s linear",
        cursor: "pointer",
      }}
      {...props}
    >
      <EditablePreview
        noOfLines={1}
        px={1}
        _hover={{ backgroundColor: "purple.50", borderRadius: "md" }}
      />
      {/*@ts-ignore*/}
      <Input
        p={1}
        h={"full"}
        as={EditableInput}
        maxW="50%"
        overflow="visible"
        _focus={{
          border: "none",
          boxShadow: "none",
        }}
        {...props}
        {...inputProps}
      />
    </ChakraEditable>
  );
};
