import { MenuItem } from "@chakra-ui/react";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { renderToast } from "core/helpers/toast";
import { useDuplicateReportMutation } from "core/models/reports";
import { IReport } from "core/types/Report";
import { useNavigate } from "react-router-dom";

export const DuplicateItem: React.FC<{ report: IReport }> = ({ report }) => {
  const navigate = useNavigate();
  const [duplicateReport] = useDuplicateReportMutation();
  const onAfterDuplicate = (duplicatedReportId: number) => {
    navigate(`/a/${report.appId}/report/${duplicatedReportId}`);
  };
  const onDuplicateReport = async () => {
    if (report.appId && report.id) {
      duplicateReport({
        appId: report.appId,
        reportId: report.id,
      })
        .unwrap()
        .then((data) => {
          renderToast({ action: "duplicate", status: "success" });
          if (onAfterDuplicate && data) {
            onAfterDuplicate(data.id);
          }
        })
        .catch(() => renderToast({ action: "duplicate", status: "error" }));
    }
  };

  return (
    <MenuItem onClick={onDuplicateReport}>
      <div className="flex items-center gap-2">
        <DocumentDuplicateIcon className="h-4" />
        <p>Duplicate</p>
      </div>
    </MenuItem>
  );
};
