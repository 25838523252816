import React, { useState } from "react";
import { pluralize } from "helpers/pluralize";
import { ITimerangeOption } from "core/types/TimerangeOption";
import { IInsightSection } from "core/types/TemplateConfig";
import { IReportInsight } from "core/types/Report";
import { useQueryParam } from "core/hooks/useQueryParam";
import useInsightDataFetching from "core/hooks/useInsightDataFetching";
import { useInsightCsvExport } from "core/hooks/useInsightCsvExport";
import {
  DATE_RANGE_OPTIONS_FROM_VALUE,
  MOST_ACTIVE_USERS_OPTIONS,
  NINETY_DAYS,
  RELATIVE_TIMERANGE_VALUE_FROM_KEYS,
} from "core/constants/timerange";
import TimeRangePicker from "core/components/TimeRangePicker";
import { InsightReactions } from "core/components/InsightReactions";
import InsightCard from "core/components/InsightCard/Index";
import { UserListSingleContact } from "core/components/Contact/UserListSingleContact";
import { Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { MostActiveUsersList } from "./MostActiveUsersList";

export interface IMostActiveUser {
  userId: string;
  eventCount: number;
  numberOfActiveDays: number;
  traits: { [key: string]: string };
}

export interface IMostActiveUsersData {
  contacts?: IMostActiveUser[];
}

const mockData = {
  contacts: [
    {
      userId: "Josephine Edamon",
      eventCount: 5,
      numberOfActiveDays: 5,
    },
    {
      userId: "Roy Carpent",
      eventCount: 4,
      numberOfActiveDays: 4,
    },
    {
      userId: "Anita James",
      eventCount: 3,
      numberOfActiveDays: 3,
    },
    {
      userId: "Alice Terpantine",
      eventCount: 2,
      numberOfActiveDays: 2,
    },
    {
      userId: "Gus Edams",
      eventCount: 1,
      numberOfActiveDays: 1,
    },
  ],
};

export const MostActiveUsersContainer: React.FC<IReportInsight> = ({
  previewMode,
  sharingMode,
  screenshotMode,
  sharingSecretToken,
  report,
  config,
}) => {
  const insight = config.insights.find(
    ({ slug }) => slug === "most-active-users",
  ) as IInsightSection;
  const isSetupValid = config.validateSetup(report.config);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const timerangeParam = useQueryParam("insight_timerange");
  const initialTimeRange = timerangeParam
    ? DATE_RANGE_OPTIONS_FROM_VALUE[
        parseInt(
          timerangeParam,
          10,
        ) as keyof typeof DATE_RANGE_OPTIONS_FROM_VALUE
      ]
    : DATE_RANGE_OPTIONS_FROM_VALUE[
        RELATIVE_TIMERANGE_VALUE_FROM_KEYS[
          NINETY_DAYS.value as keyof typeof RELATIVE_TIMERANGE_VALUE_FROM_KEYS
        ] as keyof typeof DATE_RANGE_OPTIONS_FROM_VALUE
      ];
  const [timerange, setTimerange] =
    useState<ITimerangeOption>(initialTimeRange);
  const intervalDays = timerange.count;

  const { response, isLoading, isFetching, error, onRefreshInsight } =
    useInsightDataFetching({
      report,
      insight,
      insightParams: {
        intervalDays,
        truncate: true,
      },
      sharingSecretToken,
      previewMode,
      skip: !isSetupValid,
    });

  const { requestCsvExport, csvExportLoading } = useInsightCsvExport({
    report,
    insightId: 8,
    count: 1, // hard coded to 1 because we don't know count beforehand
    insightParams: {
      intervalDays,
      truncate: true,
    },
  });

  const data = previewMode ? mockData : response?.data;
  const noData = data?.contacts?.length === 0;

  const onViewButtonClick = () => {
    if (!sharingMode && !previewMode) {
      onOpen();
    }
  };

  if (!insight) return null;

  return (
    <>
      <InsightCard>
        {(Card) => (
          <Card.Container insight={insight} id={insight.slug}>
            <Card.Header
              hasCaching={response?.hasCaching}
              screenshotMode={screenshotMode}
              showReportTitle={sharingMode}
              insight={insight}
              showActions={!sharingMode && !previewMode}
              sharingMode={sharingMode}
              config={config}
              report={report}
              refresh={onRefreshInsight}
              cachedAt={response?.cachedAt}
            >
              {timerange && (
                <TimeRangePicker
                  previewMode={previewMode}
                  timerange={timerange}
                  setTimeRange={setTimerange}
                  options={MOST_ACTIVE_USERS_OPTIONS}
                />
              )}
            </Card.Header>
            <Card.Body
              isLoading={!data || isLoading || isFetching}
              isPreviewMode={previewMode}
              isSetupValid={isSetupValid}
              error={error}
            >
              {noData && !isLoading ? (
                <Card.EmptyState />
              ) : (
                <Flex
                  p={5}
                  h={320}
                  id="loaded"
                  data-testid="loaded"
                  direction="column"
                >
                  {data?.contacts?.map((contact: IMostActiveUser) => (
                    <UserListSingleContact
                      key={contact.userId}
                      id={contact.userId}
                      traits={contact.traits}
                      description={`Used ${contact.eventCount} ${pluralize(contact.eventCount, "time", "times")} ${
                        contact?.numberOfActiveDays === 1
                          ? `for ${contact?.numberOfActiveDays} day`
                          : `over ${contact?.numberOfActiveDays} days`
                      }`}
                    />
                  ))}
                </Flex>
              )}
            </Card.Body>
            {!sharingMode && !previewMode && !noData && !isLoading && (
              <Card.Footer topBorder={false}>
                <div className="flex w-full flex-col">
                  <div className="w-full px-4 py-0">
                    <div className="flex w-full items-center justify-between gap-2 rounded-lg bg-gray-50 p-4">
                      <div className="w-7/10">
                        <Text fontSize="sm">
                          These users have been the most active in the{" "}
                          <Text as="span" className="lowercase">
                            {timerange.label}
                          </Text>
                          . Look at their user profiles to find common patterns
                          amongst them.
                        </Text>
                      </div>
                      <Button
                        className="w-3/20 rounded-lg"
                        onClick={onViewButtonClick}
                      >
                        <div className="p-4 text-sm font-semibold">
                          View Users
                        </div>
                      </Button>
                    </div>
                  </div>
                  <InsightReactions
                    reactions={response?.reactions}
                    insightId={insight.typeId}
                    reportId={Number(report?.id)}
                  />
                </div>
              </Card.Footer>
            )}
          </Card.Container>
        )}
      </InsightCard>
      {!sharingMode && !previewMode && (
        <MostActiveUsersList
          report={report}
          key={intervalDays}
          isOpen={isOpen}
          onClose={onClose}
          csvExportLoading={csvExportLoading}
          requestCsvExport={requestCsvExport}
          insight={insight}
          intervalDays={intervalDays}
        />
      )}
    </>
  );
};
