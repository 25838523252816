import { Issue } from "modules/Settings/Crm/SyncSettings/Issues/Issue";
import { ICrmError } from "core/models/crmErrors";
import { MoonIcon } from "@chakra-ui/icons";

interface IIssuesListProps {
  issues: ICrmError[];
}

export const IssuesList: React.FC<IIssuesListProps> = ({ issues }) => {
  const isEmpty = !issues || issues.length === 0;

  if (isEmpty) {
    return (
      <div className="my-8 flex w-full flex-col items-center justify-center gap-4">
        <MoonIcon color="purple.300" h={6} w={6} />
        <div className="flex items-center justify-center">
          <p className="text-sm text-gray-700">
            There are no issues with your sync settings
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col gap-1">
      {issues.map((issue) => (
        <Issue key={issue.id} issue={issue} />
      ))}
    </div>
  );
};
