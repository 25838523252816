import { useParams } from "react-router-dom";
import { IGetCountArgs, useGetCountQuery } from "core/models/people";

export function usePeopleCount({
  audienceFilters,
  searchQuery,
  groupId,
  level,
  groupType,
}: Pick<
  IGetCountArgs,
  "audienceFilters" | "searchQuery" | "groupId" | "level" | "groupType"
>) {
  const { appId } = useParams();
  const {
    data: response,
    isLoading,
    isFetching,
  } = useGetCountQuery({
    appId: Number(appId),
    audienceFilters,
    searchQuery,
    groupId,
    level,
    groupType,
  });

  return {
    totalCount: response?.totalCount,
    matchedCount: response?.matchedCount,
    isLoadingCount: isLoading || isFetching,
  };
}
