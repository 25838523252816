import React from "react";
import { Flex, FlexProps } from "@chakra-ui/react";

interface IFooterProps extends FlexProps {
  topBorder?: boolean;
  children: React.ReactNode;
  isLoading?: boolean;
  isPreviewMode?: boolean;
  error?: any;
}

function Footer({
  isLoading,
  isPreviewMode,
  error,
  children,
  topBorder = true,
  ...props
}: IFooterProps) {
  if (isLoading || error) {
    return <></>;
  }

  return (
    <Flex
      bg="white"
      align="center"
      justify="flex-start"
      borderTop={topBorder ? "1px solid" : ""}
      borderColor={topBorder ? "gray.100" : ""}
      borderBottomLeftRadius="lg"
      borderBottomRightRadius="lg"
      borderBottomRadius="lg"
      shadow="none"
      {...props}
    >
      {children}
    </Flex>
  );
}

export default Footer;
