import humps from "humps";
import { IPagy } from "core/types/Pagy";
import { baseQuery } from "core/initializers/rtk";
import { FilterType } from "core/constants/report-setup";
import { createApi } from "@reduxjs/toolkit/query/react";

export interface ITraitKeyResponse {
  trait: string;
  valueCount: number;
  audienceType?: string;
  isComputed?: boolean;
  isPinned?: boolean;
  filterType?: FilterType;
  partOfAudience?: boolean;
}

export interface IUpdateTraitArgs {
  appId: number;
  level: number;
  trait: string;
}

interface IGetTraitsArgs {
  appId: number;
  filterType: number;
  includeValues?: boolean;
  limit?: number;
  isActive?: boolean;
}

export interface ITraitValue {
  value: string;
  count: number;
}

export interface ITraitsValueResponse {
  values: ITraitValue[];
  pagy: IPagy;
}

interface IGetTraitsValuesArgs {
  appId: number;
  filterType: number;
  page: number;
  query: string;
  trait?: string;
}

export const traitsApi = createApi({
  baseQuery,
  reducerPath: "traitsApi",
  tagTypes: ["Trait", "ComputedTrait"],
  endpoints: (builder) => ({
    getTraits: builder.query<ITraitKeyResponse[], IGetTraitsArgs>({
      query: ({ appId, filterType, limit, isActive }) => ({
        method: "GET",
        url: `traits`,
        params: {
          app_id: appId,
          filter_type: filterType,
          limit,
          is_active: isActive,
        },
      }),
      providesTags: ["Trait"],
      transformResponse: (response: ITraitKeyResponse[]) =>
        humps.camelizeKeys(response) as ITraitKeyResponse[],
    }),
    getTraitValues: builder.query<ITraitsValueResponse, IGetTraitsValuesArgs>({
      query: ({ appId, trait, filterType, page, query }) => ({
        method: "GET",
        url: `traits/values`,
        params: humps.decamelizeKeys({
          trait,
          appId,
          filterType,
          page,
          query,
        }),
      }),
      transformResponse: (response: ITraitsValueResponse) =>
        humps.camelizeKeys(response) as ITraitsValueResponse,
    }),
    archiveTrait: builder.mutation<void, IUpdateTraitArgs>({
      query: ({ appId, level, trait }) => ({
        method: "PUT",
        url: `traits/archive`,
        params: {
          app_id: appId,
          level,
          trait,
        },
      }),
      invalidatesTags: ["Trait"],
    }),
  }),
});

export const {
  useGetTraitsQuery,
  useLazyGetTraitsQuery,
  useGetTraitValuesQuery,
  useLazyGetTraitValuesQuery,
  useArchiveTraitMutation,
} = traitsApi;
