import React, { useEffect, useState } from "react";
import moment from "moment";
import { IInsightSection, ITemplateConfig } from "core/types/TemplateConfig";
import { IReport } from "core/types/Report";
import colors from "core/design-system/constants/theme/colors";
import CompanyJuneLogoIcon from "core/design-system/components/Icon/Logos/CompanyJuneLogoIcon";
import Icon from "core/design-system/components/Icon";
import { CACHEABLE_REPORT_TYPES } from "core/constants/report-configurations";
import { ArrowPathIcon } from "@heroicons/react/20/solid";
import {
  Box,
  Flex,
  FlexProps,
  Skeleton,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { Title } from "./Title";
import Actions from "./Actions";

interface IHeaderProps extends FlexProps {
  showActions: boolean;
  insight: IInsightSection;
  sharingMode: boolean;
  tooltipText?: string;
  config?: ITemplateConfig;
  report?: IReport;
  additionalConfigProps?: any;
  screenshotMode: boolean;
  showReportTitle: boolean;
  refresh?: () => void;
  cachedAt?: string;
  hasCaching: boolean;
}

function shouldDisableRefresh(cachedAt?: string) {
  const now = moment();
  return Boolean(cachedAt && now.diff(moment(cachedAt), "seconds") <= 300);
}

const Header: React.FC<IHeaderProps> = ({
  children,
  tooltipText,
  showActions = false,
  insight,
  sharingMode,
  config,
  report,
  screenshotMode,
  additionalConfigProps,
  showReportTitle,
  refresh,
  cachedAt,
  hasCaching,
  ...styleProps
}) => {
  const [refreshDisabled, setRefreshDisabled] = useState<boolean>(
    shouldDisableRefresh(cachedAt),
  );
  const [cachedAtHumanized, setCachedAtHumanized] = useState<string>(
    moment.utc(cachedAt).fromNow(),
  );
  const isCacheable =
    report && CACHEABLE_REPORT_TYPES.includes(report.reportType);

  useEffect(() => {
    if (cachedAt) {
      setRefreshDisabled(shouldDisableRefresh(cachedAt));
      setCachedAtHumanized(moment.utc(cachedAt).fromNow());
    }
  }, [cachedAt]);

  useEffect(() => {
    const checkInterval = setInterval(() => {
      if (!cachedAt) return;

      setRefreshDisabled(shouldDisableRefresh(cachedAt));
      setCachedAtHumanized(moment.utc(cachedAt).fromNow());
    }, 5000);

    return () => {
      clearInterval(checkInterval);
    };
  }, [cachedAt]);

  const onRefresh = refreshDisabled ? () => {} : refresh;

  if (screenshotMode) return null;
  return (
    <Flex
      alignItems="center"
      justify="space-between"
      borderBottom="1px solid"
      borderBottomColor="gray.100"
      p={5}
      px={7}
      {...styleProps}
    >
      <Flex justify="space-between" w="100%">
        <Flex align="center">
          {sharingMode && insight && (
            <a target="_blank" rel="noreferrer" href="https://june.so/">
              <CompanyJuneLogoIcon mr={4} fontSize={36} />
            </a>
          )}
          <Flex direction="row">
            <Flex direction="column">
              <Flex>
                <Text
                  noOfLines={1}
                  fontSize="md"
                  fontWeight="medium"
                  color="gray.900"
                >
                  <Title
                    showReportTitle={showReportTitle}
                    report={report}
                    insight={insight}
                  />
                </Text>
              </Flex>

              {hasCaching && isCacheable && !showReportTitle && (
                <Flex align="center" gridGap={1}>
                  {cachedAt ? (
                    <>
                      <Text fontSize="xs" color="gray.600">
                        Last refreshed {cachedAtHumanized}
                      </Text>
                      <Tooltip
                        label={
                          refreshDisabled
                            ? `Data can be refreshed after 5 minutes`
                            : "Refresh data"
                        }
                        hasArrow
                      >
                        <Box
                          cursor={refreshDisabled ? "not-allowed" : "pointer"}
                          onClick={onRefresh}
                        >
                          <ArrowPathIcon
                            style={{
                              height: "15px",
                              color: refreshDisabled
                                ? colors.gray[400]
                                : colors.gray[600],
                            }}
                          />
                        </Box>
                      </Tooltip>
                    </>
                  ) : (
                    <Skeleton borderRadius="md" mt={1} h="15px" w="200px" />
                  )}
                </Flex>
              )}
            </Flex>

            {tooltipText && (
              <Tooltip label={tooltipText} placement="right">
                <Flex pos="relative" top="5px" ml={2}>
                  <Icon iconType="icon" name="question-purple" />
                </Flex>
              </Tooltip>
            )}
          </Flex>
        </Flex>
        <Flex alignItems="center">
          {children}
          {showActions && report && (
            <Actions
              key={insight.typeId}
              report={report}
              config={config}
              title={
                <Title
                  showReportTitle={showReportTitle}
                  report={report}
                  insight={insight}
                />
              }
              insight={insight}
              additionalConfigProps={additionalConfigProps}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Header;
