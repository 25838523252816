import { IReport } from "core/types/Report";
import { IPinnedReport } from "core/types/PinnedReport";
import { ComponentDefaultProps, Flex } from "@chakra-ui/react";
import { Loader } from "./Loader";

export interface IBodyProps extends ComponentDefaultProps {
  isLoading?: boolean;
  pinnedReport?: IPinnedReport;
  report?: IReport;
}

export const Body: React.FC<IBodyProps> = ({
  report,
  pinnedReport,
  children,
  isLoading,
  ...props
}) => {
  if (isLoading) return <Loader />;
  return (
    <Flex
      data-testid="highlight-card-body"
      justify="center"
      align={"center"}
      py={8}
      {...props}
    >
      {children}
    </Flex>
  );
};
