import {
  useDeleteEventMutation,
  useLazyGetEventQuery,
  useLazyGetMostRecentEventsQuery,
} from "core/models/events";
import { useToast } from "core/hooks/useToast";
import { useCurrentApp } from "core/hooks/useCurrentApp";

export const useEvent = () => {
  const toast = useToast();
  const currentApp = useCurrentApp();
  const [getEvent, { data: event, isLoading: isLoadingEvent }] =
    useLazyGetEventQuery();
  const [deleteEvent] = useDeleteEventMutation();
  const [getMostRecentEvents, { data, isLoading: isLoadingMostRecentEvents }] =
    useLazyGetMostRecentEventsQuery();

  const mostRecentEvents = data?.events || [];

  function onGetEvent({ eventId }: { eventId: string | number }) {
    getEvent({ eventId, appId: currentApp.id });
  }

  const onDeleteEvent = (eventId: number) => {
    deleteEvent({ eventId, appId: currentApp.id })
      .unwrap()
      .then(() => {
        toast({
          status: "success",
          title: "Event deletion request created",
          description: "The event deletion request has been created.",
        });
      })
      .catch((e) => {
        toast({
          status: "error",
          title: "Event deletion request failed",
          description: e.data.error,
        });
      });
  };

  function onGetMostRecentEvents() {
    getMostRecentEvents({ appId: currentApp.id });
  }

  return {
    event,
    isLoadingEvent,
    onGetEvent,
    onDeleteEvent,
    mostRecentEvents,
    isLoadingMostRecentEvents,
    onGetMostRecentEvents,
  };
};
