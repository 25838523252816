import { uuid } from "core/helpers/uuid";
import {
  ACTIVE_USERS,
  ENGAGEMENT,
  FEATURE_RELEASE,
  NEW_USERS,
  POWER_USERS,
  RETENTION,
  SLIPPING_AWAY,
} from "core/constants/report-configurations";
import { Message } from "core/components/AI/Message";
import { PromptSuggestion } from "core/components/AI/Actions/PromptSuggestion";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ComponentDefaultProps } from "@chakra-ui/react";

export interface IBlockComponent extends ComponentDefaultProps {
  delay: number;
  isReverse: boolean;
}

export enum From {
  June = "june",
  User = "user",
}

export interface IConversationBlock {
  id: string;
  from: From;
  blocks: IBlock[];
  hasError?: boolean;
}

export interface IBlock {
  Component: React.FC<IBlockComponent>;
  content?: string;
  props?: any;
}

const FEATURE_REPORT_PROMPTS = [
  "Who used this feature the most this week?",
  "Who used this feature less this month compared to last month?",
];

const NEW_USERS_PROMPTS = [
  "Who are the users that signed up this week?",
  "Where did most of my signups come from this week?",
];

const ACTIVE_USERS_PROMPTS = [
  "What users are most active today?",
  "What other events are my most active users triggering the most this week?",
];

const RETENTION_PROMPTS = [
  "What event are my most retained users triggering the most?",
  "What’s the persona breakdown of my most retained users?",
];

const ENGAGEMENT_PROMPTS = [
  "What event are my most engaged users triggering this week?",
  "What company has the most engaged users?",
];

const POWER_USERS_PROMPTS = [
  "Who is the power user of my most engaged companies?",
  "What’s the persona breakdown of my power users?",
];

const CHURN_PROMPTS = [
  "Who are the most churn risk users this week?",
  "Show me a graph of my churn risk users over time?",
];

const GENERIC_PROMPTS = [
  "Which users signed up this week?",
  "Who are my most engaged users in the past week?",
  "What’s the company with the most users?",
];

function prompts(reportType: number) {
  switch (reportType) {
    case ACTIVE_USERS:
      return ACTIVE_USERS_PROMPTS;
    case FEATURE_RELEASE:
      return FEATURE_REPORT_PROMPTS;
    case NEW_USERS:
      return NEW_USERS_PROMPTS;
    case RETENTION:
      return RETENTION_PROMPTS;
    case ENGAGEMENT:
      return ENGAGEMENT_PROMPTS;
    case POWER_USERS:
      return POWER_USERS_PROMPTS;
    case SLIPPING_AWAY:
      return CHURN_PROMPTS;
    default:
      return GENERIC_PROMPTS;
  }
}

function message(reportType: number) {
  switch (reportType) {
    case ACTIVE_USERS:
      return "active users";
    case FEATURE_RELEASE:
      return "feature";
    case NEW_USERS:
      return "new users";
    case RETENTION:
      return "retention";
    case ENGAGEMENT:
      return "most engaged users";
    case POWER_USERS:
      return "power users";
    case SLIPPING_AWAY:
      return "churning users";
    default:
      return "product";
  }
}

export function initialConversationsBlock(reportType: number) {
  return {
    id: uuid(),
    from: From.June,
    blocks: [
      {
        Component: Message,
        content: `Ask a question about your ${message(
          reportType,
        )} or here’s some popular prompts to get
      started`,
      },
      ...prompts(reportType).map((prompt) => ({
        Component: PromptSuggestion,
        content: prompt,
      })),
    ],
  };
}

const initialState: IAIState = {
  isVisible: false,
  isExecutingPrompt: false,
  isExecutingQuery: false,
  conversationBlocks: [],
};

interface IAIState {
  isVisible: boolean;
  isExecutingPrompt: boolean;
  isExecutingQuery: boolean;
  conversationBlocks: IConversationBlock[];
}

export const slice = createSlice({
  name: "ai",
  initialState,
  reducers: {
    reset: (state) => {
      state.conversationBlocks = [];
    },
    togglePrompt: (state, action: PayloadAction<{ value: boolean }>) => {
      state.isVisible = action.payload.value;
    },
    toggleExecutingPrompt: (
      state,
      action: PayloadAction<{ value: boolean }>,
    ) => {
      state.isExecutingPrompt = action.payload.value;
    },
    toggleExecutingQuery: (
      state,
      action: PayloadAction<{ value: boolean }>,
    ) => {
      state.isExecutingQuery = action.payload.value;
    },
    addConversationBlock: (
      state,
      action: PayloadAction<{ conversationBlock: IConversationBlock }>,
    ) => {
      const conversationBlock = action.payload.conversationBlock;
      state.conversationBlocks = [
        ...state.conversationBlocks,
        conversationBlock,
      ];
    },
    removeConversationBlock: (
      state,
      action: PayloadAction<{ conversationBlockId: string }>,
    ) => {
      const updatedConversationBlocks = state.conversationBlocks.filter(
        (block) => block.id !== action.payload.conversationBlockId,
      );
      state.conversationBlocks = updatedConversationBlocks;
    },
  },
});

export default slice.reducer;
