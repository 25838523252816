import { ITraitConfig, ITraitWithConfig } from "core/types/Trait";
import { ComputationType } from "core/constants/traits";
import { TimerangePicker } from "core/components/SmartTrait/TimerangePicker";

export const Timerange: React.FC<{
  trait: ITraitWithConfig;
  onChangeTimerange: (
    timerange: Pick<
      ITraitConfig,
      "timeRangeType" | "intervalValue" | "intervalType"
    >,
  ) => void;
  isPaywalled?: boolean;
}> = ({ trait, onChangeTimerange, isPaywalled = false }) => {
  return (
    <div>
      <TimerangePicker
        key={trait.config.computationType}
        onChange={(val) =>
          onChangeTimerange({
            timeRangeType: val.type,
            intervalValue: val.interval,
            intervalType: val.intervalType,
          })
        }
        value={{
          type: trait.config.timeRangeType,
          interval: trait.config.intervalValue,
          intervalType: trait.config.intervalType,
        }}
        isAllTimeDisabled={
          trait.config.computationType === ComputationType.EventCountChange
        }
        isPaywalled={isPaywalled}
      />
    </div>
  );
};
