import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { CheckIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
  ISortedByOption,
  SORTED_BY_OPTIONS_ARRAY,
} from "../../core/constants/sortFilters";

interface ISelectLabelsProps {
  sortedBy: ISortedByOption;
  setSortedBy: (sortedBy: ISortedByOption) => void;
}

export const SelectSortedBy: React.FC<ISelectLabelsProps> = ({
  sortedBy,
  setSortedBy,
}) => {
  return (
    <Menu closeOnSelect={false} closeOnBlur={true} size="sm">
      <MenuButton
        size="sm"
        minW="200px"
        height="32px"
        as={Button}
        rightIcon={<ChevronDownIcon />}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontSize="sm">
            <Text as="span" color="gray.500">
              Sort by:{" "}
            </Text>
            {sortedBy.label}
          </Text>
        </Flex>
      </MenuButton>
      <MenuList minWidth="200px">
        {SORTED_BY_OPTIONS_ARRAY.map((option) => (
          <MenuItem
            onClick={() => {
              setSortedBy(option);
            }}
          >
            <Flex w="100%" align={"center"} justify={"space-between"}>
              <Text
                color={option.value === sortedBy.value ? "primary" : "black"}
                fontSize={"sm"}
              >
                {option.label}
              </Text>
              {option.value === sortedBy.value && (
                <CheckIcon fontSize="xs" color="primary" />
              )}
            </Flex>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
