import { useState } from "react";
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

export const SubscriptionStartedModal: React.FC = () => {
  const queryParams = new URLSearchParams(window?.location?.search);
  const isPaymentSuccessParamPresent = !!queryParams.get(`payment_success`);

  const [isPaymentSuccess, setIsPaymentSuccess] = useState(
    isPaymentSuccessParamPresent,
  );

  const onClose = () => {
    setIsPaymentSuccess(false);
  };
  return (
    <Modal
      closeOnEsc
      closeOnOverlayClick
      isCentered
      isOpen={isPaymentSuccess}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          borderTopRadius="md"
          bg="linear-gradient(21deg, rgba(126,213,226,1) 26%, rgba(116,221,141,1) 100%)"
          pb={0}
        >
          <Box
            borderRadius="lg"
            h="100px"
            w="100%"
            bgImage={`url('/paywall_3d_logo.png')`}
            bgRepeat="no-repeat"
            bgPosition="center"
            bgSize="contain"
          />
        </ModalHeader>
        <ModalCloseButton color="white" />
        <ModalBody>
          <div className="flex flex-col gap-2 py-5 text-center">
            <p className="text-3xl font-bold">Thanks for subscribing</p>
            <p className="text-md px-5 font-normal text-gray-700">
              You're now on the{" "}
              <span className="font-semibold">June Growth</span> plan. You can
              start using company level templates and your monthly event
              threshold has increased 🎉
            </p>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
