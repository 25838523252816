import { ITimerangeOption } from "core/types/TimerangeOption";
import { ITemplateConfig } from "core/types/TemplateConfig";
import { IReport } from "core/types/Report";
import { TimerangePicker } from "core/modules/reports/Timerange/TimerangePicker";
import EditableReportTitle from "core/modules/reports/Navbar/EditableReportTitle";
import { useReportTimerange } from "core/hooks/useReportTimerange";
import {
  AdjustmentsHorizontalIcon,
  EyeSlashIcon,
} from "@heroicons/react/20/solid";
import { Button } from "@chakra-ui/react";
import { ReportLabels } from "./ReportLabels";
import { OverflowMenu } from "../Overflow/Menu";

export const ReportNavbar: React.FC<{
  templateConfig: ITemplateConfig;
  report: IReport;
  showSetup: boolean;
  onToggleSetup: () => void;
}> = ({ report, showSetup, templateConfig, onToggleSetup }) => {
  const { hasDateRangeSection, dateRangeOptions, timerangeTooltipText } =
    templateConfig;

  const {
    label,
    timerangeType,
    relativeTimerange,
    customTimerange,
    setCustomTimerange,
    saveTimerange,
  } = useReportTimerange(report);

  return (
    <div className="flex max-w-full items-center justify-between">
      <div className="flex gap-2">
        <div className="flex-end flex">
          <EditableReportTitle reportId={report.id} appId={report.appId} />
        </div>
        <div>
          <ReportLabels report={report} />
        </div>
      </div>
      <div className="flex items-center gap-2">
        <OverflowMenu report={report} />
        {hasDateRangeSection && (
          <div className="flex items-center">
            <TimerangePicker
              label={label}
              options={dateRangeOptions}
              timerangeType={timerangeType}
              relativeTimerange={relativeTimerange as ITimerangeOption}
              customTimerange={customTimerange}
              setCustomTimerange={setCustomTimerange}
              saveTimerange={saveTimerange as (o: ITimerangeOption) => void}
              tooltipText={timerangeTooltipText}
            />
          </div>
        )}
        <Button
          onClick={onToggleSetup}
          leftIcon={
            showSetup ? (
              <EyeSlashIcon className="h-4 w-4" />
            ) : (
              <AdjustmentsHorizontalIcon className="h-4 w-4" />
            )
          }
        >
          {showSetup ? "Hide editor" : "Edit"}
        </Button>
      </div>
    </div>
  );
};
