import { useCallback, useEffect, useState } from "react";
import { IPropertyValue } from "../types/Property";
import { useGetPropertyValuesQuery } from "../models/properties";
import { useDebounce } from "./useDebounce";

export const usePropertyValues = ({
  appId,
  eventId,
  property,
  days,
}: {
  appId: number;
  eventId: number;
  property: string;
  days?: number;
}) => {
  const [cache, setCache] = useState<IPropertyValue[]>([]);
  const [values, setValues] = useState<IPropertyValue[]>([]);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const {
    data: result,
    isLoading,
    isFetching,
    error,
  } = useGetPropertyValuesQuery({
    appId: Number(appId),
    eventId,
    property: property,
    searchTerm: debouncedSearchTerm,
    page,
    days,
  });

  const pagy = result?.pagy;
  const count = result?.pagy?.count ?? 0;
  const hasMore = pagy?.last !== page;

  // cleanup on property change
  useEffect(() => {
    setPage(1);
    setCache([]);
    setValues([]);
  }, [appId, eventId, property, days]);

  // cleanup when user is typing something in search
  useEffect(() => {
    setPage(1);
    setValues([]);
  }, [searchTerm]);

  // combine all pages and keep cache to not flicker UI
  useEffect(() => {
    if (result?.data) {
      const allValues = page === 1 ? result.data : [...values, ...result.data];

      setValues(allValues);
      setCache(allValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result?.data]);

  const loadMore = useCallback(() => {
    if (hasMore) {
      setPage(page + 1);
    }
  }, [page, hasMore]);

  return {
    data: cache,
    error,
    count,
    pagy,
    isLoading: isLoading || isFetching,
    hasMore,
    loadMore,
    searchTerm,
    setSearchTerm,
  };
};
