import { Text } from "@chakra-ui/react";

interface ITooltipBodyProps {
  header?: string;
  text: string;
}

export const TooltipBody: React.FC<ITooltipBodyProps> = ({ header, text }) => {
  return (
    <div className="flex flex-col p-1">
      {header ? (
        <div className="mb-2 flex">
          <Text
            data-testid="selector-tooltip-body-header"
            fontSize="sm"
            fontWeight="bold"
          >
            {header}
          </Text>
        </div>
      ) : undefined}
      <div className="flex">
        <Text fontSize="sm">{text}</Text>
      </div>
    </div>
  );
};
