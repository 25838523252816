import React from "react";
import { classNames } from "core/helpers/classNames";
import { Box, ComponentDefaultProps, Flex } from "@chakra-ui/react";

interface ISetupCard extends ComponentDefaultProps {
  avatars: React.ReactElement;
  title: string;
  description: string;
  badge: string;
  badgeColor?: string;
}

export const SetupCard: React.FC<ISetupCard> = ({
  avatars,
  title,
  description,
  badge,
  badgeColor = "green",
  onClick,
}) => {
  return (
    <Flex
      className="shadow-sm"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="lg"
      p={5}
      justifyContent="space-between"
      cursor="pointer"
      _hover={{ boxShadow: "sm" }}
      onClick={onClick}
    >
      <Flex direction="column" gap={4}>
        <Flex>{avatars}</Flex>
        <Flex direction="column">
          <p className="text-sm font-medium">{title}</p>
          <p className="text-xs text-gray-600">{description}</p>
        </Flex>
      </Flex>
      <Flex>
        <Box>
          <span
            className={classNames(
              "inline-flex items-center rounded-full  px-2 py-1 text-xs font-medium",
              badgeColor && `bg-${badgeColor}-50`,
              badgeColor && `text-${badgeColor}-500`,
            )}
          >
            {badge}
          </span>
        </Box>
      </Flex>
    </Flex>
  );
};
