import React, { useState } from "react";
import { getReportConfig } from "core/helpers/getReportConfig";
import {
  Button,
  Checkbox,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

interface ISelectReportTypeProps {
  reportTypes: number[];
  selectedReportTypes: number[];
  setSelectedReportTypes: (reportTypes: number[]) => void;
}

export const SelectReportTypes: React.FC<ISelectReportTypeProps> = ({
  reportTypes,
  selectedReportTypes,
  setSelectedReportTypes,
}) => {
  const [reportTypesSearchQuery, setReportTypesSearchQuery] = useState("");

  const addReportType = (reportType: number) => {
    setSelectedReportTypes([...selectedReportTypes, reportType]);
  };

  const removeReportTypes = (reportType: number) => {
    setSelectedReportTypes(selectedReportTypes.filter((r) => r !== reportType));
  };

  const visibleReportTypes =
    reportTypesSearchQuery !== ""
      ? reportTypes.filter((r) =>
          getReportConfig(r)!
            .title.toLowerCase()
            .includes(reportTypesSearchQuery.toLowerCase()),
        )
      : reportTypes;
  const menuText = selectedReportTypes.length
    ? selectedReportTypes.length + " selected"
    : "All";

  return (
    <>
      <Menu closeOnSelect={false} closeOnBlur={true}>
        <MenuButton
          size="sm"
          minW="200px"
          height="32px"
          as={Button}
          rightIcon={<ChevronDownIcon />}
        >
          <div className="flex items-center justify-between">
            <Text fontSize="sm">
              <Text as="span" color="gray.500">
                Report types:{" "}
              </Text>
              {menuText}
            </Text>
          </div>
        </MenuButton>
        <MenuList minWidth="200px" pt={0}>
          <div className="flex gap-2 px-1 py-2">
            <Input
              borderRadius="lg"
              placeholder="Search report types"
              size="sm"
              value={reportTypesSearchQuery}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setReportTypesSearchQuery(e.currentTarget.value)
              }
            />
          </div>
          <MenuItem py={1}>
            <Checkbox
              size="sm"
              colorScheme={"purple"}
              key={"all"}
              isChecked={
                selectedReportTypes.length === visibleReportTypes.length
              }
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedReportTypes(visibleReportTypes);
                } else {
                  setSelectedReportTypes([]);
                }
              }}
            >
              <Text
                fontSize="sm"
                color="gray.700"
                textAlign="center"
                fontWeight="semibold"
              >
                Select all
              </Text>
            </Checkbox>
          </MenuItem>

          {visibleReportTypes.length === 0 && (
            <Text p={2} fontSize="sm" color="gray.700" textAlign="center">
              No type found
            </Text>
          )}
          {visibleReportTypes.map((reportType: number) => {
            return (
              <MenuItem py={1}>
                <Checkbox
                  w="100%"
                  colorScheme={"purple"}
                  key={reportType}
                  isChecked={selectedReportTypes.includes(reportType)}
                  size="sm"
                  onChange={(e) => {
                    if (e.target.checked) {
                      addReportType(reportType);
                    } else {
                      removeReportTypes(reportType);
                    }
                  }}
                >
                  <Text fontSize="sm" color="gray.700" textAlign="center">
                    {getReportConfig(reportType)!.title}
                  </Text>
                </Checkbox>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </>
  );
};
