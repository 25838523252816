import React from "react";
import PropTypes from "prop-types";
import { Flex, Grid, GridItem, Text, Box } from "@chakra-ui/react";
import { Line, LineChart } from "core/design-system/charts/LineChart";
import colors from "core/design-system/constants/theme/colors";

function Insight({ title, insight, children }) {
  return (
    <GridItem
      colSpan={2}
      rowSpan={1}
      direction="row"
      p={4}
      bg="gray.50"
      borderRadius="lg"
    >
      <Flex align="center" direction="row" w="100%" justify="space-between">
        <Flex direction="column">
          <Text fontSize="sm" fontWeight="semibold" mb={1}>
            {title}
          </Text>
          <Text fontSize="sm" color="gray.700">
            {insight}
          </Text>
          <Flex justify="flex-end">{children}</Flex>
        </Flex>
      </Flex>
    </GridItem>
  );
}

function TwentyPercentRuleGraph({ retentionPercent }) {
  if (retentionPercent < 20)
    return (
      <Flex mt={5} w="full" direction="column" gridGap={5}>
        <Flex direction="column">
          <Flex>
            <Text fontWeight="semibold" fontSize="sm" color="gray.400">
              20%
            </Text>
          </Flex>
          <Flex>
            <Box border="2px dashed" borderColor="gray.300" w="full" />
          </Flex>
        </Flex>
        <Flex direction="column">
          <Flex direction="row" justifyContent="space-between">
            <Flex>
              <Text fontSize="sm" color="orange.700" fontWeight="semibold">
                {retentionPercent}%
              </Text>
            </Flex>
            <Flex>
              <Text fontSize="sm" color="orange.700" fontWeight="semibold">
                Below
              </Text>
            </Flex>
          </Flex>
          <Box bg="orange.600" h="4px" w="full" borderRadius="sm" />
        </Flex>
      </Flex>
    );

  return (
    <Flex mt={5} w="full" direction="column" gridGap={5}>
      <Flex direction="column">
        <Flex direction="row" justifyContent="space-between">
          <Flex>
            <Text fontSize="sm" color="green.500" fontWeight="semibold">
              {retentionPercent}%
            </Text>
          </Flex>
          <Flex>
            <Text fontSize="sm" color="green.500" fontWeight="semibold">
              Above
            </Text>
          </Flex>
        </Flex>
        <Box bg="green.400" h="4px" w="full" borderRadius="sm" />
      </Flex>
      <Flex direction="column">
        <Flex>
          <Text fontWeight="semibold" fontSize="sm" color="gray.400">
            20%
          </Text>
        </Flex>
        <Flex>
          <Box border="2px dashed" borderColor="gray.300" w="full" />
        </Flex>
      </Flex>
    </Flex>
  );
}

function FirstDaysChart({ retentionPercent }) {
  if (retentionPercent < 20)
    return (
      <Flex
        pt={5}
        direction="row"
        align="center"
        justify="flex-start"
        h="100px"
        w="100%"
        position="relative"
      >
        <Text
          fontSize="sm"
          color="orange.700"
          fontWeight="semibold"
          position="absolute"
          right="0px"
          top="20px"
        >
          Steep curve
        </Text>
        <LineChart
          margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
          height={100}
        >
          <Line
            dataKey="y"
            activeDot={false}
            dot={false}
            isAnimationActive={false}
            stroke={colors.orange[500]}
            strokeWidth={4}
            data={[
              { x: 1, y: 100 },
              { x: 2, y: 40 },
              { x: 3, y: 50 },
              { x: 4, y: 20 },
              { x: 5, y: 10 },
            ]}
          />
        </LineChart>
      </Flex>
    );

  return (
    <Flex
      pt={5}
      direction="row"
      align="center"
      justify="flex-start"
      h="100px"
      w="100%"
      position="relative"
    >
      <Text
        fontSize="sm"
        color="green.600"
        fontWeight="semibold"
        position="absolute"
        right="0px"
        top="20px"
      >
        Smooth curve
      </Text>
      <LineChart
        margin={{ top: 20, left: 0, right: 0, bottom: 0 }}
        height={100}
      >
        <Line
          dataKey="y"
          activeDot={false}
          dot={false}
          isAnimationActive={false}
          stroke={colors.green[400]}
          strokeWidth={4}
          data={[
            { x: 1, y: 50 },
            { x: 2, y: 70 },
            { x: 3, y: 30 },
            { x: 4, y: 60 },
            { x: 5, y: 50 },
          ]}
        />
      </LineChart>
    </Flex>
  );
}

function GraphInsight({ graphInsightOverview, retentionPercent }) {
  if (!graphInsightOverview) return null;

  return (
    <Grid mb={5} px={5} templateColumns={"repeat(4, 1fr)"} gridGap={4}>
      <Insight
        title="20% rule"
        insight={graphInsightOverview.percentRuleInsight}
      >
        <TwentyPercentRuleGraph retentionPercent={retentionPercent} />
      </Insight>
      <Insight title="First days" insight={graphInsightOverview.firstDay}>
        <FirstDaysChart retentionPercent={retentionPercent} />
      </Insight>
    </Grid>
  );
}

GraphInsight.propTypes = {
  graphInsightOverview: PropTypes.object,
  retentionPercent: PropTypes.number,
};

export default GraphInsight;
