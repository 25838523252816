/**
 * Prettyfies a number to a string with thousands separators
 *
 */

export const thousands = (n: number, separator?: string): string =>
  n.toString().replace(/(\d)(?=(\d{3})+\b)/g, "$1" + ("," || separator));

/**
 * Appends K to a number if it is greater than 1000
 *
 */

export const thousandsToK = (n: number): string => {
  if (n >= 1000000) {
    return `${(n / 1000000).toFixed(n % 1000000 === 0 ? 0 : 1)}m`;
  }
  if (n >= 1000) {
    return `${(n / 1000).toFixed(n % 1000 === 0 ? 0 : 1)}k`;
  }
  return n.toString();
};
