import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { getNextPath } from "helpers/auth";
import { selector as appSelector } from "core/models/app";
import Loader from "Components/Loader";

export default function OauthRedirect() {
  const navigate = useNavigate();
  const { data: currentApp } = useSelector(appSelector);

  useEffect(() => {
    const nextPath = getNextPath();
    const queryParams = new URLSearchParams(window.location.search);
    const integration = queryParams.get("integration");
    const success = queryParams.get("success");

    if (currentApp && integration && integration !== undefined) {
      navigate(
        `/a/${currentApp.id}/settings/integrations/${integration}?success=${success}`,
      );
    } else if (nextPath && nextPath.startsWith("http")) {
      window.location.replace(nextPath);
    } else if (nextPath) {
      navigate(nextPath);
    } else {
      navigate("/");
    }
  }, [navigate, currentApp]);

  return <Loader />;
}
