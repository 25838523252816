import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IQuery } from "core/types/Query";
import { usePinQueryMutation } from "core/models/reports";
import {
  useCreateQueryMutation,
  useLazyExecuteQueryQuery,
} from "core/models/queries";
import { togglePrompt as _togglePrompt } from "core/models/ai/actions/togglePrompt";
import { toggleExecutingQuery as _toggleExecutingQuery } from "core/models/ai/actions/toggleExecutingQuery";
import { toggleExecutingPrompt as _toggleExecutingPrompt } from "core/models/ai/actions/toggleExecutingPrompt";
import { reset as _reset } from "core/models/ai/actions/reset";
import { addConversationBlock as _addConversationBlock } from "core/models/ai/actions/addConversationBlock";
import {
  From,
  IConversationBlock,
  initialConversationsBlock,
} from "core/models/ai";
import { RootState } from "core/initializers/store";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { uuid } from "core/helpers/uuid";
import { MessageResult } from "core/components/AI/Messages/Result";
import { Message } from "core/components/AI/Message";
import { TryAgain } from "core/components/AI/Actions/TryAgain";
import { PinToReport } from "core/components/AI/Actions/PinToReport";
import { useReportSetup } from "./useReportSetup";

function transformConversation(conversationBlocks: IConversationBlock[]) {
  let transpiledString = "";

  conversationBlocks.slice(-3).forEach((block) => {
    if (block.from === "june") {
      block.blocks.forEach((message) => {
        if (message.props && message.props.query) {
          transpiledString += "You: " + message.props.query.query + "\n";
        }
      });
    } else if (block.from === "user") {
      block.blocks.forEach((message) => {
        transpiledString += "User: " + message.content + "\n";
      });
    }
  });

  return (
    transpiledString || "No conversation history. The chat has just started."
  );
}

export const useAI = () => {
  const dispatch = useDispatch();
  const { id: appId, aiOptedIn } = useCurrentApp();
  const { reportId } = useParams<{
    reportId: string;
  }>() as {
    reportId: string;
  };
  const { invalidateInsights, fetchReport, currentReport } = useReportSetup();

  // State
  const isVisible = useSelector((state: RootState) => state.ai.isVisible);
  const isExecutingPrompt = useSelector(
    (state: RootState) => state.ai.isExecutingPrompt,
  );
  const isExecutingQuery = useSelector(
    (state: RootState) => state.ai.isExecutingQuery,
  );
  const conversationBlocks = useSelector(
    (state: RootState) => state.ai.conversationBlocks,
  );

  // Actions
  const toggleExecutingPrompt = (val: boolean) =>
    dispatch(_toggleExecutingPrompt(val));
  const toggleExecutingQuery = (val: boolean) =>
    dispatch(_toggleExecutingQuery(val));
  const addConversationBlock = (conversationBlock: IConversationBlock) =>
    dispatch(_addConversationBlock(conversationBlock));

  const reset = () => dispatch(_reset());
  const togglePrompt = (val: boolean) => {
    dispatch(_togglePrompt(val));
    if (!val) {
      invalidateInsights();
      fetchReport();
      reset();
    } else if (val && conversationBlocks.length === 0) {
      if (currentReport.reportType)
        addConversationBlock(
          initialConversationsBlock(currentReport.reportType),
        );
    }
  };

  // Api
  const [createQuery] = useCreateQueryMutation();
  const [executeQuery] = useLazyExecuteQueryQuery();
  const [pinQuery] = usePinQueryMutation();

  function handleError(
    message: string,
    prompt: string,
    query?: string,
    error?: string,
  ) {
    const errorConversationBlock = {
      id: uuid(),
      from: From.June,
      hasError: true,
      blocks: [
        {
          Component: Message,
          content: message,
        },
        {
          Component: TryAgain,
          props: { prompt, query, error },
        },
      ],
    } as IConversationBlock;
    toggleExecutingPrompt(false);
    toggleExecutingQuery(false);
    addConversationBlock(errorConversationBlock);
  }

  const ask = async (prompt: string, query?: string, error?: string) => {
    const userConversationBlock = {
      id: uuid(),
      from: From.User,
      blocks: [
        {
          Component: Message,
          content: prompt,
        },
      ],
    } as IConversationBlock;
    addConversationBlock(userConversationBlock);
    toggleExecutingPrompt(true);

    const promptResponse = await createQuery({
      appId,
      reportId: Number(reportId),
      prompt,
      transcript: transformConversation(conversationBlocks),
      sqlQuery: query,
      error,
    });
    const newQuery = (promptResponse as { data: IQuery })?.data as IQuery;
    if (!newQuery?.id)
      return handleError(
        "I'm sorry, I wasn't able to turn your question into a query",
        prompt,
      );

    toggleExecutingPrompt(false);
    toggleExecutingQuery(true);

    const { data: result } = await executeQuery({
      appId,
      query: newQuery?.query,
      page: 1,
    });
    const newResult = result?.result;
    if (!newResult)
      return handleError(
        "I'm sorry, I wasn't able to run the query",
        prompt,
        result?.query,
        result?.error,
      );

    const juneConversationBlock = {
      id: uuid(),
      from: From.June,
      blocks: [
        {
          Component: MessageResult,
          props: {
            result: newResult,
            query: newQuery,
          },
        },
        {
          Component: TryAgain,
          props: { prompt },
        },
        {
          Component: PinToReport,
          props: {
            query: newQuery,
          },
        },
      ],
    } as IConversationBlock;
    toggleExecutingQuery(false);
    addConversationBlock(juneConversationBlock);
  };

  return {
    // State
    isVisible,
    isExecutingPrompt,
    isExecutingQuery,
    conversationBlocks,
    aiOptedIn,

    // Actions
    togglePrompt,
    ask,
    pinQuery,
    addConversationBlock,
    reset,
  };
};
