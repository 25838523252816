interface Navigator {
  userAgentData?: {
    platform: string;
  };
}

export function isWindows() {
  const userAgentData = (navigator as Navigator).userAgentData;
  return userAgentData
    ? userAgentData.platform.includes("Win")
    : navigator.userAgent.includes("Win");
}
