import React from "react";

interface IconProps {
  className?: string;
}

export const EventIcon: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.42842 1.37137C2.88285 1.37137 2.35963 1.58809 1.97386 1.97386C1.58809 2.35963 1.37137 2.88285 1.37137 3.42842V12.5716C1.37137 13.1171 1.58809 13.6404 1.97386 14.0261C2.35963 14.4119 2.88285 14.6286 3.42842 14.6286H5.91004C6.28873 14.6286 6.59572 14.9356 6.59572 15.3143C6.59572 15.693 6.28873 16 5.91004 16H3.42842C2.51914 16 1.64711 15.6388 1.00416 14.9958C0.361207 14.3529 0 13.4809 0 12.5716V3.42842C0 2.51914 0.361207 1.64711 1.00416 1.00416C1.64711 0.361207 2.51914 0 3.42842 0H12.5716C13.4809 0 14.3529 0.361207 14.9958 1.00416C15.6388 1.64711 16 2.51914 16 3.42842V8C16 8.37869 15.693 8.68568 15.3143 8.68568C14.9356 8.68568 14.6286 8.37869 14.6286 8V3.42842C14.6286 2.88285 14.4119 2.35963 14.0261 1.97386C13.6404 1.58809 13.1171 1.37137 12.5716 1.37137H3.42842Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.23821 7.47184L13.221 10.4632L10.8518 10.9371L10.8485 10.9377C10.6634 10.9757 10.4904 11.0589 10.3452 11.1798C10.1999 11.3008 10.0868 11.4558 10.016 11.6311L9.03847 14.0775L7.23821 7.47184ZM8.78574 14.71L8.7862 14.7088ZM6.75494 5.90367C6.97363 5.87884 7.19484 5.91768 7.392 6.01551L7.39387 6.01644L14.4328 9.53591C14.645 9.64294 14.8185 9.81342 14.9293 10.0236C15.04 10.2339 15.0826 10.4734 15.0509 10.7089C15.0192 10.9444 14.915 11.1642 14.7526 11.3377C14.5902 11.5112 14.3778 11.6298 14.145 11.677L14.1433 11.6773L11.2425 12.2575L10.0601 15.2165V15.2165C9.97142 15.4392 9.81467 15.6291 9.61254 15.7581C9.4102 15.8871 9.17238 15.9492 8.93279 15.9356C8.69319 15.9219 8.46396 15.8332 8.27758 15.682C8.11295 15.5485 7.98914 15.3721 7.91924 15.1731L7.91144 15.1575L5.78004 7.33687C5.7228 7.12435 5.72812 6.89982 5.79537 6.69025C5.86262 6.48068 5.98894 6.29498 6.15915 6.15545C6.32937 6.01592 6.53624 5.92849 6.75494 5.90367Z"
        fill="currentColor"
      />
    </svg>
  );
};
