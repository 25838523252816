import { SnakeCasedProperties } from "type-fest";
import humps from "humps";
import {
  ICreateAmplitudeImportTask,
  ICreateMixpanelImportTask,
} from "core/types/ImportTask";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IImportTask } from "../types/ImportTask";

interface IDeleteImportTask {
  appId: number;
  id: number;
}

export const importTasksApi = createApi({
  reducerPath: "importTasksApi",
  baseQuery: fetchBaseQuery({ baseUrl: import.meta.env.VITE_API_HOST }),
  tagTypes: ["ImportTasks", "AmplitudeImportTasks", "MixpanelImportTasks"],
  endpoints: (builder) => ({
    getImportTasks: builder.query<IImportTask[], { appId: number }>({
      query: ({ appId }) => ({
        url: "import_tasks",
        method: "GET",
        credentials: "include",
        mode: "cors",
        params: {
          app_id: appId,
        },
      }),
      providesTags: ["ImportTasks"],
      transformResponse: (
        response: SnakeCasedProperties<IImportTask[]>,
      ): IImportTask[] => humps.camelizeKeys(response) as IImportTask[],
    }),
    getMixpanelImportTasks: builder.query<IImportTask[], { appId: number }>({
      query: ({ appId }) => ({
        url: "import_tasks",
        method: "GET",
        credentials: "include",
        mode: "cors",
        params: {
          app_id: appId,
          source_name: "mixpanel",
        },
      }),
      providesTags: ["MixpanelImportTasks"],
      transformResponse: (
        response: SnakeCasedProperties<IImportTask[]>,
      ): IImportTask[] => humps.camelizeKeys(response) as IImportTask[],
    }),
    getAmplitudeImportTasks: builder.query<IImportTask[], { appId: number }>({
      query: ({ appId }) => ({
        url: "import_tasks",
        method: "GET",
        credentials: "include",
        mode: "cors",
        params: {
          app_id: appId,
          source_name: "amplitude",
        },
      }),
      providesTags: ["AmplitudeImportTasks"],
      transformResponse: (
        response: SnakeCasedProperties<IImportTask[]>,
      ): IImportTask[] => humps.camelizeKeys(response) as IImportTask[],
    }),
    createAmplitudeImportTask: builder.mutation<
      ICreateAmplitudeImportTask,
      ICreateAmplitudeImportTask
    >({
      query: ({ appId, config }) => ({
        url: "import_tasks",
        method: "POST",
        credentials: "include",
        mode: "cors",
        params: {
          app_id: appId,
          source_name: "amplitude",
          config: JSON.stringify(humps.decamelizeKeys(config)),
        },
      }),
      invalidatesTags: ["AmplitudeImportTasks", "ImportTasks"],
    }),
    createMixpanelImportTask: builder.mutation<
      ICreateMixpanelImportTask,
      ICreateMixpanelImportTask
    >({
      query: ({ appId, config }) => ({
        url: "import_tasks",
        method: "POST",
        credentials: "include",
        mode: "cors",
        params: {
          app_id: appId,
          source_name: "mixpanel",
          config: JSON.stringify(humps.decamelizeKeys(config)),
        },
      }),
      invalidatesTags: [
        "ImportTasks",
        "AmplitudeImportTasks",
        "MixpanelImportTasks",
      ],
    }),
    deleteImportTask: builder.mutation<void, IDeleteImportTask>({
      query: ({ appId, id }) => ({
        url: `/import_tasks/${id}`,
        method: "DELETE",
        params: humps.decamelizeKeys({ appId, id }),
        credentials: "include",
        mode: "cors",
      }),
      invalidatesTags: [
        "ImportTasks",
        "AmplitudeImportTasks",
        "MixpanelImportTasks",
      ],
    }),
  }),
});

export const {
  useGetAmplitudeImportTasksQuery,
  useCreateAmplitudeImportTaskMutation,
  useGetMixpanelImportTasksQuery,
  useCreateMixpanelImportTaskMutation,
  useGetImportTasksQuery,
  useDeleteImportTaskMutation,
} = importTasksApi;
