import { IApiKey } from "core/models/apiKeys";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useApiKeys } from "core/hooks/useApiKeys";
import Icon from "core/design-system/components/Icon";
import { FRESHPAINT } from "core/constants/apiKeys";
import ClipboardInput from "core/components/ClipboardInput";
import { Button, Flex, Link, Spinner, Text } from "@chakra-ui/react";

export const ConnectFreshpaint = () => {
  const { id: appId } = useCurrentApp();
  const { apiKeys, isFetching, createApiKey } = useApiKeys({
    appId,
    source: FRESHPAINT,
  });

  return (
    <Flex direction="column" gap={2} fontSize="sm">
      <Text>
        To connect Freshpaint and June, you need to complete the following
        steps.{" "}
        <Link
          color="purple.500"
          href="https://help.june.so/en/articles/6788912-how-to-connect-freshpaint"
          isExternal
        >
          Read the full doc.
        </Link>
      </Text>
      <Flex direction="column">
        <Text fontWeight="semibold">Copy your Freshpaint API key </Text>
        {isFetching && !apiKeys && <Spinner />}
        <Flex direction="column" p={4} gap={2} alignItems="center">
          {apiKeys?.map((apiKey: IApiKey) => (
            <Flex key={apiKey.id} alignItems="center" w="full" gap={4}>
              <Flex alignItems="center" gap={2}>
                <Icon
                  iconType="logo"
                  name="companyFreshPaintLogo"
                  color="gray.500"
                />
                {apiKey.name}
              </Flex>
              <ClipboardInput
                value={apiKey.key}
                key={apiKey.key}
                fontSize="sm"
                inputSize="sm"
                w="35%"
              />
            </Flex>
          ))}
          {!apiKeys?.length && (
            <Button w="fit-content" onClick={createApiKey}>
              Generate write key
            </Button>
          )}
        </Flex>
      </Flex>
      <Text fontWeight="semibold">
        Set up June as a{" "}
        <Link href="https://documentation.freshpaint.io/destinations/apps/june">
          destination
        </Link>
        .
      </Text>
    </Flex>
  );
};
