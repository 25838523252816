import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import { getCompanyName } from "core/helpers/companyName";
import Icon from "core/design-system/components/Icon";
import {
  getDarkColor,
  getLightColor,
} from "core/design-system/charts/Breakdown/utils";
import { Badge, ComponentDefaultProps, Flex, Tooltip } from "@chakra-ui/react";
import Group from "./Group";

export enum GroupType {
  Company = 0,
  Group = 1,
}

interface ISingleGroupProps extends ComponentDefaultProps {
  appId?: string | number;
  id: string;
  traits: { [key: string]: string };
  previewMode?: boolean;
  description?: string | React.ReactNode;
  truncateName?: boolean;
  hideHoverBorder?: boolean;
  hideExternalLinkIcon?: boolean;
  isNewGroup?: "yes" | "no";
  traitValue?: string;
  traitColor?: string;
  groupType?: number;
}

export interface IGroup {
  groupId: string;
  traits: { [key: string]: string };
  groupType: number;
  firstEventTimestamp?: string;
  milestonesCompleted?: string;
}

export const GroupListSingleGroup: React.FC<ISingleGroupProps> = ({
  id,
  description,
  traits = {},
  previewMode = false,
  p = 2,
  truncateName = false,
  hideHoverBorder = false,
  hideExternalLinkIcon = false,
  isNewGroup = "no",
  traitValue,
  traitColor,
  groupType,
}) => {
  const { appId } = useParams<{ appId: string }>();
  const [showExternalLinkIcon, setShowExternalLinkIcon] = useState(false);

  if (previewMode) {
    return (
      <Flex
        p={p}
        align="center"
        justify="space-between"
        border={hideHoverBorder ? "none" : "1px"}
        borderRadius="lg"
        borderColor="white"
        _hover={{
          borderColor: hideHoverBorder ? "" : "gray.200",
          cursor: "pointer",
        }}
      >
        <Group
          group={{ id, appId: Number(appId), name: getCompanyName({ traits }) }}
          groupId={id}
          description={description}
          truncateName={truncateName}
          previewMode={previewMode}
        />
      </Flex>
    );
  }

  if (
    groupType === GroupType.Group &&
    (!traits || Object.keys(traits).length === 0)
  ) {
    return (
      <Tooltip label="This company hasn't been identified" placement="bottom">
        <Flex
          p={p}
          align="center"
          justify="space-between"
          border={hideHoverBorder ? "none" : "1px"}
          borderRadius="lg"
          borderColor="white"
          _hover={{ borderColor: "gray.200" }}
        >
          <Group
            unidentified
            groupId={id}
            description={description}
            truncateName={truncateName}
          />
          <Flex align="center">
            {isNewGroup === "yes" && (
              <Badge colorScheme="purple">New group</Badge>
            )}
          </Flex>
        </Flex>
      </Tooltip>
    );
  }

  return (
    <Link
      to={`/a/${appId}/${
        groupType === GroupType.Company ? "company" : "group"
      }/${encodeURIComponent(id)}/`}
      target="_blank"
      rel="noopener noreferrer"
      onMouseEnter={() => setShowExternalLinkIcon(true)}
      onMouseLeave={() => setShowExternalLinkIcon(false)}
    >
      <Flex
        p={p}
        align="center"
        justify="space-between"
        border={hideHoverBorder ? "none" : "1px"}
        borderRadius="lg"
        borderColor="white"
        _hover={{
          borderColor: hideHoverBorder ? "" : "gray.200",
          cursor: "pointer",
        }}
      >
        <Group
          groupId={id}
          group={{
            id,
            appId: Number(appId),
            name:
              groupType === GroupType.Company ? id : getCompanyName({ traits }),
          }}
          groupType={groupType}
          description={description}
          truncateName={truncateName}
        />

        <Flex align="center">
          {!hideExternalLinkIcon && showExternalLinkIcon && (
            <Icon
              mr={4}
              h={4}
              w={4}
              iconType="icon"
              cursor="pointer"
              color="gray.600"
              name="external-link"
            />
          )}
          {isNewGroup === "yes" && (
            <Badge colorScheme="purple">New group</Badge>
          )}
          {typeof traitValue !== "undefined" && traitColor && (
            <Badge
              bg={getLightColor(traitColor)}
              color={getDarkColor(traitColor)}
            >
              {traitValue}
            </Badge>
          )}
        </Flex>
      </Flex>
    </Link>
  );
};
