import React from "react";
import { ITrait } from "core/types/Trait";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import Trait from "./Trait";

const TRAIT_KEYS = {
  contact: "user",
  group: "company",
  company: "company",
} as { [key: string]: "user" | "company" };

export const PreviewTraitsContainer: React.FC<{
  traits?: ITrait[];
  computedTraits?: { user: ITrait[]; company?: ITrait[]; group?: ITrait[] };
  entityType: "contact" | "group" | "company";
  isLoading: boolean;
}> = ({ traits, computedTraits, entityType, isLoading }) => {
  const currentApp = useCurrentApp();
  const isContact = entityType === "contact";
  const traitKey = TRAIT_KEYS[entityType];

  const computedKeyedByName =
    computedTraits?.[traitKey]?.reduce(
      (acc, trait) => {
        acc[trait.name] = trait;
        return acc;
      },
      {} as { [key: string]: ITrait },
    ) ?? {};

  return (
    <>
      <Box
        p={4}
        w="100%"
        border="1px"
        borderRadius="lg"
        borderColor="gray.50"
        boxShadow="sm"
        bg="white"
      >
        <Flex mb={4} align="center" justify="space-between">
          <Text fontWeight="medium">
            {isContact ? <>User</> : <>Company</>}
          </Text>
        </Flex>
        <Box>
          {(traits ?? []).map(({ name, value }, index) => {
            const shouldShowWarning =
              (typeof value === "string" || typeof value === "number") &&
              Number(value) === 0 &&
              entityType === "group" &&
              !currentApp.receivingGroupContext;
            return (
              <Box key={index}>
                <Box pb={2}>
                  <Flex w="full" align="center" justifyContent="space-between">
                    <Flex w="full">
                      <Trait
                        traitId={Number(computedKeyedByName[name]?.id)}
                        name={name}
                        isComputed={!!computedKeyedByName[name]}
                        config={computedKeyedByName[name]?.config}
                        value={
                          typeof value === "string"
                            ? value
                            : JSON.stringify(value)
                        }
                        isTransient={false}
                        isLoading={isLoading}
                      />
                    </Flex>
                    {shouldShowWarning && (
                      <Flex w="20px" mr={3}>
                        <Tooltip
                          label="You may be seeing 0 due to not sending group_ids as part of your track calls"
                          hasArrow
                          placement="top"
                        >
                          <ExclamationTriangleIcon className="h-4 text-yellow-400" />
                        </Tooltip>
                      </Flex>
                    )}
                  </Flex>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
};
