import { WorkspaceWeeklyDigestOptIn } from "modules/Settings/WorkspaceWeeklyDigestOptIn";
import { WeeklyDigestOptIn } from "modules/Settings/WeeklyDigestOptIn";
import { DigestInsights } from "modules/Settings/Digest/DigestInsights";
import DefaultHeader from "modules/Pages/DefaultHeader";
import {
  InfoBody,
  InfoCard,
  InfoHeader,
} from "core/design-system/components/InfoCard";

const SECTION = {
  name: "Digest",
  title: "Digest",
  description: "Manage your email digest",
};

export const Digest: React.FC = () => {
  return (
    <div className="flex w-[985px] flex-col">
      <DefaultHeader w="inherit" section={SECTION} />
      <div className="flex flex-col gap-4">
        <InfoCard>
          <InfoHeader>What is a digest?</InfoHeader>
          <InfoBody>
            Digests are a weekly email delivered to you and your team to keep
            you aligned on who is most active and who is at risk of churning.
            This way you know which users need help before it’s too late, as
            well as those that are an opportunity to upsell.
          </InfoBody>
        </InfoCard>
        <h2 className="text-base font-semibold text-gray-900">
          Workspace settings
        </h2>
        <WorkspaceWeeklyDigestOptIn />
        <DigestInsights />
        <h2 className="text-base font-semibold text-gray-900">User settings</h2>
        <div className="flex w-full">
          <WeeklyDigestOptIn />
        </div>
      </div>
    </div>
  );
};
