import { useOnboardingChecklist } from "core/hooks/useOnboardingChecklist";
import { Spinner } from "@chakra-ui/react";
import { IOnboardingChecklist } from "core/models/onboardingChecklist";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const CompleteSetupFooter: React.FC = () => {
  const { appId } = useParams();
  const [checklist, setChecklist] = useState<IOnboardingChecklist | null>(null);

  const shouldPoll =
    !!appId && !(checklist?.isIdentifyingUsers && checklist?.isSendingEvents);

  const { onboardingChecklist } = useOnboardingChecklist({
    pollingEnabled: shouldPoll,
  });

  useEffect(() => {
    if (!appId) return;
    setChecklist(onboardingChecklist ?? null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingChecklist]);

  if (checklist?.isIdentifyingUsers && checklist?.isSendingEvents) return null;

  return (
    <div className="flex flex-col space-y-3 bg-[#FFFBF3] px-6 py-3">
      <div className="flex flex-1 items-center space-x-2">
        <Spinner
          thickness="2px"
          speed="1.2s"
          emptyColor="gray.200"
          color="purple.500"
          size="sm"
        />
        <p className="text-sm font-medium">Waiting for data...</p>
      </div>
    </div>
  );
};
