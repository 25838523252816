export const ProtectedAttributeTooltip: React.FC<{ integration: string }> = ({
  integration,
}) => {
  return (
    <div className="flex flex-col space-y-2 p-2">
      <div className="flex">
        <p className="text-sm">
          {integration === "hubspot"
            ? "Read only property"
            : `Protected attribute`}
        </p>
      </div>
      <div className="flex">
        <p className="text-xs text-gray-300">
          {integration === "hubspot"
            ? "This is a read only property"
            : "This is a protected property"}
        </p>
      </div>
    </div>
  );
};
