import { Flex, Grid, GridItem, Link, Text } from "@chakra-ui/layout";
import { Link as RouterLink } from "react-router-dom";

export const COLUMN_MIN_HEIGHT = "calc(100vh - 67px)";

export default function MobileWidgetsLayout({
  children,
  sidebar = null,
  sidebarProps = {},
}) {
  return (
    <Flex direction="column" h="100vh" alignItems="stretch">
      <Flex
        height="67px"
        px={6}
        py={4}
        borderBottomWidth={1}
        borderColor="gray.200"
        alignItems="center"
      >
        <RouterLink to="/">
          <Link
            display="block"
            rounded="md"
            borderWidth={1}
            py="1rem"
            px="0.5rem"
            lineHeight={0}
            borderColor="gray.200"
            mr={4}
            boxShadow="xs"
          >
            ←
          </Link>
        </RouterLink>
        <Text color="gray.600" fontSize="sm">
          June for iOS
        </Text>
      </Flex>

      <Grid
        templateColumns="repeat(12, minmax(0, 1fr))"
        borderRight="1px"
        borderColor="gray.200"
      >
        <GridItem
          colSpan={sidebar ? [12, 12, 8] : 12}
          borderRightWidth={sidebar ? [0, 0, 1] : 0}
          borderBottomWidth={sidebar ? [1, 0, 0] : 0}
          borderRightColor="gray.200"
          borderBottomColor="gray.200"
        >
          {children}
        </GridItem>
        {sidebar ? (
          <GridItem
            colSpan={[12, 12, 4]}
            position={["static", "sticky"]}
            height={["100%", COLUMN_MIN_HEIGHT]}
            top="67px"
            {...sidebarProps}
          >
            {sidebar}
          </GridItem>
        ) : null}
      </Grid>
    </Flex>
  );
}
