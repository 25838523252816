import React from "react";
import { IReport } from "core/types/Report";
import { useInsightCsvExport } from "core/hooks/useInsightCsvExport";
import { useContactDrilldown } from "core/hooks/useContactDrilldown";
import Error from "core/components/Error";
import { Drilldown } from "core/components/Drilldown";
import { UserListSingleContact } from "core/components/Contact/UserListSingleContact";
import { UseDisclosureReturn } from "@chakra-ui/react";

interface INewUsersListProps
  extends Pick<UseDisclosureReturn, "isOpen" | "onClose"> {
  report: IReport;
  newUsersCount: number;
  date: string;
  hour?: string;
  humanizedDate: string;
  intervalType: number;
}

export const NewUsersList: React.FC<INewUsersListProps> = ({
  report,
  intervalType,
  newUsersCount,
  humanizedDate,
  date,
  hour,
  onClose,
  isOpen,
}) => {
  const getInsightParams = () => {
    const baseParams = { intervalType, date };

    return hour !== "" ? { ...baseParams, hour } : baseParams;
  };

  const {
    contacts,
    isLoading,
    error,
    hasMoreContacts,
    loadMore,
    searchQuery,
    onSearch,
  } = useContactDrilldown({
    report,
    insightType: 4,
    skip: !isOpen,
    insightParams: getInsightParams(),
  });

  const { requestCsvExport, csvExportLoading } = useInsightCsvExport({
    report,
    insightId: 4,
    insightParams: getInsightParams(),
    count: newUsersCount,
  });

  if (error) {
    return <Error />;
  }

  const title = `There ${newUsersCount > 1 ? "are" : "is"} ${newUsersCount} new user${
    newUsersCount > 1 ? "s" : ""
  } for ${humanizedDate}`;

  return (
    <Drilldown isOpen={isOpen} onClose={onClose}>
      {(Drilldown) => (
        <>
          <Drilldown.Header>
            <Drilldown.Title title={title} isLoading={isLoading} />
            <Drilldown.DownloadButton
              isDisabled={newUsersCount <= 0}
              isLoading={csvExportLoading}
              onClick={requestCsvExport}
            />
            <Drilldown.Search
              placeholder="Search your users by email address..."
              searchQuery={searchQuery}
              onSearch={onSearch}
              disabled={newUsersCount <= 0}
            />
          </Drilldown.Header>
          <Drilldown.Body pt={4}>
            <Drilldown.List
              items={contacts}
              hasMore={hasMoreContacts}
              itemRenderer={({ item }) => (
                <UserListSingleContact
                  key={item.userId}
                  id={item.userId}
                  traits={item.traits}
                  isIdentified={item.isIdentified}
                />
              )}
              loadNext={loadMore}
              isLoading={isLoading}
              emptyStateText={`No new user`}
            />
          </Drilldown.Body>
        </>
      )}
    </Drilldown>
  );
};
