import { useInsightTitle } from "core/hooks/useInsightTitle";
import { SkeletonText } from "@chakra-ui/react";

export const DrilldownTitle: React.FC<{
  title: string;
  description?: string;
  isLoading: boolean;
}> = ({ title, description, isLoading }) => {
  const drilldownTitle = useInsightTitle({ title: title });

  if (isLoading) {
    return <SkeletonText margin={"0 auto"} width="50%" />;
  }

  return (
    <div className="max-w-4/5 mx-auto">
      <p className="mb-2 text-center text-lg font-bold">{drilldownTitle}</p>
      {description && (
        <p className="mt-2 text-sm text-gray-700">{description}</p>
      )}
    </div>
  );
};
