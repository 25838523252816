import colors from "core/design-system/constants/theme/colors";

export const FUNNEL_BREAKDOWN_COLORS = [
  "purple.400",
  "red.300",
  "cyan.300",
  "yellow.400",
  "pink.300",
  "teal.300",

  "purple.200",
  "red.100",
  "cyan.100",
  "yellow.200",
  "pink.100",
  "teal.100",
];

export const FUNNEL_BREAKDOWN_COLOR_HEX_CODES = [
  colors.purple[400],
  colors.red[300],
  colors.cyan[300],
  colors.yellow[400],
  colors.pink[300],
  colors.teal[300],

  colors.purple[200],
  colors.red[100],
  colors.cyan[100],
  colors.yellow[200],
  colors.pink[100],
  colors.teal[100],
];

export const getLightColor = (color: string) => {
  let parts = color.split(".");
  parts[1] = "100";
  return parts.join(".");
};

export const getDarkColor = (color: string) => {
  let parts = color.split(".");
  parts[1] = "500";
  return parts.join(".");
};

export const isTraitNotAvailable = (traitValue: string | undefined) =>
  traitValue === "";

export const getTraitValue = (traitValue: string) =>
  isTraitNotAvailable(traitValue) ? "Not available" : traitValue;

export const generateColorForTraitValueWithIndex = (
  traitValue: string | undefined,
  index: number | undefined,
) => {
  if (isTraitNotAvailable(traitValue)) return "gray.400";
  return FUNNEL_BREAKDOWN_COLORS[
    Number(index) % FUNNEL_BREAKDOWN_COLORS.length
  ];
};

export const generateHexColorForTraitValueWithIndex = (
  traitValue: string | undefined,
  index: number | undefined,
) => {
  if (isTraitNotAvailable(traitValue)) return colors.gray[400];
  return FUNNEL_BREAKDOWN_COLOR_HEX_CODES[
    Number(index) % FUNNEL_BREAKDOWN_COLOR_HEX_CODES.length
  ];
};
