import { useParams } from "react-router-dom";
import React from "react";
import { IInsightSection } from "core/types/TemplateConfig";
import { IReport } from "core/types/Report";
import { useContactDrilldown } from "core/hooks/useContactDrilldown";
import Error from "core/components/Error";
import { Drilldown } from "core/components/Drilldown";
import { UserListSingleContact } from "core/components/Contact/UserListSingleContact";
import { Button, Flex, Spinner, UseDisclosureReturn } from "@chakra-ui/react";
import { ITopPowerUser } from "./TopPowerUsersContainer";

interface ITopPowerUsersListProps
  extends Pick<UseDisclosureReturn, "isOpen" | "onClose"> {
  csvExportLoading: boolean;
  requestCsvExport: () => void;
  report: IReport;
  insight: IInsightSection;
}

export const TopPowerUsersList: React.FC<ITopPowerUsersListProps> = ({
  report,
  insight,
  isOpen,
  onClose,
  csvExportLoading,
  requestCsvExport,
}) => {
  const { appId } = useParams<{
    appId: string;
  }>();
  const { contacts, isLoading, error, hasMoreContacts, loadMore } =
    useContactDrilldown({
      report,
      insightType: 3,
      skip: !appId || Number(appId) === 0 || !isOpen,
      insightParams: { limit: 18 },
    });
  const topPowerUsers = contacts as any as ITopPowerUser[];

  if (error) {
    return <Error />;
  }

  return (
    <Drilldown isOpen={isOpen} onClose={onClose}>
      {(Drilldown) => (
        <>
          <Drilldown.Header>
            <Drilldown.Title title={insight.title} isLoading={isLoading} />
            <Drilldown.DownloadButton
              isDisabled={topPowerUsers?.length <= 0}
              isLoading={csvExportLoading}
              onClick={requestCsvExport}
            />
          </Drilldown.Header>
          <Drilldown.Body>
            {topPowerUsers.map((contact) => (
              <UserListSingleContact
                key={contact.userId}
                id={contact.userId}
                traits={contact.traits}
                description={`Power user for ${contact?.numberOfActiveWeeks} ${
                  contact?.numberOfActiveWeeks === 1 ? "week" : "weeks"
                }, used ${contact?.averageNumberOfActiveDaysPerWeek} ${
                  contact?.averageNumberOfActiveDaysPerWeek === 1
                    ? "day"
                    : "days"
                } per week on average`}
              />
            ))}
            {hasMoreContacts && (
              <Flex justify="center">
                <Button isDisabled={isLoading} onClick={loadMore}>
                  {isLoading ? <Spinner /> : "Load more"}
                </Button>
              </Flex>
            )}
          </Drilldown.Body>
        </>
      )}
    </Drilldown>
  );
};
