import { SnakeCasedPropertiesDeep } from "type-fest";
import humps from "humps";
import { baseQuery } from "core/initializers/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";

interface ISlackChannelsResponse {
  channels: ISlackChannel[];
}
interface ISlackChannel {
  name: string;
  id: string;
  isMember: boolean;
}

interface IGetSlackChannelsArgs {
  appId: number;
}

interface ISlackChannelName {
  name: string;
}

interface IGetSlackChannelsByIdArgs {
  channelId: string;
  appId: number;
}

export const slackAppsApi = createApi({
  baseQuery,
  reducerPath: "slackAppsApi",
  tagTypes: ["SlackApp", "SlackChannel", "SlackChannelName"],
  endpoints: (builder) => ({
    getSlackChannels: builder.query<
      ISlackChannelsResponse,
      IGetSlackChannelsArgs
    >({
      query: ({ appId }) => ({
        url: "/slack_apps/list_channels",
        params: { app_id: appId },
      }),
      providesTags: ["SlackChannel"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<ISlackChannelsResponse>,
      ): ISlackChannelsResponse =>
        humps.camelizeKeys(response) as ISlackChannelsResponse,
    }),
    getSlackChannelsById: builder.query<
      ISlackChannelName,
      IGetSlackChannelsByIdArgs
    >({
      query: ({ channelId, appId }) => ({
        url: "/slack_apps/find_channel",
        params: { channel_id: channelId, app_id: appId },
      }),
      providesTags: ["SlackChannelName"],
    }),
  }),
});

export const { useGetSlackChannelsQuery, useGetSlackChannelsByIdQuery } =
  slackAppsApi;
