import { ReactSortable } from "react-sortablejs";
import { useEffect, useState } from "react";
import {
  ITemplateConfigSetup,
  ITemplateConfigSetupSection,
} from "core/types/TemplateConfig";
import { IReport } from "core/types/Report";
import { useReportSetup } from "core/hooks/useReportSetup";
import {
  AUDIENCE_TYPE,
  MULTI_EVENT_TYPE,
  OR,
} from "core/constants/report-setup";
import { Box, Button, Divider, Flex, StyleProps } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { SetupSectionPanel } from "./SetupSectionPanel";

interface ISetupSectionsProps extends StyleProps {
  openPanelIndex: number;
  setup: ITemplateConfigSetup;
  report: IReport;
  setOpenPanelIndex: (index: number) => void;
}

function buildDynamicSections(
  openPanelIndex: number,
  funnelConfigSteps: any[],
  report: IReport,
  dynamicSetupSection?: ITemplateConfigSetupSection,
): ITemplateConfigSetupSection[] {
  return funnelConfigSteps
    .map((configKey, index) => {
      return {
        ...dynamicSetupSection,
        id: String(index),
        configKey,
        type: MULTI_EVENT_TYPE,
        // @ts-ignore
        isOpen: index === openPanelIndex,
        title: report.config["name"][configKey],
      };
    })
    .sort((a, b) => {
      const aIndex = report.config.order.indexOf(a.configKey);
      const bIndex = report.config.order.indexOf(b.configKey);
      return aIndex - bIndex;
    });
}

function generateStepId() {
  return Math.random().toString(36).slice(2);
}

export const FunnelSetupSections: React.FC<ISetupSectionsProps> = ({
  openPanelIndex,
  setup,
  report,
  setOpenPanelIndex,
}) => {
  const { addFunnelStep, updateFunnelOrder } = useReportSetup();
  setup.eventOperator = [OR]; // Override the current THEN join operator
  const dynamicSetupSection = setup.setupSections.find((s) => s.isDynamic);
  const funnelConfigSteps = Object.keys(report.config).filter((configKey) =>
    //@ts-ignore
    configKey.includes(dynamicSetupSection?.configKey),
  );

  const dynamicSections = buildDynamicSections(
    openPanelIndex,
    funnelConfigSteps,
    report,
    dynamicSetupSection,
  );
  const funnelOrder = report?.config?.order;
  const [sections, setSections] =
    useState<ITemplateConfigSetupSection[]>(dynamicSections);

  function onAddStep() {
    addFunnelStep(`${dynamicSetupSection?.configKey}${generateStepId()}`);
  }

  function onReorder(newSections: ITemplateConfigSetupSection[]) {
    const newFunnelOrder = newSections.map(
      (section: ITemplateConfigSetupSection) => section.configKey,
    );
    if (JSON.stringify(newFunnelOrder) === JSON.stringify(funnelOrder)) return;

    updateFunnelOrder(newFunnelOrder);
    setSections(newSections);
  }

  useEffect(() => {
    if (sections.length === funnelConfigSteps.length) return;
    const newDynamicSections = buildDynamicSections(
      openPanelIndex,
      funnelConfigSteps,
      report,
      dynamicSetupSection,
    );
    setSections(newDynamicSections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funnelConfigSteps, report.config]);

  return (
    <Box w="full">
      <Flex direction="column">
        <ReactSortable list={sections} setList={onReorder}>
          {sections.map(
            (section: ITemplateConfigSetupSection, index: number) => {
              return (
                <SetupSectionPanel
                  data-testid="funnel-setup-section"
                  key={section.id}
                  index={index}
                  section={section}
                  requiredSections={setup.requiredSections}
                  setup={setup}
                  setOpenPanelIndex={setOpenPanelIndex}
                  my={2}
                />
              );
            },
          )}
        </ReactSortable>
      </Flex>
      <Flex justifyContent="space-between">
        <Flex />
        <Flex>
          <Button
            data-testid="funnel-setup-sections-add-step-button"
            rightIcon={<AddIcon h={2.5} w={2.5} />}
            onClick={onAddStep}
            mb={4}
            mt={2}
          >
            Add {dynamicSetupSection?.configKey}
          </Button>
        </Flex>
      </Flex>
      {setup?.setupSections
        .filter((s) => s.type !== MULTI_EVENT_TYPE && !s.isDynamic)
        .map((section: ITemplateConfigSetupSection, index) => {
          return (
            <>
              {section.type === AUDIENCE_TYPE && <Divider opacity={1} />}
              <SetupSectionPanel
                key={sections.length}
                index={sections.length}
                section={section}
                requiredSections={setup.requiredSections}
                setup={setup}
                setOpenPanelIndex={() => setOpenPanelIndex(sections.length)}
              />
            </>
          );
        })
        .filter((x) => !!x)}
    </Box>
  );
};
