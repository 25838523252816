import { cx } from "helpers/cx";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { EventIcon } from "core/design-system/components/Icon/Custom/EventIcon";
import {
  DocumentPlusIcon,
  PresentationChartBarIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { ItemContainer } from "./ItemContainer";
import { CurrentSection } from "./index";
import { CollapsedHeader } from "./Header/CollapsedHeader";
import { CollapsedViews } from "./CollapsedViews";
import { useGettingStarted } from "core/hooks/useGettingStarted";
import { CircularProgress } from "@chakra-ui/react";
import { FEATURE_RELEASE } from "core/constants/report-configurations";
import { useNavigate } from "react-router-dom";

export interface ICollapsedSidebarProps {
  currentSection: CurrentSection;
}

export const CollapsedSidebar: React.FC<ICollapsedSidebarProps> = ({
  currentSection,
}) => {
  const openCmdk = () => {
    document.dispatchEvent(
      new KeyboardEvent("keydown", { key: "k", metaKey: true }),
    );
  };
  const { id: appId, isDemoApp } = useCurrentApp();
  const { showGettingStarted, progress } = useGettingStarted();
  const navigate = useNavigate();

  return (
    <div className="ml-[230px] box-content flex h-full min-h-screen w-[47px] flex-col items-center gap-2 bg-gray-50">
      <CollapsedHeader />
      <ItemContainer onClick={openCmdk}>
        <MagnifyingGlassIcon className="h-5 w-5 text-gray-600" />
      </ItemContainer>
      {!isDemoApp && (
        <ItemContainer
          onClick={() => {
            navigate(`/a/${appId}/report/create/${FEATURE_RELEASE}`);
          }}
        >
          <DocumentPlusIcon className="h-5 w-5 text-gray-600" />
        </ItemContainer>
      )}
      {showGettingStarted && (
        <ItemContainer
          isCurrent={currentSection === "Getting started"}
          to={`/a/${appId}/getting-started`}
        >
          <div className="flex h-5 w-5 items-center justify-center">
            <CircularProgress
              thickness="8px"
              value={progress}
              color="primary"
              trackColor="purple.100"
              size="16px"
            />
          </div>
        </ItemContainer>
      )}
      <div className="my-1">
        <CollapsedViews />
      </div>
      <ItemContainer
        isCurrent={currentSection === "Reports"}
        to={`/a/${appId}/reports`}
      >
        <PresentationChartBarIcon
          className={cx(
            "size-5",
            currentSection === "Reports" ? "text-purple-500" : "text-gray-600",
          )}
        />
      </ItemContainer>
      <ItemContainer
        isCurrent={currentSection === "Events"}
        to={`/a/${appId}/events`}
      >
        <EventIcon
          className={cx(
            "size-4",
            currentSection === "Events" ? "text-purple-500" : "text-gray-600",
          )}
        />
      </ItemContainer>
      <ItemContainer
        isCurrent={currentSection === "People"}
        to={`/a/${appId}/people`}
      >
        <UsersIcon
          className={cx(
            "size-5",
            currentSection === "People" ? "text-purple-500" : "text-gray-600",
          )}
        />
      </ItemContainer>
    </div>
  );
};
