import {
  useCreateEventAlertMutation,
  useDeleteEventAlertMutation,
  useGetEventAlertsQuery,
  useUpdateEventAlertMutation,
} from "core/models/alerts";

interface IUseEventAlerts {
  appId: number;
  eventId: number;
}

export const useEventAlerts = ({ appId, eventId }: IUseEventAlerts) => {
  const {
    data: eventAlerts,
    isLoading,
    isUninitialized,
  } = useGetEventAlertsQuery({ appId, eventId });
  const [createEventAlert] = useCreateEventAlertMutation();
  const [updateEventAlert] = useUpdateEventAlertMutation();
  const [deleteEventAlert] = useDeleteEventAlertMutation();

  return {
    eventAlerts,
    isLoading,
    isUninitialized,
    createEventAlert,
    updateEventAlert,
    deleteEventAlert,
  };
};
