export const getCompanyName = ({
  name,
  traits,
}: {
  name?: string;
  traits: { [key: string]: string } | { [key: string]: string }[];
}) => {
  if (name) return name;
  if (Array.isArray(traits)) {
    if (traits[0]?.name) return traits[0].name;
    if (traits[0]?.companyName) return traits[0].companyName;
  } else {
    if (traits?.name) return traits.name;
    if (traits?.companyName) return traits.companyName;
  }
  return undefined;
};
