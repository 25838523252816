import { ITemplateConfig } from "core/types/TemplateConfig";
import { useReportSetup } from "core/hooks/useReportSetup";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Tooltip,
} from "@chakra-ui/react";
import { ChevronUpDownIcon } from "@heroicons/react/16/solid";
import { DocumentIcon } from "@heroicons/react/24/outline";
import {
  Template,
  TEMPLATES,
  TEMPLATE_CATEGORIES,
} from "core/constants/templates";
import { getTemplateConfig, getTemplateImage } from "modules/Templates/helpers";
import { IReport } from "core/types/Report";

export const ReportTypeMenu: React.FC<{
  templateConfig: ITemplateConfig;
  report: IReport;
}> = ({ templateConfig, report }) => {
  const { updateReportType } = useReportSetup();

  return (
    <Menu matchWidth>
      <Tooltip
        hasArrow
        label={
          report.hasPinnedViewInsights
            ? "You cannot change the report type once you have pinned an insight to a view. Please create a duplicate of this report instead."
            : ""
        }
      >
        <MenuButton
          w="full"
          as={Button}
          leftIcon={<DocumentIcon className="h-4" />}
          rightIcon={<ChevronUpDownIcon className="h-4" />}
          textAlign="left"
          isDisabled={report.hasPinnedViewInsights}
        >
          {templateConfig.title}
        </MenuButton>
      </Tooltip>
      <MenuList zIndex="overlay">
        {Object.keys(TEMPLATE_CATEGORIES).map((category) => {
          return (
            <>
              <p className="px-3 pb-2 pt-4 text-xs font-medium text-gray-600">
                {category}
              </p>
              {TEMPLATE_CATEGORIES[
                category as keyof typeof TEMPLATE_CATEGORIES
              ].map((template: Template) => {
                const config = getTemplateConfig(template, []);
                const { title, description } = TEMPLATES[template];

                return (
                  <Popover trigger="hover" placement="left" flip={false}>
                    <PopoverTrigger>
                      <MenuItem
                        onClick={() =>
                          updateReportType({
                            reportType: config.reportType,
                          })
                        }
                      >
                        {template}
                      </MenuItem>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverBody>
                        <div>
                          <img
                            className="h-full w-full rounded-lg"
                            src={`${getTemplateImage(template)}`}
                            alt={title}
                          />
                          <p className="mt-2 font-medium">{title}</p>
                          <p className="text-sm text-gray-600">{description}</p>
                        </div>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                );
              })}
            </>
          );
        })}
      </MenuList>
    </Menu>
  );
};
