import React, { useEffect, useState } from "react";
import moment from "moment";
import { Timerange } from "modules/SlackInsightEditor/Timerange";
import { Frequency } from "modules/SlackInsightEditor/Frequency";
import { isEqual } from "lodash";
import { IInsightSection, ITemplateConfig } from "core/types/TemplateConfig";
import { IReport } from "core/types/Report";
import { IAlert, ICreateAlert, IUpdateAlert } from "core/types/Alert";
import { AppObjectType } from "core/models/appObjects";
import {
  useCreateAlertMutation,
  useUpdateAlertMutation,
} from "core/models/alerts";
import { useToast } from "core/hooks/useToast";
import { useSlack } from "core/hooks/useSlack";
import { useInsightTitle } from "core/hooks/useInsightTitle";
import { useAppObjects } from "core/hooks/useAppObjects";
import CompanyJuneLogoIcon from "core/design-system/components/Icon/Logos/CompanyJuneLogoIcon";
import { SLACK_REDIRECT_URL_KEY } from "core/constants/integrations";
import SlackChannels from "core/components/Slack/SlackChannels";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
} from "@chakra-ui/react";

const STATE = { enabled: 1, disabled: 0 };

interface ISlackInsightEditorDrawerProps {
  config?: ITemplateConfig;
  report?: IReport;
  insight: IInsightSection;
  onClose: () => void;
  isOpen: boolean;
  isLoading: boolean;
  alert?: IAlert;
  appId: string;
  reportId: string;
}

export const SlackInsightEditorDrawer: React.FC<
  ISlackInsightEditorDrawerProps
> = ({
  insight,
  config = {},
  report = {},
  isOpen,
  onClose,
  alert,
  isLoading,
  appId,
  reportId,
}) => {
  const toast = useToast();
  const Section = insight.section;
  const insightId = Number(insight.typeId);
  const { hasSlackAppInstalled } = useSlack();
  const { activeAppObject } = useAppObjects();
  const insightTitle = useInsightTitle({ title: insight.title });
  const [updateAlert] = useUpdateAlertMutation();
  const [createAlert] = useCreateAlertMutation();

  const [state, setState] = useState("SAVE");
  const [hasChanges, setHasChanges] = useState(false);
  const [alertChanges, setAlertChanges] = useState<IAlert>({
    insightId,
    id: 0,
    created: false,
    setup: {},
    alertableId: Number(reportId),
    appId: Number(appId),
    frequency: 0,
    alertableType: "Report",
    state: STATE.enabled,
    enrichmentEnabled: false,
  });

  useEffect(() => {
    if (alert?.created) setAlertChanges(alert);
  }, [alert]);

  useEffect(() => {
    setHasChanges(!isEqual(alert, alertChanges));
  }, [alert, alertChanges]);

  useEffect(() => {
    switch (true) {
      case alert?.created === false:
        return setState("SAVE"); // Alert does not exist
      case alert?.state === STATE.disabled:
        return setState("ENABLE"); // Alert is paused
      case alert?.created === true && hasChanges:
        return setState("UPDATE"); // Alert is created and some values have changed
      case alert?.created === true &&
        alert?.state === STATE.enabled &&
        !hasChanges:
        return setState("PAUSE"); // Alert is created, enabled, and no values have changed
      default:
        return setState("SAVE");
    }
  }, [alert, hasChanges]);

  const onCancel = () => {
    if (alert?.created) {
      setAlertChanges(alert);
    } else {
      onClose();
    }
  };

  const onSave = () => {
    window.analytics.track(
      "slack_insight_alert_editor_save_clicked",
      {
        appId,
        reportId,
        insightSlug: insight.slug,
      },
      { context: { groupId: appId } },
    );
    if (!alertChanges.slackChannelId) {
      return toast({
        title: "Select a Slack channel",
        description:
          "You need to Select a Slack channel to continue to enable the alert",
        status: "error",
      });
    }

    const alertToCreate: ICreateAlert = {
      appId: String(alertChanges.appId),
      reportId: String(alertChanges.alertableId),
      insightId: String(alertChanges.insightId),
      objectType: activeAppObject
        ? activeAppObject.objectType
        : AppObjectType.User,
      frequency: alertChanges.frequency,
      slackChannelId: alertChanges.slackChannelId,
      insightTimerange: alertChanges.insightTimerange,
    };
    createAlert(alertToCreate)
      .unwrap()
      .then(() => {
        toast({
          title: "Alert created",
          description: "You will now receive this alert",
        });
      });
  };

  const onEnable = () => {
    window.analytics.track(
      "slack_insight_alert_editor_set_live_clicked",
      {
        appId,
        reportId,
        insightSlug: insight.slug,
      },
      { context: { groupId: appId } },
    );
    if (!alertChanges.slackChannelId) {
      return toast({
        title: "Select a Slack channel",
        description:
          "You need to Select a Slack channel to continue to enable the alert",
        status: "error",
      });
    } else {
      const alertToUpdate: IUpdateAlert = {
        appId: String(alertChanges.appId),
        reportId: String(alertChanges.alertableId),
        insightId: String(alertChanges.insightId),
        objectType: activeAppObject
          ? activeAppObject.objectType
          : AppObjectType.User,
        frequency: alertChanges.frequency,
        slackChannelId: alertChanges.slackChannelId,
        state: STATE.enabled,
        insightTimerange: alertChanges.insightTimerange,
      };
      updateAlert({ id: Number(alert?.id), alert: alertToUpdate })
        .unwrap()
        .then(() => {
          toast({
            title: "Saved successfully",
            description: "Your slack notification was updated successfully.",
          });
        });
    }
  };

  const onPause = () => {
    window.analytics.track(
      "slack_insight_alert_editor_pause_clicked",
      {
        appId,
        reportId,
        insightSlug: insight.slug,
      },
      { context: { groupId: appId } },
    );

    const alertToUpdate: IUpdateAlert = {
      appId: String(alertChanges.appId),
      reportId: String(alertChanges.alertableId),
      insightId: String(alertChanges.insightId),
      objectType: activeAppObject
        ? activeAppObject.objectType
        : AppObjectType.User,
      frequency: alertChanges.frequency,
      slackChannelId: alertChanges.slackChannelId,
      insightTimerange: alertChanges.insightTimerange,
      state: STATE.disabled,
    };

    updateAlert({ id: Number(alert?.id), alert: alertToUpdate })
      .unwrap()
      .then(() => {
        toast({
          title: "Paused successfully",
          description: "You will no longer receive this alert",
        });
      });
  };

  const onUpdate = () => {
    window.analytics.track(
      "slack_insight_alert_editor_update_clicked",
      {
        appId,
        reportId,
        insightSlug: insight.slug,
      },
      { context: { groupId: appId } },
    );

    const alertToUpdate: IUpdateAlert = {
      appId: String(alertChanges.appId),
      reportId: String(alertChanges.alertableId),
      insightId: String(alertChanges.insightId),
      objectType: activeAppObject
        ? activeAppObject.objectType
        : AppObjectType.User,
      frequency: alertChanges.frequency,
      slackChannelId: alertChanges.slackChannelId,
      insightTimerange: alertChanges.insightTimerange,
    };
    updateAlert({ id: Number(alert?.id), alert: alertToUpdate })
      .unwrap()
      .then(() => {
        toast({
          title: "Saved successfully",
          description: "Your slack notification was updated successfully.",
        });
      });
  };

  function onConnectSlack() {
    localStorage.setItem(
      SLACK_REDIRECT_URL_KEY,
      `/a/${appId}/report/${reportId}?showSlack=true&insight=${insight.slug}`,
    );
    window.location.replace(`${import.meta.env.VITE_API_HOST}/auth/slack`);
  }

  return (
    <Drawer trapFocus={false} size="lg" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader bg="gray.100" mb={4}>
          <Flex
            justifyContent="space-between"
            alignContent="center"
            align="center"
          >
            <Flex>
              <Text fontSize="lg">
                Setup Slack alert for{" "}
                <Text
                  ml={1}
                  as="span"
                  color="purple.500"
                  textTransform="lowercase"
                >
                  {insightTitle}
                </Text>
              </Text>
            </Flex>
            <Flex gridGap={2}>
              <Flex>
                <Button
                  variant="ghost"
                  colorScheme="purple"
                  isDisabled={!hasChanges}
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Flex>
              <Flex>
                {!hasSlackAppInstalled ? (
                  <Button
                    isLoading={isLoading}
                    onClick={onConnectSlack}
                    colorScheme="green"
                  >
                    Connect Slack
                  </Button>
                ) : (
                  <>
                    {state === "SAVE" && (
                      <Button
                        isLoading={isLoading}
                        onClick={onSave}
                        isDisabled={!alertChanges.slackChannelId}
                        colorScheme="green"
                      >
                        Save and set live
                      </Button>
                    )}
                    {state === "ENABLE" && (
                      <Button
                        isLoading={isLoading}
                        onClick={onEnable}
                        isDisabled={!alertChanges.slackChannelId}
                        colorScheme="green"
                      >
                        Set live
                      </Button>
                    )}
                    {state === "UPDATE" && (
                      <Button
                        isLoading={isLoading}
                        onClick={onUpdate}
                        isDisabled={!alertChanges.slackChannelId}
                        colorScheme="green"
                      >
                        Update
                      </Button>
                    )}
                    {state === "PAUSE" && (
                      <Button isLoading={isLoading} onClick={onPause}>
                        Pause
                      </Button>
                    )}
                  </>
                )}
              </Flex>
            </Flex>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <Flex direction="column" gridGap={5}>
            <Flex direction="column">
              <Text mb={0} fontWeight="semibold">
                Preview
              </Text>
              <Text mb={2} fontSize="sm" color="gray.600">
                This is a preview of the graph you will receive in your chosen
                Slack channel
              </Text>
              <Flex bg="purple.100" borderRadius="lg" p={3} direction="column">
                <Flex bg="white" borderRadius="lg" direction="column">
                  <Flex direction="column" px={6} mb={2}>
                    <Flex
                      direction="row"
                      align="center"
                      mt={1}
                      justifyContent="flex-start"
                    >
                      <Flex
                        justifyContent="center"
                        p={1}
                        mr={2}
                        border="1px solid"
                        borderRadius="lg"
                        borderColor="gray.200"
                        mt={3}
                      >
                        <CompanyJuneLogoIcon fontSize={26} />
                      </Flex>
                      <Text fontWeight="semibold">June</Text>
                      <Text
                        ml={2}
                        fontSize="xs"
                        color="gray.600"
                        bg="gray.100"
                        borderRadius="md"
                        textAlign="center"
                        px={1}
                        textTransform="uppercase"
                      >
                        App
                      </Text>
                      <Text ml={2} fontSize="xs" color="gray.600">
                        {moment(Date.now()).format("HH:MM")}
                      </Text>
                    </Flex>
                    <Flex ml={10} pl={1}>
                      <Text fontSize="sm" mt={-3}>
                        Here's your{" "}
                        <Text
                          as="span"
                          fontWeight="semibold"
                          textTransform="lowercase"
                        >
                          {insightTitle}
                        </Text>{" "}
                        insight from your{" "}
                        <Text
                          as="span"
                          fontWeight="semibold"
                          textTransform="lowercase"
                        >
                          {report.name}
                        </Text>{" "}
                        report
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex w="100%">
                    {Section && (
                      <Section
                        report={report}
                        appId={appId}
                        config={config}
                        reportId={report.id}
                        reload={() => {}}
                        openSetup={() => {}}
                        sharingMode={false}
                        reloadGraph // This is necessary as the whole report component will re-render
                        previewMode
                        screenshotMode
                        showYAxis
                      />
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Flex direction="column">
              <Text mb={0} fontWeight="semibold">
                Slack channel
              </Text>
              <Text mb={2} fontSize="sm" color="gray.600">
                The channel you wish to receive the graph in
              </Text>
              {!hasSlackAppInstalled ? (
                <Box>
                  <Button
                    onClick={onConnectSlack}
                    variant="ghost"
                    colorScheme="purple"
                  >
                    Connect Slack to select a channel
                  </Button>
                </Box>
              ) : (
                <SlackChannels
                  showLabel={false}
                  defaultValue={
                    alertChanges && alertChanges.slackChannelId
                      ? alertChanges.slackChannelId
                      : ""
                  }
                  onChange={(ch) =>
                    setAlertChanges({ ...alertChanges, slackChannelId: ch })
                  }
                />
              )}
            </Flex>
            <Frequency
              alertChanges={alertChanges}
              setAlertChanges={setAlertChanges}
            />
            {insight.timerangeOptions && (
              <Timerange
                timerangeOptions={insight.timerangeOptions}
                alertChanges={alertChanges}
                setAlertChanges={setAlertChanges}
              />
            )}
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
