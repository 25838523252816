import { ITrait } from "core/types/Trait";
import { IContact } from "core/types/Contact";
import { lowerCase, startCase } from "lodash";

export interface IGetContactNameProps extends IContact {
  id: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  username?: string;
  email?: string | undefined | null;
  traits: ITrait | ITrait[];
}

export interface IGetGroupNameProps {
  id: string;
  name?: string;
}

const INVALID_TRAIT_VALUES = ["null", "", " "];

const valid = (str?: string) =>
  str && !INVALID_TRAIT_VALUES.includes(str) && str;

const nameFromTraitsArray = (traits: ITrait[]) => {
  // When trait is an array of name & value pairs [{ name: 'firstName', value: 'Gladys' }, ...]
  const firstName = traits.find(
    (trait) => lowerCase(trait.name) === "name",
  )?.value;
  const lastName = traits.find(
    (trait) => lowerCase(trait.name) === "lastname",
  )?.value;
  if (valid(firstName) && valid(lastName))
    return startCase(`${firstName} ${lastName}`);

  const name = traits.find((trait) => lowerCase(trait.name) === "name")?.value;
  if (valid(name)) return startCase(name);

  const username = traits.find(
    (trait) => lowerCase(trait.name) === "username",
  )?.value;
  if (valid(username)) return username;

  return "Unknown";
};

const nameFromTraits = (traits: ITrait) => {
  // When trait is an object { firstName: 'Gladys', ... }
  if (valid(traits.firstName) && valid(traits.lastName))
    return startCase(`${traits.firstName} ${traits.lastName}`);
  if (valid(traits.name)) return startCase(traits.name);
  if (valid(traits.username)) return traits.username;
  if (valid(traits["USERNAME"])) return traits["USERNAME"];

  return "Unknown";
};

export const getContactName = (contact?: IGetContactNameProps) => {
  if (!contact) return "Unknown";

  // Check if contact has first and last name
  if (valid(contact.firstName) && valid(contact.lastName)) {
    return startCase(`${contact.firstName} ${contact.lastName}`);
  } else if (contact.id === "") {
    // Contact is anonymous
    return "Anonymous";
  } else if (contact.traits) {
    const traitsIsArray = Array.isArray(contact.traits);
    return traitsIsArray
      ? nameFromTraitsArray(contact.traits as ITrait[])
      : nameFromTraits(contact.traits);
  }

  return "Unknown";
};

export const getGroupName = (group: IGetGroupNameProps): string => {
  if (valid(group.name) && group.name) {
    return group.name;
  }

  return "Unknown";
};
