import { RootState } from "core/initializers/store";

/**
 * Select the report object.
 */
export const selectReport = (state: RootState): any => state.report.data;

/**
 * Select the report's loading status.
 */
export const selectReportLoading = (state: RootState): boolean =>
  state.report.isLoading;

/**
 * Select the report's error object.
 */
export const selectReportError = (state: RootState): any =>
  state.report.errors.fetching;

export const selector = (state: RootState) => state.report;
