import React, { useEffect } from "react";
import { ITemplateConfigSetupSection } from "core/types/TemplateConfig";
import { IOption } from "core/types/Option";
import { useReportSetup } from "core/hooks/useReportSetup";
import Icon from "core/design-system/components/Icon";
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";

interface IEnumPickerProps {
  section: ITemplateConfigSetupSection;
}

const EnumPicker: React.FC<IEnumPickerProps> = ({ section }) => {
  const { currentReport, updateEnum } = useReportSetup();
  const { options } = section;

  const selectedOption =
    currentReport?.config &&
    currentReport?.config[section.configKey] &&
    currentReport?.config[section.configKey][0];

  const onSelectOption = (option: IOption) => {
    updateEnum({ option, configKey: section.configKey });
  };

  useEffect(() => {
    if (!selectedOption) {
      const option = options?.find((o) => o.default);
      updateEnum({ option, configKey: section.configKey });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, selectedOption);

  return (
    <Box>
      <Menu>
        <MenuButton
          bg="gray.100"
          rightIcon={<Icon iconType="icon" name="chevron-down" h={4} w={4} />}
          as={Button}
        >
          {selectedOption && selectedOption.name}
        </MenuButton>
        <MenuList>
          {options?.map((_option, index) => (
            <MenuItem key={index} onClick={() => onSelectOption(_option)}>
              <Text fontSize="sm">{_option.name}</Text>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default EnumPicker;
