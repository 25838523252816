import { Box, Text } from "@chakra-ui/react";

export const CustomTooltip: React.FC<{
  xDataKey?: string;
  yDataKey?: string;
  label?: string;
  payload?: { payload: { [key: string]: string } }[];
  active?: boolean;
}> = ({ label, payload, active, xDataKey, yDataKey }) => {
  if (active && payload && payload.length && yDataKey && xDataKey) {
    return (
      <Box bg="gray.700" px={3} py={2} borderRadius={5}>
        <Text textTransform="capitalize" fontSize="xs" color="gray.400">
          {xDataKey.split(`_`).join(` `)}{" "}
          <Text as="span" fontWeight="semibold">
            {" "}
            {label}{" "}
          </Text>
        </Text>
        <Text textTransform="capitalize" fontSize="sm" color="white">
          {yDataKey.split(`_`).join(` `)}{" "}
          <Text as="span" fontWeight="semibold">
            {payload[0].payload[yDataKey]}
          </Text>
        </Text>
      </Box>
    );
  }
  return null;
};
