import React, { useState } from "react";
import axios from "core/initializers/axios";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

export const AddTokenModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const { id: appId } = useCurrentApp();
  const [segmentToken, setSegmentToken] = useState("");
  const [segmentDestinationURL, setSegmentDestinationURL] = useState("");
  const [isSavingToken, setSavingToken] = useState(false);
  const [tokenError, setTokenError] = useState<string | null>(null);

  const saveToken = async () => {
    const params = {
      appId,
      token: segmentToken,
      destinationUrl: segmentDestinationURL,
    };
    setSavingToken(true);
    try {
      await axios.post(
        `${import.meta.env.VITE_API_HOST}/segment_tokens`,
        params,
        {
          withCredentials: true,
        },
      );
      onClose();
    } catch (e) {
      setSavingToken(false);
      setTokenError("Token is invalid.");
    }
  };

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex>
            <Text fontSize="lg" fontWeight="medium">
              Add your Segment token
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={4} fontSize="sm" color="gray.600">
            Giving us your Segment access token allows us to seamlessly connect
            with your Segment workspace in order for the June destination to be
            completely integrated.
          </Text>
          <Box mb={3} p={4} bg="gray.50" borderRadius={5}>
            <Text color="gray.600" fontSize="sm">
              To generate a new Segment token, go to your{" "}
              <Text display="inline-block" color="gray.900" as="span">
                Workspace Settings {">"} Access Management {">"} Tokens
              </Text>{" "}
              in Segment and click on{" "}
              <Text display="inline-block" color="gray.900">
                Create token
              </Text>
              . Once the token has been generated, copy and paste the value in
              the input below. You cannot retrieve the plain text token later.
            </Text>
          </Box>
          <Box mb={3}>
            <Text pb={1} fontSize="xs" color="gray.700" fontWeight="semibold">
              Segment access token
            </Text>
            <Input
              onChange={(e) => setSegmentToken(e.currentTarget.value)}
              focusBorderColor="gray"
              defaultValue={segmentToken}
              isInvalid={!!tokenError}
              placeholder="Segment token"
              fontSize="sm"
            />
            <Text pt={1} fontSize="xs" color="gray.600">
              Your token will be encrypted and saved securely.
            </Text>
          </Box>
          <Box mb={3}>
            <Text pb={1} fontSize="xs" color="gray.700" fontWeight="semibold">
              Segment destination URL
            </Text>
            <Input
              onChange={(e) => setSegmentDestinationURL(e.currentTarget.value)}
              focusBorderColor="gray"
              defaultValue={segmentDestinationURL}
              isInvalid={!!tokenError}
              placeholder="https://app.segment.com/<your-workspace-name>/destinations/june/sources/<your-source-name>/instances/<instance-id>/configuration"
              fontSize="sm"
            />
          </Box>
        </ModalBody>

        <ModalFooter>
          <Text
            cursor="pointer"
            color="purple.500"
            mr={3}
            fontSize="sm"
            as="a"
            target="_blank"
            href="https://help.june.so/en/articles/5095644-connect-segment-to-june-with-a-token"
          >
            Help article
          </Text>
          <Button
            isLoading={isSavingToken}
            onClick={saveToken}
            colorScheme="purple"
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
