import { cx } from "helpers/cx";
import { ITraitWithConfig } from "core/types/Trait";
import { TwEditable } from "core/design-system/components/TwEditable";
import { Tooltip } from "@chakra-ui/react";

export const Title: React.FC<{
  trait?: ITraitWithConfig;
  onChangeTitle: (val: string) => void;
  onChangeDescription: (val: string) => void;
  compact?: boolean;
}> = ({ trait, onChangeTitle, onChangeDescription, compact }) => {
  if (compact) {
    return (
      <div className="flex flex-col">
        <p className="text-lg font-medium">{trait?.name || "New trait"}</p>
        <Tooltip label={trait?.description} hasArrow>
          <p className="max-w-[220px] truncate text-sm text-gray-500">
            {trait?.description}
          </p>
        </Tooltip>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2">
      <div>
        {trait?.isComputed ? (
          <Tooltip
            label="The name of a computed trait cannot be modified once created."
            hasArrow
          >
            <p className="text-xl font-medium">{trait?.name || "New trait"}</p>
          </Tooltip>
        ) : (
          <Tooltip shouldWrapChildren>
            <TwEditable
              defaultValue={trait?.name || "New trait"}
              onSubmit={(val: string) => onChangeTitle(val)}
              placeholder={"Untitled "}
              fontWidth={14}
              className={cx("text-xl font-medium text-black")}
              inputClassName={cx("text-xl font-medium border-gray-300")}
            />
          </Tooltip>
        )}
      </div>
      <div>
        <TwEditable
          defaultValue={trait?.description || "Add a description..."}
          placeholder="Add a description..."
          onSubmit={(val: string) => onChangeDescription(val)}
          className="text-black"
          inputClassName="border-gray-300"
        />
      </div>
    </div>
  );
};
