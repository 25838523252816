import { Navigate, Route, Routes, useMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { NewReportModal } from "modules/Templates/NewReportModal";
import { NotMigratedModal } from "modules/NotMigratedModal";
import { AnimatePresence } from "framer-motion";
import { IUser } from "core/types/User";
import { IApp, OnboardingState } from "core/types/App";
import { useGetAppObjectsQuery } from "core/models/appObjects";
import {
  fetchApp,
  selector as appSelector,
  setCurrentApp,
} from "core/models/app";
import { useMauThreshold } from "core/hooks/useMauThreshold";
import { LOCAL_STORAGE_DEMO_APP_KEY } from "core/hooks/useDemo";
import { useAppObjects } from "core/hooks/useAppObjects";
import { CommandMenu } from "core/design-system/components/CommandMenu";
import { DEMO_APP_ID } from "core/constants/appIds";
import { Paywall } from "core/components/Paywall/Paywall";
import Loader from "Components/Loader";
import routes from "./routes";
import Navbar from "./modules/Navigation/Navbar";
import { DemoBanner } from "./DemoBanner";
import { AppProvider } from "./Context/App";
import Toasts from "./Components/Toasts";
import { SubscriptionStartedModal } from "./Components/SubscriptionStartedModal";
import ErrorBoundary from "./Components/ErrorBoundary";

const NON_PAYWALLED_ROUTES = [
  "settings",
  "general",
  "team",
  "labels",
  "billing",
  "computed-traits",
  "june-sdk",
  "segment",
  "attio",
  "hubspot",
  "slack",
  "amplitude",
  "mixpanel",
  "rudderstack",
  "freshpaint",
  "team",
  "billing",
  "getting-started",
  "debugger",
  "reports",
];

function AuthenticatedApp({ user }: { user: IUser }) {
  const [demoModeEnabled, setDemoEnabled] = useState(
    localStorage.getItem(LOCAL_STORAGE_DEMO_APP_KEY) === "true",
  );

  const match = useMatch({
    path: "/a/:appId",
    end: false,
    caseSensitive: true,
  });

  const { pathname } = window.location;
  const route = pathname.substring(pathname.lastIndexOf("/") + 1);

  const { isAboveLimit } = useMauThreshold();
  const dispatch = useDispatch();
  const { data: currentApp, isLoading } = useSelector(appSelector);
  const appId = demoModeEnabled ? DEMO_APP_ID : user.lastUsedApp.id;
  const { setAppObjects } = useAppObjects();
  const { data } = useGetAppObjectsQuery({
    appId: Number(currentApp?.id),
  });

  const setApp = (app: IApp) => {
    dispatch(setCurrentApp({ app }));
  };

  useEffect(() => {
    dispatch(fetchApp({ appId }));
    document.title = `June - ${currentApp?.name || "Home"}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, appId]);

  useEffect(() => {
    if (data) setAppObjects(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (match) {
      const { appId: urlAppId } = match?.params as { appId: string };
      const isDemoApp = Number(urlAppId) === DEMO_APP_ID;
      localStorage.setItem(LOCAL_STORAGE_DEMO_APP_KEY, String(isDemoApp));
      setDemoEnabled(isDemoApp);
    }
  }, [match]);

  if (isLoading || !currentApp) {
    return <Loader />;
  }

  const restrictAppAccess =
    !currentApp?.isPaying &&
    !currentApp?.isTrialing &&
    !currentApp?.isPaymentOverdue &&
    isAboveLimit;

  const defaultRoutePath = `/a/${user.lastUsedApp.id}/home`;
  const shouldShowRoutes = restrictAppAccess
    ? NON_PAYWALLED_ROUTES.includes(route)
    : true;

  return (
    <ErrorBoundary>
      <SubscriptionStartedModal />
      <AppProvider currentApp={currentApp} setApp={setApp}>
        {demoModeEnabled && <DemoBanner user={user} />}
        <CommandMenu />
        <NotMigratedModal />
        <Toasts />
        <NewReportModal />
        <Navbar />
        <AnimatePresence mode="wait">
          {shouldShowRoutes && (
            <Routes>
              {[...routes.indifferentRoutes, ...routes.authenticatedRoutes].map(
                (
                  { path, component: Component, subRoutes, redirectTo },
                  index,
                ) => {
                  if (redirectTo) {
                    return (
                      <Route
                        key={index}
                        path={path}
                        element={<Navigate to={redirectTo} />}
                      />
                    );
                  }

                  if (subRoutes) {
                    return (
                      <Route
                        key={index}
                        path={path}
                        element={<Component app={currentApp} user={user} />}
                      >
                        {subRoutes.map(
                          (
                            { path: subPath, component: SubComponent, isIndex },
                            subIndex,
                          ) => {
                            if (isIndex) {
                              return (
                                <Route
                                  key={`${index}-${subIndex}`}
                                  index
                                  element={
                                    <SubComponent
                                      app={currentApp}
                                      user={user}
                                    />
                                  }
                                />
                              );
                            }
                            return (
                              <Route
                                key={`${index}-${subIndex}`}
                                path={subPath}
                                element={
                                  <SubComponent app={currentApp} user={user} />
                                }
                              />
                            );
                          },
                        )}
                      </Route>
                    );
                  }
                  return (
                    <Route
                      key={index}
                      path={path}
                      element={<Component app={currentApp} user={user} />}
                    />
                  );
                },
              )}
              <Route
                path="/"
                element={<Navigate to={defaultRoutePath} />}
              ></Route>
              <Route
                path="/login"
                element={
                  <>
                    user.lastUsedApp.onboardingState ===
                    OnboardingState.ONBOARDING ? (
                    <Navigate to={`/a/${user.lastUsedApp.id}/onboarding`} />
                    ) : (
                    <Navigate to={defaultRoutePath} />
                    );
                  </>
                }
              ></Route>
              <Route
                path="/signup"
                element={
                  <>
                    user.lastUsedApp.onboardingState ===
                    OnboardingState.ONBOARDING ? (
                    <Navigate to={`/a/${user.lastUsedApp.id}/onboarding`} />
                    ) : (
                    <Navigate to={defaultRoutePath} />)
                  </>
                }
              ></Route>
              <Route
                path="/start"
                element={
                  <>
                    user.lastUsedApp.onboardingState ===
                    OnboardingState.ONBOARDING ? (
                    <Navigate to={`/a/${user.lastUsedApp.id}/onboarding`} />
                    ) : (
                    <Navigate to={defaultRoutePath} />)
                  </>
                }
              ></Route>
              {/* This route is being triggered by june.so/templates but report type is no longer used in onboarding */}
              <Route
                path="/start/:reportType"
                element={
                  <>
                    user.lastUsedApp.onboardingState ===
                    OnboardingState.ONBOARDING ? (
                    <Navigate to={`/a/${user.lastUsedApp.id}/onboarding`} />
                    ) : (
                    <Navigate to={defaultRoutePath} />)
                  </>
                }
              ></Route>
              <Route
                path="*"
                element={
                  <>
                    {user.lastUsedApp.onboardingState ===
                    OnboardingState.ONBOARDING ? (
                      <Navigate to={`/a/${user.lastUsedApp.id}/onboarding`} />
                    ) : (
                      <Navigate to={defaultRoutePath} />
                    )}
                    ;
                  </>
                }
              ></Route>
            </Routes>
          )}
          <Paywall />
        </AnimatePresence>
      </AppProvider>
    </ErrorBoundary>
  );
}

export default AuthenticatedApp;
