import { Avatar } from "@chakra-ui/react";
import { IFavourite } from "core/models/favourites";

export const CompanyItem: React.FC<{ favourite: IFavourite }> = ({
  favourite,
}) => {
  return (
    <div className="flex flex-row items-center gap-2">
      <Avatar size="2xs" name={favourite.objectId} />
      <p className="text-[13px]">{favourite.objectId}</p>
    </div>
  );
};
