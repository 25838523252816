import { createIcon } from "@chakra-ui/icons";

const GoogleIcon = createIcon({
  viewBox: "75.5 0.5 479 479",
  path: (
    <svg xmlns="http://www.w3.org/2000/svg">
      <clipPath id="a">
        <path d="m490.7 206.5h-20.9-68.6-82v71.1h98.7c-9.2 45.2-47.7 71.1-98.7 71.1-60.2 0-108.7-48.5-108.7-108.7s48.5-108.7 108.7-108.7c25.9 0 49.4 9.2 67.8 24.3l53.5-53.5c-32.6-28.6-74.5-46.1-121.3-46.1-102.1 0-184 82-184 184s82 184 184 184c92 0 175.7-66.9 175.7-184-.1-10.9-1.7-22.6-4.2-33.5z" />
      </clipPath>
      <clipPath id="b">
        <path d="m490.7 206.5h-20.9-68.6-82v71.1h98.7c-9.2 45.2-47.7 71.1-98.7 71.1-60.2 0-108.7-48.5-108.7-108.7s48.5-108.7 108.7-108.7c25.9 0 49.4 9.2 67.8 24.3l53.5-53.5c-32.6-28.6-74.5-46.1-121.3-46.1-102.1 0-184 82-184 184s82 184 184 184c92 0 175.7-66.9 175.7-184-.1-10.9-1.7-22.6-4.2-33.5z" />
      </clipPath>
      <clipPath id="c">
        <path d="m490.7 206.5h-20.9-68.6-82v71.1h98.7c-9.2 45.2-47.7 71.1-98.7 71.1-60.2 0-108.7-48.5-108.7-108.7s48.5-108.7 108.7-108.7c25.9 0 49.4 9.2 67.8 24.3l53.5-53.5c-32.6-28.6-74.5-46.1-121.3-46.1-102.1 0-184 82-184 184s82 184 184 184c92 0 175.7-66.9 175.7-184-.1-10.9-1.7-22.6-4.2-33.5z" />
      </clipPath>
      <clipPath id="d">
        <path d="m490.7 206.5h-20.9-68.6-82v71.1h98.7c-9.2 45.2-47.7 71.1-98.7 71.1-60.2 0-108.7-48.5-108.7-108.7s48.5-108.7 108.7-108.7c25.9 0 49.4 9.2 67.8 24.3l53.5-53.5c-32.6-28.6-74.5-46.1-121.3-46.1-102.1 0-184 82-184 184s82 184 184 184c92 0 175.7-66.9 175.7-184-.1-10.9-1.7-22.6-4.2-33.5z" />
      </clipPath>
      <path
        clipPath="url(#a)"
        d="m118.4 348.7v-217.4l142.2 108.7z"
        fill="#fbbc05"
      />
      <path
        clipPath="url(#b)"
        d="m118.4 131.3 142.2 108.7 58.6-51 200.7-32.6v-117.2h-401.5z"
        fill="#ea4335"
      />
      <path
        clipPath="url(#c)"
        d="m118.4 348.7 251-192.3 66.1 8.3 84.4-125.5v401.6h-401.5z"
        fill="#34a853"
      />
      <path
        clipPath="url(#d)"
        d="m519.9 440.8-259.3-200.8-33.4-25.1 292.7-83.6z"
        fill="#4285f4"
      />
    </svg>
  ),
});

export default GoogleIcon;
