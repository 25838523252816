import { IGettingStartedPanel } from "routes/GettingStarted";
import ScriptGenerator from "modules/Sdk/ScriptGenerator";
import useSdkToken from "core/hooks/useSdkToken";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { Flex, Link, Text } from "@chakra-ui/react";
import { SDKs } from "modules/Onboarding/SetupSources/SDKs";

export const ConnectSdk: React.FC<IGettingStartedPanel> = ({
  dataSource,
  sdk,
  setSdk,
}) => {
  const { id: appId } = useCurrentApp();
  const { token } = useSdkToken(appId);

  return (
    <Flex direction="column" gap={4}>
      <Text fontSize="sm">
        Copy and paste this small snippet of code to install June into your app.
        Select your language and add this to your code or{" "}
        <Link
          color="purple.500"
          href="https://www.june.so/docs/quickstart/install"
          isExternal
        >
          see our example.
        </Link>
      </Text>

      <ScriptGenerator
        p={0}
        m={0}
        sdks={SDKs}
        sdk={sdk}
        onSelectSdk={setSdk}
        codeKey="installScript"
        token={token}
        language={sdk.installLanguage}
        theme="light"
        showHeader={true}
      />
      <ScriptGenerator
        p={0}
        m={0}
        sdks={SDKs}
        sdk={sdk}
        onSelectSdk={setSdk}
        codeKey="importScript"
        token={token}
        language={sdk.language}
        theme="light"
        showHeader={true}
      />
      <ScriptGenerator
        p={0}
        m={0}
        sdks={SDKs}
        sdk={sdk}
        onSelectSdk={setSdk}
        codeKey="useScript"
        token={token}
        language={sdk.language}
        theme="light"
        showHeader={true}
      />
      <ScriptGenerator
        p={0}
        m={0}
        sdks={SDKs}
        sdk={sdk}
        onSelectSdk={setSdk}
        codeKey="useHook"
        token={token}
        language={sdk.language}
        theme="light"
        showHeader={true}
      />
    </Flex>
  );
};
