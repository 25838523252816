import { useParams } from "react-router-dom";
import { useState } from "react";
import { cx } from "helpers/cx";
import { useToast } from "core/hooks/useToast";
import { useCompany } from "core/hooks/useCompany";
import {
  ArrowSmallRightIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { SearchCompanyAction } from "./SearchCompanyAction";

interface IMergeCompanyModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const MergeCompanyModal = ({
  isOpen,
  onClose,
}: IMergeCompanyModalProps) => {
  const toast = useToast();
  const { appId, groupId: encodedGroupId } = useParams<{
    appId: string;
    groupId: string;
  }>() as {
    appId: string;
    groupId: string;
  };
  const groupId = decodeURIComponent(encodedGroupId);
  const [sourceGroupId, setSourceGroupId] = useState<string | undefined>(
    undefined,
  );
  const { mergeCompany } = useCompany({
    appId: Number(appId),
    id: groupId,
  });

  function onMerge() {
    if (!appId || !groupId || !sourceGroupId) return;
    mergeCompany({
      appId: Number(appId),
      id: groupId,
      sourceGroupId: sourceGroupId,
    });
  }

  return (
    <Modal
      size="lg"
      closeOnEsc
      closeOnOverlayClick
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent maxW="800px">
        <ModalHeader>Merge {groupId}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="flex items-center rounded-xl border border-gray-100 bg-gray-100 p-2">
            <InformationCircleIcon className="mr-2 size-5 text-gray-500" />
            <div className="text-sm text-gray-500">
              Our system intelligently combines your data. Note that{" "}
              <div className="inline font-medium">{groupId}</div> holds higher
              priority.
            </div>
          </div>
          <div className={cx("mt-4 flex items-center justify-between")}>
            <div className={cx("flex w-full items-center justify-between")}>
              <div
                className={cx(
                  "flex h-32 w-5/12 items-center justify-center rounded-xl border p-10",
                )}
              >
                {sourceGroupId ? (
                  <div className="truncate text-ellipsis font-medium">
                    {sourceGroupId}
                  </div>
                ) : (
                  <SearchCompanyAction
                    targetGroupId={groupId}
                    setSourceGroupId={setSourceGroupId}
                  />
                )}
              </div>
              <ArrowSmallRightIcon className="size-5 text-gray-400" />
              <div
                className={cx(
                  "flex h-32 w-5/12 items-center justify-center rounded-xl border p-10",
                )}
              >
                <div className="truncate text-ellipsis font-medium">
                  {groupId}
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="flex w-full items-center justify-between">
            {sourceGroupId ? (
              <div className="flex items-center p-2">
                <ExclamationTriangleIcon className="mr-2 size-5 text-yellow-500" />
                <div className="text-sm text-gray-500">
                  This action can't be undone.
                </div>
              </div>
            ) : (
              <div></div>
            )}
            <div className="flex">
              <Button
                mr={3}
                onClick={() => {
                  setSourceGroupId(undefined);
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                colorScheme="purple"
                onClick={() => {
                  onMerge();
                  toast({
                    title: "Merged successfully",
                    description:
                      "It might take some minutes for the merge to finish.",
                  });
                  onClose();
                }}
                isDisabled={!sourceGroupId}
              >
                Merge companies
              </Button>
            </div>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
