import React from "react";
import { ITraitKeyResponse } from "core/models/traits";
import {
  ICreateDataMappingParams,
  Integration,
  integrationNames,
  IProperty,
} from "core/models/dataMappings";
import { IAppObject } from "core/models/appObjects";
import { IDataMapping, ITempDataMapping } from "core/hooks/useDataMappings";
import {
  IDeleteMappingParams,
  IUpdateMappingParams,
} from "core/hooks/useDataMapping";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Divider, Text, Tooltip } from "@chakra-ui/react";
import { MappingRow } from "./MappingRow";

interface IMappingTableProps {
  traits: ITraitKeyResponse[];
  tempMappings: ITempDataMapping[];
  isLoading: boolean;
  currentProperties: IProperty[];
  properties: IProperty[];
  page: number;
  search: string;
  hasMore: boolean;
  setSearch: (query: string) => void;
  setPage: (page: number) => void;
  dataMappings: IDataMapping[];
  deleteTempMapping: (juneTrait: string) => void;
  createMapping: (params: ICreateDataMappingParams) => void;
  updateMapping: (params: IUpdateMappingParams) => void;
  deleteMapping: (params: IDeleteMappingParams) => void;
  setShowContactDataWarning?: (val: boolean) => void;
  integration: Integration;
  appObject: IAppObject;
}

export const MappingTable: React.FC<IMappingTableProps> = ({
  isLoading,
  tempMappings,
  currentProperties,
  properties,
  page,
  search,
  hasMore,
  setSearch,
  setPage,
  dataMappings,
  deleteTempMapping,
  integration,
  appObject,
}) => {
  const singularExtTraitWord =
    integration === Integration.Hubspot ? "property" : "attribute";
  const pluralExtTraitWord =
    integration === Integration.Hubspot ? "properties" : "attributes";
  const integrationName = integrationNames[integration];

  return (
    <>
      <div>
        <p className="text-sm">
          Which June {appObject.pluralName?.toLowerCase()} traits should we sync
          to <p className="inline-block capitalize">{integrationName}</p>{" "}
          {appObject.pluralName?.toLowerCase()}?
        </p>
        <p className="my-2 text-sm text-gray-500">
          These are the June {appObject.pluralName?.toLowerCase()} traits which
          we will sync to{" "}
          <p className="inline-block capitalize">{integrationName}</p>
          {` `}
          {appObject.pluralName?.toLowerCase()}
        </p>
      </div>
      <div className="mt-6 flex w-full flex-row items-center justify-between">
        <div>
          <p className=" text-sm  text-gray-500">June traits</p>
        </div>
        <div></div>
        <div>
          <div className="flex w-full flex-row items-center justify-end gap-1">
            <p className=" text-sm capitalize text-gray-500">
              {integrationName}{" "}
              <Text as="span" textTransform="lowercase">
                {pluralExtTraitWord}
              </Text>
            </p>
            <Tooltip
              hasArrow
              label={`Don't see a ${integrationName} ${singularExtTraitWord}? Create one in ${integrationName}`}
              placement="top"
            >
              <InformationCircleIcon className="h-4 text-gray-500" />
            </Tooltip>
          </div>
        </div>
      </div>
      <Divider my={3} />
      <div className="flex flex-col gap-4">
        {[...dataMappings, ...tempMappings].map((mapping, index) => {
          return (
            <MappingRow
              key={JSON.stringify(mapping) + index}
              dataMapping={mapping}
              integration={integration}
              currentProperties={currentProperties}
              properties={properties}
              deleteTempMapping={deleteTempMapping}
              page={page}
              search={search}
              setSearch={setSearch}
              setPage={setPage}
              hasMore={hasMore}
              dataMappings={dataMappings}
              appObject={appObject}
            />
          );
        })}
      </div>
    </>
  );
};
