import { ReferenceLine } from "recharts";
import { useState } from "react";
import { Size } from "core/types/ViewInsight";
import { ExplorationLegend } from "core/modules/reports/report-types/Explorer/ExplorationLegend";
import { getSerieColors } from "core/modules/reports/report-types/Explorer/ExplorationContainer";
import templateColors from "core/design-system/constants/theme/colors";
import TickText from "core/design-system/charts/TickText";
import {
  Scatter,
  ScatterCell,
  ScatterChart,
  ScatterTooltip,
  ScatterXAxis,
  ScatterYAxis,
} from "core/design-system/charts/Scatter";

interface ISimpleScatterPlotProps {
  data: any[];
  size?: Size;
  ticks?: any[];
  tooltipProps?: any;
  TooltipComponent?: React.FC<any>;
  xDataKey?: string;
  yDataKey?: string;
  margin?: { right: number; top: number; bottom: number; left: number };
}

export const SimpleScatterPlot: React.FC<ISimpleScatterPlotProps> = ({
  data,
  size,
}) => {
  const [hoveringIndex, setHoveringIndex] = useState<string | undefined>(
    undefined,
  );

  const serieColors = getSerieColors(data);
  const series = data?.map((d, index) => ({
    ...d,
    color: serieColors[d.index],
  }));

  return (
    <div className="flex w-full flex-col">
      <ScatterChart
        margin={{
          top: 15,
          right: 15,
          bottom: 0,
          left: 0,
        }}
      >
        {/* @ts-ignore */}
        <ScatterXAxis
          tick={({
            y,
            ...props
          }: {
            y: number;
            payload: { value: number };
          }) => (
            <TickText y={y + 10} {...props}>
              {props.payload.value}%
            </TickText>
          )}
          dataKey="popularity"
          name="popularity"
          ticks={[0, 100]}
        />
        {/* @ts-ignore */}
        <ScatterYAxis
          dataKey="frequency"
          name="frequency"
          ticks={[0, 100]}
          tick={({
            x,
            y,
            ...props
          }: {
            x: number;
            y: number;
            payload: { value: number };
          }) => (
            <TickText x={x - 5} y={y + 5} {...props}>
              {props.payload.value}%
            </TickText>
          )}
        />
        <ScatterTooltip />
        <ReferenceLine x="50" stroke={templateColors.gray[300]} />
        <ReferenceLine y={50} stroke={templateColors.gray[300]} />
        <Scatter data={series}>
          {series?.map((serie, index) => (
            // @ts-ignore
            <ScatterCell
              key={`cell-${index}`}
              fill={serie.color}
              fillOpacity={
                hoveringIndex !== undefined
                  ? String(serie.uuid) === String(hoveringIndex)
                    ? 1
                    : 0.2
                  : 1
              }
            />
          ))}
        </Scatter>
      </ScatterChart>
      <div className="max-w-full overflow-x-scroll">
        <ExplorationLegend
          series={series}
          size={size || Size.Small}
          onHover={setHoveringIndex}
        />
      </div>
    </div>
  );
};
