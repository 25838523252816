import { motion } from "framer-motion";
import { useNavigation } from "core/hooks/useNavigation";
import { ExpandedSidebar } from "./ExpandedSidebar";
import { CollapsedSidebar } from "./CollapsedSidebar";
import { cx } from "helpers/cx";

export type CurrentSection =
  | "Discover"
  | "Home"
  | "New report"
  | "Reports"
  | "Events"
  | "Templates"
  | "Feed"
  | "Settings"
  | "People"
  | "Getting started"
  | "AIExplorer";

export interface ISidebarProps {
  currentSection: CurrentSection;
}

export const Sidebar: React.FC<ISidebarProps> = ({ currentSection }) => {
  const { navigation } = useNavigation();
  const { isOpen } = navigation;

  return (
    <div>
      <motion.nav
        initial={{ x: isOpen ? "50px" : "-230px" }}
        animate={{
          x: isOpen ? "0" : "-230px",
        }}
        exit={{
          x: isOpen ? 0 : "-230px",
        }}
        transition={{ type: "spring", bounce: 0, duration: 0.4 }}
        className={cx(isOpen ? "h-full overflow-scroll" : "")}
      >
        {isOpen ? (
          <ExpandedSidebar currentSection={currentSection} />
        ) : (
          <CollapsedSidebar currentSection={currentSection} />
        )}
      </motion.nav>
    </div>
  );
};
