import React from "react";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
import * as Integration from "../../../modules/Settings/Integrations/Integration";
import { ImportForm } from "../../../modules/Settings/Imports/Amplitude/ImportForm";
import { IntegrationMetadata } from "../../../modules/Settings/Integrations/Integration";

const CONTENT: IntegrationMetadata = {
  title: "Amplitude",
  description: "Import your existing data to June",
  logo: "companyAmplitudeLogo",
  docsUrl:
    "https://help.june.so/en/articles/5649446-how-to-import-your-amplitude-data-to-june",
};

const Amplitude: React.FC = () => {
  return (
    <>
      <Integration.Container isExpanded>
        <Integration.Header metadata={CONTENT} />
        <Integration.Body>
          <Integration.Section>
            <Integration.Collapsable
              title={"Import your data"}
              headerIcon={<ArrowRightOnRectangleIcon width={20} height={20} />}
            >
              <ImportForm />
            </Integration.Collapsable>
          </Integration.Section>
        </Integration.Body>
      </Integration.Container>
    </>
  );
};

export default Amplitude;
