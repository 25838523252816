import { IGettingStartedPanel } from "routes/GettingStarted";
import { useState } from "react";
import { ImportForm as MixpanelImportForm } from "modules/Settings/Imports/Mixpanel/ImportForm";
import { ImportForm as AmplitudeImportForm } from "modules/Settings/Imports/Amplitude/ImportForm";
import { AMPLITUDE, MIXPANEL } from "core/constants/apiKeys";
import AnimatedToggle from "core/components/AnimatedToggle";

export const Imports: React.FC<IGettingStartedPanel> = ({ isExpanded }) => {
  const [importSourceName, setImportSourceName] = useState<
    typeof AMPLITUDE | typeof MIXPANEL
  >(AMPLITUDE);

  return (
    <div className="flex min-h-[600px] flex-col gap-4">
      <AnimatedToggle
        shouldAnimate={isExpanded}
        items={[
          { value: AMPLITUDE, label: AMPLITUDE },
          { value: MIXPANEL, label: MIXPANEL },
        ]}
        value={importSourceName}
        onChange={(val) => {
          setImportSourceName(val as "amplitude" | "mixpanel");
        }}
        containerProps={{
          width: "100%",
          minHeight: [10],
          maxHeight: [10],
        }}
        labelContainerProps={{
          width: "100%",
          justifyContent: "center",
        }}
        labelProps={{
          fontSize: "sm",
          textAlign: "center",
        }}
      />
      {importSourceName === AMPLITUDE ? (
        <AmplitudeImportForm />
      ) : (
        <MixpanelImportForm />
      )}
    </div>
  );
};
