import Notification from "core/components/Notification";
import {
  createStandaloneToast,
  ToastPosition,
  UseToastOptions,
} from "@chakra-ui/react";
import { useCallback } from "react";

export const useToast = (initialOptions?: UseToastOptions) => {
  return useCallback(
    (options: UseToastOptions) => {
      const { toast } = createStandaloneToast();

      const defaultOptions = {
        position: "top" as ToastPosition,
        render: () => (
          <Notification
            title={options.title}
            description={options.description}
            status={options.status}
          />
        ),
        duration: 4000,
        isClosable: true,
      };

      return toast({
        ...defaultOptions,
        ...initialOptions,
        ...options,
      });
    },
    [initialOptions],
  );
};
