import { MenuItem, Tooltip } from "@chakra-ui/react";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useReportSetup } from "core/hooks/useReportSetup";
import { useRefreshReportMutation } from "core/models/reports";
import { IReport } from "core/types/Report";
import { cx } from "helpers/cx";
import moment from "moment";
import { useState, useEffect } from "react";

export const RefreshItem: React.FC<{ report: IReport }> = ({ report }) => {
  const { id: appId, isFirstWeek } = useCurrentApp();
  const { fetchReport, invalidateInsights } = useReportSetup();
  const [refreshReport] = useRefreshReportMutation();

  const [refreshDisabled, setRefreshDisabled] = useState<boolean>(
    shouldDisableRefresh(report.refreshedAt),
  );

  function shouldDisableRefresh(refreshedAt?: string) {
    const now = moment();
    const refreshedAtMoment = moment(refreshedAt);
    return Boolean(refreshedAt && now.diff(refreshedAtMoment, "seconds") < 300);
  }

  useEffect(() => {
    if (report.refreshedAt) {
      const isDisabled = shouldDisableRefresh(report.refreshedAt);
      setRefreshDisabled(isDisabled);
    }
  }, [report.refreshedAt]);

  useEffect(() => {
    const checkInterval = setInterval(() => {
      if (!report.refreshedAt) return;

      const isDisabled = shouldDisableRefresh(report.refreshedAt);

      setRefreshDisabled(isDisabled);
    }, 5000);

    return () => {
      clearInterval(checkInterval);
    };
  }, [report.refreshedAt]);

  if (isFirstWeek) return null;

  return (
    <MenuItem
      onClick={() => {
        if (refreshDisabled) return;
        refreshReport({ appId, reportId: report.id }).then(() => {
          invalidateInsights();
          fetchReport();
        });
      }}
    >
      <Tooltip
        label={
          refreshDisabled
            ? `Report data can be refreshed after 5 minutes`
            : `Refresh report data`
        }
        placement="left"
        shouldWrapChildren
        hasArrow
      >
        <div
          className={cx(
            "flex items-center gap-2",
            refreshDisabled && "cursor-not-allowed text-gray-500",
          )}
        >
          <ArrowPathIcon className="h-4" />
          <p>Refresh data</p>
        </div>
      </Tooltip>
    </MenuItem>
  );
};
