import { SearchBar } from "Components/SearchBar";

interface ISearchBar {
  searchQuery: string;
  placeholder: string;
  onSearch: (value: string) => void;
  disabled?: boolean;
}

export const DrilldownSearch: React.FC<ISearchBar> = ({
  searchQuery,
  onSearch,
  placeholder = '"Search your users by email address..."',
  disabled,
}) => (
  <div className="w-full">
    <SearchBar
      placeholder={placeholder}
      searchQuery={searchQuery}
      onSearch={onSearch}
      disabled={disabled}
      mb={0}
    />
  </div>
);
