import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ITemplateConfig } from "core/types/TemplateConfig";
import { IReport } from "core/types/Report";
import { useReportSetup } from "core/hooks/useReportSetup";
import { useEvent } from "core/hooks/useEvent";
import { uuid } from "core/helpers/uuid";
import { getReportConfig } from "core/helpers/getReportConfig";
import { TRACK } from "core/constants/report-setup";
import { FEATURE_RELEASE } from "core/constants/report-configurations";
import {
  Box,
  Container,
  Flex,
  Spacer,
  StyleProps,
  Text,
} from "@chakra-ui/react";
import { Setup } from "core/modules/reports/setup/Setup";
import GenerateReportButton from "./GenerateReportButton";

interface INewReportSetup extends StyleProps {
  report: IReport;
  saveButton?: boolean;
  onCloseDrawer?: () => void;
}

export const NewReportSetup: React.FC<INewReportSetup> = ({
  report,
  saveButton = false,
  onCloseDrawer,
  ...props
}) => {
  const [initialRender, setInitialRender] = useState(true);
  const [addDefaultTrackEvent, setAddDefaultTrackEvent] = useState(false);
  const { fetchReport, addTrackEvent } = useReportSetup();
  const { reportId } = useParams<{ reportId: string }>() as {
    reportId: string;
  };
  const queryParams = new URLSearchParams(window.location.search);
  const eventId = queryParams.get("eventId");
  const { onGetEvent, event } = useEvent();

  useEffect(() => {
    if (eventId) {
      onGetEvent({ eventId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  if (Object.keys(report).length === 0 || typeof report.reportType !== "number")
    return null;

  const isNewReport = window.location.pathname.includes("setup");

  if (initialRender) {
    setInitialRender(false);
  }

  if (Number(report.id) !== Number(reportId)) {
    fetchReport();
  }

  const config = getReportConfig(report.reportType) as ITemplateConfig;
  const setup = config.setup;

  if (
    report.reportType === FEATURE_RELEASE &&
    eventId &&
    event &&
    !addDefaultTrackEvent
  ) {
    setAddDefaultTrackEvent(true);
    const configKey = setup.setupSections[0].configKey;
    const setupEvent = {
      ...event,
      uuid: uuid(),
      type: TRACK,
      adding: false,
      properties: [],
    };
    addTrackEvent({ event: setupEvent, configKey });
  }

  return (
    <Flex data-testid="new-report-setup-container" direction="column">
      <Container maxW="container.lg">
        <Flex pt={(isNewReport && 16) || 0}>
          {isNewReport && (
            <Flex display="block" w="50%">
              <Text fontSize="md">
                Setup the{" "}
                <Text textTransform="lowercase" as="b">
                  {config.title.replace("report", "")}
                </Text>{" "}
                report
              </Text>
              <Text fontSize="xs" color="gray.600">
                {config.subtitle}
              </Text>
            </Flex>
          )}
          <Spacer />
          <Flex>
            <GenerateReportButton
              saveButton={saveButton}
              onCloseDrawer={onCloseDrawer}
              setup={setup}
              report={report}
              position={!isNewReport ? "fixed" : "relative"}
              top={(!isNewReport && 5) || 0}
              right={(!isNewReport && 7) || 0}
            />
          </Flex>
        </Flex>
      </Container>
      <Spacer />
      <Flex pb={16} {...props}>
        <Box display="block" w="1000px" mx="auto" {...props}>
          <Setup setup={setup} report={report} />
        </Box>
      </Flex>
    </Flex>
  );
};

export default NewReportSetup;
