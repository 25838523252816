import React from "react";
import Icon from "core/design-system/components/Icon";
import {
  Input,
  InputGroup,
  InputLeftElement,
  StyleProps,
} from "@chakra-ui/react";

interface ISearchBar extends StyleProps {
  searchQuery: string;
  placeholder: string;
  disabled?: boolean;
  onSearch: (value: string) => void;
}

export const SearchBar = ({
  searchQuery,
  onSearch,
  placeholder = "",
  disabled,
  ...styleProps
}: ISearchBar) => {
  return (
    <InputGroup {...styleProps}>
      <InputLeftElement h="32px">
        <Icon iconType="icon" name="search" color="blackAlpha.500" />
      </InputLeftElement>
      <Input
        data-testid="searchbar-input"
        borderRadius="lg"
        size="sm"
        focusBorderColor="primary"
        placeholder={placeholder}
        value={searchQuery}
        onChange={(e) => onSearch(e.target.value)}
        disabled={disabled}
      />
    </InputGroup>
  );
};
