import { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Flex,
  Text,
} from "@chakra-ui/react";
import { NOT_MIGRATED } from "core/constants/features";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import useFlag from "core/hooks/useFlag";
import { useRequestMigrationMutation } from "core/models/apps";

const LOCAL_STORAGE_KEY = "responded_to_request_migration";

export const NotMigratedModal: React.FC = () => {
  const currentApp = useCurrentApp();
  const notMigrated = useFlag(NOT_MIGRATED);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const respondedToMigration = localStorage.getItem(LOCAL_STORAGE_KEY);
  const [responded, setResponded] = useState(respondedToMigration);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [requestMigration] = useRequestMigrationMutation();

  useEffect(() => {
    if (notMigrated && !isOpen && !responded) onOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notMigrated]);

  function onRecover() {
    requestMigration({ appId: currentApp.id });
    localStorage.setItem(LOCAL_STORAGE_KEY, "true");
    setResponded("true");
    setShowConfirmation(true);
  }

  function onCancel() {
    localStorage.setItem(LOCAL_STORAGE_KEY, "true");
    onClose();
  }

  function onConfirmation() {
    setResponded("true");
    onClose();
  }

  return (
    <Modal size="lg" isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          {showConfirmation ? (
            <Flex py={4} direction="column" gridGap={2}>
              <Text fontWeight="semibold" fontSize="xl">
                Request received
              </Text>
              <Text fontSize="sm">
                Someone from our team will reach out to you shortly and you will
                be notified when we've recovered your data. This can take
                several hours. While you wait, you can resume to send us more
                data if you wish to do so.
              </Text>
              <Flex justifyContent="flex-end" w="full" mt={2} gridGap={3}>
                <Button
                  float="right"
                  onClick={onConfirmation}
                  colorScheme="purple"
                >
                  Got it
                </Button>
              </Flex>
            </Flex>
          ) : (
            <Flex py={4}>
              <Flex direction="column" align="center">
                <Flex direction="column" gridGap={2}>
                  <Text fontWeight="semibold" fontSize="xl">
                    Recover your data?
                  </Text>
                  <Text fontSize="sm">
                    Welcome back 👋 Looks like we haven't received any data from
                    you in a while. We've been cleaning up old data recently and
                    it looks your workspace has been affected. Don't worry, we
                    can recover your data.
                    <br />
                    <br />
                    If you do not wish to recover your data, your data will be
                    lost permanently. However, you can continue to use this
                    workspace and start sending us data again, if you wish to do
                    so.
                  </Text>
                  <Flex w="full" mt={4} gridGap={3}>
                    <Flex w="full">
                      <Button onClick={onCancel} w="full">
                        No, thanks
                      </Button>
                    </Flex>
                    <Flex w="full">
                      <Button onClick={onRecover} w="full" colorScheme="purple">
                        Request to recover my data
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
