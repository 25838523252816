import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { IFilterEvents } from "modules/Contacts/Contact/ContactActivity";
import { IContact } from "core/types/Contact";
import { contactsAdapter } from "core/models/contact/index";
import { fetchOneContactPages } from "core/models/contact/actions/fetchOneContactPages";
import { fetchOneContactGroups } from "core/models/contact/actions/fetchOneContactGroups";
import { fetchOneContactEvents } from "core/models/contact/actions/fetchOneContactEvents";
import { fetchOneContactActivity } from "core/models/contact/actions/fetchOneContactActivity";
import { fetchOneContact } from "core/models/contact/actions/fetchOneContact";
import { RootState } from "core/initializers/store";
import { IntervalTypeNames } from "core/constants/timerange";
import { JoinOperatorValue } from "core/constants/report-setup";
import { fetchOneContactCompanies } from "core/models/contact/actions/fetchOneContactCompanies";

interface IUseContact {
  appId: number | string;
  id: string;
  intervalType?: IntervalTypeNames;
  filterEvents?: IFilterEvents;
}

export const useContact = ({
  appId,
  id,
  intervalType = IntervalTypeNames.DAY,
  filterEvents = { events: [], joinOperator: JoinOperatorValue.OR },
}: IUseContact) => {
  const dispatch = useDispatch();
  const isLoadingContact = useSelector(
    (state: RootState) => state.contact.fetch.loading[id],
  );
  const contactError = useSelector(
    (state: RootState) => state.contact.fetch.error[id],
  );
  const contactSelectors = contactsAdapter.getSelectors(
    (state: RootState) => state.contact,
  );
  const contact = useSelector((state: RootState): IContact | undefined =>
    contactSelectors.selectById(state, id),
  );
  const isLoadingContactActivity = useSelector(
    (state: RootState) => state.contact.fetchContactActivity.loading[id],
  );
  const contactActivityErrors = useSelector(
    (state: RootState) => state.contact.fetchContactActivity.error[id],
  );
  const contactActivity = useSelector(
    (state: RootState) => state.contact.contactActivity.data[id],
  );
  const contactEvents = useSelector(
    (state: RootState) => state.contact.contactEvents.data[id],
  );
  const contactPages = useSelector(
    (state: RootState) => state.contact.contactPages.data[id],
  );
  const contactGroups = useSelector(
    (state: RootState) => state.contact.contactGroups.data[id],
  );
  const contactCompanies = useSelector(
    (state: RootState) => state.contact.contactCompanies.data[id],
  );

  const isLoadingContactGroups = useSelector(
    (state: RootState) => state.contact.fetchContactGroups.loading[id],
  );
  const isLoadingContactCompanies = useSelector(
    (state: RootState) => state.contact.fetchContactCompanies.loading[id],
  );
  const contactGroupsErrors = useSelector(
    (state: RootState) => state.contact.fetchContactGroups.error[id],
  );
  const contactCompaniesErrors = useSelector(
    (state: RootState) => state.contact.fetchContactCompanies.error[id],
  );

  const isLoadingContactEvents = useSelector(
    (state: RootState) => state.contact.fetchContactEvents.loading[id],
  );

  const isLoadingContactPages = useSelector(
    (state: RootState) => state.contact.fetchContactPages.loading[id],
  );

  const fetchContact = useCallback(() => {
    dispatch(fetchOneContact({ appId, id }));
  }, [dispatch, appId, id]);

  const fetchContactEvents = useCallback(() => {
    dispatch(fetchOneContactEvents({ appId, id }));
  }, [dispatch, appId, id]);

  const fetchContactPages = useCallback(() => {
    dispatch(fetchOneContactPages({ appId, id }));
  }, [dispatch, appId, id]);

  const fetchContactActivity = useCallback(() => {
    dispatch(
      fetchOneContactActivity({ appId, id, intervalType, filterEvents }),
    );
  }, [dispatch, appId, id, intervalType, filterEvents]);

  const fetchContactGroups = useCallback(() => {
    dispatch(fetchOneContactGroups({ appId, id }));
  }, [dispatch, appId, id]);

  const fetchContactCompanies = useCallback(() => {
    dispatch(fetchOneContactCompanies({ appId, id }));
  }, [dispatch, appId, id]);

  return {
    contact,
    contactActivity,
    contactActivityErrors,
    contactError,
    contactEvents,
    contactGroups,
    contactCompanies,
    contactPages,
    contactGroupsErrors,
    contactCompaniesErrors,
    fetchContact,
    fetchContactActivity,
    fetchContactEvents,
    fetchContactPages,
    fetchContactGroups,
    fetchContactCompanies,
    isLoadingContact,
    isLoadingContactActivity,
    isLoadingContactGroups,
    isLoadingContactCompanies,
    isLoadingContactEvents,
    isLoadingContactPages,
  };
};
