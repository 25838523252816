const baseStyle = {
  color: "purple.500",
  _hover: {
    textDecoration: "none",
  },
};

const variants = {
  underline: {
    _hover: {
      textDecoration: "underline",
    },
  },
};

const Link = {
  baseStyle,
  variants,
};

export default Link;
