export const mockActiveUsersChangesData = [
  {
    returningUsersCount: 1200,
    newUsersCount: 10,
    count: 1200,
    insightChange: 83.33,
    date: "2021-02-01",
    humanizedDate: "Thu, Feb 01",
  },
  {
    returningUsersCount: 1430,
    newUsersCount: 10,
    count: 1430,
    insightChange: 33.33,
    date: "2021-02-01",
    humanizedDate: "Feb 01",
  },
  {
    returningUsersCount: 1673,
    newUsersCount: 10,
    count: 1673,
    insightChange: 30.2,
    date: "2021-02-01",
    humanizedDate: "Feb",
  },
];

export const dauMockData = {
  data: [
    {
      humanizedDate: "Tue, 26 Jan",
      activeUsers: 832,
      identifiedUsersCount: 832,
    },
    {
      humanizedDate: "Wed, 27 Jan",
      activeUsers: 854,
      identifiedUsersCount: 854,
    },
    {
      humanizedDate: "Thu, 28 Jan",
      activeUsers: 970,
      identifiedUsersCount: 970,
    },
    {
      humanizedDate: "Fri, 29 Jan",
      activeUsers: 898,
      identifiedUsersCount: 898,
    },
    {
      humanizedDate: "Sat, 30 Jan",
      activeUsers: 1102,
      identifiedUsersCount: 1102,
    },
    {
      humanizedDate: "Sun, 31 Jan",
      activeUsers: 654,
      identifiedUsersCount: 654,
    },
    {
      humanizedDate: "Mon, 01 Feb",
      activeUsers: 1200,
      identifiedUsersCount: 1200,
    },
  ],
  insightChange: 83.33,
};

export const wauMockData = {
  weeklyActives: {
    humanizedDate: "Jan 25 - Jan 31",
    activeUsers: 1073,
    identifiedUsersCount: 1073,
  },
  data: [
    {
      humanizedDate: "Dec 14 - Dec 20",
      activeUsers: 1282,
      identifiedUsersCount: 1282,
    },
    {
      humanizedDate: "Dec 21 - Dec 27",
      activeUsers: 1402,
      identifiedUsersCount: 1402,
    },
    {
      humanizedDate: "Dec 28 - Jan 03",
      activeUsers: 1420,
      identifiedUsersCount: 1420,
    },
    {
      humanizedDate: "Jan 04 - Jan 10",
      activeUsers: 1190,
      identifiedUsersCount: 1190,
    },
    {
      humanizedDate: "Jan 11 - Jan 17",
      activeUsers: 1377,
      identifiedUsersCount: 1377,
    },
    {
      humanizedDate: "Jan 18 - Jan 24",
      activeUsers: 1165,
      identifiedUsersCount: 1165,
    },
    {
      humanizedDate: "Jan 25 - Jan 31",
      activeUsers: 1073,
      identifiedUsersCount: 1073,
    },
  ],
  insightChange: 33.33,
};

export const mauMockData = {
  monthlyActives: {
    humanizedDate: "January",
    activeUsers: 1673,
    identifiedUsersCount: 1673,
  },
  data: [
    {
      humanizedDate: "November",
      activeUsers: 1100,
      identifiedUsersCount: 1100,
    },
    {
      humanizedDate: "December",
      activeUsers: 1285,
      identifiedUsersCount: 1285,
    },
    {
      humanizedDate: "January",
      activeUsers: 1673,
      identifiedUsersCount: 1673,
    },
  ],
  insightChange: 30.2,
};

export const dauWauMockData = {
  data: [
    {
      humanizedDate: "Jan 26",
      incomplete: false,
      stickynessRatio: 58.2,
    },
    {
      humanizedDate: "Jan 27",
      incomplete: false,
      stickynessRatio: 43.24,
    },
    {
      humanizedDate: "Jan 28",
      incomplete: false,
      stickynessRatio: 67.8,
    },
    {
      humanizedDate: "Jan 29",
      incomplete: false,
      stickynessRatio: 62.8,
    },
    {
      humanizedDate: "Jan 30",
      incomplete: false,
      stickynessRatio: 77.1,
    },
    {
      humanizedDate: "Jan 31",
      incomplete: false,
      stickynessRatio: 45.7,
    },
    {
      humanizedDate: "Feb 01",
      incomplete: false,
      stickynessRatio: 83.9,
    },
  ],
  insightChange: 83.34,
};

export const dauMauMockData = {
  data: [
    {
      humanizedDate: "Jan 03",
      incomplete: false,
      stickynessRatio: 46,
    },
    {
      humanizedDate: "Jan 04",
      incomplete: false,
      stickynessRatio: 76,
    },
    {
      humanizedDate: "Jan 05",
      incomplete: false,
      stickynessRatio: 46,
    },
    {
      humanizedDate: "Jan 06",
      incomplete: false,
      stickynessRatio: 46,
    },
    {
      humanizedDate: "Jan 07",
      incomplete: false,
      stickynessRatio: 46,
    },
    {
      humanizedDate: "Jan 08",
      incomplete: false,
      stickynessRatio: 32,
    },
    {
      humanizedDate: "Jan 09",
      incomplete: false,
      stickynessRatio: 37,
    },
    {
      humanizedDate: "Jan 10",
      incomplete: false,
      stickynessRatio: 32,
    },
    {
      humanizedDate: "Jan 11",
      incomplete: false,
      stickynessRatio: 43,
    },
    {
      humanizedDate: "Jan 12",
      incomplete: false,
      stickynessRatio: 78,
    },
    {
      humanizedDate: "Jan 13",
      incomplete: false,
      stickynessRatio: 78,
    },
    {
      humanizedDate: "Jan 14",
      incomplete: false,
      stickynessRatio: 78,
    },
    {
      humanizedDate: "Jan 15",
      incomplete: false,
      stickynessRatio: 84,
    },
    {
      humanizedDate: "Jan 16",
      incomplete: false,
      stickynessRatio: 78,
    },
    {
      humanizedDate: "Jan 17",
      incomplete: false,
      stickynessRatio: 64,
    },
    {
      humanizedDate: "Jan 18",
      incomplete: false,
      stickynessRatio: 64,
    },
    {
      humanizedDate: "Jan 19",
      incomplete: false,
      stickynessRatio: 64,
    },
    {
      humanizedDate: "Jan 20",
      incomplete: false,
      stickynessRatio: 65,
    },
    {
      humanizedDate: "Jan 21",
      incomplete: false,
      stickynessRatio: 64,
    },
    {
      humanizedDate: "Jan 22",
      incomplete: false,
      stickynessRatio: 64,
    },
    {
      humanizedDate: "Jan 23",
      incomplete: false,
      stickynessRatio: 34,
    },
    {
      humanizedDate: "Jan 24",
      incomplete: false,
      stickynessRatio: 64,
    },
    {
      humanizedDate: "Jan 25",
      incomplete: false,
      stickynessRatio: 45,
    },
    {
      humanizedDate: "Jan 26",
      incomplete: false,
      stickynessRatio: 64,
    },
    {
      humanizedDate: "Jan 27",
      incomplete: false,
      stickynessRatio: 64,
    },
    {
      humanizedDate: "Jan 28",
      incomplete: false,
      stickynessRatio: 54,
    },
    {
      humanizedDate: "Jan 29",
      incomplete: false,
      stickynessRatio: 64,
    },
    {
      humanizedDate: "Jan 30",
      incomplete: false,
      stickynessRatio: 64,
    },
    {
      humanizedDate: "Jan 31",
      incomplete: false,
      stickynessRatio: 64,
    },
    {
      humanizedDate: "Feb 01",
      incomplete: false,
      stickynessRatio: 87,
    },
  ],
  insightChange: 83.33,
};

export const wauMauMockData = {
  data: [
    {
      humanizedDate: "Jan 04 - Jan 10",
      incomplete: false,
      date: "2021-01-04",
      stickynessRatio: 72.4,
    },
    {
      humanizedDate: "Jan 11 - Jan 17",
      incomplete: false,
      date: "2021-01-11",
      stickynessRatio: 83.8,
    },
    {
      humanizedDate: "Jan 18 - Jan 24",
      incomplete: false,
      date: "2021-01-18",
      stickynessRatio: 70.9,
    },
    {
      humanizedDate: "Jan 25 - Jan 31",
      incomplete: false,
      date: "2021-01-25",
      stickynessRatio: 65.3,
    },
  ],
};

export const contributionGraphMockData = {
  data: {
    contacts: [
      {
        user_id: "3f9f5c46-9562-4d3a-84f2-8fe5a8e8a44c",
        is_identified: true,
        traits: {
          "Active days": "1",
          "First seen": "2024-08-09 13:53:12",
          "Last seen": "2024-09-03 13:53:12",
          "Total events performed": "9",
          "Usage trend": "100",
          avatar: "https://loremflickr.com/50/50/face",
          company_name: "ollins",
          createdAt: "2024-09-10T16:53:24+03:00",
          department: '{\\"department\\":\\"Engineering\\"}',
          email: "shalanda.bechtelar@ollins.com",
          firstName: "Shalanda",
          industry: "Broadcast Media",
          lastName: "Bechtelar",
          name: "Shalanda Bechtelar",
          plan: "Free",
          profession: "astronomer",
        },
        interval_dates_with_empty_intervals: [
          ["2024-08-03 00:00:00", "0"],
          ["2024-08-04 00:00:00", "0"],
          ["2024-08-05 00:00:00", "0"],
          ["2024-08-06 00:00:00", "0"],
          ["2024-08-07 00:00:00", "0"],
          ["2024-08-08 00:00:00", "0"],
          ["2024-08-09 00:00:00", "7"],
          ["2024-08-10 00:00:00", "0"],
          ["2024-08-11 00:00:00", "0"],
          ["2024-08-12 00:00:00", "0"],
          ["2024-08-13 00:00:00", "0"],
          ["2024-08-14 00:00:00", "0"],
          ["2024-08-15 00:00:00", "0"],
          ["2024-08-16 00:00:00", "0"],
          ["2024-08-17 00:00:00", "0"],
          ["2024-08-18 00:00:00", "0"],
          ["2024-08-19 00:00:00", "0"],
          ["2024-08-20 00:00:00", "0"],
          ["2024-08-21 00:00:00", "0"],
          ["2024-08-22 00:00:00", "0"],
          ["2024-08-23 00:00:00", "0"],
          ["2024-08-24 00:00:00", "0"],
          ["2024-08-25 00:00:00", "0"],
          ["2024-08-26 00:00:00", "0"],
          ["2024-08-27 00:00:00", "0"],
          ["2024-08-28 00:00:00", "0"],
          ["2024-08-29 00:00:00", "0"],
          ["2024-08-30 00:00:00", "0"],
          ["2024-08-31 00:00:00", "0"],
          ["2024-09-01 00:00:00", "0"],
          ["2024-09-02 00:00:00", "0"],
          ["2024-09-03 00:00:00", "2"],
          ["2024-09-04 00:00:00", "0"],
          ["2024-09-05 00:00:00", "0"],
          ["2024-09-06 00:00:00", "0"],
          ["2024-09-07 00:00:00", "0"],
          ["2024-09-08 00:00:00", "0"],
          ["2024-09-09 00:00:00", "0"],
          ["2024-09-10 00:00:00", "0"],
          ["2024-09-11 00:00:00", "0"],
        ],
      },
      {
        user_id: "32e5d118-3eaa-467e-b9d8-1c96d4983b4e",
        is_identified: true,
        traits: {
          "Active days": "2",
          "First seen": "2024-08-24 13:53:12",
          "Last seen": "2024-08-27 13:53:12",
          "Total events performed": "9",
          "Usage trend": "-100",
        },
        interval_dates_with_empty_intervals: [
          ["2024-08-03 00:00:00", "0"],
          ["2024-08-04 00:00:00", "0"],
          ["2024-08-05 00:00:00", "0"],
          ["2024-08-06 00:00:00", "0"],
          ["2024-08-07 00:00:00", "0"],
          ["2024-08-08 00:00:00", "0"],
          ["2024-08-09 00:00:00", "0"],
          ["2024-08-10 00:00:00", "0"],
          ["2024-08-11 00:00:00", "0"],
          ["2024-08-12 00:00:00", "0"],
          ["2024-08-13 00:00:00", "0"],
          ["2024-08-14 00:00:00", "0"],
          ["2024-08-15 00:00:00", "0"],
          ["2024-08-16 00:00:00", "0"],
          ["2024-08-17 00:00:00", "0"],
          ["2024-08-18 00:00:00", "0"],
          ["2024-08-19 00:00:00", "0"],
          ["2024-08-20 00:00:00", "0"],
          ["2024-08-21 00:00:00", "0"],
          ["2024-08-22 00:00:00", "0"],
          ["2024-08-23 00:00:00", "0"],
          ["2024-08-24 00:00:00", "7"],
          ["2024-08-25 00:00:00", "0"],
          ["2024-08-26 00:00:00", "0"],
          ["2024-08-27 00:00:00", "2"],
          ["2024-08-28 00:00:00", "0"],
          ["2024-08-29 00:00:00", "0"],
          ["2024-08-30 00:00:00", "0"],
          ["2024-08-31 00:00:00", "0"],
          ["2024-09-01 00:00:00", "0"],
          ["2024-09-02 00:00:00", "0"],
          ["2024-09-03 00:00:00", "0"],
          ["2024-09-04 00:00:00", "0"],
          ["2024-09-05 00:00:00", "0"],
          ["2024-09-06 00:00:00", "0"],
          ["2024-09-07 00:00:00", "0"],
          ["2024-09-08 00:00:00", "0"],
          ["2024-09-09 00:00:00", "0"],
          ["2024-09-10 00:00:00", "0"],
          ["2024-09-11 00:00:00", "0"],
        ],
      },
      {
        user_id: "2b1bce49-d2c4-4c25-849f-4d06a14c9b83",
        is_identified: true,
        traits: {
          "Active days": "1",
          "First seen": "2024-09-10 13:53:12",
          "Last seen": "2024-09-10 13:53:12",
          "Total events performed": "7",
          "Usage trend": "100",
          avatar: "https://loremflickr.com/50/50/face",
          company_name: "aters",
          createdAt: "2024-09-10T16:53:34+03:00",
          department: '{\\"department\\":\\"Business Development\\"}',
          email: "eugene.willms@aters.com",
          firstName: "Eugene",
          industry: "Import and Export",
          lastName: "Willms",
          name: "Eugene Willms",
          plan: "Free",
          profession: "dentist",
        },
        interval_dates_with_empty_intervals: [
          ["2024-08-03 00:00:00", "0"],
          ["2024-08-04 00:00:00", "0"],
          ["2024-08-05 00:00:00", "0"],
          ["2024-08-06 00:00:00", "0"],
          ["2024-08-07 00:00:00", "0"],
          ["2024-08-08 00:00:00", "0"],
          ["2024-08-09 00:00:00", "0"],
          ["2024-08-10 00:00:00", "0"],
          ["2024-08-11 00:00:00", "0"],
          ["2024-08-12 00:00:00", "0"],
          ["2024-08-13 00:00:00", "0"],
          ["2024-08-14 00:00:00", "0"],
          ["2024-08-15 00:00:00", "0"],
          ["2024-08-16 00:00:00", "0"],
          ["2024-08-17 00:00:00", "0"],
          ["2024-08-18 00:00:00", "0"],
          ["2024-08-19 00:00:00", "0"],
          ["2024-08-20 00:00:00", "0"],
          ["2024-08-21 00:00:00", "0"],
          ["2024-08-22 00:00:00", "0"],
          ["2024-08-23 00:00:00", "0"],
          ["2024-08-24 00:00:00", "0"],
          ["2024-08-25 00:00:00", "0"],
          ["2024-08-26 00:00:00", "0"],
          ["2024-08-27 00:00:00", "0"],
          ["2024-08-28 00:00:00", "0"],
          ["2024-08-29 00:00:00", "0"],
          ["2024-08-30 00:00:00", "0"],
          ["2024-08-31 00:00:00", "0"],
          ["2024-09-01 00:00:00", "0"],
          ["2024-09-02 00:00:00", "0"],
          ["2024-09-03 00:00:00", "0"],
          ["2024-09-04 00:00:00", "0"],
          ["2024-09-05 00:00:00", "0"],
          ["2024-09-06 00:00:00", "0"],
          ["2024-09-07 00:00:00", "0"],
          ["2024-09-08 00:00:00", "0"],
          ["2024-09-09 00:00:00", "0"],
          ["2024-09-10 00:00:00", "7"],
          ["2024-09-11 00:00:00", "0"],
        ],
      },
      {
        user_id: "6340e692-2e99-492e-aca4-d277a4c0880d",
        is_identified: true,
        traits: {
          "Active days": "1",
          "First seen": "2024-09-10 13:53:12",
          "Last seen": "2024-09-10 13:53:12",
          "Total events performed": "7",
          "Usage trend": "100",
          avatar: "https://loremflickr.com/50/50/face",
          company_name: "intheiserilpert",
          createdAt: "2024-09-10T16:53:34+03:00",
          department: '{\\"department\\":\\"Business Development\\"}',
          email: "julio.kessler@intheiserilpert.com",
          firstName: "Julio",
          industry: "Broadcast Media",
          lastName: "Kessler",
          name: "Julio Kessler",
          plan: "Paid",
          profession: "astronomer",
        },
        interval_dates_with_empty_intervals: [
          ["2024-08-03 00:00:00", "0"],
          ["2024-08-04 00:00:00", "0"],
          ["2024-08-05 00:00:00", "0"],
          ["2024-08-06 00:00:00", "0"],
          ["2024-08-07 00:00:00", "0"],
          ["2024-08-08 00:00:00", "0"],
          ["2024-08-09 00:00:00", "0"],
          ["2024-08-10 00:00:00", "0"],
          ["2024-08-11 00:00:00", "0"],
          ["2024-08-12 00:00:00", "0"],
          ["2024-08-13 00:00:00", "0"],
          ["2024-08-14 00:00:00", "0"],
          ["2024-08-15 00:00:00", "0"],
          ["2024-08-16 00:00:00", "0"],
          ["2024-08-17 00:00:00", "0"],
          ["2024-08-18 00:00:00", "0"],
          ["2024-08-19 00:00:00", "0"],
          ["2024-08-20 00:00:00", "0"],
          ["2024-08-21 00:00:00", "0"],
          ["2024-08-22 00:00:00", "0"],
          ["2024-08-23 00:00:00", "0"],
          ["2024-08-24 00:00:00", "0"],
          ["2024-08-25 00:00:00", "0"],
          ["2024-08-26 00:00:00", "0"],
          ["2024-08-27 00:00:00", "0"],
          ["2024-08-28 00:00:00", "0"],
          ["2024-08-29 00:00:00", "0"],
          ["2024-08-30 00:00:00", "0"],
          ["2024-08-31 00:00:00", "0"],
          ["2024-09-01 00:00:00", "0"],
          ["2024-09-02 00:00:00", "0"],
          ["2024-09-03 00:00:00", "0"],
          ["2024-09-04 00:00:00", "0"],
          ["2024-09-05 00:00:00", "0"],
          ["2024-09-06 00:00:00", "0"],
          ["2024-09-07 00:00:00", "0"],
          ["2024-09-08 00:00:00", "0"],
          ["2024-09-09 00:00:00", "0"],
          ["2024-09-10 00:00:00", "7"],
          ["2024-09-11 00:00:00", "0"],
        ],
      },
      {
        user_id: "7ac30197-6027-4036-abc4-8d7b416a0c99",
        is_identified: true,
        traits: {
          "Active days": "1",
          "First seen": "2024-09-10 13:53:12",
          "Last seen": "2024-09-10 13:53:12",
          "Total events performed": "7",
          "Usage trend": "100",
        },
        interval_dates_with_empty_intervals: [
          ["2024-08-03 00:00:00", "0"],
          ["2024-08-04 00:00:00", "0"],
          ["2024-08-05 00:00:00", "0"],
          ["2024-08-06 00:00:00", "0"],
          ["2024-08-07 00:00:00", "0"],
          ["2024-08-08 00:00:00", "0"],
          ["2024-08-09 00:00:00", "0"],
          ["2024-08-10 00:00:00", "0"],
          ["2024-08-11 00:00:00", "0"],
          ["2024-08-12 00:00:00", "0"],
          ["2024-08-13 00:00:00", "0"],
          ["2024-08-14 00:00:00", "0"],
          ["2024-08-15 00:00:00", "0"],
          ["2024-08-16 00:00:00", "0"],
          ["2024-08-17 00:00:00", "0"],
          ["2024-08-18 00:00:00", "0"],
          ["2024-08-19 00:00:00", "0"],
          ["2024-08-20 00:00:00", "0"],
          ["2024-08-21 00:00:00", "0"],
          ["2024-08-22 00:00:00", "0"],
          ["2024-08-23 00:00:00", "0"],
          ["2024-08-24 00:00:00", "0"],
          ["2024-08-25 00:00:00", "0"],
          ["2024-08-26 00:00:00", "0"],
          ["2024-08-27 00:00:00", "0"],
          ["2024-08-28 00:00:00", "0"],
          ["2024-08-29 00:00:00", "0"],
          ["2024-08-30 00:00:00", "0"],
          ["2024-08-31 00:00:00", "0"],
          ["2024-09-01 00:00:00", "0"],
          ["2024-09-02 00:00:00", "0"],
          ["2024-09-03 00:00:00", "0"],
          ["2024-09-04 00:00:00", "0"],
          ["2024-09-05 00:00:00", "0"],
          ["2024-09-06 00:00:00", "0"],
          ["2024-09-07 00:00:00", "0"],
          ["2024-09-08 00:00:00", "0"],
          ["2024-09-09 00:00:00", "0"],
          ["2024-09-10 00:00:00", "7"],
          ["2024-09-11 00:00:00", "0"],
        ],
      },
    ],
  },
};
