import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import Icon from "core/design-system/components/Icon";
import { Template, TEMPLATES } from "core/constants/templates";
import { baseUrl } from "core/constants/paywall";
import {
  IPaywallModalChildren,
  PaywallModal,
} from "core/components/Paywall/Modal";
import AnimatedToggle from "core/components/AnimatedToggle";
import {
  Box,
  Button,
  Flex,
  Grid,
  IconButton,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Text,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { getTemplateConfig } from "./helpers";

export const TemplatePreview: React.FC<{
  template: Template;
  setSelectedTemplate: (template?: Template) => void;
  onClose: () => void;
}> = ({ template, setSelectedTemplate, onClose }) => {
  const navigate = useNavigate();
  const { id: appId, features } = useCurrentApp();
  const [paywallModal, showPaywallModal] = useState(false);
  const { title, description } = TEMPLATES[template];
  const config = getTemplateConfig(template, features);
  const [preview, setPreview] = useState("user");
  const hasPreviewToggle = config.sections?.company;
  const redirectUrl = `${baseUrl}/a/${appId}/home`;
  // Temporary fix for handling old and new configs
  let sections = config?.sections;
  if (sections?.user && sections?.company) {
    sections = preview === "company" ? sections.company : sections.user;
  }

  return (
    <>
      <ModalHeader px={6} pt={6} pb={0}>
        <Flex alignItems="center">
          <IconButton
            variant={"ghost"}
            aria-label={"Back"}
            icon={<ArrowBackIcon fontSize={24} />}
            mr={1}
            ml={-2}
            onClick={() => {
              setSelectedTemplate(undefined);
              navigate({ search: "" });
            }}
          />
          {title}
        </Flex>
      </ModalHeader>
      <ModalCloseButton mr={3} mt={4} />
      <ModalBody pb={0} pt={2}>
        <>
          <Text>{description}</Text>
          <Box
            mt={6}
            bg="gray.50"
            borderRadius="lg"
            p={8}
            h="xl"
            overflowY="scroll"
          >
            {hasPreviewToggle && (
              <Flex w="100%" justify="center" mb={8}>
                <AnimatedToggle
                  items={[
                    {
                      value: "user",
                      label: "User",
                      endIcon: <Icon iconType="icon" name="contacts" />,
                    },
                    {
                      value: "company",
                      label: "Company",
                      endIcon: <Icon iconType="icon" name="company" />,
                    },
                  ]}
                  value={preview}
                  onChange={setPreview}
                />
              </Flex>
            )}
            <Grid templateColumns="repeat(2, 1fr)" gap={4} minW="865px">
              {sections.map((Section: any) => (
                <Section
                  appId={1}
                  config={config}
                  reportId={1}
                  previewMode
                  showYAxis
                  reportLevel={preview}
                />
              ))}
            </Grid>
          </Box>
        </>
      </ModalBody>
      <ModalFooter>
        <Button variant="ghost" onClick={onClose} mr={2}>
          Close
        </Button>
        <Button
          colorScheme="purple"
          mr={3}
          onClick={onClose}
          as={Link}
          to={`/a/${appId}/report/create/${config!.reportType}`}
        >
          Create report
        </Button>
      </ModalFooter>

      {paywallModal && (
        <PaywallModal redirectUrl={redirectUrl}>
          {(paywallModal: IPaywallModalChildren) => (
            <paywallModal.Modal
              isOpen
              onClose={() => showPaywallModal(false)}
            />
          )}
        </PaywallModal>
      )}
    </>
  );
};
