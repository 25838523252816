import React from "react";
import { IReport } from "core/types/Report";
import { useInsightCsvExport } from "core/hooks/useInsightCsvExport";
import { useGroupDrilldown } from "core/hooks/useGroupDrilldown";
import { useAppObjects } from "core/hooks/useAppObjects";
import { GroupListSingleGroup } from "core/components/Group/GroupListSingleGroup";
import Error from "core/components/Error";
import { Drilldown } from "core/components/Drilldown";
import { UseDisclosureReturn } from "@chakra-ui/react";

export interface INewGroup {
  groupId: string;
  traits: { [key: string]: string };
  groupType: number;
}

interface INewGroupsListProps
  extends Pick<UseDisclosureReturn, "isOpen" | "onClose"> {
  report: IReport;
  newGroupsCount: number;
  intervalType: number;
  humanizedDate: string;
  date: string;
  hour?: string;
}

export const NewGroupsList: React.FC<INewGroupsListProps> = ({
  isOpen,
  onClose,
  report,
  intervalType,
  newGroupsCount,
  humanizedDate,
  date,
  hour,
}) => {
  const getInsightParams = () => {
    const baseParams = { intervalType, date };

    return hour !== "" ? { ...baseParams, hour } : baseParams;
  };
  const { activeAppObject } = useAppObjects();

  const insightType = 11;
  const {
    groups: newGroups,
    isLoading,
    error,
    hasMoreGroups,
    loadMore,
  } = useGroupDrilldown({
    report,
    insightType,
    insightParams: getInsightParams(),
    skip: !isOpen,
  });

  const { requestCsvExport, csvExportLoading } = useInsightCsvExport({
    report,
    insightId: insightType,
    insightParams: getInsightParams(),
    count: newGroupsCount,
  });

  if (error) {
    return <Error />;
  }

  return (
    <Drilldown isOpen={isOpen} onClose={onClose}>
      {(Drilldown) => (
        <>
          <Drilldown.Header>
            <Drilldown.Title
              title={`There are ${newGroupsCount} new ${activeAppObject?.pluralName.toLowerCase()} for ${humanizedDate}`}
              isLoading={isLoading}
            />
            <Drilldown.DownloadButton
              isDisabled={newGroupsCount <= 0}
              isLoading={csvExportLoading}
              onClick={requestCsvExport}
            />
          </Drilldown.Header>
          <Drilldown.Body pt={4}>
            <Drilldown.List
              items={newGroups}
              itemRenderer={({ item }: { item: INewGroup }) => (
                <GroupListSingleGroup
                  key={item.groupId}
                  id={item.groupId}
                  traits={item.traits}
                  groupType={item.groupType}
                />
              )}
              hasMore={hasMoreGroups}
              isLoading={isLoading}
              loadNext={loadMore}
              emptyStateText={`No new ${activeAppObject?.pluralName.toLowerCase()}`}
            />
          </Drilldown.Body>
        </>
      )}
    </Drilldown>
  );
};
