import React, { useRef, useState } from "react";
import SelectMenu from "core/components/Select";
import {
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useOutsideClick,
} from "@chakra-ui/react";
import { CheckIcon, ChevronDownIcon } from "@chakra-ui/icons";

export interface ITimezone {
  value: string;
  label: string;
  utcOffset: number;
}

interface ISelectTimezoneProps {
  options: ITimezone[];
  defaultOption: ITimezone;
  onChange: (timeZone: string) => void;
}

export const SelectTimezone: React.FC<ISelectTimezoneProps> = ({
  options,
  defaultOption,
  onChange,
}) => {
  const initialFocusRef = useRef(null);
  const utcOffset =
    defaultOption.label !== "UTC"
      ? `(UTC${defaultOption.utcOffset >= 0 ? "+" : ""}${defaultOption.utcOffset})`
      : "";
  const timezonePlaceholder = defaultOption?.label + " " + utcOffset;
  const [searchValue, setSearchValue] = useState("");
  const [popoverOpened, setPopoverOpened] = useState(false);

  useOutsideClick({
    ref: initialFocusRef,
    handler: () => setPopoverOpened(false),
  });

  const filteredOptions =
    options.filter((option: ITimezone) =>
      searchValue ? option.label.toLowerCase().includes(searchValue) : true,
    ) || [];

  return (
    <SelectMenu>
      {(Select) => (
        <Select.Container
          isOpen={popoverOpened}
          initialFocusRef={initialFocusRef}
          returnFocusOnClose={false}
        >
          <Select.Anchor>
            <InputGroup
              ref={initialFocusRef}
              size="sm"
              width="150px"
              onFocus={(e) => setPopoverOpened(true)}
            >
              <Input
                placeholder={timezonePlaceholder}
                value={searchValue}
                focusBorderColor="purple.300"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                borderRadius="lg"
                fontWeight="medium"
              />
              <InputRightElement>
                <ChevronDownIcon />
              </InputRightElement>
            </InputGroup>
          </Select.Anchor>
          <Select.Body w="300px">
            {filteredOptions?.length > 0 ? (
              <>
                {filteredOptions.map((option: ITimezone, index: number) => (
                  <Flex
                    w="400px"
                    key={index}
                    _hover={{ bg: "gray.100" }}
                    fontSize="sm"
                    px={2}
                    py={1}
                    cursor="pointer"
                    justifyContent="space-between"
                    alignItems="center"
                    onClick={() => {
                      onChange(option.value);
                      setSearchValue(option.label);
                      setPopoverOpened(false);
                    }}
                  >
                    <Text fontWeight="medium">
                      {option.label}{" "}
                      {option.label !== "UTC" &&
                        `(UTC${option.utcOffset >= 0 ? "+" : ""}${option.utcOffset})`}
                    </Text>
                    <Flex>
                      {searchValue === option.label && (
                        <CheckIcon mr={2} fontSize="xs" />
                      )}
                    </Flex>
                  </Flex>
                ))}
              </>
            ) : (
              <Text fontSize="sm" p={2}>
                No timezone found
              </Text>
            )}
          </Select.Body>
        </Select.Container>
      )}
    </SelectMenu>
  );
};
