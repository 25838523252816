import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import moment from "moment";
import { IGroup } from "core/types/Group";
import { IContact } from "core/types/Contact";
import { IAppObject } from "core/models/appObjects";
import { useAttioSettings } from "core/hooks/useAttioSettings";
import { useAttioObjects } from "core/hooks/useAttioObjects";
import AttioIcon from "core/design-system/components/Icon/Interface/AttioIcon";
import { UninstalledCard } from "core/components/Attio/UninstalledCard";
import {
  ArrowPathIcon,
  ArrowTopRightOnSquareIcon,
  Cog6ToothIcon,
} from "@heroicons/react/24/outline";
import { Button, Skeleton, Tooltip } from "@chakra-ui/react";

const Profile: React.FC<{
  entity: IContact | IGroup;
  appObject: IAppObject;
}> = ({ entity, appObject }) => {
  const {
    objects,
    isLoading,
    isFetching,
    error,
    lastSyncedAt,
    isCreatingObject,
    isUpdatingObject,
    workspaceName,
    route,
    onCreateObject,
    onUpdateObject,
    onFetchObjects,
  } = useAttioObjects({ entity, appObject });

  const { appId } = useParams();
  const navigate = useNavigate();

  if (isLoading)
    return (
      <div className="flex items-center gap-2">
        <AttioIcon color="gray.500" w={4} h={4} mt={0.5} />
        <Skeleton borderRadius="md" height="20px" width="260px" />
      </div>
    );

  if (error)
    return (
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-4">
          <AttioIcon color="gray.500" w={4} h={4} />
          <p className="text-sm">Error occurred</p>
        </div>
        <div className="flex items-center gap-2">
          <Button
            variant="ghost"
            isLoading={isFetching}
            onClick={onFetchObjects}
          >
            Try again
          </Button>
          <Tooltip label="Open settings" hasArrow placement="top">
            <Button
              size="xs"
              variant="ghost"
              onClick={() =>
                navigate(
                  `/a/${appId}/settings/integrations/attio/${appObject.objectType}`,
                )
              }
            >
              <Cog6ToothIcon className="h-4 w-4" />
            </Button>
          </Tooltip>
        </div>
      </div>
    );

  if (!isLoading && !isFetching && objects.length === 0)
    return (
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-2">
          <AttioIcon color="gray.500" w={4} h={4} mt={0.5} />
          <Button
            isDisabled={isCreatingObject}
            variant="ghost"
            onClick={onCreateObject}
          >
            <p>
              {isCreatingObject
                ? `Creating ${appObject?.singularName?.toLocaleLowerCase()}...`
                : `Create ${appObject?.singularName?.toLocaleLowerCase()} in Attio`}
            </p>
          </Button>
        </div>
        <Tooltip label="View settings" hasArrow placement="top">
          <Button
            size="xs"
            variant="ghost"
            onClick={() =>
              navigate(
                `/a/${appId}/settings/integrations/attio/${appObject.objectType}`,
              )
            }
          >
            <Cog6ToothIcon className="h-4 w-4" />
          </Button>
        </Tooltip>
      </div>
    );

  return (
    <div className="flex w-full justify-between">
      <div className="flex gap-2">
        <AttioIcon color="gray.500" w={4} h={4} mt={0.5} />
        <div>
          <div className="flex cursor-pointer items-center gap-2 hover:underline">
            <a
              href={`https://app.attio.com/${workspaceName}${route}${objects?.[0]?.id}`}
              target="_blank"
              rel="noreferrer"
              className="text-sm"
            >
              View {appObject?.singularName?.toLocaleLowerCase()} in Attio
            </a>
            <ArrowTopRightOnSquareIcon className="h-3 w-3" />
          </div>
          <p className="text-xs text-gray-500">
            Last synced at {moment.utc(lastSyncedAt).fromNow()}
          </p>
        </div>
      </div>
      <div className="flex items-center">
        <Tooltip
          label={isUpdatingObject ? "Resyncing..." : "Resync"}
          hasArrow
          placement="top"
        >
          <Button
            isLoading={isUpdatingObject}
            size="xs"
            variant="ghost"
            onClick={onUpdateObject}
          >
            <ArrowPathIcon className="h-4 w-4" />
          </Button>
        </Tooltip>
        <Tooltip label="Open settings" hasArrow placement="top">
          <Button
            size="xs"
            variant="ghost"
            onClick={() =>
              navigate(
                `/a/${appId}/settings/integrations/attio/${appObject.objectType}`,
              )
            }
          >
            <Cog6ToothIcon className="h-4 w-4" />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export const AttioProfileContainer: React.FC<{
  entity: IContact | IGroup;
  appObject: IAppObject;
}> = ({ entity, appObject }) => {
  const { hasAttioInstalled, isLoadingSetting } = useAttioSettings();

  if (isLoadingSetting)
    return <Skeleton borderRadius="lg" height="30px" width="100%" />;

  if (!hasAttioInstalled) return <UninstalledCard />;

  return <Profile entity={entity} appObject={appObject} />;
};
