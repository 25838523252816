import React from "react";
import { VStack } from "@chakra-ui/react";
import { IHomeMilestone, Milestone } from "./Milestone";

const PLACEHOLDER_MILESTONES = [
  {
    name: "Signed up",
    convertedCount: 0,
    percentage: 0,
    isPlaceholder: true,
  },
  {
    name: "Setup",
    convertedCount: 0,
    percentage: 0,
    isPlaceholder: true,
  },
  {
    name: "Used",
    convertedCount: 0,
    percentage: 0,
    isPlaceholder: true,
  },
  {
    name: "Aha!",
    convertedCount: 0,
    percentage: 0,
    isPlaceholder: true,
  },
  {
    name: "Activated",
    convertedCount: 0,
    percentage: 0,
    isPlaceholder: true,
  },
];

export const ActivationOverviewGraph: React.FC<{ data: IHomeMilestone[] }> = ({
  data,
}) => {
  const milestones = data;
  const milestonesToRender = milestones
    ? [...milestones, ...PLACEHOLDER_MILESTONES.slice(milestones.length, 6)]
    : PLACEHOLDER_MILESTONES;

  return (
    <VStack data-testid="milestones-body" mt={2} w="full" spacing={0}>
      {milestonesToRender?.map(
        (milestone, index) =>
          milestone && (
            <Milestone key={index} milestone={milestone} entityType={"user"} />
          ),
      )}
    </VStack>
  );
};
