import { Flex, Box, ComponentDefaultProps } from "@chakra-ui/react";

const Container: React.FC<ComponentDefaultProps> = ({ children }) => {
  return (
    <Box pl={12} pr={10} mr={4} mb={4} pt={4}>
      <Box>
        <Flex direction="column" borderRadius="md" position="relative">
          <Flex>{children}</Flex>
        </Flex>
      </Box>
    </Box>
  );
};
export default Container;
