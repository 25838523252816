import { useOnboardingChecklist } from "core/hooks/useOnboardingChecklist";
import {
  DataSource,
  IOnboardingChecklist,
} from "core/models/onboardingChecklist";

export type OnboardingStep = keyof Pick<
  IOnboardingChecklist,
  | "isIdentifyingUsers"
  | "isSendingEvents"
  | "isIdentifyingGroups"
  | "connectedDataSource"
>;

export type DocSection =
  | "main"
  | "identify"
  | "track"
  | "group"
  | "page"
  | "connectDataSource";

export type Docs = {
  [key in DocSection]: string | null;
};

export const DataSourceDocs: Record<DataSource, Docs> = {
  freshpaint: {
    main: "https://documentation.freshpaint.io/destinations/apps/june",
    identify: "https://documentation.freshpaint.io/destinations/apps/june",
    track: "https://documentation.freshpaint.io/destinations/apps/june",
    group: "https://documentation.freshpaint.io/destinations/apps/june",
    page: "https://documentation.freshpaint.io/destinations/apps/june",
    connectDataSource:
      "https://help.june.so/en/articles/6788912-how-to-connect-freshpaint-with-june",
  },
  rudderstack: {
    main: "https://www.rudderstack.com/docs/destinations/streaming-destinations/june/",
    identify:
      "https://www.rudderstack.com/docs/destinations/streaming-destinations/june/",
    track:
      "https://www.rudderstack.com/docs/destinations/streaming-destinations/june/",
    group:
      "https://www.rudderstack.com/docs/destinations/streaming-destinations/june/",
    page: "https://www.rudderstack.com/docs/destinations/streaming-destinations/june/",
    connectDataSource:
      "https://help.june.so/en/articles/6803296-rudderstack-integration",
  },
  sdk: {
    main: "https://www.june.so/docs",
    identify: "https://www.june.so/docs/quickstart/identify",
    track: "https://www.june.so/docs/quickstart/track-event",
    group: "https://www.june.so/docs/quickstart/identify",
    page: "https://www.june.so/docs/tracking/web/browser",
    connectDataSource: null,
  },
  segment: {
    main: "https://segment.com/docs",
    identify: "https://segment.com/docs/connections/spec/identify/",
    track: "https://segment.com/docs/connections/spec/track/",
    group: "https://segment.com/docs/connections/spec/group/",
    page: "https://segment.com/docs/connections/spec/page/",
    connectDataSource:
      "https://help.june.so/en/articles/4804643-how-to-connect-segment",
  },
};

const stepToDocSection: Record<OnboardingStep, DocSection> = {
  isIdentifyingUsers: "identify",
  isSendingEvents: "track",
  isIdentifyingGroups: "group",
  connectedDataSource: "connectDataSource",
};

export const useDocs = () => {
  const { dataSource } = useOnboardingChecklist();
  const docs = DataSourceDocs[dataSource ?? "sdk"];
  return {
    docs,
    getLinkByStep: (step: OnboardingStep): string | null => {
      const key = stepToDocSection[step];
      return docs[key];
    },
    getLinkByDocSection: (section: DocSection): string | null => docs[section],
    getMainLinkBySource: (): string | null => docs?.main,
  };
};
