import { groupBy } from "lodash";
import { TRAIT_LEVEL } from "core/constants/traits";
import { ITraitWithConfig } from "../types/Trait";
import {
  useGetComputedTraitsQuery,
  usePinTraitMutation,
} from "../models/computedTraits";

function extractByLevel(traits: ITraitWithConfig[], traitLevel: TRAIT_LEVEL) {
  const filteredTraits = traits.filter((trait) => trait.level === traitLevel);
  const groupedTraits = groupBy(filteredTraits, "name");

  // there can be multiple instances of the same trait, we are taking the first not archived trait if there is one
  const traitByKey = Object.fromEntries(
    Object.entries(groupedTraits).map(([name, traits]) => [
      name,
      traits.find((t) => !t.isArchived) ?? traits[0],
    ]),
  );

  const pinnedTraits = filteredTraits.filter(
    (t) => traitByKey[t.name]?.isPinned,
  );
  const unpinnedTraits = filteredTraits.filter(
    (t) => !traitByKey[t.name]?.isPinned,
  );
  const hasPinnedAnyTraits = filteredTraits?.some((t) => t.isPinned) ?? false;

  return {
    traits: filteredTraits,
    traitByKey,
    pinnedTraits,
    unpinnedTraits,
    hasPinnedAnyTraits,
  };
}

export const usePinnedTraits = (
  appId: number,
  traitLevel: TRAIT_LEVEL,
): {
  isFetching: boolean;
  traits: ITraitWithConfig[];
  traitByKey: Record<string, ITraitWithConfig>;
  pinTrait: (name: string, isPinned: boolean) => Promise<void>;
  pinnedTraits: ITraitWithConfig[];
  unpinnedTraits: ITraitWithConfig[];
  hasPinnedAnyTraits: boolean;
} => {
  const { data, isFetching } = useGetComputedTraitsQuery({ appId });
  const [pinTrait] = usePinTraitMutation();

  const allTraits = data ?? [];
  const extracted = extractByLevel(allTraits, traitLevel);

  return {
    isFetching,
    pinTrait: (name, isPinned) =>
      pinTrait({
        appId,
        level: traitLevel,
        name,
        isPinned,
      }).unwrap(),
    traits: extracted.traits,
    traitByKey: extracted.traitByKey,
    pinnedTraits: extracted.pinnedTraits,
    unpinnedTraits: extracted.unpinnedTraits,
    hasPinnedAnyTraits: extracted.hasPinnedAnyTraits,
  };
};
