import { ComponentDefaultProps, Flex } from "@chakra-ui/react";
import { Loader } from "./Loader";

export interface IBodyProps extends ComponentDefaultProps {
  isLoading?: boolean;
}

export const Body: React.FC<IBodyProps> = ({
  children,
  isLoading,
  ...props
}) => {
  if (isLoading) return <Loader />;
  return (
    <Flex
      h="65%"
      data-testid="highlight-card-body"
      align="flex-end"
      my={0}
      mr={6}
      pb={6}
      {...props}
    >
      {children}
    </Flex>
  );
};
