import React from "react";
import { PropertyList } from "modules/Contacts/EventLog/PropertyList";
import { IDENTIFY } from "core/constants/events";
import {
  Box,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  StyleProps,
  Text,
} from "@chakra-ui/react";

interface IPropertiesCellProps extends StyleProps {
  props: {
    event?: { [key: string]: any };
    eventType?: number;
    properties: { [key: string]: any };
  };
}

function PropertiesCell({
  props: { event, eventType, properties },
  ...styleProps
}: IPropertiesCellProps) {
  const title = eventType === IDENTIFY ? "Traits" : "Properties";
  return (
    <Popover trigger="hover" placement="right">
      <PopoverTrigger>
        <Box
          width={styleProps.width ? styleProps.width : "100px"}
          minW={styleProps.minW ? styleProps.minW : "100px"}
        >
          <Flex align="flex-end" justify="flex-start" {...styleProps}>
            <Flex align="center">
              <Text
                borderBottomWidth="1px"
                borderStyle="dashed"
                borderColor="gray.300"
                fontSize="sm"
                color="gray.600"
              >
                {properties ? Object.keys(properties).length : 0}{" "}
                {title.toLowerCase()}
              </Text>
            </Flex>
          </Flex>
        </Box>
      </PopoverTrigger>
      <PopoverContent
        color="white"
        bg="gray.800"
        borderColor="gray.900"
        borderRadius="lg"
      >
        <PopoverBody>
          <PropertyList
            eventType={eventType}
            eventName={event?.name}
            properties={properties}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default PropertiesCell;
