export const completionRateMockData = {
  milestonesOverview: [
    {
      milestone: "milestone1",
      name: "Sign up",
      maxCohortSize: 156,
    },
    {
      milestone: "milestone2",
      name: "Setup",
      maxCohortSize: 156,
    },
  ],
  completionRates: [
    {
      date: "2022-06-27",
      cohortSize: 61,
      milestone1: "Sign up",
      milestone1ConvertedCount: 61,
      milestone1DroppedCount: 0,
      milestone1ConversionPercentage: 100.0,
      milestone2: "Setup",
      milestone2ConvertedCount: 19,
      milestone2DroppedCount: 42,
      milestone2ConversionPercentage: 31.14,
    },
    {
      date: "2022-07-04",
      cohortSize: 147,
      milestone1: "Sign up",
      milestone1ConvertedCount: 147,
      milestone1DroppedCount: 0,
      milestone1ConversionPercentage: 100.0,
      milestone2: "Setup",
      milestone2ConvertedCount: 34,
      milestone2DroppedCount: 113,
      milestone2ConversionPercentage: 23.12,
    },
    {
      date: "2022-07-11",
      cohortSize: 144,
      milestone1: "Sign up",
      milestone1ConvertedCount: 144,
      milestone1DroppedCount: 0,
      milestone1ConversionPercentage: 100.0,
      milestone2: "Setup",
      milestone2ConvertedCount: 34,
      milestone2DroppedCount: 110,
      milestone2ConversionPercentage: 23.61,
    },
    {
      date: "2022-07-18",
      cohortSize: 149,
      milestone1: "Sign up",
      milestone1ConvertedCount: 149,
      milestone1DroppedCount: 0,
      milestone1ConversionPercentage: 100.0,
      milestone2: "Setup",
      milestone2ConvertedCount: 39,
      milestone2DroppedCount: 110,
      milestone2ConversionPercentage: 26.17,
    },
    {
      date: "2022-07-25",
      cohortSize: 156,
      milestone1: "Sign up",
      milestone1ConvertedCount: 156,
      milestone1DroppedCount: 0,
      milestone1ConversionPercentage: 100.0,
      milestone2: "Setup",
      milestone2ConvertedCount: 46,
      milestone2DroppedCount: 110,
      milestone2ConversionPercentage: 29.48,
    },
    {
      date: "2022-08-01",
      cohortSize: 153,
      milestone1: "Sign up",
      milestone1ConvertedCount: 153,
      milestone1DroppedCount: 0,
      milestone1ConversionPercentage: 100.0,
      milestone2: "Setup",
      milestone2ConvertedCount: 32,
      milestone2DroppedCount: 121,
      milestone2ConversionPercentage: 20.91,
    },
    {
      date: "2022-08-08",
      cohortSize: 146,
      milestone1: "Sign up",
      milestone1ConvertedCount: 146,
      milestone1DroppedCount: 0,
      milestone1ConversionPercentage: 100.0,
      milestone2: "Setup",
      milestone2ConvertedCount: 30,
      milestone2DroppedCount: 116,
      milestone2ConversionPercentage: 20.54,
    },
    {
      date: "2022-08-15",
      cohortSize: 136,
      milestone1: "Sign up",
      milestone1ConvertedCount: 136,
      milestone1DroppedCount: 0,
      milestone1ConversionPercentage: 100.0,
      milestone2: "Setup",
      milestone2ConvertedCount: 26,
      milestone2DroppedCount: 110,
      milestone2ConversionPercentage: 19.11,
    },
    {
      date: "2022-08-22",
      cohortSize: 148,
      milestone1: "Sign up",
      milestone1ConvertedCount: 148,
      milestone1DroppedCount: 0,
      milestone1ConversionPercentage: 100.0,
      milestone2: "Setup",
      milestone2ConvertedCount: 36,
      milestone2DroppedCount: 112,
      milestone2ConversionPercentage: 24.32,
    },
    {
      date: "2022-08-29",
      cohortSize: 139,
      milestone1: "Sign up",
      milestone1ConvertedCount: 139,
      milestone1DroppedCount: 0,
      milestone1ConversionPercentage: 100.0,
      milestone2: "Setup",
      milestone2ConvertedCount: 30,
      milestone2DroppedCount: 109,
      milestone2ConversionPercentage: 21.58,
    },
    {
      date: "2022-09-05",
      cohortSize: 141,
      milestone1: "Sign up",
      milestone1ConvertedCount: 141,
      milestone1DroppedCount: 0,
      milestone1ConversionPercentage: 100.0,
      milestone2: "Setup",
      milestone2ConvertedCount: 42,
      milestone2DroppedCount: 99,
      milestone2ConversionPercentage: 29.78,
    },
    {
      date: "2022-09-12",
      cohortSize: 121,
      milestone1: "Sign up",
      milestone1ConvertedCount: 121,
      milestone1DroppedCount: 0,
      milestone1ConversionPercentage: 100.0,
      milestone2: "Setup",
      milestone2ConvertedCount: 22,
      milestone2DroppedCount: 99,
      milestone2ConversionPercentage: 18.18,
    },
    {
      date: "2022-09-19",
      cohortSize: 51,
      milestone1: "Sign up",
      milestone1ConvertedCount: 51,
      milestone1DroppedCount: 0,
      milestone1ConversionPercentage: 100.0,
      milestone2: "Setup",
      milestone2ConvertedCount: 3,
      milestone2DroppedCount: 48,
      milestone2ConversionPercentage: 5.88,
    },
  ],
};
