import { useState } from "react";
import { AddFilterType } from "helpers/filters";
import { IFilter, IFilterGroup } from "core/types/Report";
import { OperatorMenu } from "core/modules/audience/Filters/OperatorMenu";
import { useReportSetup } from "core/hooks/useReportSetup";
import { IS } from "core/constants/traitFilterComponents";
import { INTERVAL_TYPE_NUMBERS } from "core/constants/timerange";
import {
  FILTER_TYPE_TO_TRAIT_TYPE,
  FilterType,
  JoinOperatorValue,
  MORE_THAN,
  TimerangeType,
} from "core/constants/report-setup";
import { FIRST_EVENT } from "core/constants/events";
import AddAudienceFilterButton from "../Audience/AddFilterButton";
import { Filter } from "core/modules/audience/Filters/Filter";
import { uuid } from "core/helpers/uuid";

interface IFiltersProps {
  section: {
    type: FilterType;
  };
  filterGroup: IFilterGroup;
  filterGroupIndex: number;
  view?: string;
  truncate?: boolean;
}

export const Filters: React.FC<IFiltersProps> = ({
  section,
  filterGroup,
  filterGroupIndex,
  view,
  truncate,
}) => {
  const [highlight, setHighlight] = useState(false);
  const {
    currentReport,
    isCurrentlyAddingFilter,
    isUsingEventFilters,
    addAudienceFilterGroup,
    addAudienceFilter,
    updateJoinOperator,
    updateFilterGroupJoinOperator,
    removeAudienceFilter,
    updateAudience,
  } = useReportSetup();

  if (!currentReport || !currentReport.audience?.filterGroups) return null;

  const filterGroups = currentReport?.audience?.filterGroups;
  const joinOperator = currentReport?.audience?.joinOperator;

  const onChangeJoinOperator = (
    addFilterType: AddFilterType,
    filterGroupIndex: number,
    operator: JoinOperatorValue,
  ) => {
    if (addFilterType === AddFilterType.FilterGroup) {
      updateJoinOperator({ operator: operator });
    } else {
      updateFilterGroupJoinOperator({
        filterGroupIndex: filterGroupIndex,
        operator: operator,
      });
    }
  };

  function getNextFilterId() {
    if (!filterGroup?.filters) return 0;

    const previousId =
      filterGroup?.filters?.[filterGroup?.filters?.length - 1]?.id;

    if (previousId === undefined) return 0;
    if (typeof previousId === "string") return uuid();
    return previousId + 1;
  }

  function onAddEventFilter(
    addFilterType: AddFilterType,
    filterGroupIndex: number,
    filterType: string,
  ) {
    const filter = {
      id: getNextFilterId(),
      adding: true,
      _type: section,
      filterGroup,
      filterGroupIndex,
      filterType: filterType,
      type: FILTER_TYPE_TO_TRAIT_TYPE[filterType],
      body: {
        ...FIRST_EVENT,
        times: 0,
        interval: 7,
        intervalType: INTERVAL_TYPE_NUMBERS.day,
        comparisonType: MORE_THAN,
        timerangeType: TimerangeType.RELATIVE,
        trait: "",
        value: "",
      },
    };

    if (addFilterType === AddFilterType.FilterGroup) {
      const filterGroup = {
        filters: [filter],
        joinOperator: JoinOperatorValue.AND,
      };
      addAudienceFilterGroup({ filterGroup });
    } else {
      addAudienceFilter({ filterGroupIndex, filter });
    }
  }

  function onAddTraitFilter(
    addFilterType: AddFilterType,
    filterGroupIndex: number,
    filterType: string,
    trait: string,
  ) {
    const filter = {
      id: getNextFilterId(),
      adding: true,
      _type: section.type,
      filterType: filterType,
      type: FILTER_TYPE_TO_TRAIT_TYPE[filterType],
      body: { comparisonType: IS, trait, value: "" },
    };
    if (addFilterType === AddFilterType.FilterGroup) {
      const filterGroup = {
        filters: [filter],
        joinOperator: JoinOperatorValue.AND,
      };
      addAudienceFilterGroup({ filterGroup });
    } else {
      addAudienceFilter({ filterGroupIndex, filter });
    }
  }

  return (
    <div className="flex items-center">
      <div className="flex flex-wrap items-center gap-y-2 rounded-lg">
        {filterGroup?.filters?.map((filter: IFilter, filterIndex: number) => {
          return (
            <Filter
              filter={filter}
              filterIndex={filterIndex}
              filterGroupIndex={filterGroupIndex}
              audienceFilters={currentReport.audience}
              setAudienceFilters={(audience) => {
                updateAudience(audience);
              }}
              onRemoveFilter={(f) => {
                removeAudienceFilter({ filterGroupIndex, filter: f });
              }}
              onChangeJoinOperator={(operator) => {
                onChangeJoinOperator(
                  AddFilterType.Filter,
                  filterGroupIndex,
                  operator,
                );
              }}
              highlight={highlight}
              setHighlight={setHighlight}
              isLast={filterIndex === filterGroup?.filters?.length - 1}
              isFirst={filterIndex === 0}
              view={view}
              truncate={truncate}
            />
          );
        })}
        {filterGroup?.filters?.length > 0 && (
          <AddAudienceFilterButton
            isCurrentlyAddingFilter={isCurrentlyAddingFilter}
            isEventFilterDisabled={isUsingEventFilters}
            onAddTraitFilter={onAddTraitFilter}
            onAddEventFilter={onAddEventFilter}
            isDisabled={
              currentReport?.audience?.isSavedAudience ||
              isCurrentlyAddingFilter
            }
            addFilterType={AddFilterType.Filter}
            filterGroupIndex={filterGroupIndex}
            filterIndex={0}
          />
        )}
        {filterGroupIndex !== filterGroups.length - 1 &&
          !!filterGroups[filterGroupIndex].filters.length && (
            <OperatorMenu
              joinOperator={joinOperator}
              addFilterType={AddFilterType.FilterGroup}
              onChangeJoinOperator={(operator) => {
                onChangeJoinOperator(
                  AddFilterType.FilterGroup,
                  filterGroupIndex,
                  operator,
                );
              }}
              bg="white"
              ml={2}
            />
          )}
      </div>
    </div>
  );
};
