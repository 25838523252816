export enum Frequency {
  HOURLY = 0,
  DAILY = 1,
  FIVE_MINUTES = 2,
}

export const HUMANIZED_FREQUENCY = {
  [Frequency.HOURLY]: "Hourly",
  [Frequency.DAILY]: "Daily",
  [Frequency.FIVE_MINUTES]: "Five minutes",
};
