import { useReportSetup } from "core/hooks/useReportSetup";
import { uuid } from "core/helpers/uuid";
import { Box, Flex } from "@chakra-ui/react";
import { TraitPicker } from "../Breakdown/TraitPicker";
import { Pill } from "../Breakdown/Pill";
import BreakdownMenu from "../Breakdown/Menu";

export default function BreakdownPicker() {
  const { currentReport, addBreakdown } = useReportSetup();
  const { breakdown } = currentReport?.config;

  function onTraitTypeSelected(traitType: string) {
    addBreakdown({
      breakdown: {
        uuid: uuid(),
        traitType: traitType,
        adding: true,
        trait: undefined,
      },
    });
  }

  return (
    <Box gridGap={2}>
      {breakdown && (
        <>
          {breakdown.uuid && breakdown.adding && (
            <Flex gridGap={2} direction="column">
              <TraitPicker breakdown={breakdown} />
            </Flex>
          )}
          {breakdown.uuid && !breakdown.adding && (
            <Pill isRemoveable={true} breakdown={breakdown} />
          )}
        </>
      )}
      <Flex mt={5}>
        <BreakdownMenu
          isCurrentlyAddingFilter={breakdown?.adding}
          onFilterSelected={onTraitTypeSelected}
          isDisabled={breakdown?.adding || breakdown?.trait}
        />
      </Flex>
    </Box>
  );
}
