import { useEffect, useState } from "react";
import moment from "moment";
import { IReportInsight } from "core/types/Report";
import useInsightDataFetching from "core/hooks/useInsightDataFetching";
import { getContactEmail } from "core/helpers/contactEmail";
import { isScreenshotMode } from "core/design-system/constants/charts";
import { LoadMoreButton } from "core/design-system/components/LoadMoreButton";
import { IListConfig } from "core/components/List/List.types";
import InsightCard from "core/components/InsightCard/Index";
import { CSVExport } from "core/components/CSVExport";
import { Flex } from "@chakra-ui/react";
import { ISlippingAwayUsersData } from "./SlippingAway.types";
import { mockData } from "./mockData";
import { LastEventReceived } from "./LastEventReceived";
import FirstSeenDateCell from "./FirstSeenDateCell";
import ContactLink from "./ContactLink";
import List from "../../../../components/List";

export interface IGetListConfigArgs {
  events: (string | undefined)[];
  previewMode: boolean;
  sharingMode: boolean;
}

const getListConfig = ({
  events,
  previewMode,
  sharingMode,
}: IGetListConfigArgs): IListConfig => ({
  resourceName: "users",
  columns: [
    {
      title: "",
      name: "email",
      Component: ContactLink,
      align: "left",
      previewMode: previewMode,
      sharingMode: sharingMode,
    },
    {
      title: "Last event",
      name: "daysSinceLastEvent",
      Component: LastEventReceived,
      align: "left",
      sortable: true,
    },
    {
      title: "First seen",
      name: "firstSeenAt",
      Component: FirstSeenDateCell,
      align: "left",
    },
    {
      title: "Total events",
      name: "eventCount",
      align: "center",
      fontSize: "sm",
      sortable: true,
      tooltip: `Amount of times user performed ${events.join(", ")} all time`,
    },
  ],
});

export default function UserList({
  sharingSecretToken = null,
  previewMode,
  sharingMode,
  report,
  config,
  screenshotMode,
  showFooter = true,
  showActions = true,
  showReportTitle = false,
  colSpan = 2,
}: IReportInsight) {
  const [page, setPage] = useState(0);
  const [slippingAwayUsers, setSlippingAwayUsers] =
    useState<ISlippingAwayUsersData>([]);
  const [sortedBy, setSortedBy] = useState({
    name: "daysSinceLastEvent",
    direction: "asc",
  });
  const insight = config.insights.find(
    ({ slug }: { slug: string }) => slug === "slipping-away-users",
  );
  const isSetupValid = config.validateSetup(report.config);

  const events = report?.config?.events?.map(({ name }) => name) || [];
  const { response, isFetching, error, onRefreshInsight } =
    useInsightDataFetching({
      report,
      insight,
      sharingSecretToken,
      previewMode,
      insightType: insight?.typeId,
      insightParams: {
        limit: isScreenshotMode ? 5 : 30,
        page,
      },
      skip: !isSetupValid,
    });

  const loadNextUsers = () => {
    if (slippingAwayUsers.length < 100) setPage(page + 1);
  };

  const rowData = slippingAwayUsers
    .map((v) => ({ ...v, sharingMode: sharingMode }))
    .sort((a, b) => {
      if (sortedBy.direction === "asc")
        return a[sortedBy.name as keyof ISlippingAwayUsersData[0]] >
          b[sortedBy.name as keyof ISlippingAwayUsersData[0]]
          ? 1
          : -1;

      return a[sortedBy.name as keyof ISlippingAwayUsersData[0]] <
        b[sortedBy.name as keyof ISlippingAwayUsersData[0]]
        ? 1
        : -1;
    });

  const rowDataToCSV = rowData.map((row) => [
    getContactEmail(row),
    row.daysSinceLastEvent,
    moment.utc(row.firstSeenAt).format("MMMM DD, YYYY"),
    row.eventCount,
  ]);

  const headers = [
    "email",
    "last_event_received",
    "first_seen",
    "last_seen",
    "total_events",
  ];

  useEffect(() => {
    if (previewMode) {
      return setSlippingAwayUsers(
        screenshotMode ? mockData.slice(0, 5) : mockData,
      );
    }
    if (response?.data && !isFetching) {
      const users =
        page === 0 ? response.data : [...slippingAwayUsers, ...response.data];
      setSlippingAwayUsers(users);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, page, screenshotMode]);

  if (!insight) return null;

  return (
    <InsightCard>
      {(Card: any) => (
        <>
          <Card.Container insight={insight} id={insight.slug} colSpan={colSpan}>
            <Card.Header
              hasCaching={response?.hasCaching}
              screenshotMode={screenshotMode}
              insight={insight}
              showActions={!sharingMode && !previewMode && showActions}
              sharingMode={sharingMode}
              mb={0}
              config={config}
              report={report}
              refresh={onRefreshInsight}
              cachedAt={response?.cachedAt}
              showReportTitle={showReportTitle || sharingMode}
            >
              <Flex>
                <CSVExport
                  disabled={previewMode || sharingMode}
                  data={rowDataToCSV}
                  headers={headers}
                  filename={`${report?.name}-${insight.slug}.csv`}
                />
              </Flex>
            </Card.Header>
            <Card.Body
              insight={insight}
              isLoading={isFetching}
              isPreviewMode={previewMode}
              isSetupValid={isSetupValid}
              error={error}
            >
              <List
                id="loaded"
                border="none"
                rows={rowData}
                config={getListConfig({
                  events,
                  previewMode,
                  sharingMode,
                })}
                sortedBy={sortedBy}
                setSortedBy={setSortedBy}
              />
            </Card.Body>
          </Card.Container>
          {!previewMode && !sharingMode && !screenshotMode && showFooter && (
            <Flex w="100%" justifyContent="center" gridColumn="span 2">
              <LoadMoreButton isLoading={isFetching} onClick={loadNextUsers} />
            </Flex>
          )}
        </>
      )}
    </InsightCard>
  );
}
