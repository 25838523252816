import { useGetPropertiesQuery } from "core/models/properties";
import SmallAddIcon from "core/design-system/components/Icon/Interface/SmallAddIcon";
import Icon from "core/design-system/components/Icon";
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { PropertyValueList } from "../../../../core/modules/reports/setup/Editor/PropertyValuePicker";
import { usePropertyValues } from "../../../../core/hooks/usePropertyValues";

interface IPropertyFiltersProps {
  appId: number;
  eventId: number;
  property: string | undefined;
  propertyValue: string | undefined;
  propertyComparisonOperator: string | undefined;
  setProperty: (property: string | undefined) => void;
  setPropertyValue: (value: string | undefined) => void;
  setPropertyComparisonOperator: (value: string | undefined) => void;
}

export const PropertyFilters: React.FC<IPropertyFiltersProps> = ({
  appId,
  eventId,
  property,
  propertyValue,
  propertyComparisonOperator,
  setProperty,
  setPropertyValue,
  setPropertyComparisonOperator,
}) => {
  const { data: properties } = useGetPropertiesQuery({
    appId,
    eventId,
  });

  const { data, isLoading, hasMore, loadMore, searchTerm, setSearchTerm } =
    usePropertyValues({
      appId,
      eventId,
      property: property ?? "",
    });

  const handleRemoveFilter = () => {
    setProperty(undefined);
  };

  return (
    <Box>
      <Flex align="center">
        {!property || property === "" ? (
          <Menu>
            <MenuButton
              color="purple.500"
              variant="ghost"
              leftIcon={<SmallAddIcon h={4} w={4} color="purple.500" />}
              as={Button}
              isDisabled={!properties || properties?.length === 0}
            >
              Add a property
            </MenuButton>
            <MenuList minWidth="200px">
              {properties?.map(({ property }) => (
                <MenuItem
                  onClick={() => {
                    setProperty(property);
                  }}
                  key={property}
                >
                  <Text fontSize="sm">{property}</Text>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        ) : (
          <>
            <Menu>
              <MenuButton
                bg="gray.100"
                color="gray.900"
                _hover={{ bg: "gray.200" }}
                border="none"
                borderRightRadius="0px"
                as={Button}
                rightIcon={<ChevronDownIcon />}
              >
                {property}
              </MenuButton>
              <MenuList minWidth="200px">
                {properties?.map(({ property }) => (
                  <MenuItem
                    onClick={() => {
                      setProperty(property);
                    }}
                    key={property}
                  >
                    <Text fontSize="sm">{property}</Text>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
            <Menu>
              <MenuButton
                bg="gray.100"
                color="gray.900"
                _hover={{ bg: "gray.200" }}
                border="none"
                borderRadius="0px"
                isDisabled={!property}
                as={Button}
                rightIcon={<ChevronDownIcon />}
              >
                {propertyComparisonOperator === "is" ? "is" : "is not"}
              </MenuButton>
              <MenuList minWidth="200px">
                <MenuItem
                  onClick={() => setPropertyComparisonOperator("is")}
                  key={"is"}
                >
                  is
                </MenuItem>
                <MenuItem
                  onClick={() => setPropertyComparisonOperator("is_not")}
                  key={"is not"}
                >
                  is not
                </MenuItem>
              </MenuList>
            </Menu>

            <Menu>
              <MenuButton
                bg="gray.100"
                color="gray.900"
                _hover={{ bg: "gray.200" }}
                border="none"
                borderRadius="0px"
                isDisabled={!property}
                as={Button}
                rightIcon={<ChevronDownIcon />}
              >
                {propertyValue ? propertyValue : "Select a value"}
              </MenuButton>
              <MenuList minWidth="200px" maxH="200px" overflowY="scroll" p={0}>
                <PropertyValueList
                  isLoading={isLoading}
                  hasMore={hasMore}
                  propertyValues={data}
                  onChange={(value: string) => setPropertyValue(value)}
                  value={propertyValue ?? null}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  onLoadMore={loadMore}
                  ItemComponent={MenuItem}
                />
              </MenuList>
            </Menu>
            <Tooltip label="Remove filter" placement="top" hasArrow>
              <Button
                data-testid="audience-remove-trait-button"
                bg="gray.100"
                color="gray.900"
                _hover={{ bg: "gray.200" }}
                borderLeftRadius="0px"
                border="none"
                onClick={handleRemoveFilter}
                borderLeft="none"
              >
                <Icon iconType="icon" name="close" h={2} w={2} />
              </Button>
            </Tooltip>
          </>
        )}
      </Flex>
    </Box>
  );
};
