import { useNavigate } from "react-router-dom";
import React from "react";
import FeatureReleaseEmptyStateIllustration from "core/design-system/components/Icon/Illustrations/FeatureReleaseEmptyState.svg";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { FEATURE_RELEASE } from "core/constants/report-configurations";

export const EmptyState: React.FC<{ appId: number }> = ({ appId }) => {
  const navigate = useNavigate();

  return (
    <Flex direction="column" justifyContent="center" h="100%">
      <Box
        alignItems="center"
        justifyContent="flex-end"
        backgroundImage={FeatureReleaseEmptyStateIllustration}
        backgroundPosition="center 16px"
        backgroundRepeat="no-repeat"
        h="200px"
      />
      <Text fontSize="lg" fontWeight="medium" textAlign="center">
        Want to know how often users are using your feature?
      </Text>
      <Text fontSize="md" color="gray.600" textAlign="center">
        Set up a feature release report to find out
      </Text>
      <Flex justify={"center"}>
        <Button
          mt={4}
          size="md"
          colorScheme="purple"
          onClick={() => {
            window.analytics.track(
              "report_created_from_home",
              {
                app_id: appId,
              },
              { context: { groupId: appId } },
            );
            navigate(`/a/${appId}/report/create/${FEATURE_RELEASE}`);
          }}
        >
          Set up report
        </Button>
      </Flex>
    </Flex>
  );
};
