import React from "react";
import { createIcon } from "@chakra-ui/icons";

const SegmentIcon = createIcon({
  path: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path
        d="M0.162436 9.37133C0.129955 9.31942 0.108652 9.26158 0.0998316 9.20135C0.0910108 9.14111 0.0948572 9.07975 0.111136 9.02102C0.755167 6.52923 2.196 4.30333 4.22395 2.66725C5.80935 1.39152 7.70488 0.537455 9.72705 0.187717C11.7492 -0.162021 13.8295 0.00442048 15.7666 0.670928C15.8247 0.691277 15.878 0.722851 15.9233 0.763768C15.9687 0.804685 16.0051 0.854107 16.0305 0.909088C16.0776 1.02133 16.0776 1.14716 16.0305 1.2594L15.4399 2.79787C15.4007 2.90743 15.3186 2.99732 15.2117 3.04779C15.1047 3.09825 14.9817 3.10518 14.8695 3.06704C13.3486 2.54864 11.7168 2.42201 10.1313 2.69934C8.54577 2.97667 7.06014 3.64862 5.81765 4.65037C4.24312 5.9385 3.12482 7.67954 2.62216 9.62533C2.59719 9.7212 2.54036 9.80626 2.46059 9.86716C2.38081 9.92805 2.28261 9.96133 2.18138 9.96178H2.07878L0.458764 9.58113C0.399034 9.56729 0.342803 9.54174 0.293487 9.50602C0.244171 9.47029 0.2028 9.42514 0.171886 9.37331"
        fill="inherit"
      />
      <path
        d="M23.677 13.1944C23.6396 13.1473 23.5927 13.108 23.5394 13.079C23.486 13.05 23.4272 13.0318 23.3665 13.0255L21.7094 12.856C21.5919 12.8451 21.4746 12.879 21.3821 12.9507C21.2896 13.0224 21.2291 13.1263 21.2132 13.2406C20.9181 15.4412 19.8399 17.4701 18.1676 18.9716C16.88 20.1393 15.2828 20.93 13.5578 21.2537C11.8328 21.5773 10.0495 21.421 8.41107 20.8023C8.35625 20.7813 8.29772 20.771 8.23883 20.7721C8.17995 20.7732 8.12186 20.7856 8.06788 20.8086C8.0139 20.8317 7.9651 20.8649 7.92427 20.9064C7.88343 20.9478 7.85136 20.9968 7.82989 21.0504L7.18391 22.5895C7.15954 22.6447 7.14697 22.7042 7.14697 22.7643C7.14697 22.8245 7.15954 22.884 7.18391 22.9392C7.20624 22.9956 7.24061 23.0467 7.28472 23.0891C7.32883 23.1316 7.38167 23.1643 7.43974 23.1853C8.82463 23.7187 10.3002 23.9916 11.7888 23.9895C14.7985 23.9894 17.6971 22.8779 19.9037 20.8775C22.0306 18.9499 23.3947 16.3516 23.7567 13.5388C23.7651 13.4791 23.7611 13.4183 23.7449 13.3601C23.7287 13.3019 23.7005 13.2476 23.6622 13.2004"
        fill="inherit"
      />
      <path
        d="M14.5274 15.4328V13.775C14.5281 13.6556 14.4804 13.5409 14.3948 13.4558C14.3092 13.3707 14.1925 13.3223 14.0704 13.3211H0.521005C0.399494 13.3231 0.283722 13.372 0.198805 13.4569C0.113887 13.5419 0.0666714 13.6562 0.0674007 13.775V15.4328C0.0668667 15.492 0.0782683 15.5506 0.100953 15.6055C0.123638 15.6603 0.157161 15.7102 0.199605 15.7524C0.242049 15.7945 0.29258 15.8281 0.348308 15.8512C0.404036 15.8742 0.463868 15.8863 0.52438 15.8867H14.0704C14.1309 15.8863 14.1908 15.8742 14.2465 15.8512C14.3022 15.8281 14.3527 15.7945 14.3952 15.7524C14.4376 15.7102 14.4712 15.6603 14.4938 15.6055C14.5165 15.5506 14.5279 15.492 14.5274 15.4328Z"
        fill="inherit"
      />
      <path
        d="M9.31503 7.75566V9.41354C9.31394 9.53219 9.36086 9.64646 9.44556 9.73147C9.53026 9.81648 9.6459 9.86534 9.76729 9.86742H23.3153C23.3758 9.86691 23.4356 9.85476 23.4912 9.83166C23.5469 9.80857 23.5973 9.775 23.6397 9.73285C23.682 9.6907 23.7155 9.64081 23.7382 9.58602C23.7608 9.53123 23.7722 9.47262 23.7716 9.41354V7.75566C23.7688 7.63892 23.7196 7.52782 23.6345 7.44593C23.5493 7.36403 23.4348 7.31777 23.3153 7.31695H9.76526C9.64387 7.31903 9.52824 7.3679 9.44354 7.4529C9.35883 7.53791 9.31192 7.65218 9.31301 7.77083"
        fill="inherit"
      />
      <path
        d="M19.2178 2.25954C18.9431 2.25501 18.6731 2.33021 18.4421 2.47562C18.2111 2.62103 18.0294 2.83012 17.92 3.07646C17.8106 3.3228 17.7784 3.59531 17.8275 3.85953C17.8766 4.12375 18.0047 4.36782 18.1957 4.56086C18.3867 4.75391 18.632 4.88726 18.9006 4.94405C19.1691 5.00084 19.4489 4.97852 19.7044 4.87992C19.96 4.78131 20.1799 4.61085 20.3364 4.39009C20.4928 4.16932 20.5787 3.90818 20.5833 3.63968C20.5833 3.63242 20.5833 3.6245 20.5833 3.61724C20.5855 3.26039 20.443 2.91722 20.1869 2.66279C19.9309 2.40836 19.5822 2.26337 19.2171 2.25954"
        fill="inherit"
      />
      <path
        d="M4.13769 18.6754C3.86297 18.6708 3.59304 18.746 3.36205 18.8915C3.13105 19.0369 2.94936 19.246 2.83996 19.4923C2.73056 19.7386 2.69836 20.0111 2.74743 20.2754C2.7965 20.5396 2.92464 20.7837 3.11564 20.9767C3.30665 21.1697 3.55193 21.3031 3.82048 21.3599C4.08903 21.4167 4.36878 21.3944 4.62435 21.2958C4.87992 21.1972 5.09983 21.0267 5.25627 20.8059C5.41271 20.5852 5.49866 20.324 5.50323 20.0555V20.0324C5.50486 19.6759 5.36225 19.3332 5.10646 19.079C4.85067 18.8248 4.50246 18.6797 4.13769 18.6754Z"
        fill="inherit"
      />
    </svg>
  ),
  viewBox: "0 0 24 24",
});

export default SegmentIcon;
