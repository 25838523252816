import { useCallback, useEffect, useState } from "react";

/**
 * Split an array into chunks of size `viewSize` and progressively load more chunks.
 *
 * Usage:
 *
 *   const [dataView, hasMoreData, loadMoreData] = useView([1,2,3,4], 10);
 */
export const useView = <T,>(
  data: T[] | undefined,
  viewSize: number,
): [T[], boolean, () => void] => {
  const [dataView, setDataView] = useState<T[]>([]);
  const [dataIndex, setDataIndex] = useState<number>(viewSize);
  const [hasMore, setHasMore] = useState<boolean>(false);

  /**
   * Set data view to contain only a portion of the data.
   *
   */
  useEffect(() => {
    if (data) setDataView(data.slice(0, dataIndex));
  }, [data, dataIndex]);

  /**
   * Compute if the data view has more data that could be added.
   *
   */
  useEffect(() => {
    if (data && dataIndex < data.length) setHasMore(true);
    else setHasMore(false);
  }, [data, dataIndex]);

  /**
   * Load more data into the view.
   *
   */
  const loadMore = useCallback(() => {
    if (hasMore) {
      setDataIndex(dataIndex + viewSize);
    }
  }, [hasMore, dataIndex, viewSize]);

  return [dataView, hasMore, loadMore];
};
