export const overviewMockData = {
  audienceCount: 203,
  milestones: [
    {
      name: "Signed up",
      converted_count: 203,
      dropped_count: 0,
      percentage: 100,
    },
    {
      name: "Setup",
      converted_count: 132,
      dropped_count: 71,
      percentage: 65.02,
    },
    {
      name: "Used",
      converted_count: 203,
      dropped_count: 0,
      percentage: 100,
    },
    {
      name: "Aha!",
      converted_count: 203,
      dropped_count: 0,
      percentage: 100,
    },
    {
      name: "Activated",
      converted_count: 132,
      dropped_count: 71,
      percentage: 65.02,
    },
  ],
};
