import { Accordion, StyleProps } from "@chakra-ui/react";

interface ISetupSectionsProps extends StyleProps {
  index: number;
  children?: React.ReactNode;
}

const SetupSections: React.FC<ISetupSectionsProps> = ({
  children,
  index,
  ...props
}) => {
  return (
    <Accordion
      data-testid="setup-sections-accordion"
      defaultIndex={index}
      index={index}
      allowToggle
      {...props}
    >
      {children}
    </Accordion>
  );
};

export default SetupSections;
