import { useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { PublicReport } from "core/modules/reports/PublicReport";
import { useGetReportQuery } from "core/models/reports";
import { getReportConfig } from "core/helpers/getReportConfig";
import Loader from "Components/Loader";
import ErrorPage from "Components/Error/Page";
import { useAppObjects } from "core/hooks/useAppObjects";
import { getAppObject } from "core/modules/appObjects/utils";
import { getLevelParam } from "core/modules/reports/utils";
import { useGetAppObjectsQuery } from "core/models/appObjects";

function PublicReportRoute() {
  const { appId, reportId, sharingSecretToken } = useParams<{
    [key: string]: string;
  }>() as {
    appId: string;
    reportId: string;
    sharingSecretToken: string;
  };
  const {
    data: report,
    isFetching,
    error,
  } = useGetReportQuery({ appId, reportId, sharingSecretToken });
  const { data } = useGetAppObjectsQuery({
    appId: Number(appId),
    reportId: Number(reportId),
    sharingSecretToken,
  });
  const { appObjects, setAppObjects, setActiveAppObject } = useAppObjects();
  const level = getLevelParam();
  const appObject = getAppObject(appObjects, level);
  if (appObject) setActiveAppObject(appObject);

  useEffect(() => {
    if (data) setAppObjects(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isFetching) return <Loader />;
  if (error) {
    return (
      <ErrorPage
        title="You don't have permissions to view this report"
        action={{
          text: "Log in",
          href: `${import.meta.env.VITE_APP_HOST}/login`,
        }}
      />
    );
  }
  if (report && report?.reportType > -1) {
    return (
      <PublicReport
        appId={Number(appId)}
        report={report}
        config={getReportConfig(report.reportType)!}
        sharingSecretToken={sharingSecretToken}
      />
    );
  }

  return <ErrorPage />;
}

export default PublicReportRoute;
