import React, { useEffect, useState } from "react";
import { EventAlertThresholdEditor } from "modules/Events/List/EventAlertThresholdEditor";
import { EventAlertEditorTextArea } from "modules/Events/List/EventAlertEditorTextArea";
import { IAlert } from "core/types/Alert";
import { useSlack } from "core/hooks/useSlack";
import { useEditEventAlert } from "core/hooks/useEditEventAlert";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import Icon from "core/design-system/components/Icon";
import { SLACK_REDIRECT_URL_KEY } from "core/constants/integrations";
import { FREQUENCIES, IAlertFrequencyEnum } from "core/constants/alert";
import { TooltipBody } from "core/components/TooltipBody";
import SlackChannels from "core/components/Slack/SlackChannels";
import Message from "core/components/Slack/Message";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Link,
  Select,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import RenderedMessagePreview from "./RenderedMessagePreview";
import { EventAlertEditorHeading } from "./EventAlertEditorHeading";
import { AdvancedSettings } from "./EventAlertEditor/AdvancedSettings";

export enum ThresholdType {
  Recurring = "recurring",
  OneTime = "one_time",
}

interface IEventAlertEditorProps {
  eventId: number;
  alert: IAlert;
  onClose: () => void;
}

interface IEventAlertCTAProps {
  isEnabled: boolean;
  onSaveChanges: () => void;
  isSetupUpdated: boolean;
  hasSlackAppInstalled: boolean;
  appId: number;
  eventId: number;
  hasInvalidAudience: boolean;
}

const buildEventDigestMessagePreview = (frequency: { value: number }) => {
  switch (frequency.value) {
    case 0:
      return "{{event.name}} happened **X times** yesterday";
    case 1:
      return "{{event.name}} happened **X times** last week";
    case 2:
      return "{{event.name}} happened **X times** last month";
    default:
      return "";
  }
};

const EventAlertCTA: React.FC<IEventAlertCTAProps> = ({
  isEnabled,
  onSaveChanges,
  isSetupUpdated,
  hasSlackAppInstalled,
  appId,
  eventId,
  hasInvalidAudience,
}) => {
  if (!hasSlackAppInstalled) {
    return (
      <Button
        colorScheme="purple"
        onClick={() => {
          localStorage.setItem(
            SLACK_REDIRECT_URL_KEY,
            `/a/${appId}/event/${eventId}?showSlack=true`,
          );
          window.location.replace(
            `${import.meta.env.VITE_API_HOST}/auth/slack`,
          );
        }}
      >
        Connect Slack
      </Button>
    );
  }

  if (hasInvalidAudience) {
    return (
      <Button isDisabled colorScheme="purple">
        <Tooltip
          label={
            <TooltipBody text="The same event cannot be used both as a trigger and as an audience for an alert. Remove this event from your audience." />
          }
          placement="bottom"
          borderRadius="lg"
        >
          {isSetupUpdated && isEnabled
            ? "Update"
            : isEnabled
              ? "Pause"
              : "Set live"}
        </Tooltip>
      </Button>
    );
  }

  if (isSetupUpdated && isEnabled)
    return (
      <Button colorScheme="purple" onClick={onSaveChanges}>
        Update
      </Button>
    );

  if (isEnabled && !isSetupUpdated)
    return <Button onClick={onSaveChanges}>Pause</Button>;

  return (
    <Button colorScheme="green" onClick={onSaveChanges}>
      Set live
    </Button>
  );
};

const EventAlertEditor: React.FC<IEventAlertEditorProps> = ({
  alert,
  eventId,
}) => {
  const {
    newSetup,
    frequency,
    slackChannelId,
    messageBody,
    onChangeChannel,
    onChangeMessageBody,
    save,
    isSetupUpdated,
    setFrequency,
    hasInvalidAudience,
    setThresholdType,
    setThreshold,
    threshold,
    thresholdType,
  } = useEditEventAlert(alert);
  const { id: appId, receivingGroupContext } = useCurrentApp();
  const { hasSlackAppInstalled } = useSlack();
  const { state } = alert;
  const [showGroupContextWarning, setShowGroupContextWarning] =
    useState<boolean>(false);
  const isEnabled = state === 1;

  useEffect(() => {
    setShowGroupContextWarning(
      !receivingGroupContext && messageBody.includes("{{company"),
    );
  }, [receivingGroupContext, messageBody]);

  if (!frequency || !alert) return null;

  async function onSaveChanges() {
    save({
      newState: isSetupUpdated && isEnabled ? 1 : isEnabled ? 0 : 1,
    });
  }

  return (
    <>
      <Flex w="full" mb={4} justify="flex-end">
        <EventAlertCTA
          isEnabled={isEnabled}
          onSaveChanges={onSaveChanges}
          isSetupUpdated={isSetupUpdated}
          hasSlackAppInstalled={hasSlackAppInstalled}
          appId={Number(appId)}
          eventId={eventId}
          hasInvalidAudience={hasInvalidAudience}
        />
      </Flex>
      <Accordion allowToggle allowMultiple>
        <AccordionItem pb={4}>
          {({ isExpanded }) => (
            <>
              <AccordionButton>
                <VStack align={"flex-start"} my={0}>
                  <EventAlertEditorHeading
                    title="Where to receive the alert"
                    stepNumber={1}
                  />
                  {!isExpanded && (
                    <Box
                      bg={
                        slackChannelId && hasSlackAppInstalled
                          ? "gray.100"
                          : "red.50"
                      }
                      color={
                        slackChannelId && hasSlackAppInstalled
                          ? "gray.900"
                          : "red.500"
                      }
                      fontSize="sm"
                      fontWeight="medium"
                      px={3}
                      py={1}
                      borderRadius={4}
                    >
                      {!hasSlackAppInstalled && "Connect your Slack account"}
                      {hasSlackAppInstalled &&
                        slackChannelId &&
                        "Channel selected"}
                      {hasSlackAppInstalled &&
                        !slackChannelId &&
                        "Select a channel"}
                    </Box>
                  )}
                </VStack>
              </AccordionButton>
              <AccordionPanel>
                <Flex direction="column" pb={3}>
                  <Text mb={0} fontWeight="medium" fontSize="sm">
                    Slack channel
                  </Text>
                  <Text mb={2} fontSize="sm" color="gray.600">
                    The channel you wish to receive the event alert in
                  </Text>
                  {!hasSlackAppInstalled ? (
                    <Box>
                      <Button
                        colorScheme="purple"
                        variant="ghost"
                        onClick={() => {
                          localStorage.setItem(
                            SLACK_REDIRECT_URL_KEY,
                            `/a/${appId}/event/${eventId}?showSlack=true`,
                          );
                          window.location.replace(
                            `${import.meta.env.VITE_API_HOST}/auth/slack`,
                          );
                        }}
                      >
                        Connect Slack to select a channel
                      </Button>
                    </Box>
                  ) : (
                    <SlackChannels
                      onChange={onChangeChannel}
                      defaultValue={slackChannelId || ""}
                    />
                  )}
                </Flex>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
        <AccordionItem pb={4}>
          {({ isExpanded }) => (
            <>
              <AccordionButton>
                <VStack align={"flex-start"} my={0}>
                  <EventAlertEditorHeading
                    title="When to receive the alert"
                    stepNumber={2}
                  />
                  {!isExpanded && (
                    <Box
                      bg="gray.100"
                      color="gray.900"
                      fontSize="sm"
                      fontWeight="medium"
                      px={3}
                      py={1}
                      borderRadius={4}
                    >
                      {frequency.label}
                    </Box>
                  )}
                </VStack>
              </AccordionButton>
              <AccordionPanel>
                <Flex direction="column" py={3}>
                  <Text mb={0} fontWeight="medium" fontSize="sm">
                    Frequency
                  </Text>
                  <Text mb={2} fontSize="sm" color="gray.600">
                    How frequently you wish to receive the event
                  </Text>
                  <Select
                    size="sm"
                    w="md"
                    borderRadius={"lg"}
                    value={frequency.value}
                    onChange={(e) => {
                      const newValue = Number(
                        e.target.value,
                      ) as IAlertFrequencyEnum;
                      setFrequency(newValue);
                    }}
                  >
                    {FREQUENCIES.map((f, i) => (
                      <option key={i} value={f.value}>
                        {f.label} - {f.description}
                      </option>
                    ))}
                  </Select>
                </Flex>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
        <AccordionItem pb={4}>
          {({ isExpanded }) => (
            <>
              <AccordionButton>
                <VStack w="full" align={"flex-start"} my={0}>
                  <EventAlertEditorHeading title="Message" stepNumber={3} />
                  {!isExpanded && (
                    <Flex w="full">
                      <Flex w="full" bg="purple.100" borderRadius="lg" p={3}>
                        <Message
                          body={
                            <RenderedMessagePreview
                              messageBody={
                                frequency.value !== 3
                                  ? buildEventDigestMessagePreview(frequency)
                                  : messageBody
                              }
                              eventId={alert.alertableId}
                            />
                          }
                        />
                      </Flex>
                    </Flex>
                  )}
                </VStack>
              </AccordionButton>
              <AccordionPanel>
                <VStack align={"start"} my={0} py={0}>
                  <Flex direction="column" py={3}>
                    <Flex direction="column">
                      <Flex>
                        <Text mb={0} fontWeight="medium" fontSize="sm">
                          Message content
                        </Text>
                        <Tooltip
                          label={
                            <TooltipBody
                              header="How it works"
                              text="You can customize the Slack message using contact.traits, company.traits, and event.properties. For example, if you collect emails for your contacts, names of your companies, and image URLs for your events, you could specify:

                    {{contact.traits.email}} from {{company.traits.name}} just rendered a photo {{event.properties.imageUrl}}"
                            />
                          }
                          placement="bottom"
                          borderRadius="lg"
                        >
                          <Box ml={2}>
                            <Icon iconType="icon" name="question-purple" />
                          </Box>
                        </Tooltip>
                      </Flex>
                      <Flex>
                        <Text mb={2} fontSize="sm" color="gray.600">
                          The content of the alert you wish to receive.{" "}
                          <Link
                            href="https://api.slack.com/reference/surfaces/formatting"
                            isExternal
                            textDecoration={"underline"}
                            _hover={{ color: "gray.500" }}
                          >
                            See which formats Slack support.
                          </Link>
                        </Text>
                      </Flex>
                    </Flex>
                    <EventAlertEditorTextArea
                      w="100%"
                      rows={3}
                      focusBorderColor="purple.300"
                      onChange={onChangeMessageBody}
                      defaultValue={newSetup.messageBody}
                      isDisabled={
                        frequency.value !== IAlertFrequencyEnum.Instant
                      }
                      showGroupContextWarning={showGroupContextWarning}
                      fontSize="sm"
                    />
                  </Flex>
                  <Flex w="full" direction="column">
                    <Text mb={0} fontWeight="medium" fontSize="sm">
                      Preview
                    </Text>
                    <Text mb={2} fontSize="sm" color="gray.600">
                      This is a preview of the event alert you will receive
                    </Text>
                    <Flex bg="purple.100" borderRadius="lg" p={3}>
                      <Message
                        body={
                          <Flex>
                            <RenderedMessagePreview
                              messageBody={
                                frequency.value !== 3
                                  ? buildEventDigestMessagePreview(frequency)
                                  : messageBody
                              }
                              eventId={alert.alertableId}
                            />
                          </Flex>
                        }
                      />
                    </Flex>
                  </Flex>
                </VStack>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>
            <EventAlertEditorHeading title="Advanced" stepNumber={4} />
          </AccordionButton>
          <AccordionPanel>
            <AdvancedSettings alert={alert} />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <AccordionButton>
                <EventAlertEditorHeading title="Threshold" stepNumber={5} />
              </AccordionButton>
              {isExpanded ? (
                <AccordionPanel>
                  <EventAlertThresholdEditor
                    threshold={threshold}
                    thresholdType={thresholdType}
                    setThresholdType={setThresholdType}
                    setThreshold={setThreshold}
                  />
                </AccordionPanel>
              ) : (
                <div className="mb-5 ml-5">
                  <p className="inline rounded bg-gray-100 px-3 py-2 text-sm font-medium">
                    {thresholdType === ThresholdType.Recurring
                      ? "Every time"
                      : `Exactly ${threshold} time${threshold > 1 ? "s" : ""}`}
                  </p>
                </div>
              )}
            </>
          )}
        </AccordionItem>
      </Accordion>
      <Box h={24} />
    </>
  );
};

export default EventAlertEditor;
