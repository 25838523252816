import React from "react";
import { capitalize } from "lodash";
import { motion } from "framer-motion";
import MixpanelIllustration from "core/design-system/components/Icon/Illustrations/MixpanelImport.svg";
import AmplitudeIllustration from "core/design-system/components/Icon/Illustrations/AmplitudeImport.svg";
import { AMPLITUDE, MIXPANEL } from "core/constants/apiKeys";
import { Flex, Link, Text, useDisclosure } from "@chakra-ui/react";
import { PlayerModal } from "./PlayerModal";

export const GetStarted: React.FC<{
  sourceName: typeof AMPLITUDE | typeof MIXPANEL;
}> = ({ sourceName }) => {
  const {
    isOpen: isPlayerModalOpen,
    onOpen: onPlayerModalOpen,
    onClose: onPlayerModalClose,
  } = useDisclosure({ defaultIsOpen: false });

  const illustration =
    sourceName === "amplitude" ? AmplitudeIllustration : MixpanelIllustration;
  const link =
    sourceName === "amplitude"
      ? "https://help.june.so/en/articles/5649446-how-to-import-your-amplitude-data-to-june"
      : "https://help.june.so/en/articles/6788339-how-to-import-your-mixpanel-data-to-june";
  const videoLink =
    sourceName === "amplitude"
      ? "https://www.loom.com/embed/3b1f38be6a5646aba1773d72befde31b"
      : "https://www.loom.com/embed/cd7456f42ce3457e93502381ad34b099";

  const videoTitle =
    sourceName === "amplitude"
      ? "how-to-import-your-amplitude-data-to-june"
      : "how-to-import-your-mixpanel-data-to-june";

  return (
    <>
      <Flex
        alignItems="center"
        boxShadow="sm"
        border="1px solid"
        borderColor="gray.100"
        borderRadius="lg"
        w="100%"
        p={5}
        gap={5}
      >
        <motion.div
          whileHover={{
            scale: 1.02,
            transition: { duration: 0.1 },
          }}
          onClick={onPlayerModalOpen}
          style={{
            width: "280px",
            height: "112px",
            cursor: "pointer",
            borderRadius: "12px",
            backgroundImage: `url(${illustration})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        ></motion.div>
        <Flex direction="column">
          <Text fontWeight="medium" color="gray.700">
            Get started
          </Text>
          <Text fontSize="sm" color="gray.600">
            Learn how to import your {capitalize(sourceName)} data into June in
            less than 5 minutes.
          </Text>
          <Text fontSize="sm" color="gray.600">
            Once it's processed, you'll get an email confirmation. Depending on
            the size of your data, imports can take up to a few hours.{" "}
            <Link href={link} isExternal>
              Read full doc.
            </Link>
          </Text>
        </Flex>
      </Flex>
      <PlayerModal
        videoLink={videoLink}
        videoTitle={videoTitle}
        isOpen={isPlayerModalOpen}
        onClose={onPlayerModalClose}
      />
    </>
  );
};
