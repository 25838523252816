import React, { useState } from "react";
import { IAudience } from "core/types/Audience";
import { SavedAudienceTooltipPreview } from "core/modules/reports/setup/Audience/SavedAudienceTooltipPreview";
import { useGetAudiencesQuery } from "core/models/audiences";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import {
  Button,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from "@chakra-ui/react";

interface IAddSavedAudienceButtonProps {
  isDisabled: boolean;
  onSelectSavedAudience: ({ audience }: { audience: IAudience }) => void;
}

export const AddSavedAudienceButton: React.FC<IAddSavedAudienceButtonProps> = ({
  isDisabled = false,
  onSelectSavedAudience,
}) => {
  const currentApp = useCurrentApp();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { data } = useGetAudiencesQuery({
    appId: currentApp.id,
    query: searchQuery,
  });
  const savedAudiences = data?.audiences || [];

  return (
    <Menu>
      <Tooltip
        shouldWrapChildren
        label={
          isDisabled
            ? "You can only use a single Saved Audience in a report"
            : "View your saved audiences"
        }
      >
        <MenuButton
          isDisabled={isDisabled}
          as={Button}
          variant="ghost"
          colorScheme="gray"
          fontSize="sm"
        >
          View all
        </MenuButton>
      </Tooltip>
      <MenuList transition="ease" py={0} borderRadius="lg">
        <Input
          mb={2}
          size="sm"
          placeholder="Search your audiences"
          borderTopRadius="lg"
          onChange={(e) => setSearchQuery(e.currentTarget.value)}
        />
        {savedAudiences.map((audience) => (
          <MenuItem onClick={() => onSelectSavedAudience({ audience })}>
            <Tooltip
              w="full"
              maxW="900px"
              bg="gray.900"
              placement="left"
              hasArrow
              shouldWrapChildren
              label={<SavedAudienceTooltipPreview audience={audience} />}
            >
              <Flex align="center" justifyContent="space-between">
                <Text noOfLines={1} fontSize="sm">
                  {audience.name}
                </Text>
              </Flex>
            </Tooltip>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
