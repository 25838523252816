import React from "react";
import colors from "core/design-system/constants/theme/colors";
import { SimpleAreaChart } from "core/components/ViewInsight/Graphs/SimpleAreaChart";
import { IRetentionInsightUser } from "../ChurningUsersContainer";

export const ActivityCell: React.FC<{
  props: IRetentionInsightUser;
  previewMode: boolean;
  sharingMode: boolean;
  additionalProps: { userType: string };
}> = ({ props, previewMode, sharingMode, additionalProps }) => {
  const data = props?.threeMonthWeeklyActivity?.map(
    (activity: [string, number]) => ({
      x: activity[0],
      y: activity[1],
    }),
  );

  return (
    <div className="relative h-10 w-full">
      <SimpleAreaChart
        data={data}
        showTicks={false}
        margin={{ right: 20, top: 0, bottom: -20, left: -120 }}
        color={
          additionalProps.userType === "churning"
            ? colors.orange
            : colors.purple
        }
      />
    </div>
  );
};
