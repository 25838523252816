import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Business,
  CompanySize,
  DeletionRequestState,
  IApp,
  Plan,
  ReferralSource,
  SubscriptionState,
} from "core/types/App";
import { useGetAppsQuery } from "core/models/apps";
import { setCurrentApp } from "core/models/app";
import { useUserAuth } from "core/hooks/useUserAuth";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { DEMO_APP_ID } from "core/constants/appIds";

export const LOCAL_STORAGE_DEMO_APP_KEY = `demo_app_enabled`;
export const DEMO_WORKSPACE_APP = {
  id: DEMO_APP_ID,
  name: "Stripe Demo",
  idCode: "123",
  features: ["usage-based-pricing", "new-funnel-setup", "ai-gpt4-2"],
  createdAt: "2023-06-05T09:18:03.324Z",
  timeZone: "UTC",
  utcOffset: 0,
  groupContextEnabled: false,
  hasImpersonation: false,
  companyDomain: "example.com",
  autoJoinCompanyWorkspaceEnabled: true,
  onboardingState: 1,
  onboarded: false,
  subscriptionState: "paying" as SubscriptionState,
  isTrialing: false,
  isPaying: true,
  isPaymentOverdue: false,
  isFree: false,
  committeeMember: false,
  receivingGroupContext: false,
  monthlyEventCount: 747,
  monthlyUserCount: 148,
  currentBillingUserCount: 0,
  isCurrentBillingGuardrail: false,
  segmentConnected: false,
  firstSegmentTokenCreatedAt: "2023-06-05T09:18:03.324Z",
  enforceGoogleOauth: false,
  billingPeriodStartDate: "2023-06-05T09:18:03.324Z",
  billingPeriodEndDate: "2023-06-05T09:18:03.324Z",
  noCardTrialEnded: false,
  logo: undefined,
  business: "b2b" as Business,
  companySize: "1-5" as CompanySize,
  referralSource: "word_of_mouth" as ReferralSource,
  userCount: 1,
  hasRequestedAiAccess: false,
  aiOptedIn: true,
  plan: Plan.Pro,
  isFirstWeek: false,
  trialDaysRemaining: 0,
  computedTraitLimit: 20,
  deletionRequestState: DeletionRequestState.NOT_REQUESTED,
} as IApp;

interface IUseDemoParams {
  redirectPath?: string;
}

export const useDemo = (params: IUseDemoParams | void) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: appId } = useCurrentApp();
  const { currentUser } = useUserAuth();
  const { data: apps } = useGetAppsQuery();
  const redirectPath = params?.redirectPath;

  const toggleDemo = () => {
    if (!apps) return;

    const demoApp = DEMO_WORKSPACE_APP;
    const isCurrentlyUsingDemo = appId === DEMO_APP_ID;
    const redirectToApp = isCurrentlyUsingDemo
      ? currentUser?.lastUsedApp
      : demoApp;
    const LOCAL_STORAGE_DEMO_APP_VALUE = isCurrentlyUsingDemo
      ? "false"
      : "true";

    if (!isCurrentlyUsingDemo) {
      window.analytics.track(
        "toggled_demo_workspace",
        {
          app_id: appId,
          from: "sidebar",
        },
        { context: { groupId: appId } },
      );
    }

    if (redirectToApp) {
      dispatch(setCurrentApp({ app: redirectToApp }));
      navigate(
        `/a/${redirectToApp.id}/${isCurrentlyUsingDemo && redirectPath ? redirectPath : "home"}`,
      );
      localStorage.setItem(
        LOCAL_STORAGE_DEMO_APP_KEY,
        LOCAL_STORAGE_DEMO_APP_VALUE,
      );
    }
  };

  return {
    toggleDemo,
  };
};
