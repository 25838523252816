import React from "react";

interface IconProps {
  className?: string;
}

export const PageViewIcon: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.25 10.875V8.688C13.2504 8.31857 13.1779 7.95268 13.0368 7.61128C12.8956 7.26987 12.6885 6.95966 12.4273 6.69838C12.1661 6.43711 11.856 6.2299 11.5146 6.08861C11.1733 5.94733 10.8074 5.87474 10.438 5.875H9.188C9.06487 5.87513 8.94292 5.85099 8.82912 5.80396C8.71532 5.75693 8.61191 5.68794 8.52479 5.60091C8.43768 5.51389 8.36857 5.41055 8.32142 5.29681C8.27427 5.18306 8.25 5.06113 8.25 4.938V3.688C8.25039 3.31857 8.17794 2.95268 8.03677 2.61128C7.89561 2.26987 7.68851 1.95966 7.42733 1.69838C7.16614 1.43711 6.856 1.2299 6.51465 1.08861C6.1733 0.947326 5.80744 0.874738 5.438 0.875001H3.875M8.235 12.109L9.5 13.375M8.235 12.109C8.64542 11.6987 8.87505 11.1422 8.87514 10.5619C8.87524 9.98152 8.64479 9.42492 8.2345 9.0145C7.82421 8.60408 7.26768 8.37345 6.68735 8.37336C6.10702 8.37326 5.55042 8.60371 5.14 9.014C4.72958 9.42429 4.49895 9.98082 4.49886 10.5611C4.49876 11.1415 4.72921 11.6981 5.1395 12.1085C5.54979 12.5189 6.10632 12.7495 6.68665 12.7496C7.26698 12.7497 7.82458 12.5193 8.235 12.109ZM5.75 0.875001H1.688C1.43939 0.875529 1.20111 0.974523 1.02532 1.15032C0.849523 1.32611 0.750528 1.56439 0.75 1.813V15.563C0.75 16.08 1.17 16.5 1.688 16.5H12.313C12.83 16.5 13.25 16.08 13.25 15.563V8.375C13.25 6.38588 12.4598 4.47822 11.0533 3.0717C9.64678 1.66518 7.73912 0.875001 5.75 0.875001Z"
        stroke="currentColor"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
