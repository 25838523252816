import React, { useEffect, useState } from "react";
import { EditableSourceMenu } from "modules/Settings/Crm/SyncSettings/EditableSourceMenu";
import { Integration, IProperty } from "core/models/dataMappings";
import { IAppObject } from "core/models/appObjects";
import { IDataMapping, ITempDataMapping } from "core/hooks/useDataMappings";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { SelectDestination } from "./SelectDestination";

interface IMappingRowProps {
  dataMapping: IDataMapping | ITempDataMapping;
  currentProperties: IProperty[];
  properties: IProperty[];
  page: number;
  search: string;
  hasMore: boolean;
  setSearch: (query: string) => void;
  setPage: (page: number) => void;
  dataMappings: IDataMapping[];
  deleteTempMapping: (juneTrait: string) => void;
  integration: Integration;
  appObject: IAppObject;
}

export const MappingRow: React.FC<IMappingRowProps> = ({
  dataMapping,
  currentProperties,
  properties,
  hasMore,
  page,
  search,
  setPage,
  setSearch,
  dataMappings,
  deleteTempMapping,
  integration,
  appObject,
}) => {
  function findExternalProperty() {
    return (
      dataMapping &&
      properties.find((hp) => hp.name === dataMapping.destination)
    );
  }

  const usedExternalProperties = dataMappings.map((dm) => dm.destination);
  const [externalProperty, setExternalProperty] = useState<
    IProperty | undefined
  >(findExternalProperty());

  useEffect(() => {
    if (!externalProperty && currentProperties.length > 0) {
      setExternalProperty(findExternalProperty());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProperties.length, externalProperty]);

  return (
    <div className="flex w-full flex-row items-center justify-between">
      <div className="w-[200px]">
        <EditableSourceMenu
          appObject={appObject}
          integration={integration}
          dataMapping={dataMapping}
        />
      </div>
      <div>
        <ArrowRightIcon className="h-4" />
      </div>
      <div className="flex w-[200px] max-w-[200px] flex-row justify-end">
        <SelectDestination
          setExternalProperty={setExternalProperty}
          dataMapping={dataMapping}
          externalProperty={externalProperty}
          deleteTempMapping={deleteTempMapping}
          setSearchValue={setSearch}
          searchValue={search}
          properties={currentProperties}
          setPage={setPage}
          page={page}
          hasMore={hasMore}
          usedExternalProperties={usedExternalProperties}
          integration={integration}
          appObject={appObject}
        />
      </div>
    </div>
  );
};
