import React from "react";
import { IReportInsight } from "core/types/Report";
import { isUserReportLevel } from "core/modules/reports/utils";
import useInsightDataFetching from "core/hooks/useInsightDataFetching";
import { useAppObjects } from "core/hooks/useAppObjects";
import colors from "core/design-system/constants/theme/colors";
import TickText from "core/design-system/charts/TickText";
import {
  CommonCustomTooltip,
  CommonGrid,
  CommonTooltip,
  CommonXAxis,
  CommonYAxis,
} from "core/design-system/charts/Common";
import { Area, AreaChart } from "core/design-system/charts/AreaChart";
import InsightCard from "core/components/InsightCard/Index";
import { RadioIcon } from "@heroicons/react/24/outline";
import { Center, Flex, Text } from "@chakra-ui/react";

const mockData = {
  retentionData: [
    {
      x: "W0",
      y: 100,
    },
    {
      x: "W1",
      y: 45.38,
    },
    {
      x: "W2",
      y: 41.7,
    },
    {
      x: "W3",
      y: 38.93,
    },
    {
      x: "W4",
      y: 32.05,
    },
    {
      x: "W5",
      y: 34.89,
    },
    {
      x: "W6",
      y: 25.56,
    },
    {
      x: "W7",
      y: 28.38,
    },
    {
      x: "W8",
      y: 25.18,
    },
    {
      x: "W9",
      y: 25,
    },
    {
      x: "W10",
      y: 17.85,
    },
    {
      x: "W11",
      y: 16.12,
    },
    {
      x: "W12",
      y: 0,
    },
  ],
};

const RetentionContainer: React.FC<IReportInsight> = ({
  previewMode,
  sharingMode,
  screenshotMode,
  sharingSecretToken,
  report,
  config,
  showYAxis,
  reportLevel,
}) => {
  const isUserReport = isUserReportLevel(reportLevel);
  const unit = isUserReport ? "user" : "company";
  const insight = config.insights.find(
    ({ slug }: { slug: string }) => slug === `${unit}-feature-retention`,
  );
  const isSetupValid = config.validateSetup(report.config);
  const { response, isLoading, isFetching, error, onRefreshInsight } =
    useInsightDataFetching({
      report,
      insight,
      sharingSecretToken,
      previewMode,
      skip: !isSetupValid,
    });
  const { activeAppObject } = useAppObjects();
  const data = previewMode ? mockData : response?.data;

  if (!insight) return null;

  return (
    <InsightCard>
      {(Card) => (
        <Card.Container insight={insight} id={insight?.slug}>
          <Card.Header
            hasCaching={response?.hasCaching}
            screenshotMode={screenshotMode}
            showReportTitle={sharingMode}
            insight={insight}
            showActions={!sharingMode && !previewMode}
            sharingMode={sharingMode}
            config={config}
            report={report}
            refresh={onRefreshInsight}
            cachedAt={response?.cachedAt}
          />
          <Card.Body
            isLoading={!data || isLoading || isFetching}
            isPreviewMode={previewMode}
            isSetupValid={isSetupValid}
            error={error}
          >
            {data?.retentionData?.length > 0 ? (
              <Flex h="300px" w="100%">
                <AreaChart
                  margin={{
                    top: 30,
                    left: showYAxis ? 0 : -30,
                    right: 50,
                    bottom: 20,
                  }}
                  height="100%"
                  data={data?.retentionData}
                >
                  <defs>
                    <linearGradient
                      id="purpleGradient"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop
                        offset="5%"
                        stopColor={colors.purple[400]}
                        stopOpacity={0.8}
                      />
                      <stop
                        offset="95%"
                        stopColor={colors.purple[100]}
                        stopOpacity={0.4}
                      />
                    </linearGradient>
                  </defs>
                  <CommonGrid />
                  {/* @ts-ignore */}
                  <CommonXAxis dataKey="x" />
                  {/* @ts-ignore */}
                  <CommonYAxis
                    // @ts-ignore
                    tick={({ x, ...props }) =>
                      showYAxis && (
                        // @ts-ignore
                        <TickText x={x - 5} {...props}>
                          {props.payload.value} %
                        </TickText>
                      )
                    }
                  />
                  {/* @ts-ignore */}
                  <CommonTooltip
                    content={
                      <CommonCustomTooltip
                        // @ts-ignore
                        yAxisLabelAffix={`% of ${
                          isUserReport
                            ? "users"
                            : activeAppObject?.pluralName.toLowerCase()
                        } returned`}
                      />
                    }
                  />
                  <Area
                    dataKey="y"
                    type="monotone"
                    fillOpacity={1}
                    fill="url(#purpleGradient)"
                  />
                </AreaChart>
              </Flex>
            ) : (
              <Center h="300px">
                <Flex direction="column" align="center" gridGap={5}>
                  <Flex bg="purple.100" p={1} borderRadius="lg">
                    <RadioIcon
                      style={{ color: colors.purple[500], height: "30px" }}
                    />
                  </Flex>
                  <Flex direction="column" textAlign="center">
                    <Text fontSize="sm" fontWeight="medium">
                      Feature retention is not ready yet
                    </Text>
                    <Text fontSize="xs" color="gray.600">
                      Come back in 1 week to see how many users returned to use
                      your feature
                    </Text>
                  </Flex>
                </Flex>
              </Center>
            )}
          </Card.Body>
          {insight.description && !screenshotMode && (
            <Card.Footer>
              <Card.DescriptionAccordion insight={insight} />
            </Card.Footer>
          )}
        </Card.Container>
      )}
    </InsightCard>
  );
};

export default RetentionContainer;
