import { useAI } from "core/hooks/useAI";
import { RepeatIcon } from "@chakra-ui/icons";
import { IBlockComponent } from "../ConversationContainer";
import { Action } from "../Action";

export const TryAgain: React.FC<IBlockComponent> = ({
  delay,
  isReverse,
  children,
  ...props
}) => {
  const { ask } = useAI();
  const prompt =
    props?.prompt?.replace(`That didn't work. Try again. `, "") || "";
  return (
    <Action
      onClick={() =>
        ask(
          `That didn't work. Try again. ${prompt}`,
          props?.query,
          props?.error,
        )
      }
      delay={delay}
      isReverse={isReverse}
    >
      <RepeatIcon mr={2} /> Try again
    </Action>
  );
};
