import { cx } from "helpers/cx";
import { ITraitKeyResponse } from "core/models/traits";
import { TraitIcon } from "core/components/Traits/TraitIcon";
import { BoltIcon } from "@heroicons/react/24/solid";
import { Tooltip } from "@chakra-ui/react";

interface TraitOptionProps {
  trait: ITraitKeyResponse;
  isDisabled?: boolean;
}

export const TraitOption: React.FC<TraitOptionProps> = ({
  trait,
  isDisabled,
}) => {
  return (
    <div className="flex w-full">
      <div
        className={cx(
          `flex w-full items-center justify-between`,
          isDisabled ? "text-gray-500" : "text-gray-800",
        )}
      >
        <div className="flex w-full items-center gap-1">
          <TraitIcon traitName={trait.trait} />
          <p className="line-clamp-1 truncate text-xs font-medium normal-case tracking-normal">
            {trait.trait}
          </p>
        </div>
        <div className="flex">
          {trait.isComputed && (
            <Tooltip label="Computed trait" hasArrow placement="right">
              <BoltIcon className="h-3 w-3 text-purple-500" />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};
