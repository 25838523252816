import React, { useState } from "react";
import { AppObjectType, IAppObject } from "core/models/appObjects";
import { Integration } from "core/models/dataMappings";
import { TRAIT_LEVEL } from "core/constants/traits";
import { IContact } from "core/types/Contact";
import { IGroup } from "core/types/Group";
import { PreviewMenu } from "core/components/SmartTrait/PreviewMenu";
import { PreviewHeader } from "core/components/SmartTrait/PreviewHeader";
import { IDataMapping } from "core/hooks/useDataMappings";
import { ITrait } from "core/types/Trait";
import { CrmHeader } from "modules/Contacts/Contact/CrmHeader";

export const PreviewSyncContainer: React.FC<{
  appObject: IAppObject;
  integration: Integration;
  dataMappings: IDataMapping[];
}> = ({ appObject, integration, dataMappings }) => {
  const appObjectToLevel = {
    [AppObjectType.User]: TRAIT_LEVEL.USER,
    [AppObjectType.Company]: TRAIT_LEVEL.COMPANY,
    [AppObjectType.Group]: TRAIT_LEVEL.GROUP,
  };
  const [entity, setEntity] = useState<IContact | IGroup | undefined>();

  return (
    <div className="flex h-full flex-col gap-3 rounded-lg border bg-gray-50 p-4">
      <PreviewMenu
        level={appObjectToLevel[appObject.objectType]}
        entity={entity}
        setEntity={setEntity}
      />
      <PreviewHeader
        level={appObjectToLevel[appObject.objectType]}
        entityParam={entity}
      >
        {entity && appObject && (
          <CrmHeader
            entity={entity}
            appObject={appObject}
            integration={integration}
          />
        )}
      </PreviewHeader>

      <div className="flex flex-col gap-2 rounded-lg border bg-white p-6 shadow-sm">
        <p className="text-sm font-medium">Traits</p>
        {dataMappings.length === 0 && (
          <p className="text-center text-sm text-gray-500">No traits mapped</p>
        )}
        {entity &&
          dataMappings.map((dm) => (
            <div className="flex max-w-full flex-col gap-1 text-sm">
              <p className="text-gray-500">{dm.source}</p>
              <p className="truncate text-gray-700">
                {(entity?.traits as ITrait)[dm.source] || "-"}
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};
