import React from "react";
import moment from "moment";

import { Flex, Text } from "@chakra-ui/react";

interface IFirstSeenDateCellProps {
  props: { firstSeenAt: string };
}

export const FirstSeenDateCell: React.FC<IFirstSeenDateCellProps> = ({
  props: { firstSeenAt },
}) => {
  return (
    <Flex direction="column">
      <Text fontSize="sm">{moment(firstSeenAt).format("MMM DD, YYYY")}</Text>
      <Text fontSize="sm" color="gray.600">
        {moment(firstSeenAt).fromNow()}
      </Text>
    </Flex>
  );
};

export default FirstSeenDateCell;
