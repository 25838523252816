import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useRef } from "react";
import { IEvent } from "core/types/Event";
import { useExportEventCsvMutation } from "core/models/events";
import { useToast } from "core/hooks/useToast";
import { useEvent } from "core/hooks/useEvent";
import {
  ArrowDownTrayIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  StyleProps,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { IEventRow } from "./EventsList";

export type IEventAction = "delete";

interface IEventActionsMenuProps extends StyleProps {
  event: IEvent | IEventRow;
  actions?: IEventAction[];
}

interface IDeleteDialogProps {
  event: IEvent | IEventRow;
  isOpen: boolean;
  cancelRef: React.RefObject<any>;
  onClose: () => void;
}

const DeleteDialog: React.FC<IDeleteDialogProps> = ({
  event,
  isOpen,
  onClose,
  cancelRef,
}) => {
  const { appId } = useParams<{ appId: string }>() as {
    appId: string;
  };

  const navigate = useNavigate();
  const { onDeleteEvent } = useEvent();

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete {event.name}?
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>
              Delete all instances of this event until now. If you re-send this
              event, it will start showing up again.
            </Text>
            <Text pt={2}>
              It might take a few hours before this event disappears from your
              reports.
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              ml={2}
              colorScheme="red"
              onClick={() => {
                onDeleteEvent(event.id);
                navigate(`/a/${appId}/events`);
                onClose();
              }}
            >
              Delete event permanently
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export const EventActionsMenu: React.FC<IEventActionsMenuProps> = ({
  event,
  actions = ["delete"],
  ...styleProps
}) => {
  const [exportEventCsv] = useExportEventCsvMutation();
  const toast = useToast();
  const cancelRef = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onExportEventCsv = () => {
    exportEventCsv({ appId: event.appId, eventId: event.id })
      .unwrap()
      .then(() => {
        toast({
          title: "Event data export requested successfully",
          description:
            "You'll receive an email with a link to download your event data shortly.",
          status: "success",
        });
      })
      .catch(({ data }) => {
        toast({
          title: "Error requesting event data export",
          description: data.error,
          status: "error",
        });
      });
  };

  return (
    <>
      <Menu>
        <MenuButton as={Button} variant="ghost" ml={2} {...styleProps}>
          <EllipsisVerticalIcon style={{ height: "18px" }} />
        </MenuButton>
        <MenuList boxShadow="md" minWidth="140px">
          {actions.includes("delete") && (
            <>
              <MenuItem onClick={onExportEventCsv}>
                <div className="flex items-center justify-start">
                  <ArrowDownTrayIcon className="mr-2 h-4 w-4 text-gray-600" />
                  <Text fontWeight="medium" fontSize="sm" color="black">
                    Export as CSV
                  </Text>
                </div>
              </MenuItem>
              <MenuItem>
                <Link
                  className="w-full"
                  to={`/a/${event.appId}/event/${event.id}/rename-request`}
                >
                  <Flex justify="flex-start" alignItems="center">
                    <PencilSquareIcon className="mr-2 h-4 w-4 text-gray-600" />
                    <Text fontWeight="medium" fontSize="sm" color="black">
                      Rename
                    </Text>
                  </Flex>
                </Link>
              </MenuItem>
              <MenuItem onClick={onOpen}>
                <Flex justify="flex-start" alignItems="center">
                  <TrashIcon className="mr-2 h-4 w-4 text-gray-600" />

                  <Text fontWeight="medium" fontSize="sm" color="black">
                    Delete
                  </Text>
                </Flex>
              </MenuItem>
              <DeleteDialog
                event={event}
                isOpen={isOpen}
                onClose={onClose}
                cancelRef={cancelRef}
              />
            </>
          )}
        </MenuList>
      </Menu>
    </>
  );
};
