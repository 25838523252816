import { IEventRow } from "core/models/events";
import { Flex, Spinner, Tooltip } from "@chakra-ui/react";

const FREQUENCY_TRESHOLD = {
  high: 10000,
  medium: 1000,
  low: 1,
};

interface IEventFrequencyBarsProps {
  event: IEventRow;
  tooltipPlacement?: "top" | "right" | "bottom" | "left";
}

export const EventFrequencyBars: React.FC<IEventFrequencyBarsProps> = ({
  event,
  tooltipPlacement = "right",
}) => {
  let tooltipLabel = "";
  const isHigh = event.totalCount > FREQUENCY_TRESHOLD["high"];
  const isMedium = event.totalCount > FREQUENCY_TRESHOLD["medium"];
  const isLow = event.totalCount > FREQUENCY_TRESHOLD["low"];
  const isIncomplete = event.totalCount === 0;

  switch (true) {
    case isHigh:
      tooltipLabel = "This event is triggered very frequently";
      break;
    case isMedium:
      tooltipLabel = "This event is triggered frequently";
      break;
    case isLow:
      tooltipLabel = "This event is not triggered frequently";
      break;
    case isIncomplete:
      tooltipLabel = "This event has not been triggered yet";
      break;
    default:
      tooltipLabel = "This event has only triggered once";
  }

  return (
    <Tooltip placement={tooltipPlacement} label={tooltipLabel}>
      {isIncomplete ? (
        <Spinner
          speed="2s"
          size="sm"
          color="purple.500"
          emptyColor="gray.300"
        />
      ) : (
        <Flex transform="rotate(-180deg)" gridGap={0.5}>
          <Flex
            borderRadius={1}
            w={1}
            h={3}
            bg={isHigh ? "gray.700" : "gray.300"}
          ></Flex>
          <Flex
            borderRadius={1}
            w={1}
            h={2}
            bg={isMedium ? "gray.700" : "gray.300"}
          ></Flex>
          <Flex
            borderRadius={1}
            w={1}
            h={1}
            bg={isLow ? "gray.700" : "gray.300"}
          ></Flex>
        </Flex>
      )}
    </Tooltip>
  );
};

export default EventFrequencyBars;
