import { IAppObject } from "core/models/appObjects";
import { Integration, useGetDataMappingsQuery } from "core/models/dataMappings";
import { Dispatch, SetStateAction, useState } from "react";

export interface IDataMapping {
  id: number;
  appId: number;
  source: string;
  destination: string;
  isEditable: boolean;
  appObject: IAppObject;
  isMatchingIdentifier: boolean;
  isRequired: boolean;
  isUnique: boolean;
  isComputed: boolean;
}

export interface ITempDataMapping {
  source: string;
  isTemp: boolean;

  id: null;
  appId: null;
  destination: null;
  isEditable: null;
  appObject: null;
  isMatchingIdentifier: null;
  isRequired: null;
  isUnique: null;
  isComputed: null;
}

interface IUseDataMappings {
  dataMappings: IDataMapping[];
  tempMappings: ITempDataMapping[];
  setTempMappings: Dispatch<SetStateAction<ITempDataMapping[]>>;
}

export const useDataMappings = ({
  appId,
  integration,
  appObject,
}: {
  appId: number;
  integration: Integration;
  appObject: IAppObject;
}): IUseDataMappings => {
  const [tempMappings, setTempMappings] = useState<ITempDataMapping[]>([]);
  const { data } = useGetDataMappingsQuery({
    appId,
    integration,
    objectType: appObject.objectType,
  });

  return {
    dataMappings:
      data?.filter((dm: IDataMapping) => !dm.isMatchingIdentifier) || [],
    tempMappings,
    setTempMappings,
  };
};
