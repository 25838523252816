import { useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useContact } from "core/hooks/useContact";
import { MostTriggeredEvents } from "core/components/MostTriggeredEvents";
import { Skeleton } from "@chakra-ui/react";

export const ContactMostTriggeredEvents: React.FC = () => {
  const { id: appId } = useCurrentApp();
  const { contactId: encodedContactId } = useParams<{
    contactId: string;
  }>() as {
    contactId: string;
  };
  const id = decodeURIComponent(encodedContactId);
  const {
    fetchContactEvents,
    contactEvents: data,
    isLoadingContactEvents,
  } = useContact({
    id,
    appId: String(appId),
  });

  useEffect(() => {
    fetchContactEvents();
  }, [fetchContactEvents]);

  if (isLoadingContactEvents) {
    return <Skeleton borderRadius="lg" height="300px" w="100%" mb={4} />;
  }

  return <MostTriggeredEvents events={data?.mostTriggeredEvents} />;
};
