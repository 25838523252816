import React from "react";
import { IBreakdown } from "core/types/Breakdown";
import { IBarData, IVerticalBarChartProps } from "core/types/BarChart";
import { useFunnelBreakdown } from "core/hooks/useFunnelBreakdown";
import { medianTimeTakenShortened } from "core/helpers/medianTimeTaken";
import { BreakdownTooltip } from "core/design-system/charts/Breakdown/BreakdownTooltip";
import { Flex, Text, Tooltip } from "@chakra-ui/react";
import { TimeIcon } from "@chakra-ui/icons";
import {
  generateColorForTraitValueWithIndex,
  isTraitNotAvailable,
} from "./utils";

const VerticalBreakdownBarItem = ({
  barDataItem,
  index,
  handleBarClick,
  firstConvertedCount,
  unit,
  barData,
}: {
  barDataItem: IBarData;
  barData: IBarData[];
  index: number;
  handleBarClick: (param: any) => void;
  firstConvertedCount: number;
  unit: string;
}) => {
  const {
    humanizedName,
    conversionPercentage: _cp,
    droppedCount,
    convertedCount,
    medianTimeTaken,
    breakdown,
  } = barDataItem;
  const conversionPercentage = Math.round(_cp * 100) / 100;
  const droppedPercentage =
    Math.round((100 - conversionPercentage || 0) * 100) / 100;
  const barItems = barData?.map((item: IBarData) => ({
    index: item.index,
    label: item.humanizedName,
    conversion: {
      unit,
      percentage: item.conversionPercentage,
      count: item.convertedCount,
      label: "",
      onClick: handleBarClick,
      breakdown: item.breakdown,
    },
    dropped: {
      unit: "",
      percentage: 0,
      count: 0,
      label: "",
      onClick: handleBarClick,
    },
  }));
  const barItem = {
    index: barDataItem.index,
    label: barDataItem.name,
    conversion: {
      unit,
      percentage: barDataItem.conversionPercentage,
      count: barDataItem.convertedCount,
      onClick: handleBarClick,
      breakdown: barDataItem.breakdown,
    },
    dropped: {
      unit,
      percentage: 0,
      count: 0,
      onClick: handleBarClick,
    },
  };
  const { breakdownTooltip, totalBreakdownCount } = useFunnelBreakdown(
    barItem,
    barItems,
  );
  const otherCount = barItem.conversion.count - totalBreakdownCount;
  const otherPercentage = (otherCount / firstConvertedCount) * 100;
  return (
    <Flex pos="relative" gridGap={3} align="center">
      <Flex w="50px">
        <Flex w="20px" bg="gray.100" borderRadius="sm">
          <Text fontSize="sm" mx="auto">
            {index + 1}
          </Text>
        </Flex>
      </Flex>
      <Flex maxW="120px" w="150px">
        <Tooltip label={humanizedName} hasArrow placement="top">
          <Text noOfLines={1} fontSize="sm" fontWeight="semibold">
            {humanizedName}
          </Text>
        </Tooltip>
      </Flex>

      <Flex
        borderRadius="md"
        display="inline-block"
        direction="row"
        mx={4}
        w="300px"
        bg="gray.100"
        onClick={() =>
          handleBarClick({
            index,
            humanizedName,
            convertedCount,
            droppedCount,
            showConverted: false,
          })
        }
        cursor="pointer"
      >
        <Flex>
          <>
            {breakdown?.map((b: IBreakdown, index: number) => {
              if (isTraitNotAvailable(b?.traitValue)) return null;
              const conversion =
                ((b?.convertedCount || 0) / firstConvertedCount) * 100;
              if (convertedCount > totalBreakdownCount) {
                const otherCount =
                  barItem.conversion.count - (b?.convertedCount || 0);
                const traitCount = b.convertedCount || 0;
                const traitPercentage =
                  (traitCount / firstConvertedCount) * 100;

                return (
                  <>
                    <Tooltip
                      label={
                        <BreakdownTooltip
                          otherCount={otherCount}
                          breakdownTooltip={breakdownTooltip}
                        />
                      }
                    >
                      <Flex
                        borderLeftRadius={index === 0 ? "md" : "none"}
                        borderRightRadius="none"
                        bg={generateColorForTraitValueWithIndex(
                          b.traitValue,
                          breakdown.length - index - 1,
                        )}
                        h="20px"
                        w={`${traitPercentage}%`}
                        onClick={(e: any) => {
                          e.stopPropagation();
                          handleBarClick({
                            index,
                            humanizedName,
                            convertedCount,
                            droppedCount,
                            showConverted: true,
                          });
                        }}
                        cursor="pointer"
                      ></Flex>
                    </Tooltip>
                  </>
                );
              }
              return (
                <Tooltip
                  label={
                    <BreakdownTooltip breakdownTooltip={breakdownTooltip} />
                  }
                >
                  <Flex
                    borderLeftRadius={index === 0 ? "md" : "none"}
                    borderRightRadius={
                      index === breakdown.length - 1 ? "md" : "none"
                    }
                    bg={generateColorForTraitValueWithIndex(
                      b.traitValue,
                      breakdown.length - index - 1,
                    )}
                    h="20px"
                    w={`${conversion}%`}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      handleBarClick({
                        index,
                        humanizedName,
                        convertedCount,
                        droppedCount,
                        showConverted: true,
                      });
                    }}
                    cursor="pointer"
                  ></Flex>
                </Tooltip>
              );
            })}
          </>
          <Tooltip
            label={
              <BreakdownTooltip
                otherCount={otherCount}
                breakdownTooltip={breakdownTooltip}
              />
            }
          >
            <Flex
              borderRightRadius="md"
              borderLeftRadius={totalBreakdownCount === 0 ? "md" : "none"}
              bg="gray.400"
              h="20px"
              w={`${otherPercentage}%`}
              onClick={(e: any) => {
                e.stopPropagation();
                handleBarClick({
                  index,
                  humanizedName,
                  convertedCount,
                  droppedCount,
                  showConverted: true,
                });
              }}
              cursor="pointer"
            ></Flex>
          </Tooltip>
        </Flex>
      </Flex>
      <Flex w="150px" direction="column">
        <Flex>
          <Text fontWeight="semibold">{conversionPercentage || 0}%</Text>
        </Flex>
        <Flex>
          <Text
            fontSize="xs"
            color="purple.500"
            fontWeight="semibold"
            cursor="pointer"
            onClick={() => {
              handleBarClick({
                index,
                humanizedName,
                convertedCount,
                droppedCount,
                showConverted: true,
              });
            }}
          >
            {convertedCount} {unit} converted
          </Text>
        </Flex>
      </Flex>
      <Flex w="150px" direction="column">
        <Flex>
          <Text fontWeight="semibold">{droppedPercentage}%</Text>
        </Flex>
        <Flex>
          <Text
            fontSize="xs"
            color="purple.500"
            fontWeight="semibold"
            cursor="pointer"
            onClick={() => {
              handleBarClick({
                index,
                humanizedName,
                convertedCount,
                droppedCount,
                showConverted: false,
              });
            }}
          >
            {droppedCount} {unit} dropped
          </Text>
        </Flex>
      </Flex>
      {index !== 0 && (
        <Tooltip hasArrow label={`Median time taken`}>
          <Text
            cursor="pointer"
            bg="white"
            fontSize="xs"
            borderColor="gray.200"
            boxShadow="sm"
            borderRadius="lg"
            pos="absolute"
            top="-20px"
            px={1}
          >
            <TimeIcon mb={0.5} h={2.5} mr={1} />
            {medianTimeTakenShortened({ medianTimeTaken }) || "n/a"}
          </Text>
        </Tooltip>
      )}
    </Flex>
  );
};

export const VerticalBreakdownChart: React.FC<IVerticalBarChartProps> = ({
  barData,
  unit,
  handleBarClick,
}) => {
  const firstConvertedCount = barData?.[0]?.convertedCount;
  return (
    <Flex pos="relative" direction="column" w="100%" gridGap={5} px={8} py={4}>
      {barData.map((barDataItem, index) => (
        <VerticalBreakdownBarItem
          firstConvertedCount={firstConvertedCount}
          barDataItem={barDataItem}
          index={index}
          handleBarClick={handleBarClick}
          unit={unit}
          barData={barData}
        />
      ))}
    </Flex>
  );
};
