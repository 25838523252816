import { useParams } from "react-router-dom";
import moment from "moment";
import { useCompany } from "core/hooks/useCompany";
import { useClearbitLogo } from "core/hooks/useClearbitLogo";
import { isValidUrl } from "core/helpers/isValidUrl";
import { getDomainFromEmails } from "core/helpers/domainFromEmails";
import CompanyAvatar from "core/components/Avatars/CompanyAvatar";
import { ComponentDefaultProps } from "@chakra-ui/react";

export const Logo: React.FC<ComponentDefaultProps> = ({ ...props }) => {
  const { appId, groupId: encodedGroupId } = useParams<{
    appId: string;
    groupId: string;
  }>() as {
    appId: string;
    groupId: string;
  };
  const groupId = decodeURIComponent(encodedGroupId);
  const { company } = useCompany({
    appId: Number(appId),
    id: groupId,
  });

  // Remove empty emails
  const emails = company?.contacts?.reduce((emails, contact) => {
    if (contact?.email) {
      emails.push(contact.email);
    }
    return emails;
  }, [] as string[]);

  const domain = getDomainFromEmails(emails);
  const avatar = company?.traits?.find(
    ({ name, value }) => name === "avatar" && value,
  )?.value;
  const logoURL = useClearbitLogo({ domain });
  const url = isValidUrl(avatar) ? avatar : logoURL;
  const isOnline = Boolean(
    company?.lastSeenAt &&
      moment.utc().diff(moment.utc(company?.lastSeenAt), "minutes") <= 5,
  );

  if (!company) {
    return (
      <CompanyAvatar
        size="md"
        unidentified={true}
        background="gray.100"
        border={"4px solid white"}
        {...props}
      />
    );
  }

  return (
    <CompanyAvatar
      border={"4px solid white"}
      group={company}
      size="md"
      isOnline={isOnline}
      logoURL={url}
      {...props}
    />
  );
};
