import { IReport } from "core/types/Report";
import { useInsightDataFetching } from "core/hooks/useInsightDataFetching";

export const useContactDrilldownQuery = ({
  report,
  insightParams,
  insightType,
  skip,
}: {
  appId: string;
  report: IReport;
  groupId?: string;
  insightParams: any;
  insightType: number;
  skip: boolean;
}) => {
  const { response, isLoading, isFetching, error } = useInsightDataFetching({
    report,
    insightType,
    insightParams,
    skip,
  });

  return { response, isLoading, isFetching, error };
};
