import { IReport } from "core/types/Report";
import { useToast } from "core/hooks/useToast";
import { useReportSetup } from "core/hooks/useReportSetup";
import { useAI } from "core/hooks/useAI";
import { CommandLineIcon } from "@heroicons/react/24/outline";
import { MenuItem } from "@chakra-ui/react";

export const SqlItem: React.FC<{
  report: IReport;
}> = ({ report }) => {
  const toast = useToast();
  const { pinQuery } = useAI();
  const { fetchReport } = useReportSetup();
  return (
    <MenuItem>
      <div
        onClick={() => {
          pinQuery({
            appId: Number(report.appId),
            reportId: Number(report.id),
          })
            .unwrap()
            .then(() => {
              fetchReport();
              toast({
                title: "SQL block added at the bottom of the report",
                status: "success",
              });
            });
        }}
        className="flex items-center gap-2"
      >
        <CommandLineIcon className="h-4" />
        <p>SQL block</p>
      </div>
    </MenuItem>
  );
};
