export const AlreadyUsedTooltip: React.FC<{ integration: string }> = ({
  integration,
}) => {
  return (
    <div className="flex flex-col space-y-2 p-2">
      <div className="flex">
        <p className="text-sm">Already in use</p>
      </div>
      <div className="flex">
        <p className="text-xs text-gray-300">
          This {integration} property has already been mapped to a June trait
        </p>
      </div>
    </div>
  );
};
