import { MenuItem } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useDeleteReportMutation } from "core/models/reports";
import { IReport } from "core/types/Report";
import { useRef, useState } from "react";
import { renderToast } from "core/helpers/toast";
import { DeleteDialog } from "./DeleteDialog";

export const DeleteItem: React.FC<{
  report: IReport;
}> = ({ report }) => {
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef();
  const [deleteReport] = useDeleteReportMutation();
  const navigate = useNavigate();

  const onAfterDelete = () => {
    navigate(`/a/${report.appId}/reports`);
  };

  const onDeleteReport = async () => {
    if (report.appId && report.id) {
      await deleteReport({ appId: report.appId, reportId: report.id })
        .unwrap()
        .then((data) => {
          renderToast({ action: "delete", status: "success" });
          if (onAfterDelete) {
            onAfterDelete();
          }
        })
        .catch(() => renderToast({ action: "delete", status: "error" }));
      setIsOpen(false);
    }
  };

  return (
    <MenuItem
      onClick={() => {
        setIsOpen(true);
      }}
    >
      <div className="flex items-center gap-2 text-red-600">
        <TrashIcon className="h-4" />
        <p>Delete</p>
      </div>
      <DeleteDialog
        onDeleteReport={onDeleteReport}
        report={report}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        cancelRef={cancelRef as unknown as React.RefObject<HTMLButtonElement>}
      />
    </MenuItem>
  );
};
