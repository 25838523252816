import { Navigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useReport } from "core/hooks/useReport";
import { getReportConfig } from "core/helpers/getReportConfig";
import { Center, Spinner } from "@chakra-ui/react";
import { PageContainer } from "core/components/PageContainer";

function TemplateCreateRoute() {
  const { appId, reportType } = useParams<{
    appId: string;
    reportType: string;
  }>() as {
    appId: string;
    reportType: string;
  };
  const queryParams = new URLSearchParams(window.location.search);
  const eventId = queryParams.get("eventId");
  const config = getReportConfig(Number(reportType))!;
  const { onCreateReport, createdReport } = useReport();

  useEffect(() => {
    if (config.title && reportType && !createdReport) {
      onCreateReport(Number(reportType), config.title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.title]);

  if (!createdReport)
    return (
      <PageContainer>
        <Center>
          <Spinner />
        </Center>
      </PageContainer>
    );

  if (eventId)
    return (
      <Navigate
        to={`/a/${appId}/report/${createdReport.report.id}/setup?eventId=${eventId}`}
      />
    );

  return <Navigate to={`/a/${appId}/report/${createdReport.report.id}`} />;
}

export default TemplateCreateRoute;
