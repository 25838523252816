import React from "react";
import { Box, Flex, keyframes, StyleProps } from "@chakra-ui/react";

interface IPingProps extends StyleProps {
  color?: string;
  animate?: boolean;
  h?: string;
  w?: string;
}

export const Ping: React.FC<IPingProps> = ({
  color = "green.300",
  animate = true,
  h = "3",
  w = "3",
  ...styleProps
}) => {
  const pulse = keyframes({
    "0%": {
      transform: "scale(1)",
      opacity: 1,
    },
    "75%": {
      opacity: 0,
    },
    "100%": {
      transform: "scale(2)",
      opacity: 0,
    },
  });

  return (
    <Flex data-testid="ping" {...styleProps} position="relative">
      <Box
        animation={
          animate
            ? `${pulse} 1s cubic-bezier(0, 0, 0.3, 1) infinite`
            : undefined
        }
        h={h}
        w={w}
        bg={color}
        borderRadius={10}
        display="inline-flex"
        position="absolute"
        opacity="0.75"
      />
      <Box display="inline-flex" h={h} w={w} bg={color} borderRadius={10}></Box>
    </Flex>
  );
};
