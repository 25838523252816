import { IAppObject } from "core/models/appObjects";
import { Navigate } from "react-router-dom";

export const SalesforceCompanyData: React.FC<{
  appObject: IAppObject;
}> = ({ appObject }) => {
  return (
    <Navigate to={`/a/${appObject.appId}/settings/integrations/salesforce`} />
  );
};
