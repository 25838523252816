import humps from "humps";
import axios from "core/initializers/axios";
import { slice } from "../index";
import { IContact } from "../../../types/Contact";
import { AppThunk } from "../../../initializers/store";

interface IDeleteOne {
  appId: number;
  id: string;
}

export const deleteOneContact =
  ({ appId, id }: IDeleteOne): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(slice.actions.deletingOne({ id }));
      await axios.delete<IContact>(`/contacts/${encodeURIComponent(id)}`, {
        params: humps.decamelizeKeys({ appId }),
      });
      dispatch(slice.actions.deleteOne({ id }));
    } catch (e: any) {
      const title = "Failed to delete contact";
      const description = e?.response?.data?.error;
      const error = `${title}: ${description}`;
      dispatch(slice.actions.deleteOneFailed({ id, error }));
    }
  };
