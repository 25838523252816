import React from "react";
import ApiKeys from "modules/Settings/ApiKeys";
import { LogoType } from "core/design-system/components/Icon/Icon.types";
import { SEGMENT } from "core/constants/apiKeys";
import { ArrowLeftOnRectangleIcon, KeyIcon } from "@heroicons/react/24/outline";
import { Button, Flex, Link } from "@chakra-ui/react";
import Sources from "../../../modules/Settings/Integrations/Segment/Sources";
import * as Integration from "../../../modules/Settings/Integrations/Integration";

const CONTENT: IntegrationMetadata = {
  title: "Segment",
  description: "Send events and customer data to June",
  logo: "companySegmentLogo" as LogoType,
  docsUrl: "https://help.june.so/en/articles/4804643-how-to-connect-segment",
};

const Action = () => (
  <Flex>
    <Button
      as={Link}
      colorScheme="purple"
      href={`${import.meta.env.VITE_API_HOST}/auth/segment`}
    >
      Add more sources
    </Button>
  </Flex>
);

const Segment: React.FC = () => {
  return (
    <Integration.Container isExpanded>
      <Integration.Header
        metadata={CONTENT}
        ActionComponent={() => <Action />}
      />
      <Integration.Body>
        <Integration.Collapsable
          title={"Your Segment sources"}
          headerIcon={<ArrowLeftOnRectangleIcon width={20} height={20} />}
        >
          <Sources />
        </Integration.Collapsable>
        <Integration.Collapsable
          title={"Your write API keys"}
          headerIcon={<KeyIcon width={20} height={20} />}
        >
          <ApiKeys source={SEGMENT} />
        </Integration.Collapsable>
      </Integration.Body>
    </Integration.Container>
  );
};

export default Segment;
