import { useEffect, useState } from "react";
import { pageConfig, trackConfig } from "modules/Events/List/config";
import { useLazyGetPagesQuery } from "core/models/pages";
import { useLazyGetEventsQuery } from "core/models/events";
import usePagination from "core/hooks/usePagination";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { PAGE, TRACK } from "core/constants/report-setup";

export const useEventList = ({
  eventType,
}: {
  eventType: typeof TRACK | typeof PAGE;
}) => {
  const { id: appId } = useCurrentApp();
  const [searchQuery, setSearchQuery] = useState("");
  const { currentPage, previousPage, nextPage, setPage } = usePagination({
    withURL: false,
    searchQuery,
  });
  const skipPagination = true;

  const [
    getEvents,
    { data: eventsData, isFetching: isLoadingEvents, error: eventsError },
  ] = useLazyGetEventsQuery();
  const [
    getPages,
    { data: pagesData, isFetching: isLoadingPages, error: pagesError },
  ] = useLazyGetPagesQuery();

  const listConfig = {
    [TRACK]: trackConfig,
    [PAGE]: pageConfig,
  };

  const objectEvents = {
    [TRACK]: eventsData?.events,
    [PAGE]: pagesData?.pages,
  };

  const isLoadingObject = {
    [TRACK]: isLoadingEvents,
    [PAGE]: isLoadingPages,
  };

  const objectErrors = {
    [TRACK]: eventsError,
    [PAGE]: pagesError,
  };

  const objectPagy = {
    [TRACK]: eventsData?.pagy,
    [PAGE]: pagesData?.pagy,
  };

  const getParams = {
    appId,
    page: currentPage,
    query: searchQuery,
    skipPagination,
  };

  useEffect(() => {
    switch (eventType) {
      case TRACK:
        getEvents(getParams);
        break;
      case PAGE:
        getPages(getParams);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId, eventType, searchQuery, currentPage]);

  return {
    listConfig: listConfig[eventType],
    events: objectEvents[eventType] || [],
    pagy: objectPagy[eventType],
    isLoading: isLoadingObject[eventType] || false,
    error: objectErrors[eventType] || null,
    skipPagination,
    currentPage,
    previousPage,
    nextPage,
    setPage,
    searchQuery,
    setSearchQuery,
  };
};
