import { Button, Menu, MenuButton, MenuList } from "@chakra-ui/react";
import PlusIcon from "@heroicons/react/20/solid/PlusIcon";
import { EventMenuItem } from "core/modules/reports/setup/Menu/EventMenuItem";
import { EventType, ANY, FIRST } from "core/constants/report-setup";

interface IAddEventMenuProps {
  onAddEvent: (eventType: EventType) => void;
  supportedEventTypes: EventType[];
  availableEventTypes?: EventType[];
  isDisabled?: boolean;
  disableSmartEvents?: boolean;
}

const AddEventMenu: React.FC<IAddEventMenuProps> = ({
  onAddEvent,
  supportedEventTypes,
  availableEventTypes,
  isDisabled,
  disableSmartEvents,
}) => {
  function shouldDisableEventType(eventType: EventType) {
    return (
      (availableEventTypes && !availableEventTypes.includes(eventType)) ||
      (disableSmartEvents && [ANY, FIRST].includes(eventType))
    );
  }

  return (
    <div data-testid="add-event-menu">
      <Menu>
        <MenuButton
          data-testid="add-event-button"
          as={Button}
          variant="ghost"
          colorScheme="purple"
          leftIcon={<PlusIcon className="h-4" />}
          isDisabled={isDisabled}
        >
          Add event
        </MenuButton>
        <MenuList minW="300px">
          {supportedEventTypes?.map((eventType, index) => (
            <EventMenuItem
              data-testid={`add-event-${eventType}`}
              eventType={eventType}
              index={index}
              onAddEvent={onAddEvent}
              isDisabled={shouldDisableEventType(eventType)}
            />
          ))}
        </MenuList>
      </Menu>
    </div>
  );
};

export default AddEventMenu;
