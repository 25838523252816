import React from "react";
import { IReport } from "core/types/Report";
import { ILabel } from "core/types/Label";
import { useGetSearchReportsQuery } from "core/models/reports";
import List from "core/components/List";
import { Flex, Spinner, Text } from "@chakra-ui/react";
import config from "./List/config";

interface ISearchResultsProps {
  appId: string | number;
  searchQuery: string;
  labelIds: string[];
  reportTypes: number[];
  setSelectedLabels: (labels: ILabel[]) => void;
}

export const SearchResults: React.FC<ISearchResultsProps> = ({
  appId,
  searchQuery,
  labelIds,
  reportTypes,
  setSelectedLabels,
}) => {
  const {
    data: reports,
    isFetching,
    refetch,
  } = useGetSearchReportsQuery({
    appId,
    query: searchQuery.length ? searchQuery : undefined,
    reportTypes,
    labelIds,
  });

  if (isFetching) {
    return (
      <Flex w="full" justify="center" py={16}>
        <Spinner />
      </Flex>
    );
  }

  return (
    <>
      {reports?.length ? (
        <List
          mutate={refetch}
          rows={[
            ...reports.map((report: IReport) => ({
              setSelectedLabels,
              ...report,
            })),
          ]}
          config={config}
        />
      ) : (
        <Flex w="full" justify="center" py={40}>
          <Text color="gray.600">No reports found.</Text>
        </Flex>
      )}
    </>
  );
};
