import { CommandOption } from "core/design-system/components/Command";
import { FilterType } from "core/constants/report-setup";
import { CursorArrowRippleIcon } from "@heroicons/react/24/outline";
import { cx } from "helpers/cx";
import { Tooltip } from "@chakra-ui/react";

export const EventFilterOption = ({
  onAddEventFilter,
  isEventFilterDisabled,
}: {
  onAddEventFilter: (filterType: FilterType) => void;
  isEventFilterDisabled?: boolean;
}) => {
  return (
    <CommandOption
      testId="audience-event-option"
      disabled={isEventFilterDisabled}
      value={() => onAddEventFilter(FilterType.EventFilter)}
    >
      <Tooltip
        hasArrow
        placement="left"
        label={
          isEventFilterDisabled
            ? "You can only add one event filter per filter group"
            : ""
        }
      >
        <div
          className={cx(
            "flex items-center gap-2 ",
            isEventFilterDisabled
              ? "cursor-not-allowed text-gray-500"
              : "text-gray-800",
          )}
        >
          <CursorArrowRippleIcon className="h-4" />
          <p className="text-xs font-medium">Event performed</p>
        </div>
      </Tooltip>
    </CommandOption>
  );
};
