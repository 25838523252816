import { IContact } from "core/types/Contact";
import { lowerCase } from "lodash";

export const DEFAULT_EMAIL_VALUE = "Unknown";

export interface IGetContactEmailProps {
  id: string;
  email?: string | null;
  traits?: any;
  [key: string]: any;
}

const INVALID_TRAIT_VALUES = ["null", "", " "];

const valid = (str?: string) =>
  str && !INVALID_TRAIT_VALUES.includes(str) && str;

const emailFromTraitsArray = (traits: { name: string; value: string }[]) => {
  // When trait is an array of email & value pairs [{ email: 'firstemail', value: 'Gladys' }, ...]
  const email = traits.find(
    (trait) => lowerCase(trait.name) === "email",
  )?.value;
  if (valid(email)) return email;

  return DEFAULT_EMAIL_VALUE;
};

const emailFromTraits = (traits: { [key: string]: string }) => {
  // When trait is an object { firstemail: 'Gladys', ... }
  if (valid(traits.email)) return traits.email;

  return DEFAULT_EMAIL_VALUE;
};

export const getContactEmail = (contact?: IGetContactEmailProps | IContact) => {
  if (!contact) return DEFAULT_EMAIL_VALUE;

  // Check if contact has first and last email
  if (contact.id === "") {
    // Contact is anonymous
    return "Anonymous";
  } else if (contact.email) {
    return contact.email;
  } else if (contact.traits) {
    const traitsIsArray = Array.isArray(contact.traits);
    return traitsIsArray
      ? emailFromTraitsArray(
          contact.traits as { name: string; value: string }[],
        )
      : emailFromTraits(contact.traits);
  }

  return DEFAULT_EMAIL_VALUE;
};
