import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { IGroup } from "core/types/Group";
import { deleteOneGroup } from "core/models/group/actions/deleteOneGroup";
import { RootState } from "core/initializers/store";
import { useToast } from "core/hooks/useToast";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

interface IDeleteGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  group: IGroup;
  appId: string;
}

export const DeleteGroupModal = ({
  isOpen,
  onClose,
  group,
  appId,
}: IDeleteGroupModalProps) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();

  const isDeleting = useSelector(
    (state: RootState): boolean => state.group.deleteGroup.loading[group.id],
  );

  const onDelete = useCallback(() => {
    if (!appId || !group.id) return;
    dispatch(deleteOneGroup({ appId, id: group.id }));
  }, [dispatch, appId, group.id]);
  return (
    <Modal
      size="lg"
      closeOnEsc
      closeOnOverlayClick
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete this company?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            Delete this company and all the group calls we received until now.
            If they send more group events, they will show up again.
          </Text>
          <Text pt={2}>
            It might take at least an hour before this company disappears from
            your companies list.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme="red"
            isDisabled={isDeleting}
            onClick={() => {
              onDelete();
              toast({
                title: "Deleted successfully",
                description:
                  "It might take at least an hour before this company disappears from your companies list.",
              });
              navigate(`/a/${appId}/people`);
            }}
          >
            Delete them permanently
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
