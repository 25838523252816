import { getCompanyName } from "core/helpers/companyName";
import { getContactEmail } from "core/helpers/contactEmail";
import { AppObjectType } from "core/models/appObjects";
import { IContact } from "core/types/Contact";
import { IGroup } from "core/types/Group";
import { useParams } from "react-router-dom";

export interface LocalEntityRecord {
  entity: IContact | IGroup;
  appObjectType: AppObjectType;
  viewedAt: string;
}

export interface IMostRecentlyViewedPeople {
  [appId: string]: LocalEntityRecord[];
}

export function save(
  appId: string,
  entity: IContact | IGroup,
  appObjectType: AppObjectType,
) {
  const people = JSON.parse(
    localStorage.getItem("recently-viewed-people") || "{}",
  );
  const email = getContactEmail(entity);
  const companyName = getCompanyName({
    name: entity?.name,
    traits: entity?.traits,
  });
  const name = appObjectType === AppObjectType.User ? email : companyName;
  const entityData = {
    entity: {
      id: entity.id,
      name: name || "Unknown",
      email: email || "Unknown",
    },
    appObjectType: appObjectType,
    viewedAt: new Date().toISOString(),
  };
  if (people[appId]) {
    const existingEntityIndex = people[appId].findIndex(
      (e: LocalEntityRecord) => e.entity.id === entity.id,
    );
    if (existingEntityIndex !== -1) {
      people[appId][existingEntityIndex] = entityData;
    } else {
      people[appId].unshift(entityData);
      people[appId] = people[appId].slice(0, 5);
    }
  } else {
    people[appId] = [entityData];
  }
  localStorage.setItem("recently-viewed-people", JSON.stringify(people));
}

export function useRecentlyViewedPeople() {
  const { appId } = useParams();
  const mostRecentlyViewedPeopleData = localStorage.getItem(
    "recently-viewed-people",
  );
  const mostRecentlyViewedPeopleDataParsed = JSON.parse(
    mostRecentlyViewedPeopleData || "{}",
  ) as IMostRecentlyViewedPeople;
  const mostRecentlyViewedPeople = appId
    ? mostRecentlyViewedPeopleDataParsed[appId] || []
    : [];

  return {
    people: mostRecentlyViewedPeople.sort(
      (a, b) => new Date(b.viewedAt).getTime() - new Date(a.viewedAt).getTime(),
    ),
    save,
  };
}
