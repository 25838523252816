import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { TemplateCategories } from "modules/Templates/TemplateCategories";
import { useNavigation } from "core/hooks/useNavigation";
import {
  Template,
  TEMPLATE_CATEGORIES,
  TemplateCategory,
} from "core/constants/templates";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { TemplatePreview } from "./TemplatePreview";
import { TemplateCollection } from "./TemplateCollection";

export const NewReportModal: React.FC = () => {
  const navigate = useNavigate();
  const { navigation, setIsNewReportModalOpen } = useNavigation();
  const queryParams = new URLSearchParams(window.location.search);
  const template = queryParams.get("template");

  const [selectedCategory, setSelectedCategory] = useState<TemplateCategory>(
    TemplateCategory.ProductUsage,
  );

  const [selectedTemplate, setSelectedTemplate] = useState<
    Template | undefined
  >(undefined);
  const onClose = () => {
    setSelectedCategory(TemplateCategory.ProductUsage);
    navigate({ search: "" });
    setSelectedTemplate(undefined);
    setIsNewReportModalOpen(false);
  };

  const templateCollection = TEMPLATE_CATEGORIES[selectedCategory];

  useEffect(() => {
    if (template) {
      setSelectedTemplate(template as Template);
    }

    if (!navigation.newReportModalIsOpen && template) {
      setIsNewReportModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  return (
    <Modal
      isCentered
      size="6xl"
      isOpen={navigation.newReportModalIsOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent borderRadius={8}>
        {selectedTemplate ? (
          <TemplatePreview
            template={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            onClose={onClose}
          />
        ) : (
          <>
            <ModalHeader>New report</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <TemplateCategories
                setSelectedCategory={setSelectedCategory}
                selectedCategory={selectedCategory}
              />
              <TemplateCollection
                collection={templateCollection}
                setSelectedTemplate={setSelectedTemplate}
                onClose={onClose}
              />
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
