import { useNavigate, useParams } from "react-router-dom";
import { ObjectList } from "modules/People/ObjectList";
import { ExportCsv } from "modules/People/ExportCsv";
import { AudienceFilters } from "core/modules/audience/Filters/AudienceFilters";
import { useHubspotSettings } from "core/hooks/useHubspotSettings";
import { useGroupContactsList } from "core/hooks/useGroupContactsList";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useAttioSettings } from "core/hooks/useAttioSettings";
import { ErrorMessage } from "core/components/List/ErrorMessage";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Button, Flex, Stack } from "@chakra-ui/react";

export const GroupContacts: React.FC = () => {
  const { groupId: encodedGroupId } = useParams<{
    appId: string;
    groupId: string;
  }>() as {
    appId: string;
    groupId: string;
  };
  const { id: appId } = useCurrentApp();
  const navigate = useNavigate();
  const { hasHubspotInstalled } = useHubspotSettings();
  const { hasAttioInstalled } = useAttioSettings();

  const groupId = decodeURIComponent(encodedGroupId);

  function onSyncToCrm() {
    if (hasAttioInstalled) {
      navigate(
        `/a/${appId}/settings/integrations/attio/${userAppObject.objectType}`,
      );
    } else if (hasHubspotInstalled) {
      navigate(
        `/a/${appId}/settings/integrations/hubspot/${userAppObject.objectType}`,
      );
    } else {
      navigate(`/a/${appId}/settings/integrations/attio`);
    }
  }

  const {
    userAppObject,
    listConfig,
    audienceFilters,
    onSetAudienceFilters,
    searchQuery,
    setSearchQuery,
    pagy,
    contacts,
    isLoading,
    error,
    skipPagination,
    currentPage,
    previousPage,
    nextPage,
    sortBy,
    sortOrder,
    setSortBy,
    setSortOrder,
  } = useGroupContactsList({ groupId });

  return (
    <div className="flex h-full flex-col justify-start ">
      <Stack justify="flex-start" align="flex-start">
        <Flex data-id-contacts-list-container w="full" justify="space-between">
          <Flex justifyContent="space-between" align="center" mb={4}>
            <AudienceFilters
              key={userAppObject?.slug}
              audienceFilters={audienceFilters}
              setAudienceFilters={onSetAudienceFilters}
              view={userAppObject?.slug}
            />
          </Flex>
          <div className="flex gap-2">
            {userAppObject && (
              <ExportCsv
                appObject={userAppObject}
                audienceFilters={audienceFilters}
                searchQuery={searchQuery}
                groupId={groupId}
              />
            )}
            <Button onClick={onSyncToCrm} colorScheme="purple">
              <div className="flex px-2">
                <div className="flex items-center gap-2">
                  <ArrowPathIcon className="h-4 w-4" />
                  <p>Sync to CRM</p>
                </div>
              </div>
            </Button>
          </div>
        </Flex>
      </Stack>
      <div className="h-full pb-10">
        {userAppObject && error && <ErrorMessage />}
        {userAppObject && !error && (
          <ObjectList
            appObject={userAppObject}
            listConfig={listConfig}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            entities={contacts}
            isLoading={isLoading}
            pagy={pagy}
            currentPage={currentPage}
            previousPage={previousPage}
            nextPage={nextPage}
            audienceFilters={audienceFilters}
            showSearchBar={false}
            showAddTraitButton={false}
            showExportCsvButton={false}
            skipPagination={skipPagination}
            sortBy={sortBy}
            sortOrder={sortOrder}
            setSortBy={setSortBy}
            setSortOrder={setSortOrder}
          />
        )}
      </div>
    </div>
  );
};
