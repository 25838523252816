import { Navigate, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { cx } from "helpers/cx";
import { motion } from "framer-motion";
import { useCreateFeatureMutation } from "core/models/reports";
import useFlag from "core/hooks/useFlag";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import CompanyJuneLogoIcon from "core/design-system/components/Icon/Logos/CompanyJuneLogoIcon";
import { NEW_ONBOARDING } from "core/constants/features";
import { Box, Container, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { OnboardingContainer } from "./OnboardingContainer";
import { CreateFeatureInput } from "./CreateFeatureInput";
import { AssetsContainer } from "./AssetsContainer";
import { Asset } from "./Asset";

const FEATURE_EXAMPLES = [
  {
    company: "Slack",
    feature: "Message sent",
    icon: "/logos/slack.svg",
  },
  {
    company: "June",
    feature: "Report opened",
    icon: "/logos/june.svg",
  },
  {
    company: "Shopify",
    feature: "Item purchased",
    icon: "/logos/shopify.svg",
  },
  {
    company: "Intercom",
    feature: "Inbox opened",
    icon: "/logos/intercom.svg",
  },
];

export const SetupFeature: React.FC = () => {
  const { id: appId, name, logo } = useCurrentApp();
  const hasNewOnboardingEnabled = useFlag(NEW_ONBOARDING);
  const navigate = useNavigate();
  const [featureName, setFeatureName] = useState("");
  const [createFeature, { data, isLoading }] = useCreateFeatureMutation();

  useEffect(() => {
    if (data?.id) {
      navigate(`/a/${appId}/report/${data.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, appId]);

  if (!hasNewOnboardingEnabled) {
    return <Navigate to={`/a/${appId}/getting-started`} replace={true} />;
  }

  return (
    <OnboardingContainer>
      <Flex w="50%">
        <Container maxW="xl">
          <Flex direction="column" gap={10} pt={[14, 24]}>
            <motion.div
              initial={{ opacity: 0, top: -20 }}
              animate={{ opacity: 1, top: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              style={{ width: "100%", position: "relative" }}
            >
              <CompanyJuneLogoIcon h="50px" w="50px" />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, top: -20 }}
              animate={{ opacity: 1, top: 0 }}
              transition={{ duration: 0.5, delay: 0.6 }}
              style={{ width: "100%", position: "relative" }}
            >
              <Text fontSize="3xl">
                A feature is the smallest unit of value in your product. Start
                by measuring one.
              </Text>
            </motion.div>
          </Flex>
          <motion.div
            initial={{ opacity: 0, top: -20 }}
            animate={{ opacity: 1, top: 0 }}
            transition={{ duration: 0.5, delay: 0.9 }}
            style={{ width: "100%", position: "relative" }}
          >
            <Flex direction="column" gap={5} pt={12}>
              <CreateFeatureInput
                featureName={featureName}
                setFeatureName={setFeatureName}
                onCreate={() =>
                  createFeature({ appId: Number(appId), name: featureName })
                }
                isLoading={isLoading}
              />
              <Flex direction="column" align="center">
                <Text w="70%" textAlign="center" fontSize="sm" color="gray.600">
                  💡️ We’ll also automatically generate your core metrics for
                  active users, retention, and more.
                </Text>
              </Flex>
            </Flex>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: -5 }}
            animate={{ y: 1, opacity: 1 }}
            transition={{ delay: 5 }}
          >
            <Text mt={10} fontSize="sm">
              Here are some examples
            </Text>
          </motion.div>
          <SimpleGrid mt={4} columns={2} spacing={5}>
            {FEATURE_EXAMPLES.map((example, index) => (
              <motion.div
                initial={{ opacity: 0, y: -5 }}
                animate={{ y: 1, opacity: 1 }}
                transition={{ delay: (index + 12) / 2 }}
              >
                <Box
                  w="full"
                  p={4}
                  border="1px solid"
                  borderColor="gray.200"
                  borderRadius="lg"
                >
                  <Flex align="center" w="full" gap={4}>
                    <div className="h-8 w-8 rounded-full">
                      <img src={example.icon} alt="" />
                    </div>
                    <div className="min-w-0 flex-1">
                      <p className="text-sm font-medium text-gray-900">
                        {example.company}
                      </p>
                      <p className="truncate text-sm text-gray-600">
                        e.g. {example.feature}
                      </p>
                    </div>
                  </Flex>
                </Box>
              </motion.div>
            ))}
          </SimpleGrid>
        </Container>
      </Flex>
      <Flex w="50%" position="relative">
        <AssetsContainer name={name} logo={logo} delay={1.5}>
          <Flex
            position="absolute"
            top="0px"
            left="57px"
            h="64px"
            w="calc(100% - 57px)"
            bg="white"
            borderBottom="1px solid"
            borderColor="gray.200"
            zIndex="overlay"
            align="center"
          >
            <Text
              className={cx(
                `pl-10 text-gray-400 underline decoration-dashed underline-offset-4`,
                featureName && "text-gray-700",
              )}
            >
              {featureName || "Your feature"}
            </Text>
          </Flex>

          <Flex
            position="absolute"
            top="-1px"
            left="-1px"
            w="60px"
            h="full"
            bg="white"
            zIndex="toast"
            border="1px solid"
            borderColor="gray.200"
            borderTopLeftRadius="2xl"
            direction="column"
          />

          <Asset
            imageUrl="/feature-report-preview.svg"
            top="50px"
            left="100px"
            w="1000px"
          />
        </AssetsContainer>
      </Flex>
    </OnboardingContainer>
  );
};
