const baseStyle = {
  track: {
    _focus: {
      boxShadow: "none",
    },
  },
};

const Switch = {
  baseStyle,
};

export default Switch;
