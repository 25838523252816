import { Navigate, useParams } from "react-router-dom";
import { BookCall } from "modules/Onboarding/BookCall";
import { DEMO_APP_ID } from "core/constants/appIds";

export const Schedule = () => {
  const { appId } = useParams<{ appId?: string }>();

  if (Number(appId) === DEMO_APP_ID) {
    return <Navigate to={`/a/${appId}/home`} />;
  }

  return <BookCall />;
};
