import { Link } from "react-router-dom";
import React from "react";
import moment from "moment";
import { ITraitConfig } from "core/types/Trait";
import { Plan } from "core/types/App";
import Icon from "core/design-system/components/Icon";
import { ComputationType } from "core/constants/traits";
import { LongValueWrapper } from "core/components/Traits/LongValueWrapper";
import { PopoverCopy } from "core/components/ClipboardButton";
import {
  ArrowLongDownIcon,
  ArrowLongUpIcon,
  LockClosedIcon,
} from "@heroicons/react/16/solid";
import { Flex, Skeleton, Spacer, Text, Tooltip } from "@chakra-ui/react";
import { ComputedTraitTooltip } from "./ComputedTraitTooltip";
import { PaywallPopover } from "../Paywall/Popover";
import { AppNavLink } from "../../../Components/AppNavLink";

interface ITraitProps {
  name: string;
  value?: string;
  isTransient?: boolean;
  isPaywalled?: boolean;
  isComputed?: boolean;
  config?: ITraitConfig;
  traitId?: number;
  lastComputedAt?: string;
  isLoading?: boolean;
  description?: string;
  isPinned?: boolean;
  onPinTrait?: (isPinned: boolean) => void;
  editMode?: boolean;
}

function isValidUrl(string: string) {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
}

export const ChangeIcon: React.FC<{ value: string }> = ({ value }) => {
  if (Number(value) >= 0) {
    return <ArrowLongUpIcon className="h-4 text-green-500" />;
  }

  return <ArrowLongDownIcon className="h-4 text-red-500" />;
};

export const Trait: React.FC<ITraitProps> = ({
  name,
  value,
  isTransient = false,
  isComputed = false,
  config,
  lastComputedAt,
  traitId,
  isLoading = false,
  isPaywalled = false,
  description,
  isPinned = false,
  onPinTrait,
  editMode = false,
}) => {
  const isLink = value && isValidUrl(value);
  const isEventCountChange =
    value && config?.computationType === ComputationType.EventCountChange;
  const isTimestamp =
    value &&
    config?.computationType &&
    [ComputationType.FirstSeen, ComputationType.LastSeen].includes(
      config?.computationType,
    ) &&
    moment(value).isValid();

  let formattedValue = value;
  if (isEventCountChange) {
    formattedValue = Math.abs(Number(value)).toString();
  } else if (isTimestamp) {
    const humanReadableDate = new Date(value).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
    const humanReadableRelativeTime = moment.utc(value).format("hh:mm a");
    formattedValue = `${humanReadableDate} at ${humanReadableRelativeTime}`;
  }

  return (
    <div className="flex items-center">
      <div className="w-full max-w-full flex-col overflow-x-hidden">
        <Flex w={isPaywalled && isComputed ? "fit-content" : "initial"}>
          {isComputed ? (
            <>
              {isPaywalled ? (
                <PaywallPopover
                  feature="computed traits"
                  redirect="settings/computed-traits"
                  plan={Plan.Pro}
                >
                  <span
                    className={`overflow-hidden overflow-ellipsis whitespace-nowrap text-sm text-purple-500`}
                  >
                    {name}
                  </span>
                </PaywallPopover>
              ) : (
                <AppNavLink
                  to={`/settings/computed-traits/${traitId}`}
                  w="full"
                >
                  <Tooltip
                    hasArrow
                    label={
                      <ComputedTraitTooltip
                        name={name}
                        description={description}
                        lastComputedAt={lastComputedAt}
                      />
                    }
                    placement="top"
                  >
                    <Text
                      _hover={{ textDecoration: "underline dashed" }}
                      noOfLines={1}
                      mr={2}
                      fontSize="sm"
                      w="full"
                      minW="90px"
                      color="purple.500"
                    >
                      {name}
                    </Text>
                  </Tooltip>
                </AppNavLink>
              )}
            </>
          ) : (
            <Flex alignItems="center" gap={1}>
              {isTransient && (
                <Tooltip
                  label="This trait will be applied in one minute."
                  placement="left"
                >
                  <Icon iconType="icon" name="clock" color="gray.500"></Icon>
                </Tooltip>
              )}
              <Text noOfLines={1} mr={2} fontSize="sm" color="gray.500">
                {name}
              </Text>
            </Flex>
          )}
        </Flex>
        {isTransient ? (
          <Text fontSize="sm" color={"gray.500"} noOfLines={1}>
            processing
          </Text>
        ) : (
          <Flex w="full">
            {value && !isLoading ? (
              <>
                {isLink && value ? (
                  <PopoverCopy text={value}>
                    <Link
                      target={"_blank"}
                      data-testid="trait-value-link"
                      to={value}
                    >
                      <div className="text-sm text-gray-900 underline">
                        {value}
                      </div>
                    </Link>
                  </PopoverCopy>
                ) : isPaywalled ? (
                  <PaywallPopover
                    feature="computed traits"
                    redirect="settings/computed-traits"
                    plan={Plan.Pro}
                  >
                    <span
                      data-testid="paywalled-trait-value"
                      className="overflow-hidden overflow-ellipsis whitespace-nowrap pl-[4px] text-sm text-gray-600 opacity-75 blur-[3px] filter"
                    >
                      {value}
                    </span>
                  </PaywallPopover>
                ) : (
                  <PopoverCopy text={value}>
                    <span className="overflow-hidden overflow-ellipsis whitespace-nowrap text-sm text-gray-900">
                      <div className="flex items-center">
                        {isEventCountChange && <ChangeIcon value={value} />}
                        <LongValueWrapper value={formattedValue || ""} />
                        {isEventCountChange && <span>%</span>}
                      </div>
                    </span>
                  </PopoverCopy>
                )}
              </>
            ) : (
              <Skeleton borderRadius="md" h="20px" w="60px" />
            )}
          </Flex>
        )}
      </div>
      <Spacer />
      {!isTransient && editMode && !isPaywalled && (
        <Flex mr={2}>
          {isPinned ? (
            <Flex cursor="pointer" onClick={() => onPinTrait?.(false)}>
              <Icon iconType="icon" name="pin" color="gray.600" />
            </Flex>
          ) : (
            <Flex cursor="pointer" onClick={() => onPinTrait?.(true)}>
              <Icon
                iconType="icon"
                name="pin-outline"
                color="gray.500"
                fill="none"
              />
            </Flex>
          )}
        </Flex>
      )}
      {isPaywalled && (
        <div className="flex" data-testid="trait-paywall-icon">
          <PaywallPopover
            feature="computed traits"
            redirect="settings/computed-traits"
            plan={Plan.Pro}
          >
            <LockClosedIcon className="mr-2 h-4 text-purple-500" />
          </PaywallPopover>
        </div>
      )}
    </div>
  );
};

export default Trait;
