import { ComponentDefaultProps, Flex } from "@chakra-ui/react";

export const Header: React.FC<ComponentDefaultProps> = ({
  children,
  ...props
}) => {
  return (
    <Flex
      h="35%"
      data-testid="view-insight-card-header"
      direction="row"
      px={6}
      pt={6}
      {...props}
    >
      {children}
    </Flex>
  );
};
