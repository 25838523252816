import { Flex } from "@chakra-ui/react";
import { ContactBody } from "./ContactBody";

interface IContactPreviewProps {
  id: string;
  description?: string;
  p: number;
  truncateName: boolean;
  hideHoverBorder: boolean;
}

export const ContactStaticPreview: React.FC<IContactPreviewProps> = ({
  id,
  description,
  p,
  truncateName,
  hideHoverBorder,
}) => {
  return (
    <Flex
      p={p}
      align="center"
      justify="space-between"
      border="1px"
      borderRadius="lg"
      borderColor="white"
      _hover={{
        borderColor: hideHoverBorder ? "" : "gray.200",
        cursor: "pointer",
      }}
      data-testid="selector-clickable-contact"
    >
      <ContactBody
        userId={id}
        description={description}
        truncateName={truncateName}
        previewMode
      />
    </Flex>
  );
};
