import { useMemo } from "react";
import { motion } from "framer-motion";
import { PROGRESSION } from "./utils";
import { Goal, ProgressionState, Track } from "./types";
import GoalBadge from "./GoalBadge";
import Badge from "./Badge";

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
    },
  },
};

const item = {
  hidden: { opacity: 0, scale: 1, y: -10 },
  show: { opacity: 1, scale: 1, y: 0 },
};

export const BadgeProgression: React.FC<{
  track: Track;
  currentLevel?: number;
  badgeProps: Partial<React.ComponentProps<typeof Badge>>;
  isCompanyLevel: boolean;
  isReversed?: boolean;
  isSlanted?: boolean;
  isStackFlipped?: boolean;
  onAnimationFinish?: () => void;
  state?: ProgressionState;
}> = ({
  track,
  currentLevel,
  badgeProps,
  isCompanyLevel,
  isReversed,
  isSlanted,
  isStackFlipped,
  onAnimationFinish,
  state,
}) => {
  const goalList = useMemo(() => PROGRESSION[track].slice(0, 5), [track]);
  return (
    <motion.div
      style={{
        display: "flex",
        flexDirection: isReversed ? "row-reverse" : "row",
        gap: 8,
      }}
      variants={container}
      initial="hidden"
      animate="show"
      onAnimationComplete={onAnimationFinish}
    >
      {goalList.map((goal: Goal, goalLevel: number) => {
        const isLatestBadge = goalLevel + 1 === currentLevel;
        const isEmpty = goalLevel >= (currentLevel ?? 0);
        return (
          <motion.div
            style={{
              marginLeft: isSlanted ? (isReversed ? "-35px" : "0px") : "0px",
              marginRight: isSlanted ? (isReversed ? "0px" : "-35px") : "0px",
              zIndex: isStackFlipped ? -goalLevel : 0,
              position: "relative",
              top: isSlanted ? `-${goalLevel * 6}px` : "0px",
            }}
            key={goalLevel + 1}
            variants={item}
          >
            <GoalBadge
              isCompanyLevel={isCompanyLevel}
              shimmer={isLatestBadge}
              goal={goal}
              empty={isEmpty}
              state={state}
              goalLevel={goalLevel + 1}
              {...badgeProps}
            />
          </motion.div>
        );
      })}
    </motion.div>
  );
};

export default BadgeProgression;
