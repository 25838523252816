import {
  Alert,
  Button,
  CloseButton,
  Code,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ArrowForwardIcon, WarningTwoIcon } from "@chakra-ui/icons";

const LOCAL_STORAGE_EVENT_GROUP_CONTEXT = "eventGroupContext";

export const GroupContextBanner = () => {
  const defaultIsOpen =
    localStorage.getItem(LOCAL_STORAGE_EVENT_GROUP_CONTEXT) !== "true";
  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen });
  const onCloseBanner = () => {
    localStorage.setItem(LOCAL_STORAGE_EVENT_GROUP_CONTEXT, "true");
    onClose();
  };

  return (
    <Alert
      bottom="15px"
      pos="fixed"
      bg="warning.100"
      borderRadius="lg"
      display={isVisible ? "flex" : "none"}
      w="50%"
    >
      <Flex w="100%" alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <WarningTwoIcon color="warning.600" mr={4} />
          <Flex direction="column" fontSize="sm">
            <Text fontWeight="semibold">
              Some events are not associated with a company
            </Text>
            <Text>
              If users can log into a workspace, you should pass the workspace's
              <Code bg="transparent">group_id</Code> to this track event.
            </Text>
          </Flex>
        </Flex>
        <Flex gap={2}>
          <Button
            bg="warning.600"
            color="white"
            colorScheme={"warning"}
            rightIcon={<ArrowForwardIcon />}
            as="a"
            target="_blank"
            href="https://www.june.so/docs/quickstart/track-event"
          >
            Show me how
          </Button>
          <CloseButton alignSelf="center" onClick={onCloseBanner} />
        </Flex>
      </Flex>
    </Alert>
  );
};
