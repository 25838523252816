import { Box, ComponentDefaultProps } from "@chakra-ui/react";
import React from "react";

interface IAsset extends ComponentDefaultProps {
  imageUrl: string;
}

export const Asset: React.FC<IAsset> = ({ imageUrl, ...props }) => {
  return (
    <Box
      mt={16}
      backgroundRepeat="no-repeat"
      h="1000px"
      overflow="hidden"
      position="absolute"
      top="120px"
      left="120px"
      w={"1000px"}
      bgImage={`url(${imageUrl})`}
      {...props}
    />
  );
};
