import React from "react";

import { Flex, Text } from "@chakra-ui/react";

interface ILastEventReceivedProps {
  props: { daysSinceLastEvent: number };
}

export const LastEventReceived: React.FC<ILastEventReceivedProps> = ({
  props: { daysSinceLastEvent },
}) => {
  return (
    <Flex direction="column">
      <Text fontSize="sm">{daysSinceLastEvent} days ago</Text>
    </Flex>
  );
};
