import React from "react";
import { cx } from "helpers/cx";
import { IHomeFeature } from "core/types/HomeFeature";
import HelpCircle from "core/design-system/components/Icon/Interface/HelpCircle";
import {
  ADOPTION_PERCENTAGE,
  ASC,
  DESC,
  RELEASED_DATE,
  RETENTION_PERCENTAGE,
  USAGE_COUNT,
  USER_COUNT,
} from "core/constants/featureList";
import { GroupType } from "core/components/Group/GroupListSingleGroup";
import { ComponentDefaultProps, Tooltip } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Feature } from "./Feature";

interface ITableProps {
  featuresData: IHomeFeature[];
  onSort: (column?: string, order?: "desc" | "asc") => void;
  column: string;
  order: string;

  showAdoption?: boolean;
  showUserCount?: boolean;
  showUsageCount?: boolean;
  showRetention?: boolean;
  showReleaseDate?: boolean;
  showDividers?: boolean;
  groupId?: string;
  groupType?: GroupType;
}

interface IHeader extends ComponentDefaultProps {
  onSort: (column?: string, order?: "desc" | "asc") => void;
  columnTitle: string;
  columnName: string;
  column: string;
  order: string;
  children?: React.ReactChild;
  disableSorting?: boolean;
}

const Header: React.FC<IHeader> = ({
  onSort,
  columnTitle,
  columnName,
  column,
  order,
  children,
  className,
}) => {
  function onReorder() {
    const newOrder =
      column === columnName ? (order === DESC ? ASC : DESC) : DESC;
    onSort(columnName, newOrder);
  }

  return (
    <th
      className={cx(
        `cursor-pointer items-center text-left text-sm font-normal text-gray-600`,
        column === columnName && "text-purple-500",
        className,
      )}
      onClick={onReorder}
    >
      {columnTitle}
      {order === DESC ? <ChevronDownIcon /> : <ChevronUpIcon />}
      {children}
    </th>
  );
};

export const Table: React.FC<ITableProps> = ({
  featuresData,
  onSort,
  column,
  order,

  showAdoption = true,
  showUserCount = true,
  showUsageCount = true,
  showRetention = true,
  showReleaseDate = true,
  showDividers = true,
  groupId,
  groupType,
}) => {
  return (
    <table key={`${column}-${order}`} className="min-w-full">
      <thead className="border-b-[10px] border-transparent">
        <tr>
          <th className="w-[300px] text-left text-sm font-normal text-gray-600">
            <p className="text-sm">Feature</p>
          </th>
          {showAdoption && (
            <Header
              onSort={onSort}
              columnTitle="Adoption"
              columnName={ADOPTION_PERCENTAGE}
              column={column}
              order={order}
              className="w-[120px]"
            />
          )}
          {showUserCount && (
            <Header
              onSort={onSort}
              columnTitle="Users"
              columnName={USER_COUNT}
              column={column}
              order={order}
              className="w-[120px]"
            />
          )}
          {showUsageCount && (
            <Header
              onSort={onSort}
              columnTitle="Usage"
              columnName={USAGE_COUNT}
              column={column}
              order={order}
              className="w-[120px]"
            >
              <Tooltip
                label={
                  "Amount of times the events of this feature have been triggered since the launch."
                }
                hasArrow
                placement="top"
              >
                <HelpCircle h={4} w={4} color="purple.500" ml={1} />
              </Tooltip>
            </Header>
          )}
          {showRetention && (
            <Header
              onSort={onSort}
              columnTitle="Retention"
              columnName={RETENTION_PERCENTAGE}
              column={column}
              order={order}
              className="w-[120px]"
            />
          )}
          {showReleaseDate && (
            <Header
              onSort={onSort}
              columnTitle="Launched"
              columnName={RELEASED_DATE}
              column={column}
              order={order}
              className="w-[120px]"
            />
          )}
        </tr>
      </thead>
      <tbody className={cx(showDividers && "divide-y divide-gray-200")}>
        {featuresData?.map((item, index) => (
          <Feature
            key={index}
            feature={item}
            showAdoption={showAdoption}
            showUserCount={showUserCount}
            showUsageCount={showUsageCount}
            showRetention={showRetention}
            showReleaseDate={showReleaseDate}
            showDividers={showDividers}
            groupId={groupId}
            groupType={groupType}
          />
        ))}
      </tbody>
    </table>
  );
};
