import { useNavigate } from "react-router-dom";
import { useState } from "react";
import moment from "moment";
import { IReport, IReportDateRangeOption } from "core/types/Report";
import {
  getInitialTimerange,
  getReadableTimerange,
} from "core/helpers/timerange";
import {
  ABSOLUTE,
  RELATIVE,
  RELATIVE_TIMERANGE_VALUE_FROM_KEYS,
} from "core/constants/timerange";
import { useReportSetup } from "./useReportSetup";
import { useQueryParam } from "./useQueryParam";

export interface ICustomTimerange {
  startDate: Date;
  endDate: Date;
  key: string;
}

export const useReportTimerange = (
  report: IReport,
): {
  label: string;
  timerangeType: typeof RELATIVE | typeof ABSOLUTE;
  relativeTimerange: IReportDateRangeOption;
  customTimerange: ICustomTimerange[];
  readableCustomTimerange: string;
  setCustomTimerange: (customTimerange: ICustomTimerange[]) => void;
  saveTimerange: (option: IReportDateRangeOption) => void;
} => {
  const navigate = useNavigate();

  const timerangeType =
    Number(useQueryParam("timerangeType")) || report.timerangeType;
  const timerangeValue =
    Number(useQueryParam("timerangeValue")) || report.timerangeValue;
  const timerangeStartTimestamp =
    useQueryParam("startDate") || report.timerangeStartTimestamp;
  const timerangeEndTimestamp =
    useQueryParam("endDate") || report.timerangeEndTimestamp;

  const relativeTimerange = getInitialTimerange({
    timerangeType,
    timerangeValue,
  });

  let startDate = timerangeStartTimestamp
    ? moment.utc(timerangeStartTimestamp).toDate()
    : new Date(
        Date.UTC(
          new Date().getUTCFullYear(),
          new Date().getUTCMonth(),
          new Date().getUTCDate() - 7,
        ),
      );
  let endDate = timerangeEndTimestamp
    ? moment.utc(timerangeEndTimestamp).toDate()
    : new Date();
  const initialCustomTimerange = [
    {
      startDate,
      endDate,
      key: "selection",
    },
  ];

  const isCustomDate = relativeTimerange?.value === "fixed";
  const [customTimerange, setCustomTimerange] = useState(
    initialCustomTimerange,
  );
  const readableCustomTimerange = getReadableTimerange({
    startTimestamp: customTimerange[0].startDate,
    endTimestamp: customTimerange[0].endDate,
  });
  const label = isCustomDate
    ? readableCustomTimerange
    : relativeTimerange?.label;

  const { updateReportTimerange } = useReportSetup();

  const updateURL = () => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    params.delete("timerangeType");
    params.delete("timerangeValue");
    params.delete("startDate");
    params.delete("endDate");
    navigate({ search: params.toString() });
  };

  const saveTimerange = async (newTimerange: IReportDateRangeOption) => {
    const timerangeValue = newTimerange.value
      ? RELATIVE_TIMERANGE_VALUE_FROM_KEYS[newTimerange.value]
      : 0;
    const timerangeStartTimestamp = moment(customTimerange[0].startDate)
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss");
    const timerangeEndTimestamp = moment(customTimerange[0].endDate)
      .endOf("day")
      .format("YYYY-MM-DDTHH:mm:ss");

    const payload = {
      timerangeType: newTimerange.type,
      timerangeValue,
      timerangeStartTimestamp,
      timerangeEndTimestamp,
    };

    updateReportTimerange(payload);
    updateURL();
  };

  return {
    label: label || "",
    timerangeType,
    relativeTimerange,
    customTimerange,
    readableCustomTimerange,
    setCustomTimerange,
    saveTimerange,
  };
};
