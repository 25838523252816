import React from "react";
import { formatValue } from "core/components/AI/Graph/Graph";
import {
  Center,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

const MAX_ROW_COUNT = 5;
const MAX_COLUMN_COUNT = 3;

export const SimpleTable: React.FC<{
  columns: { name: string; type: string; label?: string }[];
  data: { [key: string]: string }[];
}> = ({ columns, data }) => {
  if (
    (columns.length === 1 && columns[0].type.includes("Int")) ||
    columns[0].type.includes("Float")
  ) {
    return (
      <Center h="full" w="full" ml={6}>
        <Flex align="center" direction="column">
          <Text fontWeight="medium" noOfLines={1} fontSize="5xl">
            {Number(Object.values(data[0])[0]).toLocaleString()}
          </Text>
          <Text color="gray.600" fontSize="sm">
            {columns[0].name.split("_").join(" ").replace(/\(|\)/g, "")}
          </Text>
        </Flex>
      </Center>
    );
  }
  return (
    <TableContainer w="full" ml={6}>
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            {columns?.slice(0, MAX_COLUMN_COUNT).map((column, index) => (
              <Th
                pl={0}
                key={index}
                maxW={`${315 / columns.slice(0, MAX_COLUMN_COUNT).length}px`}
              >
                <p className="text-lowercase max-w-full truncate text-xs font-medium capitalize text-black">
                  {(
                    column.label || column.name.split(`_`).join(" ")
                  ).toLowerCase()}
                </p>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data?.slice(0, MAX_ROW_COUNT).map((row, rowIndex) => (
            <Tr key={rowIndex}>
              {columns.map((column, colIndex) => (
                <Td
                  maxW={`${315 / columns.slice(0, MAX_COLUMN_COUNT).length}px`}
                  pl={0}
                  key={colIndex}
                >
                  <Text noOfLines={1} fontSize="xs">
                    {formatValue(row[column.name])}
                  </Text>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
