import { useDispatch } from "react-redux";
import { useState } from "react";
import { updateApp } from "core/models/app";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { Button, Input } from "@chakra-ui/react";

export const WorkspaceName = () => {
  const currentApp = useCurrentApp();
  const dispatch = useDispatch();
  const [currentName, setCurrentName] = useState<string>("");

  const updateWorkspaceName = () => {
    dispatch(updateApp({ appId: currentApp.id, name: currentName }));
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="mb-1 text-sm font-semibold">Workspace name</div>
      <div className="flex gap-2">
        <Input
          onChange={(e) => {
            setCurrentName(e.target.value);
          }}
          defaultValue={currentApp.name}
          size="sm"
          placeholder="Workspace name"
          borderRadius="lg"
        />
        {currentName && (
          <Button h={10} onClick={updateWorkspaceName}>
            Save
          </Button>
        )}
      </div>
    </div>
  );
};
