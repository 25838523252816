import {
  IGroup,
  IGroupActivity,
  IGroupEvents,
  IGroupState,
} from "core/types/Group";
import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

const initialState: IGroupState = {
  fetch: {
    loading: {},
    error: {},
  },
  fetchGroupActivity: {
    loading: {},
    error: {},
  },
  groupActivity: {
    data: {},
  },
  fetchGroupEvents: {
    loading: {},
    error: {},
  },
  groupEvents: {
    data: {},
  },
  deleteGroup: {
    loading: {},
    error: {},
  },
};

export const groupAdapter = createEntityAdapter<IGroup>({
  selectId: (entity) => entity.id,
});

export const slice = createSlice({
  name: "group",
  initialState: groupAdapter.getInitialState(initialState),
  reducers: {
    fetchingOne: (state, action: PayloadAction<{ id: string }>) => {
      const id = action.payload.id;
      state.fetch.loading[id] = true;
    },
    deleteOne: (state, action: PayloadAction<{ id: string }>) => {
      const id = action.payload.id;
      state.deleteGroup.loading[id] = false;
      state.deleteGroup.error[id] = undefined;
      groupAdapter.removeOne(state, id);
    },
    deletingOne: (state, action: PayloadAction<{ id: string }>) => {
      const id = action.payload.id;
      state.deleteGroup.loading[id] = true;
    },
    deleteOneFailed: (
      state,
      action: PayloadAction<{ id: string; error: string }>,
    ) => {
      const id = action.payload.id;
      const error = action.payload.error;
      state.deleteGroup.loading[id] = false;
      state.deleteGroup.error[id] = error;
    },
    fetchingGroupActivity: (state, action: PayloadAction<{ id: string }>) => {
      const id = action.payload.id;
      state.fetchGroupActivity.loading[id] = true;
    },
    fetchingGroupEvents: (state, action: PayloadAction<{ id: string }>) => {
      const id = action.payload.id;
      state.fetchGroupEvents.loading[id] = true;
    },
    upsertOne: (state, action: PayloadAction<{ id: string; data: IGroup }>) => {
      const id = action.payload.id;
      const data = action.payload.data;
      state.fetch.loading[id] = false;
      state.fetch.error[id] = undefined;
      groupAdapter.upsertOne(state, data);
    },
    upsertOneGroupActivity: (
      state,
      action: PayloadAction<{ id: string; data: IGroupActivity }>,
    ) => {
      const id = action.payload.id;
      const data = action.payload.data;
      state.fetchGroupActivity.loading[id] = false;
      state.fetchGroupActivity.error[id] = undefined;
      state.groupActivity.data[id] = data;
    },
    upsertOneGroupEvents: (
      state,
      action: PayloadAction<{ id: string; data: IGroupEvents }>,
    ) => {
      const id = action.payload.id;
      const data = action.payload.data;
      state.fetchGroupEvents.loading[id] = false;
      state.fetchGroupEvents.error[id] = undefined;
      state.groupEvents.data[id] = data;
    },
    fetchOneFailed: (
      state,
      action: PayloadAction<{ id: string; error: string }>,
    ) => {
      const id = action.payload.id;
      const error = action.payload.error;
      state.fetch.loading[id] = false;
      state.fetch.error[id] = error;
    },
    fetchGroupActivityFailed: (
      state,
      action: PayloadAction<{ id: string; error: string }>,
    ) => {
      const id = action.payload.id;
      const error = action.payload.error;
      state.fetchGroupActivity.loading[id] = false;
      state.fetchGroupActivity.error[id] = error;
    },
    fetchGroupEventsFailed: (
      state,
      action: PayloadAction<{ id: string; error: string }>,
    ) => {
      const id = action.payload.id;
      const error = action.payload.error;
      state.fetchGroupEvents.loading[id] = false;
      state.fetchGroupEvents.error[id] = error;
    },
  },
});

export default slice.reducer;
