import React from "react";
import { createIcon } from "@chakra-ui/icons";

const KeepControlIcon = createIcon({
  path: (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 7.33268H8.09333"
        stroke="#6868F7"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7651 4.97631C15.0668 6.27806 15.0668 8.3886 13.7651 9.69035C12.4633 10.9921 10.3528 10.9921 9.05102 9.69035C7.74927 8.3886 7.74927 6.27806 9.05102 4.97631C10.3528 3.67456 12.4633 3.67456 13.7651 4.97631"
        stroke="#6868F7"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7466 7.33268H27.9999"
        stroke="#6868F7"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7651 4.97631C15.0668 6.27806 15.0668 8.3886 13.7651 9.69035C12.4633 10.9921 10.3528 10.9921 9.05102 9.69035C7.74927 8.3886 7.74927 6.27806 9.05102 4.97631C10.3528 3.67456 12.4633 3.67456 13.7651 4.97631"
        stroke="#6868F7"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.9999 16.0007H23.9199"
        stroke="#6868F7"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2533 16.0007H4"
        stroke="#6868F7"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.9491 13.6423C24.2509 14.9441 24.2509 17.0546 22.9491 18.3564C21.6474 19.6581 19.5368 19.6581 18.2351 18.3564C16.9334 17.0546 16.9334 14.9441 18.2351 13.6423C19.5368 12.3406 21.6474 12.3406 22.9491 13.6423"
        stroke="#6868F7"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7466 24.6667H27.9999"
        stroke="#6868F7"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 24.6667H8.08"
        stroke="#6868F7"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7651 22.3103C15.0668 23.612 15.0668 25.7226 13.7651 27.0243C12.4633 28.3261 10.3528 28.3261 9.05102 27.0243C7.74927 25.7226 7.74927 23.612 9.05102 22.3103C10.3528 21.0085 12.4633 21.0085 13.7651 22.3103"
        stroke="#6868F7"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
});

export default KeepControlIcon;
