import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { IEmptyReportState, IReport } from "core/types/Report";
import { NewReportSetup } from "core/modules/reports/setup/NewReportSetup";
import { useReportSetup } from "core/hooks/useReportSetup";
import { useNavigation } from "core/hooks/useNavigation";
import { Flex, Spinner } from "@chakra-ui/react";

const instanceOfIReport = (
  object: IReport | IEmptyReportState,
): object is IReport => {
  return (
    "reportType" in object &&
    "reportType" in object &&
    "id" in object &&
    "state" in object &&
    "config" in object
  );
};

const NewReportSetupRoute = () => {
  const { appId, reportId } = useParams<{ appId: string; reportId: string }>();
  const { navigation, toggleSidebar } = useNavigation();
  const { currentReport, isLoading, fetchReport } = useReportSetup();
  const isValidReport =
    Object.keys(currentReport).length !== 0 &&
    typeof currentReport?.reportType === "number";

  useEffect(() => {
    if (navigation.isOpen) toggleSidebar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isValidReport) {
      fetchReport();
    }
  }, [appId, reportId, fetchReport, isValidReport]);

  if (isLoading) {
    return (
      <Flex h={400} align="center">
        <Spinner />
      </Flex>
    );
  }

  if (isValidReport && instanceOfIReport(currentReport)) {
    return <NewReportSetup report={currentReport} />;
  }
  return <></>;
};

export default NewReportSetupRoute;
