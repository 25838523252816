import { Integration, integrationNames } from "core/models/dataMappings";
import { IAppObject } from "core/models/appObjects";
import { IDataMapping } from "core/hooks/useDataMappings";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { EditableSourceMenu } from "./EditableSourceMenu";
import { EditableDestinationMenu } from "./EditableDestinationMenu";

interface IMatchingMappingRow {
  appObject: IAppObject;
  integration: Integration;
  matchingIdentifierDataMapping: IDataMapping;
}

export const MatchingMappingRow: React.FC<IMatchingMappingRow> = ({
  appObject,
  integration,
  matchingIdentifierDataMapping,
}) => {
  const integrationName = integrationNames[integration];

  return (
    <div className="w-full">
      <p className="text-sm">
        How should June {appObject.pluralName?.toLowerCase()} match with{" "}
        <p className="inline-block capitalize">{integrationName}</p>?
      </p>
      <p className="mt-2 text-sm text-gray-500">
        This is what we will use when we search{" "}
        <p className="inline-block capitalize">{integrationName}</p> for{" "}
        {appObject.pluralName?.toLowerCase()}
      </p>

      <div className="mt-4 flex w-full flex-row items-center justify-between">
        <div className="max-w-full">
          {matchingIdentifierDataMapping?.isEditable ? (
            <EditableSourceMenu
              appObject={appObject}
              integration={integration}
              dataMapping={matchingIdentifierDataMapping}
            />
          ) : (
            <p className="text-sm">{matchingIdentifierDataMapping.source}</p>
          )}
        </div>
        <div>
          <ArrowRightIcon className="h-4" />
        </div>
        <div>
          <EditableDestinationMenu
            appObject={appObject}
            integration={integration}
            matchingIdentifierDataMapping={matchingIdentifierDataMapping}
          />
        </div>
      </div>
      {!["name", "domain", "email"].includes(
        matchingIdentifierDataMapping.destination,
      ) &&
        integration === Integration.Hubspot && (
          <div className="mt-4 flex items-center justify-between gap-x-6 bg-gray-100 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
            <p className="text-sm text-gray-600">
              <p className="inline-block font-semibold">Note:</p> If you choose
              your own unique custom property, make sure that the property
              definition is a "single-line text" field type and the rules are
              set to require unique values.
            </p>
          </div>
        )}
    </div>
  );
};
