import { useState } from "react";
import { Size } from "core/types/ViewInsight";
import { ExplorationLegend } from "core/modules/reports/report-types/Explorer/ExplorationLegend";
import { useChartData } from "core/modules/reports/report-types/Explorer/ExplorationContainer";
import { CustomTooltip } from "core/modules/reports/report-types/Explorer/CustomTooltip";
import TickText from "core/design-system/charts/TickText";
import { Line, LineChart } from "core/design-system/charts/LineChart";
import {
  CommonTooltip,
  CommonXAxis,
  CommonYAxis,
} from "core/design-system/charts/Common";
import { Flex } from "@chakra-ui/react";

interface ISerie {
  color: string;
  data: { x: string; y: number; eventProperties?: string[] }[];
  max: number;
  mean: number;
  min: number;
  name: string;
  p90: number;
  total: number;
  uuid: string;
}

interface ISimpleStackedLineChart {
  data: ISerie[];
  size: Size;
  showYAxis: boolean;
}

export const simplifyXAxisTicks = (
  data: { data: { [key: string]: object }[] }[],
  dataKey: string,
) => {
  const firstTick = data && data.length && data[0].data[0][dataKey];
  const lastTick =
    data && data.length && data[0].data[data[0].data.length - 1][dataKey];
  return {
    firstTick,
    lastTick,
  };
};

export const SimpleStackedLineChart: React.FC<ISimpleStackedLineChart> = ({
  data,
  size,
  showYAxis,
}) => {
  const { series } = useChartData({ data });
  const [hoveringIndex, setHoveringIndex] = useState<string | undefined>(
    undefined,
  );

  if (!series || series.length < 1) {
    return null;
  }
  const { firstTick, lastTick } = simplifyXAxisTicks(series, "x");

  return (
    <Flex direction="column" justify="center" h="full" w="full">
      <LineChart
        h="sm"
        w="100%"
        margin={{ top: 0, left: -20, right: 0, bottom: 0 }}
      >
        {/* @ts-ignore */}
        <CommonTooltip content={<CustomTooltip />} />
        {/* @ts-ignore */}
        <CommonXAxis
          type="category"
          allowDuplicatedCategory={false}
          dataKey="x"
          tickCount={2}
          interval="preserveStartEnd"
          ticks={[firstTick, lastTick]}
        />
        {/* @ts-ignore */}
        <CommonYAxis
          // @ts-ignore
          tick={({ x, ...props }) =>
            showYAxis && (
              // @ts-ignore
              <TickText x={x - 5} {...props}>
                {props.payload.value}
              </TickText>
            )
          }
        />
        {series.map((serie, idx) => {
          return (
            <Line
              key={idx}
              dot={false}
              dataKey="y"
              data={serie.data}
              name={serie.name}
              stroke={serie.color}
              strokeWidth={1.5}
              strokeOpacity={
                hoveringIndex !== undefined
                  ? String(serie.uuid) === String(hoveringIndex)
                    ? 1
                    : 0.2
                  : 1
              }
            />
          );
        })}
      </LineChart>
      <div className="max-w-full overflow-x-scroll">
        <ExplorationLegend
          series={series}
          size={size}
          onHover={setHoveringIndex}
        />
      </div>
    </Flex>
  );
};
