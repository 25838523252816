import { Text } from "@chakra-ui/react";

export const PaywallUpgradeHeader: React.FC = () => {
  return (
    <Text fontWeight="bold" fontSize="2xl">
      Get access to audiences
    </Text>
  );
};

export const PaywallUpgradeSubHeader: React.FC = () => {
  return (
    <Text color="gray.700" fontSize="md" fontWeight="normal" px={10}>
      Upgrade to start creating your{" "}
      <Text as="span" fontWeight="bold">
        audiences
      </Text>{" "}
      and reuse them in your reports
    </Text>
  );
};
