import React from "react";
import { IInsightSection } from "core/types/TemplateConfig";
import { Box, ComponentDefaultProps, GridItem } from "@chakra-ui/react";

interface IContainerProps extends ComponentDefaultProps {
  id: string;
  insight?: IInsightSection;
}

export const InsightContainer: React.FC<IContainerProps> = ({
  id,
  insight,
  children,
  ...props
}) => {
  return (
    <GridItem
      id={id}
      h="100%"
      borderColor="gray.50"
      borderWidth="0.5px"
      bg="white"
      borderRadius="lg"
      colSpan={2}
      w={"100%"}
      boxShadow="sm"
      position="relative"
      borderBottomRadius="lg"
      {...props}
    >
      <Box>{children}</Box>
    </GridItem>
  );
};
