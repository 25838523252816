import {
  AspectRatio,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

interface IPlayerModalProps {
  isOpen: boolean;
  onClose: () => void;
  videoLink: string;
  videoTitle: string;
}

export const PlayerModal: React.FC<IPlayerModalProps> = ({
  isOpen,
  onClose,
  videoLink,
  videoTitle,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl">
      <ModalOverlay />
      <ModalContent borderRadius="lg">
        <ModalHeader>
          <Text fontSize="lg" fontWeight="bold">
            Import your data
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody px={6} pb={6} pt={0}>
          <AspectRatio
            mt={2}
            w="full"
            h="full"
            ratio={374 / 190}
            borderRadius="lg"
          >
            <iframe
              title={videoTitle}
              src={videoLink}
              allowFullScreen
              style={{ borderRadius: "12px" }}
            />
          </AspectRatio>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
