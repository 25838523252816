import { ComparisonType } from "core/types/Report";

export const IS = 0;
export const IS_NOT = 1;
export const STARTS_WITH = 2;
export const ENDS_WITH = 3;
export const CONTAINS = 4;
export const DOES_NOT_CONTAIN = 5;
export const IS_UNKNOWN = 6;
export const HAS_ANY_VALUE = 7;
export const GREATER_THAN = 8;
export const LESS_THAN = 9;
export const BEFORE = 10;
export const AFTER = 11;
export const ON = 12;
export const LESS_THAN_DAYS_AGO = 13;
export const MORE_THAN_DAYS_AGO = 14;

export interface TraitComparisonType {
  value: ComparisonType;
  name: string;
  hasInput: boolean;
}

export const TRAIT_COMPARISON_TYPES: TraitComparisonType[] = [
  { value: IS, name: "is", hasInput: true },
  { value: IS_NOT, name: "is not", hasInput: true },
  { value: STARTS_WITH, name: "starts with", hasInput: true },
  { value: ENDS_WITH, name: "ends with", hasInput: true },
  { value: CONTAINS, name: "contains", hasInput: true },
  { value: DOES_NOT_CONTAIN, name: "does not contain", hasInput: true },
  { value: IS_UNKNOWN, name: "is unknown", hasInput: false },
  { value: HAS_ANY_VALUE, name: "has any value", hasInput: false },
  { value: GREATER_THAN, name: "greater than", hasInput: true },
  { value: LESS_THAN, name: "less than", hasInput: true },
  { value: BEFORE, name: "before", hasInput: true },
  { value: AFTER, name: "after", hasInput: true },
  { value: ON, name: "on", hasInput: true },
  { value: LESS_THAN_DAYS_AGO, name: "less than days ago", hasInput: true },
  { value: MORE_THAN_DAYS_AGO, name: "more than days ago", hasInput: true },
];

export const COMPARISON_TO_NAME = [
  "is",
  "is not",
  "starts with",
  "ends with",
  "contains",
  "does not contain",
  "is unknown",
  "has any value",
  "greater than",
  "less than",
  "before",
  "after",
  "on",
  "less than days ago",
  "more than days ago",
] as string[];

export const NAME_TO_COMPARISON = {
  is: IS,
  is_not: IS_NOT,
  starts_with: STARTS_WITH,
  ends_with: ENDS_WITH,
  contains: CONTAINS,
  does_not_contain: DOES_NOT_CONTAIN,
  is_unknown: IS_UNKNOWN,
  has_any_value: HAS_ANY_VALUE,
  greater_than: GREATER_THAN,
  less_than: LESS_THAN,
  before: BEFORE,
  after: AFTER,
  on: ON,
  less_than_days_ago: LESS_THAN_DAYS_AGO,
  more_than_days_ago: MORE_THAN_DAYS_AGO,
};

export const AND = 0;
export const OR = 1;

export const OPERATOR_TO_NAME = {
  0: "and",
  1: "or",
};
