import { classNames } from "core/helpers/classNames";
import { Combobox } from "@headlessui/react";
import { IQuickNavigationItem } from "./Body";

interface IQuickNavigationOptionsProps {
  navigationItems: IQuickNavigationItem[];
}

export const QuickNavigationOptions: React.FC<IQuickNavigationOptionsProps> = ({
  navigationItems,
}) => {
  return (
    <>
      {navigationItems?.map((action: IQuickNavigationItem) => (
        <Combobox.Option
          key={action.path}
          value={action}
          className={({ active }) =>
            classNames(
              "flex cursor-default select-none items-center rounded-md px-3 py-2",
              active && "bg-gray-100",
            )
          }
        >
          <>
            {action.icon}
            <span className="ml-3 flex-auto truncate">{action.name}</span>
          </>
        </Combobox.Option>
      ))}
    </>
  );
};
