import humps from "humps";
import { baseQuery } from "core/initializers/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";

export const hubspotDealsApi = createApi({
  baseQuery,
  reducerPath: "hubspotDealsApi",
  tagTypes: ["HubspotDeals"],
  endpoints: (builder) => ({
    createDeal: builder.mutation<any, any>({
      query: ({ appId, groupId, objectType }) => ({
        url: `/hubspot/deals`,
        method: "POST",
        params: humps.decamelizeKeys({
          appId,
          groupId,
          objectType,
        }),
      }),
      transformResponse: (response: any) => humps.camelizeKeys(response),
    }),
    updateDeal: builder.mutation<any, any>({
      query: ({ id, groupId, appId, objectType }) => ({
        url: `/hubspot/deals/${id}`,
        method: "PUT",
        params: humps.decamelizeKeys({
          id,
          groupId,
          appId,
          objectType,
        }),
      }),
      transformResponse: (response: any) => humps.camelizeKeys(response),
    }),
    getDeals: builder.query<any, any>({
      query: ({ appId, groupId, objectType }) => ({
        url: `/hubspot/deals`,
        params: humps.decamelizeKeys({
          appId,
          groupId,
          objectType,
        }),
      }),
      transformResponse: (response: any) => humps.camelizeKeys(response),
    }),
  }),
});

export const {
  useCreateDealMutation,
  useUpdateDealMutation,
  useGetDealsQuery,
} = hubspotDealsApi;
