import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useCallback, useState } from "react";
import humps from "humps";
import axios from "core/initializers/axios";
import { useQueryParam } from "./useQueryParam";
import { useMountedState } from "./useMountedState";
import { useAppObjects } from "./useAppObjects";
import { triggerToast } from "../models/toasts/actions";
import { IReport } from "core/types/Report";

interface IUseInsightCsvExport {
  report: IReport;
  insightId: number;
  insightParams?: any;
  count: number;
}

export const useInsightCsvExport = ({
  report,
  insightId,
  insightParams,
  count,
}: IUseInsightCsvExport) => {
  const { appId, groupId: encodedGroupId } = useParams<{
    appId: string;
    groupId: string;
  }>() as {
    appId: string;
    groupId: string;
  };

  const groupIdQueryParam = useQueryParam("groupId");
  const { activeAppObject } = useAppObjects();
  const groupId = groupIdQueryParam
    ? groupIdQueryParam
    : decodeURIComponent(encodedGroupId);

  const dispatch = useDispatch();
  const isMounted = useMountedState();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const requestCsvExport = useCallback(() => {
    if (appId && insightId && count) {
      setError(undefined);
      setLoading(true);
      axios
        .get(`/report/insight/export`, {
          params: humps.decamelizeKeys({
            appId,
            reportId: report?.id,
            insightId,
            groupType: activeAppObject?.slug,
            insightParams: {
              ...insightParams,
              groupId,
            },
          }),
        })
        .then(() => {
          if (isMounted()) {
            const title = `Export ${activeAppObject?.singularName.toLowerCase()} list`;
            const description = `You will shortly receive an email with a CSV of your ${activeAppObject?.pluralName.toLowerCase()}`;
            dispatch(triggerToast({ title, description, status: "success" }));
          }
        })
        .catch((err) => {
          const title = "Export failed";
          const description = err?.response?.data?.error;
          const error = `${title}: ${description}`;
          setError(error);
          dispatch(triggerToast({ title, description, status: "error" }));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    dispatch,
    isMounted,
    count,
    appId,
    report?.id,
    insightId,
    activeAppObject,
    insightParams,
    groupId,
  ]);

  return {
    requestCsvExport,
    csvExportLoading: loading,
    csvExportError: error,
  };
};
