import { IEventLog } from "core/types/Debugger";
import { createSlice } from "@reduxjs/toolkit";

const initialState: {
  selectedLog: null | IEventLog;
} = {
  selectedLog: null,
};

export const eventLogSlice = createSlice({
  name: "eventLog",
  initialState,
  reducers: {
    selectLog(state, action) {
      state.selectedLog = action.payload;
    },
    deselectLog(state) {
      state.selectedLog = null;
    },
  },
});

export const eventLog = eventLogSlice.reducer;
