import { useSelector } from "react-redux";
import { IApp } from "core/types/App";
import { selector as appSelector } from "core/models/app";

export const useCurrentApp = (): IApp => {
  const { data: currentApp } = useSelector(appSelector);

  if (!currentApp) {
    throw new Error(
      "No current app found, use the app selector outside of the AuthenticatedApp component",
    );
  }

  return currentApp;
};
