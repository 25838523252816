import { IPagy } from "core/types/Pagy";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { Button } from "@chakra-ui/react";

interface PaginationProps {
  pagination: {
    currentPage: number;
    nextPage: () => void;
    previousPage: () => void;
  };
  pagy: IPagy;
  resourceName: string;
  skipPagination?: boolean;
  rowCount?: number;
}

export const Pagination: React.FC<PaginationProps> = ({
  pagination,
  pagy,
  resourceName,
  skipPagination = false,
  rowCount = 0,
}) => {
  const { currentPage, nextPage, previousPage } = pagination;
  const { items: pageSize, count, last } = pagy;

  const isPreviousPageDisabled = currentPage < 2;
  const isNextPageDisabled = skipPagination
    ? rowCount < pageSize
    : currentPage === last;

  const displayStartCount =
    currentPage === 1 ? 1 : (currentPage - 1) * pageSize;
  const displayEndCount =
    count < currentPage * pageSize ? count : currentPage * pageSize;

  return (
    <div className="flex justify-between py-2">
      <div className="flex items-center px-4 text-gray-700">
        {!skipPagination && (
          <p className="text-xs text-gray-600">
            {displayStartCount}-{displayEndCount}{" "}
            {count > pageSize && <span>out of {count}</span>} {resourceName}
          </p>
        )}
      </div>
      <div className="flex items-center">
        <Button
          variant="ghost"
          colorScheme="purple"
          isDisabled={isPreviousPageDisabled}
          onClick={previousPage}
          leftIcon={<ChevronLeftIcon className="h-3" />}
        >
          Previous page
        </Button>
        <Button
          variant="ghost"
          colorScheme="purple"
          isDisabled={isNextPageDisabled}
          onClick={nextPage}
          rightIcon={<ChevronRightIcon className="h-3" />}
        >
          Next page
        </Button>
      </div>
    </div>
  );
};
