import {
  GROUP_TRAIT_FILTER,
  USER_TRAIT_FILTER,
} from "core/constants/report-setup";
import {
  BuildingOffice2Icon,
  ChartBarIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";
import {
  Button,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Tooltip,
} from "@chakra-ui/react";

const USER_DATA_FILTERS = [
  { type: USER_TRAIT_FILTER, label: "Trait", icon: "trait" },
];
const COMPANY_DATA_FILTERS = [
  { type: GROUP_TRAIT_FILTER, label: "Trait", icon: "company" },
];

export default function BreakdownMenu({
  isCurrentlyAddingFilter,
  onFilterSelected,
  isDisabled = false,
}: {
  isCurrentlyAddingFilter: boolean;
  isDisabled: boolean;
  onFilterSelected: (filter: string) => void;
}) {
  return (
    <Menu>
      <Tooltip label="Specify which rules the users of your report should match">
        <MenuButton
          isDisabled={isDisabled || isCurrentlyAddingFilter}
          as={Button}
          variant="ghost"
          colorScheme="purple"
          leftIcon={<ChartBarIcon style={{ height: "15px" }} />}
          fontSize="sm"
        >
          Add breakdown
        </MenuButton>
      </Tooltip>
      <MenuList minW="100px">
        <MenuGroup
          color="gray.600"
          fontSize="xs"
          textTransform="uppercase"
          title="User data"
        >
          {USER_DATA_FILTERS.map((filter) => (
            <MenuItem
              textTransform="capitalize"
              fontSize="sm"
              fontWeight="normal"
              color="gray.700"
              onClick={() => onFilterSelected(filter?.type)}
              icon={<UsersIcon style={{ height: "15px" }} />}
            >
              {filter.label}
            </MenuItem>
          ))}
        </MenuGroup>
        <MenuGroup
          color="gray.600"
          fontSize="xs"
          textTransform="uppercase"
          title="Company data"
        >
          {COMPANY_DATA_FILTERS.map((filter) => (
            <MenuItem
              textTransform="capitalize"
              fontSize="sm"
              fontWeight="normal"
              color="gray.700"
              onClick={() => onFilterSelected(filter?.type)}
              icon={<BuildingOffice2Icon style={{ height: "15px" }} />}
            >
              {filter.label}
            </MenuItem>
          ))}
        </MenuGroup>
      </MenuList>
    </Menu>
  );
}
