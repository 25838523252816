import {
  useCreateEnrichedDomainMutation,
  useGetEnrichedDomainQuery,
} from "core/models/enrichedDomain";

export const useDomainEnrichment = ({
  appId,
  domain,
}: {
  appId: string;
  domain: string;
}) => {
  const { data, isLoading, error } = useGetEnrichedDomainQuery({
    domain,
    appId,
  });
  const [createdEnrichedDomain, { isLoading: isLoadingCreatingEnrichment }] =
    useCreateEnrichedDomainMutation();

  function enrichDomain({ appId, domain }: { appId: string; domain: string }) {
    createdEnrichedDomain({ appId, domain });
  }

  return {
    enrichedDomain: data,
    isLoading,
    error,
    createdEnrichedDomain: enrichDomain,
    isLoadingCreatingEnrichment,
  };
};
