import {
  ATTIO_SUPPORTED_PROPERTY_TYPES,
  HUBSPOT_SUPPORTED_PROPERTY_TYPES,
} from "../SelectDestination";

export const NotSupportedTypeTooltip: React.FC<{
  type: string;
  integration: string;
}> = ({ type, integration }) => {
  const supportedTypes =
    integration === "hubspot"
      ? HUBSPOT_SUPPORTED_PROPERTY_TYPES
      : ATTIO_SUPPORTED_PROPERTY_TYPES;
  return (
    <div className="flex flex-col space-y-2 p-2">
      <div className="flex">
        <p className="text-sm">
          Unsupported {integration} property type "{type}"
        </p>
      </div>
      <div className="flex">
        <p className="text-xs text-gray-300">
          Please use {integration} properties with one of the following property
          types: {supportedTypes.join(", ")}.
        </p>
      </div>
    </div>
  );
};
