import { XMarkIcon } from "@heroicons/react/24/outline";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useNavigation } from "core/hooks/useNavigation";
import { AnimatePresence, motion } from "framer-motion";
import { cx } from "helpers/cx";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const BookACallBanner: React.FC = () => {
  const { id, bookedACall } = useCurrentApp();
  const { navigation } = useNavigation();
  const [show, setShow] = useState(
    localStorage.getItem("dismiss_book_a_call_banner") !== "true",
  );
  const navigate = useNavigate();

  if (bookedACall || !show) return null;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ y: 5, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.05 }}
        className={cx(
          navigation.isOpen ? "ml-[290px]" : "ml-[40px]",
          `fixed inset-x-0 bottom-0 z-overlay transition-transform duration-300 ease-in-out sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8`,
        )}
      >
        <div className="flex items-center justify-between gap-x-6 bg-purple-500 px-6 py-2.5 shadow-xl shadow-purple-500/30 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
          <div className="flex items-center gap-2">
            <div className="relative">
              <img
                src="/enzo.jpg"
                alt="Enzo"
                className="h-7 w-7 rounded-full"
              />
              <div className="absolute -right-0.5 -top-0.5 h-2 w-2 rounded-full bg-green-400 ring-1 ring-purple-500" />
            </div>
            <p className="text-sm font-medium text-purple-50">
              Need set-up advice, help with onboarding your team or a demo?{" "}
              <p
                className="inline cursor-pointer font-semibold text-white underline"
                onClick={() =>
                  navigate(`/a/${id}/onboarding/schedule?book-a-call=true`)
                }
              >
                Book a call with Enzo or Alberto
              </p>
              , our June experts.
            </p>
          </div>
          <button
            type="button"
            onClick={() => {
              setShow(false);
              localStorage.setItem("dismiss_book_a_call_banner", "true");
            }}
            className="-m-1.5 flex-none p-1.5"
          >
            <span className="sr-only">Dismiss</span>
            <XMarkIcon aria-hidden="true" className="h-5 w-5 text-white" />
          </button>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
