import { IBlockComponent } from "../ConversationContainer";
import { Action } from "../Action";
import { useAI } from "core/hooks/useAI";
import { SmallCloseIcon } from "@chakra-ui/icons";

export const CloseConversation: React.FC<IBlockComponent> = ({
  delay,
  isReverse,
  children,
}) => {
  const { togglePrompt, reset } = useAI();
  return (
    <Action
      onClick={() => {
        togglePrompt(false);
        reset();
      }}
      delay={delay}
      isReverse={isReverse}
    >
      <SmallCloseIcon mr={2} /> Close the conversation
    </Action>
  );
};
