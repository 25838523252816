import {
  useDeleteImportTaskMutation,
  useGetAmplitudeImportTasksQuery,
  useGetImportTasksQuery,
  useGetMixpanelImportTasksQuery,
} from "core/models/importTasks";

interface IUseImportTasks {
  appId?: number;
  sourceName?: string;
}

export const useImportTasks = ({ appId }: IUseImportTasks) => {
  const { data: importTasks } = useGetImportTasksQuery({
    appId: Number(appId),
  });
  const { data: amplitudeImportTasks } = useGetAmplitudeImportTasksQuery({
    appId: Number(appId),
  });
  const { data: mixpanelImportTasks } = useGetMixpanelImportTasksQuery({
    appId: Number(appId),
  });
  const [deleteImportTask] = useDeleteImportTaskMutation();

  return {
    importTasks,
    deleteImportTask,
    amplitudeImportTasks,
    mixpanelImportTasks,
  };
};
