import { Box, Flex, Spacer, Text } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { useRadio } from "@chakra-ui/radio";

const APPLE_FONT_FAMILY =
  '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';

function formatNumber(num) {
  return num <= 999
    ? num
    : (0.1 * Math.floor(num / 100)).toFixed(1).replace(".0", "") + "K";
}

function FakeInsightWidget({ checkbox, insight = null }) {
  return (
    <Box
      {...checkbox}
      cursor="pointer"
      height={155}
      width={155}
      borderRadius="22px"
      backgroundColor="#0D131B"
      transition={"all 0.150s ease"}
      _focus={{
        boxShadow: "outline",
      }}
      _active={{
        transform: "scale(0.90)",
      }}
      p={4}
    >
      <Flex direction="column" h="100%">
        <Flex alignItems="center" textOverflow="ellipsis">
          <Text
            fontFamily={APPLE_FONT_FAMILY}
            fontSize={15}
            color="white"
            textTransform="uppercase"
            fontWeight={500}
            noOfLines={1}
          >
            {insight.title}
          </Text>
          <Spacer />
          <Image height="16px" width="16px" src={"/logo192.png"} />
        </Flex>

        <Text
          textColor="white"
          fontFamily={APPLE_FONT_FAMILY}
          fontWeight={300}
          fontSize="40px"
          lineHeight="48px"
        >
          {formatNumber(insight.value)}
        </Text>

        <Spacer />

        <Flex className="vertical-line-prefix" pl={2}>
          <Text
            color="white"
            fontFamily={APPLE_FONT_FAMILY}
            fontWeight={500}
            fontSize={12}
          >
            {insight.reportName}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
}

export default function InsightRadio(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();

  return (
    <Box as="label">
      <input {...input} />
      <FakeInsightWidget
        insight={props.insight}
        checkbox={getCheckboxProps()}
      />
    </Box>
  );
}
