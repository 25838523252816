import { useAppObjects } from "core/hooks/useAppObjects";

interface IUseInsightTitle {
  title: string;
  name?: string;
}

export const useInsightTitle = ({ title, name }: IUseInsightTitle) => {
  const { activeAppObject } = useAppObjects();

  if (!title) return title;

  if (name) {
    return title
      .replace("{APP_OBJECT_SINGULAR_NAME}", name.toLowerCase())
      .replace("{APP_OBJECT_PLURAL_NAME}", name.toLowerCase());
  }

  return title
    .replace(
      "{APP_OBJECT_SINGULAR_NAME}",
      activeAppObject?.singularName.toLowerCase() ?? "",
    )
    .replace(
      "{APP_OBJECT_PLURAL_NAME}",
      activeAppObject?.pluralName.toLowerCase() ?? "",
    );
};
