import { useLocation } from "react-router-dom";
import { cx } from "helpers/cx";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { ItemText } from "../../modules/Navigation/Sidebar/ItemText";
import { ItemContainer } from "../../modules/Navigation/Sidebar/ItemContainer";

export type MenuLinkProps = {
  label: string;
  path?: string;
  isNew?: boolean;
  isBeta?: boolean;
  Icon?: React.FC<{ className?: string }>;
  subMenu?: MenuLinkProps[];
  onClick?: () => void;
  action?: React.ReactNode;
};

export const MenuLink: React.FC<MenuLinkProps> = ({
  label,
  path,
  Icon,
  onClick,
  action,
  isNew,
  isBeta,
}) => {
  const app = useCurrentApp();
  const location = useLocation();

  const isActive = path ? location.pathname.includes(path) : false;
  const to = path ? `/a/${app.id}/${path}` : undefined;

  return (
    <ItemContainer isCurrent={isActive} to={to} onClick={onClick}>
      <div className="flex h-full w-full items-center justify-between">
        <div className="flex items-center gap-2">
          {Icon && (
            <Icon
              className={cx(
                "h-5 w-5",
                isActive ? "text-purple-500" : "text-gray-600",
              )}
            />
          )}
          <ItemText>{label}</ItemText>
          {action}
        </div>
        {isNew && (
          <span className="text-xs font-semibold text-blue-500">New</span>
        )}
        {isBeta && (
          <span className="text-xs font-semibold text-orange-500">Beta</span>
        )}
      </div>
    </ItemContainer>
  );
};
