import { AppObjectType } from "core/models/appObjects";

export const USER_TRAIT = 0;
export const EVENT = 1;
export const ACTIVE_AT = 2;
export const PROPERTY = 3;
export const GROUP_TRAIT = 4;
export const COMPANY_TRAIT = 7;
export const DIVIDER = 99;

export enum FilterType {
  UserTrait = USER_TRAIT,
  EventFilter = EVENT,
  GroupFilter = GROUP_TRAIT,
  CompanyFilter = COMPANY_TRAIT,
  Divider = DIVIDER,
}

export const ALLOWED_FILTER_TYPES = [
  USER_TRAIT,
  EVENT,
  ACTIVE_AT,
  PROPERTY,
  GROUP_TRAIT,
  COMPANY_TRAIT,
];

export const APP_OBJECT_TO_FILTER_TYPE = {
  [AppObjectType.User]: USER_TRAIT,
  [AppObjectType.Group]: GROUP_TRAIT,
  [AppObjectType.Company]: COMPANY_TRAIT,
};
