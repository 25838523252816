import colors from "core/design-system/constants/theme/colors";
import { Box, RadioProps, Text, useRadio } from "@chakra-ui/react";

export const TemplateRadio: React.FC<RadioProps> = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const { children } = props;
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const checkedStyle = {
    bg: "purple.50",
    color: colors.purple[500],
  };

  const hoverStyle = {
    bg: "purple.50",
  };

  return (
    <Box as="label" data-testid="template-category">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        _checked={checkedStyle}
        _hover={hoverStyle}
        borderRadius="md"
        px={3}
        py={1}
        mr={1}
      >
        <Text fontSize="sm" fontWeight="medium">
          {children}
        </Text>
      </Box>
    </Box>
  );
};
