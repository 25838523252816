import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const BarChartIcon = createIcon({
  path: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.74777 20C5.26592 20 4.85417 19.8286 4.5125 19.4859C4.17083 19.1432 4 18.7313 4 18.25V10.75C4 10.2688 4.17158 9.85677 4.51473 9.51405C4.85786 9.17135 5.27036 9 5.75223 9C6.23408 9 6.64583 9.17135 6.9875 9.51405C7.32917 9.85677 7.5 10.2688 7.5 10.75V18.25C7.5 18.7313 7.32843 19.1432 6.98528 19.4859C6.64214 19.8286 6.22964 20 5.74777 20ZM11.9978 20C11.5159 20 11.1042 19.8286 10.7625 19.4859C10.4208 19.1432 10.25 18.7313 10.25 18.25V5.75C10.25 5.26875 10.4216 4.85677 10.7647 4.51405C11.1079 4.17135 11.5204 4 12.0022 4C12.4841 4 12.8958 4.17135 13.2375 4.51405C13.5792 4.85677 13.75 5.26875 13.75 5.75V18.25C13.75 18.7313 13.5784 19.1432 13.2353 19.4859C12.8921 19.8286 12.4796 20 11.9978 20ZM18.2478 20C17.7659 20 17.3542 19.8286 17.0125 19.4859C16.6708 19.1432 16.5 18.7313 16.5 18.25V14.75C16.5 14.2688 16.6716 13.8568 17.0147 13.5141C17.3579 13.1714 17.7704 13 18.2522 13C18.7341 13 19.1458 13.1714 19.4875 13.5141C19.8292 13.8568 20 14.2688 20 14.75V18.25C20 18.7313 19.8284 19.1432 19.4853 19.4859C19.1421 19.8286 18.7296 20 18.2478 20Z"
        fill="currentColor"
      />
    </svg>
  ),
});
