import { ICrmError, useUpdateCrmErrorMutation } from "core/models/crmErrors";
import { useToast } from "core/hooks/useToast";
import { Button } from "@chakra-ui/react";

export const Issue = ({ issue }: { issue: ICrmError }) => {
  const [updateCrmError, { isLoading }] = useUpdateCrmErrorMutation();
  const toast = useToast();

  const onResolve = () => {
    updateCrmError({ appId: issue.appId, id: issue.id, isResolved: true })
      .unwrap()
      .then(() => {
        toast({
          title: "Issue resolved",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <div className="flex w-full items-start justify-between gap-4">
      <div className="flex flex-col gap-1">
        <p className="text-sm">{issue.errorMessage}</p>
        <p className="text-xs text-gray-600">
          {issue.occurrencesCount}{" "}
          {issue.occurrencesCount > 1 ? "occurrences" : "occurrence"} last
          happened {new Date(issue.lastOccurredAt).toLocaleString()}
        </p>
      </div>
      <Button
        className="flex-shrink-0"
        isLoading={isLoading}
        onClick={onResolve}
        size="xs"
      >
        Resolve
      </Button>
    </div>
  );
};
