import { cx } from "helpers/cx";
import { ITimerangeOption } from "core/types/TimerangeOption";
import { Tooltip } from "@chakra-ui/react";

function TimeRangePicker({
  setTimeRange,
  timerange,
  options,
}: {
  previewMode: boolean;
  setTimeRange: (timerange: ITimerangeOption) => void;
  timerange: ITimerangeOption;
  options: ITimerangeOption[];
}) {
  return (
    <div className="flex items-center rounded-md bg-gray-100 px-0.5">
      {options.map((o: ITimerangeOption, index: number) => {
        const isSelected = timerange.value === o.value;
        return (
          <Tooltip
            key={index}
            cursor="pointer"
            label={o.label}
            shouldWrapChildren
            hasArrow
            placement="top"
          >
            <div
              key={o.value}
              data-testid="time-range-picker-item"
              className={cx(
                "flex cursor-pointer items-center rounded-md px-1.5 py-1 text-xs",
                isSelected
                  ? "h-5 bg-white shadow-sm"
                  : "bg-transparent text-gray-500",
              )}
              onClick={() => setTimeRange(o)}
            >
              {o.short}
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
}

export default TimeRangePicker;
