import { ComparisonType, IFilter } from "core/types/Report";
import { TraitValueEditor } from "core/modules/audience/Filters/TraitValueEditor";
import { ITraitKeyResponse } from "core/models/traits";

interface ITraitFilterProps {
  filter: IFilter;
  traitValue: string | string[];
  setTraitValue: (value: string | string[]) => void;
  setFilter: (filter: IFilter) => void;
  onRemoveFilter: (filter: IFilter) => void;
  onSaveFilter: (filter: IFilter) => void;
  onSelectTrait: (trait: ITraitKeyResponse) => void;
  onChangeComparison: (comparison: ComparisonType) => void;
  onChangeTraitValue: (value: string) => void;
  isLast: boolean;
  isDisabled?: boolean;
  disabledTooltipText?: string;
  truncate?: boolean;
}

export const TraitFilter: React.FC<ITraitFilterProps> = ({
  filter,
  traitValue,
  setTraitValue,
  setFilter,
  onSelectTrait,
  onRemoveFilter,
  onChangeTraitValue,
  onSaveFilter,
  onChangeComparison,
  isDisabled = false,
  disabledTooltipText = "This filter has been disabled",
  isLast = false,
  truncate,
}) => {
  return (
    <div className="flex rounded-lg" data-testid="audience-trait-filters">
      {filter?.body?.trait && (
        <TraitValueEditor
          filter={filter}
          traitValue={traitValue}
          setTraitValue={setTraitValue}
          onChangeTraitValue={onChangeTraitValue}
          setFilter={setFilter}
          onSelectTrait={onSelectTrait}
          onRemoveFilter={onRemoveFilter}
          onSaveFilter={onSaveFilter}
          onChangeComparison={onChangeComparison}
          isDisabled={isDisabled}
          disabledTooltipText={disabledTooltipText}
          isLast={isLast}
          truncate={truncate}
        />
      )}
    </div>
  );
};
