import React from "react";
import moment from "moment";
import { EventActionsMenu } from "modules/Events/List/EventActionsMenu";
import AlertsCell from "modules/Events/List/AlertsCell";
import { IEvent } from "core/types/Event";
import Icon from "core/design-system/components/Icon";
import { TooltipBody } from "core/components/TooltipBody";
import { Flex, Link, Text, Tooltip } from "@chakra-ui/react";

export const Header: React.FC<{ event: IEvent; mutate: () => void }> = ({
  event,
  mutate,
}) => {
  return (
    <Flex justifyContent="space-between">
      <Flex direction="column">
        <Flex align="center">
          <Text mr={4} fontWeight="semibold" fontSize="xl">
            {event.name}
          </Text>
        </Flex>
        <Flex align="center" gap={2}>
          {event.firstSeenAt ? (
            <Text color="gray.600" fontSize="sm" w="fit-content">
              First triggered{" "}
              <Tooltip
                label={
                  <TooltipBody
                    text={moment
                      .utc(event.firstSeenAt)
                      .format("Do MMM, YYYY [at] h:mm a")}
                  />
                }
                placement="top"
                hasArrow
              >
                {moment.utc(event.firstSeenAt).fromNow()}
              </Tooltip>
            </Text>
          ) : (
            <Text color="gray.600" fontSize="sm" w="fit-content">
              Hasn't been triggered yet
            </Text>
          )}
          <Text color="gray.600" fontSize="sm">
            •
          </Text>
          {event?.isReceivingGroupContext ? (
            <Text color="gray.600" fontSize="sm">
              Group tracking
              <Icon
                ml={2}
                h={3}
                w={3}
                iconType="icon"
                name="check"
                color="success.400"
                data-testid="check-icon"
              />
            </Text>
          ) : (
            <Link
              isExternal
              href="https://www.june.so/docs/quickstart/track-event"
              fontSize="sm"
            >
              Set up group tracking
            </Link>
          )}
        </Flex>
      </Flex>
      <Flex>
        <AlertsCell props={event} mutate={mutate} />
        <EventActionsMenu event={event} />
      </Flex>
    </Flex>
  );
};
