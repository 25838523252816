import React from "react";
import { IAudience } from "core/types/Audience";
import { Button } from "@june-so/ui";
import { usePinAudienceMutation } from "core/models/audiences";
import { useParams } from "react-router-dom";
import { Tooltip } from "@chakra-ui/react";
import { BookmarkIcon } from "@heroicons/react/24/outline";
import { BookmarkSlashIcon } from "@heroicons/react/24/solid";

export const AudiencePinCell: React.FC<{ props: IAudience }> = ({
  props: audience,
}) => {
  const { appId } = useParams();
  const [pinAudience] = usePinAudienceMutation();

  function handlePin() {
    if (audience?.id === undefined) return null;

    pinAudience({
      appId: Number(appId),
      audienceId: audience.id,
      pinned: !audience.pinned,
    });
  }

  return (
    <Tooltip label={audience.pinned ? "Unpin audience" : "Pin audience"}>
      <Button variant="ghost" onClick={handlePin} className="text-gray-600">
        {audience.pinned ? (
          <BookmarkSlashIcon className="h-4 w-4" />
        ) : (
          <BookmarkIcon className="h-4 w-4" />
        )}
      </Button>
    </Tooltip>
  );
};
