export const mockData = [
  {
    id: 32967,
    traits: {
      email: "elisha.lynch.jr@example.org",
      firstName: "Elisha",
      lastName: "Lynch Jr.",
      userId: "80c93a6e-5880-4e33-b106-4369c671d741",
    },
    occurrences: 120,
    previewMode: true,
  },
  {
    id: 32968,
    traits: {
      email: "lacresha_west_sr@example.com",
      firstName: "Lacresha",
      lastName: "West Sr.",
      userId: "eddfd490-9f1f-421e-b69a-04d0d7077584",
    },
    occurrences: 176,
    previewMode: true,
  },
  {
    id: 32969,
    traits: {
      email: "odell_hoeger@example.net",
      firstName: "Odell Hoeger",
      lastName: null,
      userId: "afff8b8a-6a51-4766-9495-1eb10c63ed10",
    },
    occurrences: 43,
    previewMode: true,
  },
  {
    id: 32970,
    traits: {
      email: "milagro_greenholt_md@example.org",
      firstName: "Milagro Greenholt MD",
      lastName: null,
      userId: "7afb38eb-d20b-4100-90aa-798e9ae75b1f",
    },
    occurrences: 21,
    previewMode: true,
  },
  {
    id: 32971,
    traits: {
      email: "nakita_tremblay@example.com",
      firstName: "Nakita Tremblay",
      lastName: null,
      userId: "6952cf10-540b-491c-bbe1-2542038443ab",
    },
    occurrences: 56,
    previewMode: true,
  },
  {
    id: 32972,
    traits: {
      email: "ratke_jess@example.org",
      firstName: "Jess Ratke",
      lastName: null,
      userId: "49cb38ca-e3cc-46d6-9b34-43fba409a7d7",
    },
    occurrences: 13,
    previewMode: true,
  },
  {
    id: 32973,
    traits: {
      email: "dooley.rosetta.iii@example.org",
      firstName: "Rosetta Dooley III",
      lastName: null,
      userId: "34cf777a-850c-4afd-b913-1b836ddf2cec",
    },
    occurrences: 90,
    previewMode: true,
  },
  {
    id: 32974,
    traits: {
      email: "otelia.herzog.lld@example.net",
      firstName: "Otelia Herzog LLD",
      lastName: null,
      userId: "cb6c94b8-5ca8-4110-8f0e-8aa902d2cfce",
    },
    occurrences: 54,
    previewMode: true,
  },
  {
    id: 33418,
    traits: {
      email: "bernhard.sen.wesley@example.net",
      firstName: "Sen. Wesley Bernhard",
      lastName: null,
      userId: "0dbe2041-15fe-425f-8535-b1b29d5397ed",
    },
    occurrences: 22,
    previewMode: true,
  },
  {
    id: 33419,
    traits: {
      email: "prohaska.dwight@example.com",
      firstName: "Dwight Prohaska",
      lastName: null,
      userId: "d976ec78-6195-4dbe-b7d6-6d4ba8d06147",
    },
    occurrences: 63,
    previewMode: true,
  },
  {
    id: 33420,
    traits: {
      email: "howard_skiles@example.com",
      firstName: "Howard Skiles",
      lastName: null,
      userId: "5306beb0-8bb9-40b0-8b61-cde489a2235f",
    },
    occurrences: 9,
    previewMode: true,
  },
  {
    id: 33421,
    traits: {
      email: "hayes.fransisca@example.net",
      firstName: "Fransisca Hayes",
      lastName: null,
      userId: "0a4b4f83-4fc6-452b-8be2-8e2ee7ada1e0",
    },
    occurrences: 4,
    previewMode: true,
  },
  {
    id: 837791,
    traits: {
      email: null,
      firstName: null,
      lastName: null,
      userId: "test-user-23js8",
    },
    occurrences: 4,
    previewMode: true,
  },
  {
    id: 837938,
    traits: {
      email: null,
      firstName: null,
      lastName: null,
      userId: "test-user-23js8hsahdkjdl",
    },
    occurrences: 2,
    previewMode: true,
  },
];
