import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { IReport } from "core/types/Report";
import {
  IDrilldownGroup,
  useGroupDrilldown,
} from "core/hooks/useGroupDrilldown";
import { useAppObjects } from "core/hooks/useAppObjects";
import {
  generateColorForTraitValueWithIndex,
  getTraitValue,
} from "core/design-system/charts/Breakdown/utils";
import { ToggleButton } from "core/components/Toggle";
import { GroupListSingleGroup } from "core/components/Group/GroupListSingleGroup";
import Error from "core/components/Error";
import { Drilldown } from "core/components/Drilldown";
import { Box, Flex, UseDisclosureReturn } from "@chakra-ui/react";
import { FunnelBreakdownTraitSelect } from "../Funnel/FunnelBreakdownTraitSelect";

interface IFunnelGroup {
  groupId: string;
  groupType: number;
  traits: { [key: string]: string };
  value?: string;
}

interface IFunnelGroupsProps
  extends Pick<UseDisclosureReturn, "isOpen" | "onClose"> {
  report: IReport;
  convertedContactCount: number;
  droppedContactCount: number;
  eventIndex: number;
  humanizedEventName: string;
  showConverted: boolean;
  traitValues?: string[];
}

export const FunnelGroups: React.FC<IFunnelGroupsProps> = ({
  report,
  convertedContactCount,
  droppedContactCount,
  eventIndex,
  humanizedEventName,
  isOpen,
  onClose,
  showConverted = true,
  traitValues,
}) => {
  const { appId } = useParams<{ appId: string }>();
  const [converted, setConverted] = useState(showConverted);
  const _traitValues = traitValues || [];
  const [selectedTraitValues, setSelectedTraitValues] = useState<string[]>([]);
  const [funnelGroups, setFunnelGroups] = useState<IDrilldownGroup[]>([]);
  const contactCount = converted ? convertedContactCount : droppedContactCount;
  const contactAction = converted ? "performed" : "dropped off before";

  const insightType = 4;
  const { activeAppObject } = useAppObjects();

  const { groups, isLoading, error, hasMoreGroups, loadMore } =
    useGroupDrilldown({
      report,
      insightType,
      insightParams: {
        eventIndex,
        converted,
        traitValues: selectedTraitValues,
      },
      skip: !isOpen,
    });

  const handleToggle = (_converted: boolean) => {
    setConverted(_converted);
    setFunnelGroups([]);
  };

  const onDrilldownClose = () => {
    onClose();
    setConverted(true);
    setFunnelGroups([]);
  };

  useEffect(() => {
    setConverted(showConverted);
  }, [showConverted]);

  useEffect(() => {
    if (groups) {
      setFunnelGroups(groups);
    }
  }, [groups]);

  if (error) {
    return <Error />;
  }

  return (
    <Drilldown isOpen={isOpen} onClose={onDrilldownClose}>
      {(Drilldown) => (
        <>
          <Drilldown.Header>
            <Drilldown.Title
              isLoading={isLoading}
              title={`${contactCount} ${activeAppObject?.pluralName.toLowerCase()} ${contactAction} ${humanizedEventName}`}
            />
            <Box h={4}></Box>
            <Drilldown.Toggle>
              <ToggleButton
                isDisabled={false}
                isSelected={converted}
                onToggle={() => handleToggle(true)}
              >
                Converted
              </ToggleButton>
              <ToggleButton
                isDisabled={false}
                isSelected={!converted}
                onToggle={() => {
                  handleToggle(false);
                }}
              >
                Dropped
              </ToggleButton>
            </Drilldown.Toggle>
            {_traitValues.length > 0 && (
              <Flex w="full" align="center" justify="center">
                <FunnelBreakdownTraitSelect
                  labels={_traitValues}
                  selectedLabels={selectedTraitValues}
                  setSelectedLabels={setSelectedTraitValues}
                />
              </Flex>
            )}
          </Drilldown.Header>
          <Drilldown.Body>
            <Drilldown.List
              items={funnelGroups}
              hasMore={hasMoreGroups}
              itemRenderer={({ item }: { item: IFunnelGroup }) => (
                <GroupListSingleGroup
                  key={item.groupId}
                  id={item.groupId}
                  traits={item.traits}
                  groupType={item.groupType}
                  appId={Number(appId)}
                  traitValue={getTraitValue(item.value || "")}
                  traitColor={generateColorForTraitValueWithIndex(
                    item.value,
                    _traitValues.length -
                      _traitValues.indexOf(item.value || "") -
                      1,
                  )}
                />
              )}
              loadNext={loadMore}
              isLoading={isLoading}
              emptyStateText={`No ${activeAppObject?.pluralName.toLowerCase()} ${
                converted ? "converted" : "dropped"
              }`}
            />
          </Drilldown.Body>
        </>
      )}
    </Drilldown>
  );
};
