import React from "react";
import { ComponentDefaultProps } from "@chakra-ui/react";
import { ITitleProps, Title } from "./Title";
import { Loader } from "./Loader";
import { Header } from "./Header";
import { Container, IContainterProps } from "./Container";
import { Body, IBodyProps } from "./Body";

export const HighlightCard: React.FC<{
  children: ({
    Container,
    Header,
    Body,
    Loader,
    Title,
  }: {
    Container: React.FC<IContainterProps>;
    Header: React.FC<ComponentDefaultProps>;
    Body: React.FC<IBodyProps>;
    Loader: React.FC<ComponentDefaultProps>;
    Title: React.FC<ITitleProps>;
  }) => React.ReactElement;
}> = ({ children }) => {
  return children({
    Container,
    Header,
    Body,
    Loader,
    Title,
  });
};
