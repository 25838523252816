import { IContact } from "core/types/Contact";
import { getContactName, IGetContactNameProps } from "core/helpers/contactName";
import { getContactEmail } from "core/helpers/contactEmail";

export const getVisibleName = ({
  contactError,
  contact,
}: {
  contactError?: string;
  contact?: IContact;
}) => {
  if (contactError || !contact) return "Unknown";

  let name = getContactName(contact as IGetContactNameProps);
  if (name !== "Unknown") return name;

  return getContactEmail(contact);
};
