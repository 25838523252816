import React from "react";
import ApiKeys from "modules/Settings/ApiKeys";
import { LogoType } from "core/design-system/components/Icon/Icon.types";
import { KeyIcon } from "@heroicons/react/24/outline";
import * as Integration from "../../../modules/Settings/Integrations/Integration";

const CONTENT: IntegrationMetadata = {
  title: "Freshpaint",
  description: "Send events and customer data to June",
  logo: "companyFreshPaintLogo" as LogoType,
  docsUrl:
    "https://help.june.so/en/articles/6788912-how-to-connect-freshpaint-with-june",
};

const Freshpaint: React.FC = () => {
  return (
    <>
      <Integration.Container isExpanded>
        <Integration.Header metadata={CONTENT} />
        <Integration.Body>
          <Integration.Section>
            <Integration.Collapsable
              title={"Your write API keys"}
              headerIcon={<KeyIcon width={20} height={20} />}
            >
              <ApiKeys source={"freshpaint"} />
            </Integration.Collapsable>
          </Integration.Section>
        </Integration.Body>
      </Integration.Container>
    </>
  );
};

export default Freshpaint;
