import {
  IGetAudiencesResponse,
  useGetAudiencesQuery,
} from "core/models/audiences";

interface IUseAudiences {
  appId: number;
}
interface IUseAudiencesReturn {
  audiences: IGetAudiencesResponse | undefined;
  isLoading: boolean;
}

export const useAudiences = ({ appId }: IUseAudiences): IUseAudiencesReturn => {
  const { data, isLoading } = useGetAudiencesQuery({ appId });

  return { audiences: data, isLoading };
};
