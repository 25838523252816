import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
};

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    show: (state, { payload }) => {
      state.data = payload;
    },
  },
});

export const { show } = errorSlice.actions;
export const selector = (state) => state.error;
export default errorSlice.reducer;

export const showError =
  ({ title, action }) =>
  async (dispatch) => {
    dispatch(show({ title, action }));
  };
