import { Navigate, useParams } from "react-router-dom";
import { SetupFeature as SetupFeaturePage } from "modules/Onboarding/SetupFeature";
import { DEMO_APP_ID } from "core/constants/appIds";

export const SetupFeature: React.FC = () => {
  const { appId } = useParams<{ appId?: string }>();

  if (Number(appId) === DEMO_APP_ID) {
    return <Navigate to={`/a/${appId}/home`} />;
  }

  return <SetupFeaturePage />;
};
