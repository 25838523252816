import React from "react";
import {
  Flex,
  Text,
  Link,
  Skeleton,
  Button,
  Spinner,
  Spacer,
  Tooltip,
  Box,
} from "@chakra-ui/react";
import HubspotIcon from "core/design-system/components/Icon/Interface/HubspotIcon";
import { IHubspotContact } from "core/hooks/useHubspotContact";
import { IHubspotCompany } from "core/hooks/useHubspotCompany";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";
import { ArrowPathIcon, Cog6ToothIcon } from "@heroicons/react/24/outline";
import { AppObjectType } from "core/models/appObjects";
import {
  HubspotObjectTypes,
  hubspotObjectTypesSingular,
} from "core/hooks/useHubspotDeals";
import { IHubspotDeal } from "core/hooks/useHubspotDeals";
import { useHubspotProfile } from "core/hooks/useHubspotProfile";

export interface IHubspotProfileProps {
  isLoading: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  onCreate: () => void;
  onUpdate: (entityId: string) => void;
  contacts?: IHubspotContact[];
  companies?: IHubspotCompany[];
  deals?: IHubspotDeal[];
  type: HubspotObjectTypes;
  hubspotId?: string;
  lastSyncedAt?: string;
  refetch: () => void;
  fetchError: FetchBaseQueryError | SerializedError | undefined;
  createError: FetchBaseQueryError | SerializedError | undefined;
  updateError: FetchBaseQueryError | SerializedError | undefined;
  isCreatingDisabled: boolean;
  objectType?: AppObjectType;
  identifier?: string;
  showSettings?: boolean;
  showConnect?: boolean;
}

export const Profile: React.FC<IHubspotProfileProps> = ({
  isLoading,
  isCreating,
  isUpdating,
  contacts,
  companies,
  deals,
  onCreate,
  onUpdate,
  type,
  hubspotId,
  lastSyncedAt,
  refetch,
  fetchError,
  createError,
  updateError,
  isCreatingDisabled,
  objectType,
  identifier,
  showSettings,
  showConnect,
}) => {
  const currentApp = useCurrentApp();

  function onHandleError() {
    if (fetchError) {
      refetch();
    } else if (createError) {
      onCreate();
    }
  }

  function actionErrorMessage() {
    if (fetchError) return "loading";
    if (createError) return "creating";
    if (updateError) return "updating";
    return "";
  }

  const hasDataInHubSpot =
    (contacts?.length || companies?.length || deals?.length || 0) > 0;

  const {
    shouldCreateEntity,
    shouldUpdateEntity,
    shouldShowError,
    shouldShowLastSyncedAt,
  } = useHubspotProfile({
    isLoading,
    isCreating,
    hasDataInHubSpot,
    lastSyncedAt,
    fetchError,
    createError,
    updateError,
  });

  return (
    <Box
      bg="white"
      gridGap={2}
      data-testid="hubspot-profile-container"
      w="full"
    >
      <Flex align="center">
        <Flex>
          <HubspotIcon color="gray.500" w={4} h={4} />
        </Flex>
        <Flex w="full">
          {shouldCreateEntity() && (
            <Flex
              direction="column"
              gridGap={2}
              data-testid="hubspot-no-matches-found"
            >
              <Tooltip
                label={
                  isCreatingDisabled
                    ? `Creating is disabled because of missing "${identifier}" trait`
                    : ""
                }
                hasArrow
                shouldWrapChildren
              >
                <Button
                  data-testid="hubspot-create-button"
                  onClick={onCreate}
                  variant="ghost"
                  isDisabled={isCreatingDisabled}
                  ml={1}
                >
                  Create {hubspotObjectTypesSingular[type]} in HubSpot
                </Button>
              </Tooltip>
            </Flex>
          )}
          {isCreating && (
            <Button variant="ghost" ml={1} color="gray.500">
              <Spinner mr={2} size="xs" /> Creating {type}
            </Button>
          )}
          {shouldUpdateEntity() && (
            <Flex w="full" ml={2} align="center" justifyContent="space-between">
              <Flex direction="column" w="full">
                {(
                  contacts?.slice(0, 1) ||
                  companies?.slice(0, 1) ||
                  deals?.slice(0, 1)
                )?.map((entity: any, index: number) => (
                  <Flex w="full" justifyContent="space-between" gridGap={2}>
                    <Flex>
                      <Link
                        data-testid="hubspot-profile-link"
                        key={index}
                        color="black"
                        _hover={{ textDecoration: "underline" }}
                        href={
                          (contacts?.length || companies?.length || 0) > 1
                            ? `https://app-eu1.hubspot.com/contacts/${hubspotId}/objects/0-2/views/all/list?query=${
                                type === HubspotObjectTypes.Contacts
                                  ? entity.properties.email
                                  : objectType === AppObjectType.Company
                                    ? entity.properties.domain
                                    : entity.properties.name
                              }`
                            : `https://app.hubspot.com/contacts/${hubspotId}/${type}/${entity.id}`
                        }
                        isExternal
                        fontSize="sm"
                        fontWeight="normal"
                        noOfLines={
                          contacts?.length ||
                          companies?.length ||
                          deals?.length ||
                          0
                        }
                        maxW="185px"
                      >
                        {type === HubspotObjectTypes.Contacts
                          ? entity.properties.email
                          : type === HubspotObjectTypes.Deals
                            ? entity.properties.dealname
                            : objectType === AppObjectType.Company
                              ? entity.properties.domain
                              : entity.properties.name}{" "}
                        <ExternalLinkIcon mb={1} />{" "}
                        {(contacts?.length || companies?.length || 0) > 1 && (
                          <Text>
                            and{" "}
                            {(contacts?.length || companies?.length || 0) - 1}{" "}
                            other
                            {(contacts?.length || companies?.length || 0) - 1 >
                            1
                              ? "s"
                              : ""}
                          </Text>
                        )}
                      </Link>
                    </Flex>
                    <Spacer />
                    <Flex>
                      <Tooltip hasArrow label={`Sync to HubSpot`}>
                        <Button
                          data-testid="hubspot-profile-resync-button"
                          size="xs"
                          onClick={() => onUpdate(entity.id)}
                          variant="ghost"
                        >
                          {isUpdating ? (
                            <Spinner size="xs" />
                          ) : (
                            <ArrowPathIcon className="h-4 text-black" />
                          )}
                        </Button>
                      </Tooltip>
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          )}
          {shouldShowError() && (
            <Button
              data-testid="hubspot-retry-button"
              ml={1}
              onClick={onHandleError}
              variant="ghost"
              rightIcon={<ArrowPathIcon className="h-[14px] text-gray-500" />}
            >
              Try again
            </Button>
          )}
          {isLoading && (
            <Skeleton
              ml={2}
              data-testid="hubspot-skeleton"
              h="20px"
              w="200px"
              bg="gray.100"
              borderRadius="md"
            />
          )}
        </Flex>
        <Spacer />
        {showSettings && (
          <Flex>
            <Tooltip mr={-4} label="Open settings" shouldWrapChildren hasArrow>
              <Button
                mr={-4}
                as={RouterLink}
                variant="ghost"
                size="xs"
                data-testid="hubspot-settings-link"
                to={`/a/${currentApp.id}/settings/integrations/hubspot/${objectType}`}
              >
                <Cog6ToothIcon className="h-4 text-black" />
              </Button>
            </Tooltip>
          </Flex>
        )}
      </Flex>
      {shouldShowLastSyncedAt() && (
        <p className="-mt-0.5 ml-6 text-xs text-gray-500">
          Last synced {lastSyncedAt}
        </p>
      )}
      {shouldShowError() && (
        <Text
          ml={6}
          data-testid="hubspot-error-message"
          fontSize="xs"
          color="gray.600"
        >
          Something went wrong {actionErrorMessage()} the {type}
        </Text>
      )}
    </Box>
  );
};
