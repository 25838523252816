const sizes = {
  xxl: {
    dialog: { maxW: "6xl" },
  },
};

const Drawer = {
  sizes,
};

export default Drawer;
