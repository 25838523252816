import React from "react";
import { IntegrationMetadata } from "modules/Settings/Integrations/Integration";
import CreateApiKey from "modules/Settings/CreateApiKey";
import ApiKeysEmptyState from "modules/Settings/ApiKeysEmptyState";
import ApiKey from "modules/Settings/ApiKey";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useApiKeys } from "core/hooks/useApiKeys";
import { SDK } from "core/constants/apiKeys";
import { CodeBracketIcon } from "@heroicons/react/24/solid";
import { Text } from "@chakra-ui/react";
import * as Integration from "../../../modules/Settings/Integrations/Integration";

const CONTENT: IntegrationMetadata = {
  title: "API",
  description: "Interact with June via our APIs",
  logoComponent: <CodeBracketIcon width="100%" height="100%" />,
  isBeta: true,
  docsUrl: "https://help.june.so/en/articles/9832226-api-documentation",
};

const Api: React.FC = () => {
  const { id: appId } = useCurrentApp();
  const { apiKeys, isFetching } = useApiKeys({
    appId,
    keyType: "secret",
    source: SDK,
  });

  return (
    <Integration.Container isExpanded>
      <Integration.Header metadata={CONTENT} />
      <Integration.Body>
        <Integration.Section>
          <div className="mb-4 flex flex-col gap-2">
            <Text fontWeight="bold" mb={2}>
              Your Secret API Key:
            </Text>
            <div className="flex max-w-xl flex-col items-start gap-2">
              {isFetching ? (
                <div className="flex w-full flex-col gap-2">
                  <div className="flex h-[22px] animate-pulse flex-row items-center justify-center space-x-5">
                    <div className="h-full w-full rounded-md bg-gray-200"></div>
                  </div>
                  <div className="flex h-[22px] animate-pulse flex-row items-center justify-center space-x-5">
                    <div className="h-full w-full rounded-md bg-gray-300"></div>
                  </div>
                  <div className="flex h-[22px] animate-pulse flex-row items-center justify-center space-x-5">
                    <div className="h-full w-full rounded-md bg-gray-200"></div>
                  </div>
                </div>
              ) : (
                <>
                  {!isFetching && apiKeys?.length === 0 && (
                    <ApiKeysEmptyState />
                  )}
                  {apiKeys?.map((apiKey) => (
                    <ApiKey key={apiKey.id} isSecret apiKey={apiKey} />
                  ))}
                </>
              )}
            </div>
            <div className="flex max-w-lg items-start">
              <CreateApiKey source={SDK} keyType="secret" />
            </div>
          </div>
          <p className="text-sm text-gray-600">
            Use this API key to authenticate requests to the June API. Keep this
            key secure and do not share it publicly.
          </p>
        </Integration.Section>
      </Integration.Body>
    </Integration.Container>
  );
};

export default Api;
