import { useNavigate } from "react-router";
import { ReactChild } from "react";
import Icon from "core/design-system/components/Icon";
import { Template } from "core/constants/templates";
import { Box, Button, Stack, StyleProps, Text } from "@chakra-ui/react";
import HighlightCard from "./";

export interface IEmptyStateProps extends StyleProps {
  appId: string | number;
  reportName?: string;
  label?: ReactChild;
  button?: ReactChild | undefined;
  template?: Template;
}

export const EmptyState: React.FC<IEmptyStateProps> = ({
  appId,
  reportName,
  template,
  label,
  button,
  ...styleProps
}) => {
  const navigate = useNavigate();
  const { setIsNewReportModalOpen } = useNavigation();

  return (
    <HighlightCard>
      {(hc) => (
        <hc.Container
          justifyContent="center"
          border="none"
          boxShadow="none"
          {...styleProps}
        >
          <Stack
            data-testid="highlight-card-empty-state"
            direction="column"
            spacing={4}
            align="center"
            justifyContent="center"
          >
            {label ? (
              <>{label}</>
            ) : (
              <Text fontSize="lg" fontWeight="semibold" textAlign="center">
                Discover who your{" "}
                <Text as="span" color="purple.500">
                  {reportName}
                </Text>{" "}
                are?
              </Text>
            )}
            <Box>
              {button ? (
                <>{button}</>
              ) : (
                <Button
                  color="purple.500"
                  bg="gray.50"
                  rightIcon={<Icon iconType="icon" name="arrow-right-2" />}
                  onClick={() => {
                    setIsNewReportModalOpen(true);
                    if (template) {
                      navigate({
                        search: `?${new URLSearchParams({ template: template }).toString()}`,
                      });
                    }
                    // eslint-disable-next-line no-undef
                    window.analytics.track(
                      "report_created_from_home",
                      {
                        app_id: appId,
                      },
                      { context: { groupId: appId } },
                    );
                  }}
                >
                  Setup report
                </Button>
              )}
            </Box>
          </Stack>
        </hc.Container>
      )}
    </HighlightCard>
  );
};
