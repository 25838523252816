import { Link } from "react-router-dom";
import React from "react";
import moment from "moment";
import PropertiesCell from "modules/Feed/List/PropertiesCell";
import ContactNameCell from "modules/Contacts/ContactNameCell";
import { pluralize } from "helpers/pluralize";
import { Unit } from "core/types/Unit";
import { useGroup } from "core/hooks/useGroup";
import { useEventDrilldown } from "core/hooks/useEventDrilldown";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { IntervalTypeNames } from "core/constants/timerange";
import Error from "core/components/Error";
import { Drilldown } from "core/components/Drilldown";
import { Box, Flex, Text } from "@chakra-ui/react";
import { TimeIcon } from "@chakra-ui/icons";
import { IEventListProps } from "./ContactEventList";
import { EventBlock } from "../EventBlock";

export const GroupEventList: React.FC<IEventListProps> = ({
  date,
  eventsCount,
  humanizedDate,
  id,
  intervalType,
  isOpen,
  onClose,
  filterEvents,
}) => {
  const { id: appId } = useCurrentApp();
  const { group } = useGroup({ id });
  const visibleName = group?.name || "Unknown";

  const { events, isLoading, error, hasMoreEvents, loadMore } =
    useEventDrilldown({
      skip: !isOpen,
      unit: Unit.Companies,
      params: {
        id,
        date,
        intervalType,
        filterEvents,
      },
    });

  if (error) {
    return <Error />;
  }

  return (
    <Drilldown isOpen={isOpen} onClose={onClose} size="xl">
      {(Drilldown) => (
        <>
          <Drilldown.Header>
            <Drilldown.Title
              title={`${eventsCount} ${pluralize(eventsCount, "event", "events")} ${pluralize(
                eventsCount,
                "was",
                "were",
              )} triggered on ${humanizedDate} by ${visibleName}`}
              isLoading={isLoading}
            />
          </Drilldown.Header>
          <Drilldown.Body pt={8}>
            <Drilldown.List
              items={events}
              itemRenderer={({ item, index }) => (
                <Flex
                  w="100%"
                  p={2}
                  align="center"
                  justify="space-between"
                  border="1px"
                  borderRadius="lg"
                  borderColor="white"
                  _hover={{ borderColor: "gray.200" }}
                  data-testid={`selector-not-clickable-event-${id}`}
                  gridGap={2}
                >
                  <ContactNameCell
                    props={{ ...item.contact }}
                    minW="260px"
                    w="260px"
                  />
                  <Box w="180px">
                    <Link key={index} to={`/a/${appId}/event/${item.id}`}>
                      <EventBlock
                        _hover={{ bg: "purple.50" }}
                        name={item.name}
                        maxWidth={"180px"}
                        tooltipText={item.name}
                      />
                    </Link>
                  </Box>
                  <Flex direction="column" justifyContent="center" w="120px">
                    {intervalType !== IntervalTypeNames.DAY && (
                      <Text fontSize="sm" color={"gray.600"}>
                        {moment(item.date).format("Do MMM, YYYY")}
                      </Text>
                    )}
                    <Flex align="center" gridGap={1}>
                      <TimeIcon w={3} h={3} color="gray.500" />
                      <Text fontSize="sm" color={"gray.500"}>
                        {moment(item.date).format("HH:mm")}
                      </Text>
                    </Flex>
                  </Flex>
                  <PropertiesCell
                    props={{ properties: item.properties }}
                    justifyContent={"flex-end"}
                    width="100px"
                    minW={"100px"}
                  />
                </Flex>
              )}
              hasMore={hasMoreEvents}
              isLoading={isLoading}
              loadNext={loadMore}
              emptyStateText={"No event triggered"}
            />
          </Drilldown.Body>
        </>
      )}
    </Drilldown>
  );
};
