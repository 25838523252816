import React from "react";
import { cx } from "helpers/cx";

interface ItemTextProps {
  children: React.ReactNode;
  className?: string;
}

export const ItemText: React.FC<ItemTextProps> = ({ children, className }) => {
  return (
    <span
      className={cx(
        "max-w-[210px] text-ellipsis text-[13px] font-medium",
        className,
        "text-gray-900",
      )}
    >
      {children}
    </span>
  );
};
