import { useEffect, useState } from "react";
import humps from "humps";
import axios from "core/initializers/axios";
import { useMountedState } from "./useMountedState";
import { useCurrentApp } from "./useCurrentApp";

export interface IMilestone {
  name: string;
  completed: boolean;
  configured: boolean;
}

export interface IMilestones {
  appId: number;
  reportId: number;
  reportName: string;
  groupId?: string;
  contactId?: string;
  milestones: IMilestone[];
}

interface IUseMilestonesProps {
  contactId: string;
}

export const useContactMilestones = ({ contactId }: IUseMilestonesProps) => {
  const { id: appId } = useCurrentApp();
  const [milestones, setMilestones] = useState<IMilestones[]>();
  const isMounted = useMountedState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    if (!milestones) {
      axios
        .get(`/contacts/${encodeURIComponent(contactId)}/milestones`, {
          params: humps.decamelizeKeys({ appId }),
        })
        .then((response) => {
          if (isMounted() && response.status === 200) {
            const milestones = response?.data || [];
            setMilestones(milestones);
          }
        })
        .catch((error) => setError(error))
        .finally(() => setIsLoading(false));
    }
  }, [milestones, isMounted, appId, contactId]);

  return {
    milestones,
    isLoading,
    error,
  };
};
