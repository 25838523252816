import { MappingData } from "modules/Settings/Crm/SyncSettings/MappingData";
import { Integration } from "core/models/dataMappings";
import { IAppObject } from "core/models/appObjects";
import useFlag from "core/hooks/useFlag";
import { HUBSPOT_DEALS } from "core/constants/features";
import { SelectObject } from "./SelectObject";

export const GroupData: React.FC<{
  appObject: IAppObject;
}> = ({ appObject }) => {
  const canUseHubspotDeals = useFlag(HUBSPOT_DEALS);

  return (
    <>
      {canUseHubspotDeals && <SelectObject appObject={appObject} />}
      <MappingData appObject={appObject} integration={Integration.Hubspot} />
    </>
  );
};
