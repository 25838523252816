import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { ISidebarProps, Sidebar } from "modules/Navigation/Sidebar";
import { inIframe } from "helpers/iframe";
import { useNavigation } from "core/hooks/useNavigation";
import { Box } from "@chakra-ui/react";

function Navbar() {
  const location = useLocation();
  const [currentSection, setCurrentSection] =
    useState<ISidebarProps["currentSection"]>("Reports");
  const isInIframe = inIframe();
  const [isNavigationDisabled, setIsNavigationDisabled] = useState(isInIframe);
  const { navigation } = useNavigation();

  useEffect(() => {
    const getCurrentSection = () => {
      if (isInIframe) return;

      if (
        location.pathname.includes("onboarding") ||
        location.pathname.includes("success") ||
        location.pathname.includes("widgets") ||
        location.pathname.includes("choose-setup") ||
        location.pathname.includes("setup-feature") ||
        location.pathname.includes("join") ||
        location.pathname.includes("settings")
      ) {
        setIsNavigationDisabled(true);
        return;
      } else {
        setIsNavigationDisabled(false);
      }
      if (location.pathname.includes("discover")) {
        return "Discover";
      }
      if (location.pathname.includes("home")) {
        return "Home";
      }
      if (location.pathname.includes("reports/preview")) {
        return "New report";
      }
      if (location.pathname.includes("report")) {
        return "Reports";
      }
      if (
        location.pathname.includes("contacts") ||
        location.pathname.includes("contact")
      ) {
        return "People";
      }
      if (location.pathname.includes("companies")) {
        return "People";
      }
      if (
        location.pathname.includes("events") ||
        location.pathname.includes("event")
      ) {
        return "Events";
      }
      if (location.pathname.includes("settings")) {
        return "Settings";
      }
      if (location.pathname.includes("feed")) {
        return "Feed";
      }
      if (
        location.pathname.includes("templates") ||
        location.pathname.includes("template")
      ) {
        return "Templates";
      }
      if (location.pathname.includes("people")) {
        return "People";
      }
      if (location.pathname.includes("getting-started")) {
        return "Getting started";
      }
      if (location.pathname.includes("explore")) {
        return "AIExplorer";
      }

      return `Home`;
    };
    const newCurrentSection = getCurrentSection();
    setCurrentSection(newCurrentSection!);
  }, [location, location.pathname, isInIframe]);

  return (
    <Box
      position="fixed"
      zIndex={"overlay"}
      h="100%"
      minHeight="100vh"
      visibility={isNavigationDisabled ? "hidden" : "visible"}
      w={navigation.isOpen ? "300px" : "70px"}
      overflow="scroll"
    >
      {!isNavigationDisabled && <Sidebar currentSection={currentSection} />}
    </Box>
  );
}

export default Navbar;
