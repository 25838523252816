import React from "react";
import { Box, BoxProps, StyleProps, Text, Tooltip } from "@chakra-ui/react";

interface IEventProps extends StyleProps, BoxProps {
  name: string;
  maxWidth?: string;
  tooltipText?: string;
}

export const EventBlock: React.FC<IEventProps> = ({
  name,
  tooltipText,
  maxWidth,
  ...styleProps
}) => {
  return (
    <Tooltip label={tooltipText}>
      <Box
        p={1}
        px={2}
        bg="white"
        shadow="sm"
        color={"primary"}
        fontSize="sm"
        width="fit-content"
        borderRadius="sm"
        fontWeight="medium"
        {...styleProps}
      >
        <Text noOfLines={1} maxW={maxWidth ? maxWidth : "220px"}>
          {name}
        </Text>
      </Box>
    </Tooltip>
  );
};
