import { useState, useEffect } from "react";
import { useNavigation } from "./useNavigation";
import { useScreenSize } from "core/hooks/useScreenSize";

export const useCompanyColumns = () => {
  const [columns, setColumns] = useState(3);
  const { navigation } = useNavigation();
  const { screenWidth } = useScreenSize();

  useEffect(() => {
    if (screenWidth < (navigation.isOpen ? 1730 : 1470)) {
      setColumns(2);
    } else {
      setColumns(3);
    }
  }, [screenWidth, navigation]);

  return { columns };
};
