import { useEffect, useState } from "react";
import moment from "moment";
import CompanyLink from "modules/Companies/List/CompanyLink";
import { IReportInsight } from "core/types/Report";
import { mockData } from "core/modules/reports/report-types/SlippingAwayGroups/mockData";
import { LastEventReceived } from "core/modules/reports/report-types/SlippingAway/LastEventReceived";
import FirstSeenDateCell from "core/modules/reports/report-types/SlippingAway/FirstSeenDateCell";
import { useQueryParam } from "core/hooks/useQueryParam";
import useInsightDataFetching from "core/hooks/useInsightDataFetching";
import { isScreenshotMode } from "core/design-system/constants/charts";
import { LoadMoreButton } from "core/design-system/components/LoadMoreButton";
import { IListConfig } from "core/components/List/List.types";
import List from "core/components/List";
import InsightCard from "core/components/InsightCard/Index";
import { CSVExport } from "core/components/CSVExport";
import { Flex } from "@chakra-ui/react";
import {
  ISlippingAwayGroup,
  ISlippingAwayGroupsData,
} from "./SlippingAwayGroups.types";
import { GroupLink } from "./GroupLink";

const DEFAULT_PAGE_SIZE = 30;

interface IGetListConfigArgs {
  events: (string | undefined)[];
  previewMode: boolean;
  sharingMode: boolean;
  isCompanyLevel: boolean;
}

const listConfig = ({
  events,
  previewMode,
  sharingMode,
  isCompanyLevel,
}: IGetListConfigArgs): IListConfig => {
  const LinkComponent = isCompanyLevel ? CompanyLink : GroupLink;
  return {
    resourceName: "companies",
    columns: [
      {
        title: "",
        name: "name",
        Component: LinkComponent,
        width: 240,
        align: "left",
        previewMode: previewMode,
        sharingMode: sharingMode,
      },
      {
        title: "Last event",
        name: "daysSinceLastEvent",
        Component: LastEventReceived,
        width: 100,
        align: "left",
        sortable: true,
      },
      {
        title: "First seen",
        name: "firstSeenAt",
        Component: FirstSeenDateCell,
        width: 130,
        align: "left",
      },
      {
        title: "Total events",
        name: "eventCount",
        width: 130,
        align: "center",
        fontSize: "sm",
        sortable: true,
        tooltip: `Amount of times the company performed ${events.join(", ")}`,
      },
    ],
  };
};

const sortCompanies = ({
  slippingAwayCompanies,
  sortedBy,
}: {
  slippingAwayCompanies: ISlippingAwayGroupsData;
  sortedBy: { name: string; direction: string };
}) => {
  return slippingAwayCompanies
    .map((v) => ({ ...v, groupId: v.id }))
    .sort((a: ISlippingAwayGroup, b: ISlippingAwayGroup) => {
      const name = sortedBy.name as keyof typeof a;
      if (sortedBy.direction === "asc") return a[name] > b[name] ? 1 : -1;

      return a[name] < b[name] ? 1 : -1;
    });
};

function GroupList({
  sharingSecretToken,
  previewMode,
  sharingMode,
  report,
  config,
  screenshotMode,
}: IReportInsight) {
  const [page, setPage] = useState(0);
  const [hasMoreCompanies, setHasMoreCompanies] = useState(false);
  const [slippingAwayCompanies, setSlippingAwayCompanies] =
    useState<ISlippingAwayGroupsData>([]);
  const [sortedBy, setSortedBy] = useState({
    name: "daysSinceLastEvent",
    direction: "asc",
  });
  const insight = config.insights.find(
    ({ slug }: { slug: string }) => slug === "slipping-away-companies",
  );
  const isSetupValid = config.validateSetup(report.config);
  const events = report?.config?.events?.map(({ name }) => name) || [];
  const levelQueryParam = useQueryParam("level");
  const isCompanyLevel = levelQueryParam === "company";

  const { response, isFetching, error, onRefreshInsight } =
    useInsightDataFetching({
      insight,
      report,
      sharingSecretToken,
      previewMode,
      insightType: insight!.typeId,
      insightParams: {
        limit: isScreenshotMode ? 5 : DEFAULT_PAGE_SIZE,
        page,
      },
      skip: !isSetupValid,
    });

  const rowData = sortCompanies({ slippingAwayCompanies, sortedBy });

  const rowDataToCSV = rowData.map((row) => [
    row.name ? row.name : `id: ${row.id}`,
    row.daysSinceLastEvent,
    moment.utc(row.firstSeenAt).format("MMMM DD, YYYY"),
    row.eventCount,
  ]);

  const headers = [
    "company",
    "last_event_received",
    "first_seen",
    "last_seen",
    "total_events",
  ];

  const loadNextCompanies = () => {
    if (slippingAwayCompanies.length < 100) setPage(page + 1);
  };

  const hasMoreData =
    response?.data?.slippingGroups?.length !== 0 ||
    response?.data?.slippingGroups?.length > DEFAULT_PAGE_SIZE;

  function getSlippingAwayCompanies() {
    return page === 0
      ? response.data.slippingGroups
      : slippingAwayCompanies.concat(response.data.slippingGroups);
  }

  useEffect(() => {
    if (previewMode) {
      return setSlippingAwayCompanies(
        screenshotMode ? mockData.slice(0, 5) : mockData,
      );
    }

    if (!response) return;
    if (response.data.page !== page) return;

    setHasMoreCompanies(hasMoreData);
    const companies = sortCompanies({
      slippingAwayCompanies: getSlippingAwayCompanies(),
      sortedBy,
    });
    setSlippingAwayCompanies(companies);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, page]);

  return (
    <InsightCard>
      {(Card: any) => (
        <>
          <Card.Container insight={insight} id={insight!.slug}>
            <Card.Header
              hasCaching={response?.hasCaching}
              screenshotMode={screenshotMode}
              title={
                sharingMode
                  ? `Companies churn risk - ${report.name}`
                  : `Companies churn risk`
              }
              insight={insight}
              showActions={!sharingMode && !previewMode}
              sharingMode={sharingMode}
              mb={0}
              config={config}
              report={report}
              refresh={onRefreshInsight}
              cachedAt={response?.cachedAt}
            >
              <Flex>
                <CSVExport
                  disabled={previewMode || sharingMode}
                  data={rowDataToCSV}
                  headers={headers}
                  filename={`${report?.name}-${insight!.slug}.csv`}
                />
              </Flex>
            </Card.Header>
            <Card.Body
              insight={insight}
              isLoading={isFetching && page === 0}
              isPreviewMode={previewMode}
              isSetupValid={isSetupValid}
              error={error}
            >
              <List
                border="none"
                id="loaded"
                rows={rowData}
                config={listConfig({
                  events,
                  previewMode,
                  sharingMode,
                  isCompanyLevel,
                })}
                sortedBy={sortedBy}
                setSortedBy={setSortedBy}
              />
            </Card.Body>
          </Card.Container>
          {!previewMode &&
            !sharingMode &&
            hasMoreCompanies &&
            !(page === 0 && isFetching) && (
              <Flex w="100%" justifyContent="center" gridColumn="span 2">
                <LoadMoreButton
                  isLoading={isFetching}
                  onClick={loadNextCompanies}
                />
              </Flex>
            )}
        </>
      )}
    </InsightCard>
  );
}

export default GroupList;
