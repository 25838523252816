import { useParams } from "react-router-dom";
import React from "react";
import { ITemplateConfig } from "core/types/TemplateConfig";
import { IReport } from "core/types/Report";
import { useGroupDrilldown } from "core/hooks/useGroupDrilldown";
import { useAppObjects } from "core/hooks/useAppObjects";
import { Toggle, ToggleButton } from "core/components/Toggle";
import { GroupListSingleGroup } from "core/components/Group/GroupListSingleGroup";
import Error from "core/components/Error";
import { Drilldown } from "core/components/Drilldown";

interface IOverviewGroups {
  report: IReport;
  config: ITemplateConfig;
  isOpen: boolean;
  onClose: () => void;
  milestone: string | undefined;
  milestoneName: string | undefined;
  milestoneConvertedCount: number;
  milestoneDroppedCount: number;
  showCompleted: boolean;
  setShowCompleted: (completed: boolean) => void;
}

export const OverviewGroups: React.FC<IOverviewGroups> = ({
  report,
  isOpen,
  onClose,
  milestone,
  milestoneName,
  milestoneConvertedCount,
  milestoneDroppedCount,
  showCompleted,
  setShowCompleted,
}) => {
  const { appId } = useParams<{ appId: string; reportId: string }>();
  const { activeAppObject } = useAppObjects();
  const count = showCompleted ? milestoneConvertedCount : milestoneDroppedCount;

  const insightType = 10;

  const { groups, isLoading, error, hasMoreGroups, loadMore } =
    useGroupDrilldown({
      report,
      insightType,
      insightParams: {
        milestone,
        completed: showCompleted,
      },
      skip: !isOpen,
    });

  const handleToggle = (_completed: boolean) => {
    setShowCompleted(_completed);
  };

  const onDrilldownClose = () => {
    onClose();
    setShowCompleted(true);
  };

  if (error) {
    return <Error />;
  }

  return (
    <Drilldown isOpen={isOpen} onClose={onDrilldownClose}>
      {(Drilldown) => (
        <>
          <Drilldown.Header>
            <Drilldown.Title
              isLoading={isLoading}
              title={`${count} ${activeAppObject?.pluralName.toLowerCase()} ${
                showCompleted ? "completed" : "have not completed"
              } the milestone ${milestoneName}`}
            />
            <Toggle h={"2rem"} mt={6}>
              <ToggleButton
                isSelected={showCompleted}
                onToggle={() => handleToggle(true)}
              >
                Completed
              </ToggleButton>
              <ToggleButton
                isSelected={!showCompleted}
                onToggle={() => handleToggle(false)}
              >
                Uncompleted
              </ToggleButton>
            </Toggle>
          </Drilldown.Header>
          <Drilldown.Body>
            <Drilldown.List
              items={groups}
              hasMore={hasMoreGroups}
              itemRenderer={({ item }) => {
                return (
                  <GroupListSingleGroup
                    key={item.groupId}
                    id={item.groupId}
                    traits={item.traits}
                    groupType={item.groupType}
                    appId={Number(appId)}
                  />
                );
              }}
              loadNext={loadMore}
              isLoading={isLoading}
              emptyStateText={`No ${activeAppObject?.pluralName.toLowerCase()}}`}
            />
          </Drilldown.Body>
        </>
      )}
    </Drilldown>
  );
};
