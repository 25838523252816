import React from "react";
import { IReportInsight } from "core/types/Report";
import { dnuMockData } from "core/modules/reports/report-types/NewUsers/mockData";
import LineChart from "core/modules/reports/report-types/NewUsers/LineChart";
import { DAU_OPTIONS, INTERVAL_TYPES } from "core/constants/timerange";
import { LineContainer } from "core/components/LineContainer";

export const DnuContainer: React.FC<IReportInsight> = ({
  config,
  ...props
}) => {
  const insight = config.insights.find(
    ({ slug }: { slug: string }) => slug === "daily-new-users",
  );

  if (!insight) return null;

  return (
    <LineContainer
      LineChart={LineChart}
      insight={insight}
      initialTimeRange={"SEVEN_DAYS"}
      timeRangeOptions={DAU_OPTIONS}
      intervalType={INTERVAL_TYPES.DAY}
      keyAccessor={"newUsers"}
      intervalPeriod={"ONE_DAY"}
      mockData={dnuMockData}
      config={config}
      {...props}
    />
  );
};
