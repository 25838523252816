import React from "react";
import { IGroup } from "core/types/Group";
import { hashStringToNumber } from "core/helpers/contactAvatar";
import AvatarIcon from "core/design-system/components/Icon/AvatarIcon";
import { Flex, StyleProps } from "@chakra-ui/react";
import { OnlineDot } from "./OnlineDot";

interface IContactAvatar extends StyleProps {
  group?: { id: string } | IGroup;
  size?: string;
  unidentified?: boolean;
  isOnline?: boolean;
  logoURL?: string;
}

export const CompanyAvatar: React.FC<IContactAvatar> = ({
  group,
  size = "lg",
  ml = 0,
  unidentified,
  logoURL,
  isOnline,
  ...styleProps
}) => {
  const buildings = [
    "alien",
    "bear",
    "cat",
    "dog",
    "dragon",
    "fox",
    "ghost",
    "koala",
    "monkey",
    "panda",
    "pig",
    "robot",
    "rooster",
    "tiger",
    "giraffe",
  ];
  const colors = ["green", "red", "blue", "yellow", "purple", "gray"];
  const isExtraSmall = size === "xs";
  const isSmall = size === "sm";
  const isMedium = size === "md";

  const containerSize =
    (isMedium && 16) || (isSmall && 8) || (isExtraSmall && 5) || 24;
  const avatarSize =
    (isMedium && 10) || (isSmall && 6) || (isExtraSmall && 4) || 16;

  if (logoURL) {
    return (
      <Flex
        data-testid="company-logo"
        position={"relative"}
        bgImage={logoURL}
        bgSize="contain"
        bgPos="center"
        align="center"
        justify="center"
        h={containerSize}
        w={containerSize}
        borderRadius="full"
        border="4px solid white"
        // For PNG logos
        bgColor={"white"}
        bgRepeat={"no-repeat"}
        {...styleProps}
      >
        {isOnline && <OnlineDot />}
      </Flex>
    );
  }

  if (!group || unidentified) {
    return (
      <Flex
        align="center"
        justify="center"
        h={containerSize}
        w={containerSize}
        ml={ml}
        borderRadius="100%"
        {...styleProps}
      >
        <AvatarIcon h={avatarSize} w={avatarSize} name="ghost" />
      </Flex>
    );
  }

  const groupId =
    typeof group.id === "number" ? group.id : hashStringToNumber(group.id);

  return (
    <Flex
      position={"relative"}
      bg={`${colors[groupId % colors.length]}.50`}
      align="center"
      justify="center"
      h={containerSize}
      w={containerSize}
      borderRadius="100%"
      {...styleProps}
    >
      <AvatarIcon
        h={avatarSize}
        w={avatarSize}
        name={buildings[groupId % buildings.length]}
      />
      {isOnline && <OnlineDot />}
    </Flex>
  );
};

export default CompanyAvatar;
