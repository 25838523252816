import { cx } from "helpers/cx";
import { ComparisonType, IFilter } from "core/types/Report";
import { ITraitKeyResponse } from "core/models/traits";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { Tooltip } from "@chakra-ui/react";
import { Value } from "./TraitFilter/Value";
import { Trait } from "./TraitFilter/Trait";
import { Icon } from "./TraitFilter/Icon";
import { Comparison } from "./TraitFilter/Comparison";

interface ITraitValueEditorProps {
  filter: IFilter;
  traitValue: string | string[];
  setTraitValue: (value: string | string[]) => void;
  setFilter: (filter: IFilter) => void;
  onSaveFilter: (filter: IFilter) => void;
  onRemoveFilter: (filter: IFilter) => void;
  onSelectTrait: (trait: ITraitKeyResponse) => void;
  onChangeComparison: (comparison: ComparisonType) => void;
  onChangeTraitValue: (value: string) => void;
  isLast: boolean;
  isDisabled?: boolean;
  disabledTooltipText?: string;
  truncate?: boolean;
}

export const TraitValueEditor: React.FC<ITraitValueEditorProps> = ({
  filter,
  setFilter,
  traitValue,
  setTraitValue,
  onSaveFilter,
  onRemoveFilter,
  onChangeComparison,
  onSelectTrait,
  onChangeTraitValue,
  isDisabled = false,
  disabledTooltipText,
  isLast,
  truncate,
}) => {
  const { trait } = filter.body;
  const comparisonType = filter.body.comparisonType || 0;

  return (
    <Tooltip
      label={isDisabled ? disabledTooltipText : ""}
      shouldWrapChildren
      hasArrow
    >
      <div
        className={cx(
          "flex w-full items-center gap-0.5  px-2 py-1",
          isDisabled && "opacity-30",
          isLast && "rounded-lg",
        )}
      >
        <div>
          <Icon filterType={filter.type} />
        </div>
        <div>
          <Trait trait={trait || ""} onSelectTrait={onSelectTrait} />
        </div>
        <div>
          <Comparison
            comparisonType={comparisonType}
            onSelectComparisonType={onChangeComparison}
          />
        </div>
        <div>
          <Value
            value={traitValue}
            setValue={setTraitValue}
            comparisonType={comparisonType}
            filter={filter}
            setFilter={setFilter}
            onChangeTraitValue={onChangeTraitValue}
            onSaveFilter={onSaveFilter}
            truncate={truncate}
          />
        </div>
        <Tooltip label="Remove filter" placement="top" hasArrow>
          <div
            className="cursor-pointer px-0.5 py-1 text-gray-500 hover:text-gray-800"
            onClick={() => onRemoveFilter(filter)}
            data-testid="audience-remove-trait-button"
          >
            <XMarkIcon className="h-4" />
          </div>
        </Tooltip>
      </div>
    </Tooltip>
  );
};
