import MilestoneEmptyState from "core/design-system/components/Icon/Illustrations/MilestonesEmptyState.svg";

import { Box, Center, Text } from "@chakra-ui/react";

interface IEmptyStateProps {
  description: string;
}

export const EmptyState: React.FC<IEmptyStateProps> = ({ description }) => {
  return (
    <Center p={4} h="full" flexDirection="column">
      <Box
        h="80%"
        w="full"
        backgroundImage={MilestoneEmptyState}
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        bgSize="contain"
      ></Box>
      <Text textAlign="center" fontSize="sm" color="gray.600" mt={6}>
        {description}
      </Text>
    </Center>
  );
};
