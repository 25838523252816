import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import { Unit } from "core/types/Unit";
import { IInsightSection } from "core/types/TemplateConfig";
import { IReportInsight } from "core/types/Report";
import useInsightDataFetching from "core/hooks/useInsightDataFetching";
import { RELATIVE } from "core/constants/timerange";
import InsightCard from "core/components/InsightCard/Index";
import { ChartBarIcon } from "@heroicons/react/24/outline";
import { Flex, Skeleton, Switch, Text, useDisclosure } from "@chakra-ui/react";
import { FunnelGroups } from "./FunnelGroups";
import { mockData } from "../Funnel/mockData";
import { FunnelChart } from "../Funnel/FunnelChart";

declare global {
  interface Window {
    analytics: any;
  }
}

const BarChartGraph: React.FC<IReportInsight> = ({
  report,
  sharingSecretToken = null,
  previewMode,
  sharingMode,
  config,
  screenshotMode,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { appId } = useParams<{ appId: string }>() as {
    appId: string;
  };

  const insight = config.insights.find(
    ({ slug }) => slug === "company-funnel" || slug === "company-funnel-test",
  ) as IInsightSection;
  const isSetupValid = config.validateSetup(report.config);
  const {
    response: data,
    isLoading,
    isFetching,
    error,
    onRefreshInsight,
  } = useInsightDataFetching({
    insight,
    report,
    sharingSecretToken,
    previewMode,
    skip: !isSetupValid,
  });

  const [eventIndex, setEventIndex] = useState<number>();
  const [humanizedEventName, setHumanizedEventName] = useState("");
  const [convertedContactCount, setConvertedContactCount] = useState(0);
  const [droppedContactCount, setDroppedContactCount] = useState(0);
  const [showConverted, setShowConverted] = useState(false);
  const [showVertical, setShowVertical] = useState(false);
  const graphData = previewMode ? mockData : data;
  const barData = graphData?.data?.eventBars || [];
  const traitValues = barData[0]?.traitValues || [];
  const barCount = graphData?.data?.eventBars?.length || 0;
  const startDate = moment(graphData?.data?.startDate).format("DD MMM, YYYY");
  const endDate = moment(graphData?.data?.endDate).format("DD MMM, YYYY");
  const conversionPercentage = graphData?.data?.overallConversionPercentage;

  useEffect(() => {
    setShowVertical(barCount > 5);
  }, [barCount]);

  return (
    <InsightCard>
      {(Card) => (
        <Card.Container insight={insight} id={insight.slug}>
          <Card.Header
            hasCaching={data?.hasCaching}
            screenshotMode={screenshotMode}
            showReportTitle={sharingMode}
            insight={insight}
            showActions={!sharingMode && !previewMode}
            sharingMode={sharingMode}
            config={config}
            report={report}
            refresh={onRefreshInsight}
            cachedAt={data?.cachedAt}
          >
            {!sharingMode && (
              <Flex ml={4} gridGap={2}>
                <Flex>
                  <Text fontSize="sm" color="gray.600">
                    Vertical funnel
                  </Text>
                </Flex>
                <Flex>
                  <Switch
                    colorScheme="purple"
                    size="md"
                    isChecked={showVertical}
                    onChange={(e) => {
                      setShowVertical(e.currentTarget.checked);
                      window.analytics.track(
                        "funnel_view_toggled",
                        {
                          view: e.currentTarget.checked
                            ? "vertical"
                            : "horizontal",
                          appId: appId,
                        },
                        { context: { groupId: appId } },
                      );
                    }}
                  />
                </Flex>
              </Flex>
            )}
          </Card.Header>
          <Card.Body
            isLoading={!data || isLoading || isFetching}
            isPreviewMode={previewMode}
            isSetupValid={isSetupValid}
            error={error}
          >
            <Flex justify="center" align="center">
              {graphData ? (
                <>
                  {graphData.data.eventBars.length === 0 ? (
                    <Card.EmptyState IconComponent={ChartBarIcon} />
                  ) : (
                    <Flex
                      direction="column"
                      w="100%"
                      className="recharts-responsive-container"
                      id="loaded"
                    >
                      <Flex
                        direction="column"
                        align="center"
                        justify="center"
                        bg="purple.50"
                        py={2}
                      >
                        <Text color="purple.600" px={1} fontSize="sm">
                          <Text as="span" fontWeight="bold">
                            {conversionPercentage}% overall conversion rate
                          </Text>{" "}
                          {report?.timerangeType === RELATIVE
                            ? `since ${startDate}`
                            : `between ${startDate} and ${endDate}`}
                        </Text>
                      </Flex>
                      <Flex h={showVertical ? "inherit" : "400px"}>
                        <FunnelChart
                          unit={Unit.Companies}
                          onOpen={onOpen}
                          graphData={graphData}
                          setEventIndex={setEventIndex}
                          setHumanizedEventName={setHumanizedEventName}
                          setConvertedContactCount={setConvertedContactCount}
                          setDroppedContactCount={setDroppedContactCount}
                          setShowConverted={setShowConverted}
                          isVertical={showVertical}
                          traitValues={traitValues}
                        />
                      </Flex>
                    </Flex>
                  )}
                </>
              ) : (
                <Skeleton borderRadius="lg" mt={5}>
                  <Flex h="500px" w="800px" />
                </Skeleton>
              )}
            </Flex>
            {!sharingMode &&
              !previewMode &&
              isOpen &&
              typeof eventIndex === "number" && (
                <FunnelGroups
                  report={report}
                  isOpen={isOpen}
                  onClose={onClose}
                  eventIndex={eventIndex}
                  humanizedEventName={humanizedEventName}
                  convertedContactCount={convertedContactCount}
                  droppedContactCount={droppedContactCount}
                  showConverted={showConverted}
                  traitValues={traitValues}
                />
              )}
          </Card.Body>
          {insight.description && !screenshotMode && (
            <Card.Footer bg="white">
              <Card.DescriptionAccordion insight={insight} />
            </Card.Footer>
          )}
        </Card.Container>
      )}
    </InsightCard>
  );
};

export default BarChartGraph;
