import { Link } from "react-router-dom";
import React from "react";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { IView } from "core/models/views";

export const NameCell: React.FC<{ props: IView }> = ({
  props: { id, name },
}) => {
  const { id: appId } = useCurrentApp();

  return (
    <Link to={`/a/${appId}/home/${id}`}>
      <div className="flex items-center gap-4">
        <p className="text-sm">{name}</p>
      </div>
    </Link>
  );
};
