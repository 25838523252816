import { IReport } from "core/types/Report";
import { classNames } from "core/helpers/classNames";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";

export const ReportResultOption: React.FC<{
  result: IReport;
  active: boolean;
}> = ({ result, active }) => {
  return (
    <>
      <span className="flex-auto truncate">{result.name}</span>
      {active && (
        <ChevronRightIcon
          className="ml-3 size-5 flex-none text-gray-400"
          aria-hidden="true"
        />
      )}
    </>
  );
};

export const ReportResultOptions: React.FC<{ searchResults: IReport[] }> = ({
  searchResults,
}) => {
  return (
    <>
      {searchResults.map((result: IReport) => (
        <Combobox.Option
          as="div"
          key={result.id}
          value={result}
          className={({ active }) =>
            classNames(
              "flex cursor-default select-none items-center rounded-md p-2",
              active && "bg-gray-100 text-gray-900",
            )
          }
        >
          {({ active }) => (
            <ReportResultOption result={result as IReport} active={active} />
          )}
        </Combobox.Option>
      ))}
    </>
  );
};
