import { IBlockComponent } from "../ConversationContainer";
import { Flex } from "@chakra-ui/react";
import { Message } from "../Message";

export const Loader: React.FC<IBlockComponent> = ({ delay, isReverse }) => {
  return (
    <Message delay={delay} isReverse={isReverse} w="65px" h="35px">
      <Flex ml={4} mt={1.5} className="dot-falling-purple" />
    </Message>
  );
};
