import { IAudienceFilters } from "core/types/Audience";
import { FilterType } from "core/constants/report-setup";
import { hasFiltersByFilterType } from "core/helpers/hasAudienceFiltersByFilterType";

export const useConflictingAudienceFilters = ({
  audienceFilters,
  level,
}: {
  audienceFilters: IAudienceFilters;
  level: "user" | "company" | "group";
}) => {
  const hasGroupFilters = hasFiltersByFilterType(
    audienceFilters,
    FilterType.GroupFilter,
  );
  const hasCompanyFilters = hasFiltersByFilterType(
    audienceFilters,
    FilterType.CompanyFilter,
  );

  const hasConflictingAudienceFilters =
    (level === "company" && hasGroupFilters) ||
    (level === "group" && hasCompanyFilters);

  return {
    hasConflictingAudienceFilters,
  };
};
