import { Badge, Box, Flex, Text } from "@chakra-ui/react";
import { EnrichedEmail } from "./EnrichedEmail";

interface IEnrichmentCardProps {
  email?: string;
}

export const EnrichmentCard: React.FC<IEnrichmentCardProps> = ({ email }) => {
  if (!email) {
    return (
      <Box
        p={4}
        border="1px"
        borderColor="gray.50"
        boxShadow="sm"
        borderRadius="lg"
      >
        <Flex mb={3} justifyContent="space-between" align="center">
          <Flex gap={2}>
            <Badge colorScheme="purple">✨ AI</Badge>
            <Text fontWeight="medium">Enrichment</Text>
          </Flex>
        </Flex>

        <Text fontSize={"sm"} color="gray.600">
          We can't enrich this user because they don't have an email.
        </Text>
      </Box>
    );
  }

  return <EnrichedEmail email={email} />;
};
