import {
  ITemplateConfigSetup,
  ITemplateConfigSetupSection,
} from "core/types/TemplateConfig";
import { IEmptyReportState, IReport } from "core/types/Report";
import { EventSetupPreview } from "core/modules/reports/setup/Setup/HeaderPreviews/EventSetupPreview";
import {
  ANY,
  AUDIENCE_TYPE,
  BREAKDOWN_TYPE,
  ENUM_TYPE,
  FIRST,
  PAGE,
  TRACK,
} from "core/constants/report-setup";
import { Box, Flex, Text } from "@chakra-ui/react";
import { EnumPreview } from "./HeaderPreviews/EnumPreview";
import { AudiencePreview } from "./HeaderPreviews/AudiencePreview";
import { Pill as BreakdownPill } from "../Breakdown/Pill";
import { AudienceCountPreview } from "../Audience/CountPreview";

interface ISetupAccordionHeaderPreviewProps {
  configKey: string;
  isExpanded: boolean;
  currentReport: IReport | IEmptyReportState;
  setup: ITemplateConfigSetup;
  section: ITemplateConfigSetupSection;
}

export const SetupAccordionHeaderPreview: React.FC<
  ISetupAccordionHeaderPreviewProps
> = ({ isExpanded, currentReport, configKey, setup, section }) => {
  const { config } = currentReport;

  function computeHasAudienceFilters() {
    if (!currentReport.audience?.filterGroups) return false;
    return (
      currentReport.audience?.filterGroups?.[0]?.filters?.map(
        (i) => i.type === Number(AUDIENCE_TYPE),
      )?.length > 0
    );
  }

  const hasAudienceFilters = computeHasAudienceFilters();

  const isEnum =
    configKey === ENUM_TYPE &&
    config &&
    configKey &&
    config[configKey] &&
    config[configKey][0].type === ENUM_TYPE;

  const isEventSetup =
    ([
      "events",
      "starting_events",
      "milestone1",
      "milestone2",
      "milestone3",
      "milestone4",
      "milestone5",
    ].includes(configKey) ||
      (section.isDynamic && configKey.includes(section.configKey))) &&
    config[configKey] &&
    config[configKey].length &&
    config[configKey][0].type &&
    (config[configKey][0].type === TRACK ||
      config[configKey][0].type === PAGE ||
      config[configKey][0].type === FIRST ||
      config[configKey][0].type === ANY);

  const isBreakdown = Boolean(configKey === BREAKDOWN_TYPE && config.breakdown);

  const isAudience = configKey === AUDIENCE_TYPE;

  if (isEnum) {
    return (
      <>
        {!isExpanded && (
          <EnumPreview section={section} item={config[configKey][0]} />
        )}
      </>
    );
  }

  if (isEventSetup) {
    return (
      <>
        {!isExpanded && (
          <EventSetupPreview setup={setup} events={config[configKey]} />
        )}
      </>
    );
  }

  if (isBreakdown) {
    return (
      <>
        {!isExpanded && (
          <Box as="span" display="contents">
            <Flex display="block">
              <BreakdownPill
                isRemoveable={false}
                breakdown={config.breakdown}
                containerProps={{ bg: "gray.100", boxShadow: "none" }}
              />
            </Flex>
          </Box>
        )}
      </>
    );
  }
  if (isAudience) {
    return (
      <>
        {!isExpanded && (
          <>
            {currentReport.audience?.filterGroups &&
            !currentReport?.audience?.name ? (
              <AudiencePreview
                audienceFilters={{
                  filterGroups: currentReport.audience?.filterGroups,
                  joinOperator: currentReport.audience?.joinOperator,
                }}
                display="flex"
                flexFlow="wrap"
              />
            ) : null}
            {currentReport?.audience?.name && (
              <Flex bg="gray.100" borderRadius="md" px={3} py={1.5}>
                <Text fontWeight="medium" fontSize="sm">
                  {currentReport?.audience?.name}
                </Text>
              </Flex>
            )}
          </>
        )}
        <Flex mt={hasAudienceFilters ? 2 : 0}>
          {currentReport.audience?.filterGroups && (
            <AudienceCountPreview
              audienceFilters={{
                filterGroups: currentReport.audience?.filterGroups,
                joinOperator: currentReport.audience?.joinOperator,
              }}
            />
          )}
        </Flex>
      </>
    );
  }
  return null;
};
