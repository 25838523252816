import { useParams } from "react-router-dom";
import React, { useState } from "react";
import moment from "moment";
import { CrmHeader } from "modules/Contacts/Contact/CrmHeader";
import { FavouritesMenuItem } from "modules/Company/FavouritesMenuItem";
import { FavouriteObjectType } from "core/models/favourites";
import useFlag from "core/hooks/useFlag";
import { useCompany } from "core/hooks/useCompany";
import { useAppObjects } from "core/hooks/useAppObjects";
import VerticalDotsIcon from "core/design-system/components/Icon/Interface/VerticalDotsIcon";
import TeamIcon from "core/design-system/components/Icon/Interface/TeamIcon";
import { FAVOURITES } from "core/constants/features";
import {
  ArrowsRightLeftIcon,
  GlobeAltIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  StyleProps,
  Text,
} from "@chakra-ui/react";
import { TimeIcon } from "@chakra-ui/icons";
import { MergeCompanyModal } from "./MergeCompanyModal";
import { Logo as CompanyLogo } from "./Logo";
import { DeleteCompanyModal } from "./DeleteCompanyModal";

const GRADIENT_HEIGHT = 20;

export const Header: React.FC<StyleProps> = ({ ...styleProps }) => {
  const { companyAppObject } = useAppObjects();
  const { appId, groupId: encodedGroupId } = useParams<{
    appId: string;
    groupId: string;
  }>() as {
    appId: string;
    groupId: string;
  };
  const hasFavouritesEnabled = useFlag(FAVOURITES);
  const groupId = decodeURIComponent(encodedGroupId);
  const { company } = useCompany({
    appId: Number(appId),
    id: groupId,
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showMergeModal, setShowMergeModal] = useState(false);

  if (!company || !companyAppObject) {
    return (
      <Flex
        bgGradient="linear(to-r, purple.200 20%, purple.100 55%, white 100%)"
        p={8}
        w="100%"
        direction="column"
        borderRadius="lg"
        boxShadow="sm"
        {...styleProps}
        border="1px solid gray.100"
      >
        <Flex alignItems="center">
          <CompanyLogo />
          <Box ml={4}>
            <Text fontSize="lg" fontWeight={"normal"}>
              Unknown
            </Text>
          </Box>
        </Flex>
      </Flex>
    );
  }

  const companyContactsCount = company.contacts?.length;

  if (company) {
    document.title = `June - ${company.name}`;
  }

  return (
    <Flex
      border="1px solid"
      borderColor="gray.50"
      borderRadius="lg"
      boxShadow="sm"
    >
      <Flex
        bg="white"
        zIndex={1}
        w="100%"
        direction="column"
        borderRadius="lg"
        {...styleProps}
      >
        <Box
          w="100%"
          h={`20px`}
          top={`-${GRADIENT_HEIGHT}px`}
          borderTopRadius="lg"
          bgGradient="linear(to-r, purple.200 20%, purple.100 55%, purple.50 100%)"
        />
        <Flex justify={"space-between"} align="flex-start">
          <Flex alignItems="center" mb={6} px={8} pt={8}>
            <Flex gridGap={4} align="center">
              <Flex>
                <CompanyLogo
                  border="4px solid"
                  borderColor="white"
                  boxShadow="sm"
                />
              </Flex>
              <Flex direction="column">
                <Flex>
                  <Text noOfLines={1} fontSize="md" fontWeight={"medium"}>
                    {company.name}
                  </Text>
                </Flex>
                {company.firstSeenAt && (
                  <Flex>
                    <Text color="gray.600" fontSize="sm">
                      Joined{" "}
                      {moment.utc(company.firstSeenAt).format("DD MMM, YYYY")}
                    </Text>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>
          <Menu>
            <MenuButton
              as={Button}
              float="right"
              aria-label="Edit user menu"
              mt={2}
              mr={2}
              variant="ghost"
            >
              <VerticalDotsIcon
                fontSize="lg"
                fontWeight="medium"
                color="black"
              />
            </MenuButton>
            <MenuList>
              <MenuItem
                icon={<TrashIcon className="h-4" />}
                onClick={() => setShowDeleteModal(!showDeleteModal)}
              >
                <Text fontSize="sm">Delete company</Text>
              </MenuItem>
              <MenuItem
                icon={<ArrowsRightLeftIcon className="h-4" />}
                onClick={() => setShowMergeModal(!showMergeModal)}
              >
                <Text fontSize="sm">Merge company</Text>
              </MenuItem>

              {hasFavouritesEnabled && (
                <FavouritesMenuItem
                  objectType={FavouriteObjectType.Company}
                  appId={Number(appId)}
                  objectId={groupId}
                />
              )}
            </MenuList>
          </Menu>
        </Flex>
        <Flex direction="column" px={8} gridGap={1}>
          <Flex align="flex-start" gridGap={2}>
            <Flex>
              <GlobeAltIcon className="size-5 text-gray-600" />
            </Flex>
            <div className="flex flex-col gap-1">
              {company?.domains?.map((domain) => (
                <div className="truncate text-ellipsis text-sm">{domain}</div>
              ))}
            </div>
          </Flex>
          <Flex align="center" gridGap={2}>
            <Flex>
              <TeamIcon fontSize="lg" color="gray.600" />
            </Flex>
            <Flex>
              <Text fontSize="sm" color="gray.700">
                {companyContactsCount} user
                {companyContactsCount === 1 ? "" : "s"}
              </Text>
            </Flex>
          </Flex>
          {company.lastSeenAt && (
            <Flex align="center" gridGap={2}>
              <Flex>
                <TimeIcon mx={0.5} fontSize="sm" color="gray.600" />
              </Flex>
              <Flex>
                <Text fontSize="sm" color="gray.700">
                  Last seen{" "}
                  {moment
                    .utc(company.lastSeenAt)
                    .format("DD MMM, YYYY [at] hh:mm a")}
                </Text>
              </Flex>
            </Flex>
          )}
        </Flex>
        <div className="my-2" />
        {company && companyAppObject && (
          <CrmHeader entity={company} appObject={companyAppObject} />
        )}

        <DeleteCompanyModal
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
        />
        <MergeCompanyModal
          isOpen={showMergeModal}
          onClose={() => setShowMergeModal(false)}
        />
      </Flex>
    </Flex>
  );
};
