import { useParams } from "react-router-dom";
import React from "react";
import { IReport } from "core/types/Report";
import { useInsightCsvExport } from "core/hooks/useInsightCsvExport";
import { useGroupDrilldown } from "core/hooks/useGroupDrilldown";
import { useAppObjects } from "core/hooks/useAppObjects";
import { GroupListSingleGroup } from "core/components/Group/GroupListSingleGroup";
import Error from "core/components/Error";
import { Drilldown } from "core/components/Drilldown";
import { UseDisclosureReturn } from "@chakra-ui/react";

export interface IActiveGroup {
  groupId: string;
  newGroup: string;
  groupType: number;
  traits: { [key: string]: string };
  isNewGroup?: "yes" | "no";
}

interface IActiveUsersListProps
  extends Pick<UseDisclosureReturn, "isOpen" | "onClose"> {
  report: IReport;
  key: string;
  activeGroupsCount: number;
  date: string;
  showNew?: boolean;
  humanizedDate: string;
  intervalType: number;
  hour?: string;
}

export const ActiveGroupsList: React.FC<IActiveUsersListProps> = ({
  isOpen,
  onClose,
  report,
  intervalType,
  activeGroupsCount,
  humanizedDate,
  date,
  hour,
  showNew,
}) => {
  const { appId } = useParams<{ appId: string }>();
  const { activeAppObject } = useAppObjects();

  const getInsightParams = () => {
    const baseParams = { intervalType, showNew, date };

    return hour !== "" ? { ...baseParams, hour } : baseParams;
  };

  const insightType = 12;
  const {
    groups: activeGroups,
    isLoading,
    error,
    hasMoreGroups,
    loadMore,
  } = useGroupDrilldown({
    report,
    insightType,
    insightParams: getInsightParams(),
    skip: !isOpen,
  });

  const { requestCsvExport, csvExportLoading } = useInsightCsvExport({
    report,
    insightId: insightType,
    insightParams: getInsightParams(),
    count: activeGroupsCount,
  });

  if (error) {
    return <Error />;
  }

  return (
    <Drilldown isOpen={isOpen} onClose={onClose}>
      {(Drilldown) => (
        <>
          <Drilldown.Header>
            <Drilldown.Title
              title={`There are ${activeGroupsCount} active ${activeAppObject?.pluralName.toLowerCase()} for ${humanizedDate}`}
              isLoading={isLoading}
            />
            <Drilldown.DownloadButton
              isDisabled={activeGroupsCount <= 0}
              isLoading={csvExportLoading}
              onClick={requestCsvExport}
            />
          </Drilldown.Header>
          <Drilldown.Body pt={4}>
            <Drilldown.List
              items={activeGroups}
              itemRenderer={({ item }: { item: IActiveGroup }) => (
                <GroupListSingleGroup
                  key={item.groupId}
                  id={item.groupId}
                  traits={item.traits}
                  groupType={item.groupType}
                  appId={Number(appId)}
                  isNewGroup={item.isNewGroup}
                />
              )}
              hasMore={hasMoreGroups}
              isLoading={isLoading}
              loadNext={loadMore}
              emptyStateText={`No active ${activeAppObject?.pluralName.toLowerCase()}`}
            />
          </Drilldown.Body>
        </>
      )}
    </Drilldown>
  );
};
