export const USER = {
  value: "user",
  label: "Users",
  description:
    "Users who have been identified with a user_id from your system.",
  type: 0,
};

export const VISITOR = {
  value: "visitor",
  label: "Visitors",
  description:
    "Users who have been assigned an anonymous_id by Segment, and not yet identified with a user_id from your system.",
  type: 1,
  isDisabled: true,
};

export const EVERYONE = {
  value: "everyone",
  label: "Everyone",
  description: "Both users with user ids and visitors with anonymous ids.",
  type: 1,
  isDisabled: true,
};

export const USER_TYPE_OPTIONS = [
  {
    options: [USER, VISITOR, EVERYONE],
  },
];
