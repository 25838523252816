import { Link } from "react-router-dom";
import React from "react";
import moment from "moment";
import PropertiesCell from "modules/Feed/List/PropertiesCell";
import { IFilterEvents } from "modules/Contacts/Contact/ContactActivity";
import { pluralize } from "helpers/pluralize";
import { Unit } from "core/types/Unit";
import { useEventDrilldown } from "core/hooks/useEventDrilldown";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useContact } from "core/hooks/useContact";
import { getVisibleName } from "core/helpers/contacts/getVisibleName";
import { IntervalTypeNames } from "core/constants/timerange";
import Error from "core/components/Error";
import { Drilldown } from "core/components/Drilldown";
import { Box, Flex, Text } from "@chakra-ui/react";
import { TimeIcon } from "@chakra-ui/icons";
import { EventBlock } from "../EventBlock";

export interface IEventListProps {
  date: string;
  eventsCount: number;
  humanizedDate: string;
  id: string;
  intervalType: IntervalTypeNames;
  isOpen: boolean;
  onClose: () => void;
  filterEvents: IFilterEvents;
}

export const ContactEventList: React.FC<IEventListProps> = ({
  date,
  eventsCount,
  humanizedDate,
  id,
  intervalType,
  isOpen,
  onClose,
  filterEvents,
}) => {
  const { id: appId } = useCurrentApp();
  const { contact, contactError } = useContact({ appId, id });
  const visibleName = getVisibleName({ contactError, contact });

  const { events, isLoading, error, hasMoreEvents, loadMore } =
    useEventDrilldown({
      skip: !isOpen,
      unit: Unit.Users,
      params: {
        id,
        date,
        intervalType,
        filterEvents,
      },
    });

  if (error) {
    return <Error />;
  }

  return (
    <Drilldown isOpen={isOpen} onClose={onClose}>
      {(Drilldown) => (
        <>
          <Drilldown.Header>
            <Drilldown.Title
              title={`${eventsCount} ${pluralize(eventsCount, "event", "events")} ${pluralize(
                eventsCount,
                "was",
                "were",
              )} triggered on ${humanizedDate} by ${visibleName}`}
              isLoading={isLoading}
            />
          </Drilldown.Header>
          <Drilldown.Body pt={8}>
            <Drilldown.List
              items={events}
              itemRenderer={({ item, index }) => (
                <Link key={index} to={`/a/${appId}/event/${item.id}`}>
                  <Flex
                    w="100%"
                    p={2}
                    align="center"
                    justify="space-between"
                    border="1px"
                    borderRadius="lg"
                    borderColor="white"
                    _hover={{ borderColor: "gray.200" }}
                    data-testid={`selector-not-clickable-event-${id}`}
                    gridGap={2}
                  >
                    <Box w="40%">
                      <EventBlock
                        _hover={{ bg: "purple.50" }}
                        name={
                          item.name
                            ? item.name
                            : item.properties.title ||
                              item.properties.path ||
                              item.properties.url
                        }
                        maxWidth={"100%"}
                        tooltipText={
                          item.name
                            ? item.name
                            : item.properties.title ||
                              item.properties.path ||
                              item.properties.url
                        }
                      />
                    </Box>
                    <Flex gridGap={2} justifyContent="flex-start">
                      {intervalType !== IntervalTypeNames.DAY && (
                        <Text fontSize="sm" color={"gray.600"}>
                          {moment.utc(item.date).format("DD MMM, YYYY")}
                        </Text>
                      )}
                      <Flex align="center" gridGap={1}>
                        <TimeIcon w={3} h={3} color="gray.500" />
                        <Text fontSize="sm" color={"gray.500"}>
                          {moment.utc(item.date).format("hh:mm a")}
                        </Text>
                      </Flex>
                    </Flex>
                    <PropertiesCell
                      props={{ properties: item.properties }}
                      justifyContent={"center"}
                    />
                  </Flex>
                </Link>
              )}
              hasMore={hasMoreEvents}
              isLoading={isLoading}
              loadNext={loadMore}
              emptyStateText={"No event triggered"}
            />
          </Drilldown.Body>
        </>
      )}
    </Drilldown>
  );
};
