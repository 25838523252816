import { useSelector } from "react-redux";
import { selector as errorSelector } from "core/models/error";
import ErrorPage from "Components/Error/Page";

function Error() {
  const { data: error } = useSelector(errorSelector);

  return (
    <>{error && <ErrorPage title={error.title} action={error.action} />}</>
  );
}

export default Error;
