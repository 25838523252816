import React from "react";
import { ISticknessRatioData } from "core/types/LineChart";
import { Flex } from "@chakra-ui/react";
import { splitCurrentPeriodAndFormatData } from "./lineChartHelpers";
import CurrentPeriodLineChart from "./CurrentPeriodLineChart";

function DauWau({
  data,
  previewMode,
  sharingMode,
}: {
  data: ISticknessRatioData;
  previewMode: boolean;
  sharingMode: boolean;
}) {
  const [startOfWeek, formattedData] = splitCurrentPeriodAndFormatData(
    data.data,
  );

  return (
    <Flex direction="column" bg="white" borderRadius="lg">
      <Flex
        borderTopRightRadius="lg"
        borderTopLeftRadius="lg"
        direction="column"
        justify="center"
      >
        {data?.data && (
          <CurrentPeriodLineChart
            unit="% DAU / WAU"
            graphId="DAU / WAU"
            data={formattedData}
            currentPeriodReferenceLineValue={startOfWeek}
            currentPeriodTooltipText="The ratio is incomplete because the WAU for the current week changes every day."
            withDrawer={false}
            previewMode={previewMode}
            sharingMode={sharingMode}
          />
        )}
      </Flex>
    </Flex>
  );
}

export default DauWau;
