import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { IUser } from "core/types/User";

export const RedirectToMyWorkspace: React.FC<{ user: IUser }> = ({ user }) => {
  const navigate = useNavigate();

  const location = useLocation();

  function replacePath(path: string, appId: number) {
    const regexLong = /^\/redirect-to-my-workspace\/(.*)$/;
    const regexShort = /^\/_\/(.*)$/;
    return path
      .replace(regexLong, `/a/${appId}/$1`)
      .replace(regexShort, `/a/${appId}/$1`);
  }

  const redirectUrl = replacePath(location.pathname, user.lastUsedApp.id);

  useEffect(() => {
    if (redirectUrl) {
      navigate(redirectUrl, { replace: true });
    }
  }, [user, navigate, redirectUrl, location]);

  return null;
};
