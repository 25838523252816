import React from "react";
import { Select } from "@chakra-ui/react";

interface IOption {
  label: string;
  value: string;
}

interface ISelectChannelProps {
  options: IOption[];
  defaultOption?: IOption;
  onChange: (channelId: string) => void;
}

export const SelectChannel: React.FC<ISelectChannelProps> = ({
  options,
  defaultOption,
  onChange,
}) => {
  return (
    <Select
      size="sm"
      width="200px"
      placeholder="Select a channel"
      defaultValue={defaultOption?.value}
      onChange={(e) => onChange(e.target.value)}
    >
      {options.map((option: IOption, index: number) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  );
};
