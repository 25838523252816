import { Navigate } from "react-router-dom";
import { useCurrentApp } from "core/hooks/useCurrentApp";

const Groups: React.FC = () => {
  const { id: appId } = useCurrentApp();
  const hash = window.location.pathname.replace(`/a/${appId}/groups`, "");

  return <Navigate to={`/a/${appId}/people:${hash.slice(1)}`} />;
};

export default Groups;
