import { IReport } from "core/types/Report";
import { useGetReportTokenQuery } from "core/models/reports";
import ShareIcon from "core/design-system/components/Icon/Interface/ShareIcon";
import ShareModal from "core/components/ShareModal";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { MenuItem, Tooltip, useDisclosure } from "@chakra-ui/react";

export const ShareItem: React.FC<{ report: IReport }> = ({ report }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: reportToken, isLoading } = useGetReportTokenQuery({
    appId: report.appId,
    reportId: report.id,
  });

  const tokenData = reportToken?.token ? reportToken.token : undefined;

  return (
    <MenuItem onClick={onOpen}>
      <Tooltip
        label={`Share this report`}
        placement="left"
        shouldWrapChildren
        hasArrow
      >
        <div className={"flex items-center gap-2"}>
          {tokenData ? (
            <>
              <GlobeAltIcon className="h-4" />
              <span>Public</span>
            </>
          ) : (
            <>
              <ShareIcon
                h="18px"
                w="18px"
                color="gray.900"
                _hover={{ color: "gray.700" }}
              />
              <span>Share</span>
            </>
          )}
        </div>
      </Tooltip>
      {!isLoading && (
        <ShareModal
          title={report.name}
          unit={"report"}
          isOpen={isOpen}
          isSharing={Boolean(tokenData)}
          onClose={onClose}
          tokenData={tokenData}
        />
      )}
    </MenuItem>
  );
};
