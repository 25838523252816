import { ComponentDefaultProps } from "@chakra-ui/react";
import { FavouriteObjectType, IFavourite } from "core/models/favourites";
import { ContactItem } from "./ContactItem";
import { CompanyItem } from "./CompanyItem";
import { GroupItem } from "./GroupItem";
import { ReportItem } from "./ReportItem";

export const Item: React.FC<
  { favourite: IFavourite } & ComponentDefaultProps
> = ({ favourite }) => {
  if (favourite.objectType === FavouriteObjectType.User) {
    return <ContactItem favourite={favourite} />;
  }
  if (favourite.objectType === FavouriteObjectType.Company) {
    return <CompanyItem favourite={favourite} />;
  }
  if (favourite.objectType === FavouriteObjectType.Group) {
    return <GroupItem favourite={favourite} />;
  }
  if (favourite.objectType === FavouriteObjectType.Report) {
    return <ReportItem favourite={favourite} />;
  }
  return <div>Item</div>;
};
