import { SnakeCasedProperties } from "type-fest/source/snake-cased-properties";
import humps from "humps";
import { baseQuery } from "core/initializers/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";

interface IUpdateSettingsArgs {
  appId: number;
  autoUpdatesEnabled: boolean;
  autoCreatesEnabled: boolean;
  autoAssociatesEnabled: boolean;
  companyIdentifier: string;
  hubspotCompanyIdentifier: string;
  isSyncingUsers?: boolean;
  isSyncingWorkspaces?: boolean;
  isSyncingCompanies?: boolean;
}

interface IUpdateSettingsResponse {
  hubspotId: string;
  autoUpdatesEnabled: boolean;
  autoCreatesEnabled: boolean;
  autoAssociatesEnabled: boolean;
  companyIdentifier: string;
  hubspotCompanyIdentifier: string;
}

export interface IHubspotObject {
  objectName: string;
  objectSlug: string;
}

export const hubspotApi = createApi({
  baseQuery,
  reducerPath: "hubspotApi",
  tagTypes: [
    "Contact",
    "UserDataMapping",
    "CompanyDataMapping",
    "HubspotSettings",
  ],
  endpoints: (builder) => ({
    getSettings: builder.query<any, any>({
      query: ({ appId }) => ({
        url: `/hubspot/settings`,
        params: { app_id: appId },
      }),
      providesTags: ["HubspotSettings"],
      transformResponse: (response: any) => humps.camelizeKeys(response),
    }),
    getSupportedHubspotObjects: builder.query<IHubspotObject[], any>({
      query: ({ appId }) => ({
        url: `/hubspot/supported_objects`,
        params: { app_id: appId },
      }),
      transformResponse: (response: any) =>
        humps.camelizeKeys(response) as IHubspotObject[],
    }),
    deleteSettings: builder.mutation<any, any>({
      query: ({ appId }) => ({
        url: `/hubspot/destroy_settings`,
        method: "DELETE",
        params: { app_id: appId },
      }),
      invalidatesTags: ["HubspotSettings"],
      transformResponse: (response: any) => humps.camelizeKeys(response),
    }),
    updateSettings: builder.mutation<
      IUpdateSettingsResponse,
      IUpdateSettingsArgs
    >({
      query: ({
        appId,
        autoUpdatesEnabled,
        autoCreatesEnabled,
        autoAssociatesEnabled,
        companyIdentifier,
        hubspotCompanyIdentifier,
        isSyncingUsers,
        isSyncingWorkspaces,
        isSyncingCompanies,
      }) => ({
        url: `/hubspot/update_settings`,
        method: "PUT",
        params: humps.decamelizeKeys({
          appId,
          autoUpdatesEnabled,
          autoCreatesEnabled,
          autoAssociatesEnabled,
          companyIdentifier,
          hubspotCompanyIdentifier,
          isSyncingUsers,
          isSyncingWorkspaces,
          isSyncingCompanies,
        }),
      }),
      invalidatesTags: ["HubspotSettings"],
      transformResponse: (
        response: SnakeCasedProperties<IUpdateSettingsResponse>,
      ): IUpdateSettingsResponse =>
        humps.camelizeKeys(response) as IUpdateSettingsResponse,
    }),
    getContacts: builder.query<any, any>({
      query: ({ appId, email, userId }) => ({
        url: `/hubspot/contacts?app_id=${appId}`,
        params: humps.decamelizeKeys({ appId, email, userId }),
      }),
      transformResponse: (response: any) => humps.camelizeKeys(response),
    }),
    createContact: builder.query<any, any>({
      query: ({ id, appId, email }) => ({
        url: `/hubspot/create_contact?app_id=${appId}`,
        method: "POST",
        params: {
          id,
          app_id: appId,
          email,
        },
      }),
      transformResponse: (response: any) => humps.camelizeKeys(response),
    }),
    updateContact: builder.query<any, any>({
      query: ({ userId, appId, contactId }) => ({
        url: `/hubspot/update_contact?app_id=${appId}`,
        method: "PUT",
        params: {
          user_id: userId,
          app_id: appId,
          contact_id: contactId,
        },
      }),
      transformResponse: (response: any) => humps.camelizeKeys(response),
    }),
    updateCompany: builder.query<any, any>({
      query: ({ groupId, appId, companyId, objectType }) => ({
        url: `/hubspot/update_company?app_id=${appId}`,
        method: "PUT",
        params: humps.decamelizeKeys({
          groupId,
          appId,
          companyId,
          objectType,
        }),
      }),
      transformResponse: (response: any) => humps.camelizeKeys(response),
    }),
    getCompanies: builder.query<any, any>({
      query: ({ appId, groupId, groupType }) => ({
        url: `/hubspot/companies?app_id=${appId}`,
        params: humps.decamelizeKeys({
          appId,
          groupId,
          groupType,
        }),
      }),
      transformResponse: (response: any) => humps.camelizeKeys(response),
    }),
    createCompany: builder.query<any, any>({
      query: ({ id, appId, objectType }) => ({
        url: `/hubspot/create_company?app_id=${appId}`,
        method: "POST",
        params: humps.decamelizeKeys({
          id,
          appId,
          objectType,
        }),
      }),
      transformResponse: (response: any) => humps.camelizeKeys(response),
    }),
  }),
});

export const {
  useGetSettingsQuery,
  useDeleteSettingsMutation,
  useUpdateSettingsMutation,
  useGetContactsQuery,
  useLazyCreateContactQuery,
  useLazyUpdateContactQuery,
  useLazyUpdateCompanyQuery,
  useGetCompaniesQuery,
  useLazyCreateCompanyQuery,
  useGetSupportedHubspotObjectsQuery,
} = hubspotApi;
