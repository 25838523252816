import { NavLink } from "react-router-dom";
import React, { useState } from "react";
import { motion } from "framer-motion";
import useFlag from "core/hooks/useFlag";
import { USAGE_BASED_PRICING } from "core/constants/features";
import {
  ArrowLeftOnRectangleIcon,
  BanknotesIcon,
  BoltIcon,
  CircleStackIcon,
  CodeBracketIcon,
  Cog6ToothIcon,
  CubeIcon,
  InboxArrowDownIcon,
  PuzzlePieceIcon,
  TagIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon, ChevronLeftIcon } from "@heroicons/react/20/solid";
import { Box, Flex, Text } from "@chakra-ui/react";
import { MenuLink, MenuLinkProps } from "./MenuLink";
import { useCurrentApp } from "../../core/hooks/useCurrentApp";
import colors from "../../core/design-system/constants/theme/colors";

const MENU: MenuLinkProps[] = [
  {
    label: "General",
    path: "settings/general",
    Icon: Cog6ToothIcon,
  },
  {
    label: "Members",
    path: "settings/team",
    Icon: UsersIcon,
  },
  {
    label: "Labels",
    path: "settings/labels",
    Icon: TagIcon,
  },
  {
    label: "Billing",
    path: "settings/billing",
    Icon: BanknotesIcon,
  },
  {
    label: "Digest",
    path: "settings/digest",
    Icon: InboxArrowDownIcon,
  },
  {
    label: "Objects",
    path: "settings/objects",
    Icon: CubeIcon,
  },
  {
    label: "Computed Traits",
    path: "settings/computed-traits",
    Icon: BoltIcon,
  },
  {
    label: "Developer tools",
    path: "settings/developers",
    Icon: CodeBracketIcon,
  },
  {
    label: "Data sources",
    path: "settings/integrations",
    Icon: CircleStackIcon,
    subMenu: [
      {
        label: "June SDK",
        path: "settings/integrations/june-sdk",
      },

      {
        label: "Segment",
        path: "settings/integrations/segment",
      },
      {
        label: "Freshpaint",
        path: "settings/integrations/freshpaint",
      },
      {
        label: "Rudderstack",
        path: "settings/integrations/rudderstack",
      },
    ],
  },
  {
    label: "Integrations",
    path: "settings/integrations",
    Icon: PuzzlePieceIcon,
    subMenu: [
      {
        label: "Salesforce",
        path: "settings/integrations/salesforce",
        isNew: true,
      },
      {
        label: "Attio",
        path: "settings/integrations/attio",
      },
      {
        label: "HubSpot",
        path: "settings/integrations/hubspot",
      },
      {
        label: "Slack",
        path: "settings/integrations/slack",
      },
      {
        label: "API",
        path: "settings/integrations/api",
      },
    ],
  },
  {
    label: "Importers",
    path: "settings/integrations",
    Icon: ArrowLeftOnRectangleIcon,
    subMenu: [
      {
        label: "Amplitude",
        path: "settings/integrations/amplitude",
      },
      {
        label: "Mixpanel",
        path: "settings/integrations/mixpanel",
      },
    ],
  },
];

type ToggleProps = { isOpen: boolean; onToggle: () => void };
const Toggle: React.FC<ToggleProps> = ({ isOpen, onToggle }) => (
  <Flex onClick={onToggle}>
    <motion.div animate={{ rotate: isOpen ? 0 : -90 }}>
      <ChevronDownIcon className="h-4 w-4  text-gray-500 group-hover:block group-hover:text-gray-500" />
    </motion.div>
  </Flex>
);

const NavAccordion: React.FC<MenuLinkProps> = ({ subMenu, label, Icon }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Flex direction="column">
      <MenuLink
        action={<Toggle isOpen={isOpen} onToggle={() => setIsOpen(isOpen)} />}
        label={label}
        Icon={Icon}
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen && (
        <div className="ml-[28px] mt-2 flex flex-col gap-2">
          {subMenu?.map((subItem) => (
            <MenuLink key={subItem.label} {...subItem} />
          ))}
        </div>
      )}
    </Flex>
  );
};

export const SettingsNavBar: React.FC = () => {
  const app = useCurrentApp();
  const hasUsageBasedPricing = useFlag(USAGE_BASED_PRICING);

  return (
    <div className="sticky top-0 flex h-full min-h-screen w-[325px] flex-col justify-between py-4">
      <div className="flex flex-1 flex-col gap-2 overflow-y-auto">
        <Box mb={2}>
          <NavLink to={`/a/${app.id}/home`}>
            <Flex gap={2} px={4}>
              <ChevronLeftIcon
                style={{ height: "25px", color: colors.gray[900] }}
              />{" "}
              <Text color="gray.900" fontWeight="semibold">
                Settings
              </Text>
            </Flex>
          </NavLink>
        </Box>
        {MENU.map((item) => {
          if (item.subMenu) {
            return <NavAccordion key={item.label} {...item} />;
          }

          if (item.label === "Billing" && !hasUsageBasedPricing) return null;

          return <MenuLink key={item.label} {...item} />;
        })}
      </div>
    </div>
  );
};
