import { useEffect, useState } from "react";
import { IEventLog } from "core/types/EventLog";
import { useGetEventLogsQuery } from "core/models/eventLogs";
import { GroupType } from "core/components/Group/GroupListSingleGroup";
import Error from "core/components/Error";
import { SearchBar } from "Components/SearchBar";
import { Button, Flex, Spinner, Text } from "@chakra-ui/react";
import ContactEventLogGroups from "./ContactEventLogGroups";

export default function ContactEventLog({
  appId,
  contactId,
  groupId,
  groupType,
}: {
  appId: number;
  contactId?: string;
  groupId?: string;
  groupType?: GroupType;
}) {
  const [page, setPage] = useState(1);
  const [eventLogs, setEventLogs] = useState<IEventLog[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [previousSearchQuery, setPreviousSearchQuery] = useState("");
  const { data, isLoading, isFetching, error } = useGetEventLogsQuery({
    appId,
    page,
    contactId,
    groupId,
    groupType,
    query: searchQuery,
  });

  const onSearch = (query: string) => {
    setSearchQuery(query);
    setPage(1);
  };

  useEffect(() => {
    const searchCleared = previousSearchQuery && !searchQuery;

    if (data?.eventLogs) {
      if (searchQuery) {
        if (page === 1) {
          setEventLogs(data.eventLogs);
        } else {
          setEventLogs([...eventLogs, ...data.eventLogs]);
        }
      } else if (searchCleared) {
        setEventLogs(data.eventLogs);
      } else {
        setEventLogs([...eventLogs, ...data.eventLogs]);
      }
    }

    setPreviousSearchQuery(searchQuery);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchQuery]);

  if (error) {
    return <Error />;
  }

  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <SearchBar
        mb={-4}
        searchQuery={searchQuery}
        onSearch={onSearch}
        placeholder="Search your events by name..."
      />
      {(isLoading || isFetching) && (
        <div className="flex h-[500px] items-center justify-center">
          <Spinner />
        </div>
      )}
      {!(isLoading || isFetching) && (!data || eventLogs.length === 0) && (
        <Flex width="250px" height="250px" align="center" justify="center">
          <Text>No events found</Text>
        </Flex>
      )}
      {!(isLoading || isFetching) &&
        data &&
        eventLogs &&
        eventLogs.length > 0 && (
          <div className="w-full">
            <ContactEventLogGroups
              key={eventLogs.length}
              eventLogs={eventLogs}
              showUser={!!groupId}
            />
          </div>
        )}
      {data && data.pagy.last > data.pagy.page && (
        <div className="mt-6 flex w-full justify-center">
          <Button
            onClick={() => {
              setPage(page + 1);
            }}
          >
            Load more..
          </Button>
        </div>
      )}
    </div>
  );
}
