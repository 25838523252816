const baseStyle = {
  borderRadius: "md",
};

const variants = {
  success: {
    container: {
      backgroundColor: "success.50",
      color: "success.500",
      fontSize: "sm",
      fontWeight: "medium",
      borderRadius: "lg",
    },
  },
};

const Alert = {
  baseStyle,
  variants,
};

export default Alert;
