import humps from "humps";
import { IContactPages } from "core/types/Contact";
import axios from "core/initializers/axios";
import { slice } from "../index";
import { AppThunk } from "../../../initializers/store";

interface IContactPagesParams {
  appId: string | number;
  id: string;
}

export const fetchOneContactPages =
  ({ appId, id }: IContactPagesParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(slice.actions.fetchingContactPages({ id }));
      const { data } = await axios.get<IContactPages>(
        `/contacts/${encodeURIComponent(id)}/pages`,
        {
          params: humps.decamelizeKeys({ appId }),
        },
      );

      dispatch(slice.actions.upsertOneContactPages({ id, data }));
    } catch (e: any) {
      const title = "Failed to fetch contact pages";
      const description = e?.response?.data?.error;
      const error = `${title}: ${description}`;
      dispatch(slice.actions.fetchingContactPagesFailed({ id, error }));
    }
  };
