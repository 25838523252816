import { DataExport } from "./DataExport";
import { AiOptIn } from "./AiOptIn";

export const DataManagement: React.FC = () => {
  return (
    <>
      <h2 className="text-base font-semibold text-gray-900">Data management</h2>
      <AiOptIn />
      <DataExport />
    </>
  );
};
