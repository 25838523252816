import { IBreakdown } from "core/types/Breakdown";
import { useReportSetup } from "core/hooks/useReportSetup";
import { ChartBarIcon } from "@heroicons/react/20/solid";
import { ComponentDefaultProps, Flex, Text } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

export const Pill = ({
  breakdown,
  isRemoveable = false,
  containerProps = {},
  textProps = {},
}: {
  breakdown: IBreakdown;
  isRemoveable?: boolean;
  containerProps?: ComponentDefaultProps;
  textProps?: ComponentDefaultProps;
  iconProps?: ComponentDefaultProps;
  styleProps?: ComponentDefaultProps;
}) => {
  const { removeBreakdown } = useReportSetup();
  function onRemove() {
    removeBreakdown();
  }

  if (isRemoveable)
    return (
      <Flex>
        <Flex
          bg="gray.100"
          px={3}
          py={1}
          borderRadius="md"
          borderRightRadius="none"
          align="center"
        >
          <ChartBarIcon style={{ height: "15px" }} />
          <Text ml={2} fontSize="sm" color="black">
            Breakdown by {` `}
            <Text as="span" fontWeight="bold">
              {breakdown.trait}
            </Text>
          </Text>
        </Flex>
        <Flex
          px={3}
          py={1}
          borderRadius="md"
          bg="gray.100"
          borderLeft="none"
          borderLeftRadius="none"
          cursor="pointer"
          onClick={onRemove}
          align="center"
        >
          <CloseIcon h={2} w={2} />
        </Flex>
      </Flex>
    );

  return (
    <Flex
      gridGap={2}
      px={3}
      align="center"
      data-testid="breakdown-pill-uneditable"
      bg="white"
      py={1}
      borderRadius={"md"}
      boxShadow="sm"
      {...containerProps}
    >
      <Flex>
        <ChartBarIcon style={{ height: "15px" }} />
      </Flex>
      <Flex>
        <Text fontSize="sm" {...textProps}>
          Breakdown by{` `}
          <Text as="span" fontWeight="bold">
            {breakdown.trait}
          </Text>
        </Text>
      </Flex>
    </Flex>
  );
};
