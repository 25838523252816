export class Config {
  get apiUrl() {
    return this.validate("API url", import.meta.env.VITE_API_HOST);
  }

  validate(name, env) {
    if (!env) console.log(`${name} is not initialized.`);
    return env;
  }
}
