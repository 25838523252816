import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { IEventLog } from "core/types/Debugger";
import { eventLogSlice } from "../models/selected-event-log";
import { RootState } from "../initializers/store";

interface ISelectedEventLog {
  selectedLog: IEventLog | null;
  selectLog: (log: IEventLog) => void;
  deselectLog: () => void;
}

export const useSelectedEventLog = (): ISelectedEventLog => {
  const dispatch = useDispatch();
  const selectedLog = useSelector(
    (state: RootState) => state.eventLog.selectedLog,
  );

  const selectLog = useCallback(
    (log: IEventLog) => {
      dispatch(eventLogSlice.actions.selectLog(log));
    },
    [dispatch],
  );

  const deselectLog = useCallback(() => {
    dispatch(eventLogSlice.actions.deselectLog());
  }, [dispatch]);

  return {
    selectedLog,
    selectLog,
    deselectLog,
  };
};
