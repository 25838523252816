import { useParams } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { ArrowLongRightIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";

export const GettingStartedOverlay: React.FC = () => {
  const { appId } = useParams();
  const navigate = useNavigate();
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.1 }}
      style={{ height: "314px", width: "314px" }}
      className="absolute  -left-4 bottom-0 z-overlay ml-4 flex h-full w-full flex-col items-center rounded-lg bg-gradient-to-t from-white to-transparent transition delay-150 ease-in-out"
    >
      <div className="bottom m-auto flex flex-col items-center">
        <p className="mt-44 text-sm font-medium">Finish getting started</p>
        <Button
          rightIcon={<ArrowLongRightIcon className="h-4" />}
          mt={2}
          colorScheme="purple"
          onClick={(e) => {
            e.preventDefault();
            navigate(`/a/${appId}/getting-started`);
          }}
        >
          Getting started
        </Button>
      </div>
    </motion.div>
  );
};
