import {
  Box,
  Button,
  Center,
  ComponentDefaultProps,
  Flex,
  Text,
} from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";
import { LoadingChart } from "./LoadingChart";
import EmptySetup from "./EmptySetup";

interface IBodyProps extends ComponentDefaultProps {
  isLoading: boolean;
  isPreviewMode: boolean;
  isSetupValid?: boolean;
  error?: any;
}

const Body: React.FC<IBodyProps> = ({
  isLoading,
  isPreviewMode,
  children,
  isSetupValid,
  error,
  ...props
}) => {
  if (error) {
    switch (error?.response?.status || error?.originalStatus) {
      default:
        return (
          <Center bg="white" w="100%" h="400px">
            <Box textAlign="center">
              <Box
                mb={4}
                display="inline-block"
                px={3}
                py={2}
                borderRadius="lg"
                bg="purple.100"
              >
                <WarningIcon color="purple.400" h={5} w={5} />
              </Box>
              <Text fontSize="lg" fontWeight="semibold">
                Oops! Something went wrong on our side.
              </Text>
              <Text fontSize="sm" color="gray.600">
                Please try and refresh the page, or reach out to us.
              </Text>
              <Flex justifyContent="center" mt={4}>
                <Button
                  onClick={() => window.location.reload()}
                  size="sm"
                  mr={2}
                >
                  Refresh
                </Button>
                <Button
                  onClick={() => window.Intercom("show")}
                  colorScheme="purple"
                  size="sm"
                >
                  Chat with us
                </Button>
              </Flex>
            </Box>
          </Center>
        );
    }
  }

  if (!isSetupValid) {
    return <EmptySetup helpText="Select an event to get started" />;
  }

  if (isLoading && !isPreviewMode) {
    return <LoadingChart />;
  }

  return (
    <Box data-testid="insight-card-body" {...props}>
      {children}
    </Box>
  );
};

export default Body;
