import { useParams } from "react-router-dom";
import React, { useState } from "react";
import humps from "humps";
import { motion } from "framer-motion";
import axios from "core/initializers/axios";
import {
  Button,
  Center,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  StyleProps,
  Text,
  Tooltip,
} from "@chakra-ui/react";

const POSITIVE_REACTION = "positive";
const NEGATIVE_REACTION = "negative";

interface IInsightReactionsProps extends StyleProps {
  reactions?: {
    reactionValue: "positive" | "negative";
  };
  insightId: number;
  reportId: number;
}

interface IAnimatedButtonsProps {
  isReacted: boolean;
  onClick: () => void;
}

const AnimatedButtons: React.FC<IAnimatedButtonsProps> = ({
  children,
  onClick,
  isReacted,
  ...props
}) => {
  return (
    <motion.button
      style={{ opacity: isReacted ? 1 : 0.5 }}
      whileHover={{
        scale: !isReacted ? 1 : 1.3,
        transition: { duration: 0.1 },
      }}
      whileTap={{ scale: !isReacted ? 1 : 0.9 }}
      onClick={onClick}
      {...props}
    >
      {children}
    </motion.button>
  );
};

const transformToPayload = ({
  appId,
  insightType,
  reportId,
  reactionValue,
  feedback,
}: {
  appId: number;
  insightType: number;
  reportId: number;
  reactionValue: string;
  feedback?: string;
}) => ({
  appId,
  insightType,
  reportId,
  reactionValue,
  feedback,
});

async function onReacted({
  isPositiveReaction,
  appId,
  insightId,
  reportId,
  feedback,
  setShowThankYou,
  setShowNegativeFeedbackOptions,
}: {
  isPositiveReaction: boolean;
  appId: number;
  insightId: number;
  reportId: number;
  feedback?: string;
  setShowThankYou: (value: boolean) => void;
  setShowNegativeFeedbackOptions: (value: boolean) => void;
}) {
  axios.post(
    `/report/insight/react`,
    transformToPayload({
      appId,
      insightType: insightId,
      reportId,
      reactionValue: isPositiveReaction ? POSITIVE_REACTION : NEGATIVE_REACTION,
      feedback,
    }),
    {
      params: humps.decamelizeKeys({
        appId,
        insightType: insightId,
        reportId,
        reactionValue: isPositiveReaction
          ? POSITIVE_REACTION
          : NEGATIVE_REACTION,
        feedback,
      }),
    },
  );
  setShowNegativeFeedbackOptions(false);
  setShowThankYou(true);
}

export const InsightReactions: React.FC<IInsightReactionsProps> = ({
  reactions,
  insightId,
  reportId,
  ...props
}) => {
  const { appId: appIdParam } = useParams<{ appId: string }>();
  const appId = Number(appIdParam);
  const [showNegativeFeedbackOptions, setShowNegativeFeedbackOptions] =
    useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [selectedNegativeFeedbackOption, setSelectedNegativeFeedbackOption] =
    useState<string>();
  const hasReacted = reactions?.reactionValue;
  const isPositiveReaction = hasReacted
    ? reactions?.reactionValue === POSITIVE_REACTION
    : true;
  const isNegativeReaction = hasReacted
    ? reactions?.reactionValue === NEGATIVE_REACTION
    : true;

  function onSubmitNegativeFeedback() {
    onReacted({
      isPositiveReaction: false,
      appId,
      insightId,
      reportId,
      feedback: selectedNegativeFeedbackOption,
      setShowThankYou,
      setShowNegativeFeedbackOptions,
    });
  }

  if (!appId) return null;

  return (
    <Flex
      pb={2}
      pt={3}
      align="center"
      justifyContent="center"
      position="relative"
      {...props}
    >
      <Tooltip label="Was this useful for you?" placement="top" hasArrow>
        <Center>
          {showNegativeFeedbackOptions && (
            <motion.div
              style={{
                position: "absolute",
                top: "-165px",
                width: "320px",
              }}
              initial={{ opacity: 0 }}
              animate={{ y: "-5px", opacity: 1 }}
              transition={{ duration: 0.2 }}
            >
              <Flex
                boxShadow="md"
                bg="white"
                p={4}
                border="1px solid"
                borderColor="gray.200"
                borderRadius="md"
                direction="column"
              >
                <Flex>
                  <Text fontSize="sm" color="gray.600" mb={2}>
                    We'd love to know more...
                  </Text>
                </Flex>
                <div className="relative flex flex-col">
                  <RadioGroup
                    onChange={(option) =>
                      setSelectedNegativeFeedbackOption(option)
                    }
                    colorScheme="purple"
                  >
                    <Stack>
                      <Radio value="not_useful">
                        <Text fontSize="sm">Not useful for me</Text>
                      </Radio>
                      <Radio value="incorrect_data">
                        <Text fontSize="sm">Incorrect data</Text>
                      </Radio>
                      <Radio value="not_enough_information">
                        <Text fontSize="sm">Not enough information</Text>
                      </Radio>
                      <Radio value="something_else">
                        <Text fontSize="sm">Something else</Text>
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </div>
                <Button
                  position="absolute"
                  right="10px"
                  bottom="10px"
                  variant="ghost"
                  colorScheme="purple"
                  isDisabled={!selectedNegativeFeedbackOption}
                  onClick={onSubmitNegativeFeedback}
                >
                  Submit
                </Button>
              </Flex>
            </motion.div>
          )}
          <Flex gridGap={2}>
            {showThankYou ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ y: "-5px", opacity: 1 }}
                transition={{ duration: 0.2 }}
              >
                <Text fontSize="sm" color="gray.600">
                  💜
                </Text>
              </motion.div>
            ) : (
              <>
                <AnimatedButtons
                  onClick={() =>
                    !hasReacted &&
                    onReacted({
                      isPositiveReaction: true,
                      appId,
                      insightId,
                      reportId,
                      setShowThankYou,
                      setShowNegativeFeedbackOptions,
                    })
                  }
                  isReacted={isPositiveReaction}
                >
                  👍
                </AnimatedButtons>
                <AnimatedButtons
                  onClick={() =>
                    setShowNegativeFeedbackOptions(!showNegativeFeedbackOptions)
                  }
                  isReacted={isNegativeReaction}
                >
                  👎
                </AnimatedButtons>
              </>
            )}
          </Flex>
        </Center>
      </Tooltip>
    </Flex>
  );
};
