import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "core/initializers/axios";
import PropTypes from "prop-types";

function UninstallButton({ mutate, appId }) {
  const onUninstallSlackApp = async () => {
    const params = {
      appId,
    };
    await axios.post(`/slack_apps/destroy`, params, {
      withCredentials: true,
    });
    mutate();
    onClose();
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button colorScheme="red" onClick={onOpen}>
        Uninstall
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure you want to uninstall Slack?</ModalHeader>
          <ModalBody>
            <Text>This will disconnect June from your Slack workspace</Text>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={onUninstallSlackApp} colorScheme="red">
              Uninstall
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

UninstallButton.propTypes = {
  mutate: PropTypes.func,
  appId: PropTypes.string,
};

export default UninstallButton;
