import { useNavigate } from "react-router-dom";
import { HubspotProfileContainer as HubspotGroupProfileContainer } from "modules/Groups/Group/HubspotProfileContainer";
import { HubspotProfileContainer as HubspotUserProfileContainer } from "modules/Contacts/Contact/HubspotProfileContainer";
import { IGroup } from "core/types/Group";
import { IContact } from "core/types/Contact";
import { Integration } from "core/models/dataMappings";
import { AppObjectType, IAppObject } from "core/models/appObjects";
import { useHubspotSettings } from "core/hooks/useHubspotSettings";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useAttioSettings } from "core/hooks/useAttioSettings";
import HubspotIcon from "core/design-system/components/Icon/Interface/HubspotIcon";
import AttioIcon from "core/design-system/components/Icon/Interface/AttioIcon";
import { Skeleton } from "@chakra-ui/react";
import { AttioProfileContainer } from "./AttioProfileContainer";

export const CrmHeader: React.FC<{
  entity: IContact | IGroup;
  appObject: IAppObject;
  integration?: Integration;
}> = ({ entity, appObject, integration }) => {
  const { id: appId } = useCurrentApp();
  const { hasHubspotInstalled } = useHubspotSettings();
  const { hasAttioInstalled } = useAttioSettings();
  const navigate = useNavigate();

  if (!appObject) {
    return null;
  }

  const HubspotProfileContainer =
    appObject.objectType === AppObjectType.User
      ? HubspotUserProfileContainer
      : HubspotGroupProfileContainer;

  if (integration === Integration.Attio) {
    return (
      <div className="flex w-full justify-between border-t border-solid border-gray-200 px-8 py-3">
        <AttioProfileContainer entity={entity} appObject={appObject} />
      </div>
    );
  }

  if (integration === Integration.Hubspot) {
    return (
      <div className="flex w-full justify-between border-t border-solid border-gray-200 px-8 py-3">
        <HubspotProfileContainer
          appId={String(appId)}
          contact={entity}
          company={entity}
          objectType={appObject.objectType}
        />
      </div>
    );
  }

  if (!hasHubspotInstalled && !hasAttioInstalled) {
    return (
      <div className="flex w-full justify-between border-t border-solid border-gray-200 px-8 py-3">
        <div>
          <p className="text-sm font-medium">Sync with your CRM</p>
          <p className="text-xs text-gray-600">
            Connect with{" "}
            <p
              onClick={() =>
                navigate(`/a/${appId}/settings/integrations/hubspot`)
              }
              className="inline cursor-pointer text-black hover:underline"
            >
              Hubspot
            </p>{" "}
            or{" "}
            <p
              onClick={() =>
                navigate(`/a/${appId}/settings/integrations/attio`)
              }
              className="inline cursor-pointer text-black hover:underline"
            >
              Attio
            </p>
          </p>
        </div>
        <div className="flex items-center gap-3">
          <HubspotIcon w={5} h={5} />
          <AttioIcon w={5} h={5} />
        </div>
      </div>
    );
  }

  if (hasAttioInstalled && hasHubspotInstalled) {
    return (
      <>
        <div className="w-full border-t border-solid border-gray-200 px-8 py-3">
          {entity ? (
            <HubspotProfileContainer
              appId={String(appId)}
              contact={entity}
              company={entity}
              objectType={appObject.objectType}
            />
          ) : (
            <Skeleton borderRadius="lg" mb={4} height="150px" width="100%" />
          )}
        </div>
        <div className="items-centerh-full flex w-full border-t border-solid border-gray-200 py-3 pl-8 pr-4">
          {entity ? (
            <AttioProfileContainer entity={entity} appObject={appObject} />
          ) : (
            <Skeleton borderRadius="lg" height="20px" width="100%" />
          )}
        </div>
      </>
    );
  }

  return (
    <>
      {!hasAttioInstalled && (
        <div className="w-full border-t border-solid border-gray-200 px-8 py-3">
          {entity ? (
            <HubspotProfileContainer
              appId={String(appId)}
              company={entity}
              contact={entity}
              objectType={appObject.objectType}
            />
          ) : (
            <Skeleton borderRadius="lg" mb={4} height="150px" width="100%" />
          )}
        </div>
      )}
      {!hasHubspotInstalled && (
        <div className="items-centerh-full flex w-full border-t border-solid border-gray-200 py-3 pl-8 pr-4">
          {entity ? (
            <AttioProfileContainer entity={entity} appObject={appObject} />
          ) : (
            <Skeleton borderRadius="lg" height="20px" width="100%" />
          )}
        </div>
      )}
    </>
  );
};
