import React, { useState } from "react";
import { ITraitTimerange } from "core/types/Trait";
import { NumberInputFilter } from "core/modules/reports/setup/Audience/NumberInputFilter";
import { INTERVAL_OPTIONS } from "core/modules/reports/setup/Audience/EventFilter";
import { DAY, INTERVAL_TYPE_NAMES } from "core/constants/timerange";
import {
  TIMERANGE_TYPE_LABELS,
  TimerangeType,
} from "core/constants/report-setup";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuOptionGroup,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { PaywallPopover } from "../Paywall/Popover";
import { Plan } from "core/types/App";

export const TimerangePicker: React.FC<{
  onChange: (value: ITraitTimerange) => void;
  value: ITraitTimerange;
  isAllTimeDisabled?: boolean;
  isPaywalled?: boolean;
}> = ({ onChange, value, isAllTimeDisabled = false, isPaywalled = false }) => {
  const [timerangeType, setTimerangeType] = useState(value.type);
  const [interval, setInterval] = useState<number>(value.interval);
  const [intervalType, setIntervalType] = useState<number>(value.intervalType);

  const onAllTimerangeTypeChange = () => {
    setTimerangeType(TimerangeType.ALL_TIME);
    setInterval(0);
    setIntervalType(DAY);
  };
  const onRelativeTimerangeTypeChange = () => {
    setTimerangeType(TimerangeType.RELATIVE);
    setInterval(7);
    setIntervalType(DAY);
  };
  const onIntervalChange = (val: string) => {
    setInterval(Number(val));
  };
  const onIntervalTypeChange = (val: number) => {
    setIntervalType(val);
  };

  return (
    <Menu
      matchWidth
      closeOnSelect={false}
      onClose={() => {
        onChange({
          type: timerangeType,
          interval: interval,
          intervalType: intervalType,
        });
      }}
    >
      {({ onClose }) => (
        <>
          <PaywallPopover
            feature="computed traits"
            redirect="settings/computed-traits"
            plan={Plan.Pro}
            overridePaywall={!isPaywalled}
          >
            <MenuButton
              data-testid="audience-event-filter-matcher"
              fontSize="sm"
              fontWeight="medium"
              borderRadius={"md"}
              border="none"
              bg="gray.100"
              _hover={{ bg: "gray.200" }}
              as={Button}
            >
              {TIMERANGE_TYPE_LABELS[timerangeType]}{" "}
              {timerangeType === TimerangeType.RELATIVE && (
                <>
                  {interval} {INTERVAL_TYPE_NAMES[intervalType]}
                  {interval > 1 ? "s" : ""}
                </>
              )}
            </MenuButton>
          </PaywallPopover>
          <MenuList minW="180px" pb={0}>
            <RadioGroup
              pb={2}
              value={String(timerangeType)}
              colorScheme={"purple"}
            >
              <Stack px={4} py={2}>
                <Radio
                  key={TimerangeType.ALL_TIME}
                  value={String(TimerangeType.ALL_TIME)}
                  py="1px"
                  isDisabled={isAllTimeDisabled}
                  onClick={() => {
                    if (isAllTimeDisabled) return;
                    onAllTimerangeTypeChange();
                  }}
                >
                  <Text fontSize="sm">
                    {TIMERANGE_TYPE_LABELS[TimerangeType.ALL_TIME]}
                  </Text>
                </Radio>
                <Radio
                  key={TimerangeType.RELATIVE}
                  value={String(TimerangeType.RELATIVE)}
                  py="1px"
                  onClick={() => onRelativeTimerangeTypeChange()}
                >
                  <Text fontSize="sm">
                    {TIMERANGE_TYPE_LABELS[TimerangeType.RELATIVE]}
                  </Text>
                </Radio>
                {timerangeType === TimerangeType.RELATIVE && (
                  <>
                    <NumberInputFilter
                      hasInterval={timerangeType === TimerangeType.RELATIVE}
                      onChange={onIntervalChange}
                      defaultValue={interval}
                      bg="white"
                      border="0.5px solid"
                      borderColor="gray.200"
                      borderRadius="md"
                      size="sm"
                      w="full"
                    />
                    <Menu closeOnSelect closeOnBlur matchWidth>
                      <MenuButton
                        borderRadius="md"
                        fontSize="sm"
                        as={Button}
                        _hover={{ bg: "white" }}
                        fontWeight="normal"
                        data-testid="user-event-filter-relative-interval-button"
                      >
                        <Flex
                          align="center"
                          gridGap={2}
                          justifyContent="space-between"
                        >
                          <Flex>
                            {INTERVAL_TYPE_NAMES[intervalType]}
                            {interval > 1 ? "s" : ""}
                          </Flex>
                          <Flex>
                            <ChevronDownIcon style={{ height: "15px" }} />
                          </Flex>
                        </Flex>
                      </MenuButton>
                      <MenuList minW="100px">
                        <MenuOptionGroup
                          defaultValue={String(intervalType)}
                          type="radio"
                        >
                          {INTERVAL_OPTIONS.map((i) => (
                            <MenuItem
                              key={i}
                              data-testid={`user-event-filter-interval-item-${i}`}
                              fontSize="sm"
                              onClick={() => onIntervalTypeChange(i)}
                              value={i}
                            >
                              {INTERVAL_TYPE_NAMES[i]}
                              {interval > 1 ? "s" : ""}
                            </MenuItem>
                          ))}
                        </MenuOptionGroup>
                      </MenuList>
                    </Menu>
                  </>
                )}
              </Stack>
            </RadioGroup>
            <Button
              onClick={() => {
                onChange({ type: timerangeType, interval, intervalType });
                onClose();
              }}
              borderRadius="md"
              colorScheme="purple"
              variant="ghost"
              w="full"
              borderTopRadius="none"
              borderTop="1px solid"
              borderColor="gray.200"
              pt={0.5}
            >
              Done
            </Button>
          </MenuList>
        </>
      )}
    </Menu>
  );
};
