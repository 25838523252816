import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { AppObjectType } from "core/models/appObjects";
import { IGroup } from "core/types/Group";
import { useEffect, useState } from "react";
import moment from "moment";
import {
  useCreateDealMutation,
  useGetDealsQuery,
  useUpdateDealMutation,
} from "core/models/hubspot/deals";

export enum HubspotObjectTypes {
  Contacts = "contacts",
  Companies = "companies",
  Deals = "deals",
}

export const hubspotObjectTypesSingular = {
  [HubspotObjectTypes.Contacts]: "contact",
  [HubspotObjectTypes.Companies]: "company",
  [HubspotObjectTypes.Deals]: "deal",
};

interface IUseHubspotDealsParams {
  appId: number | string;
  company: IGroup;
  objectType: AppObjectType;
}

export interface IHubspotDeal {
  id: string;
  properties: { name: string };
}

interface IHubspotDealsResponse {
  deals: IHubspotDeal[];
  lastSyncedAt: string;
  createHubspotDeal: () => void;
  updateHubspotDeal: (id: string) => void;
  isLoadingDeals: boolean;
  isCreatedDealLoading: boolean;
  isUpdatedDealLoading: boolean;
  refetchDeals: () => void;
  fetchDealsError: FetchBaseQueryError | SerializedError | undefined;
  createDealError: FetchBaseQueryError | SerializedError | undefined;
  updateDealError: FetchBaseQueryError | SerializedError | undefined;
  canCreate: boolean;
}

export const useHubspotDeals = ({
  appId,
  company,
  objectType,
}: IUseHubspotDealsParams): IHubspotDealsResponse => {
  const [deals, setDeals] = useState<IHubspotDeal[]>([]);
  const [lastSyncedAt, setLastSyncedAt] = useState<string>("");

  const {
    data: fetchDeals,
    isLoading: isLoadingDeals,
    error: fetchDealsError,
    refetch: refetchDeals,
  } = useGetDealsQuery({
    appId,
    groupId: company.id,
    objectType,
  });

  const [
    createDeal,
    {
      data: createdDeals,
      isLoading: isCreatedDealLoading,
      error: createDealError,
    },
  ] = useCreateDealMutation();

  const [
    updateDeal,
    {
      data: updatedDeals,
      isLoading: isUpdatedDealLoading,
      error: updateDealError,
    },
  ] = useUpdateDealMutation();

  useEffect(() => {
    if (fetchDeals?.deals) {
      setDeals(fetchDeals.deals);
      setLastSyncedAt(moment.utc(fetchDeals.lastSyncedAt).fromNow());
    }

    if (createdDeals?.deals) {
      setDeals(createdDeals.deals);
      setLastSyncedAt(moment.utc(createdDeals.lastSyncedAt).fromNow());
    }

    if (updatedDeals?.deals) {
      setDeals(updatedDeals.deals);
      setLastSyncedAt(moment.utc(updatedDeals.lastSyncedAt).fromNow());
    }
  }, [fetchDeals, createdDeals, updatedDeals]);

  function createHubspotDeal() {
    createDeal({ appId, groupId: company.id, objectType });
  }

  function updateHubspotDeal(id: string) {
    updateDeal({
      id,
      groupId: company.id,
      objectType,
    });
  }

  function canCreate() {
    if (objectType === AppObjectType.Group) return true;

    return Boolean(company.id);
  }

  return {
    deals,
    lastSyncedAt:
      (fetchDeals?.lastSyncedAt ||
        createdDeals?.lastSyncedAt ||
        updatedDeals?.lastSyncedAt) &&
      lastSyncedAt,
    isLoadingDeals,
    createHubspotDeal,
    updateHubspotDeal,
    isCreatedDealLoading,
    isUpdatedDealLoading,
    fetchDealsError,
    createDealError,
    updateDealError,
    refetchDeals,
    canCreate: canCreate(),
  };
};
