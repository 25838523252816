import { useDispatch, useSelector } from "react-redux";
import {
  selectNavigation,
  setNewReportModalIsOpen,
  toggle,
} from "../models/navigation";

export const useNavigation = () => {
  const dispatch = useDispatch();
  const navigation = useSelector(selectNavigation);

  const toggleSidebar = () => {
    dispatch(toggle());
  };

  const setIsNewReportModalOpen = (isOpen: boolean) => {
    dispatch(setNewReportModalIsOpen(isOpen));
  };

  return { navigation, toggleSidebar, setIsNewReportModalOpen };
};
