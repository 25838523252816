import { Flex, Text } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";

export default function InsightLoadingState() {
  return (
    <Flex direction="column" alignItems="center" justifyContent="center">
      <Text color="gray.600" fontSize="sm">
        <Spinner size={"xs"} /> Loading your insights...
      </Text>
    </Flex>
  );
}
