import { useEffect, useState } from "react";
import { ReportsListHeader } from "modules/Reports/ReportsListHeader";
import { Header } from "modules/Reports/List/Header";
import { isEmpty } from "lodash";
import humps from "humps";
import { ILabel } from "core/types/Label";
import {
  useGetReportsQuery,
  useGetReportTypesQuery,
} from "core/models/reports";
import { useQueryParam } from "core/hooks/useQueryParam";
import usePagination from "core/hooks/usePagination";
import { useLabels } from "core/hooks/useLabels";
import {
  ISortedByOption,
  SORTED_BY_OPTIONS_ARRAY,
} from "core/constants/sortFilters";
import { PageContainer } from "core/components/PageContainer";
import { LoadingList } from "core/components/List/LoadingList";
import List from "core/components/List";
import { Stack } from "@chakra-ui/react";
import { SearchResults } from "./ReportsSearchResults";
import ReportsPlaceholder from "./ReportsPlaceholder";
import config from "./List/config";

const updateURL = ({
  labels,
  reportTypes,
}: {
  labels: ILabel[];
  reportTypes: number[];
}) => {
  const qs = new URLSearchParams(window.location.search);
  const pageQuery = qs.get("page") ? `page=${qs.get("page")}` : "";
  const labelQuery =
    labels.length > 0
      ? `labels=${labels?.map((l: ILabel) => l.name).join(`,`)}`
      : "";
  const reportTypesQuery =
    reportTypes.length > 0 ? `report_types=${reportTypes.join(",")}` : "";
  const searchQuery = [labelQuery, reportTypesQuery, pageQuery]
    .filter((q) => Boolean(q))
    .join("&");

  window.history.replaceState(
    null,
    "",
    `${window.location.pathname}${searchQuery ? "?" : ""}${searchQuery}`,
  );
};

function Reports({ appId }: { appId: number }) {
  const [isSearching, setIsSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const initialSortFilterIndexFromLocalStorage =
    localStorage.getItem("reportsInitialSortFilter") !== null
      ? Number(localStorage.getItem("reportsInitialSortFilter"))
      : 0;

  const initialSortFilterIndex = initialSortFilterIndexFromLocalStorage;
  const initialSortFilter = SORTED_BY_OPTIONS_ARRAY[initialSortFilterIndex];
  const [sortFilter, setSortFilter] = useState(initialSortFilter);
  const [selectedLabels, setSelectedLabels] = useState<ILabel[]>([]);
  const [selectedReportTypes, setSelectedReportTypes] = useState<number[]>([]);
  const { currentPage, previousPage, nextPage } = usePagination({
    searchQuery,
  });
  const persistedLabelsQuery = useQueryParam("labels");
  const persistedReportTypesQuery = useQueryParam("report_types");

  const onSetSelectedLabels = (_labels: ILabel[]) => {
    setSelectedLabels(_labels);
  };

  const onSetSelectedReportTypes = (_reportTypes: number[]) => {
    setSelectedReportTypes(_reportTypes);
  };

  const { data, isLoading, isUninitialized } = useGetReportsQuery(
    {
      appId,
      page: currentPage,
      sort: `${`${sortFilter.order}${humps.decamelize(sortFilter.column)}`}`,
    },
    { skip: !appId },
  );

  const { labels } = useLabels({ appId });
  const { data: reportTypesData } = useGetReportTypesQuery({ appId });
  const reportTypes = reportTypesData?.reportTypes || [];

  const setAndSaveSortFilter = (sortFilter: ISortedByOption) => {
    setSortFilter(sortFilter);
    const sortFilterIndex = SORTED_BY_OPTIONS_ARRAY.findIndex(
      (s) => s.value === sortFilter.value,
    );
    localStorage.setItem("reportsInitialSortFilter", String(sortFilterIndex));
  };

  useEffect(() => {
    updateURL({
      labels: selectedLabels,
      reportTypes: selectedReportTypes,
    });
  }, [selectedLabels, selectedReportTypes]);

  useEffect(() => {
    setIsSearching(
      !isEmpty(searchQuery) ||
        !isEmpty(selectedLabels) ||
        !isEmpty(selectedReportTypes),
    );
  }, [
    searchQuery,
    searchQuery.length,
    selectedLabels,
    selectedLabels.length,
    selectedReportTypes,
    selectedReportTypes.length,
  ]);

  useEffect(() => {
    if (persistedLabelsQuery && labels && labels.length > 0) {
      const persistedLabelNames = persistedLabelsQuery
        .split(`,`)
        .map((n) => decodeURIComponent(n));
      const findLabelsByName = labels.filter((l) =>
        persistedLabelNames.includes(l.name),
      );

      setSelectedLabels(findLabelsByName);
    }
  }, [labels, persistedLabelsQuery]);

  useEffect(() => {
    if (persistedReportTypesQuery) {
      const persistedReportTypes = persistedReportTypesQuery
        .split(`,`)
        .map((r) => Number(r));
      setSelectedReportTypes(persistedReportTypes);
    }
  }, [persistedReportTypesQuery]);

  if (isLoading || isUninitialized)
    return (
      <PageContainer>
        <Header />
        <ReportsListHeader
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          selectedLabels={selectedLabels}
          setSelectedLabels={onSetSelectedLabels}
          sortedBy={sortFilter}
          labels={labels || []}
          setSortFilter={setAndSaveSortFilter}
          reportTypes={reportTypes}
          selectedReportTypes={selectedReportTypes}
          setSelectedReportTypes={onSetSelectedReportTypes}
        />
        <LoadingList hideAvatar={true} config={config} />
      </PageContainer>
    );

  if (!data || data?.reports?.length === 0) return <ReportsPlaceholder />;

  return (
    <PageContainer>
      <Header />
      <ReportsListHeader
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        sortedBy={sortFilter}
        setSortFilter={setAndSaveSortFilter}
        labels={labels || []}
        selectedLabels={selectedLabels}
        setSelectedLabels={onSetSelectedLabels}
        reportTypes={reportTypes}
        selectedReportTypes={selectedReportTypes}
        setSelectedReportTypes={onSetSelectedReportTypes}
      />
      <Stack>
        {isSearching ? (
          <SearchResults
            appId={appId}
            searchQuery={searchQuery}
            reportTypes={selectedReportTypes}
            labelIds={selectedLabels.map((label) => label.id.toString())}
            setSelectedLabels={onSetSelectedLabels}
          />
        ) : (
          <List
            rows={[
              ...data.reports.map((report) => ({
                setSelectedLabels: onSetSelectedLabels,
                ...report,
              })),
            ]}
            pagy={data.pagy}
            config={config}
            pagination={{
              currentPage,
              previousPage,
              nextPage,
            }}
          />
        )}
      </Stack>
    </PageContainer>
  );
}

export default Reports;
