const defaultProps = {
  focusBorderColor: "purple.300",
};

const baseStyle = {
  field: {
    py: 0,
    borderRadius: "lg",
    fontWeight: "medium",
  },
};

export const Select = { defaultProps, baseStyle };
