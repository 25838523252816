import { useState } from "react";
import { ITableTraitConfig } from "core/types/TableTraitConfig";
import { useAppObjects } from "core/hooks/useAppObjects";
import { IListColumn, IListHeading } from "core/components/List/List.types";
import { Flex, Th, Tr } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import Cell from "./Cell";
import { AddColumnButton } from "./AddColumnButton";

function ListHeading({
  columns,
  sortedBy,
  setSortedBy,
  canAddAdditionalColumns,
  tableTraitConfigs,
  createTraitConfig,
  deleteTraitConfig,
  traits,
}: IListHeading) {
  const [showDeleteIcon, setShowDeleteIcon] = useState<number | null>(null);
  const { activeAppObject } = useAppObjects();

  return (
    <Tr key={activeAppObject?.slug}>
      {columns.map((column: IListColumn, index: number) => (
        <Th key={index} width={column.width}>
          <Cell
            textAlign={column.align}
            text={column.title}
            name={column.name}
            color="gray.700"
            fontWeight="semibold"
            fontSize="xs"
            tooltip={column.tooltip}
            TooltipComponent={column.TooltipComponent}
            sortable={column.sortable}
            sortedBy={sortedBy}
            setSortedBy={setSortedBy}
          />
        </Th>
      ))}
      {canAddAdditionalColumns &&
        Array.isArray(tableTraitConfigs) &&
        tableTraitConfigs.map(
          (tableTraitConfig: ITableTraitConfig, index: number) => (
            <Th key={index}>
              <Flex
                onMouseEnter={() => setShowDeleteIcon(tableTraitConfig.id)}
                onMouseLeave={() => setShowDeleteIcon(null)}
                align="center"
                gridGap={1}
                position="relative"
              >
                <Flex>
                  <Cell
                    text={tableTraitConfig.trait}
                    name={tableTraitConfig.trait}
                    fontWeight="semibold"
                    fontSize="xs"
                    color={
                      showDeleteIcon === tableTraitConfig.id
                        ? "purple.500"
                        : "black"
                    }
                  />
                </Flex>
                <Flex>
                  {showDeleteIcon === tableTraitConfig.id && (
                    <DeleteIcon
                      position="absolute"
                      bottom="7px"
                      cursor="pointer"
                      onClick={() =>
                        deleteTraitConfig &&
                        deleteTraitConfig({ id: String(tableTraitConfig.id) })
                      }
                      fontSize="xs"
                      color={
                        showDeleteIcon === tableTraitConfig.id
                          ? "purple.500"
                          : "black"
                      }
                    />
                  )}
                </Flex>
              </Flex>
            </Th>
          ),
        )}
      {canAddAdditionalColumns && (
        <Th>
          <AddColumnButton
            existingConfiguredTraits={(tableTraitConfigs || []).map(
              (t: ITableTraitConfig) => t.trait,
            )}
            createTraitConfig={createTraitConfig}
            traits={traits}
          />
        </Th>
      )}
    </Tr>
  );
}

export default ListHeading;
