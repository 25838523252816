import { NoSymbolIcon } from "@heroicons/react/24/outline";
import { Button } from "@june-so/ui";

export const ClearFilters: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => {
  return (
    <Button onClick={onClick} color="purple" variant="ghost">
      <div className="flex items-center gap-1">
        <NoSymbolIcon className="h-4 w-4" />
        Clear filters
      </div>
    </Button>
  );
};
