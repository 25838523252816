import { ITemplateConfigSetupSection } from "core/types/TemplateConfig";
import { IEvent } from "core/types/Event";
import { useReportSetup } from "core/hooks/useReportSetup";
import { COMPARISON_TO_NAME } from "core/constants/traitFilterComponents";
import { PageEvent } from "core/components/Event/PageEvent";

const PROPERTY_TYPE = 3;

function PageEventDropdown({
  section,
  event,
  ...props
}: {
  section: ITemplateConfigSetupSection;
  event: IEvent;
}) {
  const { updatePageEvent } = useReportSetup();
  const existingProperties = event.properties[0].body;
  const { value, comparisonType: comparison, property } = existingProperties;

  function onEventUpdate({
    _key,
    _value,
  }: {
    _key: string;
    _value: string | number;
  }) {
    updatePageEvent({
      event: {
        ...event,
        name: `Page where ${property} ${COMPARISON_TO_NAME[comparison as any]} ${_value}`,
        properties: [
          {
            body: {
              ...existingProperties,
              [_key]: _value,
            },
            type: PROPERTY_TYPE,
          },
        ],
      },
      configKey: section.configKey,
    });
  }

  function onChangeProperty({
    currentTarget: { innerText },
  }: {
    currentTarget: { innerText: string };
  }) {
    onEventUpdate({ _key: "property", _value: innerText });
  }

  function onChangeComparison(newComparison: string) {
    onEventUpdate({ _key: "comparisonType", _value: newComparison });
  }

  function onChangeValue(v: string) {
    onEventUpdate({ _key: "value", _value: v });
  }

  return (
    <PageEvent
      property={property}
      comparison={comparison}
      value={value}
      onChangeProperty={onChangeProperty}
      onChangeComparison={onChangeComparison}
      onChangeValue={onChangeValue}
    />
  );
}

export default PageEventDropdown;
