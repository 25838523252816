import { IAudience } from "core/types/Audience";
import { Flex, Text } from "@chakra-ui/react";
import { AudiencePreview } from "../Setup/HeaderPreviews/AudiencePreview";

interface ISavedAudienceTooltipPreview {
  audience: IAudience;
}

export const SavedAudienceTooltipPreview: React.FC<
  ISavedAudienceTooltipPreview
> = ({ audience }) => {
  return (
    <Flex w="full" direction="column">
      <Text color="white" fontSize="sm">
        {audience.name}
      </Text>
      {audience.contactCount && (
        <Text color="white" fontSize="sm">
          {audience.contactCount} users
        </Text>
      )}
      <Text color="gray.500" fontSize="sm" mb={4}>
        {audience.description}
      </Text>
      <Flex mb={2} direction="column" gridGap={2}>
        <AudiencePreview
          colorScheme="dark"
          audienceFilters={{
            filterGroups: audience.filterGroups,
            joinOperator: audience.joinOperator,
          }}
        />
      </Flex>
    </Flex>
  );
};
