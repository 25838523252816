import { AppDeletionModal } from "modules/Settings/AppDeletion/Modal";
import { Button, useDisclosure } from "@chakra-ui/react";

export const WorkspaceDeletion: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <div className="align-center flex w-full justify-between">
        <div className="flex flex-col">
          <div className="mb-1 text-sm font-semibold">
            Request workspace deletion
          </div>
          <div className="text-xs">
            Request deletion for your workspace. This will delete all the data
            collected by June.
          </div>
        </div>
        <Button colorScheme={"red"} mt={2} px={4} onClick={onOpen}>
          Request workspace deletion
        </Button>
      </div>
      <AppDeletionModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};
