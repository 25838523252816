import { Link } from "react-router-dom";

interface IEventNameCellProps {
  appId?: number | string;
  id?: number | string;
  name?: string;
}

function EventNameCell({
  props: { appId, id, name },
}: {
  props: IEventNameCellProps;
}) {
  return (
    <Link to={`/a/${appId}/event/${id}`}>
      <div className="text-primary inline-flex max-w-full rounded-md border border-gray-50 bg-white p-1 px-2 text-sm font-medium shadow-sm">
        <p className="max-w-md truncate text-purple-500" title={name}>
          {name}
        </p>
      </div>
    </Link>
  );
}

export default EventNameCell;
