import { useState } from "react";
import { usePinViewMutation, useUpdateViewMutation } from "core/models/views";
import { useViews, ViewLocation } from "core/hooks/useViews";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { baseUrl } from "core/constants/paywall";
import {
  IPaywallModalChildren,
  PaywallModal,
} from "core/components/Paywall/Modal";
import { EmojiPicker } from "core/components/EmojiPicker";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Disclosure } from "@headlessui/react";
import { Button, Tooltip } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { ItemText } from "./ItemText";
import { ItemContainer } from "./ItemContainer";
import { AnimatePresence, motion } from "framer-motion";
import { useSidebarCollapse } from "core/hooks/useSidebarCollapse";

export const Views = () => {
  const { views, isLoadingViews } = useViews({
    location: ViewLocation.Home,
    pinned: true,
  });
  const [updateView] = useUpdateViewMutation();
  const pathArray = window.location.pathname.split(`/`);
  const viewId = pathArray.includes("home")
    ? pathArray[pathArray.indexOf("home") + 1]
    : "";
  const { id: appId } = useCurrentApp();
  const [showPaywallModal, setShowPaywallModal] = useState(false);
  const { isViewsCollapsed, toggleViewsCollapse } = useSidebarCollapse();
  const [pinView] = usePinViewMutation();

  function handleEmojiClick(emojiObject: { emoji: string }, viewId: number) {
    updateView({
      id: Number(viewId),
      appId: Number(appId),
      emoji: emojiObject.emoji,
    });
  }

  return (
    <>
      <Disclosure defaultOpen={!isViewsCollapsed}>
        {({ open }) => (
          <>
            <Disclosure.Button
              onClick={toggleViewsCollapse}
              className="group flex w-full items-center justify-between px-4 text-left font-medium text-gray-600 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
            >
              <span className="text-xs font-medium">Views</span>
              <motion.div animate={{ rotate: open ? 0 : -90 }}>
                <ChevronDownIcon className="h-4 w-4  text-gray-500 group-hover:block group-hover:text-gray-500" />
              </motion.div>
            </Disclosure.Button>
            <AnimatePresence>
              <Disclosure.Panel className="text-sm text-gray-500">
                {isLoadingViews ? (
                  <div className="flex flex-col gap-1">
                    <div className="mx-3 flex h-[22px] animate-pulse flex-row items-center justify-center space-x-5">
                      <div className="h-full w-full rounded-md bg-gray-200"></div>
                    </div>
                    <div className="mx-3 flex h-[22px] animate-pulse flex-row items-center justify-center space-x-5">
                      <div className="h-full w-full rounded-md bg-gray-300"></div>
                    </div>
                    <div className="mx-3 flex h-[22px] animate-pulse flex-row items-center justify-center space-x-5">
                      <div className="h-full w-full rounded-md bg-gray-200"></div>
                    </div>
                  </div>
                ) : (
                  <motion.div
                    animate={{ opacity: 1, height: "auto" }}
                    initial={{ opacity: 0, height: 0 }}
                    exit={{ opacity: 0, height: 0 }}
                    className="flex flex-col gap-1"
                  >
                    {views?.map((view) => (
                      <ItemContainer
                        key={view.id}
                        isCurrent={viewId === String(view.id)}
                        to={`/a/${view.appId}/home/${view.id}`}
                        className="group relative flex justify-between"
                      >
                        <div className="-ml-1 flex items-center">
                          <EmojiPicker
                            emoji={view.emoji ? view.emoji : "🏠"}
                            handleEmojiClick={(e) =>
                              handleEmojiClick(e, Number(view.id))
                            }
                          />
                          <div className="ml-0.5 line-clamp-1 max-w-[210px]">
                            {view.name ? (
                              <ItemText className="max-w-[210px] text-ellipsis">
                                {view.name}
                              </ItemText>
                            ) : (
                              <ItemText className="text-opacity-60">
                                Untitled view
                              </ItemText>
                            )}
                          </div>
                        </div>
                        <div className="absolute right-0 hidden group-hover:block">
                          <Tooltip
                            size="sm"
                            label="Unpin view"
                            hasArrow
                            placement="top"
                          >
                            <Button
                              _hover={{ bg: "gray.300" }}
                              size="xs"
                              variant="ghost"
                              onClick={() => {
                                pinView({
                                  appId: Number(appId),
                                  viewId: Number(view.id),
                                  pinned: !view.pinned,
                                });
                              }}
                              className="mr-0.5 hover:bg-gray-100 hover:text-black"
                            >
                              <CloseIcon w={2.5} h={2.5} />
                            </Button>
                          </Tooltip>
                        </div>
                      </ItemContainer>
                    ))}
                    <ItemContainer
                      to={`/a/${appId}/views`}
                      className="group relative flex justify-between px-2"
                    >
                      <div className="-ml-1 flex items-center">
                        <div className="ml-0.5 line-clamp-1 max-w-[210px]">
                          <p className="text-[13px] text-gray-600">View all</p>
                        </div>
                      </div>
                    </ItemContainer>
                  </motion.div>
                )}
              </Disclosure.Panel>
            </AnimatePresence>
          </>
        )}
      </Disclosure>
      {showPaywallModal && (
        <PaywallModal redirectUrl={`${baseUrl}/a/${appId}/home`}>
          {(paywallModal: IPaywallModalChildren) => (
            <paywallModal.Modal
              isOpen
              onClose={() => setShowPaywallModal(false)}
            />
          )}
        </PaywallModal>
      )}
    </>
  );
};
