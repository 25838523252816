import { Label, ReferenceLine } from "recharts";
import { useState } from "react";
import { IReport } from "core/types/Report";
import { useAppObjects } from "core/hooks/useAppObjects";
import colors from "core/design-system/constants/theme/colors";
import { ClickableTooltipFooter } from "core/design-system/charts/Tooltip/ClickableTooltipFooter";
import { ClickableTooltipContainer } from "core/design-system/charts/Tooltip/ClickableTooltipContainer";
import {
  CommonDot,
  CommonGrid,
  CommonTooltip,
  CommonXAxis,
  CommonYAxis,
} from "core/design-system/charts/Common";
import { Area, AreaChart } from "core/design-system/charts/AreaChart";
import TickText from "core/design-system/charts//TickText";
import { Flex, Text, useDisclosure } from "@chakra-ui/react";
import { ActiveGroupsList } from "./ActiveGroupsList";

interface ILineChart {
  report: IReport;
  unit: string;
  data: any;
  dataKey: string;
  previewMode: boolean;
  sharingMode: boolean;
  hasHour: boolean;
  intervalType: number;
  settings: { [key: string]: any };
  showYAxis?: boolean;
  showInProgress?: boolean;
  allTimeHigh: number;
}

interface ILineChartFormattedData {
  x: string;
  y?: number;
  currentY?: number;
  hideY: boolean;
  previousDate: string;
  date: string;
  hour: string;
  incomplete: boolean;
}

function AllTimeHighLabel({ viewBox }: { viewBox: any }) {
  return (
    <text
      fontSize="10px"
      x={viewBox.x}
      y={viewBox.y}
      fill={colors.purple[400]}
      dy={-5}
      dx={0}
    >
      All time high
    </text>
  );
}

function LineChart({
  report,
  unit,
  data,
  dataKey = "activeGroups",
  previewMode,
  settings,
  sharingMode,
  intervalType,
  hasHour,
  showYAxis = true,
  allTimeHigh,
  showInProgress = true,
}: ILineChart) {
  const {
    isOpen: isAudiencePanelOpen,
    onOpen: onAudienceOpen,
    onClose: onAudienceClose,
  } = useDisclosure();
  const { activeAppObject } = useAppObjects();

  const [humanizedDate, setHumanizedDate] = useState("");
  const [date, setDate] = useState("");
  const [hour, setHour] = useState("");
  const showNew = !settings?.removeNewUsers;
  const [activeCount, setActiveCount] = useState(0);

  let marker: number | undefined = undefined;

  const formattedData = data.reduce(
    (formatted: ILineChartFormattedData[], row: any, currentIndex: number) => {
      const previousRow = data[currentIndex - 1];
      const nextRow = data[currentIndex + 1];
      const previousDate = previousRow?.x;

      if (!marker && row.incomplete) {
        marker = previousRow.x;
      }

      formatted.push({
        x: row.humanizedDate,
        y: !row.incomplete ? row[dataKey] : undefined,
        currentY:
          nextRow?.incomplete || row.incomplete ? row[dataKey] : undefined,
        hideY: nextRow?.incomplete,
        previousDate,
        date: row.date,
        hour: row.hour,
        incomplete: row.incomplete,
      });
      return formatted;
    },
    [],
  );

  if (!showInProgress) {
    formattedData.pop();
  }

  function CustomTooltip({
    label,
    payload,
    active,
    onClick,
  }: {
    label: string;
    payload: { [key: string]: any };
    active: boolean;
    onClick: (_: undefined, payload: any) => void;
  }) {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <ClickableTooltipContainer
          isClickable={!previewMode}
          onClick={() => {
            onClick(undefined, { payload: data });
          }}
        >
          <Text fontSize="xs" color="gray.400">
            {label}
            {` `}
            {typeof data.currentY === "number" && !data.hideY && (
              <Text as="span" fontSize="xs" color="white" fontWeight="semibold">
                (in progress)
              </Text>
            )}
          </Text>
          {typeof data.y === "number" && (
            <Flex>
              <Text fontSize="sm" color="white">
                {data.y} {activeAppObject?.pluralName.toLowerCase()}
              </Text>
            </Flex>
          )}
          {typeof data.currentY === "number" && !data.hideY && (
            <Flex>
              <Text fontSize="sm" color="white">
                {data.currentY} {activeAppObject?.pluralName.toLowerCase()}
              </Text>
            </Flex>
          )}
          {!previewMode && <ClickableTooltipFooter />}
        </ClickableTooltipContainer>
      );
    }
    return null;
  }

  function onDataPointClick(
    _data: any,
    { payload }: { payload: { [key: string]: any } },
  ) {
    if (!previewMode && !sharingMode) {
      onAudienceOpen();
      setHumanizedDate(payload.x);
      setDate(payload.date);
      if (typeof payload.y === "number") {
        setActiveCount(payload.y);
      }
      if (typeof payload.currentY === "number") {
        setActiveCount(payload.currentY);
      }
      if (hasHour) {
        setHour(payload.hour);
      }
    }
  }

  return (
    <Flex justify="center" h={300}>
      <AreaChart
        margin={{ top: 30, left: showYAxis ? 0 : -30, right: 50, bottom: 20 }}
        height="100%"
        data={formattedData}
      >
        <defs>
          <linearGradient id="purpleGradient" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor={colors.purple[400]}
              stopOpacity={0.8}
            />
            <stop
              offset="95%"
              stopColor={colors.purple[100]}
              stopOpacity={0.4}
            />
          </linearGradient>
          <linearGradient
            id="currentPeriodGradient"
            x1="0"
            y1="0"
            x2="0"
            y2="1"
          >
            <stop offset="5%" stopColor={colors.gray[400]} stopOpacity={0.8} />
            <stop offset="95%" stopColor={colors.gray[100]} stopOpacity={0.4} />
          </linearGradient>
        </defs>
        <CommonGrid />
        {/* @ts-ignore */}
        <CommonXAxis dataKey="x" />
        {/* @ts-ignore */}
        <CommonYAxis
          tick={({
            x,
            ...props
          }: {
            x: number;
            payload: { [key: string]: any };
          }) =>
            showYAxis && (
              // @ts-ignore
              <TickText x={x - 5} {...props}>
                {props.payload.value}
              </TickText>
            )
          }
        />
        {/* @ts-ignore */}
        <CommonTooltip
          trigger="hover"
          content={<CustomTooltip onClick={onDataPointClick} />}
        />
        <ReferenceLine
          y={allTimeHigh}
          stroke={colors.purple[300]}
          alwaysShow={true}
        >
          {/* @ts-ignore */}
          <Label content={AllTimeHighLabel} />
        </ReferenceLine>
        {showInProgress && (
          <ReferenceLine
            x={marker}
            stroke={colors.gray[600]}
            strokeDasharray="3 3"
          />
        )}
        <Area
          dataKey="y"
          activeDot={
            // @ts-ignore
            <CommonDot
              r={7}
              onClick={onDataPointClick}
              cursor={!previewMode && "pointer"}
            />
          }
          type="monotone"
          fillOpacity={1}
          fill="url(#purpleGradient)"
        />
        <Area
          type="monotone"
          fillOpacity={1}
          fill="url(#currentPeriodGradient)"
          dataKey="currentY"
          stroke={colors.gray[500]}
          activeDot={
            // @ts-ignore
            <CommonDot
              r={7}
              onClick={onDataPointClick}
              cursor={!previewMode && "pointer"}
            />
          }
        />
      </AreaChart>
      {!sharingMode && !previewMode && report && (
        <ActiveGroupsList
          report={report}
          key={`${date}_${hour}`}
          isOpen={isAudiencePanelOpen}
          onClose={onAudienceClose}
          humanizedDate={humanizedDate}
          date={date}
          hour={hour}
          showNew={showNew}
          activeGroupsCount={activeCount}
          intervalType={intervalType}
        />
      )}
    </Flex>
  );
}

LineChart.defaultProps = {};

export default LineChart;
