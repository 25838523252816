import humps from "humps";
import { ITrait } from "core/types/Trait";
import { baseQuery } from "core/initializers/rtk";
import { ADOPTION_PERCENTAGE, DESC } from "core/constants/featureList";
import { GroupType } from "core/components/Group/GroupListSingleGroup";
import { createApi } from "@reduxjs/toolkit/query/react";
import { IHomeFeature } from "../types/HomeFeature";

export interface IHomeInsight {
  insight: "widgetables" | "features";
}

interface IGetHomeHighlightsArgs extends IHomeInsight {
  appId: number;
}

export interface IPowerUser {
  id: string;
  traits: ITrait;
  daysActive: number;
  isIdentified: boolean;
}

interface Idata {
  activeUsers: number;
  activeGroups: number;
  date: string;
  hour: number;
  humanizedDate: string;
  identifiedNewUsersCount: number;
  identifiedUsersCount: number;
  incomplete: boolean;
}

export interface ISingleCohortData {
  id: string;
  data: { x: string; y: number }[];
  intervalType: string;
}

export interface IGetHomeHighlightsResponse {
  reportId?: number;
  reportName?: string;

  data?: Idata[];
  retentionDataByCohort?: ISingleCohortData[];
  retentionData?: ISingleCohortData[];

  // For all other insights
  [key: string]: any;
}

interface IHomeFeatureResponse {
  features: IHomeFeature[];
  pagination: {
    page: number;
    pages: number;
    items: number;
  };
}

export const homeApi = createApi({
  baseQuery,
  reducerPath: "homeApi",
  refetchOnMountOrArgChange: true,
  tagTypes: ["Home", "FeaturesList"],
  endpoints: (builder) => ({
    // TODO: Differentiate endpoints for different insights
    getHomeHighlights: builder.query<
      IGetHomeHighlightsResponse,
      IGetHomeHighlightsArgs
    >({
      query: ({ appId, insight }) => ({
        url: `home`,
        params: { app_id: appId, insight },
      }),
      providesTags: ["Home"],
      transformResponse: (response: any): IGetHomeHighlightsResponse =>
        humps.camelizeKeys(response) as IGetHomeHighlightsResponse,
    }),
    getHomeFeatureList: builder.query<
      IHomeFeatureResponse,
      {
        appId: number;
        items?: number;
        page: number;
        column: string;
        order: string;
        groupId?: string;
        groupType?: GroupType;
      }
    >({
      query: ({
        appId,
        items,
        page,
        column = ADOPTION_PERCENTAGE,
        order = DESC,
        groupId,
        groupType,
      }) => ({
        url: `home`,
        params: humps.decamelizeKeys({
          appId,
          items,
          page,
          insight: "feature_list",
          column,
          order,
          groupId,
          groupType,
        }),
      }),
      providesTags: ["FeaturesList"],
      transformResponse: (response: any) =>
        humps.camelizeKeys(response) as unknown as IHomeFeatureResponse,
    }),
  }),
});

export const { useGetHomeHighlightsQuery, useGetHomeFeatureListQuery } =
  homeApi;
