import React from "react";
import { Box } from "@chakra-ui/layout";

export const ShareBox = ({
  sharingEnabled,
  children,
}: {
  sharingEnabled: boolean;
  children: React.ReactNode;
}) => {
  const styleProps = !sharingEnabled
    ? { cursor: "not-allowed", filter: "opacity(0.5)" }
    : {};
  return <Box style={styleProps}>{children}</Box>;
};
