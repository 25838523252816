export const LESS_THAN = 0 as number;
export const MORE_THAN = 1 as number;
export const EXACTLY = 2 as number;
export const COMPARISON_SYMBOL = [LESS_THAN, MORE_THAN, EXACTLY] as number[];

export const COMPARISON_TO_LABEL = {
  [LESS_THAN]: "less than",
  [MORE_THAN]: "more than",
  [EXACTLY]: "exactly",
} as { [key: number]: string };
