import React from "react";
import ApiKeys from "modules/Settings/ApiKeys";
import CompanyJuneLogoIcon from "core/design-system/components/Icon/Logos/CompanyJuneLogoIcon";
import { SDK } from "core/constants/apiKeys";
import { KeyIcon } from "@heroicons/react/24/outline";
import { Flex } from "@chakra-ui/react";
import * as Integration from "../../../modules/Settings/Integrations/Integration";

const CONTENT: IntegrationMetadata = {
  title: "June SDK",
  description: "Track your events with us",
  logoComponent: (
    <CompanyJuneLogoIcon name="companyJuneFullLogo" w="full" h="full" />
  ),
  docsUrl: "https://www.june.so/docs",
};

export const Card = ({ children }: { children: React.ReactNode }) => (
  <Flex
    direction="column"
    bg="white"
    shadow="sm"
    p={6}
    w="full"
    my={4}
    borderWidth={1}
    borderColor={"gray.100"}
    boxShadow="sm"
    borderRadius="md"
  >
    {children}
  </Flex>
);

const JuneSdk: React.FC = () => {
  return (
    <>
      <Integration.Container isExpanded>
        <Integration.Header metadata={CONTENT} />
        <Integration.Body>
          <Integration.Section>
            <Integration.Collapsable
              title={"Your write API keys"}
              headerIcon={<KeyIcon width={20} height={20} />}
            >
              <ApiKeys source={SDK} />
            </Integration.Collapsable>
          </Integration.Section>
        </Integration.Body>
      </Integration.Container>
    </>
  );
};

export default JuneSdk;
