import { motion, isValidMotionProp } from "framer-motion";
import { Button, forwardRef } from "@chakra-ui/react";

const MotionButton = motion(
  forwardRef((props, ref) => {
    const chakraProps = Object.fromEntries(
      // do not pass framer props to DOM element
      Object.entries(props).filter(([key]) => !isValidMotionProp(key)),
    );
    return (
      <Button ref={ref} {...chakraProps} {...props}>
        {props.children}
      </Button>
    );
  }),
);

export default MotionButton;
