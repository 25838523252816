export const NewActiveUsersCustomLegend: React.FC<{
  payload: { [key: string]: any }[];
}> = ({ payload }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      {payload.map((entry) => {
        const { value, color, payload: p } = entry;
        if (!p.showLegend) return null;

        return (
          <span key={`item-${value}`}>
            <svg width="160" height="20" viewBox="0 0 180 100">
              <circle cx="-10" cy="50" r="30" fill={color} />
              <text x="40" y="70" fill="black" fontSize="60">
                {value}
              </text>
            </svg>
          </span>
        );
      })}
    </div>
  );
};
