export const hottestMockData = {
  groups: [
    {
      groupId: "0d3467c6-9206-4776-8781-fcdb2ace7ef0",
      traits: {
        name: "Wintheiser, McCullough and Kunde",
        connectedSegment: "false",
      },
      milestonesCompleted: "5",
      firstEventTimestamp: "2022-07-25 10:36:57.000000",
    },
    {
      groupId: "5897406e-10ab-4b5a-a059-c404df256285",
      traits: {
        name: "Brakus-Hermann",
        connectedSegment: "false",
      },
      milestonesCompleted: "5",
      firstEventTimestamp: "2022-07-25 10:36:57.000000",
    },
    {
      groupId: "6f5d510b-1742-43b8-8f60-cd72f0c1b581",
      traits: {
        connected_segment: "false",
        name: "TrSp-Cartwright",
      },
      milestonesCompleted: "5",
      firstEventTimestamp: "2022-07-24 10:36:57.000000",
    },
    {
      groupId: "05f2d1ee-7e61-4006-85b8-7151ef13de2e",
      traits: {
        name: "McLaughlin LLC",
        connectedSegment: "true",
      },
      milestonesCompleted: "5",
      firstEventTimestamp: "2022-07-23 10:36:57.000000",
    },
    {
      groupId: "5938eefc-6ac1-4a5b-bbdd-a64b33b5e2a7",
      traits: {
        name: "Schneider-O'Keefe",
        connectedSegment: "false",
      },
      milestonesCompleted: "5",
      firstEventTimestamp: "2022-07-23 10:36:57.000000",
    },
  ],
};
