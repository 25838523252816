import moment from "moment";
import { useAppObjects } from "core/hooks/useAppObjects";
import { ClockIcon } from "@heroicons/react/16/solid";

export const CurrentPeriodWarning = ({
  intervalType,
}: {
  intervalType: "week" | "month";
}) => {
  const { activeAppObject } = useAppObjects();

  const dayOfInterval =
    intervalType === "week" ? moment().isoWeekday() : moment().date();
  return (
    <div className="flex w-full items-center justify-center gap-2 bg-gray-100 p-2">
      <ClockIcon className="h-4 w-4 text-gray-800" />
      <p className="text-sm font-normal text-gray-800">
        The power {activeAppObject?.pluralName.toLowerCase()} percentage is in
        progress, because we're only {dayOfInterval} days into the current{" "}
        {intervalType}.
      </p>
    </div>
  );
};
