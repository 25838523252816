import React, { useState } from "react";
import { IApiKey } from "core/models/apiKeys";
import { useApiKeys } from "core/hooks/useApiKeys";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

interface IApiKeyNameEditorModalProps {
  isOpen: boolean;
  toggle: () => void;
  apiKey: IApiKey;
  appId: number;
}

export const ApiKeyNameEditorModal: React.FC<IApiKeyNameEditorModalProps> = ({
  isOpen,
  toggle,
  apiKey,
  appId,
}) => {
  const { name: initialName, source } = apiKey;
  const [name, setName] = useState(initialName);
  const { updateApiKey } = useApiKeys({
    appId,
    source,
    apiKeyId: apiKey.id,
    keyType: apiKey.keyType,
    name,
  });

  const handleChange = (event: any) => {
    const { value } = event.target;
    setName(value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    try {
      updateApiKey();
      toggle();
    } catch (error) {
      console.log("errors");
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={toggle}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit API key name</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div style={{ width: "50%" }}>
            <div style={{ padding: "0.2rem 0" }}>
              <Input
                width="fit"
                placeholder="Unnamed key"
                type="text"
                defaultValue={initialName}
                onChange={handleChange}
              />
            </div>
          </div>
          <Button onClick={handleSubmit} style={{ margin: "1rem 0" }}>
            Rename
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ApiKeyNameEditorModal;
