import React, { useEffect } from "react";
import { Text, Button, Center, Flex } from "@chakra-ui/react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { SLACK_REDIRECT_URL_KEY } from "core/constants/integrations";
import { motion } from "framer-motion";
import NewLogo from "core/design-system/components/Icon/Logos/NewLogo.svg";

function Slack() {
  const { appId } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const redirectPath = localStorage.getItem(SLACK_REDIRECT_URL_KEY);
    localStorage.removeItem(SLACK_REDIRECT_URL_KEY);
    if (redirectPath && redirectPath !== "undefined") {
      navigate(redirectPath);
    }
  }, [navigate]);

  return (
    <Center
      h="100vh"
      w="100vw"
      backgroundImage="radial-gradient(circle at center, #7b7bff1c 0, #ffffff 60%)"
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        style={{
          width: "480px",
          backgroundColor: "white",
          borderRadius: "0.5rem",
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          padding: "2rem 1.5rem",
          boxShadow:
            "rgb(0 0 0 / 5%) 0px 0px 0px 0px, rgb(0 0 0 / 5%) 0px 2px 4px",
        }}
      >
        <Flex direction="column" alignItems="center" w="full">
          <div className="flex flex-1 space-x-3">
            <img className="h-[40px]" src={NewLogo} alt="" />
            <img className="h-[37px]" src="/logos/slack.svg" alt="" />
          </div>
          <Text fontSize="lg" fontWeight="semibold" mt={4} mb={2}>
            Successfully connected to Slack
          </Text>
          <Text fontSize="md" color="gray.600" textAlign="center">
            You can now receive updates on your users behaviours without leaving
            Slack
          </Text>
        </Flex>

        <Button colorScheme="purple" as={Link} to={`/a/${appId}/home`}>
          Continue
        </Button>
      </motion.div>
    </Center>
  );
}

export default Slack;
