import { trackEvent } from "core/helpers/trackEvent";
import { Button, Flex, Tooltip } from "@chakra-ui/react";
import { UninstallModal } from "../Hubspot/UninstallModal";

export const Action = ({
  hasAttioInstalled,
  onOpenConfirmation,
  isConfirmationOpen,
  onCloseConfirmation,
  onDeleteSettings,
  id,
}: {
  hasAttioInstalled: boolean;
  hasAttioFeature: boolean;
  onOpenConfirmation: () => void;
  isConfirmationOpen: boolean;
  onCloseConfirmation: () => void;
  onDeleteSettings: () => void;
  id: number;
}) => {
  return (
    <div className="flex">
      <Flex align="center" gridGap={2}>
        {hasAttioInstalled ? (
          <>
            <Button onClick={onOpenConfirmation}>Uninstall</Button>
            <UninstallModal
              isOpen={isConfirmationOpen}
              onClose={onCloseConfirmation}
              onUninstall={() => {
                onDeleteSettings();
              }}
              integration="attio"
            />
          </>
        ) : (
          <Tooltip>
            <Button
              onClick={() => {
                trackEvent({ eventName: "clicked_install_attio", appId: id });
                window.location.replace(
                  `${import.meta.env.VITE_API_HOST}/auth/attio`,
                );
              }}
              colorScheme="purple"
            >
              Connect Attio
            </Button>
          </Tooltip>
        )}
      </Flex>
    </div>
  );
};
