export const mockActiveGroupsChangesData = [
  {
    count: 220,
    insightChange: 2,
    date: "2021-07-22",
    humanizedDate: "Thu, 22 Jul",
  },
  {
    insightChange: 23,
    count: 340,
    date: "2021-07-19",
    humanizedDate: "Jul 19 - Jul 25",
  },
  {
    insightChange: 34,
    count: 578,
    date: "2021-07-01",
    humanizedDate: "July",
  },
];

export const dagMockData = {
  data: [
    { humanizedDate: "Tue, 26 Jan", activeGroups: 6 },
    { humanizedDate: "Wed, 27 Jan", activeGroups: 16 },
    { humanizedDate: "Thu, 28 Jan", activeGroups: 14 },
    { humanizedDate: "Fri, 29 Jan", activeGroups: 10 },
    { humanizedDate: "Sat, 30 Jan", activeGroups: 6 },
    { humanizedDate: "Sun, 31 Jan", activeGroups: 2 },
    { humanizedDate: "Mon, 01 Feb", activeGroups: 12 },
  ],
  insightChange: 83.33,
};

export const magMockData = {
  monthlyActives: {
    humanizedDate: "February",
    activeGroups: 14,
  },
  data: [
    {
      humanizedDate: "November",
      activeGroups: 0,
    },
    {
      humanizedDate: "December",
      activeGroups: 9,
    },
    {
      humanizedDate: "January",
      activeGroups: 44,
    },
  ],
  insightChange: 79.54,
};

export const wagMockData = {
  weeklyActives: {
    humanizedDate: "Feb 01 - Feb 07",
    activeGroups: 14,
  },
  data: [
    {
      humanizedDate: "Dec 14 - Dec 20",
      activeGroups: 0,
    },
    {
      humanizedDate: "Dec 21 - Dec 27",
      activeGroups: 4,
    },
    {
      humanizedDate: "Dec 28 - Jan 03",
      activeGroups: 7,
    },
    {
      humanizedDate: "Jan 04 - Jan 10",
      activeGroups: 11,
    },
    {
      humanizedDate: "Jan 11 - Jan 17",
      activeGroups: 8,
    },
    {
      humanizedDate: "Jan 18 - Jan 24",
      activeGroups: 22,
    },
    {
      humanizedDate: "Jan 25 - Jan 31",
      activeGroups: 33,
    },
  ],
  insightChange: 33.33,
};
