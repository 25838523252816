import { useDispatch, useSelector } from "react-redux";
import { useWeeklyDigestOptInMutation } from "core/models/apps";
import {
  optimisticallyUpdateWeeklyDigestEnabled,
  selector,
} from "core/models/app";
import { useToast } from "core/hooks/useToast";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { Switch } from "@chakra-ui/react";

export const WorkspaceWeeklyDigestOptIn: React.FC = () => {
  const toast = useToast();
  const app = useCurrentApp();
  const appState = useSelector(selector);
  const [weeklyDigestOptIn] = useWeeklyDigestOptInMutation();
  const dispatch = useDispatch();

  const toggleDigest = () => {
    const newWeeklyDigestOptedInState = !appState.data?.weeklyDigestEnabled;
    dispatch(
      optimisticallyUpdateWeeklyDigestEnabled(newWeeklyDigestOptedInState),
    );
    weeklyDigestOptIn({
      appId: app.id,
      weeklyDigestEnabled: newWeeklyDigestOptedInState,
    })
      .then(() => {
        if (newWeeklyDigestOptedInState) {
          toast({
            title: "The email digest is now enabled",
            status: "success",
            description:
              "We'll send a weekly digest of the most active users and companies every Monday.",
          });
        } else {
          toast({
            title: "The email digest is now disabled",
            status: "success",
            description: "We'll no longer send a weekly digest to your team.",
          });
        }
      })
      .catch(() => {
        toast({
          title: "Uh oh! something went wrong on our side",
          description:
            "Please try again. If it still doesn't work, reach out to us on support",
          status: "error",
        });
        dispatch(
          optimisticallyUpdateWeeklyDigestEnabled(!newWeeklyDigestOptedInState),
        );
      });
  };

  return (
    <div className="align-center flex w-full justify-between rounded-lg">
      <div className="flex flex-col">
        <div className="mb-1 text-sm font-semibold text-gray-900">
          Enable workspace digest
        </div>
        <div className="text-sm text-gray-600">
          Allow users in this workspace to receive a digest of the most active
          users and companies every Monday
        </div>
      </div>
      <div className="pr-[37px]">
        <Switch
          data-testid="weekly-digest-opt-in"
          isChecked={appState.data?.weeklyDigestEnabled}
          onChange={toggleDigest}
          size="sm"
          className="ml-1"
          colorScheme="purple"
        />
      </div>
    </div>
  );
};
