import { useParams } from "react-router-dom";
import { IReportInsight } from "core/types/Report";
import useInsightDataFetching from "core/hooks/useInsightDataFetching";
import { isScreenshotMode } from "core/design-system/constants/charts";
import { JUNE_APP_ID } from "core/constants/appIds";
import { IListConfig } from "core/components/List/List.types";
import InsightCard from "core/components/InsightCard/Index";
import { CSVExport } from "core/components/CSVExport";
import { Flex } from "@chakra-ui/react";
import { mockData } from "./mockData";
import ContactLink from "../SlippingAway/ContactLink";
import List from "../../../../components/List";

const listConfig = ({
  previewMode,
  sharingMode,
}: {
  previewMode: boolean;
  sharingMode: boolean;
}) =>
  ({
    resourceName: "users",
    columns: [
      {
        title: "",
        name: "email",
        Component: ContactLink,
        width: 400,
        align: "left",
        previewMode: previewMode,
        sharingMode: sharingMode,
      },
      {
        title: "Events count",
        name: "occurrences",
        width: 200,
        align: "right",
        tooltip: `Amount of times user performed the event you picked in the set up`,
      },
    ],
  }) as IListConfig;

function UserList({
  previewMode,
  sharingMode,
  screenshotMode,
  sharingSecretToken = null,
  report,
  config,
  showFooter = true,
  showActions = true,
  showReportTitle = false,
  colSpan = 2,
}: IReportInsight) {
  const { appId } = useParams<{ appId: string }>() as {
    appId: string;
  };
  const insight = config.insights.find(
    ({ slug }) => slug === "most-engaged-users",
  );
  const isSetupValid = config.validateSetup(report.config);
  const limit = Number(appId) === JUNE_APP_ID ? 100 : 30;

  const { response, isLoading, onRefreshInsight } = useInsightDataFetching({
    report,
    insight,
    insightParams: {
      limit: isScreenshotMode ? 5 : limit,
    },
    sharingSecretToken,
    previewMode,
    skip: !isSetupValid,
  });

  const rowData = response?.data?.map((v: any) => ({
    ...v,
    sharingMode: sharingMode,
  }));
  const rowDataToCSV = rowData?.map((row: any) => [
    row?.traits?.email,
    row.occurrences,
  ]);
  const headers = ["email", "total_events"];

  if (!insight) return null;

  return (
    <InsightCard>
      {(Card) => (
        <Card.Container insight={insight} id={insight.slug} colSpan={colSpan}>
          <Card.Header
            hasCaching={response?.hasCaching}
            screenshotMode={screenshotMode}
            showReportTitle={sharingMode || showReportTitle}
            insight={insight}
            showActions={!sharingMode && !previewMode && showActions}
            sharingMode={sharingMode}
            mb={0}
            config={config}
            report={report}
            refresh={onRefreshInsight}
            cachedAt={response?.cachedAt}
          >
            <Flex align="center" gridGap={2}>
              <Flex>
                <CSVExport
                  disabled={previewMode || sharingMode}
                  data={rowDataToCSV || []}
                  headers={headers}
                  filename={`${report?.name}-${insight.slug}.csv`}
                />
              </Flex>
            </Flex>
          </Card.Header>
          <Card.Body
            insight={insight}
            isLoading={isLoading}
            isPreviewMode={previewMode}
            isSetupValid={isSetupValid}
          >
            <List
              rows={previewMode ? mockData : rowData}
              config={listConfig({ previewMode, sharingMode })}
              id="loaded"
              borderRadius={0}
              border={0}
            />
          </Card.Body>
          {insight.description && !screenshotMode && showFooter && (
            <Card.Footer bg="white">
              <Card.DescriptionAccordion insight={insight} />
            </Card.Footer>
          )}
        </Card.Container>
      )}
    </InsightCard>
  );
}

export default UserList;
