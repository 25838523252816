import { IAudienceFilter, IFilter } from "core/types/Report";
import { IAudienceFilters } from "core/types/Audience";
import { AppObjectSlugs } from "core/models/appObjects";
import { useAppObjects } from "core/hooks/useAppObjects";
import { getReadableTimerange } from "core/helpers/timerange";
import {
  COMPARISON_TO_NAME,
  OPERATOR_TO_NAME,
} from "core/constants/traitFilterComponents";
import { INTERVAL_TYPE_NAMES } from "core/constants/timerange";
import {
  ANY,
  FilterType,
  FIRST,
  FREQUENCY_MATCHER_LABELS,
  GROUP_TRAIT_FILTER,
  TimerangeType,
  USER_TRAIT_FILTER,
} from "core/constants/report-setup";
import { Flex, StyleProps, Text } from "@chakra-ui/react";

interface IAudiencePreviewProps extends StyleProps {
  audienceFilters: IAudienceFilters;
  colorScheme?: "light" | "dark";
}

export const AudiencePreview: React.FC<IAudiencePreviewProps> = ({
  audienceFilters,
  colorScheme = "light",
  ...styleProps
}) => {
  const { activeAppObject } = useAppObjects();

  function audienceTraitType(
    type: "user_trait_filter" | "GROUP_TRAIT_FILTER" | string,
  ) {
    switch (type) {
      case USER_TRAIT_FILTER:
        return "user trait";
      case GROUP_TRAIT_FILTER:
        return "company trait";
      default:
        return " ";
    }
  }

  return (
    <Flex w="full" direction="column" gridGap={2}>
      {audienceFilters.filterGroups.map(
        (filterGroup: IAudienceFilter, filterGroupIndex: number) => {
          return (
            <Flex>
              {filterGroupIndex !== 0 && (
                <Text
                  py={1.5}
                  px={2}
                  minW="50px"
                  fontSize="xs"
                  fontWeight="medium"
                  textTransform="uppercase"
                  color={colorScheme === "light" ? "gray.900" : "gray.100"}
                >
                  <Text as="span" color="gray.500">
                    -
                  </Text>{" "}
                  {/* @ts-ignore */}
                  {OPERATOR_TO_NAME[audienceFilters.joinOperator]}{" "}
                  <Text as="span" color="gray.500">
                    -
                  </Text>
                </Text>
              )}
              <Flex gridGap={0.5} {...styleProps}>
                {filterGroup.filters.map(
                  (item: IFilter, filterIndex: number) => {
                    const isDisabled =
                      (item.type === FilterType.GroupFilter &&
                        activeAppObject?.slug === AppObjectSlugs.Company) ||
                      (item.type === FilterType.CompanyFilter &&
                        activeAppObject?.slug === AppObjectSlugs.Group);
                    return (
                      <Flex key={filterIndex} gridGap={0.5}>
                        {filterIndex !== 0 && (
                          <Flex>
                            <Text
                              ml={0.5}
                              pt={1}
                              px={3}
                              minW="45px"
                              fontSize="sm"
                              fontWeight="medium"
                              bg={
                                colorScheme === "light"
                                  ? "gray.100"
                                  : "gray.800"
                              }
                              color={
                                colorScheme === "light"
                                  ? "gray.900"
                                  : "gray.100"
                              }
                            >
                              {OPERATOR_TO_NAME[filterGroup.joinOperator]}
                            </Text>
                          </Flex>
                        )}
                        {item?.body?.trait &&
                          typeof item?.body?.comparisonType === "number" && (
                            <Flex>
                              <Text
                                px={3}
                                py={1}
                                fontSize="sm"
                                fontWeight="medium"
                                border="none"
                                borderLeftRadius={filterIndex === 0 ? "lg" : 0}
                                borderRightRadius={
                                  filterIndex !== filterGroup.filters.length - 1
                                    ? 0
                                    : "lg"
                                }
                                bg={
                                  isDisabled
                                    ? "gray.50"
                                    : colorScheme === "light"
                                      ? "gray.100"
                                      : "gray.800"
                                }
                                color={
                                  isDisabled
                                    ? "gray.500"
                                    : colorScheme === "light"
                                      ? "gray.900"
                                      : "gray.100"
                                }
                              >
                                Where{" "}
                                {item?.filterType
                                  ? audienceTraitType(item.filterType)
                                  : ""}{" "}
                                <Text as="b">{item?.body?.trait}</Text>{" "}
                                {COMPARISON_TO_NAME[item.body?.comparisonType]}{" "}
                                <Text as="b">{item?.body?.value}</Text>
                              </Text>
                            </Flex>
                          )}
                        {item?.body?.eventId && (
                          <Flex>
                            <Text
                              px={3}
                              py={1}
                              fontSize="sm"
                              fontWeight="medium"
                              border="none"
                              borderLeftRadius={filterIndex === 0 ? "lg" : 0}
                              borderRightRadius={
                                filterIndex !== filterGroup.filters.length - 1
                                  ? 0
                                  : "lg"
                              }
                              bg={
                                colorScheme === "light"
                                  ? "gray.100"
                                  : "gray.800"
                              }
                              color={
                                colorScheme === "light"
                                  ? "gray.900"
                                  : "gray.100"
                              }
                            >
                              Where user performed{" "}
                              <Text as="b">{item.body.eventName}</Text>{" "}
                              {![FIRST, ANY].includes(
                                Number(item.body.eventId),
                              ) && (
                                <>
                                  {typeof item.body.comparisonType === "number"
                                    ? FREQUENCY_MATCHER_LABELS[
                                        item.body.comparisonType
                                      ]
                                    : ""}{" "}
                                  <Text as="b">{item.body.times} times</Text>{" "}
                                </>
                              )}
                              {item.body.timerangeType ===
                                TimerangeType.RELATIVE &&
                              item.body.intervalType !== undefined &&
                              item.body.interval ? (
                                <Text as="span">
                                  {" "}
                                  in the last{" "}
                                  <Text as="b">
                                    {item.body.interval}{" "}
                                    {
                                      INTERVAL_TYPE_NAMES[
                                        item.body.intervalType
                                      ]
                                    }
                                    {item.body.interval > 1 ? "s" : ""}{" "}
                                  </Text>
                                </Text>
                              ) : null}
                              {item.body.timerangeType ===
                              TimerangeType.ABSOLUTE ? (
                                <Text as="span">
                                  between{" "}
                                  <Text as="b">
                                    {getReadableTimerange({
                                      startTimestamp: item.body.startDate,
                                      endTimestamp: item.body.endDate,
                                    })}
                                  </Text>
                                </Text>
                              ) : null}
                            </Text>
                          </Flex>
                        )}
                      </Flex>
                    );
                  },
                )}
              </Flex>
            </Flex>
          );
        },
      )}
    </Flex>
  );
};
