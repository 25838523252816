import { cx } from "helpers/cx";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { EventIcon } from "core/design-system/components/Icon/Custom/EventIcon";
import { PresentationChartBarIcon } from "@heroicons/react/24/outline";
import { CircularProgress } from "@chakra-ui/react";
import { Views } from "./Views";
import { Audiences } from "./Audiences";
import { ItemText } from "./ItemText";
import { ItemContainer } from "./ItemContainer";
import { CurrentSection } from "./index";
import { ExpandedHeader } from "./Header/ExpandedHeader";
import { Footer } from "./Footer";
import { useState } from "react";
import { useGettingStarted } from "core/hooks/useGettingStarted";
import useFlag from "core/hooks/useFlag";
import { FAVOURITES } from "core/constants/features";
import { Favourites } from "./Favourites";

export interface IExpandedSidebarProps {
  currentSection: CurrentSection;
}

export const ExpandedSidebar: React.FC<IExpandedSidebarProps> = ({
  currentSection,
}) => {
  const { id: appId } = useCurrentApp();
  const { progress, showGettingStarted } = useGettingStarted();
  const [showCollapseIcon, setShowCollapseIcon] = useState(false);
  const hasFavouritesEnabled = useFlag(FAVOURITES);

  return (
    <div
      onMouseEnter={() => setShowCollapseIcon(true)}
      onMouseLeave={() => setShowCollapseIcon(false)}
      className="flex h-full min-h-screen w-[296px] flex-col justify-between  overflow-scroll bg-gray-50"
    >
      <div className="flex h-full w-full flex-col gap-1 overflow-scroll">
        <ExpandedHeader showCollapseIcon={showCollapseIcon} />
        {showGettingStarted && (
          <ItemContainer
            isCurrent={currentSection === "Getting started"}
            to={`/a/${appId}/getting-started`}
          >
            <div className="relative left-[1.5px] top-[0.5px] flex h-4 w-4 items-center">
              <CircularProgress
                thickness="10px"
                value={progress}
                color="primary"
                trackColor="purple.100"
                size="13px"
              />
            </div>
            <ItemText>Getting started</ItemText>
          </ItemContainer>
        )}
        <>
          <ItemContainer
            isCurrent={currentSection === "Reports"}
            to={`/a/${appId}/reports`}
          >
            <PresentationChartBarIcon
              className={cx(
                "h-4 w-4",
                currentSection === "Reports"
                  ? "text-purple-500"
                  : "text-gray-600",
              )}
            />
            <ItemText>Reports</ItemText>
          </ItemContainer>
          <ItemContainer
            isCurrent={currentSection === "Events"}
            to={`/a/${appId}/events`}
          >
            <EventIcon
              className={cx(
                "h-4 w-4 p-0.5",
                currentSection === "Events"
                  ? "text-purple-500"
                  : "text-gray-600",
              )}
            />
            <ItemText>Events</ItemText>
          </ItemContainer>
        </>

        <div className="mt-2" />
        <Views />
        <div className="mb-2" />

        <Audiences />

        <div className="mb-2" />

        {hasFavouritesEnabled && <Favourites />}
        <div className="mb-6" />
      </div>
      <Footer />
    </div>
  );
};
