import React from "react";
import {
  ITemplateConfigSetup,
  ITemplateConfigSetupSection,
} from "core/types/TemplateConfig";
import { getLevelParam } from "core/modules/reports/utils";
import { SetupAccordionHeaderPreview } from "core/modules/reports/setup/Setup/SetupAccordionHeaderPreview";
import { useReportSetup } from "core/hooks/useReportSetup";
import { useConflictingAudienceFilters } from "core/hooks/useConflictingAudienceFilters";
import DragDropIcon from "core/design-system/components/Icon/Interface/DragDropIcon";
import Icon from "core/design-system/components/Icon";
import { AUDIENCE_TYPE } from "core/constants/report-setup";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import {
  AccordionButton,
  AccordionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import SetupAccordionTitle from "./SetupAccordionTitle";

const SetupAccordionHeader: React.FC<{
  section: ITemplateConfigSetupSection;
  setup: ITemplateConfigSetup;
  index: number;
  isExpanded: boolean;
  setOpenPanelIndex: (index: number | null) => void;
}> = ({ section, isExpanded, setOpenPanelIndex, index, setup }) => {
  const { currentReport, removeFunnelStep } = useReportSetup();
  const reportLevel = getLevelParam();

  function onCollapseClick() {
    if (isExpanded) {
      setOpenPanelIndex(null);
    } else {
      setOpenPanelIndex(index);
    }
  }

  const { config } = currentReport;
  const { configKey, required, description } = section;
  const validationInput = Array.isArray(config[configKey])
    ? config[configKey]
    : [config[configKey]];

  const { hasConflictingAudienceFilters } = useConflictingAudienceFilters({
    audienceFilters: currentReport?.audience,
    level: reportLevel,
  });

  if (!currentReport) return null;

  return (
    <AccordionButton
      data-testid="setup-sections-accordion-item-header"
      onClick={onCollapseClick}
      _hover={{ bg: "transparent" }}
    >
      <Flex
        w="100%"
        direction="column"
        align="start"
        justify="space-between"
        gridGap={3}
        pb={2}
      >
        <Flex w="100%" justify="space-between" align="center">
          <Flex direction="column">
            <Flex direction="row">
              {section.ordering && (
                <Flex>
                  <DragDropIcon
                    data-testid="setup-accordion-header-drag-icon"
                    cursor="grab"
                    mt={3}
                    mx={2}
                  />
                </Flex>
              )}
              <Flex align="center">
                {configKey === AUDIENCE_TYPE &&
                  hasConflictingAudienceFilters && (
                    <ExclamationTriangleIcon className="mr-1 mt-1 h-4 text-yellow-500" />
                  )}
                <SetupAccordionTitle
                  section={section}
                  isExpanded={isExpanded}
                />
              </Flex>
              <Flex>
                <Box>
                  {!required && (
                    <Badge ml={2}>
                      <Text color="gray.700">Optional</Text>
                    </Badge>
                  )}
                  {required && (
                    <Badge colorScheme="purple" ml={2}>
                      <Text>Required</Text>
                    </Badge>
                  )}
                </Box>
              </Flex>
              <AccordionIcon h={4} w={4} ml={1} mt={0.5} />
            </Flex>
            {!isExpanded && configKey !== AUDIENCE_TYPE && description && (
              <Flex align="flex-start" textAlign="left">
                <Text fontSize="xs" color="gray.600" mt={2}>
                  {description}
                </Text>
              </Flex>
            )}
          </Flex>
          <Flex align="center" gridGap={2}>
            {section.isDynamic && (
              <Flex position="relative" bottom="6px">
                <Tooltip label="Remove step" placement="top" hasArrow>
                  <Button
                    data-testid="setup-accordion-header-delete-step-button"
                    onClick={() => removeFunnelStep(section.configKey)}
                    variant="ghost"
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
              </Flex>
            )}
            {section.validate && (
              <Flex
                alignSelf={"flex-start"}
                bg={
                  section.validate(validationInput.filter((x: boolean) => x))
                    ? "green.300"
                    : "gray.300"
                }
                w="20px"
                h="20px"
                borderRadius="full"
                pr={1}
              >
                <Icon
                  iconType="icon"
                  name="check"
                  p={0.5}
                  ml={0.5}
                  mt={0.5}
                  color="white"
                />
              </Flex>
            )}
          </Flex>
        </Flex>
        <SetupAccordionHeaderPreview
          isExpanded={isExpanded}
          setup={setup}
          configKey={configKey}
          section={section}
          currentReport={currentReport}
        />
      </Flex>
    </AccordionButton>
  );
};

export default SetupAccordionHeader;
