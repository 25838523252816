/**
 * Normalizes server-received insights to a UI friendly format.
 * This is also used in the Scriptable script to make using insights... easier.
 * @returns null || array
 */
export function normalizeInsights(insights) {
  if (!insights) return null;

  return insights.map((insight) => {
    let normalized = {
      // ID must be an index in report_insights.rb
      id: 0,
      value: 0,
      reportId: 0,
    };

    if (
      Array.isArray(insight?.data) &&
      insight.data.length > 0 &&
      insight.data[insight.data.length - 1]?.activeUsers !== undefined
    ) {
      normalized = {
        value: insight.data[insight.data.length - 1].activeUsers,
      };
    } else if (
      Array.isArray(insight?.data) &&
      insight.data.length > 0 &&
      insight.data[insight.data.length - 1]?.activeGroups !== undefined
    ) {
      normalized = {
        value: insight.data[insight.data.length - 1].activeGroups,
      };
    }

    return {
      ...normalized,
      reportName: insight.reportName,
      insightChange: insight.insightChange,
      reportId: insight.reportId,
      title: insight.title,
      id: insight.insightId,
    };
  });
}

/**
 * Builds an insight API endpoint for the widget to consume.
 * @returns str
 */
export function buildInsightEndpoint(
  appId,
  reportId,
  insightType,
  insightToken,
) {
  return `${
    import.meta.env.VITE_API_HOST
  }/report/insight/?app_id=${appId}&report_id=${reportId}&insight_type=${insightType}&sharing_secret_token=${insightToken}`;
}

/**
 * Returns the URL for the next step in the onboarding process.
 */
export function getNextUrl(insightEndpoint) {
  if (!insightEndpoint) return null;
  const fallbackHost = `${window.location.protocol}//${window.location.host}`;
  return `${
    import.meta.env.VITE_CLIENT_HOST ?? fallbackHost
  }/widgets/install/?insight_url=${encodeURIComponent(insightEndpoint)}`;
}
