import { IPropertyValue } from "core/types/Property";
import { IEventProperty } from "core/types/EventProperty";
import { IEvent } from "core/types/Event";
import { Lifecycle } from "core/design-system/components/Command/Lifecycle";
import {
  Command,
  CommandInput,
  CommandList,
  CommandOption,
} from "core/design-system/components/Command";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { Button, Flex, Input, Spinner, Text } from "@chakra-ui/react";
import { FrequencyBars } from "../Menu/FrequencyBars";

export const PropertyValueList = ({
  isLoading,
  hasMore,
  propertyValues,
  onChange,
  value: selectedValue,
  searchTerm,
  setSearchTerm,
  onLoadMore,
  ItemComponent,
}: {
  isLoading: boolean;
  hasMore: boolean;
  propertyValues: IPropertyValue[];
  onChange: (value: any) => void;
  value: string | null;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  onLoadMore: () => void;
  ItemComponent: any;
}) => {
  return (
    <Flex direction={"column"} width="full" gap={2}>
      <Input
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        size="sm"
        placeholder="Search"
      />
      {isLoading && <Spinner />}
      {propertyValues.map(({ value, frequency }, index) => (
        <ItemComponent
          key={index}
          value={selectedValue}
          onClick={() => onChange(value)}
          isSelected={value === selectedValue}
          width="full"
        >
          <Flex
            w="full"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
          >
            <Text>{value}</Text>
            <FrequencyBars frequency={frequency} />
          </Flex>
        </ItemComponent>
      ))}
      {hasMore && !isLoading ? (
        <Button
          onClick={onLoadMore}
          colorScheme="purple"
          size="sm"
          borderRadius={0}
          borderBottomRadius={5}
        >
          Load more
        </Button>
      ) : null}
      {propertyValues.length === 0 && !isLoading && (
        <Text textAlign="center" fontSize="sm" pb={2} color="gray.500">
          No values found
        </Text>
      )}
    </Flex>
  );
};

export const PropertyValuePicker = ({
  isLoading,
  hasMore,
  propertyValues,
  onChangeTrackPropertyValue,
  event,
  property,
  searchTerm,
  setSearchTerm,
  onLoadMore,
}: {
  isLoading: boolean;
  hasMore: boolean;
  propertyValues: IPropertyValue[];
  onChangeTrackPropertyValue: (
    e: any,
    event: IEvent,
    prop: IEventProperty,
    selected: boolean,
  ) => void;
  event: IEvent;
  property: IEventProperty;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  onLoadMore: () => void;
}) => {
  return (
    <Popover>
      {({ close }) => (
        <>
          <PopoverButton className="text-sm font-semibold">
            {property.body.value || "Select a value"}
          </PopoverButton>
          <PopoverPanel>
            <Lifecycle onUnmount={() => setSearchTerm("")} />
            <Command>
              <CommandInput
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <CommandList>
                {isLoading && (
                  <div className="flex w-full flex-col items-center">
                    <Spinner size="sm" />
                  </div>
                )}
                {!isLoading &&
                  propertyValues.map(({ value, frequency }, index) => (
                    <CommandOption
                      key={index}
                      value={value}
                      onClick={() => {
                        onChangeTrackPropertyValue(
                          { currentTarget: { value } },
                          event,
                          property,
                          true,
                        );
                        close();
                      }}
                    >
                      <div className="flex justify-between">
                        {value}
                        <FrequencyBars frequency={frequency} />
                      </div>
                    </CommandOption>
                  ))}
                {propertyValues?.length === 0 && !isLoading && (
                  <p className="text-sm">No values found</p>
                )}
              </CommandList>
              {hasMore && <Button onClick={onLoadMore}>Load more</Button>}
            </Command>
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};
