import { isEqual } from "lodash";
import { IFilter, IFilterGroup } from "core/types/Report";
import { useGetRecommendedFiltersQuery } from "core/models/audiences";
import { useUserAuth } from "core/hooks/useUserAuth";
import { useReportSetup } from "core/hooks/useReportSetup";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { uuid } from "core/helpers/uuid";
import { getValueText } from "core/helpers/audience/traitValue";
import Icon from "core/design-system/components/Icon";
import { COMPARISON_TO_NAME } from "core/constants/traitFilterComponents";
import { INTERVAL_TYPE_NAMES } from "core/constants/timerange";
import {
  FilterType,
  FREQUENCY_MATCHER_LABELS,
  JoinOperatorValue,
} from "core/constants/report-setup";
import {
  BuildingOffice2Icon,
  CursorArrowRippleIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";
import {
  Button,
  Flex,
  Spinner,
  Text,
  Tooltip,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { SmallAddIcon } from "@chakra-ui/icons";

export const RecommendedFilters = () => {
  const currentApp = useCurrentApp();
  const { currentUser } = useUserAuth();
  const { currentReport, isCurrentlyAddingFilter, addAudienceFilterGroup } =
    useReportSetup();
  const onFilterSelected = (filter: IFilter) => {
    const newFilter = {
      id: uuid(),
      adding: true,
      filterType: filter.type,
      type: filter.type,
      body: filter.body,
      isRecommended: true,
    };
    const newFilterGroup = {
      filters: [newFilter],
      joinOperator: JoinOperatorValue.AND,
    };
    addAudienceFilterGroup({ filterGroup: newFilterGroup });
  };

  const defaultFilter: IFilter = {
    id: uuid(),
    filterType: "user_trait_filter",
    adding: false,
    type: 0,
    body: {
      trait: "email",
      comparisonType: 5,
      value: currentUser ? currentUser.email.split("@")[1] : "gmail.com",
    },
  };

  const isExistingFilter = (filter: IFilter) => {
    if (!currentReport || !currentReport.audience?.filterGroups) return false;
    const existingFilters = currentReport.audience?.filterGroups.reduce(
      (f: IFilter[], fg: IFilterGroup) => f.concat([...fg.filters]),
      [],
    );
    if (
      existingFilters.some(
        (f: IFilter) => isEqual(f.body, filter.body) && f.type === filter.type,
      )
    )
      return false;
    return true;
  };

  const { data: recommendedFilters, isLoading } = useGetRecommendedFiltersQuery(
    {
      appId: currentApp.id,
    },
  );
  if (
    !currentApp ||
    !currentReport ||
    !recommendedFilters ||
    !recommendedFilters
  ) {
    return null;
  }
  if (isLoading) {
    return <Spinner />;
  }

  if (
    !recommendedFilters.recommendedFilters ||
    recommendedFilters.recommendedFilters.length === 0
  ) {
    return (
      <Wrap data-testid="recommended-filters-container">
        <WrapItem>
          <Tooltip
            shouldWrapChildren
            label={
              currentReport?.audience?.isSavedAudience &&
              "You can only use a Saved Audience or custom filters, not both"
            }
          >
            <Button
              borderRadius="lg"
              bg="gray.50"
              border="none"
              color="gray.900"
              _hover={{ bg: "gray.100" }}
              isDisabled={
                currentReport?.audience?.isSavedAudience ||
                isCurrentlyAddingFilter
              }
              onClick={() => {
                onFilterSelected(defaultFilter);
              }}
            >
              <Flex align={"center"} justify={"space-between"}>
                <Flex pr={2} align={"center"} color="black">
                  <Icon iconType="icon" name={"trait"} h={5} w={5} />
                  <Text
                    fontSize={"sm"}
                    fontWeight="semibold"
                    data-testid="recommended-trait-name"
                    mr={1}
                  >
                    {defaultFilter.body.trait}
                  </Text>{" "}
                  <Text
                    fontSize={"sm"}
                    data-testid="recommended-trait-comparison"
                    as="span"
                  >
                    {COMPARISON_TO_NAME[defaultFilter.body.comparisonType || 0]}
                  </Text>
                  <Text
                    fontSize={"sm"}
                    fontWeight="semibold"
                    data-testid="recommended-trait-value"
                    ml={1}
                  >
                    {getValueText(defaultFilter.body.value)}
                  </Text>
                </Flex>
                <SmallAddIcon h={4} w={4} />
              </Flex>
            </Button>
          </Tooltip>
        </WrapItem>
      </Wrap>
    );
  }

  const visibleRecommendedFilters =
    recommendedFilters.recommendedFilters.filter((f: IFilter) =>
      isExistingFilter(f),
    );

  if (visibleRecommendedFilters.length === 0) {
    return (
      <div className="mb-6 flex w-full flex-col items-center gap-2">
        <p className="text-xs text-gray-600">No recommended filters</p>
      </div>
    );
  }

  return (
    <Wrap data-testid="recommended-filters-container">
      {visibleRecommendedFilters.map((filter: IFilter, index) => (
        <WrapItem key={index}>
          <Tooltip
            shouldWrapChildren
            label={
              currentReport?.audience?.isSavedAudience &&
              "You can only use a Saved Audience or custom filters, not both"
            }
          >
            <Button
              borderRadius="lg"
              bg="gray.50"
              border="none"
              color="gray.900"
              _hover={{ bg: "gray.100" }}
              isDisabled={
                currentReport?.audience?.isSavedAudience ||
                isCurrentlyAddingFilter
              }
              onClick={() => {
                onFilterSelected(filter);
              }}
            >
              {[FilterType.UserTrait, FilterType.GroupFilter].includes(
                filter.type,
              ) && (
                <Flex align={"center"} justify={"space-between"}>
                  <Flex pr={2} align={"center"} color="black">
                    {filter.type === FilterType.UserTrait ? (
                      <UsersIcon
                        style={{ height: "15px", marginRight: "5px" }}
                      />
                    ) : (
                      <BuildingOffice2Icon
                        style={{ height: "15px", marginRight: "5px" }}
                      />
                    )}
                    <Text
                      fontSize={"sm"}
                      fontWeight="semibold"
                      data-testid="recommended-trait-name"
                      mr={1}
                    >
                      {filter.body.trait}
                    </Text>{" "}
                    <Text
                      fontSize={"sm"}
                      data-testid="recommended-trait-comparison"
                      as="span"
                    >
                      {COMPARISON_TO_NAME[filter.body.comparisonType || 0]}
                    </Text>
                    <Text
                      fontSize={"sm"}
                      fontWeight="semibold"
                      data-testid="recommended-trait-value"
                      ml={1}
                    >
                      {getValueText(filter.body.value)}
                    </Text>
                  </Flex>
                  <SmallAddIcon h={4} w={4} />
                </Flex>
              )}
              {FilterType.EventFilter === filter.type && (
                <Flex align={"center"} justify={"space-between"}>
                  <Flex pr={2} align={"center"} color="black">
                    <CursorArrowRippleIcon
                      style={{ height: "17px", marginRight: "5px" }}
                    />
                    <Text fontSize={"sm"} as="span">
                      User performed{" "}
                      <Text
                        fontSize={"sm"}
                        data-testid="recommended-event-name"
                        as="b"
                      >
                        {filter.body.eventName}
                      </Text>{" "}
                      <Text
                        fontSize={"sm"}
                        data-testid="recommended-event-comparison"
                        as="span"
                      >
                        {
                          FREQUENCY_MATCHER_LABELS[
                            filter.body.comparisonType || 0
                          ]
                        }{" "}
                      </Text>
                      <Text
                        fontSize={"sm"}
                        data-testid="recommended-event-times"
                        as="b"
                      >
                        {filter.body.times}{" "}
                        {filter.body.times === 1 ? " time" : " times"}
                      </Text>
                      {filter.body.interval &&
                      filter.body.intervalType !== undefined ? (
                        <Text fontSize={"sm"} as="span">
                          {" "}
                          in the last{" "}
                          <Text
                            fontSize={"sm"}
                            data-testid="recommended-event-interval"
                            as="b"
                          >
                            {filter.body.interval}{" "}
                            {INTERVAL_TYPE_NAMES[filter.body.intervalType]}
                            {filter.body.interval > 1 ? "s" : ""}{" "}
                          </Text>
                        </Text>
                      ) : null}
                    </Text>
                  </Flex>
                  <SmallAddIcon h={4} w={4} />
                </Flex>
              )}
            </Button>
          </Tooltip>
        </WrapItem>
      ))}
    </Wrap>
  );
};
