import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { IApp } from "core/types/App";
import { useGetAppsWithDomainQuery } from "core/models/apps";
import { useUserAuth } from "core/hooks/useUserAuth";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import NewLogo from "core/design-system/components/Icon/Logos/NewLogo.svg";
import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Skeleton,
  Text,
} from "@chakra-ui/react";

export const Join: React.FC = () => {
  const { domain, isLoading: isLoadingUser } = useUserAuth();
  const currentApp = useCurrentApp();
  const navigate = useNavigate();
  const { data: apps, isLoading: isLoadingDomains } = useGetAppsWithDomainQuery(
    { domain },
    {
      skip: isLoadingUser,
    },
  );

  function onContinueToWorkspace() {
    navigate(`/a/${currentApp.id}`);
  }

  if (apps && apps.length === 0) {
    navigate(`/a/${currentApp.id}/onboarding`);
    return null;
  }

  return (
    <Center
      h="100vh"
      w="100vw"
      backgroundImage="radial-gradient(circle at center, #7b7bff1c 0, #ffffff 60%)"
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        style={{
          width: "480px",
          backgroundColor: "white",
          borderRadius: "0.5rem",
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          padding: "2rem 1.5rem",
          boxShadow:
            "rgb(0 0 0 / 5%) 0px 0px 0px 0px, rgb(0 0 0 / 5%) 0px 2px 4px",
        }}
      >
        <Flex direction="column" alignItems="center" w="full">
          <Box backgroundImage={NewLogo} w="38px" h="40px"></Box>
          <Text fontSize="md" fontWeight="semibold" mt={4} mb={2}>
            We found your team
          </Text>
          <Text px={24} fontSize="sm" color="gray.600" textAlign="center">
            Your teammates are waiting for you.
          </Text>
        </Flex>
        <Box w="full">
          {(isLoadingUser || isLoadingDomains) && (
            <Skeleton height="80px" w="full" borderRadius="md" />
          )}
          <Flex direction="column" gridGap={2}>
            {apps?.map((app: IApp, index: number) => {
              return (
                <Flex
                  key={index}
                  border="1px solid"
                  borderColor="gray.200"
                  borderRadius="md"
                  align="center"
                  justifyContent="space-between"
                  px={6}
                  py={4}
                >
                  <Flex gridGap={5}>
                    <Flex>
                      <Box
                        borderRadius="lg"
                        bg="gray.200"
                        backgroundImage={app.logo}
                        w="50px"
                        h="50px"
                      ></Box>
                    </Flex>
                    <Flex direction="column">
                      <Text fontWeight="medium">{app.name} </Text>
                      <Text fontSize="sm" color="gray.600">
                        {app?.userCount} members
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex>
                    <Flex>
                      <Badge colorScheme="purple">Joined</Badge>
                    </Flex>
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        </Box>
        <Button colorScheme="purple" onClick={onContinueToWorkspace}>
          Continue
        </Button>
      </motion.div>
    </Center>
  );
};
