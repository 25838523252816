import ResizeTextarea from "react-textarea-autosize";
import React from "react";
import { Flex, Text, Textarea, TextareaProps } from "@chakra-ui/react";
import { WarningTwoIcon } from "@chakra-ui/icons";

interface EventAlertEditorTextAreaProps extends TextareaProps {
  showGroupContextWarning: boolean;
}

export const EventAlertEditorTextArea: React.FC<
  EventAlertEditorTextAreaProps
> = ({ showGroupContextWarning, ...props }) => {
  return (
    <>
      <Textarea
        minH="unset"
        overflow="hidden"
        w="100%"
        resize="none"
        minRows={1}
        as={ResizeTextarea}
        {...props}
      />
      {showGroupContextWarning && (
        <Flex mt={1} gap={1} align="center">
          <WarningTwoIcon color="yellow.500" h={3} w={3} />
          <Text fontSize="xs">
            Company traits only work with the group context.
          </Text>
          <Text
            as="a"
            fontSize="xs"
            color="purple.700"
            href="https://help.june.so/en/articles/6789004-how-to-track-companies-in-june"
            target="_blank"
          >
            Learn more
          </Text>
        </Flex>
      )}
    </>
  );
};
