import { Flex, ComponentDefaultProps } from "@chakra-ui/react";

export const Header: React.FC<ComponentDefaultProps> = ({
  children,
  ...props
}) => {
  return (
    <Flex
      data-testid="highlight-card-header"
      direction="row"
      px={8}
      pt={6}
      {...props}
    >
      {children}
    </Flex>
  );
};
