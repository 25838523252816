import React from "react";
import { Flex, Text, Tooltip } from "@chakra-ui/react";

export const AdditionalColumnCell: React.FC<{
  name: string;
  props: { properties: { [key: string]: string } };
}> = ({ name, props: { properties } }) => {
  let propertyValue;
  switch (typeof properties[name]) {
    case "object":
      propertyValue = JSON.stringify(properties[name]);
      break;
    case "boolean":
      propertyValue = JSON.stringify(properties[name]);
      break;
    default:
      propertyValue = properties[name];
  }

  return (
    <Flex>
      <Tooltip label={propertyValue}>
        <Text
          fontSize="sm"
          color="gray.600"
          maxWidth={300}
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {propertyValue}
        </Text>
      </Tooltip>
    </Flex>
  );
};
