import { IProperty, Integration } from "core/models/dataMappings";
import { CommandOption } from "core/design-system/components/Command";
import { Tooltip } from "@chakra-ui/react";

const UNIQUE_KEYS: Record<Integration, keyof IProperty> = {
  [Integration.Hubspot]: "hasUniqueValue",
  [Integration.Attio]: "isUnique",
  [Integration.Salesforce]: "isUnique",
};

const WRITABLE_KEYS: Record<Integration, keyof IProperty> = {
  [Integration.Hubspot]:
    "modificationMetadata.readOnlyValue" as keyof IProperty,
  [Integration.Attio]: "isWritable",
  [Integration.Salesforce]: "isWritable",
};

const VALID_TYPES: Record<Integration, string[]> = {
  [Integration.Hubspot]: ["single-line", "text", "domain", "email-address"],
  [Integration.Attio]: ["text"],
  [Integration.Salesforce]: ["string", "id"],
};

export const IdentifierPropertyMenu: React.FC<{
  property: IProperty;
  onSelect: (property: IProperty) => void;
  integration: Integration;
}> = ({ property, onSelect, integration }) => {
  const isUniqueKey = UNIQUE_KEYS[integration];
  const isUnique =
    integration === Integration.Salesforce ? true : property[isUniqueKey];
  const isWritable = property[WRITABLE_KEYS[integration]];

  const validType = VALID_TYPES[integration];
  const isValidType = validType.includes(property.type) && isWritable;

  return (
    <CommandOption
      value={() => isValidType && isUnique && onSelect(property)}
      disabled={!isValidType || !isUnique}
    >
      <Tooltip
        label={
          !isWritable
            ? "This field is read-only and cannot be written to"
            : !isValidType
              ? `The field type must be of type ${validType} and require unique values`
              : !isUnique
                ? "The property must require unique values"
                : ""
        }
        placement="right"
        shouldWrapChildren
        hasArrow
      >
        <div className="flex items-center space-x-2">
          <p className="truncate">{property.label}</p>
        </div>
      </Tooltip>
    </CommandOption>
  );
};
