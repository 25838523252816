import { useParams } from "react-router-dom";
import { getInsightConfig } from "modules/ViewInsight/ViewInsightContainer";
import { ColorPicker } from "modules/Labels/ColorPicker";
import { GraphType, Interval, Measure, Size } from "core/types/ViewInsight";
import { IReport } from "core/types/Report";
import { TimerangePicker } from "core/modules/reports/Timerange/TimerangePicker";
import { IViewInsight } from "core/models/viewInsights";
import { IAppObject } from "core/models/appObjects";
import { useViewInsightTimerange } from "core/hooks/useViewInsightTimerange";
import useInsightDataFetching from "core/hooks/useInsightDataFetching";
import {
  GraphTypeToIcon,
  InsightTypeConfigs,
} from "core/hooks/useEditViewInsight";
import { IColorPalette } from "core/design-system/constants/theme/colors";
import { TwEditable } from "core/design-system/components/TwEditable";
import { ABSOLUTE, DAY, MONTH, WEEK } from "core/constants/timerange";
import AnimatedToggle from "core/components/AnimatedToggle";
import {
  CalendarDaysIcon,
  CalendarIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { PaywallPopover } from "core/components/Paywall/Popover";
import { Plan } from "core/types/App";

export const PreviewContainer: React.FC<{
  viewInsight: IViewInsight;
  report: IReport;
  measure: Measure;
  size: Size;
  setSize: (size: Size) => void;
  width: string;
  height: string;
  graphType: GraphType;
  setGraphType: (graphType: GraphType) => void;
  colors: IColorPalette[];
  colorPalette: IColorPalette;
  setColorPalette: (colorPalette: IColorPalette) => void;
  title: string;
  setTitle: (title: string) => void;
  interval: Interval;
  setInterval: (interval: Interval) => void;
  appObject: IAppObject;
  setAppObject: (appObject: IAppObject) => void;
  isAddingNewInsight?: boolean;
}> = ({
  viewInsight,
  report,
  measure,
  appObject,
  size,
  width,
  height,
  graphType,
  colorPalette,
  colors,
  title,
  interval,
  setGraphType,
  setColorPalette,
  setTitle,
  setSize,
  setInterval,
  setAppObject,
  isAddingNewInsight,
}) => {
  const { groupId } = useParams();
  const insightConfig = getInsightConfig(
    viewInsight.insight.reportType,
    viewInsight.insightId as number,
  );

  const {
    label,
    relativeTimerange,
    customTimerange,
    readableCustomTimerange,
    setCustomTimerange,
    saveTimerange,
    timerangeValue,
    timerangeType,
    timerangeStartTimestamp,
    timerangeEndTimestamp,
  } = useViewInsightTimerange(viewInsight);

  const intervalTypes = {
    [Interval.Daily]: DAY,
    [Interval.Weekly]: WEEK,
    [Interval.Monthly]: MONTH,
  };

  const { response, isLoading, isFetching } = useInsightDataFetching({
    report,
    insightType: viewInsight.insightId,
    insightParams: intervalTypes[interval]
      ? {
          interval_type: intervalTypes[interval],
          size,
        }
      : { ...viewInsight.insight?.defaultParams, size } || {},
    sharingSecretToken: null,
    previewMode: false,
    groupType: groupId ? undefined : appObject?.slug,
    timerangeType,
    timerangeValue,
    timerangeStartTimestamp,
    timerangeEndTimestamp,
  });

  const data = insightConfig?.view
    ? insightConfig?.view?.transform(response?.data, interval)
    : [];
  const highlight = insightConfig?.view?.highlight
    ? insightConfig?.view?.highlight(response?.data)
    : {};

  const Component = insightConfig?.view ? (
    insightConfig?.view?.Components ? (
      insightConfig?.view?.Components[
        graphType as keyof typeof insightConfig.view.Components
      ]
    ) : (
      insightConfig?.view?.Component
    )
  ) : (
    <></>
  );

  return (
    <div className="h-full w-full rounded-lg bg-gray-100 p-5">
      <div className="flex justify-between">
        <div className="flex w-full items-center justify-end gap-x-2">
          <ColorPicker
            color={colorPalette}
            setColor={setColorPalette}
            colors={colors}
            isDisabled={[
              GraphType.Scatter,
              GraphType.StackedLine,
              GraphType.Table,
              GraphType.ContributionGraph,
              GraphType.Trend,
            ].includes(graphType)}
            shade={300}
            disabledTooltip="This graph type does not support colors"
          />
          <Menu matchWidth>
            <PaywallPopover
              feature="company profile insights"
              redirect="people"
              plan={Plan.Growth}
              overridePaywall={!Boolean(groupId) || isAddingNewInsight}
            >
              <MenuButton
                textAlign="left"
                as={Button}
                rightIcon={<ChevronDownIcon />}
                textTransform="capitalize"
              >
                <div className="flex items-center gap-1">
                  {GraphTypeToIcon[graphType]}
                  {graphType.split(`_`).join(` `)}
                </div>
              </MenuButton>
            </PaywallPopover>
            <MenuList w="200px">
              {Object.values(GraphType)
                .filter((g) =>
                  g === GraphType.ContributionGraph ? Boolean(groupId) : true,
                )
                .reverse()
                .map(
                  (graphType) =>
                    InsightTypeConfigs[measure].graphTypes.includes(
                      graphType,
                    ) && (
                      <MenuItem
                        textTransform="capitalize"
                        onClick={() => setGraphType(graphType)}
                      >
                        <div className="flex items-center gap-1">
                          {GraphTypeToIcon[graphType]}
                          {graphType.split(`_`).join(` `)}
                        </div>
                      </MenuItem>
                    ),
                )}
            </MenuList>
          </Menu>
        </div>
      </div>
      <Center h="full">
        <Card w={width} h={height}>
          <CardHeader w="full" pb={0}>
            <div className="flex flex-col gap-y-3">
              <div className="flex w-full items-center justify-between">
                <div className="max-w-[60%]">
                  {title && (
                    <TwEditable
                      defaultValue={title}
                      onSubmit={setTitle}
                      placeholder={"Untitled"}
                      maxW="100%"
                    />
                  )}
                </div>
                <div className="flex">
                  {InsightTypeConfigs[measure].intervals.length > 0 && (
                    <Menu matchWidth>
                      <MenuButton
                        textAlign="left"
                        size="xs"
                        as={Button}
                        variant="ghost"
                        textTransform="capitalize"
                        isDisabled={
                          typeof insightConfig?.view?.hasIntervalPicker !==
                            "undefined" &&
                          !insightConfig?.view?.hasIntervalPicker
                        }
                      >
                        <div className="flex items-center gap-x-1">
                          <CalendarDaysIcon className="h-3.5 w-3.5" />
                          <p className="text-xs">{interval}</p>
                        </div>
                      </MenuButton>
                      <MenuList>
                        {InsightTypeConfigs[measure].intervals.map(
                          (interval) => (
                            <MenuItem
                              fontSize="xs"
                              textTransform="capitalize"
                              onClick={() => setInterval(interval)}
                            >
                              {interval}
                            </MenuItem>
                          ),
                        )}
                      </MenuList>
                    </Menu>
                  )}
                  {insightConfig?.view?.hasTimerangePicker && (
                    <TimerangePicker
                      label={label}
                      options={insightConfig?.view?.dateRangeOptions || []}
                      timerangeType={timerangeType}
                      relativeTimerange={relativeTimerange}
                      customTimerange={customTimerange}
                      setCustomTimerange={setCustomTimerange}
                      saveTimerange={saveTimerange}
                      size="small"
                      tooltipText={
                        timerangeType === ABSOLUTE
                          ? readableCustomTimerange
                          : undefined
                      }
                      footerText="Insights are in UTC time"
                      customMenuButton={
                        <div className="flex items-center gap-x-1 text-black">
                          <CalendarIcon className="h-3.5 w-3.5" />
                          <p className="text-xs">{label}</p>
                        </div>
                      }
                    />
                  )}
                </div>
              </div>
              {highlight && (
                <p className="mt-1 line-clamp-1 text-3xl font-medium leading-tight">
                  {highlight?.value?.toLocaleString()}
                  {highlight?.unit}
                </p>
              )}
            </div>
          </CardHeader>
          <CardBody pl={0} pr={5}>
            {data?.length === 0 && !(isLoading || isFetching) ? (
              <div className="flex h-full w-full flex-col items-center justify-center gap-y-2 pl-5 text-gray-500">
                <ExclamationCircleIcon className="h-5 w-5" />
                <p className="text-xs">No data available</p>
              </div>
            ) : (
              <div className="flex h-full w-full justify-end">
                {isLoading || isFetching ? (
                  <div className="h-full w-full pl-5">
                    <Skeleton height="full" w="full" borderRadius="lg" />
                  </div>
                ) : (
                  Component && (
                    <Component
                      data={data}
                      {...data}
                      color={colorPalette}
                      size={size}
                      interval={interval}
                      measure={measure}
                    />
                  )
                )}
              </div>
            )}
          </CardBody>
        </Card>
        <div className="absolute bottom-24">
          <AnimatedToggle
            items={[
              {
                label: "Small",
                value: "small",
                isDisabled: !InsightTypeConfigs[measure].sizes.includes(
                  Size.Small,
                ),
              },
              {
                label: "Medium",
                value: "medium",
                isDisabled:
                  !InsightTypeConfigs[measure].sizes.includes(Size.Medium) ||
                  graphType === GraphType.Trend,
              },
              {
                label: "Large",
                value: "large",
                isDisabled:
                  !InsightTypeConfigs[measure].sizes.includes(Size.Large) ||
                  graphType === GraphType.Trend,
              },
            ]}
            value={size}
            containerProps={{
              bg: "gray.200",
            }}
            onChange={(val, e) => {
              e.preventDefault();
              setSize(val as Size);
            }}
          />
        </div>
      </Center>
    </div>
  );
};
