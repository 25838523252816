import { useMemo } from "react";
import moment from "moment";
import { cx } from "helpers/cx";
import { Size } from "core/types/ViewInsight";
import { ITrait } from "core/types/Trait";
import {
  IContributionGraphData,
  IContributionGraphUser,
} from "core/modules/reports/report-types/ActiveUsers/ContributionGraphInsight";
import { UserListSingleContact } from "core/components/Contact/UserListSingleContact";
import { Tooltip } from "@chakra-ui/react";
import { EmptyState } from "./EmptyState";

export interface IContributionGraph {
  data: IContributionGraphData;
  size?: Size;
}

const DateDot: React.FC<{
  date: string;
  eventCount: number;
  color: string;
}> = ({ date, eventCount, color }) => {
  return (
    <Tooltip
      label={`${eventCount} events on ${moment(date).format("DD MMM YYYY")}`}
      placement="top"
      hasArrow
    >
      <div className={cx("h-3 w-3 rounded-full", color ?? color)} />
    </Tooltip>
  );
};

export const ContributionGraph: React.FC<IContributionGraph> = ({
  data,
  size = Size.Small,
}) => {
  const maxEventCount = useMemo(
    () =>
      Math.max(
        ...(data?.contacts?.flatMap((c) =>
          c.intervalDatesWithEmptyIntervals.map((interval) => interval[1]),
        ) || []),
      ),
    [data],
  );

  const getColorIntensity = (eventCount: number): number => {
    if (maxEventCount === 0) return 0;
    return Math.ceil((eventCount / maxEventCount) * 5) * 100;
  };

  if (data?.contacts.length === 0) {
    return <EmptyState />;
  }

  const maxW = size === Size.Small ? "80px" : "160px";

  return (
    <div className="flex w-full flex-col pl-4 pr-5">
      {data?.contacts.map((contact: IContributionGraphUser) => (
        <div className="flex w-full items-center justify-between">
          <div className="mr-5">
            <UserListSingleContact
              id={contact.userId}
              traits={contact.traits as ITrait}
              truncateName={true}
              hideHoverBorder
              hideExternalLinkIcon
              maxW={maxW}
            />
          </div>
          <div className="flex gap-1">
            {contact?.intervalDatesWithEmptyIntervals?.map(
              (interval: [string, number], index: number) => (
                <DateDot
                  key={`${contact.userId}-${index}`}
                  date={interval[0]}
                  eventCount={interval[1]}
                  color={
                    Number(interval[1]) === 0
                      ? "bg-gray-200"
                      : `bg-green-${getColorIntensity(interval[1])}`
                  }
                />
              ),
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
