import { Flex, Box, Text } from "@chakra-ui/react";
import { ITemplateConfigSetup } from "core/types/TemplateConfig";
import { IEvent } from "core/types/Event";
import { TrackEventPill } from "core/modules/reports/setup/Pill/TrackEvent";
import PageEventPill from "core/modules/reports/setup/Pill/PageEvent";
import { FIRST, ANY, PAGE, TRACK } from "core/constants/report-setup";

interface IEventSetupPreviewProps {
  events: IEvent[];
  setup: ITemplateConfigSetup;
}

export const EventSetupPreview: React.FC<IEventSetupPreviewProps> = ({
  events,
  setup,
}) => {
  return (
    <>
      <Box data-testid="event-setup-preview" as="span" display="contents">
        {events.map((item: IEvent, index: number) => {
          return (
            <Flex display="block" key={index}>
              {(item.type === FIRST ||
                item.type === ANY ||
                item.type === TRACK) && (
                <Flex mb={-1}>
                  {index !== 0 && (
                    <Text
                      color="gray.900"
                      fontWeight="medium"
                      fontSize="xs"
                      pt={1.5}
                      px={2}
                    >
                      <Text as="span" color="gray.500">
                        -
                      </Text>{" "}
                      {setup.eventOperator}{" "}
                      <Text as="span" color="gray.500">
                        -
                      </Text>
                    </Text>
                  )}
                  <TrackEventPill showProperties showOccurrences event={item} />
                </Flex>
              )}

              {item.type === PAGE && (
                <Flex mb={-1}>
                  {index !== 0 && (
                    <Text
                      color="gray.900"
                      fontWeight="medium"
                      fontSize="xs"
                      pt={1.5}
                      px={2}
                    >
                      <Text as="span" color="gray.500">
                        -
                      </Text>{" "}
                      {setup.eventOperator}{" "}
                      <Text as="span" color="gray.500">
                        -
                      </Text>
                    </Text>
                  )}
                  <PageEventPill event={item} />
                </Flex>
              )}
            </Flex>
          );
        })}
      </Box>
    </>
  );
};
