import { createIcon } from "@chakra-ui/icons";

const CompanyJuneLogoThinkingIcon = createIcon({
  path: (
    <svg
      width="47"
      height="48"
      viewBox="0 0 47 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.6498 11.0278C37.0409 7.59714 33.7477 5.30642 30.2943 5.91133L16.6267 8.30544C14.9683 8.59593 13.494 9.52899 12.5281 10.8993L7.30455 18.3103C6.33867 19.6806 5.96032 21.376 6.25274 23.0235L8.66271 36.6011C9.27163 40.0318 12.5648 42.3225 16.0182 41.7176L29.6858 39.3235C31.3442 39.033 32.8185 38.0999 33.7844 36.7296L39.0079 29.3186C39.9738 27.9483 40.3522 26.2529 40.0598 24.6054L37.6498 11.0278ZM37.5743 25.1319C37.9396 27.1903 36.5561 29.1532 34.484 29.5161L20.7275 31.9258C18.6555 32.2887 16.6796 30.9143 16.3142 28.8559L13.8886 15.19C13.5233 13.1316 14.9068 11.1687 16.9788 10.8057L30.7353 8.39605C32.8074 8.03311 34.7833 9.40753 35.1486 11.4659L37.5743 25.1319Z"
        fill="#6868F7"
      />
      <path
        d="M19.4755 18.4626C19.2429 18.5036 19.0173 18.4475 18.7985 18.2943C18.5796 18.1411 18.4494 17.9461 18.4076 17.7094C18.3659 17.4726 18.4223 17.249 18.5771 17.0387L19.7837 15.3155C19.8794 15.1787 19.9813 15.0768 20.0894 15.0098C20.1975 14.9428 20.3197 14.8973 20.4561 14.8732C20.5925 14.8492 20.7245 14.8499 20.8521 14.8754C20.9796 14.9008 21.1118 14.9614 21.2486 15.0572L22.9718 16.2638C23.1906 16.417 23.3205 16.6099 23.3615 16.8424C23.4025 17.0749 23.3464 17.3006 23.1932 17.5194C23.0399 17.7382 22.845 17.8685 22.6082 17.9103C22.3714 17.952 22.1479 17.8955 21.9375 17.7408L20.8298 16.9651L20.0541 18.0729C19.9009 18.2917 19.708 18.4216 19.4755 18.4626Z"
        fill="#6868F7"
      />
      <path
        d="M28.7817 16.8217C28.545 16.8635 28.3214 16.807 28.111 16.6523C27.8934 16.5057 27.7635 16.3128 27.7213 16.0736C27.6791 15.8345 27.7353 15.6088 27.8896 15.3966L29.0962 13.6734C29.192 13.5367 29.295 13.4346 29.4052 13.3672C29.5153 13.2998 29.6381 13.2542 29.7733 13.2304C29.9027 13.2076 30.0322 13.2087 30.1617 13.2338C30.2912 13.2589 30.4244 13.3194 30.5611 13.4151L32.2843 14.6217C32.5032 14.7749 32.6334 14.9699 32.6752 15.2067C32.7169 15.4434 32.6605 15.667 32.5057 15.8774C32.3592 16.095 32.1663 16.2249 31.9271 16.2671C31.688 16.3093 31.4623 16.2531 31.2501 16.0988L30.1423 15.3231L29.3667 16.4309C29.2135 16.6497 29.0185 16.78 28.7817 16.8217Z"
        fill="#6868F7"
      />
      <circle
        cx="27.0602"
        cy="23.8223"
        r="2.5"
        transform="rotate(-10 27.0602 23.8223)"
        fill="#6868F7"
      />
    </svg>
  ),
  viewBox: "0 0 47 48",
});

export default CompanyJuneLogoThinkingIcon;
