import { ITrait } from "core/types/Trait";
import { ITemplateConfig } from "core/types/TemplateConfig";
import GroupList from "core/modules/reports/report-types/SlippingAwayGroups/GroupList";
import UserList from "core/modules/reports/report-types/SlippingAway/UserList";
import { SLIPPING_AWAY_GROUPED_OPTIONS } from "core/constants/timerange";
import { Template } from "core/constants/templates";
import {
  ANY,
  AUDIENCE_TYPE,
  MULTI_EVENT_TYPE,
  OR,
  PAGE,
  TRACK,
} from "core/constants/report-setup";
import { List } from "core/components/ViewInsight/Graphs/List";
import defaultTemplateConfig from "./defaultTemplateConfig";

const slippingAway: ITemplateConfig = {
  ...defaultTemplateConfig,
  route: "slipping",
  reportType: 5,
  subtitle: "Find out when users drift away from your product",
  title: "Churn risk users",
  template: Template.SlippingAway,
  aboutTemplate:
    "Get a list of the users that did not perform a specific set of actions after 7, 14 or 30 days. Re-engage with them.",
  sections: { user: [UserList], company: [GroupList] },
  hasDateRangeSection: true,
  dateRangeOptions: SLIPPING_AWAY_GROUPED_OPTIONS,
  timerangeTooltipText: "The period a user is not active for",
  insights: [
    {
      typeId: 0,
      slug: "slipping-away-users",
      title: "Churn risk {APP_OBJECT_PLURAL_NAME}",
      section: UserList,
      slackAlertAvailable: true,
      downloadable: false,
      view: {
        level: "user",
        Component: List,
        transform: (data) => {
          if (!data?.slice) return [];
          return data?.slice(0, 4)?.map((entity: any) => {
            const traits = entity?.traits as ITrait;
            return {
              id: entity?.id,
              displayName: traits?.["email"],
              avatar: traits?.["avatar"],
              description: `Last seen ${entity?.daysSinceLastEvent} days ago`,
            };
          });
        },
        hasTimerangePicker: true,
        dateRangeOptions: SLIPPING_AWAY_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 1,
      slug: "slipping-away-companies",
      title: "Churn risk {APP_OBJECT_PLURAL_NAME}",
      section: GroupList,
      slackAlertAvailable: true,
      description: {
        title: "What is a churn risk company?",
        content:
          "We consider a company as slipped away when none of the events chosen in the report setup were triggered by any of its users within the selected time range.",
      },
      downloadable: false,
      view: {
        level: "group",
        Component: List,
        transform: (data) => {
          if (!data?.slippingGroups) return [];
          return data?.slippingGroups?.slice(0, 4)?.map((entity: any) => {
            return {
              id: entity?.id,
              displayName: entity?.["name"],
              description: `Last seen ${entity?.daysSinceLastEvent} days ago`,
            };
          });
        },
        hasTimerangePicker: true,
        dateRangeOptions: SLIPPING_AWAY_GROUPED_OPTIONS,
      },
    },
  ],
  setup: {
    requiredSections: [0],
    eventOperator: OR,
    setupSections: [
      {
        id: "retention_event",
        configKey: "events",
        title: "Events",
        description:
          "Select the events you use to measure engagement in your product",
        type: MULTI_EVENT_TYPE,
        supportedEventTypes: [ANY, TRACK, PAGE],
        required: true,
        validate: (events) =>
          events &&
          events.length > 0 &&
          events.length ===
            events.map((event) => event.id).filter((x) => x).length,
      },
      {
        id: "audience",
        configKey: "audience",
        title: "Audience",
        description: "Select an audience for your report",
        type: AUDIENCE_TYPE,
        supportedUserTypes: ["user"],
      },
    ],
  },
  validateSetup: (config) =>
    config &&
    config?.events &&
    config?.events?.length > 0 &&
    config?.events?.some((e) => e.name),
};

export default slippingAway;
