import { useState } from "react";
import {
  ITemplateConfigSetup,
  ITemplateConfigSetupSection,
} from "core/types/TemplateConfig";
import { IReport } from "core/types/Report";
import { useQueryParam } from "core/hooks/useQueryParam";
import { AUDIENCE_TYPE } from "core/constants/report-setup";
import { FUNNEL, EXPLORER } from "core/constants/report-configurations";
import { Divider, Flex } from "@chakra-ui/react";
import SetupSections from "./SetupSections";
import { SetupSectionPanel } from "./SetupSectionPanel";
import { FunnelSetupSections } from "./FunnelSetupSections";
import ExamplesSection from "./ExamplesSection";

interface ISetupProps {
  setup: ITemplateConfigSetup;
  report: IReport;
}

export const Setup: React.FC<ISetupProps> = ({ setup, report }) => {
  const indexOfFirstRequiredSection = setup?.setupSections.findIndex(
    (section: ITemplateConfigSetupSection) => section.required,
  );
  const [openPanelIndex, setOpenPanelIndex] = useState(
    indexOfFirstRequiredSection,
  );
  const hasExampleSection = setup?.setupSections.some(
    (section) => section.exampleSection,
  );
  const isFunnelReport = [FUNNEL, EXPLORER].includes(report.reportType);
  const groupId = useQueryParam("groupId");

  if (hasExampleSection && window.location.href.includes(`/setup`))
    return (
      <Flex w="full" gridGap={4}>
        <Flex minW="60%">
          <SetupSections index={openPanelIndex} w="full">
            {setup?.setupSections.map(
              (section: ITemplateConfigSetupSection, index: number) => {
                return (
                  <SetupSectionPanel
                    index={index}
                    key={index}
                    section={section}
                    requiredSections={setup.requiredSections}
                    setup={setup}
                    setOpenPanelIndex={setOpenPanelIndex}
                  />
                );
              },
            )}
          </SetupSections>
        </Flex>
        {hasExampleSection && (
          <Flex p={4} w="full">
            {setup?.setupSections.map(
              (section: ITemplateConfigSetupSection, index: number) => (
                <ExamplesSection
                  exampleSection={section.exampleSection}
                  index={index}
                  openPanelIndex={openPanelIndex}
                />
              ),
            )}
          </Flex>
        )}
      </Flex>
    );

  return (
    <>
      {isFunnelReport ? (
        <>
          <SetupSections index={openPanelIndex} flex="flex">
            <FunnelSetupSections
              openPanelIndex={openPanelIndex}
              setup={setup}
              report={report}
              setOpenPanelIndex={setOpenPanelIndex}
            />
          </SetupSections>
        </>
      ) : (
        <SetupSections index={openPanelIndex} flex="flex">
          {setup?.setupSections
            .filter((s) => !s.isDynamic)
            .map((section: ITemplateConfigSetupSection, index: number) => {
              if (groupId && section.type === AUDIENCE_TYPE) return null;
              return (
                <>
                  {section.type === AUDIENCE_TYPE && <Divider opacity={1} />}
                  <SetupSectionPanel
                    key={index}
                    index={index}
                    section={section}
                    requiredSections={setup.requiredSections}
                    setup={setup}
                    setOpenPanelIndex={setOpenPanelIndex}
                  />
                </>
              );
            })
            .filter((x) => !!x)}
        </SetupSections>
      )}
    </>
  );
};
