import { Flex } from "@chakra-ui/react";
import EnumPill from "core/modules/reports/setup/Pill/Enum";
import { ITemplateConfigSetupSection } from "core/types/TemplateConfig";

interface IEnumPreviewProps {
  section: ITemplateConfigSetupSection;
  item: {
    default: boolean;
    name: string;
    type: "enum";
    value: number;
  };
}

export const EnumPreview: React.FC<IEnumPreviewProps> = ({ section, item }) => {
  return (
    <Flex display="block">
      <Flex mb={-1}>
        <EnumPill section={section} option={item} />
      </Flex>
    </Flex>
  );
};
