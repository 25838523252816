import { Size } from "core/types/ViewInsight";
import { IReportInsight } from "core/types/Report";
import { IDrilldownContact } from "core/types/Contact";
import useInsightDataFetching from "core/hooks/useInsightDataFetching";
import { ContributionGraph } from "core/components/ViewInsight/Graphs/ContributionGraph";
import InsightCard from "core/components/InsightCard/Index";
import Error from "core/components/Error";
import { contributionGraphMockData } from "./mockData";

export interface IContributionGraphUser extends IDrilldownContact {
  intervalDatesWithEmptyIntervals: [string, number][];
}

export interface IContributionGraphData {
  contacts: IContributionGraphUser[];
}

export const ContributionGraphInsight: React.FC<IReportInsight> = ({
  report,
  config,
  previewMode,
  sharingMode,
  screenshotMode,
  sharingSecretToken,
}) => {
  const insight = config.insights.find(
    ({ slug }) => slug === "contribution-graph",
  );
  const isSetupValid = config.validateSetup(report.config);

  const { response, isLoading, isFetching, error, onRefreshInsight } =
    useInsightDataFetching({
      report,
      sharingSecretToken,
      insightType: insight?.typeId,
      insightParams: {
        truncate: false,
        limit: 30,
        size: Size.Large,
      },
      previewMode,
      skip: !isSetupValid,
    });

  if (!insight) return null;

  if (error) {
    return (
      <InsightCard>
        {(Card) => (
          <Card.Container id="adoption-error">
            <Error
              position="relative"
              w="100%"
              h="200px"
              background="white"
              p={16}
              borderRadius="lg"
            />
          </Card.Container>
        )}
      </InsightCard>
    );
  }

  const data = previewMode ? contributionGraphMockData : response?.data;

  return (
    <InsightCard>
      {(Card) => (
        <Card.Container id={insight.slug} insight={insight}>
          <Card.Header
            hasCaching={response?.hasCaching}
            screenshotMode={screenshotMode}
            showReportTitle={sharingMode}
            insight={insight}
            showActions={!sharingMode && !previewMode}
            sharingMode={sharingMode}
            config={config}
            report={report}
            refresh={onRefreshInsight}
            cachedAt={response?.cachedAt}
          />
          <Card.Body
            isLoading={!data || isLoading || isFetching}
            isPreviewMode={previewMode}
            isSetupValid={isSetupValid}
            error={error}
          >
            <div className="flex px-1 py-4">
              <ContributionGraph data={data} size={Size.Large} />
            </div>
          </Card.Body>
        </Card.Container>
      )}
    </InsightCard>
  );
};
