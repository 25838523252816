import React, { useState } from "react";
import moment from "moment";
import { pluralize } from "helpers/pluralize";
import { cx } from "helpers/cx";
import { Size } from "core/types/ViewInsight";
import { thousandsToK } from "core/helpers/thousands";
import { IColorPalette } from "core/design-system/constants/theme/colors";
import { Ping } from "core/design-system/components/Ping";
import {
  ADOPTION_PERCENTAGE,
  ASC,
  DESC,
  USAGE_COUNT,
  USER_COUNT,
} from "core/constants/featureList";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { Progress, Tooltip } from "@chakra-ui/react";

interface IFeature {
  size: Size;
  width: string;
  name: string;
  index: number;
  eligibleAudienceCount: number;
  userCount: number;
  adoption: number;
  eventCount: number;
  medianEventCount: number;
  firstUsedTimestamp: string;
  lastUsedTimestamp: string;
}

interface IFeatureTable {
  data: IFeature[];
  color?: IColorPalette;
  size?: Size;
  truncate?: boolean;
}

export const COLUMN_NAME = {
  [ADOPTION_PERCENTAGE]: "adoption",
  [USER_COUNT]: "userCount",
  [USAGE_COUNT]: "eventCount",
};

const getTooltipText = ({
  isBeingUsed,
  adoptionValue,
  lastUsedTimestamp,
  featureName,
}: {
  isBeingUsed: boolean;
  adoptionValue: number;
  lastUsedTimestamp: string;
  featureName: string;
}) => {
  if (!adoptionValue) return "This feature has not been used yet.";
  if (isBeingUsed) return "This feature is currently being used.";
  return `Last time ${featureName} was used was ${moment.utc(lastUsedTimestamp).fromNow()}.`;
};

const Feature: React.FC<IFeature> = ({
  size,
  name,
  index,
  eligibleAudienceCount,
  userCount,
  adoption,
  eventCount,
  medianEventCount,
  firstUsedTimestamp,
  lastUsedTimestamp,
  width,
}) => {
  const isBeingUsed = moment().diff(moment(lastUsedTimestamp), "hours") <= 1;

  return (
    <tr>
      <td className={cx("py-1.5 pr-0")}>
        <div className="flex items-center">
          <Tooltip
            label={getTooltipText({
              isBeingUsed,
              adoptionValue: adoption,
              lastUsedTimestamp: lastUsedTimestamp,
              featureName: name,
            })}
            hasArrow
            placement="top"
            shouldWrapChildren
          >
            {isBeingUsed ? (
              <Ping mr={2} h="2.5" w="2.5" />
            ) : (
              <Ping mr={2} h="2.5" w="2.5" animate={false} color={"gray.300"} />
            )}
          </Tooltip>
          <Tooltip label={name} hasArrow placement="top" shouldWrapChildren>
            <p className={cx(`truncate text-sm`, width ?? width)}>{name}</p>
          </Tooltip>
        </div>
      </td>
      {size !== Size.Small && (
        <td className={cx("py-1.5 pr-10")}>
          <div className="flex w-full flex-row items-center">
            <Progress
              maxW="50px"
              minW="50px"
              colorScheme={"purple"}
              value={adoption}
              size="sm"
              borderRadius="lg"
              w="60%"
              mr={2}
            />
            <p className="counter text-sm">{adoption}%</p>
          </div>
        </td>
      )}
      <td className={cx("py-1.5 pr-10")}>
        <div className="flex flex-row items-center justify-between">
          <p className="counter truncate text-sm">
            {`${userCount.toLocaleString()} ${
              size !== Size.Small ? pluralize(userCount, "user", "users") : ""
            }`}
          </p>
        </div>
      </td>
      <td className={cx("py-1.5 pr-10")}>
        <div className="flex flex-row items-center justify-between">
          <p className="counter truncate text-sm">
            {`${thousandsToK(eventCount).toLocaleString()} ${size !== Size.Small ? pluralize(eventCount, "time", "times") : ""}`}
          </p>
        </div>
      </td>
    </tr>
  );
};

export const FeatureTable: React.FC<IFeatureTable> = ({
  data,
  color,
  size = Size.Small,
  truncate = true,
}) => {
  const [column, setColumn] = useState(ADOPTION_PERCENTAGE);
  const [order, setOrder] = useState(DESC);

  function onSort(newColumn?: string) {
    const newOrder = order === DESC ? ASC : DESC;
    if (newColumn) setColumn(newColumn);
    if (newOrder) setOrder(newOrder);
  }

  const sortedData = [...data]
    .sort((a, b) => {
      const columnName = COLUMN_NAME[column as keyof typeof COLUMN_NAME];
      if (order === DESC) {
        return (
          (b[columnName as keyof IFeature] as number) -
          (a[columnName as keyof IFeature] as number)
        );
      } else {
        return (
          (a[columnName as keyof IFeature] as number) -
          (b[columnName as keyof IFeature] as number)
        );
      }
    })
    .slice(0, truncate ? 4 : data.length);

  const width = size === Size.Small ? "max-w-[120px]" : "max-w-[220px]";

  return (
    <div className="flex w-full flex-col overflow-x-auto pl-6">
      <table>
        <thead className="border-b-[10px] border-transparent">
          <tr className="w-full">
            <th
              className={cx(
                "text-left text-sm font-normal text-gray-600",
                width,
              )}
            >
              <p className="text-sm">Feature</p>
            </th>
            {size !== Size.Small && (
              <th
                className={cx(
                  "cursor-pointer text-left text-sm font-normal text-gray-600",
                  width,
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  onSort(ADOPTION_PERCENTAGE);
                }}
              >
                <div className="flex items-center gap-1">
                  <p
                    className={cx(
                      "text-sm",
                      column === ADOPTION_PERCENTAGE && "text-purple-500",
                    )}
                  >
                    Adoption
                  </p>
                  {order === DESC ? (
                    <ChevronDownIcon className="h-3 w-3" />
                  ) : (
                    <ChevronUpIcon className="h-3 w-3" />
                  )}
                </div>
              </th>
            )}
            <th
              className={cx(
                "cursor-pointer text-left text-sm font-normal text-gray-600",
                width,
              )}
              onClick={(e) => {
                e.stopPropagation();
                onSort(USER_COUNT);
              }}
            >
              <div className="flex items-center gap-1">
                <p
                  className={cx(
                    "text-sm",
                    column === USER_COUNT && "text-purple-500",
                  )}
                >
                  Users
                </p>
                {order === DESC ? (
                  <ChevronDownIcon className="h-3 w-3" />
                ) : (
                  <ChevronUpIcon className="h-3 w-3" />
                )}
              </div>
            </th>
            <th
              className={cx(
                "cursor-pointer text-left text-sm font-normal text-gray-600",
                width,
              )}
              onClick={(e) => {
                e.stopPropagation();
                onSort(USAGE_COUNT);
              }}
            >
              <div className="flex items-center gap-1">
                <p
                  className={cx(
                    "text-sm",
                    column === USAGE_COUNT && "text-purple-500",
                  )}
                >
                  Usage
                </p>
                {order === DESC ? (
                  <ChevronDownIcon className="h-3 w-3" />
                ) : (
                  <ChevronUpIcon className="h-3 w-3" />
                )}
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {sortedData?.map((feature, index) => (
            <Feature key={index} {...feature} size={size} width={width} />
          ))}
        </tbody>
      </table>
    </div>
  );
};
