import { Link, useParams } from "react-router-dom";
import React from "react";
import { motion } from "framer-motion";
import { IInsightSection } from "core/types/TemplateConfig";
import { IReportInsight } from "core/types/Report";
import { IGroup } from "core/types/Group";
import { IContact } from "core/types/Contact";
import { isUserReportLevel } from "core/modules/reports/utils";
import { AppObjectType } from "core/models/appObjects";
import { useInsightTitle } from "core/hooks/useInsightTitle";
import useInsightDataFetching from "core/hooks/useInsightDataFetching";
import { useAppObjects } from "core/hooks/useAppObjects";
import {
  getContactEmail,
  IGetContactEmailProps,
} from "core/helpers/contactEmail";
import { getCompanyName } from "core/helpers/companyName";
import { Ping } from "core/design-system/components/Ping";
import InsightCard from "core/components/InsightCard/Index";
import ContactAvatar from "core/components/Avatars/ContactAvatar";
import CompanyAvatar from "core/components/Avatars/CompanyAvatar";
import {
  ComponentDefaultProps,
  Flex,
  SkeletonCircle,
  Text,
  Tooltip,
} from "@chakra-ui/react";

export interface ILiveUsersData {
  users?: IContact[] | IGroup[];
  totalCount: number;
}

const mockData = {
  totalCount: 5,
  users: [
    {
      userId: "Josephine Edamon",
      groupId: "Josephine Edamon",
      isIdentified: true,
      traits: { plan: "Pro " },
    },
    {
      userId: "Roy Carpent",
      groupId: "Roy Carpent",
      isIdentified: true,
      traits: { plan: "Pro " },
    },
    {
      userId: "Anita James",
      groupId: "Anita James",
      isIdentified: true,
      traits: { plan: "Pro " },
    },
    {
      userId: "Alice Terpantine",
      groupId: "Alice Terpantine",
      isIdentified: true,
      traits: { plan: "Pro " },
    },
    {
      userId: "Gus Edams",
      groupId: "Gus Edams",
      isIdentified: true,
      traits: { plan: "Pro " },
    },
  ],
};

interface ILiveAvatar extends ComponentDefaultProps {
  name?: string;
  to: string;
}

const LiveAvatar: React.FC<ILiveAvatar> = ({ name, to, children }) => {
  return (
    <motion.div whileHover={{ scale: 1.2 }}>
      <Link to={to}>
        <Tooltip
          label={
            <Flex>
              <Text>{name}</Text>
            </Flex>
          }
          shouldWrapChildren
        >
          <Flex>{children}</Flex>
        </Tooltip>
      </Link>
    </motion.div>
  );
};

const LiveUser: React.FC<{ contact: IContact }> = ({ contact }) => {
  const { appId } = useParams<{ appId: string }>();
  return (
    <LiveAvatar
      to={`/a/${appId}/contact/${contact.userId}`}
      name={getContactEmail(contact as IGetContactEmailProps)}
    >
      <ContactAvatar
        size="sm"
        contact={{ ...contact, id: contact.userId || "" }}
      />
    </LiveAvatar>
  );
};

const LiveCompany: React.FC<{ company: IGroup }> = ({ company }) => {
  const { appId } = useParams<{ appId: string }>();
  const { activeAppObject } = useAppObjects();

  const traits = Array.isArray(company?.traits)
    ? company?.traits?.reduce(
        (_traits, trait) => ({ ..._traits, ...trait }),
        {},
      )
    : company?.traits;

  const routeType =
    activeAppObject?.objectType === AppObjectType.Company ? "company" : "group";

  const name =
    activeAppObject?.objectType === AppObjectType.Company
      ? company.groupId
      : getCompanyName({ traits }) ?? company.groupId;

  return (
    <LiveAvatar to={`/a/${appId}/${routeType}/${company.groupId}`} name={name}>
      <CompanyAvatar group={{ id: company.groupId }} size="sm" />
    </LiveAvatar>
  );
};

export const LiveActivityContainer: React.FC<IReportInsight> = ({
  previewMode,
  sharingMode,
  sharingSecretToken,
  report,
  config,
  reportLevel,
}) => {
  const isUserReport = isUserReportLevel(reportLevel);
  const unit = isUserReport ? "users" : "companies";
  const insight = config.insights.find(
    ({ slug }: { slug: string }) => slug === `feature-live-${unit}`,
  ) as IInsightSection;
  const isSetupValid = config.validateSetup(report.config);
  const insightTitle = useInsightTitle({ title: insight.title });

  const { response, isLoading, error } = useInsightDataFetching({
    report,
    insight,
    insightParams: { truncate: true },
    sharingSecretToken,
    previewMode,
    skip: sharingMode || !isSetupValid,
  });

  const data = previewMode ? mockData : (response?.data as ILiveUsersData);

  const noData = data?.users?.length === 0;

  if (sharingMode || error) {
    return null;
  }

  return (
    <InsightCard>
      {(Card) => (
        <Card.Container insight={insight} id={insight.slug}>
          <Flex px={6} my={4}>
            <Flex
              w="full"
              align="center"
              gridGap={2}
              justifyContent="space-between"
            >
              <Flex align="center" gridGap={2}>
                <Flex>
                  <Ping
                    color={noData || isLoading ? "gray.300" : "green.300"}
                  />
                </Flex>
                <Flex>
                  <Text fontSize="sm" fontWeight="semibold" color="gray.600">
                    {insightTitle}
                  </Text>
                </Flex>
              </Flex>
              <Flex align="center" gridGap={3}>
                <Flex gridGap={2}>
                  {isLoading && (
                    <Flex gridGap={3}>
                      <SkeletonCircle size="7" />
                      <SkeletonCircle size="7" />
                      <SkeletonCircle size="7" />
                      <SkeletonCircle size="7" />
                      <SkeletonCircle size="7" />
                    </Flex>
                  )}
                  {!isSetupValid ? (
                    <Flex>
                      <Text fontSize="xs" color="gray.600" py={1}>
                        Select an event to get started
                      </Text>
                    </Flex>
                  ) : (
                    <>
                      {noData && (
                        <Flex>
                          <Text fontSize="xs" color="gray.600" py={1}>
                            No one is using your feature right now
                          </Text>
                        </Flex>
                      )}
                      {isUserReport
                        ? // @ts-ignore
                          data?.users?.map((user: IContact) => (
                            <LiveUser contact={user} />
                          ))
                        : // @ts-ignore
                          data?.users?.map((user: IGroup) => (
                            <LiveCompany company={user} />
                          ))}
                    </>
                  )}
                </Flex>
                {data?.totalCount > 5 && (
                  <Text color="gray.600" fontSize="sm">
                    + {data?.totalCount - 5}
                  </Text>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Card.Container>
      )}
    </InsightCard>
  );
};
