import { useEffect } from "react";
import {
  useDeleteCompanyMutation,
  useLazyGetCompanyProfileQuery,
  useMergeCompanyMutation,
} from "core/models/companies";

export interface IUseCompanyProps {
  appId: number;
  id: string;
}

export const useCompany = ({ appId, id }: IUseCompanyProps) => {
  const [
    getCompanyProfile,
    {
      data: company,
      isLoading: isCompanyLoading,
      isFetching: isCompanyFetching,
      error: companyError,
    },
  ] = useLazyGetCompanyProfileQuery();

  useEffect(() => {
    getCompanyProfile({ appId, id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId, id]);

  const [deleteCompany] = useDeleteCompanyMutation();
  const [mergeCompany] = useMergeCompanyMutation();

  return {
    company,
    isCompanyLoading,
    isCompanyFetching,
    companyError,
    deleteCompany,
    mergeCompany,
    getCompanyProfile,
  };
};
