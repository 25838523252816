import {
  ComponentDefaultProps,
  DrawerBody,
  Spinner,
  Stack,
} from "@chakra-ui/react";

interface IDrilldownBody extends ComponentDefaultProps {
  isLoading?: boolean;
}

export const DrilldownBody: React.FC<IDrilldownBody> = ({
  isLoading,
  children,
  ...styleProps
}) => {
  if (isLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <DrawerBody
      bg="white"
      w="full"
      h="100%"
      borderRadius="lg"
      px={0}
      pt={10}
      pb={0}
      {...styleProps}
    >
      <Stack id="scrollableBox" h="100%" style={{ overflow: "auto" }}>
        {children}
      </Stack>
    </DrawerBody>
  );
};
