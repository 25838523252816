import { useClearbitLogo } from "core/hooks/useClearbitLogo";
import CompanyAvatar from "core/components/Avatars/CompanyAvatar";

import { Flex } from "@chakra-ui/react";

export const ReferrerDomain: React.FC<{ domain: string }> = ({ domain }) => {
  const logoURL = useClearbitLogo({ domain, size: 100 });

  return (
    <Flex w="70%" alignItems="center">
      <CompanyAvatar
        mr={4}
        h={10}
        w={10}
        size="sm"
        border="none"
        logoURL={logoURL}
      />
      {domain}
    </Flex>
  );
};
