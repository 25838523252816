import useRequest from "core/hooks/useRequest";
import { useParams } from "react-router-dom";

export const useSegment = () => {
  const { appId } = useParams();
  const { data, isLoading } = useRequest({
    url: `${import.meta.env.VITE_API_HOST}/segment_tokens`,
    params: { appId },
    withCredentials: true,
    mode: "cors",
  });

  const hasConnectedSegment = data && data.length > 0;

  return {
    data,
    isLoading,
    hasConnectedSegment,
  };
};
