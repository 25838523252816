import { useParams } from "react-router-dom";
import { useState } from "react";
import { Unit } from "core/types/Unit";
import { IInsightSection } from "core/types/TemplateConfig";
import { IReportInsight } from "core/types/Report";
import { isUserReportLevel } from "core/modules/reports/utils";
import { OverviewUsers } from "core/modules/reports/report-types/UserMilestones/OverviewUsers";
import { OverviewGroups } from "core/modules/reports/report-types/GroupMilestones/OverviewGroups";
import { overviewMockData } from "core/modules/reports/report-types/GroupMilestones/mockData/overview";
import { useQueryParam } from "core/hooks/useQueryParam";
import useInsightDataFetching from "core/hooks/useInsightDataFetching";
import { useGroup } from "core/hooks/useGroup";
import {
  IHandleBarClickProps,
  VerticalConversionChart,
} from "core/design-system/charts/VerticalConversionChart";
import InsightCard from "core/components/InsightCard/Index";
import { Box, Flex, Text, useDisclosure } from "@chakra-ui/react";

interface IMilestone {
  name: string;
  percentage: number;
  convertedCount: number;
}

const OverviewContainer: React.FC<IReportInsight> = ({
  report,
  sharingSecretToken = null,
  previewMode,
  sharingMode,
  config,
  screenshotMode,
  reportLevel,
  showFooter = true,
  showActions = true,
  showReportTitle = false,
  colSpan = 2,
}) => {
  const isUserReport = isUserReportLevel(reportLevel);
  const insight = config.insights.find(
    ({ slug }) =>
      slug === `milestones-overview-${isUserReport ? "users" : "companies"}`,
  ) as IInsightSection;
  const isSetupValid = config.validateSetup(report.config);
  const { groupId: encodedGroupId } = useParams<{
    appId: string;
    groupId: string;
  }>() as {
    appId: string;
    groupId: string;
  };
  const groupIdQueryParam = useQueryParam("groupId");
  const groupId = groupIdQueryParam
    ? groupIdQueryParam
    : decodeURIComponent(encodedGroupId);
  const { group } = useGroup({ id: groupId });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [milestone, setMilestone] = useState<string | undefined>("");
  const [milestoneName, setMilestoneName] = useState<string | undefined>("");
  const [milestoneConvertedCount, setMilestoneConvertedCount] =
    useState<number>(0);
  const [milestoneDroppedCount, setMilestoneDroppedCount] = useState<number>(0);
  const [showCompleted, setShowCompleted] = useState<boolean>(true);
  const {
    response: data,
    isFetching,
    error,
    onRefreshInsight,
  } = useInsightDataFetching({
    report,
    insight,
    sharingSecretToken,
    previewMode,
    skip: !isSetupValid,
  });

  const graphData = previewMode
    ? overviewMockData.milestones
    : data?.data?.milestones || [];
  const formattedData = graphData.map(
    (milestone: IMilestone, index: number) => ({
      index,
      humanizedName: milestone.name,
      conversionPercentage: milestone.percentage,
      convertedCount: milestone.convertedCount,
    }),
  );
  const handleBarClick = ({
    humanizedName,
    showConverted,
  }: IHandleBarClickProps) => {
    onOpen();

    const milestoneKey = Object.keys(report.config.name).find(
      (key) => report.config.name[key] === humanizedName,
    );
    setMilestone(milestoneKey);
    setMilestoneName(humanizedName);

    const milestone = graphData.find(
      (milestone: IMilestone) => milestone.name === humanizedName,
    );
    setMilestoneConvertedCount(milestone.convertedCount);
    setMilestoneDroppedCount(milestone.droppedCount);
    setShowCompleted(showConverted);
  };

  return (
    <InsightCard>
      {(Card) => (
        <Card.Container insight={insight} id={insight.slug} colSpan={colSpan}>
          <Card.Header
            hasCaching={data?.hasCaching}
            screenshotMode={screenshotMode}
            showReportTitle={sharingMode || showReportTitle}
            insight={insight}
            showActions={!sharingMode && !previewMode && showActions}
            sharingMode={sharingMode}
            config={config}
            report={report}
            refresh={onRefreshInsight}
            cachedAt={data?.cachedAt}
          ></Card.Header>
          <Card.Body
            insight={insight}
            isLoading={!graphData || isFetching}
            isPreviewMode={previewMode}
            isSetupValid={isSetupValid}
            error={error}
          >
            <Flex direction="column" id="loaded">
              <Box py={1} bg="purple.100" w="full">
                <Text fontSize="sm" color="purple.600" textAlign="center">
                  <Text as="span" fontWeight="bold">
                    {data?.data?.overallCompletionPercentage}%
                  </Text>{" "}
                  completed all milestones
                </Text>
              </Box>
              <VerticalConversionChart
                barData={formattedData}
                unit={isUserReport ? Unit.Users : Unit.Companies}
                handleBarClick={handleBarClick}
                droppedLabel={""}
              />
            </Flex>
            {!sharingMode &&
              !previewMode &&
              isOpen &&
              (isUserReport ? (
                <OverviewUsers
                  report={report}
                  key={report.serializeKey}
                  isOpen={isOpen}
                  onClose={onClose}
                  milestone={milestone}
                  milestoneName={milestoneName}
                  milestoneConvertedCount={milestoneConvertedCount}
                  milestoneDroppedCount={milestoneDroppedCount}
                  startDate={group?.firstSeenAt}
                  showCompleted={showCompleted}
                  setShowCompleted={setShowCompleted}
                />
              ) : (
                <OverviewGroups
                  report={report}
                  key={report.serializeKey}
                  config={config}
                  isOpen={isOpen}
                  onClose={onClose}
                  milestone={milestone}
                  milestoneName={milestoneName}
                  milestoneConvertedCount={milestoneConvertedCount}
                  milestoneDroppedCount={milestoneDroppedCount}
                  showCompleted={showCompleted}
                  setShowCompleted={setShowCompleted}
                />
              ))}
          </Card.Body>
          {insight.description && !screenshotMode && showFooter && (
            <Card.Footer bg="white">
              <Card.DescriptionAccordion insight={insight} />
            </Card.Footer>
          )}
        </Card.Container>
      )}
    </InsightCard>
  );
};

export default OverviewContainer;
