import React, { useEffect, useRef, useState } from "react";
import { IAppObject } from "core/models/appObjects";
import { useArchiveTraitMutation, useGetTraitsQuery } from "core/models/traits";
import { APP_OBJECT_TO_FILTER_TYPE } from "core/constants/filters";
import {
  ExclamationTriangleIcon,
  BoltIcon as SolidBoltIcon,
} from "@heroicons/react/24/solid";
import { useDataMappings } from "core/hooks/useDataMappings";
import { Integration } from "core/models/dataMappings";
import HubspotIcon from "core/design-system/components/Icon/Interface/HubspotIcon";
import AttioIcon from "core/design-system/components/Icon/Interface/AttioIcon";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Skeleton,
  Tooltip,
} from "@chakra-ui/react";
import { CheckIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import {
  APP_OBJECT_TO_TRAIT_LEVEL,
  READ_ONLY_TRAITS,
} from "core/constants/traits";
import { Button } from "@headlessui/react";

interface ITraits {
  appObject: IAppObject;
  limit?: number;
  isActive?: boolean;
}

const AppObjectTraits: React.FC<ITraits> = ({
  appObject,
  limit,
  isActive = true,
}) => {
  const appId = appObject.appId;
  const filterType = APP_OBJECT_TO_FILTER_TYPE[appObject.objectType];
  const traitLevel = APP_OBJECT_TO_TRAIT_LEVEL[appObject.objectType];
  const [archiveTrait] = useArchiveTraitMutation();
  const [showArchiveTraitModal, setShowArchiveTraitModal] = useState(false);
  const [archivingTrait, setArchivingTrait] = useState<string | null>(null);
  const cancelRef = useRef<HTMLButtonElement>(null);

  const { dataMappings: hubspotDataMappings } = useDataMappings({
    appId,
    appObject,
    integration: Integration.Hubspot,
  });
  const { dataMappings: attioDataMappings } = useDataMappings({
    appId,
    appObject,
    integration: Integration.Attio,
  });
  const {
    data: traits,
    isLoading,
    refetch,
  } = useGetTraitsQuery({
    appId,
    filterType,
    limit,
    isActive,
  });

  useEffect(() => {
    refetch();
  }, [traits]);

  function isSyncedWithHubspot(trait: string | null) {
    if (!trait) return false;
    return hubspotDataMappings.map((mapping) => mapping.source).includes(trait);
  }

  function isSyncedWithAttio(trait: string | null) {
    if (!trait) return false;
    return attioDataMappings.map((mapping) => mapping.source).includes(trait);
  }

  function isReadOnly(trait: string) {
    return READ_ONLY_TRAITS.includes(trait);
  }

  function handleCancelArchiveTrait() {
    setShowArchiveTraitModal(false);
    setArchivingTrait(null);
  }

  async function handleArchiveTrait(trait: string | null) {
    if (!trait) return;

    try {
      await archiveTrait({
        appId,
        trait,
        level: traitLevel,
      }).unwrap();
      setShowArchiveTraitModal(false);
      setArchivingTrait(null);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
            >
              Name
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
            >
              <div className="flex items-center gap-1">
                Type
                <Tooltip
                  label="Computed traits are computed based on events. Non-computed traits mostly have static values."
                  placement="top"
                  textAlign="center"
                  hasArrow
                >
                  <QuestionOutlineIcon w={3} h={3} color="gray" />
                </Tooltip>
              </div>
            </th>
            {isActive && (
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
              >
                <div className="flex items-center gap-1">
                  Used in audience
                  <Tooltip
                    label="This trait is being used in a saved audience or a report"
                    placement="top"
                    textAlign="center"
                    hasArrow
                  >
                    <QuestionOutlineIcon w={3} h={3} color="gray" />
                  </Tooltip>
                </div>
              </th>
            )}
            {isActive && (
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
              >
                <div className="flex items-center gap-1">
                  CRM Sync
                  <Tooltip
                    label="This trait is synced with a CRM"
                    placement="top"
                    textAlign="center"
                    hasArrow
                  >
                    <QuestionOutlineIcon w={3} h={3} color="gray" />
                  </Tooltip>
                </div>
              </th>
            )}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {isLoading && (
            <tr>
              {[...Array(3)].map((_, index) => (
                <td key={index} className="py-4">
                  <Skeleton h="16px" w="120px" borderRadius="xl" />
                </td>
              ))}
            </tr>
          )}

          {traits?.length === 0 && (
            <tr>
              <td colSpan={4} className="py-4 text-sm text-gray-600">
                No traits found
              </td>
            </tr>
          )}

          {traits?.map((trait) => (
            <tr key={trait.trait}>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                <div className="flex items-baseline gap-2">
                  <span>{trait.trait}</span>
                  {isReadOnly(trait.trait) ? (
                    <span className="rounded-md border bg-gray-50 px-1 text-xs text-gray-600">
                      Read only
                    </span>
                  ) : null}
                </div>
              </td>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                {trait.isComputed ? (
                  <div className="flex items-center gap-1">
                    Computed
                    <SolidBoltIcon className="h-3 w-3 text-purple-500" />
                  </div>
                ) : (
                  <div>Non-computed</div>
                )}
              </td>
              {isActive && (
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                  {trait.partOfAudience && (
                    <CheckIcon h={4} color="green.400" />
                  )}
                </td>
              )}
              {isActive && (
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                  <div className="flex items-center">
                    {isSyncedWithHubspot(trait.trait) && (
                      <Link
                        to={`/a/${appId}/settings/integrations/hubspot/${appObject.objectType}`}
                        className="rounded-md px-2 py-1 hover:bg-gray-100"
                      >
                        <HubspotIcon w={4} h={4} color="#ff7a59" />
                      </Link>
                    )}
                    {isSyncedWithAttio(trait.trait) && (
                      <Link
                        to={`/a/${appId}/settings/integrations/attio/${appObject.objectType}`}
                        className="rounded-md px-2 py-1 hover:bg-gray-100"
                      >
                        <AttioIcon w={4} h={4} />
                      </Link>
                    )}
                  </div>
                </td>
              )}
              {isActive && (
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                  <Button
                    key={`${trait.trait}-archive`}
                    className="rounded-md px-2 py-1 text-purple-500 disabled:opacity-50 data-[hover]:bg-gray-100"
                    onClick={() => {
                      if (isReadOnly(trait.trait)) return;
                      setArchivingTrait(trait.trait);
                      setShowArchiveTraitModal(true);
                    }}
                    disabled={
                      isReadOnly(trait.trait) || archivingTrait === trait.trait
                    }
                  >
                    Delete
                  </Button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      {showArchiveTraitModal && (
        <AlertDialog
          leastDestructiveRef={cancelRef}
          isOpen={showArchiveTraitModal}
          onClose={() => handleCancelArchiveTrait()}
        >
          <AlertDialogOverlay />
          <AlertDialogContent>
            <AlertDialogHeader>Delete trait</AlertDialogHeader>
            <AlertDialogBody className="text-sm">
              <p>
                Are you sure you want to delete{" "}
                <span className="font-medium">{archivingTrait}</span>?
              </p>
              <br />
              <p>
                Deleting a trait will permanently remove it for all{" "}
                {appObject.pluralName} in June. Make sure to stop tracking it
                through your SDK.
              </p>
              <br />
              {(isSyncedWithHubspot(archivingTrait) ||
                isSyncedWithAttio(archivingTrait)) && (
                <p className="flex gap-2 rounded-lg bg-orange-100 p-2 text-sm">
                  <ExclamationTriangleIcon className="h-8 w-8 text-yellow-600" />
                  <div>
                    This trait is being synced with{" "}
                    <span className="font-medium">
                      {isSyncedWithHubspot(archivingTrait) && "Hubspot"}
                      {isSyncedWithHubspot(archivingTrait) &&
                        isSyncedWithAttio(archivingTrait) &&
                        " and "}
                      {isSyncedWithAttio(archivingTrait) && "Attio"}
                    </span>
                    . Deleting it will stop syncing it with your CRM.
                  </div>
                </p>
              )}
            </AlertDialogBody>
            <AlertDialogFooter className="flex justify-end gap-2">
              <Button
                className="rounded-md border px-2 py-1 text-sm font-medium disabled:opacity-50 data-[hover]:bg-gray-100"
                onClick={() => handleCancelArchiveTrait()}
              >
                Cancel
              </Button>
              <Button
                className="rounded-md bg-red-500 px-2 py-1 text-sm font-medium text-white disabled:opacity-50 data-[hover]:bg-red-600"
                onClick={() => handleArchiveTrait(archivingTrait)}
              >
                Delete trait
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </div>
  );
};

export default AppObjectTraits;
