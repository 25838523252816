import { Navigate, useParams } from "react-router-dom";
import { ChooseSetup as ChooseSetupPage } from "modules/Onboarding/ChooseSetup";
import { DEMO_APP_ID } from "core/constants/appIds";

export const ChooseSetup: React.FC = () => {
  const { appId } = useParams<{ appId?: string }>();

  if (Number(appId) === DEMO_APP_ID) {
    return <Navigate to={`/a/${appId}/home`} />;
  }

  return <ChooseSetupPage />;
};
