import { OnboardingCheck } from "modules/Onboarding/SetupSources/OnboardingCheck";
import { StepLink } from "modules/Developers/Overview/StepLink";
import { OnboardingStep } from "core/hooks/useDocs";
import { ComponentDefaultProps, Spinner, Tooltip } from "@chakra-ui/react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

interface SetupItemProps extends ComponentDefaultProps {
  isLoading: boolean;
  itemKey?: OnboardingStep;
  title: string;
  isComplete?: boolean;
  isOptional?: boolean;
  ItemAction?: React.FC;
  tooltip?: string;
}

export const SetupItem = ({
  isLoading,
  isComplete = false,
  title,
  itemKey,
  isOptional,
  ItemAction,
  tooltip,
  ...props
}: SetupItemProps) => (
  <div
    className={`flex items-center justify-between border-b border-gray-200 p-3 px-0 last:border-0 ${props.className}`}
  >
    <div className="flex items-center">
      {isLoading ? (
        <Spinner
          speed="2s"
          size="sm"
          color="purple.500"
          emptyColor="gray.300"
        />
      ) : (
        <OnboardingCheck isComplete={isComplete} />
      )}
      <p className="text-sm font-medium">{title}</p>
      {tooltip && (
        <Tooltip hasArrow label={tooltip}>
          <InformationCircleIcon className="ml-1 h-4 text-gray-500"></InformationCircleIcon>
        </Tooltip>
      )}
      {isOptional && (
        <span className="ml-2 inline-flex items-center rounded-full bg-gray-100 px-2 py-[2px] text-xs font-medium text-gray-600">
          Optional
        </span>
      )}
    </div>
    {itemKey && <StepLink step={itemKey} />}
    {ItemAction && (
      <div>
        <ItemAction />
      </div>
    )}
  </div>
);
