import Notification from "core/components/Notification";
import { createStandaloneToast } from "@chakra-ui/react";

export const renderToast = ({
  action,
  status,
  resource = "report",
}: {
  action: "delete" | "duplicate" | "save";
  status: "error" | "success";
  resource?: "report" | "audience";
}) => {
  const { toast } = createStandaloneToast();
  const message = `${status === "success" ? "Successfully" : "Failed"} ${
    status === "success" ? `${action}d` : `to ${action}`
  } ${resource}`;

  return toast({
    position: "top",
    render: () => <Notification title={message} status={status} />,
    duration: 2000,
    isClosable: true,
  });
};
