import {
  IContact,
  IContactActivity,
  IContactCompanies,
  IContactEvents,
  IContactGroups,
  IContactPages,
  IContactsState,
} from "core/types/Contact";
import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

const initialState: IContactsState = {
  fetch: {
    loading: {},
    error: {},
  },
  fetchContactGroups: {
    loading: {},
    error: {},
  },
  fetchContactCompanies: {
    loading: {},
    error: {},
  },
  fetchContactActivity: {
    loading: {},
    error: {},
  },
  fetchContactEvents: {
    loading: {},
    error: {},
  },
  fetchContactPages: {
    loading: {},
    error: {},
  },
  contactActivity: {
    data: {},
  },
  contactEvents: {
    data: {},
  },
  contactPages: {
    data: {},
  },
  contactGroups: {
    data: {},
  },
  contactCompanies: {
    data: {},
  },
  deleteContact: {
    loading: {},
    error: {},
  },
};

export const contactsAdapter = createEntityAdapter<IContact>({
  selectId: (entity) => entity.id,
});

export const slice = createSlice({
  name: "contacts",
  initialState: contactsAdapter.getInitialState(initialState),
  reducers: {
    fetchingOne: (state, action: PayloadAction<{ id: string }>) => {
      const id = action.payload.id;
      state.fetch.loading[id] = true;
    },
    deleteOne: (state, action: PayloadAction<{ id: string }>) => {
      const id = action.payload.id;
      state.deleteContact.loading[id] = false;
      state.deleteContact.error[id] = undefined;
      contactsAdapter.removeOne(state, id);
    },
    deletingOne: (state, action: PayloadAction<{ id: string }>) => {
      const id = action.payload.id;
      state.deleteContact.loading[id] = true;
    },
    deleteOneFailed: (
      state,
      action: PayloadAction<{ id: string; error: string }>,
    ) => {
      const id = action.payload.id;
      const error = action.payload.error;
      state.deleteContact.loading[id] = false;
      state.deleteContact.error[id] = error;
    },
    fetchingContactGroups: (state, action: PayloadAction<{ id: string }>) => {
      const id = action.payload.id;
      state.fetchContactGroups.loading[id] = true;
    },
    fetchingContactCompanies: (
      state,
      action: PayloadAction<{ id: string }>,
    ) => {
      const id = action.payload.id;
      state.fetchContactCompanies.loading[id] = true;
    },
    fetchingContactActivity: (state, action: PayloadAction<{ id: string }>) => {
      const id = action.payload.id;
      state.fetchContactActivity.loading[id] = true;
    },
    fetchingContactEvents: (state, action: PayloadAction<{ id: string }>) => {
      const id = action.payload.id;
      state.fetchContactEvents.loading[id] = true;
    },
    fetchingContactPages: (state, action: PayloadAction<{ id: string }>) => {
      const id = action.payload.id;
      state.fetchContactPages.loading[id] = true;
    },
    fetchingContactGroupsFailed: (
      state,
      action: PayloadAction<{ id: string; error: string }>,
    ) => {
      const id = action.payload.id;
      const error = action.payload.error;
      state.fetchContactGroups.loading[id] = false;
      state.fetchContactGroups.error[id] = error;
    },
    fetchingContactCompaniesFailed: (
      state,
      action: PayloadAction<{ id: string; error: string }>,
    ) => {
      const id = action.payload.id;
      const error = action.payload.error;
      state.fetchContactCompanies.loading[id] = false;
      state.fetchContactCompanies.error[id] = error;
    },
    fetchingContactActivityFailed: (
      state,
      action: PayloadAction<{ id: string; error: string }>,
    ) => {
      const id = action.payload.id;
      const error = action.payload.error;
      state.fetchContactActivity.loading[id] = false;
      state.fetchContactActivity.error[id] = error;
    },
    fetchingContactEventsFailed: (
      state,
      action: PayloadAction<{ id: string; error: string }>,
    ) => {
      const id = action.payload.id;
      const error = action.payload.error;
      state.fetchContactEvents.loading[id] = false;
      state.fetchContactEvents.error[id] = error;
    },
    fetchingContactPagesFailed: (
      state,
      action: PayloadAction<{ id: string; error: string }>,
    ) => {
      const id = action.payload.id;
      const error = action.payload.error;
      state.fetchContactPages.loading[id] = false;
      state.fetchContactPages.error[id] = error;
    },
    fetchingOneFailed: (
      state,
      action: PayloadAction<{ id: string; error: string }>,
    ) => {
      const id = action.payload.id;
      const error = action.payload.error;
      state.fetch.loading[id] = false;
      state.fetch.error[id] = error;
    },
    upsertOne: (
      state,
      action: PayloadAction<{ id: string; data: IContact }>,
    ) => {
      const id = action.payload.id;
      const data = action.payload.data;
      state.fetch.loading[id] = false;
      state.fetch.error[id] = undefined;
      contactsAdapter.upsertOne(state, data);
    },
    upsertOneContactGroups: (
      state,
      action: PayloadAction<{ id: string; data: IContactGroups }>,
    ) => {
      const id = action.payload.id;
      const data = action.payload.data;
      state.fetchContactGroups.loading[id] = false;
      state.fetchContactGroups.error[id] = undefined;
      state.contactGroups.data[id] = data;
    },
    upsertOneContactCompanies: (
      state,
      action: PayloadAction<{ id: string; data: IContactCompanies }>,
    ) => {
      const id = action.payload.id;
      const data = action.payload.data;
      state.fetchContactCompanies.loading[id] = false;
      state.fetchContactCompanies.error[id] = undefined;
      state.contactCompanies.data[id] = data;
    },
    upsertOneContactActivity: (
      state,
      action: PayloadAction<{ id: string; data: IContactActivity }>,
    ) => {
      const id = action.payload.id;
      const data = action.payload.data;
      state.fetchContactActivity.loading[id] = false;
      state.fetchContactActivity.error[id] = undefined;
      state.contactActivity.data[id] = data;
    },
    upsertOneContactEvents: (
      state,
      action: PayloadAction<{ id: string; data: IContactEvents }>,
    ) => {
      const id = action.payload.id;
      const data = action.payload.data;
      state.fetchContactEvents.loading[id] = false;
      state.fetchContactEvents.error[id] = undefined;
      state.contactEvents.data[id] = data;
    },
    upsertOneContactPages: (
      state,
      action: PayloadAction<{ id: string; data: IContactPages }>,
    ) => {
      const id = action.payload.id;
      const data = action.payload.data;
      state.fetchContactPages.loading[id] = false;
      state.fetchContactPages.error[id] = undefined;
      state.contactPages.data[id] = data;
    },
  },
});

export default slice.reducer;
