import { useState } from "react";
import { IGetUsersResponse, useInviteUsersMutation } from "core/models/users";
import { validateEmail } from "core/helpers/validateEmail";
import { useToast } from "./useToast";
import { useCurrentApp } from "./useCurrentApp";

export const useInviteUsers = () => {
  const { id: appId, enforceGoogleOauth } = useCurrentApp();
  const [validationError, setValidationError] = useState<string | null>(null);
  const [inviteUsersMutation, { data: users, isSuccess, error: inviteError }] =
    useInviteUsersMutation();
  const toast = useToast();

  const inviteUsers = ({
    event,
    emails,
    page,
  }: {
    event: any;
    emails: string;
    page: string;
  }) => {
    event.preventDefault();

    if (!emails) {
      setValidationError("Please enter a valid email address");
      return;
    }

    const emailList = emails.split(",").map((email) => email.trim());
    const validList = emailList.every((email) => validateEmail(email));

    if (!validList) {
      const invalidEmails = emailList.filter((email) => !validateEmail(email));
      const pluralize = invalidEmails.length > 1;
      setValidationError(
        `${invalidEmails.join(",")} ${pluralize ? "are" : "is"} not ${
          pluralize ? "" : "a"
        } valid email${pluralize ? "s" : ""}`,
      );
    } else {
      setValidationError(null);
      inviteUsersMutation({
        appId,
        emailList,
        page,
      }).then((res) => {
        const data = (res as { data: IGetUsersResponse })?.data;
        const error = (res as { error: any })?.error;
        if (data) {
          toast({
            title: "Invited successfully",
            description: `An invite link has been sent to the newly added member${
              data.length > 1 ? "s" : ""
            }`,
          });
        } else if (error) {
          if (enforceGoogleOauth) {
            toast({
              title: "Oops, something went wrong.",
              description:
                "The user you invited may already have a June account without Google authentication. If they do, please ask them to log in with Google and invite them again. Alternatively stop enforcing Google authentication in your app settings.",
              status: "error",
              duration: 4000,
            });
          } else {
            toast({
              title: "Oops, something went wrong.",
              description:
                error?.data?.error ||
                "Something went wrong while inviting the user. Please try again with a valid email or contact support.",
              status: "error",
            });
          }
        }
      });
    }
  };

  return {
    inviteUsers,
    isSuccess,
    inviteError,
    users,
    validationError,
  };
};
