import React from "react";
import { useClearbitLogo } from "core/hooks/useClearbitLogo";
import CompanyAvatar from "core/components/Avatars/CompanyAvatar";
import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import { IMostEngagedCompany } from "./CompaniesList";
import { TooltipBody } from "../../../../components/TooltipBody";

export const CompanyLink: React.FC<{
  props: IMostEngagedCompany;
  previewMode: boolean;
  sharingMode: boolean;
}> = ({
  props: { groupId, contacts, openCompany },
  previewMode,
  sharingMode,
}) => {
  const group = { groupId, contacts };
  const logoURL = useClearbitLogo({ domain: groupId });

  return (
    <Box>
      <Tooltip
        label={
          <TooltipBody text="Company profiles are disabled in preview mode" />
        }
        placement="right"
        borderRadius="lg"
        isDisabled={!previewMode && !sharingMode}
      >
        <Box
          cursor={sharingMode || previewMode ? "" : "pointer"}
          onClick={() => {
            if (sharingMode || previewMode) return;
            openCompany();
          }}
        >
          <Flex align="center">
            <Flex mr={3}>
              <CompanyAvatar
                group={{ id: groupId }}
                size="sm"
                logoURL={logoURL}
              />
            </Flex>
            <Flex direction="column">
              <Flex>
                <Text fontSize="sm" noOfLines={1}>
                  {groupId}
                </Text>
              </Flex>
              <Flex>
                <Text fontSize="sm" color="gray.600">
                  {group.contacts?.length} user
                  {group.contacts?.length === 1 ? "" : "s"}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default CompanyLink;
