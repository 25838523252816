import { IToast } from "core/types/Toast";
import { uuid } from "core/helpers/uuid";
import { toastsSlice } from "./";

interface IToastParams {
  title: string;
  description?: string;
  status: "info" | "success" | "warning" | "error";
}

export const { add, removeOne } = toastsSlice.actions;

export const triggerToast =
  ({ title, description, status }: IToastParams) =>
  async (dispatch: (args: { payload: IToast; type: string }) => void) => {
    const id = uuid();
    dispatch(add({ id, title, description, status }));
  };
