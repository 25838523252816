import { ChangeEvent, SyntheticEvent, useState } from "react";
import { IContact } from "core/types/Contact";
import axios from "core/initializers/axios";
import { useToast } from "core/hooks/useToast";
import { useContact } from "core/hooks/useContact";
import {
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

interface IEditorProps {
  contactId: string;
  isOpen: boolean;
  toggle: () => void;
  contact: IContact;
  appId: number;
  mutate: () => void;
}

export default function Editor({
  isOpen,
  toggle,
  contact,
  appId,
  mutate,
}: IEditorProps) {
  const [state, setState] = useState({
    firstName: contact.firstName,
    lastName: contact.lastName,
    email: contact.email,
  });
  const toast = useToast();
  const { fetchContact } = useContact({
    appId,
    id: contact.id,
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const { firstName, lastName, email } = state;
    axios
      .put(
        `/contacts/${encodeURIComponent(contact.id)}`,
        {
          appId,
          firstName,
          lastName,
          email,
        },
        { withCredentials: true },
      )
      .then(() => {
        mutate();
        toggle();
        fetchContact();
      })
      .catch((error) => console.log("api errors:", error));
  };

  return (
    <Modal size="sm" isOpen={isOpen} onClose={toggle}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit user</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" width="100%">
            <Input
              focusBorderColor="purple.100"
              width="fit"
              placeholder="First name..."
              type="text"
              name="firstName"
              defaultValue={contact.firstName}
              onChange={handleChange}
              mb={1}
            />
            <Input
              focusBorderColor="purple.100"
              width="fit"
              placeholder="Last name..."
              type="text"
              name="lastName"
              defaultValue={contact.lastName}
              onChange={handleChange}
              mb={1}
            />
            <Input
              focusBorderColor="purple.100"
              width="fit"
              placeholder="Email..."
              type="email"
              name="email"
              defaultValue={contact.email ? contact.email : ""}
              onChange={handleChange}
            />
          </Flex>
          <ModalFooter px={0}>
            <Button
              colorScheme="black"
              onClick={(e) => {
                handleSubmit(e);
                toast({
                  title: "Saved successfully",
                });
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
