import React from "react";
import moment from "moment";
import { IInsightSection } from "core/types/TemplateConfig";
import { IReport } from "core/types/Report";
import { useContactDrilldown } from "core/hooks/useContactDrilldown";
import Error from "core/components/Error";
import { Drilldown } from "core/components/Drilldown";
import { UserListSingleContact } from "core/components/Contact/UserListSingleContact";
import { Button, Flex, Spinner, UseDisclosureReturn } from "@chakra-ui/react";

interface IHottestUsersListProps
  extends Pick<UseDisclosureReturn, "isOpen" | "onClose"> {
  insight: IInsightSection;
  report: IReport;
  startDate?: string;
}

export const HottestUsersList: React.FC<IHottestUsersListProps> = ({
  report,
  startDate,
  isOpen,
  onClose,
  insight,
}) => {
  const {
    contacts: hottestUsers,
    isLoading,
    error,
    hasMoreContacts,
    loadMore,
  } = useContactDrilldown({
    report,
    insightType: insight.typeId,
    insightParams: { startDate },
    skip: !isOpen,
  });

  if (error) {
    return <Error />;
  }

  return (
    <Drilldown isOpen={isOpen} onClose={onClose}>
      {(Drilldown) => (
        <>
          <Drilldown.Header>
            <Drilldown.Title
              title={insight.title}
              description="Fastest users to reach all milestones"
              isLoading={isLoading}
            />
          </Drilldown.Header>
          <Drilldown.Body>
            {hottestUsers.map((user: any) => (
              <UserListSingleContact
                key={user.userId}
                id={user.userId}
                traits={user.traits}
                description={`Joined ${moment.utc(user?.milestone1Timestamp).fromNow()}`}
              />
            ))}
            {hasMoreContacts && (
              <Flex justify="center">
                <Button isDisabled={isLoading} onClick={loadMore}>
                  {isLoading ? <Spinner /> : "Load more"}
                </Button>
              </Flex>
            )}
          </Drilldown.Body>
        </>
      )}
    </Drilldown>
  );
};
