export function setNextPath(next: string): void {
  return localStorage.setItem("june__next", next);
}

export function getNextPath(): string | null {
  return localStorage.getItem("june__next");
}

export function clearNextPath(): void {
  return localStorage.removeItem("june__next");
}
