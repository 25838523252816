import React from "react";
import { Box, Center, Text } from "@chakra-ui/react";
import { MoonIcon } from "@chakra-ui/icons";

const ApiKeysEmptyState: React.FC = () => (
  <Center flexDirection="column" gap={3} py={4}>
    <Box px={3} py={2} borderRadius="lg" bg="purple.100">
      <MoonIcon color="purple.400" h={5} w={5} />
    </Box>
    <Text fontSize="sm" color="gray.600">
      You don't have any API keys
    </Text>
  </Center>
);

export default ApiKeysEmptyState;
