import { Link, useParams } from "react-router-dom";
import React from "react";
import { pluralize } from "helpers/pluralize";
import { ISlippingAwayGroup } from "core/modules/reports/report-types/SlippingAwayGroups/SlippingAwayGroups.types";
import { useClearbitLogo } from "core/hooks/useClearbitLogo";
import { TooltipBody } from "core/components/TooltipBody";
import CompanyAvatar from "core/components/Avatars/CompanyAvatar";
import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";

interface IGroupProps extends ISlippingAwayGroup {
  groupId: string;
}
interface ICompanyLinkProps {
  props: IGroupProps;
  previewMode?: boolean;
  sharingMode?: boolean;
}

export const CompanyLink: React.FC<ICompanyLinkProps> = ({
  props: group,
  previewMode = false,
  sharingMode = false,
}) => {
  const { appId } = useParams<{ appId: string }>();
  const logoURL = useClearbitLogo({ domain: group?.id });

  if (previewMode || sharingMode) {
    return (
      <Box data-testid={`group-${group?.id}`}>
        <Tooltip
          data-testid={`tooltip-group-${group?.id}`}
          label={
            <TooltipBody text="Company profiles are disabled in preview mode" />
          }
          placement="right"
          borderRadius="lg"
        >
          <Box cursor={"pointer"}>
            <Flex align="center">
              <Flex mr={3}>
                <CompanyAvatar group={group} size="sm" logoURL={logoURL} />
              </Flex>
              <Flex direction="column">
                <Flex>
                  <Text fontSize="sm" noOfLines={1}>
                    {group?.name}
                  </Text>
                </Flex>
                <Flex>
                  <Text fontSize="xs" color="gray.600">
                    {group?.contacts?.length} user
                    {group?.contacts?.length === 1 ? "" : "s"}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Box>
        </Tooltip>
      </Box>
    );
  }

  return (
    <Box>
      <Link
        to={`/a/${appId}/company/${group?.id}/`}
        data-testid={`group-link-${group?.id}`}
      >
        <Flex align="center">
          <Flex mr={3}>
            <CompanyAvatar group={group} size="sm" logoURL={logoURL} />
          </Flex>
          <Flex direction="column">
            <Flex>
              <Text fontSize="sm" noOfLines={1} color="gray.800">
                {group?.id}
              </Text>
            </Flex>
            <Flex>
              <Text fontSize="xs" color="gray.600">
                {group?.contacts?.length}{" "}
                {pluralize(group?.contacts?.length, "user", "users")}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Link>
    </Box>
  );
};

export default CompanyLink;
