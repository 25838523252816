import { NotSupportedTypeTooltip } from "modules/Integrations/Properties/NotSupportedTooltip";
import { AlreadyUsedTooltip } from "modules/Integrations//Properties/AlreadyUsedTooltip";
import { Integration, IProperty } from "core/models/dataMappings";
import { CommandOption } from "core/design-system/components/Command";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import { Tooltip } from "@chakra-ui/react";
import {
  SUPPORTED_TYPES_BY_INTEGRATION,
  TYPE_TO_ICON,
} from "./SelectDestination";
import { ProtectedAttributeTooltip } from "./Properties/ProtectedAttributeTooltip";

export const ExternalTraitsComponent: React.FC<{
  property: IProperty;
  usedExternalProperties: string[];
  integration: Integration;
  onSelect: (property: IProperty) => void;
}> = ({ property, usedExternalProperties, integration, onSelect }) => {
  const supportedTypes = SUPPORTED_TYPES_BY_INTEGRATION[integration];
  const isAlreadyUsed = usedExternalProperties.includes(property.name);
  const isSupportedType = supportedTypes.includes(property.type);
  const isProtectedProperty =
    integration === Integration.Attio || integration === Integration.Salesforce
      ? !property.isWritable
      : property?.modificationMetadata?.readOnlyValue;
  const icon = TYPE_TO_ICON[property.type] || (
    <QuestionMarkCircleIcon className="h-4" />
  );
  return (
    <CommandOption
      value={() => {
        if (!isSupportedType) return;
        if (isAlreadyUsed) return;
        return onSelect(property);
      }}
      disabled={!isSupportedType || isAlreadyUsed || isProtectedProperty}
    >
      <Tooltip
        label={
          isAlreadyUsed ? (
            <AlreadyUsedTooltip integration={integration} />
          ) : !isSupportedType ? (
            <NotSupportedTypeTooltip
              type={property.type}
              integration={integration}
            />
          ) : isProtectedProperty ? (
            <ProtectedAttributeTooltip integration={integration} />
          ) : (
            ""
          )
        }
        placement="right"
        shouldWrapChildren
        hasArrow
      >
        <div className="flex items-center space-x-2">
          <div>
            <Tooltip
              hasArrow
              placement="left"
              label={!(isSupportedType || isAlreadyUsed) ? "" : property.type}
            >
              {icon}
            </Tooltip>
          </div>
          <div>
            <p className="truncate">{property.label}</p>
          </div>
        </div>
      </Tooltip>
    </CommandOption>
  );
};
