import { ReactSortable } from "react-sortablejs";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { ChooseInsightsContainer } from "modules/ViewInsight/Modal/ChooseInsightsContainer";
import { AddInsightBuilder } from "modules/ViewInsight/Modal/AddInsightBuilder";
import {
  IViewInsight,
  useUpdateViewInsightMutation,
} from "core/models/viewInsights";
import { selector as editingPinnedReportsSelector } from "core/models/pinnedReports/selectors";
import { useViews, ViewLocation } from "core/hooks/useViews";
import { usePaywall } from "core/hooks/usePaywall";
import { useOnboardingChecklist } from "core/hooks/useOnboardingChecklist";
import { Center, Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import { ViewInsight } from "../ViewInsight/ViewInsight";

export const ViewContainer: React.FC<{
  location: ViewLocation;
  viewId: number;
  columns?: number;
  width?: string;
  paywallInsights?: boolean;
  showAddModal: boolean;
  setShowAddModal: (show: boolean) => void;
}> = ({
  location,
  viewId,
  columns = 3,
  width = "315px",
  paywallInsights,
  showAddModal,
  setShowAddModal,
}) => {
  const { viewInsights, reachedLimit } = useViews({ location, viewId });
  const { isEditing } = useSelector(editingPinnedReportsSelector);
  const [updateViewInsight] = useUpdateViewInsightMutation();
  const [reorderableInsights, setReorderableInsights] = useState<
    IViewInsight[]
  >([]);
  const { shouldShowGettingStartedBanner } = useOnboardingChecklist();
  const { shouldBePaywalled } = usePaywall();
  const [
    showChooseExistingReportInsights,
    setShowChooseExistingReportInsights,
  ] = useState(false);

  useEffect(() => {
    if (viewInsights) {
      const sortedList = [...viewInsights].sort((a, b) => a.order - b.order);
      setReorderableInsights(sortedList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewInsights]);

  useEffect(() => {
    if (showAddModal) {
      setShowChooseExistingReportInsights(false);
    }
  }, [showAddModal]);

  useEffect(() => {
    if (!isEditing)
      reorderableInsights.forEach((insight: IViewInsight, index: number) => {
        updateViewInsight({
          id: Number(insight.id),
          appId: Number(insight.appId),
          order: Number(index),
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  function onReorder(newOrder: IViewInsight[]) {
    setReorderableInsights(newOrder);
  }

  const isGreyedOut = shouldShowGettingStartedBanner;

  return (
    <Grid
      list={reorderableInsights.map((i) => ({ ...i }))}
      setList={onReorder}
      as={isEditing ? ReactSortable : Grid}
      templateColumns={`repeat(${columns}, 1fr)`}
      autoRows="315px"
      gridGap={5}
      opacity={isGreyedOut ? 0.4 : undefined}
      filter={isGreyedOut ? "grayscale(0.8)" : undefined}
      swap
    >
      {reorderableInsights?.map((viewInsight: IViewInsight, index: number) => {
        return (
          <GridItem
            key={index}
            rowSpan={viewInsight.gridHeight}
            colSpan={viewInsight.gridWidth}
            h="315px"
            minW="315px"
            minH="315px"
          >
            <ViewInsight
              key={`${columns}-${index}-${width}`}
              location={location}
              isEditing={isEditing}
              viewInsight={viewInsight}
              isBlurred={
                shouldBePaywalled &&
                paywallInsights &&
                !viewInsight.isAutoGenerated
              }
              query={viewInsight.query}
              isAI={Boolean(viewInsight.queryId)}
            />
          </GridItem>
        );
      })}
      {isEditing && (
        <GridItem w={width} h="315px">
          <Flex
            onClick={() => !reachedLimit && setShowAddModal(true)}
            cursor={reachedLimit ? "not-allowed" : "pointer"}
            w="full"
            h="full"
            border="2px dashed"
            borderColor={reachedLimit ? "gray.400" : "purple.400"}
            borderRadius="lg"
            _hover={{ bg: reachedLimit ? "gray.100" : "purple.50" }}
            transition="all .1s ease-in-out"
          >
            <Center w="full">
              <Text
                textAlign="center"
                px={20}
                fontSize="sm"
                fontWeight="medium"
                color={reachedLimit ? "gray.600" : "purple.400"}
              >
                {reachedLimit
                  ? `Maximum insights added. Remove one to add another.`
                  : `Add insight`}
              </Text>
            </Center>
          </Flex>
        </GridItem>
      )}
      <React.Suspense fallback={<div />}>
        {!showChooseExistingReportInsights ? (
          showAddModal && (
            <AddInsightBuilder
              isOpen={showAddModal}
              onClose={() => setShowAddModal(false)}
              setShowChooseExistingReportInsights={() =>
                setShowChooseExistingReportInsights(true)
              }
              viewId={viewId}
            />
          )
        ) : (
          <ChooseInsightsContainer
            location={location}
            isOpen={showAddModal}
            onClose={() => setShowAddModal(false)}
            viewId={viewId}
          />
        )}
      </React.Suspense>
    </Grid>
  );
};
