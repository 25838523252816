import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { IFilter } from "core/types/Report";
import { ITraitValue, useGetTraitValuesQuery } from "core/models/traits";
import usePagination from "core/hooks/usePagination";
import { Lifecycle } from "core/design-system/components/Command/Lifecycle";
import {
  Command,
  CommandInput,
  CommandList,
  CommandOption,
} from "core/design-system/components/Command";
import { Button, Spinner } from "@chakra-ui/react";

export const ValueSelection: React.FC<{
  filter: IFilter;
  onSelectValue: (value: string) => void;
  onDone: () => void;
}> = ({ filter, onSelectValue, onDone }) => {
  const { appId } = useParams();
  const [values, setValues] = useState<ITraitValue[]>([]);
  const [searchTrait, setSearchTrait] = useState<string>("");

  const { currentPage, setPage, nextPage } = usePagination({
    withURL: false,
    searchQuery: searchTrait,
  });
  const { data, isLoading, isFetching } = useGetTraitValuesQuery({
    appId: Number(appId),
    trait: filter?.body?.trait,
    filterType: filter?.type,
    page: currentPage,
    query: searchTrait,
  });

  useEffect(() => {
    if (!data) return;

    setValues([...values, ...data.values]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchTrait]);

  useEffect(() => {
    setPage(1);

    if (!data) return;
    setValues([...data.values]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTrait]);

  if (isLoading || !data)
    return (
      <Command className="max-h-80 w-full min-w-[200px] max-w-[250px]">
        <div className="flex w-full justify-center py-3">
          <Spinner size="sm" />
        </div>
      </Command>
    );

  return (
    <>
      <Lifecycle
        onUnmount={() => {
          setSearchTrait("");
          onDone();
        }}
      />
      <Command className="max-h-80 w-full min-w-[200px] max-w-[250px]">
        <CommandInput
          placeholder="Search value..."
          value={searchTrait}
          onChange={(e) => setSearchTrait(e.target.value)}
        />
        <div key={values.length}>
          <CommandList data-testid={`trait-value-list`} className="pb-10">
            {values.map((value, index) => (
              <CommandOption
                key={value.value}
                value={() => onSelectValue(value.value)}
              >
                <div
                  key={index}
                  className="relative flex cursor-pointer items-center gap-2"
                >
                  <div className="flex h-4 items-center">
                    <input
                      checked={filter.body.value.includes(value.value)}
                      id={`person-${value.value}`}
                      name={`person-${value.value}`}
                      type="checkbox"
                      className="h-3 w-3 rounded border-gray-300 text-purple-600 focus:ring-purple-600"
                    />
                  </div>
                  <div className="min-w-0 flex-1 text-xs">
                    <p className="select-none font-medium text-gray-900">
                      {value.value}
                    </p>
                  </div>
                </div>
              </CommandOption>
            ))}
            {values.length === 0 && searchTrait === "" && (
              <div className="flex w-full justify-center py-3">
                <p className="text-gray-500">No results</p>
              </div>
            )}
            {values.length === 0 && searchTrait !== "" && (
              <CommandOption value={() => onSelectValue(searchTrait)}>
                <div className="relative flex cursor-pointer items-center gap-3">
                  <div className="flex h-6 items-center">
                    <input
                      checked={filter.body.value.includes(searchTrait)}
                      id={`person-${searchTrait}`}
                      name={`person-${searchTrait}`}
                      type="checkbox"
                      className="h-3 w-3 rounded border-gray-300 text-purple-600 focus:ring-purple-600"
                    />
                  </div>
                  <div className="min-w-0 flex-1 text-xs">
                    <p
                      className="select-none font-medium text-gray-900"
                      onClick={() => onSelectValue(searchTrait)}
                    >
                      Specify: {searchTrait}
                    </p>
                  </div>
                </div>
              </CommandOption>
            )}
            {values.values.length !== 0 && (
              <div className="flex w-full justify-center pt-2">
                <Button
                  variant="ghost"
                  onClick={nextPage}
                  isLoading={isFetching}
                  isDisabled={data.pagy.last === currentPage}
                >
                  Load more
                </Button>
              </div>
            )}
          </CommandList>
        </div>
      </Command>
    </>
  );
};
