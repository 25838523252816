import { useParams } from "react-router-dom";
import { Measure } from "core/types/ViewInsight";
import {
  ITemplateConfig,
  ITemplateConfigSetupSection,
} from "core/types/TemplateConfig";
import { IReport } from "core/types/Report";
import { Plan } from "core/types/App";
import { MultiEventPicker } from "core/modules/reports/setup/Editor/Event";
import EnumPicker from "core/modules/reports/setup/Editor/Enum";
import { DynamicMultiEventPicker } from "core/modules/reports/setup/Editor/DynamicEvent";
import ConversionWindowPicker from "core/modules/reports/setup/Editor/ConversionWindowPicker";
import AudiencePicker from "core/modules/reports/setup/Editor/Audience";
import { AudienceCountPreview } from "core/modules/reports/setup/Audience/CountPreview";
import { IViewInsight } from "core/models/viewInsights";
import { IAppObject } from "core/models/appObjects";
import { MeasureToIcon } from "core/hooks/useEditViewInsight";
import { useAppObjects } from "core/hooks/useAppObjects";
import {
  AUDIENCE_TYPE,
  CONVERSION_WINDOW_TYPE,
  ENUM_TYPE,
  MULTI_EVENT_TYPE,
  SETTINGS_TYPE,
  SINGLE_EVENT_TYPE,
} from "core/constants/report-setup";
import ReportSettings from "core/components/ReportSettings";
import { PaywallPopover } from "core/components/Paywall/Popover";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

export const SetupContainer: React.FC<{
  viewInsight: IViewInsight;
  report: IReport;
  templateConfig: ITemplateConfig;
  measure: Measure;
  appObject: IAppObject;
  isAddingNewInsight?: boolean;
  setMeasure: (measure: Measure) => void;
  setAppObject: (appObject: IAppObject) => void;
}> = ({
  viewInsight,
  report,
  templateConfig,
  measure,
  setMeasure,
  appObject,
  setAppObject,
  isAddingNewInsight,
}) => {
  const { groupId } = useParams();
  const { appObjects } = useAppObjects();

  return (
    <div className="flex h-full flex-col gap-y-6">
      <div>
        <p className="mb-2 text-sm font-medium">Measure</p>
        <Menu matchWidth>
          <PaywallPopover
            feature="company profile insights"
            redirect="people"
            plan={Plan.Growth}
            overridePaywall={!Boolean(groupId) || isAddingNewInsight}
          >
            <MenuButton
              textAlign="left"
              w="full"
              as={Button}
              rightIcon={<ChevronDownIcon />}
              bg="gray.50"
              border="none"
              textTransform="capitalize"
            >
              <div className="flex items-center gap-1">
                {MeasureToIcon[measure]}
                {measure.split("_").join(" ")}
              </div>
            </MenuButton>
          </PaywallPopover>
          <MenuList>
            {Object.values(Measure).map((measure) => (
              <MenuItem
                textTransform="capitalize"
                onClick={() => setMeasure(measure)}
              >
                <div className="flex items-center gap-1">
                  {MeasureToIcon[measure]}
                  {measure.split("_").join(" ")}
                </div>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </div>
      {!groupId && measure !== Measure.Features && (
        <div>
          <p className="mb-2 text-sm font-medium">Object</p>
          <Menu matchWidth>
            <PaywallPopover
              feature="company level metrics"
              redirect="people"
              plan={Plan.Growth}
            >
              <MenuButton
                textAlign="left"
                w="full"
                as={Button}
                rightIcon={<ChevronDownIcon />}
                bg="gray.50"
                border="none"
                textTransform="capitalize"
              >
                <div className="flex items-center gap-1">
                  {appObject.pluralName}
                </div>
              </MenuButton>
            </PaywallPopover>
            <MenuList>
              {appObjects.map((appObject) => (
                <MenuItem
                  textTransform="capitalize"
                  onClick={() => setAppObject(appObject)}
                >
                  <div className="flex items-center gap-1">
                    {appObject.pluralName}
                  </div>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </div>
      )}
      {templateConfig?.setup?.setupSections.map(
        (section: ITemplateConfigSetupSection, index: number) => {
          if (section.type === MULTI_EVENT_TYPE) {
            if (section.isDynamic) {
              return (
                <div key={index}>
                  <p className="mb-4 text-sm font-medium capitalize">
                    {`${section.configKey}s`}
                  </p>
                  <DynamicMultiEventPicker
                    setup={templateConfig.setup}
                    section={section}
                  />
                </div>
              );
            } else {
              return (
                <div key={index}>
                  <p className="mb-4 text-sm font-medium">{section.title}</p>
                  <MultiEventPicker
                    setup={templateConfig.setup}
                    section={section}
                  />
                </div>
              );
            }
          }

          if (!groupId && section.type === AUDIENCE_TYPE) {
            return (
              <div key={index}>
                <p className="mb-4 text-sm font-medium">Audience</p>
                <div className="mb-4">
                  <AudienceCountPreview
                    audienceFilters={{
                      filterGroups: report.audience?.filterGroups,
                      joinOperator: report.audience?.joinOperator,
                    }}
                    showAvatars={false}
                  />
                </div>
                <AudiencePicker
                  section={section}
                  showRecommendedAudiences={false}
                />
              </div>
            );
          }

          if (section.type === SINGLE_EVENT_TYPE) {
            return (
              <div key={index}>
                <p className="mb-4 text-sm font-medium">{section.title}</p>
                <MultiEventPicker
                  setup={templateConfig.setup}
                  section={section}
                  singleEvent
                />
              </div>
            );
          }

          if (section.type === CONVERSION_WINDOW_TYPE) {
            return (
              <div key={index}>
                <div className="mb-4 flex items-center gap-1">
                  <p className="text-sm font-medium">Conversion Window</p>
                  <Tooltip label={section.description} placement="top">
                    <InformationCircleIcon className="h-4 w-4" />
                  </Tooltip>
                </div>
                <ConversionWindowPicker />
              </div>
            );
          }

          if (section.type === ENUM_TYPE) {
            return (
              <div key={index}>
                <p className="mb-4 text-sm font-medium">{section.title}</p>
                <EnumPicker section={section} />
              </div>
            );
          }

          if (section.type === SETTINGS_TYPE) {
            return (
              <div key={index}>
                <p className="mb-4 text-sm font-medium">{section.title}</p>
                <ReportSettings report={report} config={templateConfig} />
              </div>
            );
          }

          return null;
        },
      )}
    </div>
  );
};
