import {
  Area,
  AreaChart,
  ChartTooltip,
  defaultAreaProps,
  defaultDotProps,
  DefaultTooltip,
  defaultXAxisProps,
  Dot,
  XAxis,
} from "@june-so/ui";

export const Chart = ({
  data,
  onDataPointClick,
}: {
  data: any;
  onDataPointClick: (_: any, index: any) => void;
}) => {
  return (
    <AreaChart className="!h-48" data={data}>
      <ChartTooltip
        content={({ active, payload, label }) => (
          <DefaultTooltip
            label={label}
            active={active}
            payload={payload}
            valueFormatter={(payload: any) => {
              if (payload.y === 1) return "1 event triggered";

              return `${payload.y} events triggered`;
            }}
          />
        )}
      />
      <Area
        {...defaultAreaProps}
        dataKey="y"
        activeDot={
          <Dot
            {...defaultDotProps}
            onClick={onDataPointClick}
            cursor={"pointer"}
          />
        }
      />
      <XAxis {...defaultXAxisProps} dataKey="x" />
    </AreaChart>
  );
};
