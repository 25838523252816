import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface IAuthor {
  name: string;
  description: string;
  avatarUrl: string;
}

export interface IChangelog {
  slug: string;
  publishedAt: string;
  title: string;
  headerImage: string;
  authors: IAuthor[];
}

const baseQuery = fetchBaseQuery({ baseUrl: "https://changelog.june.so/" });

export const changelogsApi = createApi({
  baseQuery,
  reducerPath: "changelogsApi",
  endpoints: (builder) => ({
    getChangelogs: builder.query<IChangelog[], void>({
      query: () => "latest.json",
    }),
  }),
});

export const { useGetChangelogsQuery } = changelogsApi;
