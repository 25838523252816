import { useParams } from "react-router-dom";
import { EnrichedCompany } from "modules/Groups/Group/EnrichedCompany";
import { useCompany } from "core/hooks/useCompany";
import { Badge, Box, Flex, Text } from "@chakra-ui/react";

export const EnrichmentCard: React.FC = () => {
  const { appId, groupId: encodedGroupId } = useParams<{
    appId: string;
    groupId: string;
  }>() as {
    appId: string;
    groupId: string;
  };
  const groupId = decodeURIComponent(encodedGroupId);
  const { company } = useCompany({
    appId: Number(appId),
    id: groupId,
  });

  if (!groupId || !company) {
    return (
      <Box
        bg="white"
        p={4}
        border="1px"
        borderColor="gray.50"
        boxShadow="sm"
        borderRadius="lg"
      >
        <Flex mb={3} justifyContent="space-between" align="center">
          <Flex gap={2}>
            <Text fontWeight="medium">Enrichment</Text>
            <Badge colorScheme="purple">AI</Badge>
          </Flex>
        </Flex>

        <Text fontSize={"sm"} color="gray.600">
          We can't enrich this company because it doesn't have a domain.
        </Text>
      </Box>
    );
  }

  return <EnrichedCompany domain={company.name} />;
};
