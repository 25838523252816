import { createSlice } from "@reduxjs/toolkit";

export interface EditingPinnedReportsState {
  isEditing: boolean;
}

const initialState: EditingPinnedReportsState = {
  isEditing: false,
};

export const slice = createSlice({
  name: "editingPinnedReports",
  initialState,
  reducers: {
    toggleEdit: (state) => {
      state.isEditing = !state.isEditing;
    },
  },
});

export default slice.reducer;
