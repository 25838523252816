import moment from "moment";
import { ITableTraitConfig } from "core/types/TableTraitConfig";
import { IGroup } from "core/types/Group";
import { IContact } from "core/types/Contact";
import { ComputationType } from "core/constants/traits";
import { ChangeIcon } from "core/components/Traits/Trait";
import { CellContext } from "@tanstack/react-table";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/16/solid";
import { Tooltip } from "@chakra-ui/react";

const isNumber = (value: string) => !isNaN(Number(value));

export const Cell: React.FC<{
  info: CellContext<IContact | IGroup, unknown>;
  traitConfig: ITableTraitConfig;
}> = ({ info, traitConfig }) => {
  const value = (info.getValue() || "") as string;
  const domainPattern = /^(https?:\/\/)?[\w.-]+\.[a-z]{2,}(\/\S*)?$/i;
  if (domainPattern.test(value)) {
    const url = value.startsWith("http") ? value : `https://${value}`;
    return (
      <a
        className="text-sm underline"
        target="_blank"
        href={url}
        rel="noreferrer"
      >
        {value}
      </a>
    );
  } else if (value === "true") {
    return <CheckCircleIcon className="h-4 w-4 text-green-400" />;
  } else if (value === "false") {
    return <XCircleIcon className="h-4 w-4 text-red-400" />;
  } else if (
    traitConfig?.computedTrait?.config?.computationType ===
    ComputationType.EventCountChange
  ) {
    return (
      <div className="flex items-center gap-1">
        <ChangeIcon value={value} />
        {Math.abs(Number(value)).toString()}%
      </div>
    );
  } else if (
    traitConfig?.computedTrait?.config?.computationType ===
    ComputationType.UserCount
  ) {
    return (
      <div className="flex items-center gap-1">
        {(value || "0").toLocaleLowerCase()} user{Number(value) > 1 ? "s" : ""}
      </div>
    );
  } else if (
    traitConfig?.computedTrait?.config?.computationType ===
    ComputationType.DayCount
  ) {
    return (
      <div className="flex items-center gap-1">
        {(value || "0").toLocaleLowerCase()} day{Number(value) > 1 ? "s" : ""}
      </div>
    );
  } else if (
    traitConfig?.computedTrait?.config?.computationType ===
      ComputationType.FirstSeen ||
    traitConfig?.computedTrait?.config?.computationType ===
      ComputationType.LastSeen
  ) {
    if (moment(value).isValid()) {
      return (
        <Tooltip label={moment.utc(value).format("MMM DD, YYYY HH:mm:ss UTC")}>
          <div className="flex items-center gap-1">
            {moment.utc(value).fromNow()}
          </div>
        </Tooltip>
      );
    } else {
      return <>-</>;
    }
  } else if (value && isNumber(value)) {
    return <p>{Number(value).toLocaleString()}</p>;
  }
  return <>{value}</>;
};
