import { Link, useParams } from "react-router-dom";
import React from "react";
import { pluralize } from "helpers/pluralize";
import { useClearbitLogo } from "core/hooks/useClearbitLogo";
import { getDomainFromEmails } from "core/helpers/domainFromEmails";
import CompanyAvatar from "core/components/Avatars/CompanyAvatar";
import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import { ISlippingAwayGroup } from "./SlippingAwayGroups.types";
import { TooltipBody } from "../../../../components/TooltipBody";

interface IGroupProps extends ISlippingAwayGroup {
  groupId: string;
}
interface IGroupLinkProps {
  props: IGroupProps;
  previewMode?: boolean;
  sharingMode?: boolean;
}

export const GroupLink: React.FC<IGroupLinkProps> = ({
  props: group,
  previewMode = false,
  sharingMode = false,
}) => {
  const { appId } = useParams<{ appId: string }>();
  // Remove empty emails
  const emails = group?.contacts?.reduce((emails, contact) => {
    if (contact?.email) {
      emails.push(contact.email);
    }
    return emails;
  }, [] as string[]);

  const domain = getDomainFromEmails(emails);
  const logoURL = useClearbitLogo({ domain });

  if (previewMode || sharingMode) {
    return (
      <Box data-testid={`group-${group?.id}`}>
        <Tooltip
          data-testid={`tooltip-group-${group?.id}`}
          label={
            <TooltipBody text="Company profiles are disabled in preview mode" />
          }
          placement="right"
          borderRadius="lg"
        >
          <Box cursor={"pointer"}>
            <Flex align="center">
              <Flex mr={3}>
                <CompanyAvatar group={group} size="sm" logoURL={logoURL} />
              </Flex>
              <Flex direction="column">
                <Flex>
                  <Text fontSize="sm" noOfLines={1}>
                    {group?.name}
                  </Text>
                </Flex>
                <Flex>
                  <Text fontSize="xs" color="gray.600">
                    {group?.contacts?.length} user
                    {group?.contacts?.length === 1 ? "" : "s"}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Box>
        </Tooltip>
      </Box>
    );
  }

  return (
    <Box>
      <Link
        to={`/a/${appId}/group/${group?.id}`}
        data-testid={`group-link-${group?.id}`}
      >
        <Flex align="center">
          <Flex mr={3}>
            <CompanyAvatar group={group} size="sm" logoURL={logoURL} />
          </Flex>
          <Flex direction="column">
            <Flex>
              <Text
                fontSize="sm"
                noOfLines={1}
                color={!group?.name ? "gray.600" : "gray.800"}
              >
                {group?.name || group?.id}
              </Text>
            </Flex>
            <Flex>
              <Text fontSize="xs" color="gray.600">
                {group?.contacts?.length}{" "}
                {pluralize(group?.contacts?.length, "user", "users")}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Link>
    </Box>
  );
};

export default GroupLink;
