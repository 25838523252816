import { useState } from "react";
import moment from "moment";
import { IReportInsight } from "core/types/Report";
import useInsightDataFetching from "core/hooks/useInsightDataFetching";
import { isScreenshotMode } from "core/design-system/constants/charts";
import Icon from "core/design-system/components/Icon";
import InsightCard from "core/components/InsightCard/Index";
import {
  BarChart,
  BarItem,
  ChartTooltip,
  defaultGridProps,
  DefaultTooltip,
  defaultXAxisProps,
  defaultYAxisProps,
  Grid,
  XAxis,
  YAxis,
} from "@june-so/ui";
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { PowerGroupsList } from "./PowerGroupsList";
import { IPowerGroupData, IPowerGroupItem } from "./PowerGroups.types";
import { l30MockData } from "./mockData";
import { ISelectedPeriod } from "../PowerUsers/types";
import { CurrentPeriodWarning } from "../PowerUsers/CurrentPeriodWarning";

const MONTH = 1;

function BarChartGraph({
  appId,
  reportId,
  report,
  sharingSecretToken,
  previewMode,
  sharingMode,
  config,
  screenshotMode,
  showYAxis,
}: IReportInsight) {
  const {
    isOpen: isAudiencePanelOpen,
    onOpen: onAudienceOpen,
    onClose: onAudienceClose,
  } = useDisclosure();

  const [powerGroupsCount, setPowerGroupsCount] = useState<number>(0);
  const [numDays, setNumDays] = useState<number>(0);

  var lastThreeAndCurrentMonths: ISelectedPeriod[] = [];
  const startDate = moment().subtract(3, "months");
  const endDate = moment();
  while (startDate <= endDate) {
    lastThreeAndCurrentMonths.push({
      isCurrent:
        startDate.format("MMMM, YYYY") === endDate.format("MMMM, YYYY"),
      humanReadable: startDate.format("MMMM, YYYY"),
      date: startDate.format("MMMM, YYYY"),
    });
    startDate.add(1, "month");
  }
  const currentMonth =
    lastThreeAndCurrentMonths[lastThreeAndCurrentMonths.length - 1];
  const previousMonth =
    lastThreeAndCurrentMonths[lastThreeAndCurrentMonths.length - 2];
  const [selectedMonth, setSelectedMonth] = useState<ISelectedPeriod>(
    isScreenshotMode ? previousMonth : currentMonth,
  );

  const insight = config.insights.find(
    ({ slug }: { slug: string }) => slug === "power-companies-30-days",
  );
  const isSetupValid = config.validateSetup(report.config);
  const { response, isLoading, isFetching, error, onRefreshInsight } =
    useInsightDataFetching({
      insight,
      report,
      sharingSecretToken,
      previewMode,
      insightParams: { month: selectedMonth.date },
      skip: !isSetupValid,
    });

  function transformData(items: IPowerGroupData["powerGroups"]) {
    return items.map((item) => ({
      name: `${item.days} day${item.days === 1 ? "" : "s"}`,
      days: item.days,
      groupsPercentage: item.groupsPercentage,
      groupsCount: item.groupsCount,
    }));
  }

  const data = previewMode ? l30MockData : response?.data;

  if (!insight) return null;

  return (
    <InsightCard>
      {(Card) => (
        <Card.Container insight={insight} id={insight.slug}>
          <Card.Header
            hasCaching={response?.hasCaching}
            showReportTitle={sharingMode}
            screenshotMode={screenshotMode}
            title={
              sharingMode
                ? `Power Companies Curve on 30 days interval - ${report.name}`
                : "Power Companies Curve on 30 days interval"
            }
            insight={insight}
            showActions={!sharingMode && !previewMode}
            sharingMode={sharingMode}
            config={config}
            report={report}
            refresh={onRefreshInsight}
            cachedAt={response?.cachedAt}
          >
            <Flex direction="row" justify="flex-end">
              {!previewMode && (
                <Flex>
                  <Menu>
                    <MenuButton
                      as={Button}
                      leftIcon={
                        <Icon
                          iconType="icon"
                          color={`gray.900`}
                          name="calendar"
                          w="12px"
                          h="12px"
                          mb={0.5}
                        />
                      }
                    >
                      <Text as="b">
                        {selectedMonth.isCurrent
                          ? `Current month`
                          : selectedMonth.humanReadable}
                      </Text>
                    </MenuButton>
                    <MenuList minWidth="100px">
                      {lastThreeAndCurrentMonths
                        .reverse()
                        .map((month, index) => (
                          <MenuItem
                            onClick={(e) => setSelectedMonth(month)}
                            key={index}
                          >
                            <Text fontSize="sm">
                              {month.isCurrent
                                ? `Current month`
                                : month.humanReadable}
                            </Text>
                          </MenuItem>
                        ))}
                    </MenuList>
                  </Menu>
                </Flex>
              )}
            </Flex>
          </Card.Header>
          <Card.Body
            isLoading={!data || isLoading || isFetching}
            isPreviewMode={previewMode}
            isSetupValid={isSetupValid}
            error={error}
          >
            <div className="flex flex-col bg-white">
              {!previewMode && !sharingMode && selectedMonth.isCurrent && (
                <CurrentPeriodWarning intervalType="month" />
              )}
              {data && data?.powerGroups?.length > 0 && (
                <div className="flex flex-col">
                  <Flex
                    direction="row"
                    align="center"
                    justify="flex-start"
                    h="350px"
                  >
                    <BarChart
                      categories={["powerGroups"]}
                      index="name"
                      data={transformData(data.powerGroups)}
                    >
                      <ChartTooltip
                        content={({ active, payload, label }) => (
                          <DefaultTooltip
                            label={label}
                            active={active}
                            payload={payload}
                            valueFormatter={(payload: IPowerGroupItem) =>
                              `${payload.groupsPercentage}%`
                            }
                            footerFormatter={(payload: IPowerGroupItem) =>
                              payload.groupsCount === 0
                                ? "No companies"
                                : `Click to see ${payload.groupsCount === 1 ? "1 company" : `all ${payload.groupsCount} companies`}`
                            }
                          />
                        )}
                      />
                      <Grid {...defaultGridProps} />
                      <BarItem
                        cursor={previewMode ? "auto" : "pointer"}
                        onClick={(data, index) => {
                          if (!previewMode && !sharingMode) {
                            onAudienceOpen();
                            setPowerGroupsCount(data.groupsCount);
                            setNumDays(index + 1);
                          }
                        }}
                        dataKey="groupsPercentage"
                      />
                      <XAxis {...defaultXAxisProps} dataKey="name" />
                      <YAxis
                        {...defaultYAxisProps}
                        tickFormatter={(value: string | number) => `${value}%`}
                      />
                    </BarChart>
                  </Flex>
                  {!screenshotMode && (
                    <Flex mb={4} mt={2} justify="center" align="center">
                      <Text color="gray.600" fontSize="sm">
                        Total active days within a month
                      </Text>
                      <Tooltip
                        label="Only companies that performed the events you picked in the set up are displayed here"
                        placement="right"
                      >
                        <Box ml={2}>
                          <Icon iconType="icon" name="question-purple" />
                        </Box>
                      </Tooltip>
                    </Flex>
                  )}
                </div>
              )}

              {!data && !previewMode && (
                <Skeleton borderRadius="lg">
                  <Flex direction="column" bg="white">
                    <Flex
                      borderTopRightRadius="lg"
                      borderTopLeftRadius="lg"
                      direction="column"
                      w="870px"
                      h="500px"
                      justify="center"
                    />
                  </Flex>
                </Skeleton>
              )}

              {!previewMode && !sharingMode && report && (
                <PowerGroupsList
                  report={report}
                  isOpen={isAudiencePanelOpen}
                  onClose={onAudienceClose}
                  daysOfUsage={numDays}
                  intervalLength={MONTH}
                  date={selectedMonth.date}
                  powerGroupsCount={powerGroupsCount}
                />
              )}
            </div>
          </Card.Body>
          {insight.description && !screenshotMode && (
            <Card.Footer bg="white">
              <Card.DescriptionAccordion insight={insight} />
            </Card.Footer>
          )}
        </Card.Container>
      )}
    </InsightCard>
  );
}

export default BarChartGraph;
