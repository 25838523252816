import { useEffect, useState } from "react";
import { cx } from "helpers/cx";
import { IAudience, IAudienceFilters } from "core/types/Audience";
import { AudienceFilters } from "core/modules/audience/Filters/AudienceFilters";
import { TwEditable } from "core/design-system/components/TwEditable";

interface IAudienceHeaderProps {
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  audienceFilters: IAudienceFilters;
  setAudienceFilters: (audienceFilters: IAudienceFilters) => void;
  audience: IAudience;
  name: string;
  setName: (value: string) => void;
  description: string | null;
  setDescription: (value: string) => void;
}

export const AudienceHeader: React.FC<IAudienceHeaderProps> = ({
  name,
  setName,
  description,
  setDescription,
  audienceFilters,
  setAudienceFilters,
}) => {
  const [isHoveringName, setIsHoveringName] = useState(false);
  const [isFocusedName, setIsFocusedName] = useState(false);
  const [isHoveringDescription, setIsHoveringDescription] = useState(false);
  const [isFocusedDescription, setIsFocusedDescription] = useState(false);

  const handleNameChange = (value: string) => {
    if (!value) return;
    setName(value);
  };

  const handleDescriptionChange = (value: string) => {
    if (!value) return;
    setDescription(value);
  };

  useEffect(() => {
    if (!isFocusedName) setIsHoveringName(false);
  }, [isFocusedName]);

  useEffect(() => {
    if (!isFocusedDescription) setIsHoveringDescription(false);
  }, [isFocusedDescription]);

  return (
    <>
      <div className={cx("mb-6 flex h-[60px] flex-col gap-2")}>
        <div className="h-[25px]">
          {isHoveringName ? (
            <TwEditable
              key={name}
              className="w-fit text-xl font-bold"
              inputClassName="font-bold text-xl px-0"
              defaultValue={name || ""}
              placeholder={"Untitled"}
              fontWidth={12}
              fontStyle="text-bold"
              onSubmit={handleNameChange}
              onMouseLeave={() => !isFocusedName && setIsHoveringName(false)}
              onFocus={() => setIsFocusedName(true)}
              onBlur={() => setIsFocusedName(false)}
            />
          ) : (
            <h1
              onMouseEnter={() => setIsHoveringName(true)}
              className="w-fit text-xl font-bold"
            >
              {name}
            </h1>
          )}
        </div>
        <div>
          {isHoveringDescription || !description ? (
            <TwEditable
              key={description}
              className="w-fit text-sm text-gray-700"
              inputClassName="text-sm text-gray-700"
              defaultValue={description || ""}
              placeholder={"Add a description"}
              maxLength={100}
              onSubmit={handleDescriptionChange}
              onMouseLeave={() =>
                !isFocusedDescription && setIsHoveringDescription(false)
              }
              onFocus={() => setIsFocusedDescription(true)}
              onBlur={() => setIsFocusedDescription(false)}
            />
          ) : (
            <p
              onMouseEnter={() => setIsHoveringDescription(true)}
              className={`w-fit text-sm text-gray-700`}
            >
              {description}
            </p>
          )}
        </div>
      </div>
      <AudienceFilters
        audienceFilters={audienceFilters}
        setAudienceFilters={setAudienceFilters}
      />
    </>
  );
};
