import { Navigate, useParams } from "react-router-dom";
import { Questions } from "modules/Onboarding/Questions";
import { DEMO_APP_ID } from "core/constants/appIds";

function Onboarding() {
  const { appId } = useParams<{ appId?: string }>();

  if (Number(appId) === DEMO_APP_ID) {
    return <Navigate to={`/a/${appId}/home`} />;
  }

  return <Questions />;
}

export default Onboarding;
