import { baseQuery } from "core/initializers/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";

interface IFeedbackParams {
  email: string;
  appId: number;
  feedback: string;
  url: string;
}

interface IFeedbackResponse {
  success: boolean;
}

export const feedbackApi = createApi({
  baseQuery,
  reducerPath: "feedbackApi",
  endpoints: (builder) => ({
    createFeedback: builder.mutation<IFeedbackResponse, IFeedbackParams>({
      query: ({ email, appId, feedback, url }) => ({
        url: `/feedbacks?app_id=${appId}`,
        method: "POST",
        params: {
          email,
          app_id: appId,
          feedback,
          url,
        },
      }),
    }),
  }),
});

export const { useCreateFeedbackMutation } = feedbackApi;
