import { Center, Spinner } from "@chakra-ui/react";

function Loader() {
  return (
    <Center h="100vh">
      <Spinner />
    </Center>
  );
}

export default Loader;
