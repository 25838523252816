import React from "react";

import { Box, ComponentDefaultProps, Flex } from "@chakra-ui/react";

export const PublicInsight: React.FC<ComponentDefaultProps> = ({
  children,
}) => {
  return (
    <Flex direction="column" minHeight="100vh" bg="whiteAlpha.900" pt={12}>
      <Flex
        ml={0}
        justify="center"
        align="center"
        direction="column"
        transition="margin-left 0.3s"
      />
      <Flex align="flex-start" justify="center">
        <Flex
          transition="margin-left 0.3s"
          ml={0}
          justify="center"
          direction="column"
          align="center"
          my={8}
        >
          <Box maxWidth="870px" width="80vw">
            <Box>{children}</Box>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PublicInsight;
