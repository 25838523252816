import { ComponentDefaultProps } from "@chakra-ui/react";

interface ICrmSyncSettingsTable extends ComponentDefaultProps {}

export const CrmSyncSettingsTable: React.FC<ICrmSyncSettingsTable> = ({
  children,
}) => {
  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th scope="col" className="w-[50px] py-3 pl-4 pr-3 text-left"></th>
          <th
            scope="col"
            className="w-[200px] py-3 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
          ></th>
          <th
            scope="col"
            className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
          >
            Create
          </th>
          <th
            scope="col"
            className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
          >
            Update
          </th>
          <th
            scope="col"
            className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
          >
            Frequency
          </th>
          <th
            scope="col"
            className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
          >
            Last synced at
          </th>
          <th
            scope="col"
            className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
          >
            Total records synced
          </th>
          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
            <span className="sr-only">Edit</span>
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">{children}</tbody>
    </table>
  );
};
