import React, { useEffect } from "react";
import { MatchingMappingRow } from "modules/Settings/Crm/SyncSettings/MatchingMappingRow";
import { MappingTable } from "modules/Integrations/MappingTable";
import { AddMapping } from "modules/Integrations/AddMapping";
import { Integration } from "core/models/dataMappings";
import { AppObjectType, IAppObject } from "core/models/appObjects";
import { useTraits } from "core/hooks/useTraits";
import { ITempDataMapping, useDataMappings } from "core/hooks/useDataMappings";
import { useDataMapping } from "core/hooks/useDataMapping";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useCrmProperties } from "core/hooks/useCrmProperties";
import { PreviewSyncContainer } from "./PreviewSyncContainer";

export const MappingData: React.FC<{
  appObject: IAppObject;
  integration: Integration;
}> = ({ appObject, integration }) => {
  const { id: appId } = useCurrentApp();
  const { traits } = useTraits(appObject.objectType);
  const {
    currentProperties,
    properties,
    onGetProperties,
    hasMore,
    isLoading,
    page,
    search,
    setSearch,
    setPage,
  } = useCrmProperties({
    appId,
    integration,
    objectType: appObject.objectType,
  });
  const { dataMappings, tempMappings, setTempMappings } = useDataMappings({
    appId,
    integration,
    appObject,
  });

  const {
    createMapping,
    updateMapping,
    deleteMapping,
    matchingIdentifierDataMapping,
  } = useDataMapping({
    appId,
    integration,
    appObject,
  });

  const selectedTraits = dataMappings.map((mapping) => mapping.source);
  const availableTraits = traits?.filter(
    (trait) =>
      !selectedTraits.includes(trait.trait) &&
      !tempMappings.map((m) => m.source).includes(trait.trait) &&
      matchingIdentifierDataMapping?.source !== trait.trait,
  );

  useEffect(() => {
    window.addEventListener("visibilitychange", onGetProperties);

    return () => {
      window.removeEventListener("visibilitychange", onGetProperties);
    };
  }, []);

  return (
    <>
      <div className="my-2">
        {matchingIdentifierDataMapping && (
          <MatchingMappingRow
            appObject={appObject}
            integration={integration}
            matchingIdentifierDataMapping={matchingIdentifierDataMapping}
          />
        )}
      </div>

      <div className="mt-12 flex w-full gap-10">
        <div className="flex w-full flex-col">
          {traits && (
            <MappingTable
              integration={integration}
              isLoading={isLoading}
              traits={traits}
              tempMappings={tempMappings}
              currentProperties={currentProperties}
              properties={properties}
              page={page}
              search={search}
              setSearch={setSearch}
              setPage={setPage}
              hasMore={hasMore}
              dataMappings={dataMappings}
              deleteTempMapping={(trait: string) =>
                setTempMappings(tempMappings.filter((m) => m.source !== trait))
              }
              createMapping={createMapping}
              updateMapping={updateMapping}
              deleteMapping={(params) => {
                const mapping = dataMappings.find((dm) => dm.id === params.id);

                if (mapping)
                  setTempMappings(
                    tempMappings.filter((m) => m.source !== mapping.source),
                  );

                deleteMapping(params);
              }}
              appObject={appObject}
            />
          )}
          <div className="flex">
            {availableTraits && (
              <AddMapping
                addMapping={(tmpDataMapping: ITempDataMapping) => {
                  setTempMappings([...tempMappings, tmpDataMapping]);
                }}
                traits={availableTraits}
                showGroupIdOption={appObject.objectType === AppObjectType.Group}
                groupIdOptionDisabled={
                  tempMappings.some((m) => m.source === "group_id") ||
                  dataMappings.some((m) => m.source === "group_id")
                }
              />
            )}
          </div>
        </div>
        <div className="min-w-[400px]">
          <PreviewSyncContainer
            appObject={appObject}
            integration={integration}
            dataMappings={dataMappings}
          />
        </div>
      </div>
    </>
  );
};
