import { useParams } from "react-router-dom";
import { SyntheticEvent, useEffect, useState } from "react";
import { IViewInsightContainer } from "modules/ViewInsight/ViewInsight";
import { ViewInsightCard } from "modules/ViewInsight/CardContainer";
import {
  useAddViewInsightMutation,
  useDeleteViewInsightMutation,
} from "core/models/viewInsights";
import { useViews } from "core/hooks/useViews";
import { useToast } from "core/hooks/useToast";
import { useQueries } from "core/hooks/useQueries";
import { REPORT_CONFIGS } from "core/constants/report-configurations";
import { SimpleTable } from "core/components/ViewInsight/Graphs/SimpleTable";
import { SimpleBarChart } from "core/components/ViewInsight/Graphs/SimpleBarChart";
import { SimpleAreaChart } from "core/components/ViewInsight/Graphs/SimpleAreaChart";
import {
  getInitialXKey,
  getInitialYKey,
} from "core/components/AI/Graph/Result";
import { formatValue } from "core/components/AI/Graph/Graph";

export function getReportConfigByType(reportType: number) {
  return REPORT_CONFIGS.find((config) => config.reportType === reportType);
}

export const AIViewInsightContainer: React.FC<IViewInsightContainer> = ({
  location,
  viewInsight,
  query,
  isEditing,
  isAdding,
  isAdded,
  viewId,
  isBlurred,
}) => {
  const { reachedLimit } = useViews({ location });
  const toast = useToast();
  const { appId, viewId: paramViewId, groupId } = useParams();
  const _viewId = viewId ? viewId : paramViewId;
  const [showHighlightDescription, setShowHighlightDescription] =
    useState(false);
  const [deleteViewInsight] = useDeleteViewInsightMutation();
  const [addViewInsight] = useAddViewInsightMutation();
  const { queryResult, executeQuery, isExecutingQuery } = useQueries(
    viewInsight.queryId,
  );

  useEffect(() => {
    if (query)
      executeQuery({ appId: Number(appId), query: query.query, page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  function transformGraphData() {
    if (!queryResult) return [];

    return queryResult?.result?.data?.map(
      (point: { [key: string]: string }) => {
        return {
          x: formatValue(point[getInitialXKey(Object.keys(point), query)]),
          y: formatValue(point[getInitialYKey(Object.keys(point), query)]),
        };
      },
    );
  }

  return (
    <ViewInsightCard
      viewInsight={viewInsight}
      isEditing={isEditing}
      isAI
      isAdding={isAdding}
      isAdded={isAdded}
      isBlurred={isBlurred}
      isPaywalled={false}
      isEmpty={Boolean((queryResult?.pagy?.items || 0) === 0)}
      groupId={groupId}
      description={viewInsight.insight.reportName || ""}
      showHighlightDescription={showHighlightDescription}
      title={viewInsight.insight.name}
      isLoading={isExecutingQuery}
      timerange={""}
      reachedLimit={reachedLimit}
      onMouseEnter={() => setShowHighlightDescription(true)}
      onMouseLeave={() => setShowHighlightDescription(false)}
      onDelete={(e: SyntheticEvent) => {
        e.preventDefault();
        deleteViewInsight({
          id: viewInsight.id,
          appId: viewInsight.appId,
        }).then(() => {
          toast({
            title: "Insight removed",
            description: `Insight "${viewInsight.insight.name}" has been removed from the view.`,
            status: "success",
          });
        });
      }}
      onAdd={(e: SyntheticEvent) => {
        e.preventDefault();
        addViewInsight({
          appId: Number(appId),
          viewId: Number(_viewId),
          reportId: Number(viewInsight.reportId),
          queryId: Number(viewInsight.queryId),
        }).then(() => {
          toast({
            title: "Insight added",
            description: `Insight "${viewInsight.insight.name}" has been added to the view.`,
            status: "success",
          });
        });
      }}
    >
      {query?.graphType === "list" && (
        <SimpleTable
          columns={queryResult?.result?.columns}
          data={queryResult?.result?.data}
        />
      )}
      {query?.graphType === "area_chart" && (
        <SimpleAreaChart data={transformGraphData()} />
      )}
      {query?.graphType === "bar_chart" && (
        <SimpleBarChart data={transformGraphData()} />
      )}
    </ViewInsightCard>
  );
};
