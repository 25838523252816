import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { IHomeFeature } from "core/types/HomeFeature";
import { useGetHomeFeatureListQuery } from "core/models/home";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import FEATURE_RELEASE_CONFIG from "core/constants/report-configurations/report-types/featureRelease";
import { ADOPTION_PERCENTAGE, DESC } from "core/constants/featureList";
import { Drilldown } from "core/components/Drilldown";
import { Button, Center, Spinner } from "@chakra-ui/react";
import { Table } from "./Table";

export interface IFeatureDrilldown {
  isOpen: boolean;
  onClose: () => void;
}

export const FeatureDrilldown: React.FC<IFeatureDrilldown> = ({
  isOpen,
  onClose,
}) => {
  const { id: appId } = useCurrentApp();
  const [column, setColumn] = useState(ADOPTION_PERCENTAGE);
  const [order, setOrder] = useState(DESC);
  const [page, setPage] = useState(1);
  const [features, setFeatures] = useState<IHomeFeature[]>([]);
  const { data: featuresData, isFetching } = useGetHomeFeatureListQuery({
    appId,
    page,
    items: 10,
    column,
    order,
  });

  const loadMoreFeatures = () => {
    setPage((prevPage) => prevPage + 1);
  };

  function onSort(newColumn?: string, newOrder?: "desc" | "asc") {
    if (newColumn) setColumn(newColumn);
    if (newOrder) setOrder(newOrder);
  }

  useEffect(() => {
    if (featuresData?.features && page > 1)
      setFeatures([...features, ...featuresData.features]);
    if (featuresData?.features && page === 1)
      setFeatures(featuresData.features);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuresData]);

  useEffect(() => {
    if (featuresData?.features) {
      setFeatures(featuresData.features);
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column, order]);

  return (
    <Drilldown
      isOpen={isOpen}
      onClose={onClose}
      size="xxl"
      px={6}
      pb={6}
      pt={6}
    >
      {(Drilldown) => (
        <>
          <Drilldown.Header className="px-6">
            <Drilldown.Title
              title="Features"
              description="A comparison based on all your feature reports"
              isLoading={false}
            />
            <Button
              colorScheme="purple"
              mt={4}
              onClick={onClose}
              as={Link}
              to={`/a/${appId}/report/create/${FEATURE_RELEASE_CONFIG.reportType}`}
            >
              Create feature report
            </Button>
          </Drilldown.Header>
          <Drilldown.Body>
            {features.length > 0 ? (
              <Table
                featuresData={features}
                onSort={onSort}
                column={column}
                order={order}
              />
            ) : (
              <Center w="100%">
                <Spinner />
              </Center>
            )}
            {featuresData && featuresData?.pagination.pages > page && (
              <Center>
                <Button
                  isLoading={isFetching}
                  onClick={loadMoreFeatures}
                  mt={4}
                  mb={4}
                >
                  Load More
                </Button>
              </Center>
            )}
          </Drilldown.Body>
        </>
      )}
    </Drilldown>
  );
};
