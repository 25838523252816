import { IReportConfig } from "core/types/Report";
import { DEFAULT_GROUPED_OPTIONS } from "core/constants/timerange";
import { USER } from "core/components/UserTypeMenu/constants";

const defaultTemplateConfig = {
  route: "audit",
  template: null,
  isPopular: false,
  requiredEvents: ["Core events for your product"],
  userTypes: [USER],
  validation: {
    audience: {
      isValid: () => true,
      validationHeaderText:
        "Are you sure you wish to proceed without refining your audience?",
      validationBodyText:
        "Feature adoption will be measured against all of your users, which may make adoption very low.",
      proceedButtonText: `I'm sure`,
      goBackButtonText: `Let me choose my audience`,
    },
  },
  hasSmartEvents: false,
  hasAudienceSection: true,
  hasDateRangeSection: false,
  dateRangeOptions: DEFAULT_GROUPED_OPTIONS,
  validateSetup: (config: IReportConfig) =>
    config &&
    config?.events &&
    config?.events?.length > 0 &&
    config?.events?.some((e) => e.name),
};

export default defaultTemplateConfig;
