import { GridItem } from "@chakra-ui/react";
import { useInView } from "framer-motion";
import { useRef } from "react";

export const withInView = (Component: React.FC<any>, extraProps?: any) => {
  return ({ ...props }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    return (
      <GridItem colSpan={2} w="full" ref={ref} {...extraProps}>
        {isInView && <Component {...props} />}
      </GridItem>
    );
  };
};
