import colors from "core/design-system/constants/theme/colors";
import TickText from "core/design-system/charts/TickText";
import {
  CommonGrid,
  CommonLegend,
  CommonTooltip,
  CommonXAxis,
  CommonYAxis,
} from "core/design-system/charts/Common";
import { generateHexColorForTraitValueWithIndex } from "core/design-system/charts/Breakdown/utils";
import { Area, AreaChart } from "core/design-system/charts/AreaChart";
import { Flex } from "@chakra-ui/react";
import { ConversionLineChartTooltip } from "./ConversionLineChartTooltip";
import { ConversionLineChartLegend } from "./ConversionLineChartLegend";

interface IConversionLineChart {
  data: any;
  showGrid?: boolean;
  showYAxis?: boolean;
  hasBreakdown?: boolean;
}

export const ConversionLineChart: React.FC<IConversionLineChart> = ({
  data,
  showYAxis,
  showGrid = true,
  hasBreakdown = false,
}) => {
  const traitValues = data?.[0]?.traitValues ?? [];

  const formattedData = data.map((row: any) => {
    return {
      ...row,
      x: row.humanizedDate,
      date: row.date,
    };
  });

  return (
    <Flex direction="column" justify="center" h={300}>
      <AreaChart
        margin={{
          top: 30,
          left: !hasBreakdown ? 0 : 50,
          right: 50,
          bottom: 20,
        }}
        height="100%"
        data={formattedData}
      >
        {showGrid && <CommonGrid />}
        {/* @ts-ignore */}
        <CommonXAxis dataKey="x" />
        {!hasBreakdown && (
          // @ts-ignore
          <CommonYAxis
            tick={({ x, ...props }: { x: number; payload: any }) =>
              showYAxis && (
                <TickText x={x - 5} {...props}>
                  {props.payload.value} %
                </TickText>
              )
            }
          />
        )}
        {/* @ts-ignore */}
        <CommonTooltip
          content={
            // @ts-ignore
            <ConversionLineChartTooltip
              hasBreakdown={hasBreakdown}
              traitValues={traitValues}
            />
          }
        />
        {hasBreakdown ? (
          <>
            {/* @ts-ignore */}
            <CommonLegend
              content={<ConversionLineChartLegend traitValues={traitValues} />}
            />
            {traitValues
              .slice()
              .reverse()
              .map((breakdownTrait: string, index: number) => (
                <Area
                  stackId="1"
                  name={breakdownTrait}
                  dataKey={`conversionPercentage${traitValues.length - index - 1}`}
                  fill={generateHexColorForTraitValueWithIndex(
                    breakdownTrait,
                    index,
                  )}
                  stroke={generateHexColorForTraitValueWithIndex(
                    breakdownTrait,
                    index,
                  )}
                />
              ))}
          </>
        ) : (
          <Area
            type="monotone"
            fillOpacity={1}
            dataKey="conversionPercentage"
            fill={colors.purple[400]}
            stroke={colors.purple[400]}
          />
        )}
      </AreaChart>
    </Flex>
  );
};
