import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { WorkspaceName } from "modules/Settings/WorkspaceName";
import humps from "humps";
import { updateApp } from "core/models/app";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { TIME_ZONES } from "core/constants/features";
import axios from "axios";
import { WorkspaceLogo } from "./WorkspaceLogo";
import { ITimezone, SelectTimezone } from "./SelectTimezone";

export const WorkspaceSettings = () => {
  const currentApp = useCurrentApp();
  const appId = currentApp.id;
  const dispatch = useDispatch();

  const [timezones, setTimezones] = useState<ITimezone[]>([]);

  useEffect(() => {
    const fetchTimezones = async () => {
      const response = await axios.get(
        `${import.meta.env.VITE_API_HOST}/apps/${appId}/list_timezones`,
        {
          withCredentials: true,
        },
      );

      if (response?.data?.timezones) {
        setTimezones(
          response.data.timezones.map((element: any) =>
            humps.camelizeKeys(element),
          ),
        );
      }
    };

    fetchTimezones();
  }, [appId]);

  const DEFAULT_TIMEZONE = {
    value: currentApp.timeZone,
    label: currentApp.timeZone.replace("_", " "),
    utcOffset: currentApp.utcOffset,
  };

  const updateTimeZone = (timeZone: string) => {
    dispatch(updateApp({ appId: currentApp.id, timeZone }));
  };

  return (
    <>
      <h2 className="text-base font-semibold text-gray-900">
        Workspace settings
      </h2>
      <div className="pb-4">
        <div className="flex flex-col gap-5">
          <WorkspaceName />
          <WorkspaceLogo />
          {currentApp.features.includes(TIME_ZONES) && (
            <div className="flex w-full justify-between">
              <div className="flex flex-col">
                <div className="mb-1 text-sm font-semibold">Time zone</div>
                <div className="text-xs">
                  Changing time zone will affect reports across June.
                </div>
              </div>
              <SelectTimezone
                options={timezones}
                defaultOption={DEFAULT_TIMEZONE}
                onChange={updateTimeZone}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
