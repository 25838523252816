import { useState } from "react";
import { IGroup } from "core/types/Group";
import { useGetCompaniesQuery } from "core/models/companies";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { Lifecycle } from "core/design-system/components/Command/Lifecycle";
import {
  Command,
  CommandInput,
  CommandList,
  CommandOption,
} from "core/design-system/components/Command";
import { AND } from "core/constants/report-setup";
import { PlusIcon } from "@heroicons/react/24/solid";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { Button, Spinner, Tooltip } from "@chakra-ui/react";

interface ISearchCompanyActionProps {
  targetGroupId: string;
  setSourceGroupId: (sourceGroupId: string) => void;
}

export const SearchCompanyAction: React.FC<ISearchCompanyActionProps> = ({
  targetGroupId,
  setSourceGroupId,
}) => {
  const { id: appId } = useCurrentApp();
  const [searchValue, setSearchValue] = useState("");
  const {
    data: companiesData,
    isLoading,
    isFetching,
  } = useGetCompaniesQuery({
    appId,
    page: 1,
    audienceFilters: {
      filterGroups: [],
      joinOperator: AND,
    },
    searchQuery: searchValue,
  });
  const companies = companiesData?.companies || [];

  return (
    <div className="flex items-center justify-center">
      <Popover className="relative">
        <PopoverButton>
          <Tooltip hasArrow label="Select company" placement="top">
            <Button variant="ghost" color={"gray.600"}>
              <PlusIcon className="size-5" />
            </Button>
          </Tooltip>
        </PopoverButton>
        <PopoverPanel>
          {({ close }) => (
            <>
              <Lifecycle onUnmount={() => setSearchValue("")} />
              <Command>
                <CommandInput
                  placeholder="Search"
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                />
                <CommandList>
                  {isLoading || isFetching ? (
                    <div className="flex items-center justify-center">
                      <Spinner size="sm" />
                    </div>
                  ) : (
                    <>
                      {companies.length === 0 && (
                        <span className="px-2 py-1 sm:leading-4">
                          No companies found
                        </span>
                      )}
                      {companies.map((item: IGroup, index: number) => {
                        if (item.id === targetGroupId) return <></>;

                        return (
                          <CommandOption
                            key={index}
                            value={() => {
                              setSourceGroupId(item.id);
                              close();
                            }}
                          >
                            <div className="w-full">
                              <span className="line-clamp-1 text-ellipsis sm:leading-4">
                                {item.id}
                              </span>
                            </div>
                          </CommandOption>
                        );
                      })}
                    </>
                  )}
                </CommandList>
              </Command>
            </>
          )}
        </PopoverPanel>
      </Popover>
    </div>
  );
};
