import { Center } from "@chakra-ui/react";
import { ConversationContainer } from "./ConversationContainer";

export const AIContainer: React.FC = () => {
  return (
    <Center w="full" data-testid="ai-container">
      <ConversationContainer />
    </Center>
  );
};
