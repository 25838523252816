import { ComponentDefaultProps, Tooltip } from "@chakra-ui/react";

interface ITraitsPopover extends ComponentDefaultProps {
  traits: string;
}

export const TraitsPopover: React.FC<ITraitsPopover> = ({ traits }) => {
  return (
    <Tooltip
      hasArrow
      maxW="full"
      label={
        <pre className="block w-full text-xs">
          {JSON.stringify(traits, null, 2)}
        </pre>
      }
    >
      <p className="inline underline">traits</p>
    </Tooltip>
  );
};
