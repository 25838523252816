import { FixedSizeList } from "react-window";
import { IFilterOption, searchFilters } from "helpers/searchFilters";
import { TraitOption } from "core/modules/audience/Filters/TraitOption";
import { EventFilterOption } from "core/modules/audience/Filters/EventFilterOption";
import { IFilterMenuProps } from "core/modules/audience/Filters/AddFilterButton";
import { ITraitKeyResponse } from "core/models/traits";
import { FilterType } from "core/constants/report-setup";
import { DIVIDER } from "core/constants/filters";
import { ComboboxOption } from "@headlessui/react";

export const TraitOptionsList = ({
  traits,
  searchTrait,
  onAddTraitFilter,
  onAddEventFilter,
  isLoading,
  filterTypeNames,
  isEventFilterDisabled,
  testId,
  onSelectTrait,
}: Pick<
  IFilterMenuProps,
  "onAddTraitFilter" | "onAddEventFilter" | "isEventFilterDisabled"
> & {
  traits: ITraitKeyResponse[];
  isLoading: boolean;
  testId?: string;
  searchTrait: string;
  onSelectTrait?: (trait: ITraitKeyResponse) => void;
  filterTypeNames?: { [x: number]: string | undefined };
}) => {
  if (isLoading) return <></>;

  const availableFilters = [
    { trait: "Event performed", filterType: FilterType.EventFilter },
    ...(traits || []),
  ] as IFilterOption[];

  const visibleFilters = searchFilters(availableFilters, searchTrait);

  if (visibleFilters.length === 0)
    return (
      <p data-testid={testId} className="px-3 py-2 text-xs font-medium">
        No filters found
      </p>
    );

  let lastFilterType: FilterType | undefined;
  return (
    <FixedSizeList
      data-testid={testId}
      height={210}
      itemCount={visibleFilters.length}
      itemSize={30}
      width="100%"
    >
      {({ index, style }: { index: number; style: object }) => {
        const trait = visibleFilters[index] as ITraitKeyResponse;
        lastFilterType = trait.filterType as number;
        const filterTypeName =
          filterTypeNames && filterTypeNames[lastFilterType];

        return (
          <>
            <div className="w-full" style={{ ...style }}>
              {trait?.filterType === FilterType.EventFilter ? (
                <EventFilterOption
                  onAddEventFilter={
                    onAddEventFilter ? onAddEventFilter : () => {}
                  }
                  isEventFilterDisabled={isEventFilterDisabled}
                />
              ) : (
                <div className="w-full">
                  {trait?.filterType === DIVIDER ? (
                    <ComboboxOption value={() => {}} disabled={true}>
                      <p className="flex px-2 pb-2 pt-2 text-xs font-medium text-gray-600">
                        {trait.trait}
                      </p>
                    </ComboboxOption>
                  ) : (
                    <TraitOption
                      testId={`audience-${filterTypeName?.toLocaleLowerCase()}-trait-option-${index}`}
                      onClick={() => {
                        console.log("on click called");
                        if (onSelectTrait) onSelectTrait(trait);

                        trait?.filterType !== undefined &&
                          onAddTraitFilter &&
                          onAddTraitFilter(trait.filterType, trait.trait);
                      }}
                      trait={trait}
                    />
                  )}
                </div>
              )}
            </div>
          </>
        );
      }}
    </FixedSizeList>
  );
};
