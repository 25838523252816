import { cx } from "helpers/cx";

export const WrapArrow: React.FC<{
  direction?: "right" | "left";
  position?: string;
}> = ({ direction = "right", position = "20.5" }) => {
  return (
    <div
      className={cx(
        "absolute -z-[0]",
        direction === "right" && `rotate-90`,
        direction === "left" && `-rotate-90`,
      )}
      style={{
        right: direction === "right" ? `-${position}px` : "inherit",
        left: direction === "left" ? `-${position}px` : "inherit",
      }}
    >
      <div
        className="hover:border-transparent"
        style={{
          width: 0,
          height: 0,
          borderLeft: "16.8px solid transparent",
          borderRight: "16.8px solid transparent",
          borderBottom: "8px solid #f8f9fa",
        }}
      />
    </div>
  );
};
