import { SnakeCasedProperties } from "type-fest";
import humps from "humps";
import { baseQuery } from "core/initializers/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";

export const paymentApi = createApi({
  baseQuery,
  reducerPath: "paymentApi",
  tagTypes: ["Payment"],
  endpoints: (builder) => ({
    fetchBillingPortal: builder.query({
      query: (appId: number) => ({
        url: "/payments/portal",
        params: humps.decamelizeKeys({ appId }),
      }),
      transformResponse: (
        response: SnakeCasedProperties<{ sessionUrl: string }>,
      ): { sessionUrl: string } =>
        humps.camelizeKeys(response) as { sessionUrl: string },
    }),
  }),
});

export const { useFetchBillingPortalQuery, useLazyFetchBillingPortalQuery } =
  paymentApi;
