import { ILabel } from "core/types/Label";
import Icon from "core/design-system/components/Icon";
import {
  ISortedByOption,
  SORTED_BY_OPTIONS_ARRAY,
} from "core/constants/sortFilters";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { SelectSortedBy } from "./SelectSortedBy";
import { SelectReportTypes } from "./SelectReportType";
import { SelectLabels } from "./SelectLabels";

interface IReportsListHeaderProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  sortedBy: ISortedByOption;
  setSortFilter: (sortFilter: ISortedByOption) => void;
  labels: ILabel[];
  selectedLabels: ILabel[];
  setSelectedLabels: (labels: ILabel[]) => void;
  reportTypes: number[];
  selectedReportTypes: number[];
  setSelectedReportTypes: (reportTypes: number[]) => void;
}

export const ReportsListHeader: React.FC<IReportsListHeaderProps> = ({
  searchQuery,
  setSearchQuery,
  sortedBy,
  setSortFilter,
  labels,
  selectedLabels,
  setSelectedLabels,
  reportTypes,
  selectedReportTypes,
  setSelectedReportTypes,
}) => {
  return (
    <Flex mb={5}>
      <InputGroup>
        <InputLeftElement h="32px">
          <Icon iconType="icon" name="search" color="blackAlpha.500" />
        </InputLeftElement>
        <Input
          size="sm"
          borderRadius="lg"
          focusBorderColor="primary"
          placeholder="Find a report by name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </InputGroup>
      <Box ml={2}>
        <SelectReportTypes
          reportTypes={reportTypes}
          selectedReportTypes={selectedReportTypes}
          setSelectedReportTypes={setSelectedReportTypes}
        />
      </Box>
      <Box ml={2}>
        <SelectLabels
          labels={labels}
          selectedLabels={selectedLabels}
          setSelectedLabels={setSelectedLabels}
        />
      </Box>
      <Box ml={2}>
        <SelectSortedBy
          sortedBy={sortedBy}
          setSortedBy={(sortedBy: ISortedByOption) => {
            setSortFilter(sortedBy);
            const sortFilterIndex = SORTED_BY_OPTIONS_ARRAY.findIndex(
              (s) => s.value === sortedBy.value,
            );
            localStorage.setItem(
              "reportsInitialSortFilter",
              String(sortFilterIndex),
            );
          }}
        />
      </Box>
    </Flex>
  );
};
