import { useHubspotSettings } from "core/hooks/useHubspotSettings";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { trackEvent } from "core/helpers/trackEvent";
import HubspotIcon from "core/design-system/components/Icon/Interface/HubspotIcon";
import { ArrowsRightLeftIcon } from "@heroicons/react/24/outline";
import { Button, Flex, Spinner, Text, useDisclosure } from "@chakra-ui/react";
import * as Integration from "modules/Settings/Integrations/Integration";
import { UninstallModal } from "modules/Settings/Integrations/Hubspot/UninstallModal";
import { IntegrationMetadata } from "modules/Settings/Integrations/Integration";
import { Install } from "./Hubspot/Install";
import { Settings } from "modules/Settings/Integrations/Hubspot/Settings";

const CONTENT: IntegrationMetadata = {
  title: "HubSpot",
  description: "Send your June data to HubSpot",
  logoComponent: <HubspotIcon color="#ff7a59" w="full" h="full" />,
  docsUrl: "https://help.june.so/en/articles/6782394-hubspot-integration",
  isBeta: false,
};

export const PaywallUpgradeHeader: React.FC = () => {
  return (
    <Text fontWeight="bold" fontSize="2xl">
      Get access to the HubSpot
    </Text>
  );
};

export const PaywallUpgradeSubHeader: React.FC = () => {
  return (
    <Text color="gray.700" fontSize="md" fontWeight="normal" px={10}>
      Upgrade to start using the{" "}
      <Text as="span" fontWeight="bold">
        HubSpot integration
      </Text>{" "}
      and with June
    </Text>
  );
};

const Action = ({
  hasHubspotInstalled,
  onOpenConfirmation,
  isConfirmationOpen,
  onCloseConfirmation,
  onDeleteSettings,
  id,
}: {
  hasHubspotInstalled: boolean;
  onOpenConfirmation: () => void;
  isConfirmationOpen: boolean;
  onCloseConfirmation: () => void;
  onDeleteSettings: () => void;
  id: number;
}) => {
  return (
    <Flex>
      <Flex align="center" gridGap={2}>
        {!hasHubspotInstalled ? (
          <Button
            onClick={() => {
              trackEvent({ eventName: "clicked_install_hubspot", appId: id });
              window.location.replace(
                `${import.meta.env.VITE_API_HOST}/auth/hubspot/install`,
              );
            }}
            colorScheme="purple"
          >
            Connect HubSpot
          </Button>
        ) : (
          <>
            <Button onClick={onOpenConfirmation} colorScheme="red">
              Uninstall
            </Button>
            <UninstallModal
              isOpen={isConfirmationOpen}
              onClose={onCloseConfirmation}
              onUninstall={() => {
                trackEvent({
                  eventName: "clicked_hubspot_uninstall",
                  appId: id,
                });
                onDeleteSettings();
              }}
            />
          </>
        )}
      </Flex>
    </Flex>
  );
};

const Hubspot: React.FC = () => {
  const { id } = useCurrentApp();
  const { onDeleteSettings, hasHubspotInstalled, isLoadingSetting } =
    useHubspotSettings();
  const {
    isOpen: isConfirmationOpen,
    onClose: onCloseConfirmation,
    onOpen: onOpenConfirmation,
  } = useDisclosure();

  return (
    <>
      <Integration.Container isExpanded>
        <Integration.Header
          metadata={CONTENT}
          ActionComponent={() => (
            <Action
              {...{
                hasHubspotInstalled,
                onOpenConfirmation,
                isConfirmationOpen,
                onCloseConfirmation,
                onDeleteSettings,
                id,
              }}
            />
          )}
        />
        <Integration.Body>
          <Integration.Section>
            {isLoadingSetting && <Spinner />}
            {hasHubspotInstalled && <Settings />}
            {!hasHubspotInstalled && (
              <Integration.Collapsable
                title={"Connect your HubSpot account"}
                headerIcon={<ArrowsRightLeftIcon width={20} height={20} />}
              >
                <Install />
              </Integration.Collapsable>
            )}
          </Integration.Section>
        </Integration.Body>
      </Integration.Container>
    </>
  );
};

export default Hubspot;
