import moment from "moment";
import { IGroup } from "core/types/Group";
import { useClearbitLogo } from "core/hooks/useClearbitLogo";
import { isValidUrl } from "core/helpers/isValidUrl";
import { getDomainFromEmails } from "core/helpers/domainFromEmails";
import {
  generateRandomAnimalName,
  getContactAvatar,
  hashStringToNumber,
  IGetContactAvatarProps,
} from "core/helpers/contactAvatar";
import AvatarIcon from "core/design-system/components/Icon/AvatarIcon";
import Icon from "core/design-system/components/Icon";
import { TraitIcon } from "core/components/Traits/TraitIcon";
import { ClockIcon, UsersIcon } from "@heroicons/react/24/outline";
import { useAppObjects } from "core/hooks/useAppObjects";

interface IGroupPreview {
  group: IGroup;
}

export const GroupPreview: React.FC<IGroupPreview> = ({ group }) => {
  const { groupAppObject } = useAppObjects();
  const avatarURL = getContactAvatar(
    group as unknown as IGetContactAvatarProps,
  );
  const groupId =
    typeof group?.id === "number" ? group?.id : hashStringToNumber(group?.id);
  const domain = getDomainFromEmails(group?.userEmails);
  const logoURL = useClearbitLogo({ domain });
  const url = isValidUrl(avatarURL) ? avatarURL : logoURL;

  return (
    <div className="hidden h-112 w-1/2 flex-none flex-col overflow-y-auto py-4 sm:flex">
      <div className="flex-none p-6 text-center">
        {url ? (
          <img src={url} alt="" className="mx-auto h-16 w-16 rounded-full" />
        ) : (
          <AvatarIcon h={16} w={16} name={generateRandomAnimalName(groupId)} />
        )}
        <h2 className="mt-3 text-lg font-semibold text-gray-900">
          {group.name}
        </h2>
      </div>
      <div className="flex flex-auto flex-col justify-between px-6">
        <table className="w-full table-fixed">
          <tbody>
            <tr>
              <td className="w-1/2 truncate py-1 text-sm text-gray-600">
                <div className="flex items-center gap-1">
                  <UsersIcon className="h-4" />
                  Total users
                </div>
              </td>
              <td className="w-1/2 truncate py-1 text-sm">
                {group?.userCount || 0}
              </td>
            </tr>
            <tr>
              <td className="w-1/2 truncate py-1 text-sm text-gray-600">
                <div className="flex items-center gap-1">
                  <ClockIcon className="h-4" />
                  First seen
                </div>
              </td>
              <td className="w-1/2 truncate py-1 text-sm">
                {group?.firstSeenAt
                  ? moment.utc(group?.firstSeenAt).format("DD MMM, YYYY")
                  : "N/A"}
              </td>
            </tr>
            <tr>
              <td className="w-1/2 truncate py-1 text-sm text-gray-600">
                <div className="flex items-center gap-1">
                  <ClockIcon className="h-4" />
                  Last seen
                </div>
              </td>
              <td className="w-1/2 truncate py-1 text-sm">
                {group?.lastSeenAt
                  ? moment.utc(group?.lastSeenAt).format("DD MMM, YYYY")
                  : "N/A"}
              </td>
            </tr>
            {Object.entries(group?.traits)
              .slice(0, 4)
              .map(([key, value]) => {
                return (
                  <>
                    <tr>
                      <td className="w-1/2 py-1 text-sm text-gray-600">
                        <div className="flex items-center gap-1">
                          <TraitIcon traitName={key} />
                          <div className="w-5/6 truncate">{key}</div>
                        </div>
                      </td>
                      <td className="w-1/2 truncate py-1 text-sm">{value}</td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
        <button
          type="button"
          className="mt-6 w-full rounded-md bg-purple-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          View {groupAppObject?.singularName.toLowerCase()}
          <Icon name="enter" iconType="icon" ml={2} />
        </button>
      </div>
    </div>
  );
};
