import React from "react";
import { IInsightSection } from "core/types/TemplateConfig";
import { IReportInsight } from "core/types/Report";
import ScatterPlotGraph from "core/modules/reports/report-types/FeatureAudit/ScatterPlotGraph";
import GraphInsights from "core/modules/reports/report-types/FeatureAudit/GraphInsights";
import useInsightDataFetching from "core/hooks/useInsightDataFetching";
import { EventType } from "core/constants/report-setup";
import InsightCard from "core/components/InsightCard/Index";

const mockData = [
  { name: "templates_opened", index: 0, frequency: 60, popularity: 25 },
  { name: "feed_opened", index: 1, frequency: 70, popularity: 38 },
  { name: "events_opened", index: 2, frequency: 80, popularity: 40.4 },
  { name: "reports_opened", index: 3, frequency: 90, popularity: 95.4 },
  { name: "templates_opened2", index: 4, frequency: 60, popularity: 25 },
  { name: "feed_opened2", index: 5, frequency: 30, popularity: 33 },
  { name: "events_opened2", index: 6, frequency: 10, popularity: 40.4 },
  { name: "reports_opened2", index: 7, frequency: 50, popularity: 95.4 },
  { name: "events_opened3", index: 8, frequency: 30, popularity: 70.4 },
  { name: "reports_opened3", index: 9, frequency: 50, popularity: 15.4 },
];

export interface IFeatureAuditData {
  data: {
    name: string;
    index: number;
    frequency: number;
    popularity: number;
    type: EventType;
  }[];
}

const GraphContainer: React.FC<IReportInsight> = ({
  report,
  sharingSecretToken,
  previewMode,
  sharingMode,
  config,
  screenshotMode,
}) => {
  const insight = config.insights.find(
    ({ slug }: { slug: string }) => slug === "popularity-and-frequency",
  ) as IInsightSection;
  const isSetupValid = config.validateSetup(report.config);
  const { response, isLoading, isFetching, error, onRefreshInsight } =
    useInsightDataFetching({
      report,
      insight,
      sharingSecretToken,
      previewMode,
      skip: !isSetupValid,
    });

  const data = previewMode ? mockData : response?.data;

  if (!insight) return null;

  const noData = data?.length === 0;

  return (
    <InsightCard>
      {(Card) => (
        <Card.Container insight={insight} id={insight.slug}>
          <Card.Header
            hasCaching={response?.hasCaching}
            screenshotMode={screenshotMode}
            showReportTitle={sharingMode}
            insight={insight}
            showActions={!sharingMode && !previewMode}
            sharingMode={sharingMode}
            config={config}
            report={report}
            refresh={onRefreshInsight}
            cachedAt={response?.cachedAt}
          />
          <Card.Body
            insight={insight}
            isLoading={!data || isLoading || isFetching}
            isPreviewMode={previewMode}
            isSetupValid={isSetupValid}
            error={error}
          >
            {noData ? (
              <Card.EmptyState />
            ) : (
              <>
                <ScatterPlotGraph data={data} />
                <GraphInsights data={data} />
              </>
            )}
          </Card.Body>
          {insight.description && !screenshotMode && (
            <Card.Footer>
              <Card.DescriptionAccordion insight={insight} />
            </Card.Footer>
          )}
        </Card.Container>
      )}
    </InsightCard>
  );
};

export default GraphContainer;
