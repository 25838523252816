import { useState } from "react";
import { IFilterEvents } from "modules/Contacts/Contact/ContactActivity";
import { IEvent } from "core/types/Event";
import TrackEventDropdown from "core/modules/reports/setup/Menu/TrackEventDropdown";
import TrashIcon from "core/design-system/components/Icon/Interface/TrashIcon";
import Icon from "core/design-system/components/Icon";
import { ANY } from "core/constants/report-setup";

import { Box, Button, HStack, Tooltip } from "@chakra-ui/react";

import { OperatorSelect } from "../OperatorSelect";

export const FilterEvents: React.FC<{
  filterEvents: IFilterEvents;
  setFilterEvents: (filterEvents: IFilterEvents) => void;
}> = ({ filterEvents, setFilterEvents }) => {
  const [showEventFilter, setShowEventFilter] = useState(false);

  const onAddTrackEvent = (event: IEvent) => {
    setFilterEvents({
      ...filterEvents,
      events: [...filterEvents.events, event],
    });
  };

  const onRemoveTrackEvent = (event: IEvent) => {
    const events = filterEvents.events.filter((e) => e.id !== event.id);
    setFilterEvents({ ...filterEvents, events: events });
  };

  const onUpdateTrackEvent = (event: IEvent, newEvent: IEvent) => {
    const events = filterEvents.events.map((e) =>
      e.id === event.id ? newEvent : e,
    );
    setFilterEvents({ ...filterEvents, events: events });
  };

  return (
    <Box>
      {filterEvents &&
        filterEvents.events.map((filterEvent: IEvent, idx: number) => (
          <HStack
            key={idx}
            borderRadius={4}
            justifyContent="space-between"
            alignItems="center"
            role="group"
            py={1}
          >
            <HStack spacing={2}>
              {filterEvents && idx !== 0 && (
                <Box px={4}>
                  <OperatorSelect
                    isDisabled={true}
                    operator={filterEvents.joinOperator}
                  />
                </Box>
              )}
              <TrackEventDropdown
                defaultIsOpen={false}
                eventPosition={filterEvent.uuid ?? `${idx}`}
                onSelectEvent={(newEvent: IEvent) =>
                  onUpdateTrackEvent(filterEvent, newEvent)
                }
                label={filterEvent.name}
              />
              <Tooltip label="Remove event" hasArrow placement="top">
                <Button
                  border="none"
                  borderRadius="md"
                  onClick={() => onRemoveTrackEvent(filterEvent)}
                  isDisabled={
                    filterEvent.type === ANY && filterEvents.events.length === 1
                  }
                >
                  <TrashIcon h={3} w={3} color="gray.500" />
                </Button>
              </Tooltip>
            </HStack>
          </HStack>
        ))}
      {showEventFilter && (
        <Box mt={2}>
          <TrackEventDropdown
            defaultIsOpen={true}
            eventPosition={"0"}
            onSelectEvent={(event: IEvent) => {
              onAddTrackEvent(event);
              setShowEventFilter(false);
            }}
            label={"Select a track event"}
          />
        </Box>
      )}
      <Box mt={2}>
        <Button
          borderRadius="md"
          onClick={() => setShowEventFilter(true)}
          variant="ghost"
          colorScheme="purple"
          leftIcon={<Icon iconType="icon" name="add" h={3} w={3} pb={0.5} />}
        >
          {filterEvents?.events?.length ? "Add" : "Filter"} events
        </Button>
      </Box>
    </Box>
  );
};
