import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { orderBy, sumBy } from "lodash";
import { triggerToast } from "core/models/toasts/actions";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { thousands } from "core/helpers/thousands";
import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import TimeRangePicker from "core/components/TimeRangePicker";
import { DAU_OPTIONS } from "core/constants/timerange";
import { usePropertyValues } from "../../../core/hooks/usePropertyValues";

interface IPropertyDrawerProps {
  property: string;
  isDrawerOpen: boolean;
  onClose: () => void;
}

export const PropertyDrawer: React.FC<IPropertyDrawerProps> = ({
  property,
  isDrawerOpen,
  onClose,
}) => {
  const { id: appId } = useCurrentApp();
  const [timerange, setTimeRange] = useState(DAU_OPTIONS[1]);
  const { eventId } = useParams<{ eventId: string }>();
  const dispatch = useDispatch();

  const { data, isLoading, error, loadMore, hasMore } = usePropertyValues({
    appId: Number(appId),
    eventId: Number(eventId),
    property: property,
    days: timerange.count,
  });

  if (error) {
    dispatch(
      triggerToast({
        title: `Error fetching property values`,
        description: "",
        status: "error",
      }),
    );
    // hide drawer on error
    return null;
  }

  // we sum up all the values (it's correct if all pages are loaded)
  const estimatedTotal = sumBy(data, "count");

  return (
    <Drawer
      trapFocus={false}
      size="lg"
      isOpen={isDrawerOpen}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <Center>
            <Flex direction="column" alignItems="center" gridGap={2}>
              <Flex textAlign="center">
                Property
                <Text ml={1} as="b" color="purple.500">
                  {property}
                </Text>
              </Flex>
              <Flex fontWeight="normal">
                <TimeRangePicker
                  previewMode={false}
                  timerange={timerange}
                  setTimeRange={setTimeRange}
                  options={DAU_OPTIONS}
                  showTooltip={false}
                />
              </Flex>
            </Flex>
          </Center>
        </DrawerHeader>

        <DrawerBody>
          <Box borderColor="gray.100" borderWidth="1px" borderRadius="md">
            <Table border="none" variant="unstyled">
              <Thead
                bg="gray.50"
                color="gray.600"
                fontSize="xs"
                fontWeight="medium"
              >
                <Tr>
                  <Th fontWeight="medium" letterSpacing="none">
                    Property value
                  </Th>
                  <Th fontWeight="medium" letterSpacing="none" isNumeric>
                    <Text as="span">{thousands(estimatedTotal)}</Text>{" "}
                    <Text as="span" color="gray.500">
                      (100%)
                    </Text>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {orderBy(data, "count", "desc").map((property, idx) => (
                  <Tr
                    key={idx}
                    borderBottom={
                      idx === data.length - 1 ? "none" : "1px solid"
                    }
                    borderColor="gray.100"
                  >
                    <Td>
                      <Flex alignItems="center" justifyItems="center">
                        <Text fontSize="sm" color="gray.700">
                          {property.value}
                        </Text>
                      </Flex>
                    </Td>
                    <Td isNumeric>
                      <Text as="span" fontSize="sm" color="gray.700">
                        {thousands(property.count)}
                      </Text>{" "}
                      <Text as="span" fontSize="xs" color="gray.600">
                        ({property.percent.toFixed(2)}%)
                      </Text>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            {(isLoading || !data) && <Spinner />}
            {!!data?.length && !isLoading && hasMore && (
              <Button onClick={loadMore} colorScheme="purple">
                Load more
              </Button>
            )}
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default PropertyDrawer;
