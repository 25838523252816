import React from "react";
import { ITemplateConfigSetupSection } from "core/types/TemplateConfig";
import { useReportSetup } from "core/hooks/useReportSetup";
import { Text } from "@chakra-ui/react";
import { debounce } from "lodash";
import { TwEditable } from "core/design-system/components/TwEditable";

const SetupAccordionTitle: React.FC<{
  section: ITemplateConfigSetupSection;
  isExpanded: boolean;
}> = ({ section }) => {
  const { currentReport, updateSectionName } = useReportSetup();
  const { config } = currentReport;
  const { configKey, title, isRenameable } = section;

  const handleSectionNameChange = debounce(
    (name) => updateSectionName({ name, configKey: configKey }),
    500,
  );
  const sectionTitle = config?.name?.[configKey] || title;

  return (
    <>
      {isRenameable ? (
        <div className="mr-2">
          <TwEditable
            data-testid="setup-accordion-title-editable"
            key={configKey}
            defaultValue={sectionTitle}
            onSubmit={handleSectionNameChange}
          />
        </div>
      ) : (
        <Text mt={1} mr={1} fontSize="sm" fontWeight="medium">
          {sectionTitle}
        </Text>
      )}
    </>
  );
};

export default SetupAccordionTitle;
