import colors from "core/design-system/constants/theme/colors";
import { PercentChange } from "core/design-system/components/PercentChange";
import { ClickableTooltipFooter } from "core/design-system/charts/Tooltip/ClickableTooltipFooter";
import { ClickableTooltipContainer } from "core/design-system/charts/Tooltip/ClickableTooltipContainer";
import { Box, Flex, Text } from "@chakra-ui/react";

export const NewActiveUsersCustomTooltip = ({
  label,
  payload,
  active,
  unit,
  showNew,
  withDrawer,
  onClick,
}: {
  label: string;
  payload: any;
  active: boolean;
  unit: string;
  showNew: boolean;
  withDrawer: boolean;
  onClick: (_: any, payload: any) => void;
}) => {
  const data = payload && payload[0] && payload[0].payload;

  if (!active || !data) return null;

  return (
    <ClickableTooltipContainer
      isClickable={withDrawer}
      onClick={() => {
        return withDrawer ? onClick(undefined, { payload: data }) : null;
      }}
    >
      <Text fontSize="sm" color="gray.400">
        {label}
        {` `}
        {(typeof data.currentIdentifiedUsersCount === "number" ||
          typeof data.currentIdentifiedNewUsersCount === "number") &&
          !(
            data.hideIdentifiedUsersCount || data.hideIdentifiedNewUsersCount
          ) && (
            <Text as="span" color="white" fontWeight="semibold">
              (in progress)
            </Text>
          )}
      </Text>
      {showNew && (
        <>
          {typeof data.activeUsers === "number" && (
            <Text mt={2} fontSize="sm" color="white">
              {data.activeUsers} active users
            </Text>
          )}
          {typeof data.currentActiveUsers === "number" &&
            !data.hideIdentifiedUsersCount && (
              <Text mt={2} fontSize="sm" color="white">
                {data.currentActiveUsers} active users
              </Text>
            )}
          <>
            {data.previousDate && (
              <PercentChange
                ml={3}
                percentChange={data.growthActiveUsers}
                changeText={`vs ${data.previousDate}`}
              />
            )}
          </>
        </>
      )}
      <Flex mt={2} direction="column">
        {showNew && (
          <>
            {typeof data.identifiedNewUsersCount === "number" && (
              <Flex align="center">
                <Box
                  h={2}
                  w={2}
                  mr={2}
                  bg={colors.purple[300]}
                  borderRadius="100%"
                ></Box>
                <Text fontSize="sm" color="white">
                  {data.identifiedNewUsersCount} new users
                </Text>
              </Flex>
            )}
            {typeof data.currentIdentifiedNewUsersCount === "number" &&
              !data.hideIdentifiedNewUsersCount && (
                <Flex align="center">
                  <Box
                    h={2}
                    w={2}
                    mr={2}
                    bg={colors.gray[500]}
                    borderRadius="100%"
                  ></Box>
                  <Text fontSize="sm" color="white">
                    {data.currentIdentifiedNewUsersCount} new users
                  </Text>
                </Flex>
              )}
            {data.previousDate && (
              <PercentChange
                ml={3}
                percentChange={data.growthIdentifiedNew}
                changeText={`vs ${data.previousDate}`}
              />
            )}
          </>
        )}
        {typeof data.identifiedUsersCount === "number" && (
          <Flex align="center">
            <Box
              h={2}
              w={2}
              mr={2}
              bg={colors.purple[400]}
              borderRadius="100%"
            ></Box>
            <Text fontSize="sm" color="white">
              {data.identifiedUsersCount} returning users
            </Text>
          </Flex>
        )}
        {typeof data.currentIdentifiedUsersCount === "number" &&
          !data.hideIdentifiedUsersCount && (
            <Flex align="center">
              <Box
                h={2}
                w={2}
                mr={2}
                bg={colors.gray[600]}
                borderRadius="100%"
              ></Box>
              <Text fontSize="sm" color="white">
                {data.currentIdentifiedUsersCount} returning users
              </Text>
            </Flex>
          )}
      </Flex>
      {data.previousDate && (
        <PercentChange
          ml={3}
          percentChange={data.growthIdentified}
          changeText={`vs ${data.previousDate}`}
        />
      )}
      {withDrawer && <ClickableTooltipFooter />}
    </ClickableTooltipContainer>
  );
};
