import EmojiPickerComponent from "emoji-picker-react";
import { Button, ComponentDefaultProps, Text, Tooltip } from "@chakra-ui/react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";

interface IEmojiPicker extends ComponentDefaultProps {
  emoji: string;
  handleEmojiClick: (e: { emoji: string }) => void;
}

export const EmojiPicker: React.FC<IEmojiPicker> = ({
  emoji,
  handleEmojiClick,
  buttonProps,
  textProps,
}) => {
  return (
    <Popover className="relative">
      <PopoverButton>
        <Button
          _hover={{ bg: "gray.300" }}
          size="xs"
          py={0}
          variant="ghost"
          {...buttonProps}
        >
          <Tooltip label="Change emoji" placement="top" hasArrow>
            <Text fontSize="sm" {...textProps}>
              {emoji}
            </Text>
          </Tooltip>
        </Button>
      </PopoverButton>
      <PopoverPanel
        className="absolute z-[10000]"
        portal={true}
        anchor={{ to: "right end" }}
      >
        <EmojiPickerComponent onEmojiClick={handleEmojiClick} />
      </PopoverPanel>
    </Popover>
  );
};
