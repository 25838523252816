import { IListConfig } from "core/components/List/List.types";
import GroupNameCell from "./GroupNameCell";

const config = {
  resourceName: "companies",
  columns: [
    {
      title: "Name",
      name: "name",
      width: 400,
      Component: GroupNameCell,
      align: "left",
    },
  ],
} as IListConfig;

export { config };
