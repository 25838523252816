import { useCurrentApp } from "core/hooks/useCurrentApp";
import SmallAddIcon from "core/design-system/components/Icon/Interface/SmallAddIcon";
import Icon from "core/design-system/components/Icon";
import {
  Box,
  Button,
  Flex,
  Link,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { AddTokenModal } from "./AddTokenModal";
import { useSegment } from "core/hooks/useSegment";

interface ISegmentSource {
  id: number;
  sourceName: string;
  createdAt: string;
}

export default function Sources({
  showNewHeader = false,
}: {
  showNewHeader?: boolean;
}) {
  const { id: appId } = useCurrentApp();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { hasConnectedSegment, data, isLoading } = useSegment();

  return (
    <Flex direction="column" gap={4}>
      <>
        {showNewHeader ? (
          <Text fontSize="sm">
            Already connected through Segment?{" "}
            <Text
              cursor="pointer"
              onClick={onOpen}
              color="purple.500"
              as="span"
            >
              Add your Segment access token
            </Text>
            . Don’t have Segment? We’re an official partner.{" "}
            <Link
              color="purple.500"
              href="https://help.june.so/en/articles/6788471-how-to-get-50k-segment-credits-with-june"
              isExternal
            >
              Have 50,000 credits for free on us.
            </Link>
          </Text>
        ) : (
          <Box mb={3}>
            <Text fontSize="xs" color="gray.600">
              Already connected through Segment?{" "}
              <Text cursor="pointer" onClick={onOpen} color="purple.500" as="b">
                Add your Segment access token
              </Text>
              .
            </Text>
          </Box>
        )}
        <AddTokenModal isOpen={isOpen} onClose={onClose} />
      </>
      <Flex
        p={4}
        bg="gray.50"
        justifyContent="center"
        borderRadius="lg"
        minH="120px"
        direction="column"
      >
        {hasConnectedSegment && (
          <Flex
            h="full"
            direction="column"
            justifyContent="center"
            flexWrap="wrap"
            gap={2}
            align="center"
          >
            {data?.map((segmentSource: ISegmentSource) => (
              <Flex
                border="1px solid"
                borderColor="gray.100"
                bg="white"
                p={1}
                px={1}
                borderRadius="26px"
                w="fit-content"
              >
                <Flex borderRadius="100%" p={1} bg="white">
                  <Icon iconType="logo" name="companySegmentLogoFull" />
                </Flex>
                <Text fontWeight="medium" fontSize="sm" pr={2} ml={2}>
                  {segmentSource.sourceName}
                </Text>
              </Flex>
            ))}
          </Flex>
        )}
        {data?.length === 0 && (
          <Flex justify="center">
            <a href={`${import.meta.env.VITE_API_HOST}/auth/segment`}>
              <Button
                colorScheme="green"
                color="white"
                size="md"
                leftIcon={
                  <Icon
                    color="white"
                    iconType="logo"
                    name="companySegmentLogo"
                  />
                }
                onClick={() => {
                  window.analytics.track(
                    "connect_segment_clicked",
                    {
                      appId,
                    },
                    { context: { groupId: appId } },
                  );
                }}
              >
                Connect to Segment
              </Button>
            </a>
          </Flex>
        )}
        {isLoading && !data && <Spinner />}
        {hasConnectedSegment && (
          <a href={`${import.meta.env.VITE_API_HOST}/auth/segment`}>
            <Flex
              direction="row"
              align="center"
              justify="center"
              borderRadius={4}
              mt={4}
            >
              <SmallAddIcon />
              <Text
                pr={4}
                textAlign="center"
                fontWeight="semibold"
                fontSize="sm"
              >
                Add more
              </Text>
            </Flex>
          </a>
        )}
      </Flex>
    </Flex>
  );
}
