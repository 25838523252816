import React from "react";
import { IReportInsight } from "core/types/Report";
import { isUserReportLevel } from "core/modules/reports/utils";
import { weeklyAdoptionMockData } from "core/modules/reports/report-types/FeatureRelease/mockData";
import { AdoptionLineChart } from "core/modules/reports/report-types/FeatureRelease/Adoption/AdoptionLineChart";
import { useAppObjects } from "core/hooks/useAppObjects";
import { INTERVAL_TYPES } from "core/constants/timerange";
import { LineContainer } from "core/components/LineContainer";

export const WeeklyAdoptionContainer: React.FC<IReportInsight> = ({
  config,
  report,
  reportLevel,
  ...props
}) => {
  const isUserReport = isUserReportLevel(reportLevel);
  const unit = isUserReport ? "user" : "company";
  const insight = config.insights.find(
    ({ slug }: { slug: string }) => slug === `weekly-${unit}-adoption`,
  );
  const { activeAppObject } = useAppObjects();

  if (!insight) return null;

  return (
    <LineContainer
      LineChart={AdoptionLineChart}
      keyAccessor={"adoptionInTimeRange"}
      insight={insight}
      report={report}
      intervalType={INTERVAL_TYPES.WEEK}
      mockData={weeklyAdoptionMockData}
      config={config}
      unit={isUserReport ? "users" : activeAppObject?.pluralName.toLowerCase()}
      {...props}
    />
  );
};
