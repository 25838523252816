// @ts-nocheck
/* eslint-disable valid-typeof */
import { Label, ReferenceLine } from "recharts";
import { useState } from "react";
import { simplifyXAxisTicks } from "helpers/xAxis";
import { ILineChart } from "core/types/LineChart";
import { useTooltipCoordinate } from "core/hooks/useTooltipCoordinates";
import { computeGrowth } from "core/helpers/computeGrowth";
import colors from "core/design-system/constants/theme/colors";
import TickText from "core/design-system/charts/TickText";
import {
  CommonDot,
  CommonGrid,
  CommonLegend,
  CommonTooltip,
  CommonXAxis,
  CommonYAxis,
} from "core/design-system/charts/Common";
import { Area, AreaChart } from "core/design-system/charts/AreaChart";
import { Flex, useDisclosure } from "@chakra-ui/react";
import { NewActiveUsersCustomTooltip } from "./NewActiveUsersCustomTooltip";
import { NewActiveUsersCustomLegend } from "./NewActiveUsersCustomLegend";
import { ActiveUsersList } from "./ActiveUsersList";

function AllTimeHighLabel({ viewBox }) {
  return (
    <text
      fontSize="10px"
      x={viewBox.x}
      y={viewBox.y}
      fill={colors.purple[400]}
      dy={-5}
      dx={0}
    >
      All time high
    </text>
  );
}

const getUsersCount = ({
  payload,
  showNew,
}: {
  payload: any;
  showNew: boolean;
}) => {
  if (!payload) return 0;

  const {
    identifiedUsersCount,
    currentIdentifiedUsersCount,
    identifiedNewUsersCount,
    currentIdentifiedNewUsersCount,
  } = payload;

  if (!showNew) return identifiedUsersCount;

  let identifiedCount = 0;
  let newUsersCount = 0;

  if (identifiedUsersCount !== undefined) {
    identifiedCount = identifiedUsersCount;
  } else if (currentIdentifiedUsersCount !== undefined) {
    identifiedCount = currentIdentifiedUsersCount;
  }

  if (identifiedNewUsersCount !== undefined) {
    newUsersCount = identifiedNewUsersCount;
  } else if (currentIdentifiedNewUsersCount !== undefined) {
    newUsersCount = currentIdentifiedNewUsersCount;
  }

  return identifiedCount + newUsersCount;
};

function NewActiveUsersLineChart({
  report,
  data,
  intervalType = 0,
  previewMode = false,
  settings,
  sharingMode = false,
  unit,
  withDrawer = true,
  allTimeHigh,
  showYAxis = true,
  showInProgress = true,
  insight,
  showGrid = true,
  showAllTimeHigh = true,
  simplifyXAxis = false,
  h = 300,
  showTooltips = true,
}: ILineChart) {
  const [humanizedDate, setHumanizedDate] = useState("");
  const [date, setDate] = useState("");
  const [hour, setHour] = useState();
  const { coordinates, onMouseMove } = useTooltipCoordinate();
  const showNew = !settings?.removeNewUsers;
  const [activeUsersCount, setActiveUsersCount] = useState(0);

  const { isOpen, onOpen, onClose } = useDisclosure();

  let marker;

  const formattedData = data.reduce((formatted, row, currentIndex) => {
    const lastRow = formatted[formatted.length - 1];
    const nextRow = data[currentIndex + 1];
    const growthActiveUsers =
      lastRow && computeGrowth(lastRow.activeUsers, row["activeUsers"]);
    const growthIdentified =
      lastRow &&
      computeGrowth(lastRow.identifiedUsersCount, row["identifiedUsersCount"]);
    const growthIdentifiedNew =
      lastRow &&
      computeGrowth(
        lastRow.identifiedNewUsersCount,
        row["identifiedNewUsersCount"],
      );
    const growthAnonymous =
      lastRow &&
      computeGrowth(lastRow.anonymousUsersCount, row["anonymousUsersCount"]);
    const growthAnonymousNew =
      lastRow &&
      computeGrowth(
        lastRow.anonymousNewUsersCount,
        row["anonymousNewUsersCount"],
      );
    const previousDate = lastRow?.x;

    if (!marker && row.incomplete && lastRow) {
      marker = lastRow.x;
    }

    formatted.push({
      x: row.humanizedDate,
      activeUsers: !row.incomplete ? row["activeUsers"] : undefined,
      currentActiveUsers:
        nextRow?.incomplete || row.incomplete ? row["activeUsers"] : undefined,
      identifiedUsersCount: !row.incomplete
        ? row["identifiedUsersCount"]
        : undefined,
      identifiedNewUsersCount: !row.incomplete
        ? row["identifiedNewUsersCount"]
        : undefined,
      currentIdentifiedUsersCount:
        nextRow?.incomplete || row.incomplete
          ? row["identifiedUsersCount"]
          : undefined,
      currentIdentifiedNewUsersCount:
        nextRow?.incomplete || row.incomplete
          ? row["identifiedNewUsersCount"]
          : undefined,
      anonymousUsersCount: row["anonymousUsersCount"],
      anonymousNewUsersCount: row["anonymousNewUsersCount"],
      hideIdentifiedUsersCount: nextRow?.incomplete,
      hideIdentifiedNewUsersCount: nextRow?.incomplete,
      growthActiveUsers,
      growthIdentified,
      growthIdentifiedNew,
      growthAnonymous,
      growthAnonymousNew,
      previousDate,
      date: row.date,
      hour: row.hour,
    });
    return formatted;
  }, []);

  if (!showInProgress) {
    formattedData.pop();
  }

  const { firstTick, lastTick } = simplifyXAxisTicks(formattedData, "x");

  const onDataPointClick = (_, index) => {
    if (!previewMode && !sharingMode && withDrawer) {
      const usersCount = getUsersCount({ payload: index.payload, showNew });
      setHumanizedDate(index.payload.x);
      setDate(index.payload.date);
      setActiveUsersCount(usersCount);
      if (index.payload.hour) {
        setHour(index.payload.hour);
      }
      onOpen();
    }
  };

  return (
    <Flex
      data-testid={`active-user-line-chart-${insight.slug}`}
      direction="column"
      justify="center"
      h={h}
      onMouseMove={onMouseMove}
    >
      <AreaChart
        margin={{ top: 30, left: showYAxis ? 0 : -30, right: 50, bottom: 20 }}
        height="100%"
        data={formattedData}
      >
        {showGrid && <CommonGrid />}
        <CommonXAxis
          ticks={simplifyXAxis ? [firstTick, lastTick] : ""}
          dataKey="x"
        />
        <CommonYAxis
          tick={({ x, ...props }) =>
            showYAxis && (
              <TickText x={x - 5} {...props}>
                {props.payload.value} {!withDrawer && `%`}
              </TickText>
            )
          }
        />
        {showAllTimeHigh && (
          <ReferenceLine
            y={allTimeHigh}
            stroke={colors.purple[300]}
            alwaysShow={true}
          >
            <Label content={AllTimeHighLabel} />
          </ReferenceLine>
        )}
        {showInProgress && (
          <ReferenceLine
            x={marker}
            stroke={colors.gray[600]}
            strokeDasharray="3 3"
          />
        )}
        {showTooltips && (
          <CommonTooltip
            trigger="hover"
            position={coordinates}
            content={
              <NewActiveUsersCustomTooltip
                unit={unit}
                showNew={showNew}
                withDrawer={Boolean(withDrawer)}
                onClick={onDataPointClick}
              />
            }
          />
        )}
        <CommonLegend content={<NewActiveUsersCustomLegend />} />
        <Area
          stackId="1"
          name="Returning users"
          dataKey="identifiedUsersCount"
          showLegend={true}
          fill={colors.purple[500]}
          activeDot={
            <CommonDot
              r={withDrawer ? 7 : 0}
              onClick={onDataPointClick}
              cursor={withDrawer && "pointer"}
            />
          }
        />
        {showInProgress && (
          <Area
            stackId="0"
            name="Returning users"
            dataKey="currentIdentifiedUsersCount"
            showLegend={false}
            fill={colors.gray[500]}
            stroke={colors.gray[600]}
            activeDot={
              <CommonDot
                r={withDrawer ? 7 : 0}
                onClick={onDataPointClick}
                cursor={withDrawer && "pointer"}
              />
            }
          />
        )}
        {showNew && (
          <Area
            stackId="1"
            name="New users"
            dataKey="identifiedNewUsersCount"
            showLegend={true}
            fill={colors.purple[300]}
            stroke={colors.purple[300]}
            activeDot={
              <CommonDot
                r={withDrawer ? 7 : 0}
                onClick={onDataPointClick}
                cursor={withDrawer && "pointer"}
              />
            }
          />
        )}
        {showNew && (
          <Area
            stackId="0"
            name="New users"
            dataKey="currentIdentifiedNewUsersCount"
            showLegend={false}
            fill={colors.gray[300]}
            stroke={colors.gray[500]}
            activeDot={
              <CommonDot
                r={withDrawer ? 7 : 0}
                onClick={onDataPointClick}
                cursor={withDrawer && "pointer"}
              />
            }
          />
        )}
      </AreaChart>
      {!sharingMode && !previewMode && (
        <ActiveUsersList
          report={report}
          key={date}
          isOpen={isOpen}
          onClose={onClose}
          humanizedDate={humanizedDate}
          date={date}
          hour={hour}
          showNew={showNew}
          activeUsersCount={activeUsersCount}
          intervalType={intervalType}
        />
      )}
    </Flex>
  );
}

export default NewActiveUsersLineChart;
