import React from "react";
import NotificationsSection from "core/modules/notifications/NotificationsSection";
import { Drawer, DrawerContent, DrawerOverlay } from "@chakra-ui/react";

interface EventAlertEditorDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  event: any; // Assuming any type for simplicity, replace with the actual type
  reloadParent: () => void;
}

export const EventAlertEditorDrawer: React.FC<EventAlertEditorDrawerProps> = ({
  isOpen,
  onClose,
  event,
}) => {
  return (
    <Drawer
      trapFocus={false}
      size="xl"
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <NotificationsSection
          event={event}
          onClose={onClose}
          howToSetupText="Learn more"
          howToSetupLink="https://help.june.so/en/articles/5538197-how-to-use-the-event-notification-for-slack"
        />
      </DrawerContent>
    </Drawer>
  );
};

export default EventAlertEditorDrawer;
