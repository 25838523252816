import { useEffect } from "react";
import { useLazyGetEventsQuery } from "core/models/events";
import usePagination from "core/hooks/usePagination";
import { useCurrentApp } from "core/hooks/useCurrentApp";

export const useEvents = ({ query }: { query?: string }) => {
  const currentApp = useCurrentApp();
  const { currentPage, previousPage, nextPage } = usePagination({
    withURL: false,
    searchQuery: query,
  });
  const [
    getEvents,
    {
      data: eventsData,
      isLoading: isLoadingEvents,
      isFetching: isFetchingEvents,
    },
  ] = useLazyGetEventsQuery();

  function onGetEvents() {
    getEvents({
      appId: currentApp.id,
      page: currentPage,
      query: query || "",
    });
  }

  useEffect(() => {
    getEvents({
      appId: currentApp.id,
      page: currentPage,
      query: query || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, currentApp.id, query]);

  return {
    events: eventsData?.events,
    isLoadingEvents,
    isFetchingEvents,
    pagy: eventsData?.pagy,
    currentPage,
    previousPage,
    nextPage,
    onGetEvents,
  };
};
