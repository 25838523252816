import { ReactElement } from "react";
import { motion } from "framer-motion";
import { ComponentDefaultProps, Flex } from "@chakra-ui/react";

interface IConversationBlock extends ComponentDefaultProps {
  Avatar: ReactElement<ComponentDefaultProps>;
  isReverse?: boolean;
  delay?: number;
}

export const ConversationBlock: React.FC<IConversationBlock> = ({
  Avatar,
  isReverse,
  delay,
  children,
}) => {
  return (
    <Flex mt={3} gridGap={4} direction={isReverse ? "row-reverse" : "row"}>
      <Flex direction="column">
        <motion.div
          style={{
            borderRadius: "100%",
          }}
          initial={{ opacity: 0, x: isReverse ? 10 : -10 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{
            duration: 0.8,
            delay: delay ? delay : 0.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          {Avatar}
        </motion.div>
      </Flex>
      <Flex gridGap={3} direction="column">
        {children}
      </Flex>
    </Flex>
  );
};
