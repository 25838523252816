import React, { useEffect, useRef, useState } from "react";
import { cx } from "helpers/cx";
import { ITemplateConfigSetupSection } from "core/types/TemplateConfig";
import { IEvent } from "core/types/Event";
import { IEventRow, useLazyGetEventsQuery } from "core/models/events";
import { useEvents } from "core/hooks/useEvents";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import Icon from "core/design-system/components/Icon";
import {
  Command,
  CommandInput,
  CommandList,
} from "core/design-system/components/Command";
import { SMART_EVENTS } from "core/constants/events";
import {
  Button,
  Center,
  ComponentDefaultProps,
  Divider,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import EventFrequencyBars from "./EventFrequencyBars";

interface ITrackEventDropdownProps extends ComponentDefaultProps {
  eventPosition?: string;
  onSelectEvent: (event: IEvent) => void;
  defaultIsOpen: boolean;
  label?: string;
  hasSmartEvents?: boolean;
  areSmartEventsDisabled?: boolean;
  section?: ITemplateConfigSetupSection;
}

export const TrackEventDropdown: React.FC<ITrackEventDropdownProps> = ({
  eventPosition,
  onSelectEvent,
  label,
  defaultIsOpen = true,
  hasSmartEvents = false,
  areSmartEventsDisabled = false,
  section,
  ...props
}) => {
  const { id: appId } = useCurrentApp();
  const [searchQuery, setSearchQuery] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [allEvents, setAllEvents] = useState<IEventRow[]>([]);
  const { onGetEvents, events, isFetchingEvents, pagy, nextPage } = useEvents({
    query: searchQuery,
  });
  const [getEvents, { data: searchResults, isFetching: isFetchingResults }] =
    useLazyGetEventsQuery();
  const hasMoreEvents = pagy && pagy.count > allEvents.length;

  const onSearch = (e: any) => {
    setSearchQuery(e.currentTarget.value);
  };

  const onEventClick = (e: IEventRow, onClose: () => void) => {
    onSelectEvent({
      ...e,
      uuid: String(eventPosition),
      appId,
    } as unknown as IEvent);
    onClose && onClose();
  };

  const loadMoreEvents = () => {
    nextPage();
    onGetEvents();
  };

  useEffect(() => {
    if (searchQuery !== "" && searchResults) {
      return setAllEvents(searchResults?.events);
    }
    if (events) {
      setAllEvents([...allEvents, ...events]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, searchResults]);

  useEffect(() => {
    getEvents({ appId, page: 1, query: searchQuery || "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    onGetEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Popover defaultIsOpen={defaultIsOpen} {...props}>
      {({ onClose }) => {
        return (
          <>
            <PopoverTrigger {...props}>
              <div className="flex w-fit cursor-pointer items-center gap-2 truncate rounded-l-md bg-gray-50 px-3 py-[6.5px] font-medium hover:bg-gray-100">
                <Tooltip
                  label={label}
                  size="sm"
                  isDisabled={
                    label === "Select an event" || (label?.length ?? 0) < 16
                  }
                >
                  <p className="text-sm font-semibold">
                    <div
                      data-testid="track-event-dropdown-label"
                      className={"ellipsis flex truncate"}
                    >
                      {label || "Select an event"}
                    </div>
                  </p>
                </Tooltip>
                <Icon
                  iconType="icon"
                  name="chevron-down"
                  h={4}
                  w={4}
                  {...props}
                />
              </div>
            </PopoverTrigger>
            <PopoverContent p={0} shadow="none" border="none">
              <PopoverBody p={0} shadow="none" border="none">
                <Command className="w-full min-w-[200px] max-w-[250px]">
                  <CommandInput
                    placeholder="Search"
                    value={searchQuery}
                    onChange={onSearch}
                  />
                  <CommandList data-testid={`audience-trait-options`}>
                    {hasSmartEvents && (
                      <>
                        {SMART_EVENTS.map((smartEvent: any, index: number) => {
                          const isDisabled = section?.supportedEventTypes
                            ? !section?.supportedEventTypes.includes(
                                smartEvent.type,
                              )
                            : false;
                          return (
                            <Tooltip
                              label={
                                isDisabled
                                  ? "You cannot use this event"
                                  : smartEvent.eventName
                              }
                              placement="right"
                            >
                              <div
                                onClick={() => {
                                  if (isDisabled || areSmartEventsDisabled)
                                    return;
                                  onEventClick(smartEvent, onClose);
                                }}
                                className={cx(
                                  "flex items-center justify-between rounded-md px-2 py-1 hover:bg-gray-50",
                                  isDisabled || areSmartEventsDisabled
                                    ? "cursor-not-allowed text-gray-500"
                                    : "cursor-pointer text-black ",
                                )}
                                key={index}
                                data-testid={`smart-event${smartEvent.id}`}
                              >
                                <div className="flex items-center">
                                  <p className="max-w-[200px] truncate text-sm">
                                    {smartEvent.eventName}
                                  </p>
                                </div>
                              </div>
                            </Tooltip>
                          );
                        })}
                        <Divider my={1} />
                      </>
                    )}
                    {isFetchingEvents || isFetchingResults ? (
                      <Center py={8}>
                        <Spinner />
                      </Center>
                    ) : (
                      <>
                        {allEvents.map((event: IEventRow, index: number) => {
                          return (
                            <Tooltip
                              label={event.name}
                              placement="right"
                              hasArrow
                            >
                              <div
                                className="flex cursor-pointer items-center justify-between rounded-md px-2 py-1 hover:bg-gray-50"
                                onClick={() => {
                                  onEventClick(event, onClose);
                                }}
                                key={index}
                              >
                                <p className="max-w-[200px] truncate text-sm">
                                  {event.name}
                                </p>
                                <EventFrequencyBars event={event} />
                              </div>
                            </Tooltip>
                          );
                        })}
                      </>
                    )}
                    {!isFetchingEvents &&
                      !isFetchingResults &&
                      allEvents.length === 0 && (
                        <Text
                          textAlign="center"
                          px={4}
                          pb={8}
                          fontSize="sm"
                          color="gray.600"
                        >
                          No events found
                        </Text>
                      )}

                    {searchQuery.length === 0 && hasMoreEvents && (
                      <Button
                        mt={2}
                        size="sm"
                        onClick={loadMoreEvents}
                        isDisabled={!hasMoreEvents}
                      >
                        <p
                          className={cx(
                            "text-sm",
                            isFetchingEvents ? "text-gray-600" : "text-black",
                          )}
                        >
                          {isFetchingEvents ? "Loading..." : "Load more"}
                        </p>
                      </Button>
                    )}
                  </CommandList>
                </Command>
              </PopoverBody>
            </PopoverContent>
          </>
        );
      }}
    </Popover>
  );
};

export default TrackEventDropdown;
