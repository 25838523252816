import { useEffect } from "react";
import LoginPage from "../modules/Authentication/Login";

const Login = () => {
  useEffect(() => {
    if (window.location.pathname.includes("/a/")) {
      localStorage.setItem("june__next", window.location.pathname);
    }
  }, []);

  return <LoginPage />;
};

export default Login;
