import React from "react";
import { Box, Flex, FlexProps, Text } from "@chakra-ui/react";

const CircleEmojiIcon: React.FC<{ color: FlexProps["bg"] }> = ({
  color,
  children,
}) => {
  return (
    <Flex
      fontSize="xs"
      h={8}
      w={8}
      align="center"
      justify="center"
      borderRadius="full"
      bg={color}
    >
      <Text textAlign={"center"}>{children}</Text>
    </Flex>
  );
};

interface IBenchmarksProps {
  label: string;
  value: number;
  color?: string;
  relativeWidth?: number;
  fontWeight?: string;
  fontColor?: string;
}

function Benchmarks({
  label,
  value,
  color = "gray.300",
  relativeWidth = 1.2,
  fontWeight = "normal",
  fontColor = "gray.600",
}: IBenchmarksProps) {
  return (
    <Flex _last={{ marginBottom: 0 }} align="center" mb={4}>
      <Flex w={"25%"}>
        <Text color={fontColor} fontWeight={fontWeight} fontSize={"sm"}>
          {label}
        </Text>
      </Flex>
      <Flex w={"65%"} mx={4}>
        <Box
          borderRadius={"5px"}
          width={`${value * relativeWidth}%`}
          height={"4px"}
          bg={color}
        ></Box>
      </Flex>
      <Flex w={"15%"}>
        {" "}
        <Text color={fontColor} fontWeight={fontWeight} fontSize={"sm"}>
          {value}%
        </Text>
      </Flex>
    </Flex>
  );
}

function InsightTipsIcon() {
  return <CircleEmojiIcon color="yellow.100">💡</CircleEmojiIcon>;
}

function FeatureReleasedInsightIcon() {
  return (
    <CircleEmojiIcon color="green.100">
      <Text as="span" ml="-3px" textAlign={"center"}>
        ⏳
      </Text>
    </CircleEmojiIcon>
  );
}

function sortInsights(a: { value: number }, b: { value: number }) {
  if (b.value < a.value) {
    return 1;
  }
  if (b.value > a.value) {
    return -1;
  }

  return 0;
}

export { FeatureReleasedInsightIcon, InsightTipsIcon, sortInsights };

export default Benchmarks;
