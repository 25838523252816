import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ITraitWithConfig } from "core/types/Trait";
import { IGroup } from "core/types/Group";
import { IContact } from "core/types/Contact";
import { useLazyGetComputeTraitQuery } from "core/models/computedTraits";
import { TRAIT_LEVEL } from "core/constants/traits";
import { Flex } from "@chakra-ui/react";
import { PreviewMenu } from "./PreviewMenu";
import { PreviewHeader } from "./PreviewHeader";
import { PreviewTraitsContainer } from "../Traits/PreviewTraitsContainer";

export const SmartTraitPreview: React.FC<{
  name: string;
  level: TRAIT_LEVEL;
  trait: ITraitWithConfig;
}> = ({ name, level, trait }) => {
  const { appId } = useParams();
  const [value, setValue] = useState(0);
  const [entity, setEntity] = useState<IContact | IGroup | undefined>();
  const computedTraits =
    level === TRAIT_LEVEL.USER
      ? {
          user: [{ name, value, config: trait.config, isComputed: true }],
          company: [],
        }
      : {
          company: [{ name, value, config: trait.config, isComputed: true }],
          user: [],
        };

  const [getComputeTrait, { data, isFetching: isComputingPreviewTraitValue }] =
    useLazyGetComputeTraitQuery();

  useEffect(() => {
    if (entity?.id)
      getComputeTrait({ appId: Number(appId), trait, entityId: entity.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity, trait]);

  useEffect(() => {
    if (data?.result !== undefined) setValue(data?.result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.result]);

  return (
    <Flex
      direction="column"
      w={500}
      gap={5}
      p={10}
      bg="gray.50"
      rounded="lg"
      border="1px solid"
      borderColor="gray.200"
      justifyContent="start"
    >
      {trait && (
        <PreviewMenu level={level} entity={entity} setEntity={setEntity} />
      )}
      <PreviewHeader level={level} entityParam={entity} />
      <PreviewTraitsContainer
        key={`${entity?.id}-${name}-${value}`}
        entityType={level === TRAIT_LEVEL.USER ? "contact" : "group"}
        computedTraits={computedTraits || []}
        isLoading={isComputingPreviewTraitValue}
        traits={[{ name, value: String(value) }]}
      />
    </Flex>
  );
};
