import { IReportInsight } from "core/types/Report";
import { magMockData } from "core/modules/reports/report-types/ActiveGroups/mockData";
import LineChart from "core/modules/reports/report-types/ActiveGroups/LineChart";
import { INTERVAL_TYPES, MAU_OPTIONS } from "core/constants/timerange";
import { LineContainer } from "core/components/LineContainer";

export const MagContainer: React.FC<IReportInsight> = ({
  config,
  ...props
}) => {
  const insight = config.insights.find(
    ({ slug }: { slug: string }) => slug === "mac",
  );

  if (!insight) {
    return null;
  }

  return (
    <LineContainer
      LineChart={LineChart}
      insight={insight}
      initialTimeRange={"THREE_MONTHS"}
      timeRangeOptions={MAU_OPTIONS}
      intervalType={INTERVAL_TYPES.MONTH}
      keyAccessor={"data"}
      intervalPeriod={"THIRTY_DAYS"}
      mockData={magMockData}
      config={config}
      {...props}
    />
  );
};
