import { Link } from "react-router-dom";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { Button } from "@chakra-ui/react";

function MobileWidgets() {
  const { id: appId } = useCurrentApp();

  return (
    <>
      <h2 className="text-base font-semibold text-gray-900">Mobile widget</h2>
      <div className="flex w-full justify-between">
        <div className="flex flex-col">
          <div className="mb-1 text-sm font-semibold">June for iOS</div>
          <div className="text-xs">Take your startup metrics everywhere.</div>
        </div>
        <Link to={`/a/${appId}/widgets`}>
          <Button>
            <div className="text-sm">Get the widget</div>
          </Button>
        </Link>
      </div>
    </>
  );
}

export default MobileWidgets;
