import { updateLevel } from "helpers/filters";
import { IAudienceFilters } from "core/types/Audience";
import { IAppObject } from "core/models/appObjects";
import { useAppObjects } from "core/hooks/useAppObjects";
import { PeopleTab } from "./Tab";

export const PeopleTabs: React.FC<{
  audienceFilters: IAudienceFilters;
  searchQuery: string;
  setPage: (page: number) => void;
}> = ({ audienceFilters, searchQuery, setPage }) => {
  const { appObjects, activeAppObject, setActiveAppObject } = useAppObjects();

  const handleLevelChange = (appObject: IAppObject) => {
    setActiveAppObject(appObject);
    updateLevel(appObject.slug);
    setPage(1);
  };

  return (
    <>
      {activeAppObject &&
        appObjects
          .filter((aO) => aO.isEnabled)
          .map((appObject) => (
            <PeopleTab
              key={appObject.objectType}
              appObject={appObject}
              activeAppObject={activeAppObject}
              audienceFilters={audienceFilters}
              searchQuery={searchQuery}
              onLevelChange={handleLevelChange}
            />
          ))}
    </>
  );
};
