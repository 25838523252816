import React from "react";
import { Spinner, Tooltip } from "@chakra-ui/react";
import { cx } from "helpers/cx";
import { CrmSyncStatus } from "core/models/crmSyncs";

interface LastSyncStatusDotProps {
  status?: CrmSyncStatus;
}

const TITLE = {
  succeeded: "Last sync succeeded",
  enqueued: "Sync enqueued",
  started: "Sync in progress",
  failed: "Sync failed",
};

export const LastSyncStatusDot: React.FC<LastSyncStatusDotProps> = ({
  status,
}) => {
  if (status === CrmSyncStatus.Enqueued)
    return (
      <Tooltip
        label={(status && TITLE[status]) || "Never synced"}
        placement="top"
        hasArrow
      >
        <Spinner
          size="xs"
          emptyColor="gray.50"
          color="gray.400"
          thickness="2px"
        />
      </Tooltip>
    );

  if (status === CrmSyncStatus.Started)
    return (
      <Tooltip
        label={(status && TITLE[status]) || "Never synced"}
        placement="top"
        hasArrow
      >
        <Spinner
          size="xs"
          emptyColor="yellow.50"
          color="yellow.400"
          thickness="2px"
        />
      </Tooltip>
    );

  return (
    <Tooltip
      label={(status && TITLE[status]) || "Never synced"}
      placement="top"
      hasArrow
    >
      <div
        className={cx(
          "h-[12px] w-[12px] rounded-full",
          status === CrmSyncStatus.Succeeded
            ? "bg-green-300"
            : status === CrmSyncStatus.Failed
              ? "bg-red-400"
              : "bg-gray-300",
        )}
      ></div>
    </Tooltip>
  );
};
