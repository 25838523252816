import { cx } from "helpers/cx";
import { Combobox } from "@headlessui/react";
import { ComponentDefaultProps } from "@chakra-ui/react";

interface ICommandProps extends ComponentDefaultProps {
  className?: string;
  children?: React.ReactNode;
}

export const Command: React.FC<ICommandProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <Combobox
      as="div"
      className={cx(
        "absolute z-popover mt-1 max-h-80 w-[200px] overflow-y-auto overflow-x-hidden rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
        className ?? "",
      )}
      {...props}
    >
      <div className="flex flex-col gap-2 px-2 py-1">{children}</div>
    </Combobox>
  );
};

export { CommandOption } from "core/design-system/components/Command/CommandOption";
export { CommandList } from "core/design-system/components/Command/CommandList";
export { CommandInput } from "core/design-system/components/Command/CommandInput";
