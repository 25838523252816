import { ComponentDefaultProps } from "@chakra-ui/react";
import { ContactWithData } from "./ContactWithData";
import { ContactStaticPreview } from "./ContactStaticPreview";

interface ISingleContactProps extends ComponentDefaultProps {
  id: string;
  isNewUser?: string;
  previewMode?: boolean;
  description?: string;
  truncateName?: boolean;
  hideHoverBorder?: boolean;
  hideExternalLinkIcon?: boolean;
}

// NOTE: Do not use for drilldowns

const SingleContact: React.FC<ISingleContactProps> = ({
  id,
  isNewUser,
  description,
  previewMode = false,
  p = 2,
  truncateName = false,
  hideHoverBorder = false,
  hideExternalLinkIcon = false,
  ...props
}) => {
  return previewMode ? (
    <ContactStaticPreview
      id={id}
      description={description}
      p={p}
      truncateName={truncateName}
      hideHoverBorder={hideHoverBorder}
    />
  ) : (
    <ContactWithData
      id={id}
      isNewUser={isNewUser}
      description={description}
      truncateName={truncateName}
      hideHoverBorder={hideHoverBorder}
      hideExternalLinkIcon={hideExternalLinkIcon}
      p={p}
      {...props}
    />
  );
};

export default SingleContact;
