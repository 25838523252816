import {
  useDeleteSettingsMutation,
  useGetSettingsQuery,
} from "core/models/attio";
import { AppObjectType } from "core/models/appObjects";
import { useCurrentApp } from "core/hooks/useCurrentApp";

export interface IAttioSetting {
  appId?: number;
  installed?: boolean;
  isSyncingGroups?: boolean;
  isSyncingCompanies?: boolean;
  companyIdentifier?: string;
  lastSyncedGroups?: string;
  lastSyncedCompanies?: string;
}

export interface IUpdateSettingParams extends IAttioSetting {
  objectType: AppObjectType;
  callback?: () => void;
}

interface IUseAttioSettingsResponse {
  setting?: IAttioSetting;
  isLoadingSetting: boolean;
  isDeletingSettings: boolean;
  hasAttioInstalled: boolean;
  onDeleteSettings: () => void;
}

export const useAttioSettings = (): IUseAttioSettingsResponse => {
  const currentApp = useCurrentApp();

  const { data: setting, isLoading: isLoadingSetting } = useGetSettingsQuery({
    appId: currentApp.id,
  });
  const [deleteSettings, { isLoading: isDeletingSettings }] =
    useDeleteSettingsMutation();

  function onDeleteSettings() {
    deleteSettings({ appId: currentApp.id });
  }

  return {
    setting,
    isLoadingSetting,
    isDeletingSettings,
    hasAttioInstalled: Boolean(setting?.installed),
    onDeleteSettings,
  };
};
