import { useParams } from "react-router-dom";
import { ReactComponentLike } from "prop-types";
import { IInsightSection, ITemplateConfig } from "core/types/TemplateConfig";
import { IReport } from "core/types/Report";
import { IQuery } from "core/types/Query";
import { isUserReportLevel } from "core/modules/reports/utils";
import { GroupBanner } from "core/modules/reports/GroupBanner";
import { useReportSetup } from "core/hooks/useReportSetup";
import { useQueryParam } from "core/hooks/useQueryParam";
import { ReportInsight } from "core/components/Reportinsight";
import { SparklesIcon } from "@heroicons/react/24/solid";
import { Divider, Grid } from "@chakra-ui/react";

export const InsightsContainer: React.FC<{
  report: IReport;
  templateConfig: ITemplateConfig;
  insightConfig?: IInsightSection;
}> = ({ report, templateConfig, insightConfig }) => {
  const { appId } = useParams();
  const { serializeKey, hasBeenPersisted } = useReportSetup();
  const groupId = useQueryParam("groupId");
  const groupType = useQueryParam("groupType");
  const isUserLevel = isUserReportLevel();
  let sections = templateConfig.sections;
  if (sections?.user && sections?.company) {
    sections = !isUserLevel ? sections.company : sections.user;
  }

  return (
    <>
      <div className="mb-4">
        {groupId && groupType && (
          <GroupBanner groupId={groupId} groupType={groupType} />
        )}
      </div>
      <Grid
        data-testid="report-insights-container"
        templateColumns="repeat(2, 1fr)"
        gap={4}
        minW="550px"
      >
        {(insightConfig ? [insightConfig.section] : sections).map(
          (Section: ReactComponentLike) => (
            <Section
              key={serializeKey}
              report={report}
              appId={appId}
              config={templateConfig}
              reportId={report.id}
              previewMode={false}
              hasBeenPersisted={hasBeenPersisted}
              showYAxis
            />
          ),
        )}
      </Grid>
      {!insightConfig && (
        <div className="my-5 flex w-full items-center gap-2">
          <div className="w-full">
            <Divider />
          </div>
          <div>
            <SparklesIcon className="h-4 text-gray-500" />
          </div>
          <div className="w-full">
            <Divider />
          </div>
        </div>
      )}
      {!insightConfig && (
        <div
          data-testid="report-ai-insights"
          className="mb-8 flex flex-col gap-4"
        >
          {report?.insights?.map((insight: IQuery) => (
            <ReportInsight query={insight} />
          ))}
        </div>
      )}
    </>
  );
};
