import React, { useEffect, useState } from "react";
import { AudienceHeader } from "modules/Audiences/Audience/AudienceHeader";
import { IAudience } from "core/types/Audience";
import { useUpdateAudienceMutation } from "core/models/audiences";
import { usePeopleList } from "core/hooks/usePeopleList";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useAppObjects } from "core/hooks/useAppObjects";
import { Breadcrumbs } from "core/design-system/components/Breadcrumbs";
import { PageContainer } from "core/components/PageContainer";
import { Button, Flex } from "@chakra-ui/react";
import { PeopleTabs } from "./People/Tabs";
import { ObjectList } from "./People/ObjectList";

export enum People {
  Contacts = "contacts",
  Groups = "groups",
}

const Audience: React.FC<{ audience: IAudience }> = ({ audience }) => {
  const { id: appId } = useCurrentApp();
  const { setActiveAppObject } = useAppObjects();

  const {
    activeAppObject,
    audienceFilters,
    searchQuery,
    setPage,
    setSearchQuery,
    onSetAudienceFilters,
    listConfig,
    entities,
    pagy,
    isLoading,
    skipPagination,
    currentPage,
    previousPage,
    nextPage,
    sortBy,
    sortOrder,
    setSortBy,
    setSortOrder,
  } = usePeopleList(true);

  useEffect(() => {
    if (audience?.appObject) {
      setActiveAppObject(audience.appObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audience?.appObject?.id]);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string | null>(null);

  const audienceFiltersLength = audienceFilters?.filterGroups?.length || 0;

  const hasUnsavedChanges =
    JSON.stringify(audienceFilters?.filterGroups) !==
      JSON.stringify(audience?.filterGroups) ||
    audience?.name !== name ||
    audience?.description !== description ||
    audienceFilters?.joinOperator !== audience?.joinOperator ||
    audience?.appObject?.id !== activeAppObject?.id;

  const [updateAudience] = useUpdateAudienceMutation();
  const handleSave = () => {
    if (!name && !audienceFilters) return;

    setIsEditing(false);
    updateAudience({
      audienceId: audience.id!,
      appId,
      name,
      description: description?.length ? description : null,
      audienceFilters,
      appObjectId: activeAppObject?.id,
    });
  };

  useEffect(() => {
    if (audience) {
      setName(audience.name ?? "");
      setDescription(audience.description ?? null);
      if (audience.filterGroups?.length > 0) {
        onSetAudienceFilters({
          filterGroups: audience.filterGroups,
          joinOperator: audience.joinOperator,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audience]);

  const audienceHeaderProps = {
    isEditing,
    setIsEditing,
    name,
    setName,
    description,
    setDescription,
    audience,
    audienceFiltersLength,
    audienceFilters,
    setAudienceFilters: onSetAudienceFilters,
  };

  const pages = [
    { name: "Audiences", path: `/a/${appId}/audiences`, current: false },
    { name: audience?.name ?? "Audience", path: "#", current: true },
  ];

  return (
    <PageContainer>
      <div className="mb-2 flex justify-between">
        <Breadcrumbs pages={pages} />
      </div>
      <div className="mt-6 flex justify-between">
        <div className="flex flex-col">
          <AudienceHeader {...audienceHeaderProps} audience={audience!} />
        </div>
        <Button
          className="flex-shrink-0"
          colorScheme="purple"
          onClick={handleSave}
          isDisabled={!hasUnsavedChanges}
        >
          Save
        </Button>
      </div>
      <Flex mt={5}>
        <PeopleTabs
          audienceFilters={audienceFilters}
          searchQuery={searchQuery}
          setPage={setPage}
        />
      </Flex>
      {activeAppObject && (
        <ObjectList
          appObject={activeAppObject}
          listConfig={listConfig}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          entities={entities}
          isLoading={isLoading}
          pagy={pagy}
          currentPage={currentPage}
          previousPage={previousPage}
          nextPage={nextPage}
          audienceFilters={audienceFilters}
          skipPagination={skipPagination}
          sortBy={sortBy}
          sortOrder={sortOrder}
          setSortBy={setSortBy}
          setSortOrder={setSortOrder}
        />
      )}
    </PageContainer>
  );
};

export default Audience;
