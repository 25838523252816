import humps from "humps";
import { baseQuery } from "core/initializers/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";

import type { SnakeCasedPropertiesDeep } from "type-fest";
export interface ICrmError {
  id: number;
  appId: number;
  appObject: string;
  integration: "hubspot" | "attio";
  errorMessage: string;
  errorType: string;
  isResolved: boolean;
  occurrencesCount: number;
  lastOccurredAt: string;
  createdAt: string;
}

export const crmErrorsApi = createApi({
  baseQuery,
  reducerPath: "crmErrorsApi",
  tagTypes: ["CrmErrors"],
  endpoints: (builder) => ({
    getCrmErrors: builder.query<
      ICrmError[],
      { appId: number; integration: string; objectTypeId: number }
    >({
      query: ({ appId, integration, objectTypeId }) => ({
        url: `/crm_errors`,
        params: humps.decamelizeKeys({
          appId,
          integration,
          objectTypeId,
        }),
      }),
      providesTags: ["CrmErrors"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<ICrmError[]>,
      ): ICrmError[] => humps.camelizeKeys(response) as ICrmError[],
    }),
    updateCrmError: builder.mutation<
      ICrmError,
      {
        id: number;
        isResolved: boolean;
        appId: number;
      }
    >({
      query: ({ id, isResolved, appId }) => ({
        url: `/crm_errors/${id}`,
        method: "PATCH",
        body: humps.decamelizeKeys({ isResolved, appId }),
      }),
      invalidatesTags: ["CrmErrors"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<ICrmError>,
      ): ICrmError => humps.camelizeKeys(response) as ICrmError,
    }),
  }),
});

export const { useGetCrmErrorsQuery, useUpdateCrmErrorMutation } = crmErrorsApi;
