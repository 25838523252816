import { useState } from "react";
import { Tab } from "./Tab";

export interface TabConfig {
  title: string;
  Component: React.FC;
}

type TabsProps = {
  tabs: TabConfig[];
  value?: string;
  onChange?: (tab: string) => void;
};

export const Tabs: React.FC<TabsProps> = ({ tabs, value, onChange }) => {
  const [selectedTab, setSelectedTab] = useState<string>(
    value ?? tabs[0]?.title,
  );

  const tab = tabs.find((tab) => tab.title === selectedTab);

  if (!tab) {
    return null;
  }

  return (
    <div className="flex w-full flex-col">
      <div className="z-10 flex w-full">
        {tabs.map((tab) => (
          <Tab
            isSelected={tab.title === selectedTab}
            onTabChange={() => {
              setSelectedTab(tab.title);
              onChange?.(tab.title);
            }}
          >
            {tab.title}
          </Tab>
        ))}
      </div>
      <hr className="relative bottom-[2px] w-full" />
      <div className="mt-8 flex w-full">{<tab.Component />}</div>
    </div>
  );
};
