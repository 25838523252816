import React, { useState, useEffect } from "react";
import axios from "core/initializers/axios";
import PropTypes from "prop-types";

const { Provider, Consumer } = React.createContext();

function AppProvider({ currentApp, setApp, children }) {
  const [appsList, setAppsList] = useState(undefined);

  const refreshAppsList = () => {
    axios
      .get(`/apps/`, {
        withCredentials: true,
      })
      .then(({ data }) => {
        setAppsList(data);
      })
      .catch((error) => console.log("Error getting apps list:", error));
  };
  const reloadApp = () => {
    axios
      .get(`/apps/${currentApp.id}`, {
        withCredentials: true,
      })
      .then(({ data }) => {
        setApp(data);
      })
      .catch((error) => console.log("Error getting app:", error));
  };

  const setCurrentApp = (app) => {
    setApp(app);
    axios
      .post(
        `/apps/${app.id}/last_used_app`,
        { appId: app.id },
        {
          withCredentials: true,
          mode: "cors",
        },
      )
      .catch((error) => console.log("Error setting last used app:", error));
  };

  useEffect(() => {
    refreshAppsList();
  }, []);

  const hasApps = appsList?.length > 0;
  return (
    <Provider
      value={{
        hasApps,
        setCurrentApp,
        currentApp,
        reloadApp,
        loadAppList: refreshAppsList,
        apps: appsList || [],
      }}
    >
      {children}
    </Provider>
  );
}

export { AppProvider, Consumer as AppConsumer };

AppProvider.propTypes = {
  currentApp: PropTypes.object,
  setApp: PropTypes.func,
  children: PropTypes.object,
};
