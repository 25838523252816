/**
 * Find the most reccuring value in an array
 *
 */

export const mode = (arr: any[]) => {
  return arr
    .sort(
      (a, b) =>
        arr.filter((v) => v === a).length - arr.filter((v) => v === b).length,
    )
    .pop();
};
