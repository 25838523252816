import { ThunkDispatch } from "@reduxjs/toolkit";
import { IAppObject } from "../appObjects";
import { slice } from "./index";
import { AnyAction } from "redux";
import { RootState } from "core/initializers/store";

export const {
  setActiveAppObject: _setActiveAppObject,
  setAppObjects: _setAppObjects,
} = slice.actions;

export const setActiveAppObject =
  ({ activeAppObject }: { activeAppObject: IAppObject }) =>
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(_setActiveAppObject({ activeAppObject }));
  };

export const setAppObjects =
  ({ appObjects }: { appObjects: IAppObject[] }) =>
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(_setAppObjects({ appObjects }));
  };
