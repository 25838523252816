import { IReport } from "core/types/Report";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { Button, Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { SqlItem } from "./SqlItem";
import { ShareItem } from "./ShareItem";
import { RefreshItem } from "./RefreshItem";
import { DuplicateItem } from "./DuplicateItem";
import { DeleteItem } from "./DeleteItem";
import { AskAiItem } from "./AskAIItem";
import { FavouriteItem } from "./FavouriteItem";

export const OverflowMenu: React.FC<{
  report: IReport;
}> = ({ report }) => {
  return (
    <Menu>
      <MenuButton as={Button} variant="ghost">
        <EllipsisVerticalIcon className="h-4" />
      </MenuButton>
      <MenuList>
        <ShareItem report={report} />
        <RefreshItem report={report} />
        <DuplicateItem report={report} />
        <AskAiItem />
        <SqlItem report={report} />
        <FavouriteItem report={report} />
        <DeleteItem report={report} />
      </MenuList>
    </Menu>
  );
};
