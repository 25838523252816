import { IBlockComponent } from "../ConversationContainer";
import { Message } from "../Message";
import { Result } from "../Graph/Result";

export const MessageResult: React.FC<IBlockComponent> = ({
  delay,
  isReverse,
  ...props
}) => {
  return (
    <Message delay={delay} isReverse={isReverse} w="700px">
      <Result result={props.result} query={props.query} />
    </Message>
  );
};
