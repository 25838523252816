import { useState } from "react";
import moment from "moment";
import { Integration } from "core/models/dataMappings";
import { ICrmSync } from "core/models/crmSyncs";
import { CrmEntityState } from "core/models/crmEntities";
import { AppObjectType, IAppObject } from "core/models/appObjects";
import usePagination from "core/hooks/usePagination";
import { useCrmEntities } from "core/hooks/useCrmEntities";
import { ToggleButton } from "core/components/Toggle";
import {
  GroupListSingleGroup,
  GroupType,
} from "core/components/Group/GroupListSingleGroup";
import { Drilldown } from "core/components/Drilldown";
import { UserListSingleContact } from "core/components/Contact/UserListSingleContact";
import { ComponentDefaultProps } from "@chakra-ui/react";
import { TraitsPopover } from "./TraitsPopover";

interface IRecordsDrilldown extends ComponentDefaultProps {
  appObject: IAppObject;
  integration: Integration;
  isOpen: boolean;
  onClose: () => void;
  crmSync?: ICrmSync;
}

export const RecordsDrilldown: React.FC<IRecordsDrilldown> = ({
  appObject,
  integration,
  isOpen,
  onClose,
  crmSync,
}) => {
  const [state, setState] = useState<CrmEntityState>(CrmEntityState.Synced);
  const { currentPage, nextPage, setPage } = usePagination({
    withURL: false,
    persist: false,
  });
  const { crmEntities, pagy, isLoadingCrmEntities } = useCrmEntities({
    integration,
    objectType: appObject.objectType,
    page: currentPage,
    state,
    crmSync,
  });

  return (
    <Drilldown isOpen={isOpen} onClose={onClose}>
      {(Drilldown) => (
        <>
          <Drilldown.Header>
            <Drilldown.Title
              title={appObject.pluralName}
              isLoading={isLoadingCrmEntities}
            />
            <Drilldown.Toggle>
              <ToggleButton
                isSelected={state === CrmEntityState.Synced}
                onToggle={() => {
                  setPage(1);
                  setState(CrmEntityState.Synced);
                }}
              >
                Synced
              </ToggleButton>
              <ToggleButton
                isSelected={state === CrmEntityState.NotSynced}
                onToggle={() => {
                  setPage(1);
                  setState(CrmEntityState.NotSynced);
                }}
              >
                Not synced
              </ToggleButton>
            </Drilldown.Toggle>
          </Drilldown.Header>
          <Drilldown.Body pt={4}>
            <Drilldown.List
              key={state}
              items={crmEntities}
              hasMore={crmEntities.length < Number(pagy?.count)}
              itemRenderer={({ item }) =>
                appObject.objectType === AppObjectType.User ? (
                  <UserListSingleContact
                    key={item.entityId}
                    id={item.entityId}
                    traits={item.traits}
                    description={
                      state === CrmEntityState.NotSynced ? (
                        <p className="text-xs">
                          <p className="inline-block capitalize">Skipped</p>{" "}
                          {moment.utc(item.updatedAt).fromNow()}
                        </p>
                      ) : (
                        <p className="text-xs">
                          <p className="inline-block capitalize">
                            {item.action}
                          </p>{" "}
                          {moment.utc(item.updatedAt).fromNow()} with{" "}
                          <TraitsPopover traits={item.traits} />
                        </p>
                      )
                    }
                  />
                ) : (
                  <GroupListSingleGroup
                    key={item.entityId}
                    id={item.entityId}
                    traits={item.traits}
                    groupType={
                      appObject.objectType === AppObjectType.Group
                        ? GroupType.Group
                        : GroupType.Company
                    }
                    description={
                      state === CrmEntityState.NotSynced ? (
                        <p className="text-xs">
                          <p className="inline-block capitalize">Skipped</p>{" "}
                          {moment.utc(item.updatedAt).fromNow()}
                        </p>
                      ) : (
                        <p className="text-xs">
                          <p className="inline-block capitalize">
                            {item.action}
                          </p>{" "}
                          {moment.utc(item.updatedAt).fromNow()} with{" "}
                          <TraitsPopover traits={item.traits} />
                        </p>
                      )
                    }
                  />
                )
              }
              loadNext={() => nextPage()}
              isLoading={isLoadingCrmEntities}
              emptyStateText={`No records synced`}
            />
          </Drilldown.Body>
        </>
      )}
    </Drilldown>
  );
};
