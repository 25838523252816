const baseStyle = {
  container: {
    shadow: "sm",
    bg: "white",
    borderRadius: 5,
    border: "1px solid gray.50",
    mb: 4,
    "&:last-child": {
      mb: 0,
    },
  },
  button: {
    _hover: { bg: "transparent" },
    _focus: { bg: "transparent" },
    fontSize: "md",
    fontWeight: "medium",
  },
};

export const Accordion = { baseStyle };
