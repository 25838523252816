import { useEffect, useState } from "react";
import { IFilterEvents } from "modules/Contacts/Contact/ContactActivity";
import humps from "humps";
import { Unit } from "core/types/Unit";
import { IContactActivityEvent } from "core/types/Contact";
import axios from "core/initializers/axios";
import {
  INTERVAL_TYPE_NUMBERS,
  IntervalTypeNames,
} from "core/constants/timerange";
import { useCurrentApp } from "./useCurrentApp";

interface IUseEventDrilldownProps {
  skip: boolean;
  unit: Unit;
  params: {
    id: string;
    date: string;
    intervalType: IntervalTypeNames;
    filterEvents: IFilterEvents;
  };
}

export const useEventDrilldown = ({
  skip,
  unit,
  params: { id, date, intervalType, filterEvents },
}: IUseEventDrilldownProps) => {
  const { id: appId } = useCurrentApp();
  const [isLoading, setIsloading] = useState(false);
  const [page, setPage] = useState(1);
  const [error, setError] = useState<string | null>(null);
  const [hasMoreEvents, setHasMoreEvents] = useState(false);
  const [events, setEvents] = useState<IContactActivityEvent[]>([]);
  const path = unit === Unit.Users ? "contacts" : "groups";

  const fetchEvents = async () => {
    if (skip) return;

    const response = await axios.get(
      `/${path}/${encodeURIComponent(id)}/events`,
      {
        params: humps.decamelizeKeys({
          appId,
          date,
          page,
          intervalType: INTERVAL_TYPE_NUMBERS[intervalType],
          filterEvents,
        }),
      },
    );

    if (response?.data?.pagy) {
      setHasMoreEvents(response.data.pagy.page !== response.data.pagy.last);
    }

    if (response?.data?.events) {
      const allEvents =
        page === 1
          ? response.data.events
          : [...events, ...response.data.events];

      setEvents(allEvents);
    } else {
      setError("Unable to fetch events data");
    }
  };

  const loadMore = () => {
    if (hasMoreEvents) setPage(page + 1);
  };

  useEffect(() => {
    setIsloading(true);

    fetchEvents();

    setIsloading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, path, appId, id, date, intervalType, page]);

  useEffect(() => {
    // On date change, reset the page to 1
    setPage(1);
  }, [date]);

  return {
    isLoading,
    error,
    hasMoreEvents,
    events,
    loadMore,
  };
};
