import { Code, Text } from "@chakra-ui/react";

export const UnidentifiedUserDescription = ({
  contactId,
}: {
  contactId: string;
}) => {
  return (
    <>
      <Text>
        We received track calls, but no identify calls for a user with{" "}
        <Code colorScheme={"gray"} color="red.400" children={contactId} /> as a
        userId. Send an identify call to add information about this user{" "}
      </Text>
      <Text
        as="a"
        fontWeight="semibold"
        color="yellow.600"
        target="blank"
        href="https://segment.com/docs/connections/spec/identify/"
      >
        Learn more →
      </Text>
    </>
  );
};
