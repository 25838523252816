import { Navigate } from "react-router-dom";
import { useCurrentApp } from "core/hooks/useCurrentApp";

const Contacts: React.FC = () => {
  const { id: appId } = useCurrentApp();
  const hash = window.location.pathname.replace(`/a/${appId}/contacts`, "");

  return <Navigate to={`/a/${appId}/people:${hash.slice(1)}`} />;
};

export default Contacts;
