import { Flex, Text, Button, ComponentDefaultProps } from "@chakra-ui/react";

interface ICreateFeatureInput extends ComponentDefaultProps {
  featureName: string;
  setFeatureName: (val: string) => void;
  onCreate: () => void;
  isLoading: boolean;
}

export const CreateFeatureInput: React.FC<ICreateFeatureInput> = ({
  featureName,
  setFeatureName,
  onCreate,
  isLoading,
}) => {
  return (
    <Flex direction="column" gap={4}>
      <Text fontSize="lg">What feature do you want to analyse?</Text>
      <input
        type="text"
        value={featureName}
        onChange={(e) => setFeatureName(e.currentTarget.value)}
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        placeholder={"Your feature"}
      />
      <Button
        isLoading={isLoading}
        isDisabled={featureName.length === 0}
        colorScheme="purple"
        onClick={onCreate}
      >
        Continue
      </Button>
    </Flex>
  );
};
