import React, { useState } from "react";
import { useInviteUsers } from "core/hooks/useInviteUsers";
import Icon from "core/design-system/components/Icon";
import { Box, Button, Flex, Input, Text } from "@chakra-ui/react";

export const AddTeammate: React.FC = () => {
  const [emails, setEmails] = useState<string>("");
  const { validationError, inviteUsers, inviteError } = useInviteUsers();

  return (
    <>
      {validationError ? (
        <Box px={4} py={2}>
          <Flex
            align="center"
            bg="red.50"
            borderRadius="lg"
            color="red.500"
            px={4}
            py={2}
          >
            <Icon iconType="icon" name="warning" mr={2} />
            <Text fontSize="sm">{validationError}</Text>
          </Flex>
        </Box>
      ) : undefined}

      <Flex
        px={4}
        py={2}
        w="100%"
        align="center"
        justifyContent="space-between"
      >
        <Input
          w="85%"
          focusBorderColor="purple.500"
          placeholder="example1@email.com, example2@email.com"
          type="email"
          name="email"
          value={emails}
          onChange={(e) => {
            setEmails(e.target.value);
          }}
          borderRadius="lg"
          size="sm"
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              inviteUsers({ event: e, emails, page: "manage_team" });
              if (!inviteError) setEmails("");
            }
          }}
        />
        <Button
          ml={2}
          py={2}
          px={4}
          colorScheme="purple"
          onClick={(e) =>
            inviteUsers({ event: e, emails, page: "manage_team" })
          }
          disabled={emails === ""}
        >
          Invite team members
        </Button>
      </Flex>
    </>
  );
};
