import { Integration, IProperty } from "core/models/dataMappings";
import { IAppObject } from "core/models/appObjects";
import { useToast } from "core/hooks/useToast";
import { IDataMapping, ITempDataMapping } from "core/hooks/useDataMappings";
import { useDataMapping } from "core/hooks/useDataMapping";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import {
  Command,
  CommandInput,
  CommandList,
  CommandOption,
} from "core/design-system/components/Command";
import {
  CalendarDaysIcon,
  ChevronDownIcon,
  HashtagIcon,
  LanguageIcon,
  Square2StackIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import {
  Button,
  ButtonGroup,
  IconButton,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { ExternalTraitsComponent } from "./ExternalTraitsComponent";

interface ISelectDestinationProps {
  dataMapping: IDataMapping | ITempDataMapping;
  externalProperty: IProperty | undefined;
  setExternalProperty: (property: IProperty | undefined) => void;
  setSearchValue: (value: string) => void;
  searchValue: string;
  properties: IProperty[];
  setPage: (page: number) => void;
  page: number;
  hasMore: boolean;
  usedExternalProperties: string[];
  integration: Integration;
  deleteTempMapping: (juneTrait: string) => void;
  appObject: IAppObject;
}

export const TYPE_TO_ICON = {
  timestamp: <CalendarDaysIcon className="h-4" />,
  datetime: <CalendarDaysIcon className="h-4" />,
  text: <LanguageIcon className="h-4" />,
  string: <LanguageIcon className="h-4" />,
  number: <HashtagIcon className="h-4" />,
  bool: <Square2StackIcon className="h-4" />,
} as { [key: string]: JSX.Element };

export const HUBSPOT_SUPPORTED_PROPERTY_TYPES = [
  "string",
  "bool",
  "number",
  "date",
  "datetime",
];

export const SALESFORCE_SUPPORTED_PROPERTY_TYPES = [
  "string",
  "textarea",
  "int",
  "double",
  "date",
  "datetime",
];

export const ATTIO_SUPPORTED_PROPERTY_TYPES = ["text", "number", "timestamp"];

export const SUPPORTED_TYPES_BY_INTEGRATION = {
  [Integration.Hubspot]: HUBSPOT_SUPPORTED_PROPERTY_TYPES,
  [Integration.Salesforce]: SALESFORCE_SUPPORTED_PROPERTY_TYPES,
  [Integration.Attio]: ATTIO_SUPPORTED_PROPERTY_TYPES,
} as { [key: string]: string[] };

export const SelectDestination: React.FC<ISelectDestinationProps> = ({
  dataMapping,
  externalProperty,
  setSearchValue,
  properties,
  setPage,
  page,
  hasMore,
  usedExternalProperties,
  integration,
  setExternalProperty,
  deleteTempMapping,
  appObject,
}) => {
  const { id: appId } = useCurrentApp();
  const toast = useToast();
  const { createMapping, updateMapping, deleteMapping } = useDataMapping({
    appId,
    integration,
    appObject,
  });

  function handleDataClick({
    source,
    destination,
  }: {
    source: string;
    destination: string;
  }) {
    if (dataMapping.id) {
      updateMapping({ id: dataMapping.id, source, destination });
      toast({
        title: "Updated successfully",
      });
    } else {
      createMapping({
        appId: Number(appId),
        source: dataMapping.source,
        integration,
        destination,
        objectType: appObject.objectType,
      })
        .unwrap()
        .then(() => {
          deleteTempMapping(dataMapping.source);
          toast({
            title: "Saved successfully",
          });
        });
    }
  }

  const onRemoveMapping = () => {
    if (dataMapping && dataMapping.id) {
      deleteMapping({ appId, integration, id: dataMapping.id });
      toast({
        title: "Removed successfully",
      });
      setExternalProperty(undefined);
    }
    deleteTempMapping(dataMapping.source);
  };

  const onSelect = (property: IProperty) => {
    handleDataClick({ source: dataMapping.source, destination: property.name });
    setExternalProperty(property);
  };

  return (
    <div className="max-w-full">
      {externalProperty && dataMapping ? (
        <ButtonGroup
          variant="ghost"
          size="sm"
          borderRadius="lg"
          isAttached
          bg="gray.50"
          maxW="full"
        >
          <Tooltip
            hasArrow
            label={
              !dataMapping.isEditable
                ? "This is a required mapping. It cannot be changed."
                : ""
            }
            placement="top"
            shouldWrapChildren
          >
            <Popover className="max-w-full">
              <PopoverButton
                className="max-w-full"
                disabled={!dataMapping.isEditable}
              >
                <Button variant="ghost" as={Button} maxW="full" pr={0}>
                  <p className="truncate">{externalProperty.label}</p>
                  <Tooltip
                    hasArrow
                    label={
                      !dataMapping.isEditable
                        ? "You cannot remove this mapping"
                        : "Remove mapping"
                    }
                    placement="top"
                  >
                    <IconButton
                      aria-label="Remove mapping"
                      icon={<XMarkIcon className="h-4 w-4" />}
                      isDisabled={!dataMapping.isEditable}
                      onClick={onRemoveMapping}
                    />
                  </Tooltip>
                </Button>
              </PopoverButton>
              <PopoverPanel>
                <Command className="w-[250px]">
                  <CommandInput
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    placeholder="Search properties..."
                  />
                  <CommandList>
                    {properties.length === 0 && (
                      <div className="flex items-center justify-center">
                        <span className="px-2 py-1 text-left sm:leading-4">
                          No properties found
                        </span>
                      </div>
                    )}
                    {properties.map((property, index) => (
                      <ExternalTraitsComponent
                        usedExternalProperties={usedExternalProperties}
                        property={property}
                        integration={integration}
                        key={index}
                        onSelect={() => onSelect(property)}
                      />
                    ))}
                    {hasMore && (
                      <CommandOption
                        value={() => {
                          setPage(page + 1);
                        }}
                      >
                        Load more...
                      </CommandOption>
                    )}
                  </CommandList>
                </Command>
              </PopoverPanel>
            </Popover>
          </Tooltip>
        </ButtonGroup>
      ) : (
        <ButtonGroup
          variant="ghost"
          size="sm"
          borderRadius="lg"
          isAttached
          bg="gray.50"
        >
          <Popover>
            <PopoverButton>
              <Button
                variant="ghost"
                bg="gray.50"
                as={Button}
                color="gray.600"
                rightIcon={<ChevronDownIcon className="h-3 w-3" />}
              >
                <Text noOfLines={1} maxW="180px">
                  Select a property
                </Text>
              </Button>
            </PopoverButton>
            <PopoverPanel>
              <Command className="w-[250px]">
                <CommandInput
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                  placeholder="Search properties..."
                />
                <CommandList>
                  {properties.length === 0 && (
                    <div className="flex items-center justify-center">
                      <span className="px-2 py-1 text-left sm:leading-4">
                        No properties found
                      </span>
                    </div>
                  )}
                  {properties.map((property, index) => (
                    <ExternalTraitsComponent
                      usedExternalProperties={usedExternalProperties}
                      property={property}
                      integration={integration}
                      onSelect={() => onSelect(property)}
                      key={index}
                    />
                  ))}

                  {hasMore && (
                    <CommandOption
                      value={() => {
                        setPage(page + 1);
                      }}
                    >
                      Load more...
                    </CommandOption>
                  )}
                </CommandList>
              </Command>
            </PopoverPanel>
          </Popover>
          <Tooltip hasArrow label="Remove mapping" placement="top">
            <IconButton
              aria-label="Remove mapping"
              icon={<XMarkIcon className="h-4 w-4" />}
              onClick={onRemoveMapping}
            />
          </Tooltip>
        </ButtonGroup>
      )}
    </div>
  );
};
