import { Link } from "react-router-dom";
import { Flex, ComponentDefaultProps } from "@chakra-ui/react";
import { usePinnedReports } from "core/hooks/usePinnedReports";

export interface IContainterProps extends ComponentDefaultProps {
  children: React.ReactNode;
  appId?: string | number;
  reportId?: string | number;
  level?: string;
}

export const Container: React.FC<IContainterProps> = ({
  appId,
  reportId,
  level = "user",
  children,
  ...props
}) => {
  const { isEditing } = usePinnedReports("home");
  if (reportId && !isEditing) {
    return (
      <Link to={`/a/${appId}/report/${reportId}?level=${level}`}>
        <Flex
          h="full"
          w="full"
          direction="column"
          border="1px solid"
          borderColor="gray.50"
          borderRadius={8}
          boxShadow="sm"
          bg="white"
          data-testid="highlight-card-container"
          {...props}
        >
          {children}
        </Flex>
      </Link>
    );
  }

  return (
    <Flex
      h="full"
      w="full"
      direction="column"
      border="1px solid"
      borderColor="gray.50"
      borderRadius={8}
      boxShadow="sm"
      bg="white"
      data-testid="highlight-card-container"
      {...props}
    >
      {children}
    </Flex>
  );
};
