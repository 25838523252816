import { useState } from "react";
import { transformFilterGroups } from "helpers/params";
import { IAudienceFilters } from "core/types/Audience";
import axios from "core/initializers/axios";
import { useToast } from "core/hooks/useToast";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import {
  TRAIT_LEVEL,
  TRAIT_LEVEL_PLURAL_STRING,
  TRAIT_LEVEL_RESOURCE,
  TRAIT_LEVEL_STRING,
} from "core/constants/traits";
import { JoinOperatorValue } from "core/constants/report-setup";
import {
  Alert,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";

interface ITraitEditorProps {
  id?: string | number | null;
  refetch?: () => void;
  traitLevel: TRAIT_LEVEL;
  isOpen: boolean;
  onClose: () => void;
  audienceFilters?: IAudienceFilters;
  searchQuery?: string;
  objectCount?: number;
}

export default function TraitEditor({
  id = null,
  refetch,
  traitLevel,
  isOpen,
  onClose,
  audienceFilters,
  searchQuery,
  objectCount,
}: ITraitEditorProps) {
  const { id: appId } = useCurrentApp();
  const isBulk = id === null;
  const traitLevelString = TRAIT_LEVEL_STRING[traitLevel];
  const traitLevelResource = TRAIT_LEVEL_RESOURCE[traitLevel];
  const traitLevelPluralString = TRAIT_LEVEL_PLURAL_STRING[traitLevel];

  const toast = useToast();

  const [trait, setTrait] = useState("");
  const [value, setValue] = useState("");

  const handleChange = (event: any) => {
    const { name, value: _value } = event.target;

    name === "trait" ? setTrait(_value) : setValue(_value);
  };

  const handleSucceeded = () => {
    const now = Date.now();
    localStorage.setItem(
      `${appId}-${id}-${now}-${traitLevelString}-trait`,
      JSON.stringify({ name: trait, value, updatedAt: now }),
    );

    onClose();
    if (refetch) {
      refetch();
    }
    setTrait("");
    setValue("");

    toast({
      title: "Saved successfully",
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const url = isBulk
      ? `/${traitLevelResource}/bulk_update`
      : `/${traitLevelResource}/${encodeURIComponent(id)}`;
    const baseParams = { appId, trait, value };
    const params = id
      ? baseParams
      : {
          ...baseParams,
          filter_groups: transformFilterGroups(
            audienceFilters?.filterGroups || [],
          ),
          join_operator: audienceFilters?.joinOperator || JoinOperatorValue.AND,
          query: searchQuery,
        };

    await axios
      // @ts-ignore
      .put(url, params, { withCredentials: true, mode: "cors" })
      .then(() => {
        handleSucceeded();
      })
      .catch((error) => {
        console.log(error, "API error: ");
        toast({
          status: "error",
          title: "There was an error",
          description: "Unable to successfully save new trait",
        });
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <p className="text-lg font-medium leading-6 text-gray-900">
            Add a trait{" "}
            {isBulk ? `to ${objectCount} ${traitLevelPluralString}` : ""}
          </p>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Alert status="info" mb="2" background="purple.50" rounded="sm">
            <InfoOutlineIcon fontSize="sm" color="purple.500" mr={2} />
            <p className="text-sm text-purple-500">
              You can override a trait by simply adding it again.
            </p>
          </Alert>
          <div className="mb-4 block">
            <p className="mb-1 text-sm font-medium text-gray-600">Trait</p>
            <Input
              placeholder="Trait"
              borderRadius="md"
              focusBorderColor="purple.500"
              type="text"
              name="trait"
              size="sm"
              onChange={handleChange}
            />
          </div>
          <div className="block">
            <p className="mb-1 text-sm font-medium text-gray-600">Value</p>
            <Input
              placeholder="Value"
              borderRadius="md"
              focusBorderColor="purple.500"
              size="sm"
              type="text"
              name="value"
              onChange={handleChange}
            />
          </div>
          <div className="mt-4 w-full">
            <p className="rounded-md bg-red-50 px-2 py-1 text-center text-sm text-red-500">
              <span className="font-semibold">Caution:</span> this cannot be
              undone!
            </p>
          </div>
          <div className="my-4 flex justify-end">
            <Button
              colorScheme="black"
              onClick={(e) => handleSubmit(e)}
              isDisabled={!trait.length || !value.length}
            >
              Apply trait
            </Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
