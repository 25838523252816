import { ComponentDefaultProps, Flex } from "@chakra-ui/react";

export interface IContainterProps extends ComponentDefaultProps {
  children: React.ReactNode;
}

export const Container: React.FC<IContainterProps> = ({
  children,
  ...props
}) => {
  return (
    <Flex
      _hover={{ boxShadow: "lg", borderColor: "gray.100", cursor: "pointer" }}
      transition="all .2s ease-in-out"
      h="full"
      w="full"
      direction="column"
      border="1px solid"
      borderColor="gray.50"
      borderRadius="lg"
      boxShadow="sm"
      bg="white"
      data-testid="highlight-card-container"
      {...props}
    >
      {children}
    </Flex>
  );
};
