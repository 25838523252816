import { Avatar } from "@chakra-ui/react";
import {
  getContactEmail,
  IGetContactEmailProps,
} from "core/helpers/contactEmail";
import { useContact } from "core/hooks/useContact";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { IFavourite } from "core/models/favourites";
import { useEffect } from "react";
import { LoadingItem } from "./LoadingItem";

export const ContactItem: React.FC<{ favourite: IFavourite }> = ({
  favourite,
}) => {
  const { id: appId } = useCurrentApp();
  const { contact, isLoadingContact, contactError, fetchContact } = useContact({
    appId,
    id: favourite.objectId,
  });

  const email = getContactEmail(contact as IGetContactEmailProps);

  useEffect(() => {
    fetchContact();
  }, [fetchContact]);

  if (contactError || !contact) return <></>;

  if (isLoadingContact) return <LoadingItem />;

  return (
    <div className="flex flex-row items-center gap-2">
      <Avatar size="2xs" name={email || favourite.objectId} />
      <p className="max-w-[240px] truncate text-[13px]">
        {email || favourite.objectId}
      </p>
    </div>
  );
};
