import humps from "humps";
import { IPagy } from "core/types/Pagy";
import { IEventLog } from "core/types/Debugger";
import { baseQuery } from "core/initializers/rtk";
import { transformResponseExceptPropertiesAndContext } from "core/helpers/transformResponseExceptPropertiesAndContext";
import { createApi } from "@reduxjs/toolkit/query/react";

import type { SnakeCasedPropertiesDeep } from "type-fest";
export interface IDebuggerData {
  result: {
    data: IEventLog[];
    pagy: IPagy;
  };
  eventType: string;
}

export interface IDebuggerChart {
  result: { [key: string]: { date: string; count: number }[] };
}

export const debuggerApi = createApi({
  baseQuery,
  reducerPath: "debuggerApi",
  tagTypes: ["Debugger"],
  endpoints: (builder) => ({
    getData: builder.query<
      IDebuggerData,
      { appId: number; eventType: string | null }
    >({
      query: ({ appId, eventType }) => ({
        url: `/debugger/data`,
        params: humps.decamelizeKeys({
          appId,
          eventType: eventType ?? undefined,
        }),
      }),
      providesTags: ["Debugger"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IDebuggerData>,
      ): IDebuggerData => {
        return transformResponseExceptPropertiesAndContext(
          response,
        ) as IDebuggerData;
      },
    }),
    getChart: builder.query<IDebuggerChart, { appId: number; days: number }>({
      query: ({ appId, days }) => ({
        url: `/debugger/chart`,
        params: humps.decamelizeKeys({ appId, days }),
      }),
      providesTags: ["Debugger"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IDebuggerChart>,
      ): IDebuggerChart => humps.camelizeKeys(response) as IDebuggerChart,
    }),
  }),
});

export const { useGetDataQuery, useLazyGetDataQuery, useGetChartQuery } =
  debuggerApi;
