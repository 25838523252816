import React from "react";
import { IAudienceFilters } from "core/types/Audience";
import { AudiencePreview } from "core/modules/reports/setup/Setup/HeaderPreviews/AudiencePreview";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { Disclosure } from "@headlessui/react";

interface IFiltersAccordionProps {
  audienceFilters: IAudienceFilters;
}

export const FiltersAccordion: React.FC<IFiltersAccordionProps> = ({
  audienceFilters,
}) => {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex items-center gap-1 py-2">
            <>
              <span className="text-sm font-medium">Filters</span>
              <ChevronRightIcon
                className={`h-4 w-4 ${open ? "rotate-90 transform" : ""}`}
              />
            </>
          </Disclosure.Button>

          <Disclosure.Panel className="mt-2 overflow-x-scroll p-2">
            <AudiencePreview audienceFilters={audienceFilters} />
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
