import { useNavigate } from "react-router-dom";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { Template, TEMPLATES } from "core/constants/templates";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { getTemplateConfig, getTemplateImage } from "./helpers";

interface ITemplateCardProps {
  template: Template;
  setSelectedTemplate: (template: Template) => void;
  onClose: () => void;
}

export const TemplateCard: React.FC<ITemplateCardProps> = ({
  template,
  setSelectedTemplate,
  onClose,
}) => {
  const { id: appId, features } = useCurrentApp();
  const navigate = useNavigate();
  const { title, description } = TEMPLATES[template];

  const handleSelectTemplate = (template: Template) => {
    onClose();
    const config = getTemplateConfig(template, features);
    navigate(`/a/${appId}/report/create/${config!.reportType}`);
  };

  return (
    <Flex
      direction="column"
      border="1px solid"
      borderRadius="lg"
      borderColor="gray.100"
      boxShadow="sm"
      bg="white"
      gap={4}
      p={4}
      cursor="pointer"
      onClick={() => handleSelectTemplate(template)}
      _hover={{
        boxShadow: "md",
        borderColor: "gray.400",
      }}
    >
      <Image
        borderRadius="lg"
        h="full"
        w="full"
        src={`${getTemplateImage(template)}`}
      />
      <Box>
        <Text fontWeight="medium" color="gray.800">
          {title}
        </Text>
        <Text color="gray.600" fontSize="sm">
          {description}
        </Text>
      </Box>
    </Flex>
  );
};
