import { useParams } from "react-router-dom";
import DefaultHeader from "modules/Pages/DefaultHeader";
import { useLabels } from "core/hooks/useLabels";
import {
  InfoBody,
  InfoCard,
  InfoHeader,
  InfoReadMoreLink,
} from "core/design-system/components/InfoCard";
import { Box } from "@chakra-ui/react";
import { LabelList } from "./LabelList";
import { CreateLabels } from "./CreateLabels";

const SECTION = {
  name: "Labels",
  title: "Labels",
  description: "Manage workspace labels",
};

export const Labels: React.FC = () => {
  const { appId } = useParams<{ appId: string }>() as {
    appId: string;
  };
  const { labels } = useLabels({ appId });

  return (
    <Box data-testid="labels-container">
      <DefaultHeader section={SECTION} />
      <div className="flex flex-col gap-4">
        <InfoCard>
          <InfoHeader>What is a label?</InfoHeader>
          <InfoBody>
            Use labels to organize reports in your workspace. You can filter by
            label on the reports list to find the reports you need. Labels
            created in this section apply to your whole workspace.
          </InfoBody>
          <InfoReadMoreLink href="https://help.june.so/en/articles/6820591-setting-up-and-using-labels" />
        </InfoCard>

        <CreateLabels />
        <LabelList labels={labels} />
      </div>
    </Box>
  );
};
