import humps from "humps";
import { IUser } from "core/types/User";
import { baseQuery } from "core/initializers/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";

interface ILoginArgs {
  email: string;
  password: string;
  anonymousId?: string;
}

interface ILoginResponse {
  data: IUser;
  errors?: string[];
}

interface IIsLoggedinResponse {
  isLoggedIn: boolean;
  user: IUser;
  features: string[];
}

interface ISignupArgs {
  email: string;
  password: string;
  passwordConfirmation: string;
  anonymousId?: string;
}

interface ISignupResponse {
  status: string;
  data: IUser;
  error?: ISignupErrorResponse;
}

interface IUpdateOnboardingTraitsArgs {
  user: IUser;
  source: string;
  companySize?: string;
  business?: string;
}

interface ISignupErrorResponse {
  status: number;
  error: string;
}

export const authApi = createApi({
  baseQuery,
  reducerPath: "authApi",
  tagTypes: ["Auth"],
  endpoints: (builder) => ({
    login: builder.mutation<ILoginResponse, ILoginArgs>({
      query: ({ email, password, anonymousId }) => ({
        url: "/login",
        method: "POST",
        body: { user: { email, password, anonymous_id: anonymousId } },
      }),
      invalidatesTags: ["Auth"],
      transformResponse: (response: ILoginResponse): ILoginResponse =>
        humps.camelizeKeys(response) as ILoginResponse,
    }),
    updateOnboardingTraits: builder.mutation<void, IUpdateOnboardingTraitsArgs>(
      {
        query: ({ user, source, companySize, business }) => ({
          url: `/users/${user.id}/update_onboarding_traits`,
          method: "PUT",
          body: {
            onboarding_traits: { source, company_size: companySize, business },
          },
        }),
        invalidatesTags: ["Auth"],
      },
    ),
    signup: builder.mutation<ISignupResponse, ISignupArgs>({
      query: ({ email, password, passwordConfirmation, anonymousId }) => ({
        url: "/users",
        method: "POST",
        body: {
          email,
          password,
          password_confirmation: passwordConfirmation,
          anonymous_id: anonymousId,
        },
      }),
      invalidatesTags: ["Auth"],
      transformResponse: (response: ISignupResponse): ISignupResponse =>
        humps.camelizeKeys(response) as ISignupResponse,
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: "/logout",
        method: "DELETE",
        withCredentials: true,
      }),
      invalidatesTags: ["Auth"],
    }),
    getIsLoggedIn: builder.query<IIsLoggedinResponse, void>({
      query: () => ({
        url: "/logged_in",
        withCredentials: true,
      }),
      providesTags: ["Auth"],
      transformResponse: (response: IIsLoggedinResponse): IIsLoggedinResponse =>
        humps.camelizeKeys(response) as IIsLoggedinResponse,
    }),
  }),
});

export const {
  useLoginMutation,
  useGetIsLoggedInQuery,
  useSignupMutation,
  useLogoutMutation,
  useUpdateOnboardingTraitsMutation,
} = authApi;
