import { useState } from "react";
import moment from "moment";
import { IReportInsight } from "core/types/Report";
import { isUserReportLevel } from "core/modules/reports/utils";
import useInsightDataFetching from "core/hooks/useInsightDataFetching";
import { useAppObjects } from "core/hooks/useAppObjects";
import { HeaderInsightStat } from "core/design-system/components/HeaderInsightStat";
import { AdoptionState, AdoptionStateNames } from "core/constants/adoption";
import InsightCard from "core/components/InsightCard/Index";
import Error from "core/components/Error";
import { Grid, useDisclosure } from "@chakra-ui/react";
import { adoptionChangesMockData } from "./mockData";
import { AdoptionUserList } from "./AdoptionUserList";

export const HeaderInsight: React.FC<IReportInsight> = ({
  previewMode,
  sharingMode,
  screenshotMode,
  sharingSecretToken,
  report,
  config,
  reportLevel,
}) => {
  const isUserReport = isUserReportLevel(reportLevel);
  const insightSlug = isUserReport
    ? "adoption-changes"
    : "adoption-company-changes";
  const insight = config.insights.find(({ slug }) => slug === insightSlug);
  const isSetupValid = config.validateSetup(report.config);
  const isSharingOrPreview = sharingMode || previewMode;
  const [adoptionState, setAdoptionState] = useState<AdoptionState>();
  const [adoptionCount, setAdoptionCount] = useState<number>(0);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { activeAppObject } = useAppObjects();

  const { response, isLoading, isFetching, error, onRefreshInsight } =
    useInsightDataFetching({
      report,
      sharingSecretToken,
      insightType: insight?.typeId,
      previewMode,
      skip: !isSetupValid,
    });

  const unit = isUserReport
    ? "users"
    : activeAppObject?.pluralName.toLowerCase();
  const data = previewMode ? adoptionChangesMockData : response?.data;

  if (!insight) return null;

  if (error) {
    return (
      <InsightCard>
        {(Card) => (
          <Card.Container id="adoption-error">
            <Error
              position="relative"
              w="100%"
              h="200px"
              background="white"
              p={16}
              borderRadius="lg"
            />
          </Card.Container>
        )}
      </InsightCard>
    );
  }

  const notAdoptedData = data?.notAdopted;
  const adoptedData = data?.adopted;
  const featureReleasedTimestamp = data?.featureReleasedTimestamp;
  const featureReleaseDate =
    featureReleasedTimestamp &&
    moment(featureReleasedTimestamp).format("MMMM Do YYYY");

  const onButtonClick = (
    adoptionState: AdoptionState,
    adoptionCount: number,
  ) => {
    if (!isSharingOrPreview) {
      setAdoptionState(adoptionState);
      setAdoptionCount(adoptionCount);
      onOpen();
    }
  };

  return (
    <InsightCard>
      {(Card) => (
        <Card.Container id={insight.slug} insight={insight}>
          <Card.Header
            hasCaching={response?.hasCaching}
            screenshotMode={screenshotMode}
            showReportTitle={sharingMode}
            insight={insight}
            showActions={!sharingMode && !previewMode}
            sharingMode={sharingMode}
            config={config}
            report={report}
            refresh={onRefreshInsight}
            cachedAt={response?.cachedAt}
          />
          <Card.Body
            isLoading={!data || isLoading || isFetching}
            isPreviewMode={previewMode}
            isSetupValid={isSetupValid}
            error={error}
          >
            <Grid templateColumns="repeat(2, 1fr)" id="loaded">
              <HeaderInsightStat
                label={AdoptionStateNames[AdoptionState.NotAdopted]}
                headingTooltip={`Percentage of ${unit} that never triggered adoption nor set up event(s) since the feature launch`}
                mainCount={`${notAdoptedData && notAdoptedData.percentage}%`}
                isLoading={isLoading}
                percentChange={notAdoptedData && notAdoptedData.change}
                reverseChangeColor={true}
                changeText="vs last week"
                buttonText={isSharingOrPreview ? undefined : "View"}
                onButtonClick={() =>
                  onButtonClick(AdoptionState.NotAdopted, notAdoptedData.count)
                }
                showProgressBar={true}
                progressBarColor="gray"
                firstStat={true}
              />
              <HeaderInsightStat
                label={AdoptionStateNames[AdoptionState.Adopted]}
                mainCount={`${adoptedData && adoptedData.percentage}%`}
                percentChange={adoptedData && adoptedData.change}
                changeText="vs last week"
                headingTooltip={`Percentage of ${unit} that have triggered adoption event(s) at least once since the feature launch`}
                isLoading={isLoading}
                buttonText={isSharingOrPreview ? undefined : "View"}
                onButtonClick={() =>
                  onButtonClick(AdoptionState.Adopted, adoptedData.count)
                }
                showProgressBar={true}
                lastStat={true}
              />
              {!isSharingOrPreview && adoptionState && (
                <AdoptionUserList
                  report={report}
                  unit={unit}
                  isOpen={isOpen}
                  onClose={onClose}
                  adoptedCount={adoptionCount}
                  adoptionState={adoptionState}
                  adoptionUserType={adoptionState}
                  date={featureReleasedTimestamp}
                  humanizedDate={featureReleaseDate}
                  isOverall={true}
                  showToggle={false}
                />
              )}
            </Grid>
          </Card.Body>
        </Card.Container>
      )}
    </InsightCard>
  );
};
