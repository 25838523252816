import { Link, useNavigate } from "react-router-dom";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { FEATURE_RELEASE } from "core/constants/report-configurations";
import { Button, Flex, Text, Tooltip } from "@chakra-ui/react";

export const Header = () => {
  const { id: appId, isDemoApp } = useCurrentApp();
  const navigate = useNavigate();

  return (
    <Flex pb={4} justifyContent="space-between" alignItems="center">
      <Flex>
        <Text fontWeight="bold" fontSize="xl">
          Your reports
        </Text>
      </Flex>
      <Flex align="center">
        <Button ml={2} as={Link} to={`/a/${appId}/settings/team`}>
          Invite team
        </Button>
        <Tooltip
          label={isDemoApp && "You cannot create reports in demo mode"}
          hasArrow
        >
          <Button
            ml={2}
            isDisabled={isDemoApp}
            onClick={() => {
              !isDemoApp &&
                navigate(`/a/${appId}/report/create/${FEATURE_RELEASE}`);
            }}
            colorScheme="purple"
          >
            New report +
          </Button>
        </Tooltip>
      </Flex>
    </Flex>
  );
};

export default Header;
