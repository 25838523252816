import { useEffect, useState } from "react";
import _ from "lodash";
import { cx } from "helpers/cx";
import { ITableTraitConfig } from "core/types/TableTraitConfig";
import { IPagy } from "core/types/Pagy";
import { IGroup } from "core/types/Group";
import { IContact } from "core/types/Contact";
import { ITraitKeyResponse } from "core/models/traits";
import { useUpdateTableTraitConfigMutation } from "core/models/tableTraitConfig";
import { AppObjectType, IAppObject } from "core/models/appObjects";
import { usePeopleTableColumns } from "core/hooks/usePeopleTableColumns";
import { Pagination } from "core/components/List/Pagination";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  MouseSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { Th } from "./Th";
import { Td } from "./Td";
import { LoadingState } from "./LoadingState";
import { EmptyState } from "./EmptyState";
import { AddColumnButton } from "../../List/private/AddColumnButton";

export const Table: React.FC<{
  data: IContact[] | IGroup[];
  appObject: IAppObject;
  tableTraitConfigs: ITableTraitConfig[];
  traits: ITraitKeyResponse[];
  createTraitConfig?: ({
    trait,
    objectType,
  }: {
    trait: string;
    objectType: AppObjectType;
  }) => void;
  deleteTraitConfig: ({ id }: { id: string }) => void;
  updateTraitConfigOrder: ({
    order,
  }: {
    order: { id: number; order: number }[];
  }) => void;
  hasFilters: boolean;
  isLoading: boolean;
  pagination?: {
    currentPage: number;
    previousPage: () => void;
    nextPage: () => void;
  };
  pagy?: IPagy;
  onClearFilters?: () => void;
  sortBy: string;
  sortOrder: string;
  setSortBy: (sortBy: string) => void;
  setSortOrder: (sortOrder: string) => void;
}> = ({
  data,
  appObject,
  tableTraitConfigs,
  traits,
  createTraitConfig,
  deleteTraitConfig,
  updateTraitConfigOrder,
  onClearFilters,
  pagination,
  pagy,
  hasFilters,
  isLoading,
  sortBy,
  sortOrder,
  setSortBy,
  setSortOrder,
}) => {
  const { columns, columnOrder, setColumnOrder } = usePeopleTableColumns(
    appObject,
    tableTraitConfigs,
    deleteTraitConfig,
    sortBy,
    sortOrder,
    setSortBy,
    setSortOrder,
  );
  const [scrollPos, setScrollPos] = useState(0);
  const [updateTableTraitConfig] = useUpdateTableTraitConfigMutation();

  const table = useReactTable({
    data,
    columns,
    state: { columnOrder },
    columnResizeMode: "onChange",
    columnResizeDirection: "ltr",
    onColumnOrderChange: setColumnOrder,
    getCoreRowModel: getCoreRowModel(),
  });

  const sensors = useSensors(useSensor(MouseSensor, {}));

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const activeIndex = columnOrder.indexOf(active.id as string);
      const overIndex = columnOrder.indexOf(over.id as string);

      // Prevent reordering of the first two columns
      if (activeIndex < 1 || overIndex < 1) {
        return;
      }

      setColumnOrder((columnOrder) => {
        return arrayMove(columnOrder, activeIndex, overIndex);
      });
    }
  }

  useEffect(() => {
    // Pin the first column to the left
    table.getHeaderGroups().forEach((headerGroup) => {
      headerGroup.headers[0].column.pin("left");
    });
  }, [table]);

  useEffect(() => {
    if (columns.length !== columnOrder.length) return;
    if (columnOrder.length === 0) return;
    if (
      _.isEqual(
        columns.map((c) => c.id),
        columnOrder,
      )
    )
      return;

    const newOrder = columnOrder.slice(1).map((o, index) => {
      const traitConfig = tableTraitConfigs.find((t) => t.trait === o);
      return { id: traitConfig?.id as number, order: index } as {
        id: number;
        order: number;
      };
    });
    updateTraitConfigOrder({ order: newOrder });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnOrder, tableTraitConfigs]);

  function handleResize(traitName: string, size: number) {
    const traitConfig = tableTraitConfigs.find((t) => t.trait === traitName);

    if (traitConfig) {
      updateTableTraitConfig({
        appId: appObject.appId,
        id: String(traitConfig.id),
        size,
      });
    }
  }

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <div className="z-0 flex h-full min-h-[500px] flex-col justify-between">
        <div
          className="mx-0 mt-5 min-h-[500px] max-w-full overflow-x-scroll"
          onScroll={(e) => setScrollPos(e.currentTarget.scrollLeft)}
        >
          <table
            className="divide-y divide-gray-100"
            style={{
              width: table.getCenterTotalSize(),
            }}
          >
            <thead>
              {table.getHeaderGroups().map((headerGroup) => {
                return (
                  <tr
                    className="min-w-full divide-x divide-gray-100"
                    key={headerGroup.id}
                  >
                    <SortableContext
                      items={columnOrder.slice(1)}
                      strategy={horizontalListSortingStrategy}
                    >
                      {headerGroup.headers.map((header, headerIndex) => {
                        return (
                          <Th
                            header={header}
                            headerIndex={headerIndex}
                            showShadow={scrollPos > 0}
                            isSortedBy={sortBy.includes(header.id)}
                            table={table}
                            columnResizeMode="onChange"
                            onResized={(size) => handleResize(header.id, size)}
                            appObject={appObject}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                          </Th>
                        );
                      })}
                      <th className={cx("w-full px-1")}>
                        <AddColumnButton
                          existingConfiguredTraits={(
                            tableTraitConfigs || []
                          ).map((t: ITableTraitConfig) => t.trait)}
                          createTraitConfig={createTraitConfig}
                          traits={traits}
                        />
                      </th>
                    </SortableContext>
                  </tr>
                );
              })}
            </thead>
            <tbody>
              {!isLoading &&
                table.getRowModel().rows.map((row, rowIndex) => {
                  return (
                    <tr className="divide-x divide-gray-100" key={row.id}>
                      {row.getVisibleCells().map((cell, cellIndex) => {
                        return (
                          <SortableContext
                            key={cell.id}
                            items={columnOrder.slice(1)}
                            strategy={horizontalListSortingStrategy}
                          >
                            <Td
                              cell={cell}
                              cellIndex={cellIndex}
                              lastRow={data.length - 1 === rowIndex}
                              showShadow={scrollPos > 0}
                              isSortedBy={sortBy.includes(cell.column.id)}
                              appObject={appObject}
                            >
                              <p className="truncate">
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext(),
                                )}
                              </p>
                            </Td>
                          </SortableContext>
                        );
                      })}
                    </tr>
                  );
                })}
              {isLoading && (
                <LoadingState
                  appObject={appObject}
                  tableTraitConfigs={tableTraitConfigs}
                />
              )}
            </tbody>
          </table>
          {table.getRowModel().rows.length === 0 && (
            <EmptyState
              appObject={appObject}
              hasFilters={hasFilters}
              onClearFilters={onClearFilters}
            />
          )}
        </div>
        <div className="mt-3">
          {pagination && pagy && (
            <Pagination
              pagination={pagination}
              pagy={pagy}
              resourceName={appObject.pluralName}
              rowCount={table.getRowModel().rows.length}
              skipPagination={true}
            />
          )}
        </div>
      </div>
    </DndContext>
  );
};
