import { useCallback } from "react";
import { useLazyFetchBillingPortalQuery } from "core/models/payment";
import { useCurrentApp } from "core/hooks/useCurrentApp";

export const useBilling = () => {
  const { id: appId, stripeCustomerId } = useCurrentApp();
  const [fetch, { isLoading }] = useLazyFetchBillingPortalQuery();

  const redirectToBillingPortal = useCallback(async () => {
    const { data } = await fetch(appId);
    window.open(data?.sessionUrl, "_blank")?.focus();
  }, [appId, fetch]);

  return {
    isLoading,
    isStripeCustomer: !!stripeCustomerId,
    redirectToBillingPortal,
  };
};
