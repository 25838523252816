import React from "react";
import { EventType, TRACK, PAGE } from "core/constants/report-setup";
import { EventIcon } from "core/design-system/components/Icon/Custom/EventIcon";
import { PageViewIcon } from "core/design-system/components/Icon/Custom/PageView";
import { Link } from "@chakra-ui/react";
import { ChevronRightIcon, MoonIcon } from "@chakra-ui/icons";

interface IListEmptyStateProps {
  resourceName?: string;
  eventType?: EventType;
  isEventResource?: boolean;
}

export const ListEmptyState: React.FC<IListEmptyStateProps> = ({
  resourceName,
  eventType,
  isEventResource,
}) => {
  return (
    <div className="flex h-[64vh] w-full flex-col items-center justify-center">
      {isEventResource && eventType === TRACK && (
        <div className="flex flex-col items-center justify-center gap-1 text-sm">
          <div className="mb-4 rounded-full border border-gray-200 p-5">
            <EventIcon className="h-6 w-6 text-purple-500" />
          </div>
          <div className="font-medium text-black">
            We couldn't find any Events
          </div>
          <div className="text-gray-600">
            Events can be used to track the actions of your users.
          </div>
          <Link
            className="font-medium"
            onClick={(e) => {
              e.preventDefault();
              window.Intercom("showArticle", "5095720");
            }}
          >
            Learn more
            <ChevronRightIcon w={4} h={4} />
          </Link>
        </div>
      )}

      {isEventResource && eventType === PAGE && (
        <div className="flex flex-col items-center justify-center gap-1 text-sm">
          <div className="mb-4 rounded-full border border-gray-200 p-5">
            <PageViewIcon className="h-6 w-6 text-purple-500" />
          </div>
          <div className="font-medium text-black">
            We couldn't find any Pageviews
          </div>
          <div className="text-gray-600">
            Pageviews with the <code>url</code> and <code>path</code> properties
            will show up here
          </div>
          <Link
            className="font-medium"
            onClick={(e) => {
              e.preventDefault();
              window.Intercom("showArticle", "8180056");
            }}
          >
            Learn more
            <ChevronRightIcon w={4} h={4} />
          </Link>
        </div>
      )}

      {!isEventResource && resourceName && (
        <div className="flex flex-col items-center justify-center gap-3 text-sm">
          <div className="mb-4 rounded-full border border-gray-200 p-6">
            <MoonIcon color="purple.500" w={6} h={6} />
          </div>
          <div className="font-medium text-black">
            We couldn't find any {resourceName}
          </div>
        </div>
      )}
    </div>
  );
};
