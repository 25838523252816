import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { pluralize } from "helpers/pluralize";
import { IInsightSection } from "core/types/TemplateConfig";
import { IReport } from "core/types/Report";
import useInsightDataFetching from "core/hooks/useInsightDataFetching";
import { GroupListSingleGroup } from "core/components/Group/GroupListSingleGroup";
import Error from "core/components/Error";
import { Drilldown } from "core/components/Drilldown";
import { Button, Flex, Spinner, UseDisclosureReturn } from "@chakra-ui/react";
import { IMostActiveGroup } from "./MostActiveGroupsContainer";

interface IMostActiveGroupsListProps
  extends Pick<UseDisclosureReturn, "isOpen" | "onClose"> {
  insight: IInsightSection;
  previewMode: boolean;
  report: IReport;
  sharingSecretToken?: string | null;
}

export const MostActiveGroupsList: React.FC<IMostActiveGroupsListProps> = ({
  isOpen,
  onClose,
  insight,
  sharingSecretToken,
  previewMode,
  report,
}) => {
  const { appId } = useParams<{ appId: string; reportId: string }>();

  const [page, setPage] = useState(0);
  const [mostActiveGroups, setMostActiveGroups] = useState<IMostActiveGroup[]>(
    [],
  );

  const { response, isLoading, error } = useInsightDataFetching({
    insight,
    report,
    insightParams: { page, limit: 18 },
    sharingSecretToken,
    previewMode,
  });

  const loadNext = () => {
    if (mostActiveGroups.length < 100) setPage(page + 1);
  };

  useEffect(() => {
    if (response?.data) {
      const allGroups = [...mostActiveGroups, ...response?.data?.groups];
      setMostActiveGroups(allGroups);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (error) {
    return <Error />;
  }

  const hasMore = mostActiveGroups.length < 100;

  return (
    <Drilldown isOpen={isOpen} onClose={onClose}>
      {(Drilldown) => (
        <>
          <Drilldown.Header>
            <Drilldown.Title title={insight.title} isLoading={isLoading} />
          </Drilldown.Header>
          <Drilldown.Body>
            {mostActiveGroups.map((group: IMostActiveGroup) => (
              <GroupListSingleGroup
                key={group.groupId}
                id={group.groupId}
                traits={group.traits}
                groupType={group.groupType}
                appId={Number(appId)}
                description={`Used ${group.eventCount} ${pluralize(group.eventCount, "time", "times")} ${
                  group.dayCount === 1
                    ? `for ${group.dayCount} day`
                    : `over ${group.dayCount} days`
                }`}
              />
            ))}
            {hasMore && (
              <Flex justify="center">
                <Button isDisabled={isLoading} onClick={loadNext}>
                  {isLoading ? <Spinner /> : "Load more"}
                </Button>
              </Flex>
            )}
          </Drilldown.Body>
        </>
      )}
    </Drilldown>
  );
};
