import { MoonIcon } from "@heroicons/react/24/solid";

export const EmptyState: React.FC = () => {
  return (
    <div className="ml-6 flex h-full w-full items-center justify-center">
      <div className="flex flex-col items-center justify-center gap-4">
        <div className="flex h-8 w-8 items-center justify-center rounded bg-purple-50 p-2">
          <MoonIcon className="h-4 w-4 text-purple-500" />
        </div>
        <div className="flex flex-col items-center">
          <p className="text-xs font-medium">Nothing to see here</p>
        </div>
      </div>
    </div>
  );
};
