import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useViews, ViewLocation } from "core/hooks/useViews";
import { Spinner } from "@chakra-ui/react";
import { selectAppId, selector as appSelector } from "../core/models/app";

function Home() {
  const { isLoading } = useSelector(appSelector);
  const appId = useSelector(selectAppId);
  const { views } = useViews({ location: ViewLocation.Home, pinned: true });
  const navigate = useNavigate();

  useEffect(() => {
    if (views && views?.length > 0) {
      navigate(`/a/${appId}/home/${views?.[0]?.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [views?.length]);

  if (!appId) {
    return <Navigate to={`/`} />;
  }

  if (isLoading) return <Spinner />;

  return null;
}

export default Home;
