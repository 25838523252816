import axios from "axios";
import humps from "humps";

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_HOST,
  transformResponse: [
    ...axios.defaults.transformResponse,
    (data) => humps.camelizeKeys(data),
  ],
  transformRequest: [
    (data) => humps.decamelizeKeys(data),
    ...axios.defaults.transformRequest,
  ],
});

instance.defaults.withCredentials = true;

export default instance;
