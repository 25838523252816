import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import React, { useRef, useState } from "react";
import { createApp } from "core/models/app";
import { useUserAuth } from "core/hooks/useUserAuth";
import { useNavigation } from "core/hooks/useNavigation";
import useFlag from "core/hooks/useFlag";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useBilling } from "core/hooks/useBilling";
import ArrowLeftBackSquare from "core/design-system/components/Icon/Interface/Essential/ArrowLeftBackSquare";
import { SolidChevronDown } from "core/design-system/components/Icon/Custom/SolidChevronDown";
import {
  Command,
  CommandList,
  CommandOption,
} from "core/design-system/components/Command";
import { USAGE_BASED_PRICING } from "core/constants/features";
import { DEMO_APP_ID } from "core/constants/appIds";
import {
  ArrowRightOnRectangleIcon,
  BanknotesIcon,
  Cog6ToothIcon,
  CommandLineIcon,
  DocumentPlusIcon,
  MagnifyingGlassIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/20/solid";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ComponentDefaultProps,
  Divider,
  Input,
  Tooltip,
} from "@chakra-ui/react";
import { WorkspaceList } from "./WorkspaceList";
import AppLogo from "./AppLogo";
import { isWindows } from "helpers/isWindows";
import { FEATURE_RELEASE } from "core/constants/report-configurations";
import { motion } from "framer-motion";
import { cx } from "helpers/cx";

const Item: React.FC<ComponentDefaultProps> = ({
  isDisabled,
  children,
  onClick = () => {},
  value,
}) => {
  return (
    <CommandOption onClick={onClick} disabled={isDisabled} value={value}>
      {children}
    </CommandOption>
  );
};

export const ExpandedHeader: React.FC<{ showCollapseIcon: boolean }> = ({
  showCollapseIcon,
}) => {
  const dispatch = useDispatch();
  const currentApp = useCurrentApp();
  const { toggleSidebar } = useNavigation();

  const { logout } = useUserAuth();
  const [isCreateAppModalOpen, setCreateAppModal] = useState(false);
  const [newAppName, setAppName] = useState<string>();
  const deleteCreateWorkspaceRef = useRef<HTMLButtonElement>(null);
  const { redirectToBillingPortal, isStripeCustomer } = useBilling();

  const hasUsageBasedPricing = useFlag(USAGE_BASED_PRICING);

  const handleLogout = (event: React.MouseEvent) => {
    event.preventDefault();
    logout();
  };

  const navigate = useNavigate();

  const openCmdk = () => {
    document.dispatchEvent(
      new KeyboardEvent("keydown", { key: "k", metaKey: true }),
    );
  };

  if (!currentApp) {
    return null;
  }

  return (
    <>
      <div className="flex w-full max-w-[290px] justify-between">
        <div className="flex w-full max-w-full items-center justify-between hover:bg-gray-100">
          <Popover className="h-full min-h-[50px] w-full px-2">
            <PopoverButton className="h-full w-full hover:bg-gray-100 focus:outline-none">
              <div className="flex h-full w-full items-center gap-2">
                <div>
                  <AppLogo currentApp={currentApp} />
                </div>
                <div className="flex max-w-[140px] items-center">
                  <p className="truncate text-sm font-semibold">
                    {currentApp.name}
                  </p>
                  <div>
                    <SolidChevronDown className="ml-1 h-4 w-4 text-gray-900" />
                  </div>
                </div>
              </div>
            </PopoverButton>
            <PopoverPanel className="w-full">
              {({ close }) => (
                <Command className="max-h-full w-full max-w-[270px]">
                  <CommandList>
                    <WorkspaceList currentApp={currentApp} onClose={close} />
                    <Divider my={0.5} />
                    <Item
                      value=""
                      isDisabled={currentApp.id === DEMO_APP_ID}
                      onClick={() =>
                        currentApp.id !== DEMO_APP_ID && setCreateAppModal(true)
                      }
                    >
                      <div className="flex items-center gap-2">
                        <PlusIcon className="h-4 w-4" />
                        <p>Create new workspace</p>
                      </div>
                    </Item>
                    <Divider my={0.5} />
                    <Item
                      value="settings"
                      onClick={() =>
                        navigate(`/a/${currentApp.id}/settings/general`)
                      }
                    >
                      <div className="flex items-center gap-2">
                        <Cog6ToothIcon className="h-4 w-4" />
                        <p>Settings & integrations</p>
                      </div>
                    </Item>
                    <Item
                      value="developers"
                      onClick={() =>
                        navigate(`/a/${currentApp.id}/settings/developers`)
                      }
                    >
                      <div className="flex items-center gap-2">
                        <CommandLineIcon className="h-4 w-4" />
                        <p>Developer tools</p>
                      </div>
                    </Item>
                    {!hasUsageBasedPricing && isStripeCustomer && (
                      <Item
                        value="billing"
                        onClick={() => redirectToBillingPortal()}
                      >
                        <div className="flex items-center gap-2">
                          <BanknotesIcon className="h-4 w-4" />
                          <p>Billing</p>
                        </div>
                      </Item>
                    )}
                    <Item
                      value="team"
                      onClick={() =>
                        navigate(`/a/${currentApp.id}/settings/team`)
                      }
                    >
                      <div className="flex items-center gap-2">
                        <UsersIcon className="h-4 w-4" />
                        <p>Manage team</p>
                      </div>
                    </Item>
                    <Divider my={0.5} />
                    <Item value="signout" onClick={handleLogout}>
                      <div className="flex items-center gap-2">
                        <ArrowRightOnRectangleIcon className="h-4 w-4" />
                        <p>Sign out</p>
                      </div>
                    </Item>
                  </CommandList>
                </Command>
              )}
            </PopoverPanel>
          </Popover>
          <div className="flex max-w-full items-center justify-end gap-x-2 pr-2">
            {showCollapseIcon && (
              <Tooltip label="Collapse sidebar" hasArrow>
                <motion.div
                  initial={{ opacity: 0, left: 0 }}
                  animate={{ opacity: 1, left: 10 }}
                  exit={{ opacity: 0, left: 0 }}
                  onClick={toggleSidebar}
                  className="flex h-full cursor-pointer items-center justify-center"
                >
                  <ArrowLeftBackSquare h={5} w={5} ml={1} color="gray.700" />
                </motion.div>
              </Tooltip>
            )}
            <div onClick={openCmdk} className="cursor-pointer">
              <Tooltip
                hasArrow
                label={`Search workspace (${isWindows() ? "CtrlK" : "⌘K"})`}
              >
                <MagnifyingGlassIcon className="h-4 w-4 text-black" />
              </Tooltip>
            </div>
            <div
              className={cx(
                " rounded-lg border bg-white p-1 shadow-md",
                currentApp.isDemoApp ? "cursor-not-allowed" : "cursor-pointer",
              )}
              onClick={() => {
                !currentApp.isDemoApp &&
                  navigate(
                    `/a/${currentApp.id}/report/create/${FEATURE_RELEASE}`,
                  );
              }}
            >
              <Tooltip
                hasArrow
                label={
                  currentApp.isDemoApp
                    ? "You cannot create reports in demo mode"
                    : `New report`
                }
              >
                <DocumentPlusIcon
                  className={cx(
                    "h-5 w-5 ",
                    currentApp.isDemoApp ? "text-gray-500" : "text-black",
                  )}
                />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={deleteCreateWorkspaceRef}
        onClose={() => setCreateAppModal(false)}
        isOpen={isCreateAppModalOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Create a new workspace</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Input
              onChange={(e) => setAppName(e.currentTarget.value)}
              placeholder="Workspace name"
              focusBorderColor="gray.400"
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={deleteCreateWorkspaceRef}
              onClick={() => setCreateAppModal(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                dispatch(createApp({ appName: String(newAppName) }));
                setCreateAppModal(false);
              }}
              colorScheme="purple"
              ml={3}
              isDisabled={!newAppName || newAppName.length === 0}
            >
              Create
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
