import React from "react";
import { VStack } from "@chakra-ui/react";
import { IHomeMilestone, Milestone } from "./Milestone";
import colors, {
  IColorPalette,
} from "core/design-system/constants/theme/colors";
import { Size } from "core/types/ViewInsight";

export const FunnelOverviewGraph: React.FC<{
  data: IHomeMilestone[];
  color?: IColorPalette;
  size?: Size;
}> = ({ data, color = colors.purple, size = Size.Small }) => {
  return (
    <VStack data-testid="funnel-body" mt={2} w="full" spacing={0}>
      {data?.map(
        (funnelBar, index) =>
          funnelBar && (
            <Milestone
              key={index}
              milestone={funnelBar}
              entityType={"user"}
              color={color}
              size={size}
            />
          ),
      )}
    </VStack>
  );
};
