import humps from "humps";
import { IReport } from "core/types/Report";
import { baseQuery } from "core/initializers/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";

export const reportApi = createApi({
  baseQuery,
  reducerPath: "reportApi",
  tagTypes: ["Report", "Token", "Insight", "Query"],
  endpoints: (builder) => ({
    createReport: builder.mutation({
      query: ({ appId, reportType, name }) => ({
        url: `/reports`,
        method: "POST",
        body: humps.decamelizeKeys({ appId, reportType, name }),
      }),
      invalidatesTags: ["Report", "Insight", "Query"],
      transformResponse: (response: { report: IReport }) =>
        humps.camelizeKeys(response) as { report: IReport },
    }),
  }),
});

export const { useCreateReportMutation } = reportApi;
