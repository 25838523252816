import { SnakeCasedProperties } from "type-fest";
import humps from "humps";
import { IReport } from "core/types/Report";
import { IPagy } from "core/types/Pagy";
import { IEvent } from "core/types/Event";
import { IAlert, ICreateAlert, IUpdateAlert } from "core/types/Alert";
import { baseQuery } from "core/initializers/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";

interface GetEventAlertsArgs {
  appId: number;
  eventId: number;
}

interface CreateEventAlertsArgs {
  appId: number;
  eventId: number;
}

interface UpdateEventAlertsArgs extends IUpdateAlert {
  id: number;
}

interface DeleteEventAlertsArgs {
  appId: number;
  id: number;
}
interface IGetEnabledAlertsArgs {
  appId: number;
  page: number;
}

export interface IAlertWithAlertable extends IAlert {
  alertable: IReport | IEvent;
}

export interface IEnabledAlertsResponse {
  alerts: IAlertWithAlertable[];
  pagy: IPagy;
}

interface GetAlertArgs {
  appId: string | number;
  alertType?: 0;
  reportId?: string;
  eventId?: string | number;
  insightId?: number;
  objectType?: string;
}

interface IUpdateAlertArgs {
  id: number;
  alert: IUpdateAlert;
}

export const alertsApi = createApi({
  baseQuery,
  reducerPath: "alerts",
  tagTypes: ["Alert"],
  endpoints: (builder) => ({
    createAlert: builder.mutation<IAlert, ICreateAlert>({
      query: (alert) => ({
        url: "/alerts",
        method: "POST",
        body: humps.decamelizeKeys(alert),
      }),
      invalidatesTags: ["Alert"],
      transformResponse: (response: IAlert): IAlert =>
        humps.camelizeKeys(response) as IAlert,
    }),
    updateAlert: builder.mutation<IAlert, IUpdateAlertArgs>({
      query: ({ id, alert }) => ({
        url: `/alerts/${id}`,
        method: "PUT",
        body: humps.decamelizeKeys(alert),
      }),
      invalidatesTags: ["Alert"],
      transformResponse: (response: IAlert): IAlert =>
        humps.camelizeKeys(response) as IAlert,
    }),
    getAlert: builder.query<IAlert, GetAlertArgs>({
      query: ({
        appId,
        alertType,
        reportId,
        eventId,
        insightId,
        objectType,
      }) => ({
        url: "/alerts/0",
        params: {
          app_id: appId,
          alert_type: alertType,
          report_id: reportId,
          event_id: eventId,
          insight_id: insightId,
          object_type: objectType,
        },
      }),
      providesTags: ["Alert"],
      transformResponse: (response: IAlert): IAlert =>
        humps.camelizeKeys(response) as IAlert,
    }),
    getEventAlerts: builder.query<IAlert[], GetEventAlertsArgs>({
      query: ({ appId, eventId }) => ({
        url: "/alerts",
        params: { app_id: appId, event_id: eventId },
      }),
      providesTags: ["Alert"],
      transformResponse: (response: IAlert[]): IAlert[] =>
        humps.camelizeKeys(response) as IAlert[],
    }),
    createEventAlert: builder.mutation<IAlert, CreateEventAlertsArgs>({
      query: ({ appId, eventId }) => ({
        url: "/alerts",
        method: "POST",
        params: { app_id: appId, event_id: eventId },
      }),
      invalidatesTags: ["Alert"],
      transformResponse: (response: IAlert): IAlert =>
        humps.camelizeKeys(response) as IAlert,
    }),
    updateEventAlert: builder.mutation<IAlert, UpdateEventAlertsArgs>({
      query: ({
        id,
        appId,
        state,
        enrichmentEnabled,
        frequency,
        slackChannelId,
        property,
        propertyValue,
        propertyComparisonOperator,
        joinOperator,
        setup = {},
        threshold,
        thresholdType,
      }) => ({
        url: `/alerts/${id}`,
        method: "PUT",
        params: {
          app_id: appId,
          state: state,
          setup: JSON.stringify(humps.decamelizeKeys(setup)),
          enrichment_enabled: enrichmentEnabled,
          frequency: frequency,
          slack_channel_id: slackChannelId,
          property: property,
          property_value: propertyValue,
          property_comparison_operator: propertyComparisonOperator,
          join_operator: joinOperator,
          threshold,
          threshold_type: thresholdType,
        },
      }),
      invalidatesTags: ["Alert"],
      transformResponse: (response: IAlert): IAlert =>
        humps.camelizeKeys(response) as IAlert,
    }),
    deleteEventAlert: builder.mutation<IAlert, DeleteEventAlertsArgs>({
      query: ({ appId, id }) => ({
        url: `/alerts/${id}`,
        method: "DELETE",
        params: { app_id: appId },
      }),
      invalidatesTags: ["Alert"],
    }),
    getEnabledAlerts: builder.query<
      IEnabledAlertsResponse,
      IGetEnabledAlertsArgs
    >({
      query: ({ appId, page }) => ({
        url: "alerts/enabled_alerts",
        params: { app_id: appId, page },
      }),
      providesTags: ["Alert"],
      transformResponse: (
        response: SnakeCasedProperties<IEnabledAlertsResponse>,
      ): IEnabledAlertsResponse =>
        humps.camelizeKeys(response) as IEnabledAlertsResponse,
    }),
  }),
});

export const {
  useGetEventAlertsQuery,
  useCreateEventAlertMutation,
  useUpdateEventAlertMutation,
  useDeleteEventAlertMutation,
  useGetEnabledAlertsQuery,
  useGetAlertQuery,
  useCreateAlertMutation,
  useUpdateAlertMutation,
} = alertsApi;
