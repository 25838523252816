import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { EmptyState } from "modules/Home/highlights/user/Features/EmptyState";
import { useGetHomeFeatureListQuery } from "core/models/home";
import { useOnboardingChecklist } from "core/hooks/useOnboardingChecklist";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import Icon from "core/design-system/components/Icon";
import {
  ADOPTION_PERCENTAGE,
  ASC,
  DESC,
  RELEASED_DATE,
  RETENTION_PERCENTAGE,
  USAGE_COUNT,
  USER_COUNT,
} from "core/constants/featureList";
import HighlightCard from "core/components/Highlight";
import {
  Button,
  ComponentDefaultProps,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Table } from "./Features/Table";
import { FeatureDrilldown } from "./Features/FeatureDrilldown";
import { FEATURE_RELEASE } from "core/constants/report-configurations";
import { GroupType } from "core/components/Group/GroupListSingleGroup";

const LIMIT = 5;

export const FeatureList: React.FC<
  { groupType?: GroupType; groupId?: string } & ComponentDefaultProps
> = ({ groupType, groupId, ...props }) => {
  const { id: appId } = useCurrentApp();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [column, setColumn] = useState(ADOPTION_PERCENTAGE);
  const [order, setOrder] = useState(DESC);
  const {
    data: featuresData,
    error,
    isFetching,
  } = useGetHomeFeatureListQuery({
    appId,
    page: 1,
    items: LIMIT,
    column,
    order,
    groupId,
    groupType,
  });
  const { shouldShowGettingStartedBanner } = useOnboardingChecklist();

  const noData = !featuresData || featuresData?.features.length === 0;
  const showNewReportButton =
    featuresData?.features.length && featuresData.features.length < LIMIT;

  function onSort(newColumn?: string, newOrder?: "desc" | "asc") {
    if (newColumn) setColumn(newColumn);
    if (newOrder) setOrder(newOrder);
  }

  function buildHighlight() {
    let verb = "Adopted";
    let noun = order === DESC ? "Most" : "Least";

    switch (column) {
      case USAGE_COUNT:
        verb = "used";
        break;
      case ADOPTION_PERCENTAGE:
        verb = "adopted";
        break;
      case USER_COUNT:
        verb = "users";
        break;
      case RETENTION_PERCENTAGE:
        verb = "retained";
        break;
    }

    if (column === RELEASED_DATE && order === DESC) {
      noun = "Latest";
      verb = "features";
    }

    if (column === RELEASED_DATE && order === ASC) {
      noun = "Oldest";
      verb = "features";
    }

    return `${noun} ${verb}`;
  }

  return (
    <HighlightCard>
      {(hc) =>
        noData && !isFetching ? (
          <hc.Container
            opacity={shouldShowGettingStartedBanner ? 0.4 : undefined}
            filter={
              shouldShowGettingStartedBanner ? "grayscale(0.8)" : undefined
            }
            py={10}
            maxH="400px"
          >
            <EmptyState appId={appId} />
          </hc.Container>
        ) : (
          <hc.Container
            h={
              isFetching || Boolean(error) || Boolean(noData)
                ? "400px"
                : "fit-content"
            }
            {...props}
          >
            <hc.Header>
              <hc.Title
                title="Features"
                appId={appId}
                value={buildHighlight()}
                valueSize="2xl"
                tooltipText={
                  <Flex p={2} direction="column">
                    <Text>
                      See how your features are performing and how they compare
                      to others
                    </Text>
                    <Text mt={2} fontSize="xs" color="gray.500">
                      Click on view all to see all your features
                    </Text>
                  </Flex>
                }
                Cta={
                  <Button
                    colorScheme="purple"
                    border="none"
                    borderRadius="md"
                    size="sm"
                    variant="ghost"
                    rightIcon={<Icon iconType="icon" name="arrow-right-2" />}
                    mr={2}
                    onClick={onOpen}
                  >
                    View all
                  </Button>
                }
              />
            </hc.Header>
            <hc.Body
              w="100%"
              isLoading={isFetching || Boolean(error)}
              flexDirection="column"
              justifyContent="flex-start"
              pb={showNewReportButton ? 8 : 0}
              pt={4}
              opacity={shouldShowGettingStartedBanner ? 0.4 : undefined}
              filter={
                shouldShowGettingStartedBanner ? "grayscale(0.8)" : undefined
              }
              px={8}
            >
              <div className="w-full pb-4">
                {featuresData && (
                  <Table
                    featuresData={featuresData.features}
                    onSort={onSort}
                    column={column}
                    order={order}
                    groupId={groupId}
                    groupType={groupType}
                  />
                )}
              </div>
              {showNewReportButton && (
                <Button
                  mt={4}
                  size="md"
                  colorScheme="gray"
                  onClick={() => {
                    window.analytics.track(
                      "report_created_from_home",
                      {
                        app_id: appId,
                      },
                      { context: { groupId: appId } },
                    );
                    navigate(`/a/${appId}/report/create/${FEATURE_RELEASE}`);
                  }}
                >
                  Track a new feature
                </Button>
              )}
            </hc.Body>
            {isOpen && <FeatureDrilldown isOpen={isOpen} onClose={onClose} />}
          </hc.Container>
        )
      }
    </HighlightCard>
  );
};
