import { SnakeCasedProperties } from "type-fest";
import humps from "humps";
import { baseQuery } from "core/initializers/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";

export enum AppObjectType {
  User = "identify",
  Group = "custom_group",
  Company = "automatic_company",
}

export enum AppObjectSlugs {
  User = "user",
  Group = "group",
  Company = "company",
}

export interface IAppObject {
  id: number;
  appId: number;
  singularName: string;
  pluralName: string;
  slug: string;
  objectType: AppObjectType;
  isEnabled: boolean;
}

export const appObjectApi = createApi({
  baseQuery,
  reducerPath: "appObjectApi",
  tagTypes: ["AppObject"],
  endpoints: (builder) => ({
    getAppObjects: builder.query<
      IAppObject[],
      {
        appId: number;
        reportId?: number;
        insightType?: number;
        sharingSecretToken?: string | null;
      }
    >({
      query: ({
        appId,
        reportId = null,
        insightType = null,
        sharingSecretToken = null,
      }) => ({
        url: "/app_objects",
        params: {
          app_id: appId,
          report_id: reportId,
          insight_type: insightType,
          sharing_secret_token: sharingSecretToken,
        },
      }),
      providesTags: ["AppObject"],
      transformResponse: (
        response: SnakeCasedProperties<IAppObject[]>,
      ): IAppObject[] => humps.camelizeKeys(response) as IAppObject[],
    }),
    getAppObject: builder.query<IAppObject, { appId: number; id: number }>({
      query: ({ id, appId }) => ({
        url: `app_objects/${id}`,
        params: { app_id: appId },
      }),
      providesTags: ["AppObject"],
      transformResponse: (
        response: SnakeCasedProperties<IAppObject>,
      ): IAppObject => humps.camelizeKeys(response) as IAppObject,
    }),
    updateAppObject: builder.mutation<
      IAppObject,
      {
        appId: number;
        id: number;
        singularName?: string;
        pluralName?: string;
        isEnabled?: boolean;
      }
    >({
      query: ({ id, singularName, pluralName, isEnabled, appId }) => ({
        url: `app_objects/${id}`,
        method: "PUT",
        body: humps.decamelizeKeys({
          singularName,
          pluralName,
          isEnabled,
          appId,
        }),
      }),
      invalidatesTags: ["AppObject"],
      transformResponse: (
        response: SnakeCasedProperties<IAppObject>,
      ): IAppObject => humps.camelizeKeys(response) as IAppObject,
    }),
  }),
});

export const {
  useGetAppObjectsQuery,
  useLazyGetAppObjectsQuery,
  useGetAppObjectQuery,
  useUpdateAppObjectMutation,
} = appObjectApi;
