import TeamIcon from "core/design-system/components/Icon/Interface/TeamIcon";
import KeepControlIcon from "core/design-system/components/Icon/Interface/KeepControlIcon";
import InstantIcon from "core/design-system/components/Icon/Interface/InstantIcon";
import CelebrateIcon from "core/design-system/components/Icon/Interface/CelebrateIcon";
import { Box, Center, Flex, Text } from "@chakra-ui/react";

export const Install: React.FC = () => {
  return (
    <Flex gridGap={4}>
      <Flex direction="column" gridGap={4} py={6}>
        <Flex direction="row" gridGap={4}>
          <Flex>
            <InstantIcon color="primary" h={7} w={7} />
          </Flex>
          <Flex direction="column">
            <Text fontSize="sm" fontWeight="bold">
              View and create contacts and companies from June
            </Text>
            <Text fontSize="sm" fontWeight="sm" color="gray.600">
              View and create HubSpot contacts and companies, right in June
              along with the rest of their product usage.
            </Text>
          </Flex>
        </Flex>
        <Flex direction="row" gridGap={4}>
          <Flex>
            <CelebrateIcon h={7} w={7} />
          </Flex>
          <Flex direction="column">
            <Text fontSize="sm" fontWeight="bold">
              Automatically sync contacts and companies
            </Text>
            <Text fontSize="sm" fontWeight="sm" color="gray.600">
              Automatically create and update HubSpot contacts and companies
              when they get created and updated in June.
            </Text>
          </Flex>
        </Flex>
        <Flex direction="row" gridGap={4}>
          <Flex>
            <KeepControlIcon h={7} w={7} />
          </Flex>
          <Flex direction="column">
            <Text fontSize="sm" fontWeight="bold">
              Control what data is sent to HubSpot from June
            </Text>
            <Text fontSize="sm" fontWeight="sm" color="gray.600">
              Map the June usage data you want to send to your team in HubSpot
              so its always up to date.
            </Text>
          </Flex>
        </Flex>
        <Flex direction="row" gridGap={4}>
          <Flex>
            <TeamIcon color="purple.500" h={7} w={7} />
          </Flex>
          <Flex direction="column">
            <Text fontSize="sm" fontWeight="bold">
              Rally your team behind your most important metrics
            </Text>
            <Text fontSize="sm" fontWeight="sm" color="gray.600">
              Get everyone up to speed with what's happening inside your
              company, even your Sales and Customer Success teams.
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex w="100%">
        <Center w="100%">
          <Box
            borderRadius="lg"
            h="350px"
            w="100%"
            bgImage={`url('/empty-hs.png')`}
            bgRepeat="no-repeat"
            bgPosition="center"
            bgSize="contain"
          />
        </Center>
      </Flex>
    </Flex>
  );
};
