const MockCohortData = {
  bestDate: "2020-12-07T00:00:00.000+05:30",
  worstDate: "2021-02-22T00:00:00.000+05:30",
  intervalType: "week",
  cohortData: [
    {
      startDate: "2021-02-22T00:00:00.000+05:30",
      endDate: "2021-02-28T23:59:59.999+05:30",
      people: 35,
      peoplePreview: [311, 312, 313, 314],
      data: [
        {
          week: 0,
          startDate: "2021-02-22T00:00:00.000+05:30",
          endDate: "2021-02-28T23:59:59.999+05:30",
          retention: 100,
          retainedUsersCount: 35,
          retainedUsers: [],
        },
        {
          week: 1,
          startDate: "2021-03-01T00:00:00.000+05:30",
          endDate: "2021-03-07T23:59:59.999+05:30",
          retention: 8.571428571428571,
          retainedUsersCount: 3,
          retainedUsers: [],
        },
      ],
    },
    {
      startDate: "2021-02-15T00:00:00.000+05:30",
      endDate: "2021-02-21T23:59:59.999+05:30",
      people: 33,
      peoplePreview: [278, 279, 280, 281],
      data: [
        {
          week: 0,
          startDate: "2021-02-15T00:00:00.000+05:30",
          endDate: "2021-02-21T23:59:59.999+05:30",
          retention: 100,
          retainedUsersCount: 33,
          retainedUsers: [],
        },
        {
          week: 1,
          startDate: "2021-02-22T00:00:00.000+05:30",
          endDate: "2021-02-28T23:59:59.999+05:30",
          retention: 24.242424242424242,
          retainedUsersCount: 8,
          retainedUsers: [],
        },
        {
          week: 2,
          startDate: "2021-03-01T00:00:00.000+05:30",
          endDate: "2021-03-07T23:59:59.999+05:30",
          retention: 9.090909090909092,
          retainedUsersCount: 3,
          retainedUsers: [],
        },
      ],
    },
    {
      startDate: "2021-02-08T00:00:00.000+05:30",
      endDate: "2021-02-14T23:59:59.999+05:30",
      people: 33,
      peoplePreview: [245, 246, 247, 248],
      data: [
        {
          week: 0,
          startDate: "2021-02-08T00:00:00.000+05:30",
          endDate: "2021-02-14T23:59:59.999+05:30",
          retention: 100,
          retainedUsersCount: 33,
          retainedUsers: [],
        },
        {
          week: 1,
          startDate: "2021-02-15T00:00:00.000+05:30",
          endDate: "2021-02-21T23:59:59.999+05:30",
          retention: 18.181818181818183,
          retainedUsersCount: 6,
          retainedUsers: [],
        },
        {
          week: 2,
          startDate: "2021-02-22T00:00:00.000+05:30",
          endDate: "2021-02-28T23:59:59.999+05:30",
          retention: 21.21212121212121,
          retainedUsersCount: 7,
          retainedUsers: [],
        },
        {
          week: 3,
          startDate: "2021-03-01T00:00:00.000+05:30",
          endDate: "2021-03-07T23:59:59.999+05:30",
          retention: 0.0,
          retainedUsersCount: 0,
          retainedUsers: [],
        },
      ],
    },
    {
      startDate: "2021-02-01T00:00:00.000+05:30",
      endDate: "2021-02-07T23:59:59.999+05:30",
      people: 26,
      peoplePreview: [219, 220, 221, 222],
      data: [
        {
          week: 0,
          startDate: "2021-02-01T00:00:00.000+05:30",
          endDate: "2021-02-07T23:59:59.999+05:30",
          retention: 100,
          retainedUsersCount: 26,
          retainedUsers: [],
        },
        {
          week: 1,
          startDate: "2021-02-08T00:00:00.000+05:30",
          endDate: "2021-02-14T23:59:59.999+05:30",
          retention: 30.76923076923077,
          retainedUsersCount: 8,
          retainedUsers: [],
        },
        {
          week: 2,
          startDate: "2021-02-15T00:00:00.000+05:30",
          endDate: "2021-02-21T23:59:59.999+05:30",
          retention: 30.76923076923077,
          retainedUsersCount: 8,
          retainedUsers: [],
        },
        {
          week: 3,
          startDate: "2021-02-22T00:00:00.000+05:30",
          endDate: "2021-02-28T23:59:59.999+05:30",
          retention: 23.076923076923077,
          retainedUsersCount: 6,
          retainedUsers: [],
        },
        {
          week: 4,
          startDate: "2021-03-01T00:00:00.000+05:30",
          endDate: "2021-03-07T23:59:59.999+05:30",
          retention: 0.0,
          retainedUsersCount: 0,
          retainedUsers: [],
        },
      ],
    },
    {
      startDate: "2021-01-25T00:00:00.000+05:30",
      endDate: "2021-01-31T23:59:59.999+05:30",
      people: 26,
      peoplePreview: [193, 194, 195, 196],
      data: [
        {
          week: 0,
          startDate: "2021-01-25T00:00:00.000+05:30",
          endDate: "2021-01-31T23:59:59.999+05:30",
          retention: 100,
          retainedUsersCount: 26,
          retainedUsers: [],
        },
        {
          week: 1,
          startDate: "2021-02-01T00:00:00.000+05:30",
          endDate: "2021-02-07T23:59:59.999+05:30",
          retention: 26.923076923076923,
          retainedUsersCount: 7,
          retainedUsers: [],
        },
        {
          week: 2,
          startDate: "2021-02-08T00:00:00.000+05:30",
          endDate: "2021-02-14T23:59:59.999+05:30",
          retention: 23.076923076923077,
          retainedUsersCount: 6,
          retainedUsers: [],
        },
        {
          week: 3,
          startDate: "2021-02-15T00:00:00.000+05:30",
          endDate: "2021-02-21T23:59:59.999+05:30",
          retention: 30.76923076923077,
          retainedUsersCount: 8,
          retainedUsers: [],
        },
        {
          week: 4,
          startDate: "2021-02-22T00:00:00.000+05:30",
          endDate: "2021-02-28T23:59:59.999+05:30",
          retention: 30.76923076923077,
          retainedUsersCount: 8,
          retainedUsers: [],
        },
        {
          week: 5,
          startDate: "2021-03-01T00:00:00.000+05:30",
          endDate: "2021-03-07T23:59:59.999+05:30",
          retention: 19.230769230769234,
          retainedUsersCount: 5,
          retainedUsers: [],
        },
      ],
    },
    {
      startDate: "2021-01-18T00:00:00.000+05:30",
      endDate: "2021-01-24T23:59:59.999+05:30",
      people: 21,
      peoplePreview: [172, 173, 174, 175],
      data: [
        {
          week: 0,
          startDate: "2021-01-18T00:00:00.000+05:30",
          endDate: "2021-01-24T23:59:59.999+05:30",
          retention: 100,
          retainedUsersCount: 21,
          retainedUsers: [],
        },
        {
          week: 1,
          startDate: "2021-01-25T00:00:00.000+05:30",
          endDate: "2021-01-31T23:59:59.999+05:30",
          retention: 52.38095238095239,
          retainedUsersCount: 11,
          retainedUsers: [],
        },
        {
          week: 2,
          startDate: "2021-02-01T00:00:00.000+05:30",
          endDate: "2021-02-07T23:59:59.999+05:30",
          retention: 23.809523809523807,
          retainedUsersCount: 5,
          retainedUsers: [],
        },
        {
          week: 3,
          startDate: "2021-02-08T00:00:00.000+05:30",
          endDate: "2021-02-14T23:59:59.999+05:30",
          retention: 23.809523809523807,
          retainedUsersCount: 5,
          retainedUsers: [],
        },
        {
          week: 4,
          startDate: "2021-02-15T00:00:00.000+05:30",
          endDate: "2021-02-21T23:59:59.999+05:30",
          retention: 14.285714285714285,
          retainedUsersCount: 3,
          retainedUsers: [],
        },
        {
          week: 5,
          startDate: "2021-02-22T00:00:00.000+05:30",
          endDate: "2021-02-28T23:59:59.999+05:30",
          retention: 33.33333333333333,
          retainedUsersCount: 7,
          retainedUsers: [],
        },
        {
          week: 6,
          startDate: "2021-03-01T00:00:00.000+05:30",
          endDate: "2021-03-07T23:59:59.999+05:30",
          retention: 14.285714285714285,
          retainedUsersCount: 3,
          retainedUsers: [],
        },
      ],
    },
    {
      startDate: "2021-01-11T00:00:00.000+05:30",
      endDate: "2021-01-17T23:59:59.999+05:30",
      people: 30,
      peoplePreview: [142, 143, 144, 145],
      data: [
        {
          week: 0,
          startDate: "2021-01-11T00:00:00.000+05:30",
          endDate: "2021-01-17T23:59:59.999+05:30",
          retention: 100,
          retainedUsersCount: 30,
          retainedUsers: [],
        },
        {
          week: 1,
          startDate: "2021-01-18T00:00:00.000+05:30",
          endDate: "2021-01-24T23:59:59.999+05:30",
          retention: 50.0,
          retainedUsersCount: 15,
          retainedUsers: [],
        },
        {
          week: 2,
          startDate: "2021-01-25T00:00:00.000+05:30",
          endDate: "2021-01-31T23:59:59.999+05:30",
          retention: 40.0,
          retainedUsersCount: 12,
          retainedUsers: [],
        },
        {
          week: 3,
          startDate: "2021-02-01T00:00:00.000+05:30",
          endDate: "2021-02-07T23:59:59.999+05:30",
          retention: 46.666666666666664,
          retainedUsersCount: 14,
          retainedUsers: [],
        },
        {
          week: 4,
          startDate: "2021-02-08T00:00:00.000+05:30",
          endDate: "2021-02-14T23:59:59.999+05:30",
          retention: 30.0,
          retainedUsersCount: 9,
          retainedUsers: [],
        },
        {
          week: 5,
          startDate: "2021-02-15T00:00:00.000+05:30",
          endDate: "2021-02-21T23:59:59.999+05:30",
          retention: 26.666666666666668,
          retainedUsersCount: 8,
          retainedUsers: [],
        },
        {
          week: 6,
          startDate: "2021-02-22T00:00:00.000+05:30",
          endDate: "2021-02-28T23:59:59.999+05:30",
          retention: 30.0,
          retainedUsersCount: 9,
          retainedUsers: [],
        },
        {
          week: 7,
          startDate: "2021-03-01T00:00:00.000+05:30",
          endDate: "2021-03-07T23:59:59.999+05:30",
          retention: 16.666666666666664,
          retainedUsersCount: 5,
          retainedUsers: [],
        },
      ],
    },
    {
      startDate: "2021-01-04T00:00:00.000+05:30",
      endDate: "2021-01-10T23:59:59.999+05:30",
      people: 25,
      peoplePreview: [117, 118, 119, 120],
      data: [
        {
          week: 0,
          startDate: "2021-01-04T00:00:00.000+05:30",
          endDate: "2021-01-10T23:59:59.999+05:30",
          retention: 100,
          retainedUsersCount: 25,
          retainedUsers: [],
        },
        {
          week: 1,
          startDate: "2021-01-11T00:00:00.000+05:30",
          endDate: "2021-01-17T23:59:59.999+05:30",
          retention: 44.0,
          retainedUsersCount: 11,
          retainedUsers: [],
        },
        {
          week: 2,
          startDate: "2021-01-18T00:00:00.000+05:30",
          endDate: "2021-01-24T23:59:59.999+05:30",
          retention: 56.00000000000001,
          retainedUsersCount: 14,
          retainedUsers: [],
        },
        {
          week: 3,
          startDate: "2021-01-25T00:00:00.000+05:30",
          endDate: "2021-01-31T23:59:59.999+05:30",
          retention: 40.0,
          retainedUsersCount: 10,
          retainedUsers: [],
        },
        {
          week: 4,
          startDate: "2021-02-01T00:00:00.000+05:30",
          endDate: "2021-02-07T23:59:59.999+05:30",
          retention: 32.0,
          retainedUsersCount: 8,
          retainedUsers: [],
        },
        {
          week: 5,
          startDate: "2021-02-08T00:00:00.000+05:30",
          endDate: "2021-02-14T23:59:59.999+05:30",
          retention: 36.0,
          retainedUsersCount: 9,
          retainedUsers: [],
        },
        {
          week: 6,
          startDate: "2021-02-15T00:00:00.000+05:30",
          endDate: "2021-02-21T23:59:59.999+05:30",
          retention: 44.0,
          retainedUsersCount: 11,
          retainedUsers: [],
        },
        {
          week: 7,
          startDate: "2021-02-22T00:00:00.000+05:30",
          endDate: "2021-02-28T23:59:59.999+05:30",
          retention: 32.0,
          retainedUsersCount: 8,
          retainedUsers: [],
        },
        {
          week: 8,
          startDate: "2021-03-01T00:00:00.000+05:30",
          endDate: "2021-03-07T23:59:59.999+05:30",
          retention: 16.0,
          retainedUsersCount: 4,
          retainedUsers: [],
        },
      ],
    },
    {
      startDate: "2020-12-28T00:00:00.000+05:30",
      endDate: "2021-01-03T23:59:59.999+05:30",
      people: 28,
      peoplePreview: [89, 90, 91, 92],
      data: [
        {
          week: 0,
          startDate: "2020-12-28T00:00:00.000+05:30",
          endDate: "2021-01-03T23:59:59.999+05:30",
          retention: 100,
          retainedUsersCount: 28,
          retainedUsers: [],
        },
        {
          week: 1,
          startDate: "2021-01-04T00:00:00.000+05:30",
          endDate: "2021-01-10T23:59:59.999+05:30",
          retention: 53.57142857142857,
          retainedUsersCount: 15,
          retainedUsers: [],
        },
        {
          week: 2,
          startDate: "2021-01-11T00:00:00.000+05:30",
          endDate: "2021-01-17T23:59:59.999+05:30",
          retention: 57.14285714285714,
          retainedUsersCount: 16,
          retainedUsers: [],
        },
        {
          week: 3,
          startDate: "2021-01-18T00:00:00.000+05:30",
          endDate: "2021-01-24T23:59:59.999+05:30",
          retention: 28.57142857142857,
          retainedUsersCount: 8,
          retainedUsers: [],
        },
        {
          week: 4,
          startDate: "2021-01-25T00:00:00.000+05:30",
          endDate: "2021-01-31T23:59:59.999+05:30",
          retention: 35.714285714285715,
          retainedUsersCount: 10,
          retainedUsers: [],
        },
        {
          week: 5,
          startDate: "2021-02-01T00:00:00.000+05:30",
          endDate: "2021-02-07T23:59:59.999+05:30",
          retention: 32.142857142857146,
          retainedUsersCount: 9,
          retainedUsers: [],
        },
        {
          week: 6,
          startDate: "2021-02-08T00:00:00.000+05:30",
          endDate: "2021-02-14T23:59:59.999+05:30",
          retention: 32.142857142857146,
          retainedUsersCount: 9,
          retainedUsers: [],
        },
        {
          week: 7,
          startDate: "2021-02-15T00:00:00.000+05:30",
          endDate: "2021-02-21T23:59:59.999+05:30",
          retention: 39.285714285714285,
          retainedUsersCount: 11,
          retainedUsers: [],
        },
        {
          week: 8,
          startDate: "2021-02-22T00:00:00.000+05:30",
          endDate: "2021-02-28T23:59:59.999+05:30",
          retention: 25.0,
          retainedUsersCount: 7,
          retainedUsers: [],
        },
        {
          week: 9,
          startDate: "2021-03-01T00:00:00.000+05:30",
          endDate: "2021-03-07T23:59:59.999+05:30",
          retention: 7.142857142857142,
          retainedUsersCount: 2,
          retainedUsers: [],
        },
      ],
    },
    {
      startDate: "2020-12-21T00:00:00.000+05:30",
      endDate: "2020-12-27T23:59:59.999+05:30",
      people: 46,
      peoplePreview: [43, 44, 45, 46],
      data: [
        {
          week: 0,
          startDate: "2020-12-21T00:00:00.000+05:30",
          endDate: "2020-12-27T23:59:59.999+05:30",
          retention: 100,
          retainedUsersCount: 46,
          retainedUsers: [],
        },
        {
          week: 1,
          startDate: "2020-12-28T00:00:00.000+05:30",
          endDate: "2021-01-03T23:59:59.999+05:30",
          retention: 67.3913043478261,
          retainedUsersCount: 31,
          retainedUsers: [],
        },
        {
          week: 2,
          startDate: "2021-01-04T00:00:00.000+05:30",
          endDate: "2021-01-10T23:59:59.999+05:30",
          retention: 47.82608695652174,
          retainedUsersCount: 22,
          retainedUsers: [],
        },
        {
          week: 3,
          startDate: "2021-01-11T00:00:00.000+05:30",
          endDate: "2021-01-17T23:59:59.999+05:30",
          retention: 41.30434782608695,
          retainedUsersCount: 19,
          retainedUsers: [],
        },
        {
          week: 4,
          startDate: "2021-01-18T00:00:00.000+05:30",
          endDate: "2021-01-24T23:59:59.999+05:30",
          retention: 39.130434782608695,
          retainedUsersCount: 18,
          retainedUsers: [],
        },
        {
          week: 5,
          startDate: "2021-01-25T00:00:00.000+05:30",
          endDate: "2021-01-31T23:59:59.999+05:30",
          retention: 34.78260869565217,
          retainedUsersCount: 16,
          retainedUsers: [],
        },
        {
          week: 6,
          startDate: "2021-02-01T00:00:00.000+05:30",
          endDate: "2021-02-07T23:59:59.999+05:30",
          retention: 41.30434782608695,
          retainedUsersCount: 19,
          retainedUsers: [],
        },
        {
          week: 7,
          startDate: "2021-02-08T00:00:00.000+05:30",
          endDate: "2021-02-14T23:59:59.999+05:30",
          retention: 36.95652173913043,
          retainedUsersCount: 17,
          retainedUsers: [],
        },
        {
          week: 8,
          startDate: "2021-02-15T00:00:00.000+05:30",
          endDate: "2021-02-21T23:59:59.999+05:30",
          retention: 30.434782608695656,
          retainedUsersCount: 14,
          retainedUsers: [],
        },
        {
          week: 9,
          startDate: "2021-02-22T00:00:00.000+05:30",
          endDate: "2021-02-28T23:59:59.999+05:30",
          retention: 21.73913043478261,
          retainedUsersCount: 10,
          retainedUsers: [],
        },
        {
          week: 10,
          startDate: "2021-03-01T00:00:00.000+05:30",
          endDate: "2021-03-07T23:59:59.999+05:30",
          retention: 6.521739130434782,
          retainedUsersCount: 3,
          retainedUsers: [],
        },
      ],
    },
    {
      startDate: "2020-12-14T00:00:00.000+05:30",
      endDate: "2020-12-20T23:59:59.999+05:30",
      people: 22,
      peoplePreview: [21, 22, 23, 24],
      data: [
        {
          week: 0,
          startDate: "2020-12-14T00:00:00.000+05:30",
          endDate: "2020-12-20T23:59:59.999+05:30",
          retention: 100,
          retainedUsersCount: 22,
          retainedUsers: [],
        },
        {
          week: 1,
          startDate: "2020-12-21T00:00:00.000+05:30",
          endDate: "2020-12-27T23:59:59.999+05:30",
          retention: 95.45454545454545,
          retainedUsersCount: 21,
          retainedUsers: [],
        },
        {
          week: 2,
          startDate: "2020-12-28T00:00:00.000+05:30",
          endDate: "2021-01-03T23:59:59.999+05:30",
          retention: 63.63636363636363,
          retainedUsersCount: 14,
          retainedUsers: [],
        },
        {
          week: 3,
          startDate: "2021-01-04T00:00:00.000+05:30",
          endDate: "2021-01-10T23:59:59.999+05:30",
          retention: 50.0,
          retainedUsersCount: 11,
          retainedUsers: [],
        },
        {
          week: 4,
          startDate: "2021-01-11T00:00:00.000+05:30",
          endDate: "2021-01-17T23:59:59.999+05:30",
          retention: 63.63636363636363,
          retainedUsersCount: 14,
          retainedUsers: [],
        },
        {
          week: 5,
          startDate: "2021-01-18T00:00:00.000+05:30",
          endDate: "2021-01-24T23:59:59.999+05:30",
          retention: 40.909090909090914,
          retainedUsersCount: 9,
          retainedUsers: [],
        },
        {
          week: 6,
          startDate: "2021-01-25T00:00:00.000+05:30",
          endDate: "2021-01-31T23:59:59.999+05:30",
          retention: 54.54545454545454,
          retainedUsersCount: 12,
          retainedUsers: [],
        },
        {
          week: 7,
          startDate: "2021-02-01T00:00:00.000+05:30",
          endDate: "2021-02-07T23:59:59.999+05:30",
          retention: 40.909090909090914,
          retainedUsersCount: 9,
          retainedUsers: [],
        },
        {
          week: 8,
          startDate: "2021-02-08T00:00:00.000+05:30",
          endDate: "2021-02-14T23:59:59.999+05:30",
          retention: 13.636363636363635,
          retainedUsersCount: 3,
          retainedUsers: [],
        },
        {
          week: 9,
          startDate: "2021-02-15T00:00:00.000+05:30",
          endDate: "2021-02-21T23:59:59.999+05:30",
          retention: 31.818181818181817,
          retainedUsersCount: 7,
          retainedUsers: [],
        },
        {
          week: 10,
          startDate: "2021-02-22T00:00:00.000+05:30",
          endDate: "2021-02-28T23:59:59.999+05:30",
          retention: 36.36363636363637,
          retainedUsersCount: 8,
          retainedUsers: [],
        },
        {
          week: 11,
          startDate: "2021-03-01T00:00:00.000+05:30",
          endDate: "2021-03-07T23:59:59.999+05:30",
          retention: 4.545454545454546,
          retainedUsersCount: 1,
          retainedUsers: [],
        },
      ],
    },
    {
      startDate: "2020-12-07T00:00:00.000+05:30",
      endDate: "2020-12-13T23:59:59.999+05:30",
      people: 20,
      peoplePreview: [11, 8, 19, 4],
      data: [
        {
          week: 0,
          startDate: "2020-12-07T00:00:00.000+05:30",
          endDate: "2020-12-13T23:59:59.999+05:30",
          retention: 100,
          retainedUsersCount: 20,
          retainedUsers: [],
        },
        {
          week: 1,
          startDate: "2020-12-14T00:00:00.000+05:30",
          endDate: "2020-12-20T23:59:59.999+05:30",
          retention: 100.0,
          retainedUsersCount: 20,
          retainedUsers: [],
        },
        {
          week: 2,
          startDate: "2020-12-21T00:00:00.000+05:30",
          endDate: "2020-12-27T23:59:59.999+05:30",
          retention: 80.0,
          retainedUsersCount: 16,
          retainedUsers: [],
        },
        {
          week: 3,
          startDate: "2020-12-28T00:00:00.000+05:30",
          endDate: "2021-01-03T23:59:59.999+05:30",
          retention: 55.00000000000001,
          retainedUsersCount: 11,
          retainedUsers: [],
        },
        {
          week: 4,
          startDate: "2021-01-04T00:00:00.000+05:30",
          endDate: "2021-01-10T23:59:59.999+05:30",
          retention: 75.0,
          retainedUsersCount: 15,
          retainedUsers: [],
        },
        {
          week: 5,
          startDate: "2021-01-11T00:00:00.000+05:30",
          endDate: "2021-01-17T23:59:59.999+05:30",
          retention: 50.0,
          retainedUsersCount: 10,
          retainedUsers: [],
        },
        {
          week: 6,
          startDate: "2021-01-18T00:00:00.000+05:30",
          endDate: "2021-01-24T23:59:59.999+05:30",
          retention: 40.0,
          retainedUsersCount: 8,
          retainedUsers: [],
        },
        {
          week: 7,
          startDate: "2021-01-25T00:00:00.000+05:30",
          endDate: "2021-01-31T23:59:59.999+05:30",
          retention: 50.0,
          retainedUsersCount: 10,
          retainedUsers: [],
        },
        {
          week: 8,
          startDate: "2021-02-01T00:00:00.000+05:30",
          endDate: "2021-02-07T23:59:59.999+05:30",
          retention: 45.0,
          retainedUsersCount: 9,
          retainedUsers: [],
        },
        {
          week: 9,
          startDate: "2021-02-08T00:00:00.000+05:30",
          endDate: "2021-02-14T23:59:59.999+05:30",
          retention: 20.0,
          retainedUsersCount: 4,
          retainedUsers: [],
        },
        {
          week: 10,
          startDate: "2021-02-15T00:00:00.000+05:30",
          endDate: "2021-02-21T23:59:59.999+05:30",
          retention: 25.0,
          retainedUsersCount: 5,
          retainedUsers: [],
        },
        {
          week: 11,
          startDate: "2021-02-22T00:00:00.000+05:30",
          endDate: "2021-02-28T23:59:59.999+05:30",
          retention: 30.0,
          retainedUsersCount: 6,
          retainedUsers: [],
        },
        {
          week: 12,
          startDate: "2021-03-01T00:00:00.000+05:30",
          endDate: "2021-03-07T23:59:59.999+05:30",
          retention: 10.0,
          retainedUsersCount: 2,
          retainedUsers: [],
        },
      ],
    },
  ],
};

export default MockCohortData;
