import React from "react";
import { pluralize } from "helpers/pluralize";
import { IRetentionInsightUser } from "../ChurningUsersContainer";

export const UsedOverCell: React.FC<{
  props: IRetentionInsightUser;
  previewMode: boolean;
}> = ({ props, previewMode }) => {
  return (
    <div className="text-sm">
      {props.numberOfActiveIntervals}{" "}
      {pluralize(
        Number(props.numberOfActiveIntervals),
        props.intervalType,
        `${props.intervalType}s`,
      )}
    </div>
  );
};
