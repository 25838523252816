import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useLazyGetSearchReportsQuery } from "core/models/reports";
import { selector as editingPinnedReportsSelector } from "core/models/pinnedReports/selectors";
import { toggleEdit } from "core/models/pinnedReports/actions";
import {
  useGetPinnedReportsQuery,
  useUpdatePinnedReportMutation,
} from "core/models/pinnedReports";
import {
  ACTIVE_USERS,
  ENGAGEMENT,
  FEATURE_RELEASE,
  MILESTONES,
  NEW_USERS,
  POWER_USERS,
  RETENTION,
  SLIPPING_AWAY,
} from "core/constants/report-configurations";

export const usePinnedReports = (location: "home" | "company_profile") => {
  const HOME_CARD_TITLE_TO_REPORT_TYPE = {
    Activation: MILESTONES,
    "Active now": ACTIVE_USERS,
    "Power users": POWER_USERS,
    "New users": NEW_USERS,
    "Active users": ACTIVE_USERS,
    "User retention": RETENTION,
  } as { [key: string]: number };

  const COMPANY_INSIGHT_CARD_TITLE_TO_REPORT_TYPE = {
    "Milestone overview": MILESTONES,
    "Weekly active users": ACTIVE_USERS,
    "Weekly users": FEATURE_RELEASE,
    "Weekly usage": FEATURE_RELEASE,
    "Most engaged": ENGAGEMENT,
    "Churn risk users": SLIPPING_AWAY,
  } as { [key: string]: number };

  const { appId } = useParams<{ appId: string }>();
  const cardTitlesToReportType =
    location === "home"
      ? HOME_CARD_TITLE_TO_REPORT_TYPE
      : COMPANY_INSIGHT_CARD_TITLE_TO_REPORT_TYPE;

  const { isEditing } = useSelector(editingPinnedReportsSelector);
  const dispatch = useDispatch();

  const { data, refetch } = useGetPinnedReportsQuery({
    appId: Number(appId),
    location,
  });
  const [getSearchReports] = useLazyGetSearchReportsQuery();
  const [updatePinnedReport] = useUpdatePinnedReportMutation();

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId, isEditing]);

  function fetchReportsByReportType(reportType: number) {
    return getSearchReports({
      appId: Number(appId),
      reportTypes: [reportType],
    });
  }

  function updatePinnedReportFor(pinnedReportId: number, reportId: number) {
    return updatePinnedReport({
      appId: Number(appId),
      id: pinnedReportId,
      reportId,
    });
  }

  function setIsEditing(value: boolean) {
    dispatch(toggleEdit());
  }

  return {
    isEditing,
    setIsEditing,
    pinnedReports: data?.pinnedReports,
    fetchReportsByReportType,
    updatePinnedReportFor,
    cardTitlesToReportType,
  };
};
