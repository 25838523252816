import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { ITraitWithConfig } from "core/types/Trait";
import {
  Bars3BottomLeftIcon,
  BoltIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import { ComponentDefaultProps, Divider } from "@chakra-ui/react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { useRef, useState } from "react";

export const ComputedTraitPopover: React.FC<
  {
    computedTrait: ITraitWithConfig;
  } & ComponentDefaultProps
> = ({ computedTrait, children }) => {
  const { appId } = useParams();
  const navigate = useNavigate();

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [openState, setOpenState] = useState(false);

  const onHover = (open: boolean, action: string) => {
    if (
      (!open && !openState && action === "onMouseEnter") ||
      (open && openState && action === "onMouseLeave")
    ) {
      setOpenState((openState) => !openState);
      buttonRef?.current?.click(); // eslint-disable-line
    }
  };

  return (
    <Popover>
      {({ open }) => (
        <div
          onMouseEnter={() => onHover(open, "onMouseEnter")}
          onMouseLeave={() => onHover(open, "onMouseLeave")}
        >
          <PopoverButton ref={buttonRef}>{children}</PopoverButton>
          <PopoverPanel
            transition
            className="absolute w-80 rounded-md border border-gray-200 bg-white p-3 text-sm shadow-md transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
            anchor={{ to: "bottom", gap: 8 }}
          >
            <div className="flex max-w-full flex-col gap-y-1 font-normal normal-case text-black">
              <div className="flex items-center gap-1">
                <div>
                  <BoltIcon className="h-4 w-4" />
                </div>
                <div className="flex items-center gap-2">
                  <p>{computedTrait.name}</p>
                  {computedTrait.isLive ? (
                    <span className="inline-flex items-center rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">
                      Live
                    </span>
                  ) : (
                    <span className="inline-flex items-center rounded-md bg-gray-100 px-1.5 py-0.5 text-xs font-medium text-gray-600">
                      Paused
                    </span>
                  )}
                </div>
              </div>
              {computedTrait.description && (
                <div className="flex max-w-full gap-1">
                  <div>
                    <Bars3BottomLeftIcon className="mt-0.5 h-4 w-4" />
                  </div>
                  <div className="max-w-full">
                    <p className="flex flex-wrap">
                      {computedTrait.description}
                    </p>
                  </div>
                </div>
              )}
              <div className="flex max-w-full items-center gap-1">
                <div>
                  <ClockIcon className="h-4 w-4" />
                </div>
                <div className="max-w-full">
                  <p className="max-w-[250px] truncate">
                    Last computed at:{" "}
                    {computedTrait.lastComputedAt
                      ? moment.utc(computedTrait.lastComputedAt).fromNow()
                      : "Never"}
                  </p>
                </div>
              </div>
            </div>
            <Divider my={2} />
            <div>
              <p className="text-xs font-normal normal-case text-gray-600">
                Computed traits are traits that are computed based on events.{" "}
                <p
                  onClick={() =>
                    navigate(`/a/${appId}/settings/computed-traits`)
                  }
                  className="inline cursor-pointer font-medium text-purple-500"
                >
                  View all
                </p>
              </p>
            </div>
          </PopoverPanel>
        </div>
      )}
    </Popover>
  );
};
