import React from "react";
import {
  LineChart as RechartsLineChart,
  Line as RechartsLine,
  ResponsiveContainer as RechartsResponsiveContainer,
} from "recharts";
import colors from "core/design-system/constants/theme/colors";
import {
  isAnimationDisabled,
  addLoadedIdToElement,
} from "core/design-system/constants/charts";

function LineChart({ children, data = [], ...props }) {
  return (
    <RechartsResponsiveContainer width="100%" height="100%">
      <RechartsLineChart
        margin={{ top: 10, left: 0, right: 50, bottom: 10 }}
        data={data}
        {...props}
      >
        {children}
      </RechartsLineChart>
    </RechartsResponsiveContainer>
  );
}

class Line extends RechartsLine {
  static defaultProps = {
    ...RechartsLine.defaultProps,
    // Customized props
    type: "monotone",
    stroke: colors.purple[500],
    strokeWidth: 1.5,
    activeDot: { r: 5 },
    dataKey: "y",
    isAnimationActive: !isAnimationDisabled,
    onAnimationEnd: () => addLoadedIdToElement(),
  };
}

export { LineChart, Line };
