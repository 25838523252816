import { IListConfig } from "core/components/List/List.types";
import { NameCell } from "./NameCell";
import { PinCell } from "./PinCell";
import { ActionsCell } from "./ActionsCell";
import { UpdatedAt } from "./UpdatedAt";

const config = {
  resourceName: "views",
  columns: [
    {
      title: "",
      name: "pinned",
      align: "left",
      Component: PinCell,
      width: 10,
    },
    {
      title: "View",
      name: "name",
      align: "left",
      width: `80%`,
      Component: NameCell,
    },
    {
      title: "",
      name: "updatedAt",
      align: "left",
      width: `80%`,
      Component: UpdatedAt,
    },
    {
      title: "",
      name: "menu",
      align: "right",
      Component: ActionsCell,
    },
  ],
} as IListConfig;

export { config };
