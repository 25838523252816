import { Link } from "@chakra-ui/react";
import { OnboardingStep, useDocs } from "core/hooks/useDocs";

export const StepLink = ({ step }: { step: OnboardingStep }) => {
  const { getLinkByStep } = useDocs();
  const link = getLinkByStep(step);

  if (!link) {
    return null;
  }

  return (
    <Link
      fontSize="sm"
      _hover={{ textDecoration: "underline" }}
      href={link}
      target="_blank"
    >
      View docs
    </Link>
  );
};
