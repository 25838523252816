import { ChevronRightIcon } from "@heroicons/react/20/solid";

interface IInfoCardProps {
  children: React.ReactNode;
}

export const InfoCard: React.FC<IInfoCardProps> = ({ children }) => {
  return (
    <div className="flex flex-col gap-4 rounded-lg border border-gray-200 bg-white p-4 text-sm text-gray-700">
      <div className="flex flex-col gap-2">{children}</div>
    </div>
  );
};
interface IInfoHeaderProps {
  children: React.ReactNode;
}

export const InfoHeader: React.FC<IInfoHeaderProps> = ({ children }) => {
  return <p className="text-sm font-medium text-gray-900">{children}</p>;
};

export const InfoBody: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return <div className="text-sm text-gray-600">{children}</div>;
};

export const InfoReadMoreLink: React.FC<{
  href: string;
  articleId?: string;
}> = ({ href, articleId }) => {
  return (
    <div className="flex justify-start">
      <a
        href={href}
        rel="noreferrer"
        target="_blank"
        className="flex items-center justify-start gap-1"
        onClick={(e) => {
          if (articleId) {
            e.preventDefault();
            Intercom("showArticle", articleId);
          }
        }}
      >
        <span className="text-xs font-medium">Read more</span>
        <ChevronRightIcon className="h-4 w-4" />
      </a>
    </div>
  );
};
