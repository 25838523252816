// @ts-nocheck
import { Label, ReferenceLine } from "recharts";
import React, { useState } from "react";
import { computeGrowth } from "core/helpers/computeGrowth";
import colors from "core/design-system/constants/theme/colors";
import TickText from "core/design-system/charts/TickText";
import {
  CommonDot,
  CommonGrid,
  CommonTooltip,
  CommonXAxis,
  CommonYAxis,
} from "core/design-system/charts/Common";
import { Area, AreaChart } from "core/design-system/charts/AreaChart";
import { Flex, useDisclosure } from "@chakra-ui/react";
import { NewUsersList } from "./NewUsersList";
import { CustomTooltip } from "./CustomTooltip";
import { useTooltipCoordinate } from "core/hooks/useTooltipCoordinates";

interface ILineChart {
  report: IReport;
  unit: string;
  data: any; // TBD(@aliceclv) => type data for new users
  dataKey: string;
  previewMode: boolean;
  sharingMode: boolean;
  hasHour: boolean;
  intervalType: number;
  allTimeHigh: number;
  showInProgress?: boolean;
}

interface ILineChartData {
  x: string;
  y: number;
  growth: number;
  previousDate: string;
  date: Date;
  hour: string;
}

function AllTimeHighLabel({ viewBox }: { viewBox: any }) {
  return (
    <text
      fontSize="10px"
      x={viewBox.x}
      y={viewBox.y}
      fill={colors.purple[400]}
      dy={-5}
      dx={0}
    >
      All time high
    </text>
  );
}

function LineChart({
  report,
  unit,
  data,
  dataKey = "newUsers",
  previewMode,
  sharingMode,
  intervalType,
  hasHour,
  showYAxis,
  allTimeHigh,
  showInProgress = true,
}: ILineChart) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newUsersCount, setNewUsersCount] = useState(0);
  const [humanizedDate, setHumanizedDate] = useState("");
  const [date, setDate] = useState("");
  const [hour, setHour] = useState("");
  const { coordinates, onMouseMove } = useTooltipCoordinate(-60);

  const onDataPointClick = (_data, { payload }) => {
    if (!previewMode && !sharingMode) {
      onOpen();
      setHumanizedDate(payload.x);
      setDate(payload.date);
      if (typeof payload.y === "number") {
        setNewUsersCount(payload.y);
      }
      if (typeof payload.currentY === "number") {
        setNewUsersCount(payload.currentY);
      }
      if (hasHour) {
        setHour(payload.hour);
      }
    }
  };

  let marker;

  const formattedData = data.reduce(
    (formatted: ILineChartData[], row, currentIndex) => {
      const lastRow = formatted[formatted.length - 1];
      const nextRow = data[currentIndex + 1];
      const previousDate = lastRow?.x;
      const growth = lastRow && computeGrowth(lastRow.y, row[dataKey]);

      if (!marker && row.incomplete) {
        marker = lastRow.x;
      }

      formatted.push({
        x: row.humanizedDate,
        y: !row.incomplete ? row[dataKey] : undefined,
        currentY:
          nextRow?.incomplete || row.incomplete ? row[dataKey] : undefined,
        hideY: nextRow?.incomplete,
        growth,
        previousDate,
        date: row.date,
        hour: row.hour,
      });

      return formatted;
    },
    [],
  );

  if (!showInProgress) {
    formattedData.pop();
  }

  return (
    <Flex justify="center" h={300} onMouseMove={onMouseMove}>
      <AreaChart
        margin={{ top: 30, left: showYAxis ? 0 : -20, right: 50, bottom: 20 }}
        height="100%"
        data={formattedData}
      >
        <defs>
          <linearGradient id="purpleGradient" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor={colors.purple[400]}
              stopOpacity={0.8}
            />
            <stop
              offset="95%"
              stopColor={colors.purple[100]}
              stopOpacity={0.4}
            />
          </linearGradient>
          <linearGradient
            id="currentPeriodGradient"
            x1="0"
            y1="0"
            x2="0"
            y2="1"
          >
            <stop offset="5%" stopColor={colors.gray[400]} stopOpacity={0.8} />
            <stop offset="95%" stopColor={colors.gray[100]} stopOpacity={0.4} />
          </linearGradient>
        </defs>
        <CommonGrid />
        <CommonXAxis dataKey="x" />
        <CommonYAxis
          tick={({ x, ...props }) =>
            showYAxis && (
              <TickText x={x - 5} {...props}>
                {props.payload.value}
              </TickText>
            )
          }
        />
        <CommonTooltip
          trigger="hover"
          position={coordinates}
          content={
            <CustomTooltip
              onClick={onDataPointClick}
              unit={unit}
              previewMode={previewMode}
              data={formattedData}
            />
          }
        />
        <ReferenceLine
          y={allTimeHigh}
          stroke={colors.purple[300]}
          alwaysShow={true}
        >
          {/* @ts-ignore */}
          <Label content={AllTimeHighLabel} />
        </ReferenceLine>
        {showInProgress && (
          <ReferenceLine
            x={marker}
            stroke={colors.gray[600]}
            strokeDasharray="3 3"
          />
        )}
        <Area
          dataKey="y"
          activeDot={
            <CommonDot
              onClick={onDataPointClick}
              r={7}
              cursor={!previewMode && "pointer"}
            />
          }
          type="monotone"
          fillOpacity={1}
          fill="url(#purpleGradient)"
        />
        <Area
          type="monotone"
          fillOpacity={1}
          fill="url(#currentPeriodGradient)"
          dataKey="currentY"
          stroke={colors.gray[500]}
          activeDot={
            <CommonDot
              onClick={onDataPointClick}
              r={7}
              cursor={!previewMode && "pointer"}
            />
          }
        />
      </AreaChart>
      {!sharingMode && !previewMode && (
        <NewUsersList
          report={report}
          key={date}
          isOpen={isOpen}
          onClose={onClose}
          humanizedDate={humanizedDate}
          date={date}
          hour={hour}
          newUsersCount={newUsersCount}
          intervalType={intervalType}
        />
      )}
    </Flex>
  );
}

export default LineChart;
