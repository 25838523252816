import React from "react";
import { Tooltip } from "@chakra-ui/react";

const LONG_VALUE_LIMIT = 50;
export const LongValueWrapper = (props: { value: string }) => {
  if (props.value.length > LONG_VALUE_LIMIT) {
    return (
      <Tooltip label={props.value} placement="bottom">
        <span>{props.value.slice(0, LONG_VALUE_LIMIT)}...</span>
      </Tooltip>
    );
  }
  return <>{props.value}</>;
};
