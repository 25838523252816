// @ts-nocheck
import {
  CartesianGrid as RechartsCartesianGrid,
  Cell as RechartsCell,
  Dot as RechartsDot,
  Legend as RechartsLegend,
  Tooltip as RechartsTooltip,
  XAxis as RechartsXAxis,
  YAxis as RechartsYAxis,
} from "recharts";
import colors from "core/design-system/constants/theme/colors";
import { TickText } from "core/design-system/charts/TickText";
import { Box, Text, ComponentDefaultProps } from "@chakra-ui/react";

class CommonXAxis extends RechartsXAxis {
  static defaultProps = {
    ...RechartsXAxis.defaultProps,
    // Customized props
    axisLine: { stroke: colors.gray[50] },
    tickLine: false,
    tick: ({ y, ...props }) => {
      const value = props?.payload?.value;
      return (
        <TickText y={y + 15} {...props}>
          {value}
        </TickText>
      );
    },
  };
}

class CommonYAxis extends RechartsYAxis {
  static defaultProps = {
    ...RechartsYAxis.defaultProps,
    // Customized props
    axisLine: false,
    tickLine: false,
    tick: ({ x, ...props }) => (
      <TickText x={x - 5} {...props}>
        {props.payload.value}
      </TickText>
    ),
  };
}

class CommonGrid extends RechartsCartesianGrid {
  static defaultProps = {
    ...RechartsCartesianGrid.defaultProps,
    // Customized props
    vertical: false,
    strokeDasharray: "3",
  } as ComponentDefaultProps;
}

class CommonTooltip extends RechartsTooltip {
  static defaultProps = {
    ...RechartsTooltip.defaultProps,
    // Customized props
    cursor: { fill: colors.gray[50], stroke: colors.gray[200] },
    content: <CommonCustomTooltip />,
  };
}

class CommonCell extends RechartsCell {
  static defaultProps = {
    ...RechartsCell.defaultProps,
    // Customized props
  };
}

class CommonDot extends RechartsDot {
  static defaultProps = {
    ...RechartsDot.defaultProps,
    // Customized props
    r: 5,
  };
}

class CommonLegend extends RechartsLegend {
  static defaultProps = {
    ...RechartsLegend.defaultProps,
    // Customized props
  };
}

function CommonCustomTooltip({
  label,
  payload,
  active,
  yAxisDataAccessor = null,
  xAxisDataAccessor = null,
  yAxisLabelAffix = null,
  yAxisLabelPrefix = null,
}) {
  if (active && payload && payload.length) {
    return (
      <Box bg="gray.900" px={3} py={2} borderRadius={5}>
        <Text fontSize="xs" color="gray.400">
          {xAxisDataAccessor ? payload[0].payload[xAxisDataAccessor] : label}
        </Text>
        <Text fontSize="sm" color="gray.200">
          {yAxisLabelPrefix}
          {yAxisDataAccessor
            ? payload[0].payload[yAxisDataAccessor]
            : payload[0].payload.y}
          {yAxisLabelAffix}
        </Text>
      </Box>
    );
  }
  return null;
}

export {
  CommonXAxis,
  CommonYAxis,
  CommonGrid,
  CommonTooltip,
  CommonCell,
  CommonDot,
  CommonLegend,
  CommonCustomTooltip,
};
