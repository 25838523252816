import { isDefinedString, isEmptyObject, isId } from "./utils";
import { Issue } from "./issue";

export const validateGroup = (payload: any): Issue[] => {
  const issues: Issue[] = [];

  if (!isId(payload["userId"])) {
    issues.push({
      level: "warning",
      message: "Missing user id",
    });
  }

  if (!isId(payload["groupId"])) {
    issues.push({
      level: "error",
      message: "Missing group id",
    });
  }

  if (!isDefinedString(payload["timestamp"])) {
    issues.push({
      level: "error",
      message: "Missing timestamp",
    });
  }

  if (isEmptyObject(payload["traits"])) {
    issues.push({
      level: "info",
      message: "Empty traits",
    });
  }

  return issues;
};
