import { ISingleCohortData } from "core/models/home";
import colors, {
  IColorPalette,
} from "core/design-system/constants/theme/colors";
import { Flex, Text, Tooltip } from "@chakra-ui/react";

function getRowColor({
  retentionData,
  color,
}: {
  retentionData: number;
  color: IColorPalette;
}) {
  const { gray } = colors;

  const retention = Math.floor(retentionData / 4);
  let colorIndex = retention > 5 ? 5 : retention;
  const colorKey = Number(Object.keys(color)[colorIndex]) as keyof typeof color;

  const backgroundColor =
    retentionData === 0 ? gray[50] : (color[colorKey] as string);

  return backgroundColor;
}

interface ICohortTable {
  data: ISingleCohortData[];
  color?: IColorPalette;
}

export const CohortTable: React.FC<ICohortTable> = ({
  data,
  color = colors.templateLightPurple,
}) => {
  const cohortData = data ? [...data] : [];
  const reversedData = [...cohortData.reverse()];

  return (
    <Flex
      data-testid="home-retention-cohort-table"
      justify="flex-end"
      direction="column"
      gridGap="1px"
      ml={6}
      mr="14px"
      w="100%"
      overflow="hidden"
    >
      {reversedData?.map((cohort, index) => {
        if (index > 12) return null;
        return (
          <Flex gridGap="1px" key={index}>
            {cohort?.data?.map((value, i) => {
              return (
                <Tooltip
                  hasArrow
                  key={i}
                  label={
                    <div className="flex flex-col gap-1">
                      <p className="font-medium text-gray-600">
                        Joined on {cohort.id}
                      </p>
                      <p>
                        {value.y}% after {i + 1} {cohort.intervalType}
                        {i === 0 ? "" : "s"}
                      </p>
                    </div>
                  }
                >
                  <Flex
                    _hover={{ border: `1px solid ${colors.purple[600]}` }}
                    bg={getRowColor({ retentionData: value.y, color })}
                    w="35px"
                    minW="35px"
                    h="8px"
                  />
                </Tooltip>
              );
            })}
          </Flex>
        );
      })}
      <Flex mt={2} px={3.5}>
        {reversedData?.map((cohort, index) => {
          return (
            <Text
              w="35.5px"
              minW="35.5px"
              key={index}
              px={0}
              fontSize="xs"
              color="gray.700"
            >
              {index + 1}
            </Text>
          );
        })}
      </Flex>
    </Flex>
  );
};
