import colors from "core/design-system/constants/theme/colors";
import {
  generateHexColorForTraitValueWithIndex,
  getTraitValue,
} from "core/design-system/charts/Breakdown/utils";
import { Box, Flex, Stack, Text } from "@chakra-ui/react";

interface IConversionLineChartTooltip {
  label: string;
  payload: any;
  active: boolean;
  hasBreakdown?: boolean;
  traitValues?: string[];
}

export const ConversionLineChartTooltip: React.FC<
  IConversionLineChartTooltip
> = ({ label, payload, active, hasBreakdown, traitValues, ...props }) => {
  const data = payload?.[0]?.payload;

  if (active && data) {
    return (
      <Box bg="gray.700" px={3} py={2} borderRadius={5} {...props}>
        <Text fontSize="xs" color="gray.400">
          {label}
        </Text>
        {hasBreakdown ? (
          <>
            {traitValues?.map((trait, index) => (
              <Stack direction="row" spacing="2" align="center">
                <Box
                  h="2"
                  w="2"
                  bg={generateHexColorForTraitValueWithIndex(
                    trait,
                    traitValues.length - index - 1,
                  )}
                  borderRadius={10}
                ></Box>
                <Flex w="full" justify="space-between">
                  <Text fontSize="sm" color="white">
                    {getTraitValue(trait)}
                  </Text>
                  <Text ml={2} fontSize="sm" color="white">
                    {data[`conversionPercentage${index}`]?.toFixed(1)}%
                  </Text>
                </Flex>
              </Stack>
            ))}
          </>
        ) : (
          <Stack direction="row" spacing="2" align="center">
            <Box h="2" w="2" bg={colors.purple[400]} borderRadius={10}></Box>
            <Text fontSize="sm" color="white">
              {data.conversionPercentage?.toFixed(1)}% conversion
            </Text>
          </Stack>
        )}
      </Box>
    );
  }
  return null;
};
