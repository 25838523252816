import { IGettingStartedPanel } from "routes/GettingStarted";
import { useOnboardingChecklist } from "core/hooks/useOnboardingChecklist";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { FRESHPAINT, RUDDERSTACK, SDK, SEGMENT } from "core/constants/apiKeys";
import { Toggle, ToggleButton } from "core/components/Toggle";
import { Flex } from "@chakra-ui/react";
import { ConnectSegment } from "./SetupSources/ConnectSegment";
import { ConnectSdk } from "./SetupSources/ConnectSdk";
import { ConnectRudderstack } from "./SetupSources/ConnectRudderstack";
import { ConnectFreshpaint } from "./SetupSources/ConnectFreshpaint";

export interface IOption {
  label: string;
  value: string;
}

export const DATA_SOURCE_OPTIONS: IOption[] = [
  { value: SEGMENT, label: "Segment" },
  { value: SDK, label: "June" },
  { value: RUDDERSTACK, label: "Rudderstack" },
  { value: FRESHPAINT, label: "Freshpaint" },
];

export const ConnectSource: React.FC<IGettingStartedPanel> = ({
  dataSource,
  sdk,
  setSdk,
}) => {
  const { id: appId } = useCurrentApp();
  const { updateOnboardingChecklist } = useOnboardingChecklist();
  const dataSourceLabel = DATA_SOURCE_OPTIONS.find(
    (o: IOption) => o.value === dataSource,
  )?.label;

  return (
    <div>
      <Flex direction="column" gap={4}>
        <Toggle w="100%">
          {DATA_SOURCE_OPTIONS.map((option: IOption) => (
            <ToggleButton
              key={option.value}
              isSelected={dataSourceLabel === option.label}
              onToggle={() => {
                updateOnboardingChecklist({
                  appId,
                  step: "data_source",
                  value: option.value,
                });
              }}
            >
              {option.label}
            </ToggleButton>
          ))}
        </Toggle>

        {dataSource === SEGMENT && <ConnectSegment />}
        {dataSource === SDK && (
          <ConnectSdk dataSource={dataSource} sdk={sdk} setSdk={setSdk} />
        )}
        {dataSource === RUDDERSTACK && <ConnectRudderstack />}
        {dataSource === FRESHPAINT && <ConnectFreshpaint />}
      </Flex>
    </div>
  );
};
