import { FilterType } from "core/constants/report-setup";
import {
  BuildingOffice2Icon,
  UserGroupIcon,
  UserIcon,
} from "@heroicons/react/20/solid";

export const Icon: React.FC<{ filterType: FilterType }> = ({ filterType }) => {
  return (
    <div className="mx-0.5 mr-1">
      {filterType === FilterType.UserTrait ? (
        <UserIcon
          data-testid="audience-trait-filter-user-icon"
          className="h-4 w-4"
        />
      ) : filterType === FilterType.GroupFilter ? (
        <UserGroupIcon
          data-testid="audience-trait-filter-group-icon"
          className="h-4 w-4"
        />
      ) : (
        <BuildingOffice2Icon
          data-testid="audience-trait-filter-company-icon"
          className="h-4 w-4"
        />
      )}
    </div>
  );
};
