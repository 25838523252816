import PropTypes from "prop-types";
import React, { useState } from "react";
import Icon from "core/design-system/components/Icon";
import { pull } from "lodash";
import {
  Box,
  Checkbox,
  Menu,
  MenuButton,
  MenuList,
  MenuDivider,
  Button,
  Text,
  Flex,
} from "@chakra-ui/react";

function CohortDropdown({
  cohorts,
  selectedAggregated,
  selectedCohorts,
  onSelectAggregated,
  onSelectCohorts,
}) {
  const [open, setOpen] = useState(false);
  const text = () => {
    let str = "";
    if (!selectedAggregated && !selectedCohorts.length) return "Select Cohorts";
    if (selectedAggregated) str += "Aggregated";
    if (selectedAggregated && selectedCohorts.length > 0) str += " + ";
    if (selectedCohorts.length > 1) str += `${selectedCohorts.length} Cohorts`;
    else if (selectedCohorts.length > 0) str += " 1 Cohort";
    return str;
  };

  return (
    <Menu closeOnSelect={false} closeOnBlur={true} isOpen={open}>
      {({ isOpen }) => (
        <>
          <MenuButton
            isActive={isOpen}
            as={Button}
            minWidth="220px"
            pl={4}
            pr={2}
            py={4}
            onClick={() => setOpen(!open)}
          >
            <Flex justifyContent="space-between" alignItems="center">
              {text()}
              <Icon
                iconType="icon"
                name="arrow-down-full"
                color={isOpen ? "gray.800" : "gray.600"}
              />
            </Flex>
          </MenuButton>
          <MenuList minWidth="220px" py={2}>
            <Box px={4} py={2}>
              <Checkbox
                colorScheme="purple"
                size="lg"
                spacing="1rem"
                isChecked={selectedAggregated}
                onChange={(e) => onSelectAggregated(e.target.checked)}
              >
                <Text fontSize="md" color="gray.800">
                  Aggregated
                </Text>
              </Checkbox>
            </Box>
            <MenuDivider />
            <Box maxHeight="400px" overflow="auto">
              {cohorts.map((cohort, idx) => (
                <Box key={idx} px={4} py={2} _hover={{ bg: "gray.100" }}>
                  <Checkbox
                    colorScheme="purple"
                    size="lg"
                    spacing="1rem"
                    isChecked={selectedCohorts.includes(cohort)}
                    onChange={() =>
                      selectedCohorts.includes(cohort)
                        ? onSelectCohorts([...pull(selectedCohorts, cohort)])
                        : onSelectCohorts([...selectedCohorts, cohort])
                    }
                  >
                    <Text fontSize="md" color="gray.800">
                      {cohort}
                    </Text>
                  </Checkbox>
                </Box>
              ))}
            </Box>
          </MenuList>
        </>
      )}
    </Menu>
  );
}

CohortDropdown.propTypes = {
  cohorts: PropTypes.arrayOf(PropTypes.string),
  selectedAggregated: PropTypes.bool,
  selectedCohorts: PropTypes.arrayOf(PropTypes.string),
  onSelectAggregated: PropTypes.func,
  onSelectCohorts: PropTypes.func,
};

export default CohortDropdown;
