import { Button } from "@chakra-ui/react";

export const UserSettings: React.FC<{}> = ({ ...props }) => {
  return (
    <>
      <h2 className="text-base font-semibold text-gray-900">User settings</h2>
      <div className="flex w-1/2 gap-2">
        <Button
          as="a"
          target="_blank"
          href="https://help.june.so/en/articles/6976618-how-to-change-your-email"
        >
          <div className="text-sm">Change email</div>
        </Button>
        <Button
          as="a"
          target="_blank"
          href="https://help.june.so/en/articles/6976521-how-to-change-the-password-of-your-june-account"
        >
          <div className="text-sm">Change password</div>
        </Button>
      </div>
    </>
  );
};
