import { Button, Divider, Stack, StyleProps, Tooltip } from "@chakra-ui/react";
import {
  BookOpenIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";

export interface IDefaultHeaderProps extends StyleProps {
  section: {
    title: string | JSX.Element | undefined;
    description?: string | JSX.Element;
    howItWorksText?: string;
    howItWorksArticle?: string;
    howItWorksArticleId?: string;
    sectionRight?: React.ComponentType;
    help?: JSX.Element;
  };
}

export const DefaultHeader: React.FC<IDefaultHeaderProps> = ({
  section,
  ...props
}) => {
  return (
    <Stack
      maxW="full"
      className="flex w-full max-w-full items-center"
      {...props}
    >
      <div className="flex w-full max-w-full justify-between gap-2">
        <div className="flex w-full max-w-full items-center gap-2">
          <p className="text-xl font-bold">{section.title}</p>
          {section.description && (
            <Tooltip label={section.description}>
              <InformationCircleIcon className="h-4 w-4 text-gray-600" />
            </Tooltip>
          )}
        </div>
        <div className="flex items-center gap-2">
          {section.howItWorksArticle && (
            <Tooltip label="Learn more">
              <Button
                as="a"
                target="_blank"
                rel="noreferrer"
                onClick={(e) => {
                  if (section.howItWorksArticleId) {
                    e.preventDefault();
                    window.Intercom("showArticle", section.howItWorksArticleId);
                  }
                }}
                href={section.howItWorksArticle}
                leftIcon={<BookOpenIcon className="h-4 w-4" />}
              >
                <div className="flex items-center gap-1">
                  {section.howItWorksText || "Learn"}
                </div>
              </Button>
            </Tooltip>
          )}
          {section.sectionRight && (
            <div className="flex-end">{<section.sectionRight />}</div>
          )}
        </div>
        {section.help && <div className="ml-2">{section.help}</div>}
      </div>
      <Divider mt={2} mb={4} />
    </Stack>
  );
};

export default DefaultHeader;
