import React from "react";
import { IView } from "core/models/views";
import moment from "moment";

export const UpdatedAt: React.FC<{ props: IView }> = ({ props: view }) => {
  return (
    <p className="text-xs text-gray-500">
      Last updated {moment(view.updatedAt).fromNow()}
    </p>
  );
};
