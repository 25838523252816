import { useState } from "react";
import { IAudienceFilters } from "core/types/Audience";
import { Level } from "core/models/people";
import { IAppObject } from "core/models/appObjects";
import { usePeopleCount } from "core/hooks/usePeopleCount";
import { TRAIT_LEVEL } from "core/constants/traits";
import TraitEditor from "core/components/Traits/TraitEditor";
import { GroupType } from "core/components/Group/GroupListSingleGroup";
import { Button, Tooltip } from "@chakra-ui/react";
import { OBJECT_TYPE_TO_GROUP_TYPE, OBJECT_TYPE_TO_LEVEL } from "./Tab";

export const BulkTrait: React.FC<{
  appObject: IAppObject;
  audienceFilters: IAudienceFilters;
  searchQuery: string;
}> = ({ appObject, audienceFilters, searchQuery }) => {
  const groupType = OBJECT_TYPE_TO_GROUP_TYPE[
    appObject.objectType
  ] as GroupType;
  const level = OBJECT_TYPE_TO_LEVEL[appObject.objectType] as Level;
  let traitLevel;
  if (level === Level.User) {
    traitLevel = TRAIT_LEVEL.USER;
  } else if (groupType === GroupType.Company) {
    traitLevel = TRAIT_LEVEL.COMPANY;
  } else {
    traitLevel = TRAIT_LEVEL.GROUP;
  }
  const [traitEditModal, showTraitEditModal] = useState(false);

  const { matchedCount, isLoadingCount } = usePeopleCount({
    audienceFilters,
    searchQuery,
    level,
    groupType,
  });

  return (
    <div className="flex">
      <Tooltip
        label={`Add a trait to ${matchedCount?.toLocaleString()} ${appObject.pluralName.toLocaleLowerCase()} who match these filters`}
        placement="bottom"
        w="150px"
      >
        <Button
          py={2}
          onClick={() => showTraitEditModal(!traitEditModal)}
          isDisabled={isLoadingCount || matchedCount === 0}
          size="sm"
        >
          Add trait
        </Button>
      </Tooltip>
      <TraitEditor
        traitLevel={traitLevel}
        isOpen={traitEditModal}
        onClose={() => showTraitEditModal(false)}
        audienceFilters={audienceFilters}
        searchQuery={searchQuery}
        objectCount={matchedCount}
      />
    </div>
  );
};
