import { ComponentDefaultProps } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { useCurrentApp } from "../core/hooks/useCurrentApp";

interface IAppNavLink extends ComponentDefaultProps {
  to: string;
}

export const AppNavLink: React.FC<IAppNavLink> = ({ to, ...props }) => {
  const app = useCurrentApp();

  // remove first slash if it exists
  if (to[0] === "/") {
    to = to.slice(1);
  }

  return <NavLink to={`/a/${app.id}/${to}`} {...props} />;
};
