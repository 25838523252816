import React from "react";
import { Box, Text, useRadio, UseRadioProps } from "@chakra-ui/react";

export const RadioCard: React.FC<UseRadioProps> = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const { children } = props;
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  function getCheckedStyle() {
    const _checkedStyle = {
      background: "white",
      border: "1px solid",
      borderColor: "gray.100",
      borderRadius: "md",
      height: "100%",
      fontWeight: "medium",
      color: "gray.800",
    };

    return _checkedStyle;
  }
  const checkedStyle = getCheckedStyle();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        w="60px"
        p={0.5}
        fontSize="xs"
        fontWeight={"400"}
        cursor="pointer"
        color={"gray.600"}
        _checked={checkedStyle}
      >
        {typeof children === "string" ? (
          <Text textAlign="center">
            {children?.charAt(0)?.toUpperCase() + children?.slice(1)}
          </Text>
        ) : (
          children
        )}
      </Box>
    </Box>
  );
};
