import React from "react";
import { IAppUser } from "core/types/User";
import axios from "core/initializers/axios";
import { useToast } from "core/hooks/useToast";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

interface IRemoveTeammateModalProps {
  member: IAppUser;
  mutateUserList: () => void;
  onClose: () => void;
  isOpen: boolean;
}

const RemoveTeammateModal: React.FC<IRemoveTeammateModalProps> = ({
  member,
  mutateUserList,
  onClose,
  isOpen,
}) => {
  const { id: appId } = useCurrentApp();
  const toast = useToast();

  const onDeleteUser = () => {
    axios
      .delete(`/users/${member.id}/delete_from_app/${appId}`, {
        withCredentials: true,
      })
      .then(() => {
        mutateUserList();
        onClose();
        toast({
          title: "Account successfully suspended",
          description: "This member has no longer access to your workspace.",
          duration: 6000,
        });
      });
  };
  return (
    <Modal onClose={onClose} isOpen={isOpen} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontSize="md">
            Are you sure you want to remove {member.email}?
          </Text>
        </ModalHeader>
        <ModalBody>
          <Text fontSize="sm" color="gray.600">
            They won't be able to access this workspace.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={2} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="red" onClick={onDeleteUser}>
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RemoveTeammateModal;
