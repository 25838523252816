import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { IApp } from "core/types/App";
import { useGetAppsQuery } from "core/models/apps";
import {
  DEMO_WORKSPACE_APP,
  LOCAL_STORAGE_DEMO_APP_KEY,
} from "core/hooks/useDemo";
import {
  CommandInput,
  CommandList,
  CommandOption,
} from "core/design-system/components/Command";
import { DEMO_APP_ID } from "core/constants/appIds";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { Skeleton } from "@chakra-ui/react";
import { setCurrentApp } from "../../../../core/models/app";

export const WorkspaceList: React.FC<{
  currentApp: IApp;
  onClose: () => void;
}> = ({ currentApp, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  const { data: apps, isLoading } = useGetAppsQuery();

  const handleAppChange = (redirectToApp: IApp) => {
    dispatch(setCurrentApp({ app: redirectToApp }));
    navigate(`/a/${redirectToApp.id}/home`);

    if (redirectToApp.id === DEMO_APP_ID) {
      localStorage.setItem(LOCAL_STORAGE_DEMO_APP_KEY, "true");
    }
  };

  if (isLoading) {
    return (
      <CommandList>
        <div className="flex flex-col gap-1">
          <Skeleton w="full" h={6} borderRadius="md" />
          <Skeleton w="full" h={6} borderRadius="md" />
          <Skeleton w="full" h={6} borderRadius="md" />
        </div>
      </CommandList>
    );
  }

  return (
    <>
      {apps && apps?.length > 5 && (
        <CommandInput
          className="mb-1"
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      )}
      <CommandList className="max-h-[215px] overflow-y-scroll">
        {apps
          ?.filter((a) => a.name.toLowerCase().includes(search.toLowerCase()))
          ?.map((app, index) => (
            <CommandOption
              className="my-0.5 w-full"
              key={index}
              value={() => {
                onClose();
                handleAppChange(app);
              }}
            >
              <div className="flex items-center justify-between gap-2">
                <div className="flex max-w-[80%] items-center gap-2 ">
                  <div className="truncate">{app.name}</div>
                  {app.committeeMember ? (
                    <div>
                      <span className="inline-flex items-center rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">
                        Committee
                      </span>
                    </div>
                  ) : null}
                </div>
                {app.id === currentApp.id && (
                  <CheckCircleIcon className="h-4 w-4 text-purple-600" />
                )}
              </div>
            </CommandOption>
          ))}
        <CommandOption
          className="w-full"
          value={() => {
            handleAppChange(DEMO_WORKSPACE_APP);
            onClose();
          }}
        >
          <div className="flex items-center justify-between gap-2">
            <div className="flex items-center gap-2">
              <div>{DEMO_WORKSPACE_APP.name}</div>
              <div>
                <span className="inline-flex items-center rounded-md bg-orange-100 px-1.5 py-0.5 text-xs font-medium text-orange-700">
                  Demo
                </span>
              </div>
            </div>
            {DEMO_WORKSPACE_APP.id === currentApp.id && (
              <CheckCircleIcon className="h-4 w-4 text-purple-600" />
            )}
          </div>
        </CommandOption>
      </CommandList>
    </>
  );
};
