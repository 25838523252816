export const computeGrowth = (
  previousCount: number,
  currentCount: number,
): number => {
  if (previousCount === currentCount) return 0;
  if (previousCount === 0) return 100;

  let growth = (Math.abs(currentCount - previousCount) / previousCount) * 100;
  if (previousCount > currentCount) growth *= -1;

  return parseInt(growth.toFixed(1), 10);
};
