import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { IAudienceFilters } from "core/types/Audience";
import { useSaveAudienceMutation } from "core/models/audiences";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useAppObjects } from "core/hooks/useAppObjects";
import { renderToast } from "core/helpers/toast";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { FiltersAccordion } from "./FiltersAccordion";

interface ISaveAudienceModalProps {
  isOpen: boolean;
  initialAudienceFilters: IAudienceFilters;
  onClose: () => void;
  showFilterPreview?: boolean;
}

export const SaveAudienceModal: React.FC<ISaveAudienceModalProps> = ({
  isOpen,
  initialAudienceFilters,
  onClose,
  showFilterPreview = true,
}) => {
  const navigate = useNavigate();
  const { id: appId } = useCurrentApp();
  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string>("");
  const [audienceFilters, setAudienceFilters] = useState<IAudienceFilters>(
    initialAudienceFilters,
  );
  const { activeAppObject } = useAppObjects();
  const [saveAudience] = useSaveAudienceMutation();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const isNameEmpty = !name;
  const isFilterEmpty = audienceFilters.filterGroups?.length === 0;

  const handleSave = async () => {
    if (isNameEmpty || isFilterEmpty) {
      setIsSubmitting(true);
      return;
    }

    await saveAudience({
      appId,
      name,
      description,
      audienceFilters,
      appObjectId: activeAppObject?.id,
    })
      .unwrap()
      .then(() => {
        renderToast({
          action: "save",
          status: "success",
          resource: "audience",
        });
        navigate(`/a/${appId}/audiences`);
      })
      .catch(() =>
        renderToast({ action: "save", status: "error", resource: "audience" }),
      );

    onClose();
  };

  useEffect(() => {
    setAudienceFilters(initialAudienceFilters);
  }, [initialAudienceFilters]);

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setIsSubmitting(false);
      }}
    >
      <ModalOverlay />
      <ModalContent borderRadius="lg">
        <ModalHeader>
          <Text fontSize="md" fontWeight="medium" mb={2}>
            Save audience
          </Text>
          <Text fontSize="sm" color="gray.600" fontWeight="normal">
            Saved audiences enable you to save a set of filters that you can use
            in any report
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            mb={4}
            borderRadius="lg"
            size="sm"
            width="100%"
            placeholder="Pick a name for your audience"
            type="text"
            name="name"
            onChange={(e) => setName(e.target.value)}
          />
          {isNameEmpty && isSubmitting && (
            <Text className="-mt-2 mb-4 ml-2 text-xs font-medium text-red-400">
              Name is required
            </Text>
          )}
          <Textarea
            borderRadius="lg"
            focusBorderColor="purple.500"
            size="sm"
            placeholder="Give a meaningful description of your audience: who are the people you are filtering?"
            onChange={(e) => setDescription(e.target.value)}
            mb={4}
          />
          {showFilterPreview && (
            <FiltersAccordion audienceFilters={audienceFilters} />
          )}
          {isFilterEmpty && isSubmitting && (
            <Text className="-mt-2 mb-4 ml-2 text-xs font-medium text-red-400 ">
              At least one filter is required
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={handleSave}
            colorScheme="purple"
            disabled={isSubmitting && (isFilterEmpty || isNameEmpty)}
          >
            Save audience
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SaveAudienceModal;
