import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { IContact } from "core/types/Contact";
import { deleteOneContact } from "core/models/contact/actions/deleteOneContact";
import { RootState } from "core/initializers/store";
import { useToast } from "core/hooks/useToast";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

interface IDeleteContactModalProps {
  isOpen: boolean;
  onClose: () => void;
  contact: IContact;
  appId: number;
}

export const DeleteContactModal = ({
  isOpen,
  onClose,
  contact,
  appId,
}: IDeleteContactModalProps) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();

  const isDeleting = useSelector(
    (state: RootState): boolean =>
      state.contact.deleteContact.loading[contact.id],
  );

  const onDelete = useCallback(() => {
    if (!appId || !contact.id) return;
    dispatch(deleteOneContact({ appId, id: contact.id }));
  }, [dispatch, appId, contact.id]);

  return (
    <Modal
      size="lg"
      closeOnEsc
      closeOnOverlayClick
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete this user?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            Delete this user and all the events they performed until now. If
            they send more events, they will show up again.
          </Text>
          <Text pt={2}>
            It might take at least an hour before they disappear from your users
            list
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme="red"
            isDisabled={isDeleting}
            onClick={() => {
              onDelete();
              toast({
                title: "Deleted successfully",
                description:
                  "It might take at least an hour before this user disappears from your users list.",
              });
              navigate(`/a/${appId}/people`);
            }}
          >
            Delete them permanently
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
