import React from "react";
import {
  useGetReportQuery,
  useUpdateReportNameMutation,
} from "core/models/reports";
import { useToast } from "core/hooks/useToast";
import { TwEditable } from "core/design-system/components/TwEditable";

interface IEditableReportTitleProps {
  reportId: number;
  appId: number;
}

const EditableReportTitle: React.FC<IEditableReportTitleProps> = ({
  reportId,
  appId,
}) => {
  const { data: report } = useGetReportQuery({
    appId,
    reportId,
  });
  const toast = useToast();
  const [updateReportName, { error }] = useUpdateReportNameMutation();

  const updateName = async (newReportName: string) => {
    if (newReportName === "") {
      return;
    }
    await updateReportName({
      appId,
      reportId,
      name: newReportName,
    });
    if (error) {
      return toast({
        title: "Failed to update report name",
        status: "error",
        duration: 5000,
      });
    }
  };
  if (!report) return <></>;

  return (
    <TwEditable
      defaultValue={report?.name}
      onSubmit={updateName}
      placeholder={"Untitled"}
    />
  );
};

export default EditableReportTitle;
