import React from "react";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { IView } from "core/models/views";
import { EllipsisVerticalIcon, TrashIcon } from "@heroicons/react/24/outline";
import { DeleteViewModal } from "modules/Navigation/Sidebar/DeleteViewModal";

export const ActionsCell: React.FC<{ props: IView }> = ({ props: view }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Menu>
        <MenuButton as={Button} variant="ghost">
          <EllipsisVerticalIcon className="h-4 w-4" />
        </MenuButton>
        <MenuList>
          <MenuItem className="flex items-center gap-1" onClick={onOpen}>
            <TrashIcon className="h-4 w-4" />
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
      <DeleteViewModal
        isOpen={isOpen}
        view={view}
        onClose={onClose}
        route="/a/:appId/views"
      />
    </>
  );
};
