import { useEffect, useMemo, useState } from "react";
import { IView, useGetViewsQuery } from "core/models/views";
import { MAX_INSIGHTS_PER_VIEW, ViewLocation } from "core/hooks/useViews";
import { IViewActionItem } from "core/components/InsightCard/Actions/AddToViewAction";
import { useAppObjects } from "./useAppObjects";

interface IViewInsightActionsProps {
  appId: number;
  reportId: string;
  insightId: number;
}

export const useViewInsightActions = ({
  appId,
  reportId,
  insightId,
}: IViewInsightActionsProps) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const { data, isLoading } = useGetViewsQuery({
    appId,
    location: ViewLocation.Home,
    pinned: false,
  });
  const { activeAppObject } = useAppObjects();
  const views = useMemo(() => data?.views || [], [data?.views]);

  const [items, setItems] = useState<IViewActionItem[]>([]);

  const hasBeenPinned = Boolean(
    views?.some((view) =>
      view.viewInsights.some(
        (viewInsight) =>
          String(viewInsight.reportId) === reportId &&
          viewInsight.insightId === insightId &&
          viewInsight?.appObject?.objectType === activeAppObject?.objectType,
      ),
    ),
  );

  useEffect(() => {
    const viewsToItems = (views: IView[]) => {
      return views
        .map((view) => ({
          displayName: view.name,
          value: view.id,
          isAdded: view.viewInsights.some(
            (viewInsight) =>
              String(viewInsight.reportId) === reportId &&
              viewInsight.insightId === insightId &&
              viewInsight?.appObject?.objectType ===
                activeAppObject?.objectType,
          ),
          viewInsightId:
            view.viewInsights.find(
              (viewInsight) =>
                String(viewInsight.reportId) === reportId &&
                viewInsight.insightId === insightId,
            )?.id ?? null,
          canAddInsightToView: view.viewInsights.length < MAX_INSIGHTS_PER_VIEW,
        }))
        .filter((item) =>
          item.displayName.toLowerCase().includes(searchValue.toLowerCase()),
        );
    };

    if (views) {
      setItems(viewsToItems(views));
    }
  }, [views, reportId, insightId, searchValue, activeAppObject]);

  return { isLoading, items, hasBeenPinned, searchValue, setSearchValue };
};
