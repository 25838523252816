import { TigerIcon } from "./Avatars/TigerIcon";
import { RoosterIcon } from "./Avatars/RoosterIcon";
import { RobotIcon } from "./Avatars/RobotIcon";
import { PigIcon } from "./Avatars/PigIcon";
import { PandaIcon } from "./Avatars/PandaIcon";
import { MonkeyIcon } from "./Avatars/MonkeyIcon";
import { KoalaIcon } from "./Avatars/KoalaIcon";
import { GiraffeIcon } from "./Avatars/GiraffeIcon";
import { GhostIcon } from "./Avatars/GhostIcon";
import { FoxIcon } from "./Avatars/FoxIcon";
import { DragonIcon } from "./Avatars/DragonIcon";
import { DogIcon } from "./Avatars/DogIcon";
import { CatIcon } from "./Avatars/CatIcon";
import { BearIcon } from "./Avatars/BearIcon";
import { AnonymousIcon } from "./Avatars/AnonymousIcon";
import { AlienIcon } from "./Avatars/AlienIcon";

const iconPaths = {
  anonymous: AnonymousIcon,
  alien: AlienIcon,
  bear: BearIcon,
  cat: CatIcon,
  dog: DogIcon,
  dragon: DragonIcon,
  fox: FoxIcon,
  ghost: GhostIcon,
  koala: KoalaIcon,
  monkey: MonkeyIcon,
  panda: PandaIcon,
  pig: PigIcon,
  robot: RobotIcon,
  rooster: RoosterIcon,
  tiger: TigerIcon,
  giraffe: GiraffeIcon,
};

export default iconPaths;
