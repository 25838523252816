import React from "react";

export const TickText: React.FC<any> = ({ children, ...props }) => {
  return (
    <text {...props} className="custom-label-text">
      {children}
    </text>
  );
};

export default TickText;
