import { IListColumn, IListConfig } from "core/components/List/List.types";
import SentAtDateCell from "./SentAtDateCell";
import EventTypeCell from "./EventTypeCell";
import CompanyCell from "./CompanyCell";
import AnyEventNameCell from "./AnyEventNameCell";
import { ContactCell } from "./ContactCell";

export const config: ({
  showEventType,
  showEventName,
  showEventProperties,
  additionalColumns,
}: {
  showEventType?: boolean;
  showEventName?: boolean;
  showEventProperties?: boolean;
  additionalColumns?: IListColumn[];
}) => IListConfig = ({
  showEventType = true,
  showEventName = true,
  additionalColumns,
}) => {
  let columns = [
    {
      title: "Sent at",
      name: "timestamp",
      align: "left",
      Component: SentAtDateCell,
    },
    {
      title: "User",
      name: "email",
      Component: ContactCell,
      dataKey: "contact",
      align: "left",
      tooltip:
        "We refer to a user as 'Anonymous' if they are not identified. 'Unknown' is when we're not able to find 'firstName', 'lastName', 'name', 'username', or 'email' in their traits.",
    },
  ] as IListColumn[];

  if (showEventType) {
    columns.splice(2, 0, {
      title: "",
      name: "eventType",
      dataKey: "event",
      align: "left",
      Component: EventTypeCell,
    });
  }

  columns.splice(2, 0, {
    title: "Workspace",
    name: "workspace",
    dataKey: "group",
    align: "left",
    Component: CompanyCell,
    tooltip:
      "The group associated with the user who triggered the event. Pass the group_id in the context of the track call to associate the event with a company.",
  });

  if (showEventName) {
    columns.splice(2, 0, {
      title: "Event",
      name: "event",
      align: "left",
      Component: AnyEventNameCell,
    });
  }

  if (additionalColumns) {
    columns = columns.concat(additionalColumns);
  }

  return {
    resourceName: "events",
    columns,
  };
};
