import { createIcon } from "@chakra-ui/react";

export const QuestionIcon = createIcon({
  viewBox: "0 0 16 16",
  path: (
    <>
      <path
        d="M7.622 10.502c.404 0 .571-.29.571-.703v-.237c0-.976.326-1.477 1.477-2.215 1.204-.774 1.854-1.679 1.854-2.98 0-1.837-1.511-3.164-3.603-3.164-1.652 0-2.936.809-3.42 2.1a2.301 2.301 0 0 0-.192.906c0 .352.193.554.536.554.307 0 .457-.132.562-.545.255-1.222 1.17-1.969 2.47-1.969 1.406 0 2.408.888 2.408 2.17 0 .924-.43 1.548-1.53 2.277-1.23.8-1.73 1.53-1.73 2.707v.413c0 .378.184.686.597.686Zm0 3.577c.466 0 .826-.36.826-.817a.814.814 0 0 0-.826-.827.812.812 0 0 0-.817.827c0 .457.36.817.817.817Z"
        fill="currentColor"
      />
    </>
  ),
});
