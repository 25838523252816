import { ComponentDefaultProps, Flex, Text, Tooltip } from "@chakra-ui/react";

interface ITab extends ComponentDefaultProps {
  isSelected: boolean;
  onTabChange: () => void;
  tooltipLabel?: string;
}

export const Tab: React.FC<ITab> = ({
  isSelected,
  onTabChange,
  tooltipLabel,
  children,
  ...props
}) => {
  return (
    <Flex mr={2}>
      <Tooltip
        label={
          tooltipLabel &&
          isSelected && (
            <Text as="b" fontWeight="bold" color="gray.100">
              {tooltipLabel}
            </Text>
          )
        }
        hasArrow
        placement="bottom"
      >
        <Text
          cursor="pointer"
          px={2}
          py={1}
          fontSize="sm"
          fontWeight="bold"
          color={isSelected ? "gray.700" : "gray.600"}
          borderBottom={isSelected ? "4px solid" : "none"}
          borderColor="purple.500"
          onClick={onTabChange}
          {...props}
        >
          {children}
        </Text>
      </Tooltip>
    </Flex>
  );
};
