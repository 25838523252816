import { ITrait } from "core/types/Trait";
import { ITemplateConfig } from "core/types/TemplateConfig";
import CompaniesList from "core/modules/reports/report-types/GroupEngagement/CompaniesList";
import UserList from "core/modules/reports/report-types/Engagement/UserList";
import { DEFAULT_GROUPED_OPTIONS } from "core/constants/timerange";
import { Template } from "core/constants/templates";
import {
  ANY,
  AUDIENCE_TYPE,
  MULTI_EVENT_TYPE,
  OR,
  PAGE,
  TRACK,
} from "core/constants/report-setup";
import { List } from "core/components/ViewInsight/Graphs/List";
import defaultTemplateConfig from "./defaultTemplateConfig";

const engagement: ITemplateConfig = {
  ...defaultTemplateConfig,
  isPopular: true,
  route: "engagement",
  reportType: 2,
  title: "Most engaged users",
  template: Template.Engagement,
  aboutTemplate: "Get a detailed list of the users who are the most engaged",
  subtitle: "A list of your most engaged users",
  sections: { user: [UserList], company: [CompaniesList] },
  hasDateRangeSection: true,
  dateRangeOptions: DEFAULT_GROUPED_OPTIONS,
  insights: [
    {
      typeId: 0,
      slug: "most-engaged-users",
      title: "Most engaged {APP_OBJECT_PLURAL_NAME}",
      slackAlertAvailable: true,
      section: UserList,
      description: {
        title: "Learn more about most engaged users",
        content:
          "Top 30 users ranked by the total count of interactions with the events selected in the report setup.",
      },
      downloadable: false,
      view: {
        level: "user",
        Component: List,
        transform: (data) => {
          if (!data?.slice) return [];
          return data?.slice(0, 4)?.map((entity: any) => {
            const traits = entity?.traits as ITrait;
            return {
              id: entity?.id,
              displayName: traits?.["email"],
              avatar: traits?.["avatar"],
              description: `Performed ${entity?.occurrences} events`,
            };
          });
        },
        hasTimerangePicker: true,
        dateRangeOptions: DEFAULT_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 1,
      slug: "most-engaged-companies",
      title: "Most engaged {APP_OBJECT_PLURAL_NAME}",
      slackAlertAvailable: true,
      section: CompaniesList,
      description: {
        title: "Learn more about most engaged companies",
        content:
          "Top 30 companies ranked by the total count of interactions with the events selected in the report setup.",
      },
      downloadable: false,
      view: {
        level: "group",
        Component: List,
        transform: (data) => {
          if (!data?.slice) return [];
          return data?.slice(0, 4)?.map((entity: any) => {
            const traits = entity?.traits as ITrait;
            return {
              id: entity?.groupId,
              displayName: traits?.["name"],
              avatar: traits?.["avatar"],
              description: `Performed ${entity?.count} events`,
            };
          });
        },
        hasTimerangePicker: true,
        dateRangeOptions: DEFAULT_GROUPED_OPTIONS,
      },
    },
  ],
  setup: {
    requiredSections: [0],
    eventOperator: OR,
    setupSections: [
      {
        id: "retention_event",
        configKey: "events",
        title: "Events",
        description: "Select the events you want use to measure engagement",
        type: MULTI_EVENT_TYPE,
        supportedEventTypes: [ANY, TRACK, PAGE],
        required: true,
        validate: (events) =>
          events &&
          events.length > 0 &&
          events.length ===
            events.map((event) => event.id).filter((x) => x).length,
      },
      {
        id: "audience",
        configKey: "audience",
        title: "Audience",
        description: "Select an audience for your report",
        type: AUDIENCE_TYPE,
        supportedUserTypes: ["user"],
      },
    ],
  },
  validateSetup: (config) =>
    config &&
    config?.events &&
    config?.events?.length > 0 &&
    config?.events?.some((e) => e.name),
};

export default engagement;
