import { useEffect } from "react";
import { InsightsContainer } from "modules/Report/InsightsContainer";
import { GraphType } from "core/types/ViewInsight";
import { ITimerangeOption } from "core/types/TimerangeOption";
import { IReport } from "core/types/Report";
import { TimerangePicker } from "core/modules/reports/Timerange/TimerangePicker";
import { IViewInsight } from "core/models/viewInsights";
import { useReportTimerange } from "core/hooks/useReportTimerange";
import { useReportSetup } from "core/hooks/useReportSetup";
import { useNavigateToReport } from "core/hooks/useNavigateToReport";
import { getReportConfig } from "core/helpers/getReportConfig";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { getInsightConfig } from "./ViewInsightContainer";

export const FullInsightModal: React.FC<{
  viewInsight: IViewInsight;
  isOpen: boolean;
  onClose: () => void;
  isBlurred?: boolean;
  groupId?: string;
}> = ({ viewInsight, isOpen, onClose, isBlurred, groupId }) => {
  const templateConfig = getReportConfig(viewInsight.report.reportType)!;
  const insightConfig = getInsightConfig(
    viewInsight.insight.reportType,
    viewInsight.insightId as number,
  );
  const { navigateToReport } = useNavigateToReport({ viewInsight });
  const { hasDateRangeSection, dateRangeOptions, timerangeTooltipText } =
    templateConfig;
  const { currentReport, loadReportSetup } = useReportSetup();

  const {
    label,
    timerangeType,
    relativeTimerange,
    customTimerange,
    setCustomTimerange,
    saveTimerange,
  } = useReportTimerange(currentReport as IReport);

  useEffect(() => {
    loadReportSetup({
      report: viewInsight.report,
      setup: templateConfig.setup,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewInsight]);

  return (
    <Modal
      scrollBehavior="inside"
      isCentered
      size="6xl"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <div className="flex w-[95%] items-center justify-between">
            <div>
              <p>{viewInsight.title || viewInsight.report.name}</p>
              <p className="text-sm font-normal capitalize text-gray-600">
                {insightConfig?.title.replace(
                  "{APP_OBJECT_PLURAL_NAME}",
                  viewInsight.appObject?.pluralName || "",
                )}
              </p>
            </div>
          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="rounded-lg bg-gray-100 px-10 pb-10 pt-5">
            <div className="flex justify-end">
              {hasDateRangeSection && (
                <div className="flex items-center">
                  <TimerangePicker
                    label={label}
                    options={dateRangeOptions}
                    timerangeType={timerangeType}
                    relativeTimerange={relativeTimerange as ITimerangeOption}
                    customTimerange={customTimerange}
                    setCustomTimerange={setCustomTimerange}
                    saveTimerange={
                      saveTimerange as (o: ITimerangeOption) => void
                    }
                    tooltipText={timerangeTooltipText}
                  />
                </div>
              )}
            </div>
            {insightConfig && currentReport.id && (
              <InsightsContainer
                report={currentReport as IReport}
                templateConfig={templateConfig}
                insightConfig={insightConfig}
              />
            )}
          </div>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="purple" variant="ghost" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            isDisabled={viewInsight.graphType === GraphType.ContributionGraph}
            onClick={() => {
              navigateToReport({
                groupId: groupId,
                skipNavigate: isBlurred,
              });
            }}
            colorScheme="purple"
          >
            View full report
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
