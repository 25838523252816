import {
  useCreateEnrichedEmailMutation,
  useGetEnrichedEmailQuery,
} from "core/models/enrichedEmail";

export const useEmailEnrichment = ({
  appId,
  email,
}: {
  appId: string;
  email: string;
}) => {
  const { data, isLoading, error } = useGetEnrichedEmailQuery({ email, appId });
  const [createdEnrichedEmail, { isLoading: isLoadingCreatingEnrichment }] =
    useCreateEnrichedEmailMutation();

  function enrichEmail({ appId, email }: { appId: string; email: string }) {
    createdEnrichedEmail({ appId, email });
  }

  return {
    enrichedEmail: data,
    isLoading,
    error,
    createdEnrichedEmail: enrichEmail,
    isLoadingCreatingEnrichment,
  };
};
