import { ISticknessRatioDatum } from "core/types/LineChart";

export const splitCurrentPeriodAndFormatData = (
  data?: ISticknessRatioDatum[],
): [string, ISticknessRatioDatum[]] | [] => {
  if (!data) return [];

  const completedPeriodData = data.filter((row: any) => !row.incomplete);
  const currentPeriodData = data.filter((row: any) => row.incomplete);

  const formattedCompletePeriodData = completedPeriodData.map((row: any) => {
    return {
      ...row,
      x: row.humanizedDate,
      date: row.date,
    };
  });
  const formattedCurrentPeriodData = currentPeriodData.map(
    (row: any, index: number) => {
      return {
        ...row,
        x: row.humanizedDate,
        date: row.date,
        stickynessRatio: index === 0 ? row.stickynessRatio : undefined,
        currentStickynessRatio: row.stickynessRatio,
      };
    },
  );

  return [
    currentPeriodData[0]?.humanizedDate,
    [...formattedCompletePeriodData, ...formattedCurrentPeriodData],
  ];
};
