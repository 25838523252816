import { useEffect } from "react";
import {
  useGetIsLoggedInQuery,
  useLoginMutation,
  useLogoutMutation,
  useSignupMutation,
  useUpdateOnboardingTraitsMutation,
} from "core/models/auth";
import * as Sentry from "@sentry/browser";

export const useUserAuth = () => {
  const isLoggedIn = useGetIsLoggedInQuery();
  const [login] = useLoginMutation();
  const [signup, signupResult] = useSignupMutation();
  const [logout] = useLogoutMutation();
  const [updateOnboardingTraits] = useUpdateOnboardingTraitsMutation();

  const features = isLoggedIn.data ? isLoggedIn.data.features : [];
  const currentUser = isLoggedIn.data ? isLoggedIn.data.user : undefined;
  const isLoading = isLoggedIn.isLoading;
  const isSignupLoading = signupResult.isLoading;
  const signupResponse = signupResult;

  const domain = currentUser?.email?.split(`@`)[1];

  useEffect(() => {
    if (import.meta.env.PROD && currentUser) {
      // @ts-ignore
      window.Intercom("update", {
        email: currentUser.email,
        user_id: currentUser.id,
        user_hash: currentUser.userHash,
      });
      Sentry.setUser({ email: currentUser.email, user_id: currentUser.id });
      window.analytics.identify(currentUser.id);
    }
  }, [currentUser]);

  return {
    login,
    signup,
    signupResponse,
    currentUser,
    features,
    logout,
    isLoading,
    updateOnboardingTraits,
    isSignupLoading,
    domain,
  };
};
