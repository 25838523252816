import moment from "moment";

export const mockData = [
  {
    id: "32967",
    name: "Elisha",
    eventCount: 14,
    firstSeenAt: moment().subtract(4, "days").toISOString(),
    lastSeenAt: moment().subtract(9, "hours").toISOString(),
    daysSinceLastEvent: 7,
    contacts: [{ userId: "1", email: "email@apple.com" }],
  },
  {
    id: "32968",
    name: "Lacresha",
    eventCount: 11,
    firstSeenAt: moment().subtract(1, "days").toISOString(),
    lastSeenAt: moment().subtract(2, "hours").toISOString(),
    daysSinceLastEvent: 7,
    contacts: [{ userId: "1", email: "email@google.com" }],
  },
  {
    id: "32969",
    name: "Odell Hoeger",
    eventCount: 12,
    firstSeenAt: moment().subtract(10, "days").toISOString(),
    lastSeenAt: moment().subtract(2, "hours").toISOString(),
    daysSinceLastEvent: 7,
    contacts: [{ userId: "1", email: "email@nevermind.com" }],
  },
  {
    id: "32970",
    name: "Milagro Greenholt MD",
    eventCount: 19,
    firstSeenAt: moment().subtract(30, "days").toISOString(),
    lastSeenAt: moment().subtract(1, "hours").toISOString(),
    daysSinceLastEvent: 7,
    contacts: [{ userId: "1", email: "email@hello.com" }],
  },
  {
    id: "32971",
    name: "Nakita Tremblay",
    eventCount: 15,
    firstSeenAt: moment().subtract(11, "days").toISOString(),
    lastSeenAt: moment().subtract(2, "hours").toISOString(),
    daysSinceLastEvent: 7,
    contacts: [{ userId: "1", email: "email@fake.com" }],
  },
  {
    id: "32972",
    name: "Jess Ratke",
    eventCount: 13,
    firstSeenAt: moment().subtract(10, "days").toISOString(),
    lastSeenAt: moment().subtract(9, "hours").toISOString(),
    daysSinceLastEvent: 7,
    contacts: [{ userId: "1", email: "email@notexisting.com" }],
  },
  {
    id: "32973",
    name: "Rosetta Dooley III",
    eventCount: 20,
    firstSeenAt: moment().subtract(20, "days").toISOString(),
    lastSeenAt: moment().subtract(7, "hours").toISOString(),
    daysSinceLastEvent: 7,
    contacts: [{ userId: "1", email: "email@company.com" }],
  },
  {
    id: "32974",
    name: "Otelia Herzog LLD",
    eventCount: 21,
    firstSeenAt: moment().subtract(11, "days").toISOString(),
    lastSeenAt: moment().subtract(5, "hours").toISOString(),
    daysSinceLastEvent: 7,
    contacts: [{ userId: "1", email: "email@facesome.com" }],
  },
  {
    id: "33418",
    name: "Sen. Wesley Bernhard",
    eventCount: 29,
    firstSeenAt: moment().subtract(13, "days").toISOString(),
    lastSeenAt: moment().subtract(8, "hours").toISOString(),
    daysSinceLastEvent: 7,
    contacts: [{ userId: "1", email: "email@amazing.com" }],
  },
  {
    id: "33419",
    name: "Dwight Prohaska",
    eventCount: 9,
    firstSeenAt: moment().subtract(11, "days").toISOString(),
    lastSeenAt: moment().subtract(7, "hours").toISOString(),
    daysSinceLastEvent: 7,
    contacts: [{ userId: "1", email: "email@company.com" }],
  },
  {
    id: "33420",
    name: "Howard Skiles",
    eventCount: 17,
    firstSeenAt: moment().subtract(1, "days").toISOString(),
    lastSeenAt: moment().subtract(9, "hours").toISOString(),
    daysSinceLastEvent: 7,
    contacts: [{ userId: "1", email: "email@great.com" }],
  },
  {
    id: "33421",
    name: "Fransisca Hayes",
    eventCount: 14,
    firstSeenAt: moment().subtract(22, "days").toISOString(),
    lastSeenAt: moment().subtract(2, "hours").toISOString(),
    daysSinceLastEvent: 7,
    contacts: [{ userId: "1", email: "email@facebook.com" }],
  },
  {
    id: "837791",
    name: "June Inc",
    eventCount: 12,
    firstSeenAt: moment().subtract(1, "days").toISOString(),
    lastSeenAt: moment().subtract(1, "hours").toISOString(),
    daysSinceLastEvent: 7,
    contacts: [{ userId: "1", email: "email@june.so" }],
  },
  {
    id: "837938",
    name: "Data Corp",
    eventCount: 13,
    firstSeenAt: moment().subtract(2, "days").toISOString(),
    lastSeenAt: moment().subtract(3, "hours").toISOString(),
    daysSinceLastEvent: 7,
    contacts: [{ userId: "1", email: "email@othercompany.com" }],
  },
];
