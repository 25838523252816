export const mockNewUsersChangesData = [
  {
    count: 12,
    insightChange: 83.33,
    date: "2021-01-26T00:00:00.000Z",
    humanizedDate: "Tue, 26 Jan",
  },
  {
    count: 93,
    insightChange: 33.33,
    date: "2021-01-26T00:00:00.000Z",
    humanizedDate: "26 Jan",
  },
  {
    count: 435,
    insightChange: 30.2,
    date: "2021-01-26T00:00:00.000Z",
    humanizedDate: "Jan",
  },
];

export const dnuMockData = {
  newUsers: [
    { humanizedDate: "Tue, 26 Jan", newUsers: 6 },
    { humanizedDate: "Wed, 27 Jan", newUsers: 16 },
    { humanizedDate: "Thu, 28 Jan", newUsers: 14 },
    { humanizedDate: "Fri, 29 Jan", newUsers: 10 },
    { humanizedDate: "Sat, 30 Jan", newUsers: 6 },
    { humanizedDate: "Sun, 31 Jan", newUsers: 2 },
    { humanizedDate: "Mon, 01 Feb", newUsers: 12 },
  ],
};

export const wnuMockData = {
  newUsers: [
    {
      humanizedDate: "Dec 14 - Dec 20",
      newUsers: 0,
    },
    {
      humanizedDate: "Dec 21 - Dec 27",
      newUsers: 4,
    },
    {
      humanizedDate: "Dec 28 - Jan 03",
      newUsers: 7,
    },
    {
      humanizedDate: "Jan 04 - Jan 10",
      newUsers: 11,
    },
    {
      humanizedDate: "Jan 11 - Jan 17",
      newUsers: 8,
    },
    {
      humanizedDate: "Jan 18 - Jan 24",
      newUsers: 22,
    },
    {
      humanizedDate: "Jan 25 - Jan 31",
      newUsers: 33,
    },
  ],
};

export const mnuMockData = {
  newUsers: [
    {
      humanizedDate: "November",
      newUsers: 0,
    },
    {
      humanizedDate: "December",
      newUsers: 87,
    },
    {
      humanizedDate: "January",
      newUsers: 234,
    },
  ],
};
