import { useAppObjects } from "core/hooks/useAppObjects";
import { formatRetentionDate } from "core/components/CohortTable/helpers";
import { Flex, SkeletonText, Text, Tooltip } from "@chakra-ui/react";

interface IRetentionDrilldownHeaderProps {
  intervalType: string;
  cohortStartDate: string;
  cohortEndDate: string;
  cohortIntervalStartDate: string;
  cohortIntervalEndDate: string;
  intervalNumber: number;
  count: number;
  retained: boolean;
  isLoading: boolean;
}

export const RetentionDrilldownHeader: React.FC<
  IRetentionDrilldownHeaderProps
> = ({
  count,
  intervalType,
  cohortStartDate,
  cohortEndDate,
  cohortIntervalStartDate,
  cohortIntervalEndDate,
  intervalNumber,
  retained,
  isLoading,
}) => {
  const { activeAppObject } = useAppObjects();
  const cohortDate = formatRetentionDate({
    intervalType,
    startDate: cohortStartDate,
    endDate: cohortEndDate,
  });
  const cohortIntervalDate = formatRetentionDate({
    intervalType,
    startDate: cohortIntervalStartDate,
    endDate: cohortIntervalEndDate,
  });

  if (isLoading) {
    return <SkeletonText margin={"0 auto"} width="50%" />;
  }

  return (
    <Flex
      direction="column"
      w="full"
      justifyContent="center"
      alignItems="center"
    >
      <Text fontSize="lg" fontWeight="bold">
        {count} {activeAppObject?.pluralName.toLowerCase()}
        {intervalNumber > 0 && (
          <Tooltip label={cohortIntervalDate}>
            <Text as="span" fontSize="lg" fontWeight="bold">
              {retained ? " retained" : " dropped"} after {intervalNumber}{" "}
              {intervalType}
              {intervalNumber > 1 ? "s" : ""}
            </Text>
          </Tooltip>
        )}
      </Text>
      <Text fontWeight="medium" align="center" color="gray.600" fontSize="sm">
        Joined {cohortDate}
      </Text>
    </Flex>
  );
};
