import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { selector as appSelector, updateApp } from "core/models/app";
import { useToast } from "core/hooks/useToast";
import { Box, Flex, Switch, Text } from "@chakra-ui/react";

export const EnforceGoogleOAuth: React.FC = () => {
  const {
    data: { id: appId, enforceGoogleOauth },
    errors,
  } = useSelector(appSelector);
  const toast = useToast();
  const dispatch = useDispatch();

  const onToggleEnforceGoogleOauth = () => {
    dispatch(updateApp({ appId, enforceGoogleOauth: !enforceGoogleOauth }));
  };

  useEffect(() => {
    if (errors.fetching) {
      toast({
        title: "Can't enforce Google OAuth for the workspace",
        description:
          "Some of your users don't use Google for authentication. Please remove them first.",
        status: "error",
        duration: 5000,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enforceGoogleOauth, errors?.fetching]);

  return (
    <Box borderWidth="1px" borderColor="gray.100" borderRadius="md" p={4}>
      <Flex>
        <Box>
          <Flex align="center" gridGap={4} mb={2}>
            <Flex>
              <Switch
                isChecked={enforceGoogleOauth}
                size="md"
                colorScheme="purple"
                onChange={onToggleEnforceGoogleOauth}
              />
            </Flex>
            <Flex>
              <Text fontSize="md" fontWeight="semibold">
                Enforce Google OAuth Authentication
              </Text>
            </Flex>
          </Flex>

          <Box ml="50px" fontSize="sm" color="gray.600">
            Enabling this will require all users to sign in with Google before
            they can access your app. This is useful if you want to have
            two-factor authentication on your app.
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};
