import { ChangeEvent, useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useAI } from "core/hooks/useAI";
import Icon from "core/design-system/components/Icon";
import {
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";

export const PromptInput: React.FC = () => {
  const [prompt, setPrompt] = useState("");
  const { isExecutingPrompt, isExecutingQuery, ask } = useAI();

  const initialFocusRef = useRef(null);
  const controls = useAnimation();

  const onCreateQuery = ({ prompt }: { prompt: string }) => {
    controls.start({
      transition: { duration: 1, ease: "easeOut", repeat: Infinity },
      boxShadow: [
        "0px 1px 2px var(--june-colors-purple-500)",
        "0px 1px 5px var(--june-colors-purple-500)",
        "0px 1px 8px var(--june-colors-purple-500)",
        "0px 1px 10px var(--june-colors-purple-500)",
        "0px 1px 8px var(--june-colors-purple-500)",
        "0px 1px 5px var(--june-colors-purple-500)",
        "0px 1px 2px var(--june-colors-purple-500)",
      ],
    });
    ask(prompt);
    setPrompt("");
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && !(isExecutingPrompt || isExecutingQuery)) {
      onCreateQuery({ prompt });
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPrompt(event.currentTarget.value);
  };

  const handleCreate = () => {
    if (prompt) onCreateQuery({ prompt });
  };

  return (
    <motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }}>
      <motion.div
        animate={controls}
        style={{
          boxShadow: "0px 1px 12px #6868F7",
          marginBottom: "20px",
          borderRadius: "15px",
        }}
      >
        <InputGroup borderRadius="2xl">
          <InputLeftElement
            ml={1}
            h="full"
            pointerEvents="none"
            children={<Search2Icon color="gray.300" />}
          />
          <Input
            ref={initialFocusRef}
            border="1px solid"
            borderColor="purple.300"
            h={14}
            pr="120px"
            bg="white"
            size="md"
            placeholder="How many users signed up last week?"
            value={prompt}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            borderRadius="2xl"
            autoFocus
          />
          <InputRightElement mr={2} w="100px" h="full">
            <Button
              colorScheme="purple"
              w="100px"
              mr={2}
              onClick={handleCreate}
              fontWeight="semibold"
              isDisabled={isExecutingPrompt || isExecutingQuery}
              leftIcon={
                !(isExecutingPrompt || isExecutingQuery) ? (
                  <Icon
                    iconType="icon"
                    w="15px"
                    h="15px"
                    name="aiExplorer"
                    color="white"
                  />
                ) : (
                  <></>
                )
              }
            >
              {isExecutingPrompt || isExecutingQuery ? (
                <Flex
                  backgroundColor="white"
                  color="white"
                  ml={-2}
                  bgColor="white"
                  className="dot-falling-white"
                />
              ) : (
                "Ask AI"
              )}
            </Button>
          </InputRightElement>
        </InputGroup>
      </motion.div>
    </motion.div>
  );
};
