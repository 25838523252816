import { cx } from "helpers/cx";

interface DrilldownHeaderProps {
  className?: string;
  children: React.ReactNode;
}

export const DrilldownHeader: React.FC<DrilldownHeaderProps> = ({
  children,
  className,
}) => {
  return (
    <div
      className={cx(
        "flex w-full flex-col items-center justify-center gap-2 pt-2",
        className,
      )}
    >
      {children}
    </div>
  );
};
