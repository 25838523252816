import React from "react";
import useSmartTrait from "core/hooks/useSmartTrait";
import { ComputedTraitEditor } from "./ComputedTraitEditor";

export const EditComputedTrait: React.FC = () => {
  const { trait, isLoading, onCompute, onSave, onSetLive, onDelete } =
    useSmartTrait();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!trait) {
    return <div>Not found</div>;
  }

  return (
    <ComputedTraitEditor
      trait={trait}
      onCompute={onCompute}
      onSave={onSave}
      onSetLive={onSetLive}
      onDelete={onDelete}
    />
  );
};
