import axios from "core/initializers/axios";
import { useToast } from "core/hooks/useToast";
import GoogleIcon from "core/design-system/components/Icon/Logos/GoogleIcon";
import Icon from "core/design-system/components/Icon";
import { Button, Flex, Heading, Stack, Text } from "@chakra-ui/react";

export const VerifyEmail = ({ email }: { email: string }) => {
  const toast = useToast();

  const resendVerification = () => {
    axios
      .post("/auth/confirmation/resend", {
        email,
      })
      .then((response) => {
        if (response.data.status === 200) {
          toast({
            title: "Email verification sent",
            description: "Check your inbox for an email verification link",
            duration: 6000,
          });
        }
      })
      .catch((error) => {
        toast({
          title: "Error",
          description: error.response.data.error,
          status: "error",
          duration: 6000,
        });
      });
  };

  return (
    <div className="flex h-full min-h-screen w-full justify-center px-4">
      <Stack w={["100%", "448px"]} mt={[8, 16]}>
        <Flex mb={8} justify="center">
          <Icon w={32} h={16} iconType="logo" name="companyJuneFullLogo" />
        </Flex>
        <Flex direction={"column"}>
          <Heading>Verify your email address</Heading>
          <Text mt={4}>
            Check your {email} inbox for an email verification link
          </Text>
          <Button
            size="lg"
            as="a"
            href="https://mail.google.com"
            mt={6}
            target="_blank"
            rel="noopener noreferrer"
            leftIcon={<GoogleIcon />}
          >
            Open Gmail
          </Button>

          <Button size="lg" as="a" onClick={resendVerification} mt={2}>
            Resend verification email
          </Button>
          <Button size="lg" className="openMessenger" mt={2}>
            Get help
          </Button>
        </Flex>
      </Stack>
    </div>
  );
};
