import { Flex } from "@chakra-ui/react";
import React from "react";
import { AnimatePresence, motion } from "framer-motion";

export const OnboardingContainer: React.FC = ({ children }) => {
  return (
    <AnimatePresence mode="wait">
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ y: -1000 }}
        transition={{ duration: 0.5 }}
      >
        <Flex w="full" h="full" gap={[10, 5, 5, 10]}>
          {children}
        </Flex>
      </motion.main>
    </AnimatePresence>
  );
};
