import { DEMO_APP_ID } from "core/constants/appIds";
import moment from "moment";
import { useOnboardingChecklist } from "./useOnboardingChecklist";
import { useParams } from "react-router-dom";

export function useGettingStarted() {
  const { appId } = useParams();
  const {
    onboardingChecklist,
    isChecklistComplete,
    onboardingProgressPercentage,
  } = useOnboardingChecklist();
  const onboardingChecklistUpdatedMoreThanSevenDaysAgo =
    moment().diff(moment(onboardingChecklist?.updatedAt), "days") > 7;
  const showGettingStarted =
    !(isChecklistComplete && onboardingChecklistUpdatedMoreThanSevenDaysAgo) &&
    Number(appId) !== DEMO_APP_ID;

  return { progress: onboardingProgressPercentage, showGettingStarted };
}
