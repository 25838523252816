import { Navigate } from "react-router-dom";
import { ITemplateConfig } from "core/types/TemplateConfig";
import { IReport } from "core/types/Report";
import { useUserAuth } from "core/hooks/useUserAuth";
import CompanyJuneLogoIcon from "core/design-system/components/Icon/Logos/CompanyJuneLogoIcon";
import { Button, Flex, Grid, Stack, Text } from "@chakra-ui/react";
import { isUserReportLevel } from "core/modules/reports/utils";
import { inIframe } from "helpers/iframe";

interface IPublicReportProps {
  appId: number;
  report: IReport;
  sharingSecretToken: string;
  config: ITemplateConfig;
}

function SignUpBanner() {
  return (
    <Flex align="flex-start" justify="center" data-id-public-banner>
      <Flex
        mb={8}
        px={4}
        py={2}
        width="80vw"
        maxWidth="870px"
        height="8vh"
        maxHeight="80px"
        align="center"
        justify="space-between"
        bg="purple.900"
        borderWidth="1px"
        borderRadius="lg"
        borderColor="gray.200"
      >
        <Flex align="center">
          <CompanyJuneLogoIcon />
          <Text ml={4} color="white" fontSize="sm" fontWeight="medium">
            Welcome to June!
          </Text>
          <Text ml={2} color="white" fontSize="sm">
            Create an account to edit and collaborate on this report.
          </Text>
        </Flex>
        <Flex align="center">
          <Button as="a" href="https://analytics.june.so/login" fontSize="md">
            Log in
          </Button>
          <Button
            ml={4}
            as="a"
            href="https://analytics.june.so/start"
            fontSize="md"
            colorScheme="purple"
          >
            Sign up
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}

export const PublicReport: React.FC<IPublicReportProps> = ({
  appId,
  report,
  config,
  sharingSecretToken,
}) => {
  const { currentUser } = useUserAuth();
  const isUserLevel = isUserReportLevel();
  // Temporary fix for handling old and new report configs
  let sections = config.sections as any;
  if (sections.company && sections.company) {
    sections = !isUserLevel ? sections.company : sections.user;
  }

  if (currentUser && !inIframe())
    return <Navigate to={`/a/${appId}/report/${report.id}`} />;

  return (
    <Flex direction="column" minHeight="100vh" bg="whiteAlpha.900" pt={12}>
      <SignUpBanner />
      <Flex align="flex-start" justify="center">
        <Flex maxWidth="870px" width="80vw">
          <Text fontSize="3xl" fontWeight="bold">
            {report.name}
          </Text>
        </Flex>
      </Flex>
      <Flex
        ml={0}
        justify="center"
        align="center"
        direction="column"
        transition="margin-left 0.3s"
      />
      <Flex align="flex-start" justify="center">
        <>
          <Flex
            transition="margin-left 0.3s"
            ml={0}
            justify="center"
            direction="column"
            align="center"
            my={8}
          >
            <Stack maxWidth="870px" width="80vw">
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={4}
                maxW="870px"
                minW="870px"
              >
                {sections.map((Section: React.FC<any>, index: number) => (
                  <Section
                    appId={appId}
                    config={config}
                    reportId={report.id}
                    report={report}
                    sharingMode={true}
                    sharingSecretToken={sharingSecretToken}
                    showYAxis
                  />
                ))}
              </Grid>
            </Stack>
          </Flex>
        </>
      </Flex>
    </Flex>
  );
};
