import { createSlice } from "@reduxjs/toolkit";

export interface NavigationState {
  isOpen: boolean;
  newReportModalIsOpen: boolean;
}

const initialState: NavigationState = {
  isOpen: true,
  newReportModalIsOpen: false,
};

export const slice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    toggle: (state) => {
      state.isOpen = !state.isOpen;
    },
    setNewReportModalIsOpen: (state, action) => {
      state.newReportModalIsOpen = action.payload;
    },
  },
});

export const { toggle, setNewReportModalIsOpen } = slice.actions;
export const selectNavigation = (state: { navigation: NavigationState }) =>
  state.navigation;

export default slice.reducer;
