import { useSelector } from "react-redux";
import { Spinner } from "@chakra-ui/react";
import ReportList from "../modules/Reports";
import { selector as appSelector } from "../core/models/app";

function Reports() {
  const { data: currentApp, isLoading } = useSelector(appSelector);

  if (!currentApp) return null;
  if (isLoading) return <Spinner />;

  return <ReportList appId={currentApp.id} />;
}

export default Reports;
