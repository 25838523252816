import { DIVIDER, FilterType } from "core/constants/filters";

export interface IFilterOption {
  trait: string;

  filterType: FilterType;
}

// Eventually we might want to use a more sophisticated search library

export const searchFilters = (
  availableFilters: IFilterOption[],
  searchTrait: string,
) => {
  if (!searchTrait) return availableFilters;

  const searchTraitLower = searchTrait.toLowerCase();
  const filtered = availableFilters?.filter(
    (t: IFilterOption) =>
      t.filterType === DIVIDER ||
      t.trait.toLowerCase().includes(searchTraitLower),
  );

  return filtered?.reduce(
    (acc: IFilterOption[], current: IFilterOption, index: number) => {
      const isLastItem = index === filtered.length - 1;
      const isNextItemADivider = filtered[index + 1]?.filterType === DIVIDER;
      if (
        current.filterType === DIVIDER &&
        (isLastItem || isNextItemADivider)
      ) {
        return acc;
      }
      acc.push(current);
      return acc;
    },
    [],
  );
};
