export const adoptionChangesMockData = {
  notAdopted: {
    count: 3560,
    percentage: 73,
    change: -1,
  },
  adopted: {
    count: 1286,
    percentage: 27,
    change: 4,
  },
  eligibleAudienceCount: 4846,
};

export const weeklyAdoptionMockData = {
  adoptionInTimeRange: [
    {
      humanized_date: "Feb 07 - Feb 13",
      date: "2022-02-07",
      adoptionCount: 16,
      adoptionPercentage: 89,
    },
    {
      humanized_date: "Feb 14 - Feb 20",
      date: "2022-02-14",
      adoptionCount: 19,
      adoptionPercentage: 43,
    },
    {
      humanized_date: "Feb 21 - Feb 27",
      date: "2022-02-21",
      adoptionCount: 33,
      adoptionPercentage: 46,
    },
    {
      humanized_date: "Feb 28 - Mar 06",
      date: "2022-02-28",
      adoptionCount: 28,
      adoptionPercentage: 29,
    },
    {
      humanized_date: "Mar 07 - Mar 13",
      date: "2022-03-07",
      adoptionCount: 24,
      adoptionPercentage: 21,
    },
    {
      humanized_date: "Mar 14 - Mar 20",
      date: "2022-03-14",
      adoptionCount: 20,
      adoptionPercentage: 20,
    },
    {
      humanized_date: "Mar 21 - Mar 27",
      date: "2022-03-21",
      adoptionCount: 21,
      adoptionPercentage: 19,
    },
    {
      humanized_date: "Mar 28 - Apr 03",
      date: "2022-03-28",
      adoptionCount: 27,
      adoptionPercentage: 24,
    },
    {
      humanized_date: "Apr 04 - Apr 10",
      date: "2022-04-04",
      adoptionCount: 28,
      adoptionPercentage: 25,
    },
    {
      humanized_date: "Apr 11 - Apr 17",
      date: "2022-04-11",
      adoptionCount: 23,
      adoptionPercentage: 21,
    },
    {
      humanized_date: "Apr 18 - Apr 24",
      date: "2022-04-18",
      adoptionCount: 27,
      adoptionPercentage: 21,
    },
    {
      humanized_date: "Apr 25 - May 01",
      date: "2022-04-25",
      adoptionCount: 25,
      adoptionPercentage: 24,
    },
    {
      humanized_date: "May 02 - May 08",
      date: "2022-05-02",
      adoptionCount: 14,
      adoptionPercentage: 23,
    },
  ],
};

export const weeklyDiscoveryMockData = {
  discoveryInTimeRange: [
    {
      humanized_date: "Feb 07 - Feb 13",
      date: "2022-02-07",
      adoptionCount: 16,
      adoptionPercentage: 89,
    },
    {
      humanized_date: "Feb 14 - Feb 20",
      date: "2022-02-14",
      adoptionCount: 19,
      adoptionPercentage: 43,
    },
    {
      humanized_date: "Feb 21 - Feb 27",
      date: "2022-02-21",
      adoptionCount: 33,
      adoptionPercentage: 46,
    },
    {
      humanized_date: "Feb 28 - Mar 06",
      date: "2022-02-28",
      adoptionCount: 28,
      adoptionPercentage: 29,
    },
    {
      humanized_date: "Mar 07 - Mar 13",
      date: "2022-03-07",
      adoptionCount: 24,
      adoptionPercentage: 21,
    },
    {
      humanized_date: "Mar 14 - Mar 20",
      date: "2022-03-14",
      adoptionCount: 20,
      adoptionPercentage: 20,
    },
    {
      humanized_date: "Mar 21 - Mar 27",
      date: "2022-03-21",
      adoptionCount: 21,
      adoptionPercentage: 19,
    },
    {
      humanized_date: "Mar 28 - Apr 03",
      date: "2022-03-28",
      adoptionCount: 27,
      adoptionPercentage: 24,
    },
    {
      humanized_date: "Apr 04 - Apr 10",
      date: "2022-04-04",
      adoptionCount: 28,
      adoptionPercentage: 25,
    },
    {
      humanized_date: "Apr 11 - Apr 17",
      date: "2022-04-11",
      adoptionCount: 23,
      adoptionPercentage: 21,
    },
    {
      humanized_date: "Apr 18 - Apr 24",
      date: "2022-04-18",
      adoptionCount: 27,
      adoptionPercentage: 21,
    },
    {
      humanized_date: "Apr 25 - May 01",
      date: "2022-04-25",
      adoptionCount: 25,
      adoptionPercentage: 24,
    },
    {
      humanized_date: "May 02 - May 08",
      date: "2022-05-02",
      adoptionCount: 14,
      adoptionPercentage: 23,
    },
  ],
};

export const dailyUsageMockData = {
  dailyUsage: { humanizedDate: "Mon, 01 Feb", usage: 12 },
  usageInTimeRange: [
    { humanizedDate: "Tue, 26 Jan", usageCount: 6, uniqueUserCount: 10 },
    { humanizedDate: "Wed, 27 Jan", usageCount: 16, uniqueUserCount: 20 },
    { humanizedDate: "Thu, 28 Jan", usageCount: 14, uniqueUserCount: 30 },
    { humanizedDate: "Fri, 29 Jan", usageCount: 10, uniqueUserCount: 40 },
    { humanizedDate: "Sat, 30 Jan", usageCount: 6, uniqueUserCount: 10 },
    { humanizedDate: "Sun, 31 Jan", usageCount: 2, uniqueUserCount: 20 },
    { humanizedDate: "Mon, 01 Feb", usageCount: 12, uniqueUserCount: 30 },
  ],
};

export const weeklyUsageMockData = {
  weeklyUsage: {
    chartDate: "Mar 01",
    humanizedDate: "Mar 01 - Mar 07",
    usage: 50,
  },
  usageInTimeRange: [
    {
      chartDate: "Feb 22",
      humanizedDate: "Feb 22 - Feb 28",
      usageCount: 106,
      uniqueUserCount: 100,
    },
    {
      chartDate: "Feb 15",
      humanizedDate: "Feb 15 - Feb 21",
      usageCount: 92,
      uniqueUserCount: 70,
    },
    {
      chartDate: "Feb 08",
      humanizedDate: "Feb 08 - Feb 14",
      usageCount: 98,
      uniqueUserCount: 80,
    },
    {
      chartDate: "Feb 01",
      humanizedDate: "Feb 01 - Feb 07",
      usageCount: 102,
      uniqueUserCount: 90,
    },
    {
      chartDate: "Jan 25",
      humanizedDate: "Jan 25 - Jan 31",
      usageCount: 106,
      uniqueUserCount: 101,
    },
    {
      chartDate: "Jan 18",
      humanizedDate: "Jan 18 - Jan 24",
      usageCount: 100,
      uniqueUserCount: 90,
    },
    {
      chartDate: "Jan 1",
      humanizedDate: "Jan 11 - Jan 17",
      usageCount: 108,
      uniqueUserCount: 100,
    },
  ],
};

export const monthlyUsageMockData = {
  monthlyUsage: { humanizedDate: "March", usage: 100 },
  usageInTimeRange: [
    { humanizedDate: "December", usageCount: 334, uniqueUserCount: 10 },
    { humanizedDate: "January", usageCount: 460, uniqueUserCount: 30 },
    { humanizedDate: "February", usageCount: 398, uniqueUserCount: 40 },
  ],
};

export const featureUsageHeaderMockData = [
  {
    count: 24,
    change: -4,
  },
  {
    count: 80,
    change: 20,
  },
  {
    count: 153,
    change: 77.25,
  },
];
