import { ITrait } from "core/types/Trait";

export function readTraitValue(
  traits: ITrait | ITrait[],
  trait: string,
): string | undefined {
  if (Array.isArray(traits))
    return traits.filter((t) => t.name === trait)?.[0]?.value;
  return traits[trait];
}
