import React from "react";
import {
  ITemplateConfigSetup,
  ITemplateConfigSetupSection,
} from "core/types/TemplateConfig";
import { AudienceAccordion } from "core/modules/reports/setup/Setup/AudienceAccordion";
import { AUDIENCE_TYPE, SETTINGS_TYPE } from "core/constants/report-setup";
import { AccordionItem, Box, ComponentDefaultProps } from "@chakra-ui/react";
import SetupAccordionHeader from "./SetupAccordionHeader";
import { SetupAccordionBody } from "./SetupAccordionBody";

interface ISetupSectionPanelProps extends ComponentDefaultProps {
  section: ITemplateConfigSetupSection;
  setup: ITemplateConfigSetup;
  setOpenPanelIndex: React.Dispatch<any>;
  requiredSections: ITemplateConfigSetup["requiredSections"];
  index: number;
}

export const SetupSectionPanel: React.FC<ISetupSectionPanelProps> = ({
  section,
  setup,
  setOpenPanelIndex,
  index,
  requiredSections,
  ...props
}) => {
  if (section.configKey === SETTINGS_TYPE) {
    return <></>;
  }

  if (section.type === AUDIENCE_TYPE) {
    return <AudienceAccordion section={section} />;
  }

  return (
    <AccordionItem
      data-testid="setup-sections-accordion-item"
      w="full"
      border="1px solid gray.50"
      borderRadius={5}
      shadow="sm"
      as={"div"}
      my={5}
      bg="white"
      {...props}
    >
      {({ isExpanded }) => (
        <Box
          border="0.5px solid"
          borderColor={isExpanded ? "gray.600" : "gray.50"}
          boxShadow="sm"
          p={3}
          borderRadius={5}
        >
          <SetupAccordionHeader
            section={section}
            isExpanded={isExpanded}
            setOpenPanelIndex={setOpenPanelIndex}
            index={index}
            setup={setup}
          />
          <SetupAccordionBody
            section={section}
            isExpanded={isExpanded}
            setOpenPanelIndex={setOpenPanelIndex}
            index={index}
            requiredSections={requiredSections}
            setup={setup}
          />
        </Box>
      )}
    </AccordionItem>
  );
};
