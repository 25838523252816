import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";

export const useHubspotProfile = ({
  isLoading,
  fetchError,
  isCreating,
  hasDataInHubSpot,
  lastSyncedAt,
  createError,
  updateError,
}: {
  isLoading: boolean;
  isCreating: boolean;
  hasDataInHubSpot: boolean;
  lastSyncedAt?: string;
  fetchError: FetchBaseQueryError | SerializedError | undefined;
  createError: FetchBaseQueryError | SerializedError | undefined;
  updateError: FetchBaseQueryError | SerializedError | undefined;
}) => {
  function shouldCreateEntity() {
    return !isLoading && !fetchError && !isCreating && !hasDataInHubSpot;
  }

  function shouldUpdateEntity() {
    return !isLoading && !fetchError && hasDataInHubSpot;
  }

  function shouldShowError() {
    return !isLoading && (fetchError || createError || updateError);
  }

  function shouldShowLastSyncedAt() {
    return !isLoading && hasDataInHubSpot && lastSyncedAt;
  }

  return {
    shouldCreateEntity,
    shouldUpdateEntity,
    shouldShowError,
    shouldShowLastSyncedAt,
  };
};
