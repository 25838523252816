import "./markdown.styles.css";
import remarkParse from "remark-parse";
import remarkBreaks from "remark-breaks";
import ReactMarkdown from "react-markdown";
import React from "react";
import { get, isNil } from "lodash";
import { IEvent } from "core/types/Event";
import { EventProperties, useGetPropertiesQuery } from "core/models/properties";
import { useGetEventQuery } from "core/models/events";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { Skeleton } from "@chakra-ui/react";

const getPropertyValue = (properties: EventProperties, variable: string) => {
  const path = variable.replace("event.properties.", "").split(".");
  const propertyName = path[0];
  const property = properties.find(({ property }) => property === propertyName);

  if (!property) {
    return "*invalid property*";
  }

  if (typeof property?.value !== "object") {
    return property.value;
  }

  return get(property.value, path.slice(1), "*invalid or empty property*");
};

const variableValue = ({
  event,
  properties,
  placeholder,
}: {
  event: IEvent;
  properties: EventProperties;
  placeholder: string;
}) => {
  // Remove braces
  const variable = placeholder.replace(/}|{/g, "");
  let value = "";
  if (variable.includes("contact.traits")) {
    return "**test_contact_trait**";
  }
  if (variable.includes("event.properties.")) {
    return getPropertyValue(properties, variable);
  }

  switch (variable) {
    case "event.name":
      value = event.name || "";
      break;
    case "contact.email":
      value = "**user@example.com**";
      break;
    case "contact.user_id":
      value = "**23456**";
      break;
    case "company.name":
      value = "**Google**";
      break;
    default:
      value = `*Error: invalid property in variable **${variable
        .split(`{{`)
        .join("")
        .split("}}")
        .join("")}** *`;
      break;
  }
  return value;
};

export const RenderedMessagePreview: React.FC<{
  messageBody: string;
  eventId: number;
}> = ({ messageBody, eventId }) => {
  const { id: appId } = useCurrentApp();
  const regex = /\{\{(.+?)\}\}/g;

  const { data: event, isFetching } = useGetEventQuery({
    appId,
    eventId,
  });

  const { data: properties, isLoading: propertiesLoading } =
    useGetPropertiesQuery({
      appId,
      eventId,
      withExampleValue: true,
    });

  const getRenderedMessage = () => {
    if (!event || !properties) {
      return "";
    }

    let messageString = messageBody;
    let matchObject = null;
    matchObject = messageString.match(regex);

    // eslint-disable-next-line no-constant-condition
    while (!isNil(matchObject)) {
      messageString = messageString.replace(
        matchObject[0],
        variableValue({ placeholder: matchObject[0], event, properties }),
      );
      matchObject = messageString.match(regex);
    }

    return messageString;
  };

  const renderedMessage = getRenderedMessage();

  return (
    <>
      {isFetching || propertiesLoading ? (
        <Skeleton h="2rem" w="95%" />
      ) : (
        <ReactMarkdown
          className="markdown"
          linkTarget="_blank"
          children={renderedMessage}
          remarkPlugins={[remarkParse, remarkBreaks]}
        />
      )}
    </>
  );
};

export default RenderedMessagePreview;
