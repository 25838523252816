import React from "react";
import { IAppUser } from "core/types/User";
import Icon from "core/design-system/components/Icon";

import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import RemoveTeammateModal from "./RemoveTeammateModal";

interface IUserActionsMenuProps {
  member: IAppUser;
  mutateUserList: () => void;
}

const UserActionsMenu: React.FC<IUserActionsMenuProps> = ({
  member,
  mutateUserList,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Menu>
        {({ isOpen: isMenuOpen }) => (
          <>
            <MenuButton variant="ghost" as={Button} isActive={isMenuOpen}>
              <Icon iconType="icon" name="more" />
            </MenuButton>
            <MenuList minWidth="240px">
              <MenuItem onClick={onOpen}>
                <Text fontSize="sm">Suspend account</Text>
              </MenuItem>
            </MenuList>
          </>
        )}
      </Menu>
      <RemoveTeammateModal
        member={member}
        isOpen={isOpen}
        onClose={onClose}
        mutateUserList={mutateUserList}
      />
    </>
  );
};

export default UserActionsMenu;
