import { useParams } from "react-router-dom";
import { useState } from "react";
import moment from "moment";
import { usePaywall } from "core/hooks/usePaywall";
import { useEmailEnrichment } from "core/hooks/useEmailEnrichment";
import { baseUrl } from "core/constants/paywall";
import Trait from "core/components/Traits/Trait";
import {
  IPaywallModalChildren,
  PaywallModal,
} from "core/components/Paywall/Modal";
import { Badge, Box, Button, Flex, Stack, Text } from "@chakra-ui/react";
import { LockIcon } from "@chakra-ui/icons";

interface IEnrichedEmailProps {
  email: string;
}

export const EnrichedEmail: React.FC<IEnrichedEmailProps> = ({ email }) => {
  const { appId } = useParams<{ appId: string }>() as { appId: string };
  const { shouldBePaywalled } = usePaywall();
  const [showPaywallModal, setShowPaywallModal] = useState(false);
  const {
    enrichedEmail,
    isLoading,
    error,
    createdEnrichedEmail,
    isLoadingCreatingEnrichment,
  } = useEmailEnrichment({
    appId,
    email,
  });
  const redirectUrl = `${baseUrl}/a/${appId}/contacts`;

  if (isLoading) return null;

  if (error || !enrichedEmail || !enrichedEmail.enrichmentState) {
    return (
      <Box
        p={4}
        border="1px"
        borderColor="gray.50"
        bg="white"
        boxShadow="sm"
        borderRadius="lg"
      >
        <Flex mb={3} justifyContent="space-between" align="center">
          <Flex gap={2}>
            <Text fontWeight="medium">Enrichment</Text>
            <Badge colorScheme="purple">AI</Badge>
          </Flex>
        </Flex>
        <Stack gap={2}>
          <Text fontSize={"sm"} color="gray.600">
            We haven't enriched{" "}
            <Text as="span" fontWeight="bold">
              {email}
            </Text>{" "}
            yet.{" "}
            <Text
              as="a"
              href="https://help.june.so/en/articles/7945659-manual-enrichment"
              target="_blank"
              color="primary"
            >
              Learn more
            </Text>
          </Text>
          {shouldBePaywalled ? (
            <Button
              onClick={() => {
                setShowPaywallModal(true);
              }}
              leftIcon={<LockIcon />}
            >
              Enrich
            </Button>
          ) : (
            <Button
              isLoading={isLoadingCreatingEnrichment}
              onClick={() => {
                createdEnrichedEmail({ email, appId });
              }}
            >
              Enrich
            </Button>
          )}
        </Stack>
        {showPaywallModal && (
          <PaywallModal redirectUrl={redirectUrl}>
            {(paywallModal: IPaywallModalChildren) => (
              <paywallModal.Modal
                isOpen
                onClose={() => setShowPaywallModal(false)}
              />
            )}
          </PaywallModal>
        )}
      </Box>
    );
  }

  return (
    <Box
      p={4}
      mt={4}
      border="1px"
      borderColor="gray.50"
      boxShadow="sm"
      borderRadius="lg"
    >
      <Flex mb={3} justifyContent="space-between" align="center">
        <Flex gap={2}>
          <Text fontWeight="medium">Enrichment</Text>
          <Badge colorScheme="purple">AI</Badge>
        </Flex>
      </Flex>
      {enrichedEmail.email && (
        <Box pb={2}>
          <Trait name="Email" value={enrichedEmail.email} isTransient={false} />
        </Box>
      )}
      {enrichedEmail.title && (
        <Box pb={2}>
          <Trait name="Title" value={enrichedEmail.title} isTransient={false} />
        </Box>
      )}
      {enrichedEmail.linkedinUrl && (
        <Box pb={2}>
          <Trait
            name="LinkedIn URL"
            value={enrichedEmail.linkedinUrl}
            isTransient={false}
          />
        </Box>
      )}
      <Flex justify="end">
        <Text color="gray.500" fontSize="xs">
          Updated on{" "}
          <Text as="span">
            {moment(enrichedEmail.updatedAt).format("Do MMM, YYYY")}
          </Text>
        </Text>
      </Flex>
    </Box>
  );
};
