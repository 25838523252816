import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import { Flex, Link, SimpleGrid, Stack, Text } from "@chakra-ui/layout";
import { useRadioGroup } from "@chakra-ui/radio";
import { Image } from "@chakra-ui/image";
import { useGetHomeHighlightsQuery } from "core/models/home";
import InsightRadio from "modules/MobileWidgets/InsightRadio";
import {
  buildInsightEndpoint,
  getNextUrl,
  normalizeInsights,
} from "modules/MobileWidgets/utils";
import InsightLoadingState from "modules/MobileWidgets/InsightLoadingState";
import InsightEmptyState from "modules/MobileWidgets/InsightEmptyState";
import QrCode from "modules/MobileWidgets/QrCode";
import { useShareInsightMutation } from "core/models/insights";
import { useToast } from "core/hooks/useToast";
import MobileWidgetsLayout, {
  COLUMN_MIN_HEIGHT,
} from "modules/MobileWidgets/MobileWidgetsLayout";
import InstallSteps from "modules/MobileWidgets/InstallSteps";

function OnboardingSidebar({
  insightEndpoint,
  selectedInsight,
  shareInsightError,
}) {
  const nextUrl = getNextUrl(insightEndpoint);

  return (
    <Flex
      borderTopWidth={[1, 1, 0]}
      borderColor="gray.200"
      overflowX="hidden"
      direction="column"
      width="100%"
      minHeight={COLUMN_MIN_HEIGHT}
      py={[16, 0]}
      px={8}
    >
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
      >
        <Flex
          mb={4}
          w="40px"
          h="40px"
          borderRadius="full"
          alignItems="center"
          justifyContent="center"
          background="linear-gradient(135deg, #C3C3FC 15.79%, #6868F7 85.11%), #6868F7;"
        >
          <Text color="white" fontWeight={600}>
            3
          </Text>
        </Flex>
        <Text fontWeight={400} fontSize="xl">
          Scan this QR code
        </Text>
      </Flex>

      <QrCode
        isLoading={selectedInsight && !shareInsightError && !nextUrl}
        flexGrow={1}
        nextUrl={nextUrl}
        py={[16, 0]}
      />

      <Flex flexGrow={1} direction="column" alignItems="center">
        <Stack direction="column" spacing={1} mt={8} mb={4} textAlign="center">
          <Text fontSize="xs">1. Open your iPhone's camera app.</Text>
          <Text fontSize="xs">2. Point it at the image on the left.</Text>
          <Text fontSize="xs">
            3. Click "Yes" on the prompt to continue the install.
          </Text>
        </Stack>

        <Text fontSize="xs" textColor={!nextUrl ? "transparent" : "primary"}>
          <Link href={nextUrl} target="_blank">
            (alternatively, visit this link on your phone)
          </Link>
        </Text>
      </Flex>
    </Flex>
  );
}

function MobileWidgetsOnboarding() {
  const autoscrollRef = useRef(null);
  const [insightEndpoint, setInsightEndpoint] = useState(null);
  const [selectedInsight, setSelectedInsight] = useState(null);
  const { appId } = useParams();

  // HomeController defines a specific type of insight that
  // allows us to get all available widgets.
  const {
    data: insights,
    isLoading: isLoadingInsights,
    error: getInsightsError,
  } = useGetHomeHighlightsQuery({
    appId,
    insight: "widgetables",
  });
  const [shareInsight, { error: shareInsightError }] =
    useShareInsightMutation();

  const normalizedInsights = normalizeInsights(insights);

  // Initialize the Chakra state for the InsightRadio.
  const { getRadioProps } = useRadioGroup({
    name: "insight_selection",
    onChange: (insightTitle) => {
      setSelectedInsight(
        normalizedInsights.find((i) => i.title === insightTitle),
      );
    },
  });

  const toast = useToast();

  // This effect runs when the user selects an insight.
  // It fetches the sharing token for the insight, then builds an API endpoint
  // that the widget can use to access the insight..
  // TODO: Replace this logic with Alice's proper RTK token query.
  useEffect(() => {
    async function getEndpoint() {
      try {
        const {
          data: { token },
        } = await shareInsight({
          appId,
          reportId: selectedInsight.reportId,
          insightType: selectedInsight.id,
          insightId: selectedInsight.id,
        });
        const finalEndpoint = buildInsightEndpoint(
          appId,
          selectedInsight.reportId,
          selectedInsight.id,
          token,
        );
        setInsightEndpoint(finalEndpoint);
      } catch (e) {
        console.error(e);
        // Errors are handled in the mutation itself, just silence them
      }
    }

    if (selectedInsight) {
      getEndpoint();
    }
  }, [appId, selectedInsight, shareInsight]);

  useEffect(() => {
    const executeScroll = () =>
      autoscrollRef.current.scrollIntoView({ behavior: "smooth" });

    if (insightEndpoint) executeScroll();
  }, [insightEndpoint, autoscrollRef]);

  useEffect(() => {
    // Show a toast on errors.
    if (shareInsightError) {
      toast({
        position: "bottom",
        title: "There was an error",
        description: "Couldn't share the insight.",
        status: "error",
      });
    } else if (getInsightsError) {
      toast({
        position: "bottom",
        title: "There was an error",
        description: "Couldn't fetch your insights.",
        status: "error",
      });
    }
  }, [shareInsightError, getInsightsError, toast]);

  return (
    <MobileWidgetsLayout
      sidebar={
        <>
          <OnboardingSidebar
            insightEndpoint={insightEndpoint}
            selectedInsight={selectedInsight}
            shareInsightError={shareInsightError}
          />
        </>
      }
      sidebarProps={{ display: ["none", "none", "flex"] }}
    >
      <Flex overflowX="hidden" direction="column" minHeight={COLUMN_MIN_HEIGHT}>
        <Flex
          flexGrow={1}
          borderBottomWidth={1}
          borderColor="gray.200"
          alignItems="center"
          justifyContent="center"
          py={16}
          px={8}
        >
          <Flex direction="column" alignItems="center">
            <Flex
              mb={4}
              w="40px"
              h="40px"
              borderRadius="full"
              alignItems="center"
              justifyContent="center"
              background="linear-gradient(135deg, #C3C3FC 15.79%, #6868F7 85.11%), #6868F7;"
            >
              <Text color="white" fontWeight={600}>
                1
              </Text>
            </Flex>
            <Text fontWeight={400} fontSize="xl" mb={2}>
              Select an insight
            </Text>
            <Text color="gray.600" mb={[16, 20]} textAlign="center">
              To get started, select an insight widget you'd like to view on
              your phone.
              <br /> We populated these cards based on your most read reports.
            </Text>
            <Flex direction="column">
              {isLoadingInsights && <InsightLoadingState />}
              {normalizedInsights?.length === 0 && (
                <InsightEmptyState appId={appId} />
              )}
              <SimpleGrid columns={[1, 2, 3]} gap={4}>
                {normalizedInsights?.map((insight) => {
                  const radio = getRadioProps({ value: insight.title });
                  return (
                    <InsightRadio
                      key={insight.title}
                      {...radio}
                      insight={insight}
                    />
                  );
                })}
              </SimpleGrid>
            </Flex>
          </Flex>
        </Flex>

        <div ref={autoscrollRef} />
        <Flex
          borderBottomWidth={[1, 1, 0]}
          borderColor="gray.200"
          flexGrow={1}
          alignItems="center"
          justifyContent="center"
          py={16}
          px={8}
        >
          <Flex direction="column" alignItems="center">
            <Flex
              mb={4}
              w="40px"
              h="40px"
              borderRadius="full"
              alignItems="center"
              justifyContent="center"
              background="linear-gradient(135deg, #C3C3FC 15.79%, #6868F7 85.11%), #6868F7;"
            >
              <Text color="white" fontWeight={600}>
                2
              </Text>
            </Flex>
            <Text fontWeight={400} fontSize="xl" mb={2}>
              Get Scriptable
            </Text>
            <Text color="gray.600" mb={10} textAlign="center">
              Scriptable is an app that allows you to create custom home screen
              widgets.
            </Text>
            <div w="100%">
              <Link
                target="_blank"
                href="https://apps.apple.com/app/scriptable/id1405459188"
              >
                <Image
                  htmlWidth="150px"
                  objectFit="contain"
                  alt="Get it on the App Store"
                  src="/mobile-widgets/appstore.png"
                />
              </Link>
            </div>
          </Flex>
        </Flex>

        <InstallSteps
          startStep={3}
          insightUrl={insightEndpoint}
          display={{ base: "flex", md: "none" }}
        />
      </Flex>
    </MobileWidgetsLayout>
  );
}

export default MobileWidgetsOnboarding;
