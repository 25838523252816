import { Link, useParams } from "react-router-dom";
import React, { useState } from "react";
import { ColorPicker, DEFAULT_COLORS } from "modules/Labels/ColorPicker";
import { ILabel } from "core/types/Label";
import { useLabels } from "core/hooks/useLabels";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { IColorPalette } from "core/design-system/constants/theme/colors";

interface ISelectLabelsProps {
  labels: ILabel[];
  selectedLabels: ILabel[];
  setSelectedLabels: (labels: ILabel[]) => void;
}

export const SelectLabels: React.FC<ISelectLabelsProps> = ({
  labels,
  selectedLabels,
  setSelectedLabels,
}) => {
  const { appId } = useParams<{ appId: string }>() as {
    appId: string;
  };

  const [labelsSearchQuery, setLabelsSearchQuery] = useState("");
  const { createLabel } = useLabels({ appId });
  const [color, setColor] = useState<IColorPalette>(DEFAULT_COLORS[0]);

  const onCreateLabel = () => {
    setLabelsSearchQuery("");
    createLabel({
      appId,
      name: labelsSearchQuery,
      color: color[500],
    });
  };

  const addLabel = (label: ILabel) => {
    setSelectedLabels([...selectedLabels, label]);
  };

  const removeLabel = (label: ILabel) => {
    setSelectedLabels(selectedLabels.filter((l) => l.id !== label.id));
  };

  const visibleLabels =
    labelsSearchQuery !== ""
      ? labels.filter((l) =>
          l.name.toLowerCase().includes(labelsSearchQuery.toLowerCase()),
        )
      : labels;
  const menuText = selectedLabels.length
    ? selectedLabels.length + " selected"
    : "All";
  const emptyLabels = labels.length === 0;

  return (
    <>
      <Menu closeOnSelect={false} closeOnBlur={true}>
        <MenuButton
          size="sm"
          minW="200px"
          height="32px"
          as={Button}
          rightIcon={<ChevronDownIcon />}
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Text fontSize="sm">
              <Text as="span" color="gray.500">
                Labels:{" "}
              </Text>
              {menuText}
            </Text>
          </Flex>
        </MenuButton>
        <MenuList minWidth="200px" pt={0}>
          {emptyLabels ? (
            <Flex p={10} align={"center"} direction="column">
              <Text fontSize="sm">You have no labels</Text>
              <Link to={`/a/${appId}/settings/labels`}>
                <Text fontSize="xs" color="primary">
                  Create a label →
                </Text>
              </Link>
            </Flex>
          ) : (
            <>
              <Flex
                bg="gray.100"
                px={4}
                py={2}
                align="center"
                justify="space-between"
              >
                <Text
                  color="gray.500"
                  fontSize="xs"
                  fontWeight="medium"
                  textTransform="uppercase"
                >
                  labels
                </Text>
                <Link to={`/a/${appId}/settings/labels`}>
                  <Text fontSize="xs" color="primary">
                    Edit labels →
                  </Text>
                </Link>
              </Flex>
              <Flex px={3} py={2} gap={2}>
                <Input
                  borderRadius="lg"
                  placeholder="Search labels"
                  size="sm"
                  value={labelsSearchQuery}
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    setLabelsSearchQuery(e.currentTarget.value)
                  }
                />
              </Flex>
              <MenuItem py={1}>
                <Checkbox
                  size="md"
                  colorScheme={"purple"}
                  key={"all"}
                  isChecked={labels.length === selectedLabels.length}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedLabels(visibleLabels);
                    } else {
                      setSelectedLabels([]);
                    }
                  }}
                >
                  <Text
                    fontSize="sm"
                    color="gray.700"
                    textAlign="center"
                    fontWeight="semibold"
                  >
                    Select all
                  </Text>
                </Checkbox>
              </MenuItem>
              {visibleLabels.map((label) => {
                return (
                  <MenuItem py={1}>
                    <Checkbox
                      w="100%"
                      colorScheme={"purple"}
                      key={label.name}
                      isChecked={selectedLabels
                        .map((l) => l.id)
                        .includes(label.id)}
                      size="sm"
                      onChange={(e) => {
                        if (e.target.checked) {
                          addLabel(label);
                        } else {
                          removeLabel(label);
                        }
                      }}
                    >
                      <Flex align="center">
                        <Box
                          bg={label.color}
                          width={2}
                          height={2}
                          borderRadius="50%"
                        />
                        <Text ml={2} fontSize="sm" color="gray.700">
                          {label.name}
                        </Text>
                      </Flex>
                    </Checkbox>
                  </MenuItem>
                );
              })}
              {visibleLabels.length === 0 && (
                <Flex justifyContent="center" w="full" px={2} gridGap={1}>
                  <ColorPicker color={color} setColor={setColor} />
                  <Button
                    ml={1}
                    onClick={onCreateLabel}
                    w="full"
                    colorScheme="purple"
                    variant="ghost"
                  >
                    Create new label
                  </Button>
                </Flex>
              )}
            </>
          )}
        </MenuList>
      </Menu>
    </>
  );
};
