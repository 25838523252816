import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selector as appSelector } from "core/models/app";
import { usePaywall } from "core/hooks/usePaywall";
import { useMauThreshold } from "core/hooks/useMauThreshold";
import { useBilling } from "core/hooks/useBilling";
import Icon from "core/design-system/components/Icon";
import { baseUrl } from "core/constants/paywall";
import { DEMO_APP_ID } from "core/constants/appIds";
import {
  IPaywallModalChildren,
  PaywallModal,
} from "core/components/Paywall/Modal";
import { Box, Flex, Progress, Text } from "@chakra-ui/react";

export const UsageThreshold = () => {
  const { data: currentApp, isLoading } = useSelector(appSelector);
  const { plan } = usePaywall();
  const { userCount, barColor, threshold, hasUsageBasedPricing } =
    useMauThreshold();
  const navigate = useNavigate();
  const { redirectToBillingPortal } = useBilling();

  if (isLoading) return null;
  if (!currentApp) return null;

  const isPaying = currentApp.isPaying;
  const isPaymentOverdue = currentApp.isPaymentOverdue;
  const isTrial = currentApp.isTrialing && currentApp.trialDaysRemaining >= 0;
  const hasTrialed = currentApp.noCardTrialEnded;
  const isDesignCommitteeMember = currentApp.committeeMember;
  const redirectUrl = `${baseUrl}/a/${currentApp.id}/getting-started`;

  return (
    <div>
      <Box mx={2} my={2} px={3}>
        <Flex
          direction="row"
          justifyContent="space-between"
          alignContent="center"
          mb={1}
        >
          <Text color="gray.600" fontSize="xs" pos="relative" top="4px" mb={1}>
            <Text as="span" color="gray.900" fontWeight="medium">
              {userCount.toLocaleString("en-US")}{" "}
            </Text>
            /{" "}
            <Text as="span" color="gray.900" fontWeight="medium">
              {isDesignCommitteeMember
                ? "Unlimited"
                : threshold.toLocaleString("en-US")}{" "}
              MAU
            </Text>{" "}
          </Text>
          <Flex
            direction="column"
            gridGap={0.5}
            onClick={() =>
              hasUsageBasedPricing
                ? navigate(`/a/${currentApp.id}/settings/billing`)
                : redirectToBillingPortal()
            }
          >
            <Flex>
              {isPaying && !isDesignCommitteeMember && (
                <Text
                  color="white"
                  bg="purple.500"
                  fontSize="xs"
                  fontWeight="medium"
                  m={0}
                  px={1.5}
                  py={0.5}
                  as="span"
                  borderRadius="md"
                  textTransform="capitalize"
                  noOfLines={1}
                >
                  {plan.replace("_", " ")}
                </Text>
              )}
            </Flex>
            <Flex>
              {isDesignCommitteeMember && (
                <Text
                  color="white"
                  bg="purple.500"
                  fontSize="xs"
                  fontWeight="medium"
                  m={0}
                  px={1.5}
                  py={0.5}
                  borderRadius="md"
                  noOfLines={1}
                >
                  Committee
                </Text>
              )}
            </Flex>
            <Flex>
              {isPaymentOverdue && (
                <Text
                  color="white"
                  bg="red.500"
                  fontSize="xs"
                  fontWeight="medium"
                  m={0}
                  px={1.5}
                  py={0.5}
                  as="span"
                  borderRadius="md"
                  noOfLines={1}
                >
                  Payment overdue
                </Text>
              )}
            </Flex>
          </Flex>
          <PaywallModal
            redirectUrl={redirectUrl}
            isCloseable={!currentApp.isTrialing || !hasTrialed}
          >
            {(paywallModal: IPaywallModalChildren) => (
              <>
                {!isPaying &&
                  !isPaymentOverdue &&
                  !isTrial &&
                  !isDesignCommitteeMember && (
                    <paywallModal.UpgradeButton
                      tooltipText={
                        hasTrialed && currentApp.isTrialing
                          ? "Your trial has ended. Choose your plan to continue using June"
                          : "Increase your monthly active user threshold and unlock premium features"
                      }
                      variant="ghost"
                      colorScheme="purple"
                      size="xs"
                      isDisabled={currentApp.id === DEMO_APP_ID}
                    >
                      {hasTrialed && currentApp.isTrialing
                        ? "Choose plan"
                        : hasTrialed
                          ? "Upgrade"
                          : "Start Free Trial"}
                    </paywallModal.UpgradeButton>
                  )}
                <paywallModal.Modal />
              </>
            )}
          </PaywallModal>
        </Flex>
        <Progress
          value={(userCount / threshold) * 100}
          size="xs"
          colorScheme={barColor}
          borderRadius="sm"
          bgColor="gray.300"
        />
      </Box>
      {isTrial && (
        <div
          className={
            "mx-3 mt-2 flex cursor-default items-center rounded-md bg-orange-50 px-3 py-1"
          }
        >
          <Icon iconType="icon" name="rocket" color="orange.900" fill="none" />
          <Text
            fontSize="sm"
            fontWeight="medium"
            color="gray.800"
            m={0}
            as="span"
            noOfLines={1}
            ml={2}
          >
            Trial is active
          </Text>
          <Text
            fontSize="sm"
            fontWeight="medium"
            color="orange.900"
            pl={1}
            m={0}
            as="span"
            borderRadius="md"
            noOfLines={1}
          >
            {currentApp.trialDaysRemaining === 0
              ? "until today"
              : currentApp.trialDaysRemaining > 1
                ? `${currentApp.trialDaysRemaining} days left`
                : `${currentApp.trialDaysRemaining} day left`}
          </Text>
        </div>
      )}
    </div>
  );
};
