import humps from "humps";
import { IAppUser } from "core/types/User";
import { baseQuery } from "core/initializers/rtk";

import { createApi } from "@reduxjs/toolkit/query/react";

import type { SnakeCasedPropertiesDeep } from "type-fest";

interface IGetUsersArgs {
  appId: number;
}

interface IInviteUsersArgs {
  appId: number;
  emailList: string[];
  page: string;
}

export type IGetUsersResponse = IAppUser[];

export const usersApi = createApi({
  baseQuery,
  reducerPath: "usersApi",
  tagTypes: ["User"],
  endpoints: (builder) => ({
    getUsers: builder.query<IGetUsersResponse, IGetUsersArgs>({
      query: ({ appId }) => ({ url: "/users", params: { app_id: appId } }),
      providesTags: ["User"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IGetUsersResponse>,
      ): IGetUsersResponse => humps.camelizeKeys(response) as IGetUsersResponse,
    }),
    inviteUsers: builder.mutation<IGetUsersResponse, IInviteUsersArgs>({
      query: ({ appId, emailList, page }) => ({
        url: "/users/invite_to_app",
        method: "POST",
        body: { app_id: appId, email_list: emailList, page },
      }),
      invalidatesTags: ["User"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IGetUsersResponse>,
      ): IGetUsersResponse => humps.camelizeKeys(response) as IGetUsersResponse,
    }),
    weeklyDigestOptIn: builder.mutation({
      query: ({ appId, weeklyDigestOptedIn }) => ({
        url: `/users/weekly_digest_opt_in`,
        method: "POST",
        params: humps.decamelizeKeys({ appId, weeklyDigestOptedIn }),
        credentials: "include",
        mode: "cors",
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useInviteUsersMutation,
  useWeeklyDigestOptInMutation,
} = usersApi;
