import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Disclosure } from "@headlessui/react";
import { ItemText } from "./ItemText";
import { ItemContainer } from "./ItemContainer";
import { AnimatePresence, motion } from "framer-motion";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useFavourites } from "core/hooks/useFavourites";
import { FavouriteRoute, IFavourite } from "core/models/favourites";
import { Item } from "./Favourites/Item";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { Spinner, Tooltip } from "@chakra-ui/react";
import { useState } from "react";
import { useSidebarCollapse } from "core/hooks/useSidebarCollapse";

export const Favourites = () => {
  const currentApp = useCurrentApp();
  const { favourites, onDeleteFavourite, isDeleting } = useFavourites(
    currentApp.id,
  );
  const navigate = useNavigate();
  const [showRemoveFavouriteId, setShowRemoveFavouriteId] = useState<
    number | null
  >(null);

  const { isFavouritesCollapsed, toggleFavouritesCollapse } =
    useSidebarCollapse();

  return (
    <Disclosure defaultOpen={!isFavouritesCollapsed}>
      {({ open }) => (
        <>
          {favourites && favourites.length > 0 && (
            <Disclosure.Button
              onClick={toggleFavouritesCollapse}
              className="group flex w-full items-center justify-between px-4 text-left font-medium text-gray-600 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
            >
              <span className="text-xs font-medium">My favourites</span>
              <motion.div animate={{ rotate: open ? 0 : -90 }}>
                <ChevronDownIcon className="h-4 w-4  text-gray-500 group-hover:block group-hover:text-gray-500" />
              </motion.div>
            </Disclosure.Button>
          )}
          <AnimatePresence>
            <Disclosure.Panel className="text-sm text-gray-500">
              <motion.div
                animate={{ opacity: 1, height: "auto" }}
                initial={{ opacity: 0, height: 0 }}
                exit={{ opacity: 0, height: 0 }}
                className="flex flex-col gap-1"
              >
                {favourites?.map((favourite: IFavourite) => {
                  return (
                    <ItemContainer
                      key={favourite.id}
                      isCurrent={window.location.href.includes(
                        `/a/${currentApp.id}/${FavouriteRoute[favourite.objectType]}/${favourite.objectId}`,
                      )}
                      onClick={() =>
                        navigate(
                          `/a/${currentApp.id}/${FavouriteRoute[favourite.objectType]}/${favourite.objectId}`,
                        )
                      }
                      className="group relative flex justify-between pr-0"
                      onMouseEnter={() =>
                        setShowRemoveFavouriteId(favourite.id)
                      }
                      onMouseLeave={() => setShowRemoveFavouriteId(null)}
                    >
                      <div className="-ml-1 flex w-full items-center">
                        <div className="ml-0.5 line-clamp-1 w-full max-w-[300px]">
                          <ItemText className="flex w-full max-w-[300px] items-center justify-between text-ellipsis">
                            <div className="max-w-[220px]">
                              <Item favourite={favourite} />
                            </div>
                            {showRemoveFavouriteId === favourite.id && (
                              <Tooltip
                                label="Remove from my favourites"
                                hasArrow
                                placement="right"
                              >
                                {isDeleting ? (
                                  <Spinner size="xs" />
                                ) : (
                                  <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    className="rounded p-0.5 hover:bg-gray-300"
                                  >
                                    <XMarkIcon
                                      className="h-4 w-4 cursor-pointer text-gray-600 hover:text-black"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onDeleteFavourite(favourite);
                                      }}
                                    />
                                  </motion.div>
                                )}
                              </Tooltip>
                            )}
                          </ItemText>
                        </div>
                      </div>
                    </ItemContainer>
                  );
                })}
              </motion.div>
            </Disclosure.Panel>
          </AnimatePresence>
        </>
      )}
    </Disclosure>
  );
};
