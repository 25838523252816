import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { clearNextPath, getNextPath, setNextPath } from "helpers/auth";
import { useUserAuth } from "core/hooks/useUserAuth";
import { useToast } from "core/hooks/useToast";
import GoogleIcon from "core/design-system/components/Icon/Logos/GoogleIcon";
import CompanyJuneLogoIcon from "core/design-system/components/Icon/Logos/CompanyJuneLogoIcon";

export function Login() {
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { currentUser, login } = useUserAuth();
  const toast = useToast();

  useEffect(() => {
    const qs = new URLSearchParams(location.search);
    const next = qs.get("next");
    let hostnameAllowed = false;
    try {
      if (next) {
        hostnameAllowed = [
          "localhost",
          "june.so",
          "analytics.june.so",
        ].includes(new URL(next).hostname);
      }
    } catch (e) {}
    if (next && (next.startsWith("/") || hostnameAllowed)) {
      setNextPath(next);
    } else {
      clearNextPath();
    }
  }, [location]);

  const anonymousId =
    analytics && analytics.user && analytics.user().anonymousId();

  const onLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const user = {
      email,
      anonymousId,
      password,
    };

    const data = await login(user);

    if ("data" in data && data.data?.errors) {
      data.data.errors.forEach((error: string) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
          duration: 5000,
        });
      });
    }
  };
  useEffect(() => {
    if (currentUser) {
      const nextPath = getNextPath();
      if (nextPath) {
        if (nextPath.startsWith("http")) {
          window.location.replace(nextPath);
        } else {
          navigate(nextPath);
        }
        clearNextPath();
      } else {
        navigate(`/a/${currentUser.lastUsedApp.id}/home`);
      }
    }
  }, [currentUser, navigate]);

  return (
    <>
      <div className="h-screen flex-col justify-between bg-authentication-gradient">
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
            <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <div className="mb-8 flex flex-col items-center justify-center">
                  <div className="flex justify-center">
                    <CompanyJuneLogoIcon h="50px" w="50px" />
                  </div>
                  <h2 className="mt-4 text-center text-xl font-medium leading-9 tracking-tight text-gray-800">
                    Login
                  </h2>
                  <p className="text-gray-500">Welcome back 🥰</p>
                </div>
              </div>
              <a
                href={`${
                  import.meta.env.VITE_API_HOST
                }/auth/google_oauth2?anonymous_id=${anonymousId}`}
                className="flex w-full items-center justify-center gap-3 rounded-md bg-gray-100 px-3 py-1.5 text-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0]"
              >
                <GoogleIcon h={5} w={5} />

                <span className="text-sm font-semibold leading-6">
                  Log in with Google
                </span>
              </a>
              <div className="relative mt-8">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-200"></div>
                </div>
                <div className="relative flex justify-center text-sm font-medium leading-6">
                  <span className="bg-white px-6 text-gray-900">
                    Or continue with
                  </span>
                </div>
              </div>
              <form className="mt-8 space-y-6" onSubmit={onLogin}>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password
                  </label>
                  <div className="mt-2">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>

                <div className="flex items-center justify-end">
                  <div className="text-sm leading-6">
                    <Link
                      className="hover:text-puple-600 font-semibold text-purple-500"
                      to="/forgot-password"
                    >
                      Forgot password?
                    </Link>
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-purple-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-purple-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-500"
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Login.propTypes = {
  handleLogin: PropTypes.func,
};

export default Login;
