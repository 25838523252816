import { FaceFrownIcon } from "@heroicons/react/24/outline";

export const ErrorMessage: React.FC = () => {
  return (
    <div className="flex h-[500px] w-full items-center justify-center">
      <div className="flex flex-col items-center gap-2">
        <div className="mb-4 rounded-full border border-gray-200 bg-white p-3 shadow-sm">
          <FaceFrownIcon className="h-6 text-purple-500" />
        </div>
        <p className="font-medium leading-3">Oops, something went wrong</p>
        <p className="w-72 text-center text-sm text-gray-600">
          Please{"  "}
          <p
            onClick={() => window.location.reload()}
            className="inline-block cursor-pointer font-medium text-purple-500"
          >
            refresh
          </p>{" "}
          the page or {` `}
          <p
            onClick={() =>
              window.Intercom(
                "showNewMessage",
                "I'm experiencing an issue with the People page. Can you take a look please?",
              )
            }
            className="inline-block cursor-pointer font-medium text-purple-500"
          >
            reach out
          </p>
          {` `}
          if the issue persists
        </p>
      </div>
    </div>
  );
};
