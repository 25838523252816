import { Flex, StyleProps, Text } from "@chakra-ui/react";

const ColumnHeader: React.FC<StyleProps> = ({ children, ...props }) => {
  return (
    <Flex p={2} align="center" justify="flex-start" {...props}>
      <Text
        color="gray.600"
        textTransform="uppercase"
        fontWeight="bold"
        fontSize="xs"
      >
        {children}
      </Text>
    </Flex>
  );
};
export default ColumnHeader;
