import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ViewContainer } from "modules/Views/Container";
import { GettingStartedBanner } from "modules/Home/GettingStartedBanner";
import { useUpdateViewMutation } from "core/models/views";
import { selector as editingPinnedReportsSelector } from "core/models/pinnedReports/selectors";
import { toggleEdit } from "core/models/pinnedReports/actions";
import { useViews, ViewLocation } from "core/hooks/useViews";
import { useOnboardingChecklist } from "core/hooks/useOnboardingChecklist";
import { TwEditable } from "core/design-system/components/TwEditable";
import { PageContainer } from "core/components/PageContainer";
import { EmojiPicker } from "core/components/EmojiPicker";
import { Button, Divider, Flex, Skeleton } from "@chakra-ui/react";
import { FeatureList } from "modules/Home/highlights/user/FeatureList";
import { Squares2X2Icon, SquaresPlusIcon } from "@heroicons/react/24/outline";
import { useState } from "react";

export const View: React.FC = () => {
  const { appId, viewId } = useParams();
  const dispatch = useDispatch();
  const { isEditing } = useSelector(editingPinnedReportsSelector);
  const { shouldShowGettingStartedBanner } = useOnboardingChecklist();
  const [showAddModal, setShowAddModal] = useState(false);

  const { view, isLoadingViews } = useViews({
    location: ViewLocation.Home,
    pinned: false,
  });

  const [updateView] = useUpdateViewMutation();

  function handleEmojiClick(emojiObject: { emoji: string }, viewId: number) {
    updateView({
      id: Number(viewId),
      appId: Number(appId),
      emoji: emojiObject.emoji,
    });
  }

  function onUpdateViewName(title: string) {
    if (view?.id)
      updateView({ id: Number(view.id), appId: Number(appId), name: title });
  }

  return (
    <PageContainer
      maxW="1106px"
      h="100vh"
      bgColor={isEditing ? "bg-gray-50" : "bg-white"}
      shadow={!isEditing}
    >
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-col">
          <div className="flex items-center gap-2">
            <div className="flex">
              <EmojiPicker
                emoji={view?.emoji ? view?.emoji : "🏠"}
                handleEmojiClick={(e) => handleEmojiClick(e, Number(view?.id))}
                buttonProps={{ size: "normal", px: 2 }}
                textProps={{ fontSize: "3xl" }}
              />
            </div>
            <div className="flex">
              {view && (
                <TwEditable
                  key={view.name}
                  className={"text-2xl font-semibold"}
                  inputClassName={"text-2xl font-semibold"}
                  defaultValue={view.name}
                  placeholder={"Untitled view"}
                  fontWidth={14}
                  onSubmit={onUpdateViewName}
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <Button
            className="view-add-insight-button"
            variant="ghost"
            onClick={() => {
              setShowAddModal(!showAddModal);
              dispatch(toggleEdit());
            }}
            leftIcon={<SquaresPlusIcon className="h-4 w-4" />}
          >
            Add
          </Button>
          <Button
            key={isEditing ? "edit" : "done"}
            variant={isEditing ? "solid" : "ghost"}
            colorScheme={isEditing ? "purple" : "gray"}
            onClick={() => dispatch(toggleEdit())}
            leftIcon={<Squares2X2Icon className="h-4 w-4" />}
          >
            {isEditing ? "Done" : "Edit"}
          </Button>
        </div>
      </div>
      <Flex my={3} />
      {isLoadingViews ? (
        <div className="flex flex-col gap-5">
          <div className="flex gap-5">
            <Skeleton
              borderRadius="lg"
              className="h-[315px] w-[350px] rounded"
            />
            <Skeleton
              borderRadius="lg"
              className="h-[315px] w-[350px] rounded"
            />
            <Skeleton
              borderRadius="lg"
              className="h-[315px] w-[350px] rounded"
            />
          </div>
          <div className="flex gap-5">
            <Skeleton
              borderRadius="lg"
              className="h-[315px] w-[350px] rounded"
            />
            <Skeleton
              borderRadius="lg"
              className="h-[315px] w-[350px] rounded"
            />
            <Skeleton
              borderRadius="lg"
              className="h-[315px] w-[350px] rounded"
            />
          </div>
        </div>
      ) : (
        <ViewContainer
          location={ViewLocation.Home}
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
          viewId={Number(viewId)}
        />
      )}
      <Divider my={4} />
      <FeatureList opacity={isEditing ? 0.5 : 1} />
      <div className="pb-10" />
      {shouldShowGettingStartedBanner && <GettingStartedBanner />}
    </PageContainer>
  );
};
