export enum DeletionType {
  Timestamp = "timestamp",
  Event = "event",
  Contact = "contact",
  Group = "group",
  App = "app",
}

export enum State {
  Requested = "requested",
  Started = "started",
  Cancelled = "cancelled",
  Finished = "finished",
  Failed = "failed",
}

export interface IDataDeletionRequest {
  id: number;
  deletionType: DeletionType;
  state: State;
  endDate?: string;
  eventName?: string;
  contactId?: string;
  groupId?: string;
}
