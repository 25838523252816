import React from "react";
import { ILabel } from "core/types/Label";
import { LabelPill } from "modules/Labels/LabelPill";
import {
  Box,
  Flex,
  Text,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@chakra-ui/react";

interface IReportLabelsProps {
  labels: ILabel[];
  setSelectedLabels?: (labels: ILabel[]) => void;
}

export const ReportLabelsBadge: React.FC<IReportLabelsProps> = ({
  labels,
  setSelectedLabels,
}) => {
  if (!labels || labels.length === 0) return <></>;

  if (labels.length > 3)
    return (
      <Flex ml={2} align="center" justify="flex-end">
        <Popover>
          <PopoverTrigger>
            <Button h={6} borderRadius="2xl">
              <Flex align="center">
                <Flex pl={4} align="center">
                  {labels.slice(0, 3).map((label, index) => {
                    return (
                      <Box
                        ml={index !== 0 ? -5 : 0}
                        bg={label.color}
                        width={3}
                        height={3}
                        borderRadius="50%"
                        border="1px"
                        borderColor="white"
                        cursor="pointer"
                      />
                    );
                  })}
                </Flex>
                <Text ml={4} fontSize="xs">
                  {labels.length} labels
                </Text>
              </Flex>
            </Button>
          </PopoverTrigger>
          <PopoverContent w="200px" borderRadius="lg">
            <Flex align="center">
              <Flex py={2} align="flex-start" direction="column">
                <Text
                  ml={4}
                  mb={2}
                  color="gray.600"
                  fontSize="xs"
                  fontWeight="medium"
                  textTransform="uppercase"
                >
                  labels
                </Text>
                {labels.map((label) => {
                  return (
                    <Flex ml={2} mb={1} px={2} py={1} align="center">
                      <Box
                        bg={label.color}
                        width={2}
                        height={2}
                        borderRadius="50%"
                      />
                      <Text ml={2} fontSize="xs" color="gray.700">
                        {label.name}
                      </Text>
                    </Flex>
                  );
                })}
              </Flex>
            </Flex>
          </PopoverContent>
        </Popover>
      </Flex>
    );

  return (
    <Flex align="center" justify="flex-end">
      {labels.map((label) => (
        <LabelPill label={label} setSelectedLabels={setSelectedLabels} />
      ))}
    </Flex>
  );
};
