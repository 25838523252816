import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { IQuery } from "core/types/Query";
import { useReportSetup } from "core/hooks/useReportSetup";
import { useQueries } from "core/hooks/useQueries";
import { Editable } from "core/design-system/components/Editable";
import InsightCard from "core/components/InsightCard/Index";
import AnimatedToggle from "core/components/AnimatedToggle";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  Button,
  Center,
  ComponentDefaultProps,
  Flex,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import { ArrowUpDownIcon } from "@chakra-ui/icons";
import { Graph } from "./Graph";
import { ChartSettings } from "./ChartSettings";
import { AxisSettings } from "./AxisSettings";
import { SQLEditor } from "../SqlEditor";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

export interface IResultColumn {
  name: string;
  type: string;
}

export function getInitialYKey(
  availableKeys: string[],
  query?: IQuery,
): string {
  if (query?.graphYAxis) return query?.graphYAxis;

  if (availableKeys?.length > 0)
    return (
      availableKeys?.find((k: string) => k.toLowerCase().includes(`count`)) ||
      availableKeys[0] ||
      ""
    );

  return "";
}

export function getInitialXKey(
  availableKeys: string[],
  query?: IQuery,
): string {
  if (query?.graphXAxis) return query?.graphXAxis;
  if (availableKeys?.length > 0) {
    return (
      availableKeys.find(
        (k: string) =>
          k.toLowerCase().includes(`timestamp`) ||
          k.toLowerCase().includes(`date`) ||
          k.toLowerCase().includes(`_id`) ||
          k.toLowerCase().includes(`name`) ||
          k.toLowerCase().includes(`day`) ||
          k.toLowerCase().includes(`month`) ||
          k.toLowerCase().includes(`week`),
      ) ||
      availableKeys[1] ||
      ""
    );
  }
  return "";
}

interface IResult extends ComponentDefaultProps {
  query: IQuery;
  isEditing?: boolean;
}

export const Result: React.FC<IResult> = ({ query, isEditing = true }) => {
  const {
    query: latestQuery,
    queryResult,
    updateQuery,
    removeQuery,
    executeQuery,
    isExecutingQuery,
  } = useQueries(query.id);
  const { appId, reportId } = useParams();
  const availableKeys = queryResult?.result?.columns?.map(
    (c: IResultColumn) => c.name,
  );
  const defaultYDataKey = getInitialYKey(availableKeys, query);
  const defaultXDataKey = getInitialXKey(availableKeys, query);

  const [xDataKey, setXDataKey] = useState(defaultXDataKey);
  const [yDataKey, setYDataKey] = useState(defaultYDataKey);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [result, setResult] = useState<any>(queryResult?.result);
  const { invalidateInsights, fetchReport } = useReportSetup();

  useEffect(() => {
    if (!yDataKey) setYDataKey(defaultYDataKey);
    if (!xDataKey) setXDataKey(defaultXDataKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xDataKey, yDataKey, queryResult]);

  useEffect(() => {
    const newResult = queryResult?.result;
    const pagy = queryResult?.pagy;

    if (!newResult || !pagy) return;

    setHasMore(true);

    if (page === 1) {
      setResult(newResult);
    } else if (pagy.page === page) {
      const newData = [...result?.data, ...newResult?.data];

      setResult({
        columns: result.columns,
        data: newData,
      });
    }

    if (pagy?.page === pagy?.last) {
      setHasMore(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResult]);

  useEffect(() => {
    if (query.id) {
      executeQuery({ appId: Number(appId), query: query.query, page });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.id, page]);

  function onUpdateXAxis(key: string) {
    if (query.id)
      updateQuery({ appId: Number(appId), queryId: query.id, graphXAxis: key });
  }

  function onUpdateYAxis(key: string) {
    if (query.id)
      updateQuery({ appId: Number(appId), queryId: query.id, graphYAxis: key });
  }

  function onUpdateGraphType(graphType: string) {
    if (query.id)
      updateQuery({ appId: Number(appId), queryId: query.id, graphType });
  }

  function onUpdateInsightTitle(title: string) {
    if (query.id)
      updateQuery({ appId: Number(appId), queryId: query.id, prompt: title });
  }

  function onRemoveQueryFromReport(queryId: number) {
    removeQuery({
      appId: Number(appId),
      reportId: Number(reportId),
      queryId,
    }).then(() => {
      invalidateInsights();
      fetchReport();
    });
  }

  const isList = latestQuery?.graphType === "list";

  if (!latestQuery) return null;

  return (
    <InsightCard>
      {(Card) => (
        <Card.Container id={String(latestQuery?.id)}>
          <Accordion allowToggle>
            <AccordionItem boxShadow="none" borderBottom="none">
              <AccordionButton as="span" p={0} borderBottom="none">
                <Flex
                  w="full"
                  align="center"
                  borderBottom="1px solid"
                  borderColor="gray.200"
                  justifyContent="space-between"
                  py={3}
                  px={5}
                >
                  <Box>
                    <Flex align="center" gridGap={2}>
                      <Flex>
                        <Tooltip
                          label={`${query?.createdBy?.email} asked using June AI`}
                          placement="top"
                          shouldWrapChildren
                          hasArrow
                        >
                          <Avatar
                            size="xs"
                            name={query?.createdBy?.email}
                            src={query?.createdBy?.imageUrl}
                          />
                        </Tooltip>
                      </Flex>
                      <Flex>
                        <Editable
                          left="0px"
                          onClick={(e) => e.preventDefault()}
                          submitOnBlur
                          color="black"
                          noOfLines={1}
                          fontSize="md"
                          defaultValue={latestQuery?.prompt}
                          onSubmit={onUpdateInsightTitle}
                          inputProps={{ w: "450px" }}
                        />
                      </Flex>
                    </Flex>
                  </Box>
                  <Flex>
                    <Flex mr={2}>
                      <Tooltip label="Show query" hasArrow shouldWrapChildren>
                        <AnimatedToggle
                          items={[
                            {
                              value: "sql",
                              label: "",
                              icon: (
                                <div className="flex items-center gap-1">
                                  <p className="text-sm text-gray-600">SQL</p>
                                  <ArrowUpDownIcon
                                    className="text-gray-600"
                                    transform="rotate(90deg)"
                                  />
                                </div>
                              ),
                            },
                          ]}
                          value="sql"
                          onChange={() => {}}
                        />
                      </Tooltip>
                    </Flex>
                    <ChartSettings
                      query={latestQuery}
                      onUpdateGraphType={onUpdateGraphType}
                    />
                  </Flex>
                </Flex>
              </AccordionButton>
              <AccordionPanel p={0}>
                {latestQuery && (
                  <SQLEditor
                    query={latestQuery}
                    executeQuery={executeQuery}
                    updateQuery={updateQuery}
                  />
                )}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Box
            h={isList ? "full" : "300px"}
            px={isList ? 0 : 6}
            py={isList ? 0 : 4}
          >
            {result && !isExecutingQuery && !queryResult?.error && (
              <Graph
                key={`${page}-${xDataKey}-${yDataKey}`}
                result={result}
                query={latestQuery}
                xKey={xDataKey}
                yKey={yDataKey}
                page={page}
                setPage={setPage}
                hasMore={hasMore}
              />
            )}
            {!isExecutingQuery && queryResult?.error && (
              <div className="flex h-[200px] w-full items-center justify-center">
                <div className="flex flex-col gap-2 text-center">
                  <ExclamationTriangleIcon className="h-6 text-gray-600" />
                  <p className="text-sm font-medium">
                    Error occured running query
                  </p>
                </div>
              </div>
            )}
            {isExecutingQuery && (
              <Center h="200px" w="full">
                <Spinner />
              </Center>
            )}
          </Box>
          <Card.Footer
            px={isList && isEditing ? 0 : 6}
            py={isList && isEditing ? 0 : 2}
            bg="white"
            borderTop={isList && isEditing ? "none" : "1px solid"}
          >
            <Flex w="full" align="center" justifyContent="space-between">
              <Flex align="center">
                {!isList && (
                  <Box bg="transparent">
                    <Flex w="full" justifyContent="space-between">
                      <Flex align="center" gridGap={5}>
                        <AxisSettings
                          axisLabel="Y Axis"
                          axisKey={yDataKey}
                          availableKeys={availableKeys}
                          setKey={setYDataKey}
                          onUpdateYAxis={onUpdateYAxis}
                        />
                        <AxisSettings
                          axisLabel="X Axis"
                          axisKey={xDataKey}
                          availableKeys={availableKeys}
                          setKey={setXDataKey}
                          onUpdateXAxis={onUpdateXAxis}
                        />
                      </Flex>
                    </Flex>
                  </Box>
                )}
              </Flex>
              {!isEditing && (
                <Flex>
                  <Button
                    onClick={() => onRemoveQueryFromReport(latestQuery.id)}
                    colorScheme="gray"
                    color="gray.600"
                    variant="ghost"
                  >
                    Remove
                  </Button>
                </Flex>
              )}
            </Flex>
          </Card.Footer>
        </Card.Container>
      )}
    </InsightCard>
  );
};
