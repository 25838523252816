import { ITrait } from "core/types/Trait";
import { lowerCase } from "lodash";

export interface IGetContactAvatarProps {
  traits: { name: string; value: string }[] | { [key: string]: string };
}
const INVALID_TRAIT_VALUES = ["null", "", " "];

const valid = (str?: string) =>
  str && !INVALID_TRAIT_VALUES.includes(str) && str;

const avatarFromTraitsArray = (traits: { name: string; value: string }[]) => {
  // When trait is an array of name & value pairs [{ name: 'firstName', value: 'Gladys' }, ...]
  const avatarUrl = traits.find(
    (trait) => lowerCase(trait.name) === "avatar",
  )?.value;
  if (valid(avatarUrl)) return avatarUrl;

  return undefined;
};

export const avatarFromTraits = (traits: ITrait) => {
  // When trait is an object { firstName: 'Gladys', ... }
  if (valid(traits?.avatar)) return traits?.avatar;

  return undefined;
};

export const getContactAvatar = (contact: IGetContactAvatarProps) => {
  const traitsIsArray = Array.isArray(contact?.traits);
  return traitsIsArray
    ? avatarFromTraitsArray(
        contact?.traits as { name: string; value: string }[],
      )
    : avatarFromTraits(contact?.traits as { [key: string]: string });
};

export const generateRandomAnimalName = (contactId: number) => {
  const animals = [
    "alien",
    "bear",
    "cat",
    "dog",
    "dragon",
    "fox",
    "ghost",
    "koala",
    "monkey",
    "panda",
    "pig",
    "robot",
    "rooster",
    "tiger",
    "giraffe",
  ];

  return animals[Math.abs(contactId) % animals.length];
};

// Turn any string ("hello") into a number
// Used for contact id and group id that can sometimes be string ("test-user")
export const hashStringToNumber = (string: string | undefined) => {
  if (!string || string?.length === 0) return 0;

  var hash = 0,
    i,
    chr;

  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }

  return Math.abs(hash);
};
