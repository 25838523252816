import { Box, Flex, Text } from "@chakra-ui/react";
import colors from "core/design-system/constants/theme/colors";

export const CurrentPeriodTooltip: React.FC<{
  label: string;
  payload: any;
  active: boolean;
  unit: string;
  currentPeriodTooltipText: string;
}> = ({ label, payload, active, unit, currentPeriodTooltipText }) => {
  const data = payload?.[0]?.payload;

  if (active && data) {
    return (
      <Box w="280px" bg="gray.900" px={3} py={2} borderRadius={5}>
        <Text fontSize="xs" color="gray.400">
          {label}
        </Text>
        <>
          {data.currentStickynessRatio ? (
            <Flex direction="column">
              <Flex align="center">
                <Box
                  h={2}
                  w={2}
                  mr={2}
                  bg={colors.gray[500]}
                  borderRadius="100%"
                ></Box>
                <Text fontSize="sm" color="white">
                  {data.currentStickynessRatio} {unit}
                </Text>
              </Flex>
              <Text fontSize="xs" color="white">
                {currentPeriodTooltipText}
              </Text>
            </Flex>
          ) : (
            <Flex align="center">
              <Box
                h={2}
                w={2}
                mr={2}
                bg={colors.purple[500]}
                borderRadius="100%"
              ></Box>
              <Text fontSize="sm" color="white">
                {data.stickynessRatio} {unit}
              </Text>
            </Flex>
          )}
        </>
      </Box>
    );
  }
  return null;
};
