import { Box, Flex, Skeleton, SkeletonCircle, Stack } from "@chakra-ui/react";

export const LoadingListItem = ({
  hideAvatar = false,
}: {
  hideAvatar: boolean;
}) => {
  const colors: string[] = [
    "templateLightPurple",
    "orange",
    "templatePink",
    "templateRed",
    "templateDarkGreen",
    "yellow",
  ];

  const randomNumber: number = Math.floor(Math.random() * 5);
  const color: string = colors[randomNumber];

  return (
    <Box borderRadius="sm" borderBottomWidth="1px" borderColor="gray.100">
      <Flex justify="space-between" h={16}>
        <Flex align="center" px={4} color="gray.600">
          {!hideAvatar && (
            <SkeletonCircle
              size="10"
              startColor={`${color}.50`}
              endColor={`${color}.100`}
            />
          )}

          <Stack ml={hideAvatar ? 0 : 4}>
            <Skeleton
              borderRadius="lg"
              h={2}
              w={20}
              startColor="gray.200"
              endColor="gray.300"
            />
            <Skeleton
              borderRadius="lg"
              colorScheme="gray"
              w={52}
              h={2}
              startColor="gray.50"
              endColor="gray.100"
            />
          </Stack>
        </Flex>
      </Flex>
    </Box>
  );
};
