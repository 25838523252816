import React from "react";
import colors from "core/design-system/constants/theme/colors";
import { Button, Tooltip } from "@chakra-ui/react";

interface IActionProps {
  onClick: () => void;
  children: JSX.Element;
  tooltipLabel: string;
  isEnabled: boolean;
}

export const Action: React.FC<IActionProps> = ({
  onClick,
  children,
  tooltipLabel,
  isEnabled,
  ...props
}) => {
  if (isEnabled)
    return (
      <Tooltip size="sm" hasArrow label={tooltipLabel} placement="top">
        <Button onClick={onClick} variant="ghost" px={0} {...props}>
          {children}
        </Button>
      </Tooltip>
    );

  const childrenWithProps = React.Children.map(children, (child, index) => {
    if (React.isValidElement(child)) {
      // @ts-ignore
      return React.cloneElement(child, { color: colors.gray[400], key: index });
    }
    return child;
  });

  return (
    <Tooltip
      label="Slack alerts are not available for this insight"
      placement="top"
      size="sm"
    >
      <Button
        cursor="not-allowed"
        _hover={{ bg: "white" }}
        variant="ghost"
        px={0}
        {...props}
      >
        {childrenWithProps}
      </Button>
    </Tooltip>
  );
};
