import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useLazyGetTraitsQuery } from "core/models/traits";
import {
  AppObjectSlugs,
  AppObjectType,
  IAppObject,
} from "core/models/appObjects";
import { FilterType } from "core/constants/report-setup";
import { useAppObjects } from "./useAppObjects";

export const useUserTraits = () => {
  const { appId } = useParams();
  const [getTraits, { data, isLoading }] = useLazyGetTraitsQuery();
  function fetchUserTraits() {
    getTraits({
      appId: Number(appId),
      filterType: FilterType.UserTrait,
    });
  }
  return {
    userTraits: data || [],
    isUserTraitsLoading: isLoading,
    fetchUserTraits,
  };
};

export const useCompanyTraits = () => {
  const { appId } = useParams();
  const [getTraits, { data, isLoading }] = useLazyGetTraitsQuery();
  function fetchCompanyTraits() {
    getTraits({
      appId: Number(appId),
      filterType: FilterType.CompanyFilter,
    });
  }
  return {
    companyTraits: data || [],
    isCompanyTraitsLoading: isLoading,
    fetchCompanyTraits,
  };
};

export const useGroupTraits = () => {
  const { appId } = useParams();
  const [getTraits, { data, isLoading }] = useLazyGetTraitsQuery();
  function fetchGroupTraits() {
    getTraits({
      appId: Number(appId),
      filterType: FilterType.GroupFilter,
    });
  }
  return {
    groupTraits: data || [],
    isGroupTraitsLoading: isLoading,
    fetchGroupTraits,
  };
};

export const useTraits = (objectType?: AppObjectType | undefined) => {
  const { appId } = useParams();
  const userTraits = useUserTraits();
  const companyTraits = useCompanyTraits();
  const groupTraits = useGroupTraits();

  const traitsByObjectType = {
    [AppObjectType.User]: userTraits?.userTraits,
    [AppObjectType.Company]: companyTraits?.companyTraits,
    [AppObjectType.Group]: groupTraits?.groupTraits,
  };

  const { appObjects } = useAppObjects();

  useEffect(() => {
    if (!appObjects || appObjects.length === 0) return;

    appObjects.forEach((object: IAppObject) => {
      switch (object.slug) {
        case AppObjectSlugs.User:
          userTraits.fetchUserTraits();
          break;
        case AppObjectSlugs.Group:
          groupTraits.fetchGroupTraits();
          break;
        case AppObjectSlugs.Company:
          companyTraits.fetchCompanyTraits();
          break;
        default:
          break;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId, appObjects]);

  return {
    ...userTraits,
    ...companyTraits,
    ...groupTraits,
    traits: objectType && traitsByObjectType[objectType],
  };
};
