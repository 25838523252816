import React, { useEffect, useState } from "react";
import { IInsightSection } from "core/types/TemplateConfig";
import { IReportInsight } from "core/types/Report";
import { isUserReportLevel } from "core/modules/reports/utils";
import LineChart from "core/modules/reports/report-types/FeatureRelease/Users/UsersLineChart";
import { weeklyUsageMockData } from "core/modules/reports/report-types/FeatureRelease/mockData";
import { useQueryParam } from "core/hooks/useQueryParam";
import { useAppObjects } from "core/hooks/useAppObjects";
import {
  DAU_OPTIONS,
  INTERVAL_TYPES,
  MAU_OPTIONS,
  WAU_OPTIONS,
} from "core/constants/timerange";
import SelectMenu from "core/components/Select";
import { IntervalPeriod, LineContainer } from "core/components/LineContainer";

export const TIME_RANGE_PICKER_OPTIONS = {
  daily: {
    label: "Daily",
    value: "daily",
    intervalPeriod: "ONE_DAY" as IntervalPeriod,
    initialTimeRange: "SEVEN_DAYS",
    timeRangeOptions: DAU_OPTIONS,
    intervalType: INTERVAL_TYPES.DAY,
  },
  weekly: {
    label: "Weekly",
    value: "weekly",
    intervalPeriod: "SEVEN_DAYS" as IntervalPeriod,
    initialTimeRange: "THREE_MONTHS",
    timeRangeOptions: WAU_OPTIONS,
    intervalType: INTERVAL_TYPES.WEEK,
  },
  monthly: {
    label: "Monthly",
    value: "monthly",
    intervalPeriod: "THIRTY_DAYS" as IntervalPeriod,
    initialTimeRange: "THREE_MONTHS",
    timeRangeOptions: MAU_OPTIONS,
    intervalType: INTERVAL_TYPES.MONTH,
  },
};

export type UsersInterval = "daily" | "weekly" | "monthly";

export const IntervalPicker: React.FC<{
  usersInterval: "daily" | "weekly" | "monthly";
  setUsersInterval: (interval: UsersInterval) => void;
}> = ({ usersInterval, setUsersInterval }) => {
  const label = Object.values(TIME_RANGE_PICKER_OPTIONS).find(
    (o) => o.value === usersInterval,
  )?.label;

  return (
    <SelectMenu>
      {(Select) => (
        <Select.Container>
          <Select.Button w="140px" mx={2}>
            {label}
          </Select.Button>
          <Select.Body w="140px">
            {Object.values(TIME_RANGE_PICKER_OPTIONS).map((o) => {
              return (
                <Select.Item
                  key={o.value}
                  value={o}
                  isSelected={usersInterval === o.value}
                  onClick={(o) => setUsersInterval(o.value as UsersInterval)}
                >
                  {o.label}
                </Select.Item>
              );
            })}
          </Select.Body>
        </Select.Container>
      )}
    </SelectMenu>
  );
};

export const FeatureUsersContainer: React.FC<IReportInsight> = ({
  config,
  report,
  reportLevel,
  ...props
}) => {
  const [usersInterval, setUsersInterval] = useState<UsersInterval>("weekly");
  const isUserReport = isUserReportLevel(reportLevel);
  const unit = isUserReport ? "users" : "companies";
  const insightIdQueryParam = useQueryParam("insightId");

  const insight = config.insights.find((i: IInsightSection) => {
    if (insightIdQueryParam) return i.typeId === Number(insightIdQueryParam);
    return i.slug === `${usersInterval}-${unit}`;
  });
  const { activeAppObject } = useAppObjects();

  useEffect(() => {
    if (insightIdQueryParam === undefined || !insight) return;

    if (insight?.slug.includes("daily")) setUsersInterval("daily");
    if (insight?.slug.includes("weekly")) setUsersInterval("weekly");
    if (insight?.slug.includes("monthly")) setUsersInterval("monthly");
  }, [insightIdQueryParam, insight]);

  if (!insight) return null;

  return (
    <LineContainer
      key={usersInterval}
      LineChart={LineChart}
      report={report}
      insight={insight}
      initialTimeRange={
        TIME_RANGE_PICKER_OPTIONS[usersInterval].initialTimeRange
      }
      timeRangeOptions={
        TIME_RANGE_PICKER_OPTIONS[usersInterval].timeRangeOptions
      }
      intervalType={TIME_RANGE_PICKER_OPTIONS[usersInterval].intervalType}
      keyAccessor={"usageInTimeRange"}
      intervalPeriod={TIME_RANGE_PICKER_OPTIONS[usersInterval].intervalPeriod}
      mockData={weeklyUsageMockData}
      config={config}
      actions={
        <IntervalPicker
          usersInterval={usersInterval}
          setUsersInterval={setUsersInterval}
        />
      }
      unit={isUserReport ? "users" : activeAppObject?.pluralName.toLowerCase()}
      {...props}
    />
  );
};
