import { Flex, Text } from "@chakra-ui/react";

export const ActivityTooltip: React.FC = () => {
  return (
    <Flex direction="column" p={2}>
      <Text>What is a "user triggered" event?</Text>
      <Text color="gray.400" my={2} fontSize="sm">
        User triggered are events that can only be triggered by users. Changing
        the status can be useful if you have events that users don't actively
        perform, e.g. "Push notification sent" or "Message received".
      </Text>

      <Text color="gray.400" fontSize="sm">
        <Text color="white" as="span" fontWeight="bold">
          Important:
        </Text>{" "}
        Only user triggered events will be included in the "Any event" smart
        event.
      </Text>
    </Flex>
  );
};
