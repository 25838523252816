import { IBarData } from "core/types/BarChart";

export const medianTimeTakenShortened = ({
  medianTimeTaken,
}: Pick<IBarData, "medianTimeTaken">) => {
  if (!medianTimeTaken) return null;

  if (medianTimeTaken.includes("less than a minute")) {
    return "<1m";
  } else if (medianTimeTaken.includes("1 minute")) {
    return medianTimeTaken.replace("1 minute", "1m");
  } else if (medianTimeTaken.includes("minutes")) {
    return medianTimeTaken.replace("minutes", "m").replace(" ", "");
  } else if (medianTimeTaken.includes("about")) {
    return medianTimeTaken
      .replace("about", "~")
      .replace("hour", "h")
      .replace("minutes", "m")
      .replace("day", "d")
      .replace("month", "m")
      .split(` `)
      .join("");
  } else if (medianTimeTaken.includes("1 hour")) {
    return medianTimeTaken.replace("1 hour", "1h");
  } else if (medianTimeTaken.includes("hours")) {
    return medianTimeTaken.replace("hours", "h").replace(" ", "");
  } else if (medianTimeTaken.includes("1 day")) {
    return medianTimeTaken.replace("1 day", "1d");
  } else if (medianTimeTaken.includes("days")) {
    return medianTimeTaken.replace("days", "d").replace(" ", "");
  } else if (medianTimeTaken.includes("1 month")) {
    return medianTimeTaken.replace("1 month", "1mo");
  } else if (medianTimeTaken.includes("months")) {
    return medianTimeTaken.replace("months", "mo");
  } else if (medianTimeTaken.includes("year")) {
    return medianTimeTaken.replace("year", "y").replace(" ", "");
  }
};
