// From https://github.com/streamich/react-use/blob/master/src/useMountedState.ts
// Docs: https://github.com/streamich/react-use/blob/master/docs/useMountedState.md

import { useCallback, useEffect, useRef } from "react";

export const useMountedState = () => {
  const mountedRef = useRef<boolean>(false);
  const get = useCallback(() => mountedRef.current, []);

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  return get;
};
