import { createIcon } from "@chakra-ui/react";

export const SlackIconEnabled = createIcon({
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        d="M20.077 11.23a1.923 1.923 0 1 0-1.923-1.922v1.923h1.923Zm-5.385 0a1.923 1.923 0 0 0 1.923-1.922V3.923a1.923 1.923 0 0 0-3.846 0v5.385c0 1.062.861 1.923 1.923 1.923Z"
        fill="#2EB67D"
      />
      <path
        d="M3.923 12.77a1.923 1.923 0 1 0 1.923 1.922V12.77H3.923Zm5.385 0a1.923 1.923 0 0 0-1.923 1.923v5.384a1.923 1.923 0 1 0 3.846 0v-5.385a1.923 1.923 0 0 0-1.923-1.923Z"
        fill="#E01E5A"
      />
      <path
        d="M12.77 20.077a1.923 1.923 0 1 0 1.922-1.923H12.77v1.923Zm0-5.385c0 1.062.86 1.923 1.923 1.923h5.384a1.923 1.923 0 1 0 0-3.846h-5.385a1.923 1.923 0 0 0-1.923 1.923Z"
        fill="#ECB22E"
      />
      <path
        d="M11.23 3.923a1.923 1.923 0 1 0-1.922 1.923h1.923V3.923Zm0 5.385a1.923 1.923 0 0 0-1.923-1.923H3.923a1.923 1.923 0 0 0 0 3.846h5.384a1.923 1.923 0 0 0 1.924-1.923Z"
        fill="#36C5F0"
      />
    </>
  ),
});
