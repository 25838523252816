import { useEffect } from "react";
import { IQuery } from "core/types/Query";
import { useRemoveQueryMutation } from "core/models/reports";
import {
  ICreateQueryArgs,
  useCreateQueryMutation,
  useGetQueriesQuery,
  useLazyExecuteQueryQuery,
  useLazyGetQueryQuery,
  useLazySearchQueriesQuery,
  useSaveQueryMutation,
  useUpdateQueryMutation,
  useVoteForQueryMutation,
} from "core/models/queries";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from "@reduxjs/toolkit/dist/query";
import { useCurrentApp } from "./useCurrentApp";

export type CreateQueryType = MutationTrigger<
  MutationDefinition<
    ICreateQueryArgs,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    "Query",
    IQuery,
    "queriesApi"
  >
>;

export const useQueries = (queryId?: number) => {
  const { id: appId } = useCurrentApp();
  const [getQuery, { data: query, isFetching: isFetchingQuery }] =
    useLazyGetQueryQuery();

  const { data: queriesData, isFetching: isFetchingQueries } =
    useGetQueriesQuery({
      appId,
    });

  const [searchQueries, { data: searchedQueriesData }] =
    useLazySearchQueriesQuery();
  const [
    createQuery,
    { data: createdQuery, isLoading: isCreatingQuery, error: createQueryError },
  ] = useCreateQueryMutation();
  const [updateQuery, { data: updatedQuery, isLoading: isUpdatingQuery }] =
    useUpdateQueryMutation();
  const [
    executeQuery,
    {
      data: queryResult,
      isFetching: isExecutingQuery,
      error: executeQueryError,
    },
  ] = useLazyExecuteQueryQuery();
  const [saveQuery, { isLoading: isAddingQueryToSaveList }] =
    useSaveQueryMutation();
  const [voteForQuery] = useVoteForQueryMutation();
  const [removeQuery] = useRemoveQueryMutation();

  useEffect(() => {
    if (queryId)
      getQuery({
        appId,
        id: Number(queryId),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryId]);

  const isLoading =
    isFetchingQuery ||
    isExecutingQuery ||
    isUpdatingQuery ||
    isAddingQueryToSaveList;

  return {
    query: query || createdQuery || updatedQuery,
    queries: queriesData?.queries,
    searchedQueries: searchedQueriesData?.queries,
    queryResult,
    searchQueries,
    createQuery,
    updateQuery,
    executeQuery,
    voteForQuery,
    saveQuery,
    removeQuery,
    isLoading,
    isCreatingQuery,
    isFetchingQueries,
    createQueryError,
    executeQueryError,
    isExecutingQuery,
  };
};
